import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInstallationContext } from '../../../context/installation';
import { ExalusInstallationPayload, IntegrationType } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { getExalusInstallationCredentials } from '../../../integrations/exalus/utils';

export const useInstallationListHeader = () => {
  const navigate = useNavigate();
  const { installationList, integrationType, selectedInstallationId, selectedInstallation } = useInstallationContext();

  const sortButton = useMemo(() => installationList.length > 0 && !isLocalApp, [installationList]);

  const goToDashboard = () => {
    if (integrationType === IntegrationType.Exalus) {
      const exalusCredentials = getExalusInstallationCredentials(selectedInstallationId);
      if (exalusCredentials) navigate(ROUTES.Root(), { replace: true });
      else {
        if (selectedInstallation) {
          const { externalPayload, installationId } = selectedInstallation;

          navigate(
            ROUTES.ExalusLogin(
              (externalPayload as ExalusInstallationPayload).serialNumber,
              (externalPayload as ExalusInstallationPayload).pin,
              installationId,
            ),
          );
        }
      }
    } else navigate(ROUTES.Root(), { replace: true });
  };

  return {
    sortButton,
    goToDashboard,
  };
};
