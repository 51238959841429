import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DialogActions } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getLocaleByLanguage } from '../../utils/helpers';
import { IconEdit } from '../icons';
import './index.scss';

type CalendarProps = {
  label: string;
  date: Date;
  onChange: (date: Date) => void;
  hidden?: boolean;
  covered?: boolean;
  minDate?: Date;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

const CustomActionBar: React.FC = ({ onAccept, onCancel }: any) => {
  const { t } = useTranslation('common');

  return (
    <DialogActions className="actions">
      <button onClick={onCancel}>{t('buttons.cancel')}</button>
      <button onClick={onAccept}>OK</button>
    </DialogActions>
  );
};

const Calendar: React.FC<CalendarProps> = ({
  date,
  label,
  onChange,
  hidden = false,
  covered = false,
  minDate,
  open,
  onOpen,
  onClose,
}) => {
  const [year] = useState<number>(new Date().getFullYear());
  const adapterLocale = getLocaleByLanguage();

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      onChange(new Date(newDate));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
      <MobileDatePicker
        className={classNames('custom-calendar', {
          'custom-calendar--covered': covered,
          'custom-calendar--hidden': hidden,
        })}
        localeText={{ toolbarTitle: `${year}` }}
        slots={{
          actionBar: CustomActionBar,
        }}
        format="dd.MM.yyyy"
        value={date}
        onChange={handleDateChange}
        disablePast
        label={label}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: <IconEdit />,
              className: 'form-control',
            },
          },
          dialog: {
            className: 'calendar-dialog-root',
          },
        }}
        {...(typeof open !== 'undefined' ? { open } : {})}
        {...(onOpen ? { onOpen } : {})}
        {...(onClose ? { onClose } : {})}
        {...(minDate ? { minDate } : {})}
      />
    </LocalizationProvider>
  );
};

export default Calendar;
