import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { DeviceConnectionState } from '../../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../../routes';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelType } from '../../../types';
import { Checkbox } from '../../../../../components/checkbox';
import { IconSortHandle } from '../../../../../components/icons';
import { ChannelBoxControls } from '../controls';

type BodyProps = {
  channel: ChannelType;
  isDraggable: boolean;
  isCheckbox: boolean;
  checked?: boolean;
  onChannelToggle?: (id: string, value: boolean) => void;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  isListItem: boolean;
};

export const Body: React.FC<BodyProps> = ({
  channel,
  isDraggable,
  isCheckbox,
  attributes,
  onChannelToggle,
  listeners,
  checked,
  isListItem,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const state = useChannelsStateStore((s) => s.channelState[channel.id]);

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    onChannelToggle?.(channel.id, e.target.checked);
  };

  if (isDraggable) {
    return (
      <div className="device-box__draggable">
        <div className="device-box__draggable-handle" {...attributes} {...listeners}>
          <IconSortHandle />
        </div>
      </div>
    );
  }

  if (isCheckbox) return <Checkbox id={channel.id} checked={checked} onChange={handleToggle} />;

  if (state?.deviceConnectionState === DeviceConnectionState.Recovery) {
    return (
      <div className="device-box__update" onClick={() => navigate(ROUTES.DeviceSettings(channel?.deviceId))}>
        {t('update')}
      </div>
    );
  }

  return (
    <div className="device-box__controls">
      <ChannelBoxControls isListItem={isListItem} channel={channel} />
    </div>
  );
};
