import React from 'react';
import { useTranslation } from 'react-i18next';
import { CoverButton } from '../../../../cover-button';
import { IconVent } from '../../../../icons';

export type BlindDetailsMicroventilationProps = {
  handleMicroventilation: () => void;
  position?: number;
  lastPosition?: number;
  onHold?: () => void;
};

export const BlindDetailsMicroventilation: React.FC<BlindDetailsMicroventilationProps> = ({
  handleMicroventilation,
  position,
  lastPosition,
  onHold,
}) => {
  const { t } = useTranslation('channel-details');

  return (
    <CoverButton lastPosition={lastPosition} position={position} onClick={handleMicroventilation} onHold={onHold}>
      <IconVent />
      <span>{t('microventilation')}</span>
    </CoverButton>
  );
};
