import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionHeader from '../../../../../components/action/action-header';
import { ActionAdvancedIcon } from '../../../../../components/advanced-icon';
import { IconConnectionError, IconErrorSmall, IconRecovery } from '../../../../../components/icons';
import { ActionType, DeviceConnectionState, UserAction } from '../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelActionDetails } from '../../../types';
import { actionNameType, checkActionStatus } from '../../../utils/actions/status';
import { ActionIcon } from '../icon';

type ActionDetailsHeaderProps = {
  userAction: UserAction;
  channels: ChannelActionDetails[];
};

export const ActionDetailsHeader: React.FC<ActionDetailsHeaderProps> = ({ userAction, channels }) => {
  const { t } = useTranslation('action');
  const channelState = useChannelsStateStore((s) => s.channelState);
  const isAdvanced = userAction?.action.actionType === ActionType.Advanced;

  const getStatusIcon = () => {
    if (!channels.length) {
      return <IconErrorSmall size={18} />;
    } else if (isDisconnected) {
      return <IconConnectionError big details size={14} />;
    } else if (isRecoveryStatus) {
      return <IconRecovery big details />;
    } else {
      return null;
    }
  };

  const isRecoveryStatus = checkActionStatus(channels, channelState, DeviceConnectionState.Recovery);
  const isDisconnected = checkActionStatus(channels, channelState, DeviceConnectionState.Disconnected);
  const statusIcon = getStatusIcon();

  return (
    <ActionHeader
      actionName={userAction.action.name}
      actionType={t(actionNameType[userAction.action.actionType])}
      statusIcon={statusIcon}
      isAdvanced={isAdvanced}
      icon={<ActionIcon action={userAction.action} className={'action-icon--header'} />}
      iconAdvanced={<ActionAdvancedIcon iconName={userAction.iconName} />}
    />
  );
};
