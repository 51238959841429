import { DeviceTasksInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { TaskExecution } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { create } from 'zustand';

type LoggedUserStore = {
  channelTasks: Record<string, true>;
  setChannelTasks: (channelTasks: DeviceTasksInfo[]) => void;
};

export const useChannelTasksStore = create<LoggedUserStore>((set) => ({
  channelTasks: {},
  setChannelTasks: (channelTasks: DeviceTasksInfo[]) => {
    const mappedTasks: Record<string, true> = channelTasks
      .filter((x) => x.Status === TaskExecution.ExecutingTasks)
      .reduce((acc, curr) => {
        acc[`${curr.DeviceGuid}_${curr.Channel}`] = true;
        return acc;
      }, {});

    set({ channelTasks: mappedTasks });
  },
}));
