import React, { ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DebouncedFunc } from 'lodash';
import { Header, Input, NavHeader, Page, SubmitButton } from '../..';

type DeviceVariantsProps = {
  children: ReactNode;
  search: string;
  handleNext: () => void;
  debouncedResults: DebouncedFunc<({ target }: ChangeEvent<HTMLInputElement>) => void>;
};

const DeviceVariants: React.FC<DeviceVariantsProps> = ({ children, search, handleNext, debouncedResults }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader title={t('action.create.tasks.task')} />
          <Header column isUnderline>
            <Input
              defaultValue={search}
              value={search}
              onChange={debouncedResults}
              placeholder={tc('search')}
              noBottomMargin
              autoFocus
            />
          </Header>
        </>
      }
    >
      {children}
      <SubmitButton onClick={handleNext}>{tc('buttons.next')}</SubmitButton>
    </Page>
  );
};

export default DeviceVariants;
