import React from 'react';
import { IconPlay, ControlWrapper, InitiateButton } from '../../../../../components';
import { UserAction } from '../../../../../data-access/gql-types/graphql';
import { useAdvancedAction } from '../../../hooks/advanced-action';
import './index.scss';

type AdvancedActionSwitchProps = {
  action: UserAction;
};

const AdvancedActionSwitch: React.FC<AdvancedActionSwitchProps> = ({ action }) => {
  const { setActionOn } = useAdvancedAction(action);

  return (
    <ControlWrapper>
      <InitiateButton isOn={false} onClickControl={setActionOn}>
        <IconPlay isLarge />
      </InitiateButton>
    </ControlWrapper>
  );
};

export default AdvancedActionSwitch;
