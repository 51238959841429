import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BatteryDeviceState,
  BatteryStateEnum,
  BlindStatusCode,
  ConfigurationDeviceState,
  ConfigurationStateEnum,
  FloodSensorStates,
  LightThresholdStateEnum,
  LinkQualityEnum,
  OvercurrentProtectionDeviceState,
  SignalStrenghtDeviceState,
  WindThresholdStateEnum,
  GateControllerHealthState,
  IDeviceState as IChannelState,
  BlindErrorCode,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { isNumber } from 'lodash';
import { toastError, toastInfo } from '../../../../../utils/toast';
import { useBrightnessControl } from '../../../components/channel/hooks/use-brightness-control';
import { useFloodControl } from '../../../components/channel/hooks/use-flood-control';
import { useMovementControl } from '../../../components/channel/hooks/use-movement-control';
import { useWindControl } from '../../../components/channel/hooks/use-wind-control';
import { ChannelType, SensorType } from '../../../enums';
import { getChannelType, getSensorType } from '../../../utils';
import { getGatePreciseErrorMessage } from '../../../utils/channel/gate';
import { getChannelState } from '../../../utils/channel/state';
import { useGateErrors } from './use-gate-errors';

type SomethingWrongParams = {
  detailsView: boolean;
};

export const useSomethingWrong = (channel: IDeviceChannel, params?: SomethingWrongParams) => {
  const { t } = useTranslation('channel-details');
  const { windThreshold } = useWindControl(channel);
  const { brightnessThreshold } = useBrightnessControl(channel);
  const { isTamperProtection } = useMovementControl(channel);
  const { flood } = useFloodControl(channel);
  const { blindPositionState, blindErrors, blindError, setBlindError } = useGateErrors(channel);
  const states = channel.States || [];
  const isDetailsView = params?.detailsView;

  const isStatusError = (status?: BlindStatusCode) =>
    status && [BlindStatusCode.UnableToMove, BlindStatusCode.MotorOverheatedPleaseWait].includes(status);

  const signalQuality = getChannelState<SignalStrenghtDeviceState>(states, ChannelResponseType.SignalStrength)?.Data
    .Quality;

  useEffect(() => {
    if (isDetailsView && isNumber(signalQuality)) {
      if ([LinkQualityEnum.Acceptable, LinkQualityEnum.Low, LinkQualityEnum.Terrible].includes(signalQuality)) {
        toastInfo({ content: t('exalus.weakSignal') });
      } else if (signalQuality === LinkQualityEnum.NoLink) {
        toastInfo({ content: t('exalus.noSignal') });
      }
    }
  }, [isDetailsView, signalQuality]);

  useEffect(() => {
    if (isDetailsView) {
      switch (getChannelType(channel)) {
        case ChannelType.Blind: {
          if (blindErrors.length) {
            switch (blindErrors[0]) {
              case BlindErrorCode.ObstacleDetected: {
                if (blindError !== BlindErrorCode.ObstacleDetected) {
                  toastError({ content: t('exalus.obstacleDetected') });
                  setBlindError(BlindErrorCode.ObstacleDetected);
                }
                break;
              }
              case BlindErrorCode.MotorFailure: {
                if (blindError !== BlindErrorCode.MotorFailure) {
                  toastError({ content: t('exalus.motorFailure') });
                  setBlindError(BlindErrorCode.MotorFailure);
                }
                break;
              }
            }
          } else {
            const status = blindPositionState?.GetBlindStatus ? blindPositionState.GetBlindStatus() : null;
            if (!status) return;

            switch (status) {
              case BlindStatusCode.UnableToMove: {
                if (blindError !== BlindStatusCode.UnableToMove) {
                  toastError({ content: t('exalus.unableToMove') });
                  setBlindError(BlindStatusCode.UnableToMove);
                }
                break;
              }
              case BlindStatusCode.MotorOverheatedPleaseWait: {
                if (blindError !== BlindStatusCode.MotorOverheatedPleaseWait) {
                  toastError({ content: t('exalus.motorOverheatedPleaseWait') });
                  setBlindError(BlindStatusCode.MotorOverheatedPleaseWait);
                }
                break;
              }
            }
          }

          break;
        }
        default:
          break;
      }
    }
  }, [isDetailsView, blindError, blindPositionState, blindErrors]);

  const connectionIcon = useMemo(() => {
    const signalStrength = getChannelState<SignalStrenghtDeviceState>(states, ChannelResponseType.SignalStrength);

    if (!signalStrength?.Data) return;
    return !![LinkQualityEnum.NoLink, LinkQualityEnum.Terrible, LinkQualityEnum.Low].includes(
      signalStrength.Data.Quality,
    );
  }, [states]);

  const channelOrientedError = useMemo(() => {
    switch (getChannelType(channel)) {
      case ChannelType.Blind: {
        if (blindErrors.length) {
          if ([BlindErrorCode.ObstacleDetected || BlindErrorCode.MotorFailure].includes(blindErrors[0])) return true;
        }

        const status = blindPositionState?.GetBlindStatus ? blindPositionState.GetBlindStatus() : null;
        if (!status) return false;

        const overcurrentState = states.find(
          (state) => state.TypeAsEnum === ChannelResponseType.OvercurrentProtectionState,
        ) as OvercurrentProtectionDeviceState | undefined;

        return isStatusError(status) || overcurrentState?.Data?.OverrcurrentDetected;
      }
      case ChannelType.Switch: {
        const overcurrentState = getChannelState<OvercurrentProtectionDeviceState>(
          states,
          ChannelResponseType.OvercurrentProtectionState,
        );

        return overcurrentState?.Data.OverrcurrentDetected;
      }
      case ChannelType.Gate: {
        const gateHealth = getChannelState<IChannelState<GateControllerHealthState>>(
          states,
          ChannelResponseType.GateControllerHealth,
        );

        const errorMessage = getGatePreciseErrorMessage(gateHealth);
        return errorMessage ? t(errorMessage) : false;
      }
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Flood:
            return flood === FloodSensorStates.FloodDetected;
          case SensorType.Movement:
            return isTamperProtection;
          default:
            return false;
        }
      default:
        return false;
    }
  }, [states, flood, isTamperProtection]);

  const channelOrientedWarning = useMemo(() => {
    switch (getChannelType(channel)) {
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Wind:
            return windThreshold === WindThresholdStateEnum.Exceeded;
          case SensorType.MeasuredBrightness:
            return brightnessThreshold === LightThresholdStateEnum.Exceeded;
          default:
            return false;
        }
      default:
        return false;
    }
  }, [states]);

  const calibrationNeed = useMemo(() => {
    const calibration = getChannelState<ConfigurationDeviceState>(states, ChannelResponseType.ConfigurationState)?.Data;

    if (calibration) {
      if (
        [
          ConfigurationStateEnum.OngoingConfiguration,
          ConfigurationStateEnum.NotConfigured,
          ConfigurationStateEnum.PartiallyConfigured,
        ].includes(calibration.Configuration)
      ) {
        return calibration.Configuration;
      }
    }

    return null;
  }, [states]);

  const battery = getChannelState<BatteryDeviceState>(states, ChannelResponseType.BatteryState)?.Data;

  const gateHealth = getChannelState<IChannelState<GateControllerHealthState>>(
    states,
    ChannelResponseType.GateControllerHealth,
  );

  return {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    channelOrientedWarning,
    batteryError: battery?.State === BatteryStateEnum.Low || battery?.State === BatteryStateEnum.Empty,
    gateHealth,
  };
};
