import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, uniq } from 'lodash';
import { useMutation } from '@apollo/client';
import {
  ChangeOnEventActionMutation,
  ChangeOnEventActionMutationVariables,
  ChannelTypeInternal,
  NotificationSenderType,
  VersionedFeatureType,
} from '../../../../../data-access/gql-types/graphql';
import { CHANGE_ON_EVENT_ACTION } from '../../../../../data-access/mutations/channels';
import { useBackdropContext } from '../../../../../context/backdrop';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useVersionMap } from '../../../hooks/api/version-map';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { ChannelGateType, ChannelType } from '../../../types';

export const useEvents = () => {
  const { t } = useTranslation('channel-settings');
  const { t: tc } = useTranslation('common');
  const [selectedChannel, setSelectedChannel] = useState<ChannelType | null>(null);
  const [pushActive, setPushActive] = useState<boolean>(false);
  const { channelList, setChannelList } = useDevicesAndChannelsContext();
  const { checkSupport } = useVersionMap();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [changeOnEventAction] = useMutation<ChangeOnEventActionMutation, ChangeOnEventActionMutationVariables>(
    CHANGE_ON_EVENT_ACTION,
  );

  const eventsChannels = useMemo(
    () =>
      channelList.filter(
        (channel) =>
          channel.data.type === ChannelTypeInternal.Gate &&
          checkSupport({
            feature: VersionedFeatureType.GateAlerts,
            supportKey: 'Gate',
            currentVersion: channel.currentFirmwareVersion,
          }),
      ),
    [channelList, checkSupport],
  );

  const deviceTypes: string[] = useMemo(
    () => uniq(eventsChannels.map((channel) => channel.data.type)),
    [eventsChannels],
  );

  const handleCloseModal = () => setSelectedChannel(null);

  const selectChannel = (channel: ChannelType) => {
    setSelectedChannel(channel);

    if (channel.data.type === ChannelTypeInternal.Gate) {
      setPushActive((channel.data as ChannelGateType).onEventAction === NotificationSenderType.Push);
    }
  };

  const togglePush = (e: ChangeEvent<HTMLInputElement>) => setPushActive(e.target.checked);

  const handleSave = () => {
    if (!selectedChannel) {
      toastError({ content: tc('errors.somethingWentWrong') });
      return;
    }

    turnOnBackdrop();
    const onEventAction = pushActive ? NotificationSenderType.Push : null;

    changeOnEventAction({
      variables: {
        input: {
          channelId: selectedChannel.id,
          onEventAction,
        },
      },
      onCompleted: () => {
        toastSuccess({ content: t('toast.editConfigurationSuccess') });
        turnOffBackdrop();
        handleCloseModal();

        setChannelList((prev) => {
          const tempList = cloneDeep(prev);
          const index = tempList.findIndex((x) => x.id === selectedChannel.id);

          if (index !== -1) {
            (tempList[index].data as ChannelGateType).onEventAction = onEventAction;
          }

          return tempList;
        });
      },
      onError: () => {
        turnOffBackdrop();
      },
    });
  };

  return {
    eventsChannels,
    deviceTypes,
    selectedChannel,
    pushActive,
    handleCloseModal,
    togglePush,
    selectChannel,
    handleSave,
  };
};
