import { useTranslation } from 'react-i18next';
import { Accordion } from '../../../../../../../components';
import { useChannelsStateStore } from '../../../../../store/channels-state';
import { ChannelType } from '../../../../../types';
import { ChannelGenericInputStateType } from '../../../../../types/channel/generic-input';
import InputType from '../input-type';

type InputAccordionProps = {
  input: ChannelType;
  monoBiConfigEnable: boolean;
};

export const InputAccordion: React.FC<InputAccordionProps> = ({ input, monoBiConfigEnable }) => {
  const { t } = useTranslation('channel-list');
  const state = useChannelsStateStore((s) => s.channelState[input.id]) as ChannelGenericInputStateType | null;

  return (
    <Accordion
      key={input.id}
      kind="custom"
      title={`${t('input')} ${input.alias}`}
      titleIcon={<div className="input-state" style={{ backgroundColor: state?.status ? '#00B191' : ' #9a9ba2' }} />}
    >
      <InputType key={input.id} inputChannel={input} monoBiConfigEnable={monoBiConfigEnable} />
    </Accordion>
  );
};
