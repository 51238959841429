import {
  useLightSetBrightness,
  useLightSetRgb,
  useLightSetTemperature,
  useLightToggleOff,
  useLightToggleOn,
} from '../../api/light/light.hooks';
import { ChannelTypeInternal, LightToggleValueInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelType, ChannelLightStateType } from '../../types';

export const useLight = (channel: ChannelType) => {
  const toggleOn = useLightToggleOn();
  const toggleOff = useLightToggleOff();
  const setBrightness = useLightSetBrightness();
  const setTemperature = useLightSetTemperature();
  const setColor = useLightSetRgb();
  const { id: channelId, data, deviceId } = channel;
  const state = useChannelsStateStore((s) => s.channelState[channelId]) as ChannelLightStateType | null;

  const isOn = data.type === ChannelTypeInternal.Light ? state?.toggleValue === LightToggleValueInternal.On : false;

  const handleControlOn = (status: boolean) => {
    if (!status) toggleOff.mutate({ deviceId, channelId });
    else toggleOn.mutate({ deviceId, channelId });
  };

  const handleControlBrightness = (value: number) => {
    setBrightness.mutate({ deviceId: channel.deviceId, channelId: channel.id, brightness: value });
  };

  const handleControlTemperature = (value: number) => {
    setTemperature.mutate({ deviceId: channel.deviceId, channelId: channel.id, temperature: value });
  };

  const handleControlRgb = (value: string) => {
    setColor.mutate({
      deviceId: channel.deviceId,
      channelId: channel.id,
      r: Number(value.split(',')[0]),
      g: Number(value.split(',')[1]),
      b: Number(value.split(',')[2]),
    });
  };

  return { handleControlOn, handleControlBrightness, handleControlTemperature, handleControlRgb, isOn };
};
