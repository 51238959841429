import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import c from 'classnames';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useInstallationContext } from '../../context/installation';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import StateSyncButton from '../../integrations/exalus/components/state-sync-button';
import { useLoggedUser } from '../../integrations/exalus/hooks/common/logged-user';
import { ROUTES } from '../../routes';
import { useNotificationsStore } from '../../store/notifications';
import { InstallationIconTypeEnum } from '../../types';
import { isLocalApp } from '../../utils/helpers/local-app';
import { InstallationIcon } from '../edit-installation-icon';
import { IconChevron, IconNotification } from '../icons';
import { PopUpNav, PopUpNavLink } from '../popup-nav';

type DashboardHeaderProps = {
  navLinks: PopUpNavLink[];
  loading?: boolean;
};

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({ navLinks, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const { user } = useLoggedUser();
  const countOfUnreadNotifications = useNotificationsStore((s) => s.countOfUnreadNotifications);
  const { selectedInstallation, installationsLoading, integrationType } = useInstallationContext();
  const isExalus = integrationType === IntegrationType.Exalus;

  const goToNotificationslist = () => navigate(ROUTES.Notification());

  const handleRedirectToInstallationList = () => navigate(ROUTES.Installation());

  return (
    <div className="dashboard__header">
      <div className={c('dashboard__installation', { exalus: isExalus })} onClick={handleRedirectToInstallationList}>
        <div className="dashboard__installation-default-icon">
          <InstallationIcon
            iconName={selectedInstallation?.iconName || InstallationIconTypeEnum.INSTALLATION_0}
            activeColor={selectedInstallation?.hexColor}
            size={36}
          />
        </div>
        {!(installationsLoading && loading) && (
          <>
            <span className="dashboard__installation-name">
              {selectedInstallation ? selectedInstallation.name : t('noSelectedInstallation')}
            </span>
            <IconChevron direction="right" noSpace size={22} />
          </>
        )}
      </div>
      <div className="notifications_row">
        {!isLocalApp && (
          <button className="dashboard__notification" onClick={goToNotificationslist}>
            <IconNotification isOn={!!countOfUnreadNotifications} />
          </button>
        )}
        {isExalus && <StateSyncButton />}
        {selectedInstallation && user?.AccessLevel !== AccessLevel.Guest && (
          <PopUpNav className="dashboard__popup-nav" links={navLinks} />
        )}
      </div>
    </div>
  );
};
