import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDevicesAndChannelsContext } from '../../../../../../context/devices-and-channels';
import { useOptimizerConfigurationContext } from '../../../context/configuration-context';
import { InputSliderDialog } from '../input-slider-dialog';

export const OptimizerConfigurationTemperatureAutoconsumption: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { watch } = useFormContext();
  const { optimizerConfigValidation } = useDevicesAndChannelsContext();
  const { errors } = useOptimizerConfigurationContext();
  const { tempSelfConsumption, tempMin, tempMax, tempMinEnabled } = watch();

  const minValue = useMemo(() => {
    if (tempMinEnabled) return tempMin;
    return optimizerConfigValidation?.tempSelfConsumptionMin.min || tempMin;
  }, [tempMinEnabled, tempMin]);

  return (
    <InputSliderDialog
      propertyName="tempSelfConsumption"
      propertyValue={tempSelfConsumption}
      unit="°C"
      dialogTitle={t('optimizer.configuration.temperature')}
      headerTitle={t('optimizer.configuration.temperatureAutoconsumption')}
      min={minValue}
      max={tempMax}
      maxWidth
      noBottomMargin
      {...(optimizerConfigValidation?.tempSelfConsumptionMin.step
        ? { step: optimizerConfigValidation.tempSelfConsumptionMin.step }
        : {})}
      {...(errors.tempSelfConsumption && { errorMessage: errors.tempSelfConsumption })}
    />
  );
};
