import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityType, ChannelActionParameterInput } from '../../../../../data-access/gql-types/graphql';
import './index.scss';

type AdvancedActionChannelProps = {
  parameter: ChannelActionParameterInput | undefined;
};

export const AdvancedActionChannelParameters: React.FC<AdvancedActionChannelProps> = ({ parameter }) => {
  const { t } = useTranslation('action');

  if (!parameter) return null;

  const getSetting = () => {
    switch (parameter.activityType) {
      case ActivityType.On:
        return t('deviceParameters.on');
      case ActivityType.Off:
        return t('deviceParameters.off');
      case ActivityType.Open:
        return t('deviceParameters.open');
      case ActivityType.Close:
        return t('deviceParameters.close');
      case ActivityType.Stop:
        return t('deviceParameters.stop');
      case ActivityType.SetDim:
        return `${parameter.value}%`;
      case ActivityType.Tilt:
        return t('deviceParameters.ventilation');
      case ActivityType.SetLightColor: {
        const style = { '--rgb-color': `rgb(${parameter.value})` } as React.CSSProperties;
        const element = <span style={style} className="advanced-action-device-parameters__rgb-indicator" />;

        return (
          <span className="advanced-action-device-parameters__rgb">
            {t('deviceParameters.color') + ' '} {element}
          </span>
        );
      }
      case ActivityType.SetPosition:
        return `${t('deviceParameters.position')} ${parameter.value}%`;
      default:
        return '';
    }
  };

  return <span>{getSetting()}</span>;
};
