import { DeviceConnectionState } from '../../../../data-access/gql-types/graphql';
import { ChannelStateType, ChannelType } from '../../types';

export const checkDeviceStatus = (
  channels: ChannelType[] | undefined,
  channelState: Record<string, ChannelStateType | null>,
  status: DeviceConnectionState,
) => {
  if (!channels?.length) return false;
  return !!channels?.some((channel) => channelState[channel.id]?.deviceConnectionState === status);
};
