import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { GatePositionDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { useSomethingWrong } from '../../../../../../modules/channel-list/hooks/use-something-wrong';
import { getGatePreciseErrorMessage } from '../../../../../../utils/channel/gate';
import { getChannelState } from '../../../../../../utils/channel/state';

export const useGatePreciseDetails = (channel: IDeviceChannel) => {
  const { t } = useTranslation('channel-details');
  const { connectionIcon, calibrationNeed, channelOrientedError, gateHealth } = useSomethingWrong(channel, {
    detailsView: true,
  });
  const states = channel.States || [];

  const position =
    getChannelState<GatePositionDeviceState>(states, ChannelResponseType.GatePosition)?.Data.Position ?? null;

  const isWarning = gateHealth?.Data.PhotoCellsActive;
  const errorMessage = t(getGatePreciseErrorMessage(gateHealth));
  const externalLimitSwitchActive = !!(
    gateHealth?.Data.ExternalLimitSwitchAActive || gateHealth?.Data.ExternalLimitSwitchBActive
  );

  return {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    errorMessage,
    isWarning,
    position,
    externalLimitSwitchActive,
  };
};
