import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, SubmitButton } from '../../../../../../components';
import { TariffKindInternal } from '../../../../../../data-access/gql-types/graphql';
import { useMeterTariffPricesConfiguration } from '../../hooks/use-meter-tariff-prices-configuration';
import { TariffPricesForm } from '../../types';

const TariffPrices: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const form = useForm<TariffPricesForm>({
    defaultValues: {
      tariff: TariffKindInternal.None,
      prices: [],
    },
  });
  const { handleSubmitPrices } = useMeterTariffPricesConfiguration(form);
  const { prices } = form.watch();

  const onSubmit = (e) => {
    e.preventDefault();
    const values = form.getValues();
    handleSubmitPrices(values);
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          {prices.map((p, i) => (
            <Controller
              key={p.pricingTier}
              name={`prices.${i}.price`}
              control={form.control}
              rules={{ required: tc('isRequired') as string }}
              render={({ field, fieldState: { error } }) => (
                <div className="grid-list-24">
                  <Input
                    label={
                      prices.length > 1
                        ? `${t(
                            `energyMeasurements.subLabel.FORWARD_ACTIVE_ENERGY_PERIOD${p.pricingTier}${
                              prices.length === 2 ? '_OF_2' : ''
                            }`,
                          )} (zł/kWh)`
                        : t('price')
                    }
                    placeholder={'0 zł'}
                    inputType="number"
                    step="0.1"
                    name={field.name}
                    onChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    {...(error ? { errorMessage: error.message } : {})}
                  />
                </div>
              )}
            />
          ))}
          <SubmitButton />
        </form>
      </FormProvider>
    </div>
  );
};

export default TariffPrices;
