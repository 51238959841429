import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChannelTypeInternal, GateModeInternal, LightModeTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { ROUTES } from '../../../../../routes';
import { ChannelLightType } from '../../../types';
import { ChannelGateType } from '../../../types/channel/gate';
import { useLavvaCreateActionContext } from '../context';
import { TaskVariant } from '../types';

export const useActionTaskVariants = () => {
  const navigate = useNavigate();
  const { channelList } = useDevicesAndChannelsContext();
  const { taskVariant, setTaskVariant, name } = useLavvaCreateActionContext();

  useEffect(() => {
    if (!name) navigate(ROUTES.ActionList(), { replace: true });
  }, []);

  const selectTaskVariant = (taskVariant: TaskVariant) => {
    setTaskVariant(taskVariant);
    navigate(ROUTES.DeviceVariants());
  };

  const getChannelsByVariant = (v?: TaskVariant) => {
    const all = [];

    switch (v || taskVariant) {
      case TaskVariant.OnOff: {
        return channelList.filter(
          (x) => x.data.type === ChannelTypeInternal.Switch || x.data.type === ChannelTypeInternal.Light,
        );
      }
      case TaskVariant.Dim: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Light &&
            (x.data as ChannelLightType).supportedModes.includes(LightModeTypeInternal.Brightness),
        );
      }
      case TaskVariant.SetRGBW: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Light &&
            (x.data as ChannelLightType).supportedModes.includes(LightModeTypeInternal.Colors),
        );
      }
      case TaskVariant.SetBlind: {
        return channelList.filter((x) => x.data.type === ChannelTypeInternal.Blind);
      }
      case TaskVariant.SetGate: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Gate &&
            (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp,
        );
      }
      case TaskVariant.SetVentilation: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Gate &&
            (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp,
        );
      }

      default: {
        return all;
      }
    }
  };

  return {
    selectTaskVariant,
    getChannelsByVariant,
  };
};
