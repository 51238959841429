import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Switch } from '../../../../../../../components';
import ArrowButton from '../../../../../../../components/arrow-button';
import { UserPermissionType } from '../../../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../../../routes';
import { ChannelType } from '../../../../../types';

type GateDialogConfigurationProps = {
  pushActive: boolean;
  selectedChannel: ChannelType | null;
  togglePush: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const GateDialogConfiguration: React.FC<GateDialogConfigurationProps> = ({
  pushActive,
  selectedChannel,
  togglePush,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('action');
  const { t: tf } = useTranslation('configuration');
  const { permissions } = useUserPermissions();
  const hasPermissions = permissions[UserPermissionType.ChannelConfiguration];

  return (
    <>
      <div className="row-container m-b-24">
        <p className="label">{t('events.push')}</p>
        <Switch checked={pushActive} onChange={togglePush} disabled={!hasPermissions} />
      </div>
      {hasPermissions && (
        <ArrowButton
          title={tf('barrierAlert.label')}
          onClick={() =>
            navigate(
              `${ROUTES.ChannelConfiguration(
                selectedChannel?.data.type,
                selectedChannel?.id,
              )}?tab=1&barrier-alert=true`,
            )
          }
          small
          dark
        />
      )}
    </>
  );
};
