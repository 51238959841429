import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindRemoteButtonState,
  CurrentWindThresholdState,
  FloodSensorState,
  GatePositionState,
  GatewayPositionState,
  HumiditySensorState,
  IDeviceStateData,
  MeasuredBrightnessState,
  MeasuredEnergyState,
  MeasuredTemperatureState,
  MovementSensorState,
  PressureSensorState,
  ReedState,
  RemoteButtonState,
  WindSpeedState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import {
  AstronomicalClockWithOffsetArgument,
  DeviceStateArgument,
  SunPosition,
} from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { ArgumentType, ConditionsTypes, ISequence } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { isEmpty } from 'lodash';
import { leadingZero } from '../../../../../utils/helpers';
import { useExalusSunTimes } from '../../../hooks/scenarios';
import { deviceSources } from '../../action-create/utils/action-sources';
import { getMeasurementParameters } from '../../action-create/utils/energy-parameters';
import { useActionParameterOptions } from '../../action-create/utils/use-action-parameter-options';
import { useActions } from '../../action-list/hooks/use-actions';
import { ConditionDetails, DeviceConditionState } from '../types';

export const useGetConditionParams = () => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('channel-details');
  const {
    daysOfWeek,
    sunPositions,
    stateDeviceTriggerOptions,
    reedOptions,
    windOptions,
    floodOptions,
    movementOptions,
    gateOptions,
    gatewayOptions,
    triggerButtonMethodOptions,
    conditionsTypes,
  } = useActionParameterOptions();
  const { data: sunTimes } = useExalusSunTimes();
  const { actions } = useActions();

  const getDeviceConditionState = (
    argument: DeviceStateArgument,
    argumentDeviceState?: IDeviceStateData,
  ): DeviceConditionState => {
    const icon = deviceSources.find((x) => x.deviceResponses.includes(argument.Type))?.icon || null;

    switch (argument.Type) {
      case DeviceResponseType.MeasuredTemperature: {
        return {
          icon,
          state: `${t('action.create.tasks.states.temperature').toLowerCase()} ${
            (argumentDeviceState as MeasuredTemperatureState).Temperature
          }°C`,
        };
      }
      case DeviceResponseType.RemoteButtonState: {
        return {
          icon,
          state: stateDeviceTriggerOptions
            .find((x) => x.value === (argumentDeviceState as RemoteButtonState).State)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.ReedState: {
        return {
          icon,
          state: reedOptions
            .find((x) => x.value === ((argumentDeviceState as ReedState).State ? 1 : 0))
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.PressureSensorState: {
        return {
          icon,
          state: `${t('action.create.tasks.states.pressure').toLowerCase()} ${
            (argumentDeviceState as PressureSensorState).Pressure
          }hPa`,
        };
      }
      case DeviceResponseType.WindSpeedState: {
        return {
          icon,
          state: `${t('action.create.tasks.states.windSpeed').toLowerCase()} ${
            (argumentDeviceState as WindSpeedState).Value
          }m/s`,
        };
      }
      case DeviceResponseType.CurrentWindThreshold: {
        return {
          icon,
          state: windOptions
            .find((x) => x.value === (argumentDeviceState as CurrentWindThresholdState).WindThreshold)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.HumiditySensorState: {
        return {
          icon,
          state: `${t('action.create.tasks.states.humidity').toLowerCase()}: ${
            (argumentDeviceState as HumiditySensorState).Humidity
          }%`,
        };
      }
      case DeviceResponseType.MeasuredBrightness: {
        return {
          icon,
          state: `${t('action.create.tasks.states.brightness').toLowerCase()} ${
            (argumentDeviceState as MeasuredBrightnessState).Brightness
          }lux`,
        };
      }
      case DeviceResponseType.FloodSensorState: {
        return {
          icon,
          state: floodOptions
            .find((x) => x.value === (argumentDeviceState as FloodSensorState).State)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.MovementSensorState: {
        return {
          icon,
          state: movementOptions
            .find((x) => x.value === (argumentDeviceState as MovementSensorState).Movement)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.GatePosition: {
        return {
          icon,
          state: gateOptions
            .find((x) => x.value === (argumentDeviceState as GatePositionState).PositionType)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.GatewayPosition: {
        return {
          icon,
          state: gatewayOptions
            .find((x) => x.value === (argumentDeviceState as GatewayPositionState).PositionType)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.BlindRemoteButtonState: {
        return {
          icon,
          state: triggerButtonMethodOptions
            .find((x) => x.value === (argumentDeviceState as BlindRemoteButtonState).State)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.FacadeRemoteButtonState: {
        return {
          icon,
          state: triggerButtonMethodOptions
            .find((x) => x.value === (argumentDeviceState as BlindRemoteButtonState).State)
            ?.label.toLowerCase(),
        };
      }
      case DeviceResponseType.MeasuredEnergy: {
        if (!isEmpty((argumentDeviceState as MeasuredEnergyState)?.MeasurementParameters)) {
          const params = getMeasurementParameters(argumentDeviceState);

          return {
            icon,
            state: `${tc(`exalus.energyMeasurements.types.${params.key}`).toLowerCase()} ${
              params.value ? `${params.value.toFixed(2)} ${params.unit ? `(${params.unit})` : ''}` : '-'
            }`,
          };
        }

        return {
          icon,
          state: '?',
        };
      }
      default: {
        return {
          icon,
          state: '',
        };
      }
    }
  };

  const trans = (key: string) => t(`action.create.conditions.${key}`);

  const getAstronomicalDescription = (argument: AstronomicalClockWithOffsetArgument, seq: ISequence) => {
    const astronomicalValue = `${sunPositions.find((x) => x.value === argument.Position)?.label.toLowerCase()} ${
      argument.Offset ? `${argument.Offset < 0 ? '-' : '+'}${argument.Offset / 60} min` : ''
    }`;

    if (seq.AtMeetCondition) {
      return `${trans('whenWillBe')} ${astronomicalValue}`;
    }

    let isIsItDescription = '';

    switch (seq.ConditionType) {
      case ConditionsTypes.SmallerThan: {
        isIsItDescription = 'ifBefore';
        break;
      }
      case ConditionsTypes.BiggerThan: {
        isIsItDescription = 'ifAfter';
        break;
      }
      case ConditionsTypes.BiggerThanOrEqual: {
        isIsItDescription = 'ifAfterOrEqual';
        break;
      }
      case ConditionsTypes.SmallerThanOrEqual: {
        isIsItDescription = 'ifBeforeOrEqual';
        break;
      }
      case ConditionsTypes.Equal: {
        isIsItDescription = 'ifEqual';
        break;
      }
      case ConditionsTypes.NotEqueal: {
        isIsItDescription = 'ifNotEqual';
        break;
      }
    }

    return `${
      isIsItDescription ? `${t(`action.create.conditions.conditionTypes.${isIsItDescription}`)}: ` : ''
    }${astronomicalValue}`;
  };

  const getConditionParams = (seq: ISequence, channels?: IDeviceChannel[]): ConditionDetails | null => {
    const timeSec = seq.ConditionTimeout;
    const conditionType = conditionsTypes.find((x) => x.value === (seq.ConditionType as ConditionsTypes))?.label;

    switch (seq.LeftArgumentType) {
      case ArgumentType.ArgumentAsDaysOfWeek: {
        const argument = seq.LeftArgument.Argument?.ArgumentAsDaysOfWeek;
        if (argument) {
          return {
            description: `${
              seq.AtMeetCondition ? trans('whenWillBe') : trans('ifItIs')
            } ${conditionType?.toLowerCase()} ${trans('oneOf').toLowerCase()}: ${daysOfWeek
              .filter((x) => argument.find((y) => y === x.value))
              .map((x) => x.label.toLowerCase())
              .join(', ')}`,
          } as ConditionDetails;
        }

        return null;
      }
      case ArgumentType.ArgumentAsTimeSpan: {
        const argument = seq.LeftArgument.Argument?.ArgumentAsTimeSpan;
        if (argument) {
          return {
            description: t('action.create.conditions.triggerAt', {
              days: argument.days,
              hours: argument.hours,
              minutes: argument.minutes,
              seconds: argument.seconds,
            }),
          } as ConditionDetails;
        }

        return null;
      }
      case ArgumentType.ArgumentAsTime: {
        const argument = seq.LeftArgument.Argument?.ArgumentAsTime;
        if (argument) {
          return {
            description: `${
              seq.AtMeetCondition ? trans('whenWillBe') : trans('ifItIs')
            } ${conditionType?.toLowerCase()} ${[argument.Hour, argument.Minute, argument.Second]
              .map(leadingZero)
              .join(':')}`,
          } as ConditionDetails;
        }

        return null;
      }
      case ArgumentType.ArgumentAsAstronomicalClockWithOffset: {
        const argument = seq.LeftArgument.Argument?.ArgumentAsAstronomicalClockWithOffset;
        if (argument) {
          return {
            description: getAstronomicalDescription(argument, seq),
            secondLine: `${trans('nextAt')}:  ${
              sunTimes
                ? argument.Position === SunPosition.SunRise
                  ? format(sunTimes.sunRiseTime.getTime() + (argument.Offset / 60) * 60000, 'HH:mm, dd.MM')
                  : format(sunTimes.sunSetTime.getTime() + (argument.Offset / 60) * 60000, 'HH:mm, dd.MM')
                : ''
            }`,
          } as ConditionDetails;
        }

        return null;
      }
      case ArgumentType.ArgumentAsAstronomicalClock: {
        const argument = seq.LeftArgument.Argument?.ArgumentAsAstronomicalClock;
        if (argument) {
          return {
            description: '',
          } as ConditionDetails;
        }

        return null;
      }
      case ArgumentType.ArgumentAsDeviceState: {
        const argument = seq.LeftArgument.Argument?.ArgumentAsDeviceState;
        if (argument) {
          const argumentDeviceState = seq.LeftArgument.Argument?.ArgumentAsDeviceState.GetCheckDeviceState();
          const foundChannel = (channels || []).find(
            (c) =>
              c.GetDevice().Guid === seq.LeftArgument.Argument?.ArgumentAsDeviceState.DeviceGuid &&
              c.Number === argumentDeviceState?.Channel,
          );

          const deviceConditionState = getDeviceConditionState(argument, argumentDeviceState);

          if (foundChannel && argumentDeviceState) {
            return {
              title: foundChannel.Name,
              icon: deviceConditionState.icon,
              description: `${
                seq.AtMeetCondition ? trans('whenWillBe') : trans('ifItIs')
              } ${conditionType?.toLowerCase()}: ${deviceConditionState.state || ''} ${timeSec ? `+ ${timeSec}s` : ''}`,
            } as ConditionDetails;
          }
        }

        return null;
      }
      case ArgumentType.ArgumentAsConditionInfo: {
        const argument = seq?.LeftArgument.Argument?.ArgumentAsConditionInfo;

        if (argument) {
          const found = actions?.find((x) => x.DeviceGuid === argument.DeviceGuid);

          if (found) {
            return {
              description: found.Name,
            } as ConditionDetails;
          }
        }

        return null;
      }
      default: {
        return null;
      }
    }
  };

  return {
    getConditionParams,
  };
};
