import { gql } from '@apollo/client';

export const PUSH_REGISTRATIONS = gql`
  query PushReceiversRegistrations {
    allPushReceiversRegistrations {
      id
      name
      pushReceiverType
      token
    }
  }
`;

export const BLUETOOTH_LOGGING = gql`
  query BluetoothLoggingEnabled($installationId: UUID!) {
    bluetoothLoggingEnabled(installationId: $installationId)
  }
`;
