import { cloneDeep } from 'lodash';
import {
  RequestAckPayload,
  DeviceConnectionStateChangePayload,
  DeviceFirmwareUpdatedPayload,
  RecoveryDeviceStatusRecievedPayload,
} from '../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useChannelsStateStore } from '../../../store/channels-state';

export const useUpdateCommonLavvaState = () => {
  const { channelList, setDeviceList, updateDeviceDetails } = useDevicesAndChannelsContext();
  const setChannelState = useChannelsStateStore((s) => s.setChannelState);
  const setAllChannelsState = useChannelsStateStore((s) => s.setAllChannelsState);

  const onRequestAckReceived = (receivedData: RequestAckPayload, callback?: () => void) => {
    if (receivedData.status === 'TIMEOUT') {
      if (callback) callback();
    }
  };

  const onDeviceFirmwareUpdated = (receivedData: DeviceFirmwareUpdatedPayload) => {
    setDeviceList((prev) => {
      const tempList = cloneDeep(prev);
      const index = tempList.findIndex((device) => device?.id === receivedData.deviceId);

      if (index !== -1) {
        tempList[index].isUpdating = false;
        tempList[index].payload.newestFirmwareVersion = '';
        tempList[index].payload.currentFirmwareVersion = receivedData.currentFirmwareVersion;
        tempList[index].payload.isUpdateAvailable = false;
      }

      return tempList;
    });

    updateDeviceDetails((prev) => {
      const temp = cloneDeep(prev);
      if (temp) {
        temp.isUpdating = false;
        temp.payload.newestFirmwareVersion = '';
        temp.payload.currentFirmwareVersion = receivedData.currentFirmwareVersion;
        temp.payload.isUpdateAvailable = false;
      }
      return temp;
    });
  };

  const setDeviceAsUpdating = (deviceId: string) => {
    setDeviceList((prev) => {
      const tempList = cloneDeep(prev);
      const index = tempList.findIndex((device) => device?.id === deviceId);
      if (index !== -1) tempList[index].isUpdating = true;

      return tempList;
    });

    updateDeviceDetails((prev) => {
      const temp = cloneDeep(prev);
      if (temp) temp.isUpdating = true;
      return temp;
    });
  };

  const onRecoveryFirmwareUpdated = (receivedData: RecoveryDeviceStatusRecievedPayload) => {
    setDeviceList((prev) => {
      const tempList = cloneDeep(prev);
      const index = tempList.findIndex((device) => device?.id === receivedData.deviceId);

      if (index !== -1) {
        tempList[index].recoveryMode = {
          status: receivedData.recoveryDeviceStatus,
          progress: receivedData.progress || undefined,
        };
      }

      return tempList;
    });

    updateDeviceDetails((prev) => {
      const temp = cloneDeep(prev);
      if (temp) {
        temp.recoveryMode = {
          status: receivedData.recoveryDeviceStatus,
          progress: receivedData.progress || undefined,
        };
      }
      return temp;
    });
  };

  const onDeviceConnectionStateChange = (deviceStateData: DeviceConnectionStateChangePayload) => {
    const newChannelList = Array.from(channelList);
    const channelsToUpdate = newChannelList.filter((channel) => channel?.deviceId === deviceStateData.deviceId);
    const states = {};

    for (let i = 0; i < channelsToUpdate.length; i++) {
      states[channelsToUpdate[i].id] = {
        deviceConnectionState: deviceStateData.deviceConnectionState,
      };
    }

    setAllChannelsState(states);
  };

  const usesDepleted = (id: string) => {
    if (id) {
      setChannelState(id, { grantedUses: 0, usesCounter: 0 });
    }
  };

  return {
    onRequestAckReceived,
    onDeviceConnectionStateChange,
    usesDepleted,
    onDeviceFirmwareUpdated,
    onRecoveryFirmwareUpdated,
    setDeviceAsUpdating,
  };
};
