import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, IconAdd, NavHeader, Page, SubmitButton, Tabs } from '../../../../components';
import { Tip } from '../../../../components/tip';
import { useInstallationContext } from '../../../../context/installation';
import { LearningScenarios } from '../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../routes';
import ExalusDevices from '../../components/devices';
import { useExalusStorageContext } from '../../context/storage';
import { useLoggedUser } from '../../hooks/common/logged-user';
import { UpdateDevices } from './components/update-devices';
import { useDeviceListContext } from './context';
import { useDevices } from './hooks/use-devices';
import { useDevicesUpdatesAvailability } from './hooks/use-devices-updates-availability';

const DeviceList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('device-list');
  const { selectedInstallation } = useInstallationContext();
  const { activeDeviceTabIndex, setActiveDeviceTabIndex } = useExalusStorageContext();
  const { canAddRemoveConfigureDevice } = useLoggedUser();
  const { updatesAvailability } = useDevicesUpdatesAvailability();
  const { updateMode, selectedDeviceIds, setShowUpdateAllDialog } = useDeviceListContext();
  const { groupedDevices, isFetched } = useDevices({ updateMode, updatesAvailability });

  return (
    <Page
      isStickyHeader
      containerClassName="p-t-32"
      header={
        <>
          <NavHeader>
            {canAddRemoveConfigureDevice && (
              <button onClick={() => navigate(ROUTES.AddExalusDevices())}>
                <IconAdd />
              </button>
            )}
            <UpdateDevices />
          </NavHeader>
          <Header title={t('devices')} />
          {selectedInstallation && (
            <Tabs
              tabList={groupedDevices}
              activeTabIndex={activeDeviceTabIndex}
              setActiveTabIndex={setActiveDeviceTabIndex}
              tabsType="pills"
              moreButton="normal"
              moreListFullHeight
            />
          )}
        </>
      }
    >
      <ExalusDevices itemGroupsList={groupedDevices} isFetched={isFetched} />
      <Tip tip={t('DeviceAddTip')} learningKey={LearningScenarios.AddDevice} point={<IconAdd />} top={26} right={20} />
      {updateMode && updatesAvailability.length > 0 && (
        <SubmitButton disabled={!selectedDeviceIds.length} onClick={() => setShowUpdateAllDialog(true)}>
          {t('update')}
        </SubmitButton>
      )}
    </Page>
  );
};

export default DeviceList;
