import { useEffect, useState } from 'react';
import { useInstallationContext } from '../../../../../context/installation';
import { useNativeFunctionsContext } from '../../../../../context/native-functions';
import { UserGroup, UserPermissionType } from '../../../../../data-access/gql-types/graphql';
import { useRefetchData } from '../../../../../hooks/refresh-data';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useApi } from '../../../hooks';
import * as storage from '../../../storage';

export const useDashboard = () => {
  const { groups } = useApi();
  const { installationList, selectedInstallation } = useInstallationContext();
  const { nativeAppInstalledPopup } = useNativeFunctionsContext();
  const { channelList } = useDevicesAndChannelsContext();
  const { permissions } = useUserPermissions();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isListView, setIsListView] = useState<boolean>(storage.getItem('isListView'));
  const { refetchAll } = useRefetchData();

  useEffect(() => {
    const index = groups.findIndex((group: UserGroup) => group.id === storage.getItem('lastActiveGroupId'));
    setActiveTabIndex(index < 0 ? 0 : index);
  }, [groups]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!installationList.length) refetchAll();
    }, 300);

    return () => clearTimeout(timeout);
  }, [installationList.length]);

  const toggleListView = () => {
    storage.setItem('isListView', !isListView);
    setIsListView(!isListView);
  };

  const handleSetActiveGroupIndex = (index: number) => {
    setActiveTabIndex(index);
    storage.setItem('lastActiveGroupId', String(groups[index]?.id));
  };

  return {
    isListView,
    activeTabIndex,
    activeGroup: groups[activeTabIndex],
    isCarouselContent: !!(installationList.length !== 0 || selectedInstallation),
    groupTabsVisible: selectedInstallation && channelList.length > 0,
    updateDeviceDialogVisible: !nativeAppInstalledPopup && permissions[UserPermissionType.DeviceList],
    noInstallationSelected: !selectedInstallation,
    toggleListView,
    handleSetActiveGroupIndex,
  };
};
