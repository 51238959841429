import { useOptimizerSetMode, useOptimizerSetTestModeLoad } from '../../../../../api/optimizer/optimizer.hooks';
import { OptimizerModeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../../../context/backdrop';
import { ChannelType } from '../../../../../types';
import { getEnumKeyByValue } from '../../../../../../../utils/helpers';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { TestFormBody } from '../types';

export const useOptimizerTest = (channel: ChannelType) => {
  const { mutate: setMode } = useOptimizerSetMode();
  const { mutate: setModeLoad } = useOptimizerSetTestModeLoad();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { clearErrors } = useOptimizerConfigurationContext();

  const submitConfiguration = (mode: OptimizerModeInternal, formValues: TestFormBody) => {
    clearErrors();
    turnOnBackdrop();

    setMode(
      {
        deviceId: channel.deviceId,
        channelId: channel.id,
        mode: getEnumKeyByValue(OptimizerModeInternal, mode),
      },
      {
        onSuccess: ({ data }) => {
          if (data) {
            turnOnBackdrop();

            setModeLoad(
              {
                deviceId: channel.deviceId,
                channelId: channel.id,
                scr: [formValues.phase1, formValues.phase2, formValues.phase3],
              },
              {
                onSuccess: () => {
                  turnOffBackdrop();
                },
              },
            );
          } else {
            turnOffBackdrop();
          }
        },
      },
    );
  };

  return {
    submitConfiguration,
  };
};
