import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateBox, Header, IconWarning, NavHeader, Page } from '../..';

type ActionTasksVariantsProps = {
  children: ReactNode[];
  listCount: number;
};

const ActionTasksVariants: React.FC<ActionTasksVariantsProps> = ({ children, listCount }) => {
  const { t } = useTranslation('action');

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.task')} />
        </>
      }
    >
      <div className="grid-list-24">
        {listCount ? (
          children
        ) : (
          <EmptyStateBox
            header={t('action.create.tasks.noVariants.header')}
            content={t('action.create.tasks.noVariants.content')}
            icon={<IconWarning />}
          />
        )}
      </div>
    </Page>
  );
};

export default ActionTasksVariants;
