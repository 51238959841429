import React, { useEffect, useState } from 'react';
import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceControlFeature } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { SetLightBrightness } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, LightSlider, SubmitButton } from '../../../../../../../../components';
import { useExalusCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, DimStateTask } from '../../../../../../../../types/actions';

export const DimSettings: React.FC = () => {
  const { channels } = useExalusCreateActionContext();
  const [brightness, setBrightness] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      setBrightness(((task.taskParams as DeviceTaskParams).state as DimStateTask).brightness);
    }
  }, [task]);

  const handleChangeBrightness = (value: number) => setBrightness(value);

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task = new SetLightBrightness();
      task.DeviceGuid = channels[i].GetDevice().Guid;
      task.Channel = channels[i].Number;
      task.TaskType = DeviceTaskType.SetLightBrightness;
      task.FeatureType = DeviceControlFeature.SetLightBrightness;
      task.Brightness = brightness;

      finalizeDeviceTaskSubmit(task, channels[i], { brightness } as DimStateTask, i === channels.length - 1);
    }
  };

  return (
    <>
      <ControlWrapper>
        <LightSlider value={brightness} onChange={handleChangeBrightness} />
      </ControlWrapper>
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
