import React from 'react';
import {
  MeasurementChannelKind,
  VectorMeasurementResponseItem,
  VectorParameterTypeInternal,
} from '../../../../../../../../../data-access/gql-types/graphql';
import { useApi } from '../../../../../../../hooks';
import { measurementParameters } from '../../../../../measurements/utils';
import { EnergyConsumptionSummaryElement } from './element';
import './index.scss';

type EnergyConsumptionSummaryProps = {
  measurements: VectorMeasurementResponseItem[];
  itemPrefix?: string;
};

export const EnergyConsumptionSummary: React.FC<EnergyConsumptionSummaryProps> = ({ measurements, itemPrefix }) => {
  const { convertNumberToMeasurement } = useApi();

  return (
    <ul className="energy-consumption-summary">
      {measurements.map((phase, index) => {
        const parameter = convertNumberToMeasurement(MeasurementChannelKind.Meter)('vectors', phase.type);
        const isGreaterUnit = parameter === VectorParameterTypeInternal.ActivePower && Math.abs(phase.value) > 1000;

        return (
          <EnergyConsumptionSummaryElement
            key={index}
            value={(phase.value / (isGreaterUnit ? 1000 : 1)).toFixed(2)}
            index={index}
            itemPrefix={itemPrefix}
            divider={index !== measurements.length - 1}
            unit={isGreaterUnit ? measurementParameters[parameter].unit2 : measurementParameters[parameter].unit}
          />
        );
      })}
    </ul>
  );
};
