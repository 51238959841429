import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../components';
import './index.scss';

type PageStartFlowProps = {
  handleStart: () => void;
  icon: React.ReactNode;
  title: string;
  subTitile?: string;
  headerTitle?: string;
  isIconClose?: boolean;
};

export const PageStartFlow: React.FC<PageStartFlowProps> = ({
  handleStart,
  icon,
  headerTitle,
  isIconClose,
  title,
  subTitile,
}) => {
  const { t } = useTranslation('common');

  return (
    <Page
      className="page-start-flow"
      containerClassName="page__container--no-padding-top"
      whiteContent
      isStickyHeader
      header={<NavHeader isIconClose={isIconClose} {...(headerTitle ? { title: headerTitle } : {})} />}
    >
      <div className="center-content">
        {icon}
        <Header title={title} className="m-t-32 m-b-24" />
        {subTitile && <p className="sub-header m-b-24">{subTitile}</p>}
        <button className="button max-width-desktop" onClick={handleStart}>
          {t('buttons.start')}
        </button>
      </div>
    </Page>
  );
};
