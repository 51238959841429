import React, { useMemo } from 'react';
import {
  DeviceTaskType as ChannelTaskType,
  IDeviceTaskTypeInfo as IChannelTaskTypeInfo,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper } from '../../../../../../../components';
import { BlindDetailsPositionControlButtons } from '../../../../control-buttons/blind-details/position';
import { BlindDetailsSimpleControlButtons } from '../../../../../../../components/channel-details-controls/blind-simple';
import { useCentralActionBlindControl } from '../../../hooks/use-central-action-blind-control';

type CentralActionBlindDetailsControlsBoxProps = {
  channels: IDeviceChannel[];
};

export const CentralActionBlindDetailsControlsBox: React.FC<CentralActionBlindDetailsControlsBoxProps> = ({
  channels,
}) => {
  const { open, close, stop, handleAction, handleSliderEvent, handleMicroventilation } =
    useCentralActionBlindControl(channels);

  const isViewSimple = useMemo(
    () =>
      channels.some((channel) => {
        return (
          channel.AvailableTaskTypes?.any(
            (a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetBlindPositionSimple,
          ) ||
          channel
            ?.GetDevice()
            .AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetBlindPositionSimple)
        );
      }),
    [channels],
  );

  return (
    <>
      {isViewSimple ? (
        <ControlWrapper>
          <BlindDetailsSimpleControlButtons close={close} stop={stop} open={open} />
        </ControlWrapper>
      ) : (
        <BlindDetailsPositionControlButtons
          close={close}
          stop={stop}
          open={open}
          handleAction={handleAction}
          handleSliderEvent={handleSliderEvent}
          handleMicroventilation={handleMicroventilation}
        />
      )}
    </>
  );
};
