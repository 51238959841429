import { GateKindInternal } from '../../../../../../../../data-access/gql-types/graphql';
import { ChannelType } from '../../../../../../types';
import { useGateSupportedFeatures } from '../../../hooks/use-gate-supported';
import { DefaultKindControl } from './default';
import { LockStatusControl } from './lock-status';

type GateKindsControlProps = {
  gateKind: GateKindInternal;
  channel: ChannelType;
  handleAction: () => void;
};

export const GateKindsControl: React.FC<GateKindsControlProps> = ({ gateKind, channel, handleAction }) => {
  const { lockReportingModeSupported } = useGateSupportedFeatures(channel);

  switch (gateKind) {
    default: {
      return lockReportingModeSupported ? (
        <LockStatusControl channel={channel} handleAction={handleAction} />
      ) : (
        <DefaultKindControl channel={channel} handleAction={handleAction} />
      );
    }
  }
};
