import React from 'react';
import * as uuid from 'uuid';
import { VentilationChannelSettings } from '../../../../../../../../components/action-create/channel-settings/ventilation';
import { useLavvaCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, SceneTaskType, Task } from '../../../../types';

export const VentilationSettings: React.FC = () => {
  const { channels, taskVariant } = useLavvaCreateActionContext();
  const { finalizeDeviceTaskSubmit } = useTaskEdit();

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task: Task = {
        id: uuid.v4(),
        taskType: SceneTaskType.ChannelState,
        taskParams: {
          state: {},
          channel: channels[i],
          variant: taskVariant,
        } as DeviceTaskParams,
      };

      finalizeDeviceTaskSubmit(task, i === channels.length - 1);
    }
  };

  return <VentilationChannelSettings handleSubmit={handleSubmit} />;
};
