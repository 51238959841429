import { InitiateButton } from '../../../../../../../../../components';
import { useGate } from '../../../../../../../hooks';
import { useChannelsStateStore } from '../../../../../../../store/channels-state';
import { ChannelGateStateType, ChannelType } from '../../../../../../../types';
import { getGateStep } from '../../../../../../../utils/channels/helpers/gate';
import { GateStepIcon } from '../../../gate-step-icon';

type DefaultKindControlProps = {
  channel: ChannelType;
  handleAction: () => void;
};

export const DefaultKindControl: React.FC<DefaultKindControlProps> = ({ channel, handleAction }) => {
  const { gateActive } = useGate(channel);
  const state = useChannelsStateStore((state) => state.channelState[channel.id]) as ChannelGateStateType | null;
  const gateStep = getGateStep(state, false);

  return (
    <InitiateButton isOn={false} onClickControl={handleAction} active={gateActive}>
      <GateStepIcon step={gateStep} />
    </InitiateButton>
  );
};
