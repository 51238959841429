import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { ACCENT_COLOR_DEFAULT_INDEX_MAP, INSTALLATION_COLOR_LIST } from '../const';
import {
  CurrentInstallationQueryVariables,
  InstallationGetAllQueryVariables,
  IntegrationType,
  Query,
  SelectInstallationMutation,
  SelectInstallationMutationVariables,
  UserInstallation,
} from '../data-access/gql-types/graphql';
import { SELECT_INSTALLATION } from '../data-access/mutations/installations';
import { CURRENT_INSTALLATION, INSTALLATION_GET_ALL } from '../data-access/queries/installations';
import { ON_INSTALLATION_DETACH } from '../data-access/subscriptions/on-installation-detach';
import { environment } from '../environment';
import { useSentry } from '../hooks';
import { useExalusContext } from '../integrations/exalus/context';
import { useExalusConnection } from '../integrations/exalus/hooks';
import { useExalusMigrationData } from '../integrations/exalus/hooks/common/use-migration-data';
import { getExalusInstallationCredentials } from '../integrations/exalus/utils';
import * as storage from '../integrations/lavva/storage';
import { ChildrenProps, InstallationState } from '../types';
import { isLocalApp } from '../utils/helpers/local-app';
import { setBodyStyle } from '../utils/theme';
import { toastError } from '../utils/toast';
import { useBackdropContext } from './backdrop';
import { useGraphqlContext } from './graphql';

const initialState: InstallationState = {
  selectedInstallationId: window.localStorage.getItem('selectedInstallationId') || undefined,
  setSelectedInstallationId: () => null,
  installationList: [],
  updateInstallationList: () => null,
  selectedInstallation: undefined,
  installationsLoading: false,
  skipLavvaFetch: false,
  hardRedirect: false,
  currentInstallationRefetch: () => null,
  selectInstallation: () => null,
  integrationType: IntegrationType.Undefined,
};

export const InstallationContext = createContext(initialState);
export const useInstallationContext = (): InstallationState => useContext(InstallationContext);

const InstallationContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const { t: tc } = useTranslation('common');
  const [installationList, setInstallationList] = useState(initialState.installationList);
  const [selectedInstallation, setSelectedInstallation] = useState(initialState.selectedInstallation);
  const [selectedInstallationId, setCurrentInstallationId] = useState(initialState.selectedInstallationId);
  const [integrationType, setIntegrationType] = useState<IntegrationType>(initialState.integrationType);
  const { setSelectedExalusCredentials } = useExalusContext();
  const { goToInstallationList, goToExalusLogin } = useExalusConnection();
  const { setSentryTags } = useSentry();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { updateBrokerUrl } = useGraphqlContext();
  const { checkIfExalusMigrationAvailableLocal, checkIfExalusMigrationAvailable } = useExalusMigrationData();
  const [changeInstallation] = useMutation<SelectInstallationMutation, SelectInstallationMutationVariables>(
    SELECT_INSTALLATION,
  );
  const { data: installationsData, loading: installationsLoading } = useQuery<Query, InstallationGetAllQueryVariables>(
    INSTALLATION_GET_ALL,
    {
      fetchPolicy: 'cache-and-network',
      // onError: () => toastError({ content: tc('errors.downloadData') }),
      skip: isLocalApp,
    },
  );
  const {
    data: currentInstallationData,
    refetch: currentInstallationRefetch,
    loading: currentInstallationLoading,
  } = useQuery<Query, CurrentInstallationQueryVariables>(CURRENT_INSTALLATION, {
    fetchPolicy: 'no-cache',
    // onError: () => toastError({ content: tc('errors.downloadData') }),
    skip: isLocalApp,
  });

  const { data: installationDetachData } = useSubscription(ON_INSTALLATION_DETACH, {
    variables: { installationId: selectedInstallationId },
    skip: !selectedInstallationId,
  });

  const updateInstallationList = (list: UserInstallation[]) => {
    setInstallationList(list);
    storage.setLocalItem(
      'installationList',
      list.filter((x) => x.integrationType === IntegrationType.Exalus),
    );
  };

  const localAppInstallationsSets = async () => {
    turnOnBackdrop();
    await checkIfExalusMigrationAvailableLocal();
    const localInstallations = storage.getLocalItem('installationList');

    if (localInstallations?.length) {
      const installationId = localStorage.getItem('selectedInstallationId');

      if (installationId) {
        const found = localInstallations.find((x) => x.installationId === installationId);

        if (found) setSelectedInstallationId(found.installationId);
        else {
          goToInstallationList();
        }
      } else {
        setSelectedInstallationId(localInstallations[0].installationId);
      }
      updateInstallationList(localInstallations);
      turnOffBackdrop();
    } else {
      goToExalusLogin();
      turnOffBackdrop();
    }
  };

  useEffect(() => {
    if (isLocalApp) localAppInstallationsSets();
  }, []);

  useEffect(() => {
    if (
      installationDetachData?.onInstallationDetach?.newBrokerUrl &&
      installationDetachData?.onInstallationDetach?.installationId
    ) {
      if (selectedInstallationId === installationDetachData?.onInstallationDetach?.installationId) {
        updateBrokerUrl(installationDetachData?.onInstallationDetach?.newBrokerUrl);
      }
    }
  }, [installationDetachData]);

  const authorizedAppInstallationsSet = async (userInstallations: UserInstallation[]) => {
    turnOnBackdrop();
    const list = (await checkIfExalusMigrationAvailable(userInstallations)) as UserInstallation[];
    updateInstallationList(list);
    turnOffBackdrop();

    const foundSelected = userInstallations.find((x) => x.isSelected);
    setSelectedInstallationId(foundSelected?.installationId);
  };

  useEffect(() => {
    if (installationsData?.allUserInstallations) {
      authorizedAppInstallationsSet(installationsData.allUserInstallations);
    }
  }, [installationsData]);

  useEffect(() => {
    if (currentInstallationData?.currentInstallation?.brokerUrl) {
      updateBrokerUrl(currentInstallationData.currentInstallation.brokerUrl);
    }
  }, [currentInstallationData, selectedInstallationId]);

  useEffect(() => {
    currentInstallationRefetch();
  }, [selectedInstallationId]);

  useEffect(() => {
    const foundInstallation = installationList.find((x) => x.installationId === selectedInstallationId);
    setSelectedInstallation(
      foundInstallation
        ? { ...foundInstallation, payload: currentInstallationData?.currentInstallation?.payload }
        : undefined,
    );
    setIntegrationType(foundInstallation?.integrationType || IntegrationType.Undefined);

    setSentryTags({
      installationId: foundInstallation?.installationId || '-',
      installationName: foundInstallation?.name || '-',
      integrationType: foundInstallation?.integrationType || '-',
      version: environment.VERSION || '-',
    });

    if (foundInstallation) {
      if (foundInstallation?.integrationType === IntegrationType.Exalus) {
        const credentials = getExalusInstallationCredentials(foundInstallation.installationId);

        if (credentials) setSelectedExalusCredentials(credentials);
        else goToInstallationList();
      }
    }
  }, [selectedInstallationId, installationList, currentInstallationData]);

  const hardRedirect = useMemo(
    () => installationList.filter((x) => x.integrationType === IntegrationType.Exalus).length > 1,
    [installationList],
  );

  useEffect(() => {
    if (!selectedInstallation) return;

    setBodyStyle(
      '--color-accent',
      selectedInstallation.hexColor ||
        INSTALLATION_COLOR_LIST[ACCENT_COLOR_DEFAULT_INDEX_MAP[environment.INTEGRATION_DEFAULT]],
    );
  }, [selectedInstallation?.hexColor]);

  const setSelectedInstallationId = (installationId: string | undefined) => {
    setCurrentInstallationId(installationId);

    if (installationId) window.localStorage.setItem('selectedInstallationId', installationId);
    else window.localStorage.removeItem('selectedInstallationId');
  };

  const selectInstallation = (installationId: string | undefined, onSuccess: () => void) => {
    turnOnBackdrop();

    changeInstallation({
      variables: { installationId },
      onCompleted: (data) => {
        if (data?.selectInstallation) {
          if (data.selectInstallation.brokerUrl) updateBrokerUrl(data.selectInstallation.brokerUrl);
          setSelectedInstallationId(data.selectInstallation.installationId || undefined);
          onSuccess();
        } else {
          turnOffBackdrop();
          toastError({ content: tc('errors.somethingWentWrong') });
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const skipLavvaFetch =
    !selectedInstallationId ||
    integrationType === null ||
    integrationType === IntegrationType.Exalus ||
    integrationType === IntegrationType.Undefined;

  const values: InstallationState = {
    selectedInstallationId,
    selectedInstallation,
    installationList,
    installationsLoading: installationsLoading || currentInstallationLoading,
    setSelectedInstallationId,
    updateInstallationList,
    currentInstallationRefetch,
    selectInstallation,
    hardRedirect,
    integrationType,
    skipLavvaFetch,
  };

  return <InstallationContext.Provider value={values}>{children}</InstallationContext.Provider>;
};

export default InstallationContextProvider;
