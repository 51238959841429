import React, { useState } from 'react';
import {
  DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult,
  DeviceTaskPairExecutionResult,
} from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, Slider } from '../../../../../../components';
import { BlindDetailsControls } from '../../../../../../components/channel-details-controls/blind/controls';
import { BlindDetailsFavourites } from '../../../../../../components/channel-details-controls/blind/favourites';
import { defaultFavouritePositionList } from '../../../../../../const';

type BlindDetailsPositionControlButtonsProps = {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  handleAction: (action, position?: number) => Promise<ChannelTaskPairExecutionResult[]>;
  handleSliderEvent: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleMicroventilation: (position: number) => Promise<DeviceTaskPairExecutionResult[]>;
};

export const BlindDetailsPositionControlButtons: React.FC<BlindDetailsPositionControlButtonsProps> = ({
  close,
  stop,
  open,
  handleAction,
  handleSliderEvent,
  handleMicroventilation,
}) => {
  const [sliderValue, setSliderValue] = useState(50);

  const onFavouriteClick = (position: number) => {
    setSliderValue(position);
    handleAction(BlindActionEnum.Percentage, position);
  };

  return (
    <>
      <div className="cover-detail-view">
        <ControlWrapper>
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            showValue={false}
          />
        </ControlWrapper>
        <BlindDetailsControls
          downButton={{
            handleClick: close,
          }}
          stopButton={{
            handleClick: stop,
          }}
          upButton={{
            handleClick: open,
          }}
        />
      </div>

      <BlindDetailsFavourites
        favouritePositionList={defaultFavouritePositionList}
        onFavouriteClick={onFavouriteClick}
        microventilation={{
          position: 91,
          handleMicroventilation: () => handleMicroventilation(91),
        }}
      />
    </>
  );
};
