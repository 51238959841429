import React from 'react';
import classNames from 'classnames';
import { CoverButton } from '../../../cover-button';
import { IconFacadeBlind, IconFavorite, IconWoodenBlind } from '../../../icons';
import './index.scss';
import { BlindDetailsMicroventilation, BlindDetailsMicroventilationProps } from './microventilation';

type BlindDetailsFavouritesProps = {
  favouritePositionList: number[];
  onFavouriteClick: (position: number) => void;
  onFavouriteHold?: (index: number) => void;
  lastPosition?: number;
  disabledPadding?: boolean;
  icon?: 'blind' | 'facade' | 'star';
  microventilation?: BlindDetailsMicroventilationProps;
};

export const BlindDetailsFavourites: React.FC<BlindDetailsFavouritesProps> = ({
  onFavouriteClick,
  favouritePositionList,
  onFavouriteHold,
  lastPosition,
  disabledPadding,
  icon = 'star',
  microventilation,
}) => {
  const renderIcon = (position: number) => {
    switch (icon) {
      case 'facade':
        return <IconFacadeBlind size={22} tilt={50} position={position} className="icon-favorite" />;
      case 'blind':
        return <IconWoodenBlind position={position} />;
      default:
        return <IconFavorite />;
    }
  };

  return (
    <div className={classNames('blind-details__favourites', { 'disable-padding': disabledPadding })}>
      <div className="positions">
        {[0, 1, 2].map((index) => {
          const position = favouritePositionList[index];

          return (
            <CoverButton
              key={index}
              position={position}
              lastPosition={lastPosition}
              onClick={() => onFavouriteClick(position)}
              onHold={() => onFavouriteHold?.(index)}
            >
              {renderIcon(position)}
              <span>{position}%</span>
            </CoverButton>
          );
        })}
      </div>
      {microventilation && (
        <BlindDetailsMicroventilation
          position={microventilation.position}
          lastPosition={microventilation.lastPosition}
          handleMicroventilation={microventilation.handleMicroventilation}
          onHold={microventilation.onHold}
        />
      )}
    </div>
  );
};
