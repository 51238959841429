import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { Api } from 'lavva.exalushome';
import { NativeWebViewFunctions } from 'lavva.webview';
import { CommonService } from 'lavva.webview/build/js/Services/Common/CommonService';
import { ICommonService, NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { INotificationsService } from 'lavva.webview/build/js/Services/Notifications/INotificationsService';
import { NotificationsService } from 'lavva.webview/build/js/Services/Notifications/NotificationService';
import { IThemesService, Theme } from 'lavva.webview/build/js/Services/Themes/IThemesService';
import { ThemesService } from 'lavva.webview/build/js/Services/Themes/ThemesService';
import { environment } from '../environment';
import { ChildrenProps } from '../types';

const initialState: UseNativeFunctionsState = {
  getNativeContext: async () => null,
  getNotificationToken: () => null,
  setNativeTheme: () => null,
  nativeAppInstalledPopup: false,
  setNativeAppInstalledPopup: () => null,
  nativeInit: false,
  notificationApiSupported: false,
  nativeCtx: null,
  checkNotificationPermission: async () => false,
};

export const NativeFunctionsContext = createContext<UseNativeFunctionsState>(initialState);
export const useNativeFunctionsContext = (): UseNativeFunctionsState => useContext(NativeFunctionsContext);

export type UseNativeFunctionsState = {
  getNativeContext: () => Promise<NativeContext | null>;
  getNotificationToken: any;
  setNativeTheme: (theme: Theme) => void;
  nativeAppInstalledPopup: boolean;
  setNativeAppInstalledPopup: Dispatch<SetStateAction<boolean>>;
  nativeInit: boolean;
  notificationApiSupported: boolean;
  nativeCtx: NativeContext | null;
  checkNotificationPermission: () => Promise<boolean>;
};

const NativeFunctionsProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [nativeAppInstalledPopup, setNativeAppInstalledPopup] = useState<boolean>(false);
  const [nativeInit, setNativeInit] = useState<boolean>(false);
  const [nativeCtx, setNativeCtx] = useState<NativeContext | null>(null);
  const notificationApiSupported = 'Notification' in window;

  const checkIfNativeAppIsInstalled = async () => {
    if ('getInstalledRelatedApps' in window.navigator) {
      const relatedApps = await (window.navigator as any).getInstalledRelatedApps();
      const foundApp = relatedApps.find((app) => app.id === environment.NATIVE_APP_ID);

      if (foundApp) setNativeAppInstalledPopup(true);
    }
  };

  const getNativeContext = async () => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const commonApi = await nativeApi.GetNativeFunctionServiceByServiceType<ICommonService>(
          CommonService.ServiceName,
        );

        const nativeContext = await commonApi.GetNativeContextAsync();
        setNativeCtx(nativeContext);
        return nativeContext;
      } catch (error) {
        console.log(error);
        return null;
      }
    }

    return null;
  };

  const checkIfNativePopupShouldDisplay = async (native: NativeContext | null) => {
    if (native === NativeContext.None) {
      const nativePopupTest = new URLSearchParams(window.location.search).get('native-popup');
      if (nativePopupTest) checkIfNativeAppIsInstalled();

      // const remind = storage.getItem('remindNative');
      // if (!remind) checkIfNativeAppIsInstalled();
      // else if (diffHours(new Date(remind), 72)) checkIfNativeAppIsInstalled();
    }
  };

  const getNotificationToken = async () => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const notificationsApi = await nativeApi.GetNativeFunctionServiceByServiceType<INotificationsService>(
          NotificationsService.ServiceName,
        );
        const token: string | null = await notificationsApi.GetDeviceFirebaseTokenAsync();
        return token;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkNotificationPermission = async () => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const notificationsApi = await nativeApi.GetNativeFunctionServiceByServiceType<INotificationsService>(
          NotificationsService.ServiceName,
        );
        const permission = await notificationsApi.CheckNotificationPermissionAsync();
        console.log('PERMISSION', permission);
        return permission;
      } catch (error) {
        console.log(error);
      }
    }

    return false;
  };

  const setNativeTheme = async (theme: Theme) => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const themesApi = await nativeApi.GetNativeFunctionServiceByServiceType<IThemesService>(
          ThemesService.ServiceName,
        );
        await themesApi.SetThemeAsync(theme);

        console.log('Set theme success');
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const runNativeInitFunctions = async () => {
      const result = await getNativeContext();
      checkIfNativePopupShouldDisplay(result);
    };

    NativeWebViewFunctions.Init();
    setNativeInit(true);
    runNativeInitFunctions();
  }, []);

  const values = {
    getNativeContext,
    getNotificationToken,
    setNativeTheme,
    nativeAppInstalledPopup,
    setNativeAppInstalledPopup,
    nativeInit,
    nativeCtx,
    checkNotificationPermission,
    notificationApiSupported,
  };

  return <NativeFunctionsContext.Provider value={values}>{children}</NativeFunctionsContext.Provider>;
};

export default NativeFunctionsProvider;
