import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconInitiate2Props = {
  className?: string;
};

export const IconInitiate2: React.FC<IconInitiate2Props> = ({ className }) => (
  <svg
    className={classNames('icon-initiate-2', { [className as string]: className })}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.4" stroke="#15141D" strokeWidth="1.5" />
    <path
      d="M20.3205 12.4267C21.1594 13.2659 21.7307 14.335 21.962 15.4988C22.1934 16.6627 22.0745 17.869 21.6203 18.9652C21.1661 20.0614 20.3971 20.9984 19.4104 21.6576C18.4237 22.3168 17.2638 22.6686 16.0772 22.6686C14.8906 22.6686 13.7306 22.3168 12.744 21.6576C11.7573 20.9984 10.9883 20.0614 10.5341 18.9652C10.0799 17.869 9.961 16.6627 10.1923 15.4988C10.4237 14.335 10.9949 13.2659 11.8338 12.4267"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.1 9.33333V16" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
