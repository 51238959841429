import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Info from '../../../../../components/channel-configuration/info';
import { ChannelBox } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { Calibration } from '../../device-settings/device-settings-form/sections';
import { InputsType } from '../components/inputs-type';
import SwapIn from '../components/swap-in';
import { useConfiguration } from '../hooks/use-configuration';
import { InfoPageType } from '../types';

type CoverConfigurationProps = {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
};

const CoverConfiguration: React.FC<CoverConfigurationProps> = ({ setInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();
  const { swapOut } = useConfiguration({ channel });

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => setInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem noRedirect />
      <div className="row_container max-width-desktop">
        <button className="button button--secondary" onClick={swapOut}>
          {t('reverseOutputs')}
        </button>
      </div>
      <div className="row_container margin_top">
        <span>{t('reverseOutputsContent')}</span>
      </div>
      <hr />
      <Info title={t('calibration')} onClick={() => setInfoPage(InfoPageType.CALIBRATION)} />
      <Calibration channel={channel} />
      <hr />
      <Info title={t('inService')} onClick={() => setInfoPage(InfoPageType.INSERVICE)} />
      <SwapIn channel={channel} />
      <InputsType setInfoPage={setInfoPage} isUnderlineUp />
    </>
  );
};

export default CoverConfiguration;
