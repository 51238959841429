import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, CarouselItem, Header, IconAdd, Page, Tabs } from '../../../../components';
import { Tip } from '../../../../components/tip';
import { useInstallationContext } from '../../../../context/installation';
import { ActionType } from '../../../../data-access/gql-types/graphql';
import { useRefetchData } from '../../../../hooks/refresh-data';
import { tipKey, tipTranslation } from '../../../../types';
import { TriggerNumberExceededDialog } from '../../components/trigger/trigger-number-exceeded-dialog';
import { useApi } from '../../hooks';
import CheckContentWrapper from '../common/check-content-wrapper';
import { Events } from './components/events';
import { TriggerList } from './components/trigger-list';
import { UserActionList } from './components/user-action-list';
import { useActionList } from './hooks/use-action-list';
import { actionTabKeys } from './utils';

const ActionList: React.FC = () => {
  const { t } = useTranslation('action');
  const { userActions: userActionData } = useApi();
  const { selectedInstallation } = useInstallationContext();
  const { refetchActions, refetchTriggers } = useRefetchData();
  const {
    handleTabChange,
    handleAction,
    setTriggerNumberExceededOpen,
    activeTabIndex,
    triggerNumberExceededOpen,
    channelListCount,
    allTriggers,
    loading,
    addButtonVisible,
    tipAvailable,
  } = useActionList();

  useEffect(() => {
    refetchActions();
  }, []);

  useEffect(() => {
    if (activeTabIndex === 2) refetchTriggers();
  }, [activeTabIndex, userActionData]);

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height p-t-16"
      header={
        <>
          <Header title={t('actions')} installationLogoTitleHeight>
            {addButtonVisible && (
              <div onClick={handleAction}>
                <IconAdd />
              </div>
            )}
          </Header>
          {selectedInstallation && (
            <Tabs
              tabList={actionTabKeys.map((x) => ({ label: t(`actionsType.${x}`) }))}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={handleTabChange}
              tabsType="pills"
              isDivider={false}
            />
          )}
        </>
      }
    >
      <CheckContentWrapper>
        <Carousel setActiveIndex={handleTabChange} activeIndex={activeTabIndex}>
          <CarouselItem>
            <UserActionList
              list={userActionData?.filter(({ action }) =>
                [ActionType.Basic, ActionType.Central].includes(action.actionType),
              )}
              channelListCount={channelListCount}
              isAdvanced={false}
            />
          </CarouselItem>
          <CarouselItem>
            <UserActionList
              list={userActionData?.filter(({ action }) => action.actionType === ActionType.Advanced)}
              channelListCount={channelListCount}
              isAdvanced
            />
          </CarouselItem>
          <CarouselItem>
            <TriggerList list={allTriggers} loading={loading} channelListCount={channelListCount} />
          </CarouselItem>
          <CarouselItem>
            <Events />
          </CarouselItem>
        </Carousel>
      </CheckContentWrapper>
      <TriggerNumberExceededDialog open={triggerNumberExceededOpen} setOpen={setTriggerNumberExceededOpen} />
      {tipAvailable && (
        <Tip
          tip={t(tipTranslation[activeTabIndex])}
          learningKey={tipKey[activeTabIndex]}
          point={<IconAdd />}
          top={40}
          right={22}
        />
      )}
    </Page>
  );
};

export default ActionList;
