import React from 'react';
import { Action, ActionType, ChannelTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { IconHand, IconWoodenGate } from '../../../../../components/icons';
import { IconSun, IconRGBCircles, IconWoodenBlind, IconSquareMeter } from '../../../../../components/icons';

type BasicIconProps = {
  action: Action;
};

export const BasicIcon: React.FC<BasicIconProps> = ({ action }) => {
  switch (action.actionType) {
    case ActionType.Advanced:
      return <IconHand className="icon-hand--color-accent" />;
    case ActionType.Basic:
    case ActionType.Central:
      switch (action.channelType) {
        case ChannelTypeInternal.Meter:
          return <IconSquareMeter isOn={false} />;
        case ChannelTypeInternal.Blind:
          return <IconWoodenBlind position={100} />;
        case ChannelTypeInternal.Gate:
          return <IconWoodenGate position={0} />;
        case ChannelTypeInternal.Light:
          return <IconRGBCircles isOn />;
        case ChannelTypeInternal.Switch:
          return <IconSun isOn />;
        default:
          return null;
      }
    default:
      return null;
  }
};
