import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import c from 'classnames';
import { useMutation } from '@apollo/client';
import {
  DialogConfirmation,
  Header,
  IconChevron,
  IconInitial,
  NavHeader,
  Page,
  SubmitButton,
} from '../../../components';
import { useBackdropContext } from '../../../context/backdrop';
import {
  PassOwnershipToInstallationMutation,
  PassOwnershipToInstallationMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { PASS_OWNERSHIP_TO_INSTALLATION } from '../../../data-access/mutations/share';
import { useProfile } from '../../../hooks';
import { useMutationErrors } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { MutationErrorType } from '../../../types';
import { toastError } from '../../../utils/toast';
import { useInstallationShare } from '../hooks/use-installation-share';
import './index.scss';

const InstallationSummaryForward: React.FC = () => {
  const navigate = useNavigate();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const { state, members } = useInstallationShare();
  const [timeLeft, setTimeLeft] = useState<number>(10);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [forwardedPopup, setForwardedPopup] = useState<boolean>(false);
  const { user, refetchUserMe } = useProfile();
  const { handleErrors } = useMutationErrors(MutationErrorType.Invitation);
  const [passOwnershipToInstallation, { loading }] = useMutation<
    PassOwnershipToInstallationMutation,
    PassOwnershipToInstallationMutationVariables
  >(PASS_OWNERSHIP_TO_INSTALLATION);

  const submit = () => {
    turnOnBackdrop();
    passOwnershipToInstallation({
      variables: {
        input: {
          installationId: state.installationId,
          newOwnerId: state.userId,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (!data || !data.passOwnershipToInstallation) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.passOwnershipToInstallation.result?.ok) {
          setForwardedPopup(true);
          refetchUserMe();
        } else {
          handleErrors(data.passOwnershipToInstallation.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  useEffect(() => {
    const end = Date.now() + 10 * 1000;
    const timer = setInterval(() => {
      const duration = end - Date.now();
      const durationInSecond = Math.floor(duration / 1000);

      if (durationInSecond < 0) {
        clearInterval(timer);
      } else if (durationInSecond !== timeLeft) {
        setTimeLeft(durationInSecond);
      }
    }, 200);

    return () => clearInterval(timer);
  }, []);

  const member = useMemo(() => members.find((x) => x.id === state.userId), [state.userId, members]);

  const renderUser = (name: string, img: string, withImage?: boolean) => (
    <div className="user">
      {withImage && (
        <>{img ? <img src={img} height={40} width={40} alt={''} /> : <IconInitial initial={name.charAt(0)} />}</>
      )}
      <p className={c('name text-ellipsis', { 'user-type': !withImage })}>{name}</p>
    </div>
  );

  const handleClosePopup = () => {
    setForwardedPopup(false);
    navigate(ROUTES.Root(), { replace: true });
  };

  return (
    <Page
      className="forward-summary-installation"
      header={
        <>
          <NavHeader />
          <Header title={t('forward.forwarding_installation')} />
        </>
      }
    >
      <>
        <h3 className="subHeader">{t('forward.forward_who')}</h3>
        <p className="forward-summary-installation-info">{member?.email || '-'}</p>
        <p className="forward-summary-installation-info">{t('forward.will_be_owner')}</p>

        <div className="forward-direction max-width-desktop">
          {renderUser(member?.firstName || '-', member?.image || '', true)}
          <IconChevron direction="right" />
          {renderUser(t('forward.owner'), '')}
        </div>

        <h3 className="subHeader">{t('forward.changing_role')}</h3>
        <p className="forward-summary-installation-info">{t('forward.will_not_be_owner')}</p>

        <div className="forward-direction max-width-desktop">
          {renderUser(user.profile.firstName || '-', user.profileImage.imageUrl || '', true)}
          <IconChevron direction="right" />
          {renderUser(t('forward.admin'), '')}
        </div>

        <SubmitButton onClick={submit} type="button" disabled={timeLeft > 0} isLoading={loading}>
          {`${t('forward.forward_installation')} ${timeLeft > 0 ? `(${timeLeft}s)` : ''}`}
        </SubmitButton>
      </>
      <DialogConfirmation
        show={forwardedPopup}
        setShow={handleClosePopup}
        header={t('forward.forwarded_installation')}
        content={t('forward.forwarded_info', {
          email: member?.email,
        })}
        primaryBtnText={tc('buttons.understand')}
        primaryBtnAction={handleClosePopup}
      />
    </Page>
  );
};

export default InstallationSummaryForward;
