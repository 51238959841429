import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/IRcm21ConfigService';
import { MultisensorChannelOffsetParams } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';
import {
  Rcm21ConfigService,
  VersionedOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rcm21VersionedService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/VersionedOptions/^1.6.0.34/Rcm21VersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type OffsetFormProps = {
  device: IDevice;
  offset: MultisensorChannelOffsetParams | null;
  versionService: string;
};

const OffsetForm: React.FC<OffsetFormProps> = ({ device, offset, versionService }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<MultisensorChannelOffsetParams>();

  useEffect(() => {
    if (offset !== null) {
      form.setValue('Offset', offset.Offset);
    }
  }, [offset]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (offset) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcm21ConfigService>(
        Rcm21ConfigService.ServiceName,
      );

      const versionedOptions: VersionedOptions = await configService.VersionedConfigurationOptions();

      const result = await (versionedOptions[versionService] as Rcm21VersionedService).SetChannelOffsetAsync(
        device,
        offset.Channel,
        values.Offset,
      );
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <ConfigurationInput
          name="Offset"
          label={t('exalus.params.Rcm21Params.OffsetParameters')}
          placeholder={t('exalus.params.Rcm21Params.OffsetParameters')}
          inputType="number"
          min={-5}
          max={5}
        />

        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default OffsetForm;
