import React, { Dispatch, SetStateAction } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FacadeActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { BlindDetailsFavourites } from '../../../../../../../components/channel-details-controls/blind/favourites';
import { defaultFavouritePositionList } from '../../../../../../../const';
import { useFacadeControl } from '../../../hooks/use-facade-control';

type FavouritesProps = {
  channel: IDeviceChannel;
  position: number;
  setTargetValue: Dispatch<SetStateAction<number>>;
};

export const Favourites: React.FC<FavouritesProps> = ({ channel, position, setTargetValue }) => {
  const { handleAction } = useFacadeControl(channel);

  const onFavouriteClick = (position: number) => {
    setTargetValue(position);
    handleAction(FacadeActionEnum.Percentage, position);
  };

  return (
    <BlindDetailsFavourites
      favouritePositionList={defaultFavouritePositionList}
      onFavouriteClick={onFavouriteClick}
      lastPosition={position}
      disabledPadding
      icon="facade"
    />
  );
};
