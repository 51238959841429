import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { MeasurementDetailItem } from '../index';
import { CustomTableHead } from './head';
import './index.scss';

type LiveTableProps = {
  unit: string;
  data: MeasurementDetailItem[];
};

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export const LiveTable: React.FC<LiveTableProps> = ({ unit, data }) => {
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [orderByKey, setOrderByKey] = React.useState<keyof MeasurementDetailItem>('timestamp');

  const handleSort = (event: React.MouseEvent<unknown>, newOrderBy: keyof MeasurementDetailItem) => {
    const isAsc = orderByKey === newOrderBy && order === Order.ASC;
    const toggledOrder = isAsc ? Order.DESC : Order.ASC;
    setOrder(toggledOrder);
    setOrderByKey(newOrderBy);
  };

  const rows = useMemo(() => {
    return orderBy(data, orderByKey, order);
  }, [data, order, orderByKey]);

  return (
    <TableContainer
      classes={{
        root: 'live-table-container max-width-desktop',
      }}
    >
      <Table size="small" stickyHeader>
        <CustomTableHead order={order} orderBy={orderByKey} onSort={handleSort} />
        <TableBody>
          {rows
            ? rows.map((row) => {
                return (
                  <TableRow
                    key={row.timestamp}
                    classes={{
                      root: 'live-table-container__row',
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{
                        root: 'live-table-container__cell',
                      }}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{
                        root: 'live-table-container__cell live-table-container__cell--value',
                      }}
                    >
                      {row.value} {unit}
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
