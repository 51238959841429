import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetMeterAmpTransformers } from '../../../../api/meter/meter.hooks';
import { AmpTransformValueRequest } from '../../../../api/meter/meter.types';
import { InputSelect } from '../../../../../../components';
import { AmpTransformersInternal } from '../../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useDevicesAndChannelsContext } from '../../../../context/devices-and-channels';
import { ChannelMeterType } from '../../../../types';
import Info from '../../../../../../components/channel-configuration/info';

type AmpTransformersProps = {
  setTimeoutError: () => void;
};

const AmpTransformers: React.FC<AmpTransformersProps> = ({ setTimeoutError }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelId: string }>();
  const { channel } = useDevicesAndChannelsContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate } = useSetMeterAmpTransformers();

  const ampTransformers = (channel?.data as ChannelMeterType)?.ampTransformers;

  const onChangeAmpTransformers = (value: AmpTransformersInternal) => {
    if (!channelId) return;
    
    if (ampTransformers !== value) {
      turnOnBackdrop();

      mutate(
        { channelId, value: AmpTransformValueRequest[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  return (
    <>
      <Info title={t('ampTransformers.ampTransormer')} />
      <InputSelect
        value={ampTransformers}
        onChange={onChangeAmpTransformers}
        options={[
          { label: t('ampTransformers.oneHundredAmps'), value: AmpTransformersInternal.OneHundredAmps },
          { label: t('ampTransformers.twoHundredsAmps'), value: AmpTransformersInternal.TwoHundredsAmps },
          { label: t('ampTransformers.fourHundredsAmps'), value: AmpTransformersInternal.FourHundredsAmps },
        ]}
        label={''}
      />
    </>
  );
};

export default AmpTransformers;
