import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DialogConfirmation, IconDelete } from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { useInstallationContext } from '../../../../../context/installation';
import {
  IntegrationType,
  RemoveDeviceMutation,
  RemoveDeviceMutationVariables,
} from '../../../../../data-access/gql-types/graphql';
import { REMOVE_DEVICE } from '../../../../../data-access/mutations/devices';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useMutationErrors } from '../../../../../hooks';
import { MutationErrorType } from '../../../../../types';
import { toastError, toastSuccess } from '../../../../../utils/toast';

type RemoveDeviceComponentProps = {
  deviceId: string;
};

const RemoveDeviceComponent: React.FC<RemoveDeviceComponentProps> = ({ deviceId }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const { t: td } = useTranslation('device-info');
  const { selectedInstallationId, selectedInstallation } = useInstallationContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { turnOffBackdrop, turnOnBackdrop } = useBackdropContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Device);
  const [removeDevice, { loading }] = useMutation<RemoveDeviceMutation, RemoveDeviceMutationVariables>(REMOVE_DEVICE);

  const removeDeviceAndAllChannels = () => {
    if (selectedInstallation?.integrationType === IntegrationType.Lavva) {
      turnOnBackdrop();
      removeDevice({
        variables: {
          input: {
            deviceId,
            installationId: selectedInstallationId,
          },
        },
        onCompleted: (data) => {
          if (!data || !data.removeDevice) {
            toastError({ content: tc('errors.somethingWentWrong') });
            return;
          }

          if (data.removeDevice.result?.ok) {
            setTimeout(() => {
              setShowDialog(false);
              const from = new URLSearchParams(search).get('from');
              turnOffBackdrop();
              toastSuccess({ content: t('toast.removeDeviceSuccess') });

              if (from === 'list') navigate(-1);
              else navigate(-3);
            }, 3500);
          } else {
            turnOffBackdrop();
            handleErrors(data.removeDevice.errors || []);
          }
        },
      });
    }
  };

  return (
    <>
      <ArrowButton
        title={td('delete_device')}
        onClick={() => setShowDialog(true)}
        type="delete"
        disabled={loading}
        arrowIcon={<IconDelete />}
        small
        className="m-b-24"
      />
      <DialogConfirmation
        show={showDialog}
        setShow={setShowDialog}
        primaryBtnDisabled={loading}
        header={t('removeDeviceDialog.header')}
        content={t('removeDeviceDialog.content')}
        primaryBtnText={tc(`buttons.${!loading ? 'remove' : 'loading'}`)}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={removeDeviceAndAllChannels}
        secondaryBtnAction={() => setShowDialog(false)}
      />
    </>
  );
};

export default RemoveDeviceComponent;
