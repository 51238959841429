import React, { useEffect, useState } from 'react';
import { ControlWrapper, Slider, SubmitButton } from '../../../../../../../components';
import { TriggerActionRequestInput, TriggerActionType } from '../../../../../../../data-access/gql-types/graphql';
import { GateControls } from '../../../../../components/';
import { ChannelType } from '../../../../../types';
import { GateActionInternal } from '../../../../action-create/types';
import { useGateSupportedFeatures } from '../../../../channel-details/gate/hooks/use-gate-supported';
import { useTriggerFormContext } from '../../../context';

type GateSetupProps = {
  setAction: (value: TriggerActionRequestInput) => void;
  channel: ChannelType;
  defaultParameters: TriggerActionRequestInput | undefined;
};

const ValueMapper = {
  [GateActionInternal.Unknown]: 0,
  [GateActionInternal.Tilt]: 0,
  [GateActionInternal.Stopped]: 1,
  [GateActionInternal.Open]: 2,
  [GateActionInternal.Closed]: 3,
};

const GateSetup: React.FC<GateSetupProps> = ({ setAction, channel, defaultParameters }) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [activeControl, setActiveControl] = useState<GateActionInternal>(GateActionInternal.Unknown);
  const [channelParameters, setChannelParameters] = useState<TriggerActionRequestInput | null>(null);
  const { updateAction } = useTriggerFormContext();
  const { setDirectionSupported, setPositionSupported, ventilationSupported } = useGateSupportedFeatures(channel);

  const setPosition = (value: number) => {
    setSliderValue(value);
    setChannelParameters(() => ({
      id: channel.id,
      actionType: TriggerActionType.GateSetPosition,
      integerValue: value,
    }));
  };

  useEffect(() => {
    if (defaultParameters) {
      switch (defaultParameters.actionType) {
        case TriggerActionType.GateSetPosition: {
          setSliderValue(defaultParameters.integerValue || 0);
          setPosition(defaultParameters.integerValue || 0);
          break;
        }
        case TriggerActionType.GateTilt: {
          setSliderValue(0);
          setActiveControl(GateActionInternal.Tilt);
          break;
        }
        default: {
          const gateAction = Object.keys(ValueMapper).find(
            (key) => ValueMapper[key] === defaultParameters.integerValue,
          ) as GateActionInternal | undefined;

          if (gateAction !== undefined) {
            if (gateAction === GateActionInternal.Open) setSliderValue(0);
            if (gateAction === GateActionInternal.Closed) setSliderValue(100);

            setChannelParameters(() => ({
              id: channel.id,
              actionType: getTriggerActionTypeByGateAction(gateAction),
              integerValue: defaultParameters.integerValue,
            }));
            setActiveControl(gateAction);
          }
        }
      }
    }
  }, [setSliderValue, defaultParameters]);

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    setActiveControl(GateActionInternal.Unknown);
    setPosition(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setSliderValue(Number(event.target.value));

  const onSubmit = () => {
    updateAction(channel.id, {
      id: channel.id,
      actionType: channelParameters?.actionType || TriggerActionType.GateSetPosition,
      integerValue: channelParameters?.integerValue,
    });
    setAction({
      id: channel.id,
      actionType: channelParameters?.actionType || TriggerActionType.GateSetPosition,
      integerValue: channelParameters?.integerValue,
    });
  };

  const getTriggerActionTypeByGateAction = (gateAction: GateActionInternal) => {
    switch (gateAction) {
      case GateActionInternal.Tilt:
        return TriggerActionType.GateTilt;
      default:
        return TriggerActionType.GateSetDirection;
    }
  };

  const handleControl = (value: GateActionInternal) => {
    setActiveControl(value);

    setChannelParameters(() => ({
      id: channel.id,
      actionType: getTriggerActionTypeByGateAction(value),
      integerValue: ValueMapper[value],
    }));
  };

  return (
    <ControlWrapper className="control-wrapper--full-space">
      <div>
        {(setDirectionSupported || ventilationSupported) && (
          <GateControls
            kind="action"
            open={() => {
              setSliderValue(0);
              handleControl(GateActionInternal.Open);
            }}
            close={() => {
              setSliderValue(100);
              handleControl(GateActionInternal.Closed);
            }}
            stop={() => handleControl(GateActionInternal.Stopped)}
            ventilation={() => {
              setSliderValue(0);
              handleControl(GateActionInternal.Tilt);
            }}
            ventilationVisible={ventilationSupported}
            directionVisible={setDirectionSupported}
            activeButton={activeControl}
          />
        )}
        {setPositionSupported && (
          <Slider
            onPointerUp={handleSliderEvent}
            value={sliderValue}
            onChange={onChange}
            position="horizontal"
            noBgElement
          />
        )}
      </div>
      <SubmitButton onClick={onSubmit} />
    </ControlWrapper>
  );
};

export default GateSetup;
