import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/IZfBidiConfigService';
import { ZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { ISrp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp22ConfigService';
import { Srp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp22ConfigService';
import { useBackdropContext } from '../../../../../../../context/backdrop';
import { useExalusChannel, useExalusChannelStateChanged } from '../../../../../hooks';

export const useCalibration = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const { t } = useTranslation('device-info');
  const { channelId } = useParams<{ channelId?: string }>();
  const { turnOnBackdrop, turnOffBackdrop, backdropOpen } = useBackdropContext();
  const { data: channel } = useExalusChannel((channelId || '').split('_')[0], channelId || '');
  useExalusChannelStateChanged(channel);

  const getZfBidiConfigService = async () => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    return await extaLifeService.GetDeviceServiceByServiceTypeAsync<IZfBidiConfigService>(
      ZfBidiConfigService.ServiceName,
    );
  };

  const getSrp22ConfigService = async () => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    return await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISrp22ConfigService>(
      Srp22ConfigService.ServiceName,
    );
  };

  return {
    navigate,
    channel,
    step,
    backdropOpen,
    t,
    turnOnBackdrop,
    turnOffBackdrop,
    getZfBidiConfigService,
    getSrp22ConfigService,
    setStep,
  };
};
