import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from 'lavva.webview/build/js/Services/Themes/IThemesService';
import { useNativeFunctionsContext } from '../../../context/native-functions';
import * as storage from '../../../integrations/lavva/storage';
import { addBodyClass, getThemeFromDevice, removeBodyClass } from '../../../utils/theme';

export const useThemeSettings = () => {
  const { t } = useTranslation('profile');
  const { setNativeTheme } = useNativeFunctionsContext();

  const optionsTheme = useMemo(
    () => [
      { value: 'device', label: t('theme.device') },
      { value: 'light', label: t('theme.light') },
      { value: 'dark', label: t('theme.dark') },
    ],
    [t],
  );

  const optionsThemeDefaultIndex = useMemo(() => {
    const currentTheme = storage.getItem('theme');
    const optionIndex = optionsTheme.findIndex((option) => option.value === currentTheme);
    return optionIndex < 0 ? 0 : optionIndex;
  }, [optionsTheme, storage]);

  const changeTheme = (themeName) => {
    switch (themeName) {
      case 'dark':
        addBodyClass('dark');
        storage.setItem('theme', 'dark');
        setNativeTheme(Theme.Dark);
        break;
      case 'light':
        removeBodyClass('dark');
        storage.setItem('theme', 'light');
        setNativeTheme(Theme.Light);
        break;
      case 'device':
        if (getThemeFromDevice() === 'dark') addBodyClass('dark');
        else removeBodyClass('dark');
        storage.setItem('theme', 'device');
        setNativeTheme(Theme.System);
        break;
    }
  };

  return {
    changeTheme,
    optionsTheme,
    optionsThemeDefaultIndex,
  };
};
