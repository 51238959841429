import React, { useMemo, useState } from 'react';
import { ControlWrapper, Slider } from '../../../../../components';
import { GateStateDirectionInternal } from '../../../../../data-access/gql-types/graphql';
import { GateControls } from '../../../components/';
import { useGateAction } from '../../../hooks';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelGateStateType, ChannelType } from '../../../types';
import { useActionGatesSupportedFeatures } from '../hooks/use-gates-supported';

type GateActionProps = {
  channels: ChannelType[];
};

const GateAction: React.FC<GateActionProps> = ({ channels }) => {
  const [sliderValue, setSliderValue] = useState<number>(50);
  const { handleAction, handleVentilation } = useGateAction(channels);
  const channelState = useChannelsStateStore((s) => s.channelState);
  const { setDirectionSupported, setPositionSupported, ventilationSupported } =
    useActionGatesSupportedFeatures(channels);

  const onChange = (value: string | number) => {
    setSliderValue(typeof value === 'number' ? value : value === 'close' ? 100 : 0);
    handleAction(value);
  };

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    handleAction(Number((event.target as HTMLInputElement).value));
  };

  const runningStateActive = useMemo(() => {
    return channels.any((x) =>
      [GateStateDirectionInternal.Opening, GateStateDirectionInternal.Closing].includes(
        (channelState[x.id] as ChannelGateStateType)?.direction,
      ),
    );
  }, [channelState, channels]);

  return (
    <ControlWrapper className="control-wrapper--full-space">
      <div>
        {(setDirectionSupported || ventilationSupported) && (
          <GateControls
            kind="action"
            open={() => onChange('open')}
            close={() => onChange('close')}
            stop={() => onChange('stop')}
            ventilation={handleVentilation}
            ventilationVisible={ventilationSupported}
            directionVisible={setDirectionSupported}
            active={runningStateActive}
          />
        )}
        {setPositionSupported && (
          <Slider
            onPointerUp={handleSliderEvent}
            value={sliderValue}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            position="horizontal"
            animationWhenActive
            noBgElement
          />
        )}
      </div>
    </ControlWrapper>
  );
};

export default GateAction;
