import React, { useState, PointerEvent, useEffect } from 'react';
import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceControlFeature } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { SetBlindMicroventilation } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, IconChevron, Slider, SubmitButton } from '../../../../../../../../components';
import { useExalusCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, MicroventilationStateTask } from '../../../../../../../../types/actions';

export const MicroventilationSettings: React.FC = () => {
  const { channels } = useExalusCreateActionContext();
  const [sliderValue, setSliderValue] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      setSliderValue(((task.taskParams as DeviceTaskParams).state as MicroventilationStateTask).position);
    }
  }, [task]);

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    setSliderValue(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setSliderValue(Number(event.target.value));

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task = new SetBlindMicroventilation();
      task.DeviceGuid = channels[i].GetDevice().Guid;
      task.Channel = channels[i].Number;
      task.TaskType = DeviceTaskType.SetBlindMicroventilation;
      task.FeatureType = DeviceControlFeature.SetBlindMicroventilation;
      task.Position = sliderValue;

      finalizeDeviceTaskSubmit(
        task,
        channels[i],
        { position: sliderValue } as MicroventilationStateTask,
        i === channels.length - 1,
      );
    }
  };

  return (
    <>
      <ControlWrapper>
        <Slider value={sliderValue} onPointerUp={handleSliderEvent} onChange={onChange} showValue={false} />
        <button className="microventilation-button" onClick={() => setSliderValue(95)}>
          <IconChevron direction="down" />
        </button>
      </ControlWrapper>
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
