import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeviceResetToDefaults } from '../../../api/device/device.hooks';
import { DialogConfirmation } from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { useErrors } from '../../../../../hooks/errors';
import { toastSuccess } from '../../../../../utils/toast';

type DeviceResetToDefaultsProps = {
  deviceId: string;
};

const DeviceResetToDefaults: React.FC<DeviceResetToDefaultsProps> = ({ deviceId }) => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { handleLavvaResolve } = useErrors();
  const { mutate } = useDeviceResetToDefaults();

  const handleResetToDefaults = () => {
    mutate(
      { deviceId },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          handleLavvaResolve({
            status: data.publishStatus,
            deviceId,
            onSuccess: () => toastSuccess({ content: t('resetDefaults.success') }),
          });
        },
      },
    );
  };

  return (
    <>
      <ArrowButton title={t('resetDefaults.button')} onClick={() => setShowDialog(true)} small className="m-b-24" />
      <DialogConfirmation
        show={showDialog}
        setShow={setShowDialog}
        header={t('resetDefaults.popupHeader')}
        content={t('resetDefaults.popupContent')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleResetToDefaults}
        secondaryBtnAction={() => setShowDialog(false)}
        timer
      />
    </>
  );
};

export default DeviceResetToDefaults;
