import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ExalusActionCreateConditions from '../integrations/exalus/modules/action-create/conditions';
import ExalusActionSources from '../integrations/exalus/modules/action-create/conditions/action-sources';
import ExalusDeviceParameters from '../integrations/exalus/modules/action-create/conditions/action-sources/device-parameters';
import ExalusDeviceSource from '../integrations/exalus/modules/action-create/conditions/action-sources/device-source';
import ExalusScenarioSource from '../integrations/exalus/modules/action-create/conditions/action-sources/scenario-source';
import ExalusScheduleSource from '../integrations/exalus/modules/action-create/conditions/action-sources/schedule-source';
import ExalusActionConditionsTypes from '../integrations/exalus/modules/action-create/conditions/conditions-types';
import ExalusActionTaskDelay from '../integrations/exalus/modules/action-create/tasks/task-variants/delay-view';
import ExalusActionTaskLock from '../integrations/exalus/modules/action-create/tasks/task-variants/lock-view';
import ExalusActionTaskNotify from '../integrations/exalus/modules/action-create/tasks/task-variants/notify-view';
import ExalusActionDetails from '../integrations/exalus/modules/action-details';
import AddDevices from '../integrations/exalus/modules/add-devices';
import CentralActionByTypeDetails from '../integrations/exalus/modules/central-action-by-type-details';
import CentralActionDetails from '../integrations/exalus/modules/central-action-details';
import BlindAutoCalibration from '../integrations/exalus/modules/channel-configuration/forms/common/auto-calibration';
import DeviceRemotes from '../integrations/exalus/modules/channel-configuration/forms/common/device-remotes';
import DeviceUpdate from '../integrations/exalus/modules/channel-configuration/forms/common/update';
import Srp22Calibration from '../integrations/exalus/modules/channel-configuration/forms/srp/calibration-srp22';
import ZfBidiCalibration from '../integrations/exalus/modules/channel-configuration/forms/zf-bidi/calibration';
import Chat from '../integrations/exalus/modules/chat';
import ExalusControllerSettings from '../integrations/exalus/modules/controller-settings';
import CreateAccountOffer from '../integrations/exalus/modules/dashboard/components/create-account-popup';
import ExalusIntegrationCreate from '../integrations/exalus/modules/integration-create';
import ExalusIntegrationLogin from '../integrations/exalus/modules/integration-login';
import ExalusQRCodeAuth from '../integrations/exalus/modules/integration-qr-auth';
import ExalusLogin from '../integrations/exalus/modules/login';
import ExalusAddDns from '../integrations/exalus/modules/network/add-dns';
import ExalusNetworkSettings from '../integrations/exalus/modules/network/network-settings';
import ExalusNetworkStaticSettings from '../integrations/exalus/modules/network/static-settings';
import UserManual from '../integrations/exalus/modules/user-manual';
import ExalusUsersList from '../integrations/exalus/modules/users-list';
import ExalusChangePassword from '../integrations/exalus/modules/users-list/change-password';
import ExalusUserCreate from '../integrations/exalus/modules/users-list/create-user';
import ExalusUserEdit from '../integrations/exalus/modules/users-list/edit-user';
import ExalusUserDetails from '../integrations/exalus/modules/users-list/user-details';
import ActionChannelSort from '../integrations/lavva/modules/action-channels-sort';
import ActionCreateBasic from '../integrations/lavva/modules/action-create-basic';
import ActionDetails from '../integrations/lavva/modules/action-details';
import ActionEditChannels from '../integrations/lavva/modules/action-edit-channels';
import ActionEditName from '../integrations/lavva/modules/action-edit-name';
import MeasurementDetails from '../integrations/lavva/modules/channel-details/measurements/details';
import DeviceAddBluetooth from '../integrations/lavva/modules/device-add/pages';
import DeviceAddBluetoothIdentify from '../integrations/lavva/modules/device-add/pages/identify';
import DeviceAddBluetoothNetwork from '../integrations/lavva/modules/device-add/pages/network';
import DeviceAddBluetoothSuccess from '../integrations/lavva/modules/device-add/pages/success/success';
import DeviceUpdateBluetoothSuccess from '../integrations/lavva/modules/device-add/pages/success/update-success';
import DeviceAddBluetoothSetWifiSuccess from '../integrations/lavva/modules/device-add/pages/success/wifi-success';
import DeviceAddBluetoothUpdate from '../integrations/lavva/modules/device-add/pages/update/update';
import WisniowskiDriveAddStart from '../integrations/lavva/modules/device-add/pages/wisniowski/drive-add-start';
import WisniowskiDriveAddSuccess from '../integrations/lavva/modules/device-add/pages/wisniowski/drive-add-success';
import WisniowskiDriveAddTutorialStep0 from '../integrations/lavva/modules/device-add/pages/wisniowski/drive-add-tutorial/step-0';
import WisniowskiDriveAddTutorialStep1 from '../integrations/lavva/modules/device-add/pages/wisniowski/drive-add-tutorial/step-1';
import WisniowskiDriveAddTutorialStep2 from '../integrations/lavva/modules/device-add/pages/wisniowski/drive-add-tutorial/step-2';
import DeviceWifiConfiguration from '../integrations/lavva/modules/device-wifi-configuration';
import OptimizerSchedule from '../integrations/lavva/modules/optimizer-schedule';
import RgbSceneForm from '../integrations/lavva/modules/rgb-scene-form';
import TriggerDetails from '../integrations/lavva/modules/trigger-details';
import TriggerForm from '../integrations/lavva/modules/trigger-form';
import InstallationCreate from '../modules/installation-create';
import IntegrationsList from '../modules/installation-create/integration-list';
import InstallationCreated from '../modules/installation-created';
import InstallationList from '../modules/installation-list';
import InstallationSettings from '../modules/installation-settings';
import RemoveInstallation from '../modules/installation-settings/installation/installation-remove';
import ShareAccessCount from '../modules/installation-share/access-count';
import ShareAccessTime from '../modules/installation-share/access-time';
import InstallationSummaryForward from '../modules/installation-share/forward-summary-installation';
import ChannelsShare from '../modules/installation-share/share-channels';
import ChannelsShareEdit from '../modules/installation-share/share-channels-edit';
import ShareEmail from '../modules/installation-share/share-email';
import GroupsShare from '../modules/installation-share/share-groups';
import GroupsShareEdit from '../modules/installation-share/share-groups-edit';
import ShareRestriction from '../modules/installation-share/share-restriction';
import ShareRole from '../modules/installation-share/share-role';
import InstallationShareSubject from '../modules/installation-share/share-subject';
import SharedInstallations from '../modules/installation-share/shared-installation';
import SharedUser from '../modules/installation-share/shared-user';
import InvitationSummary from '../modules/installation-share/summary-share-invitation/invitation';
import ShareSummary from '../modules/installation-share/summary-share-invitation/share';
import InvitationAccepted from '../modules/invitation-accepted';
import InvitationReceived from '../modules/invitation-received';
import NotificationList from '../modules/notification-list';
import PrivacyPage from '../modules/privacy';
import RodoPage from '../modules/rodo';
import Settings from '../modules/settings';
import Store from '../modules/store';
import StoreDeviceDetails from '../modules/store-device-details';
import SupportPage from '../modules/support';
import TermsPage from '../modules/terms';
import {
  ChannelList,
  Dashboard,
  ChannelConfiguration,
  ChannelDetails,
  GroupList,
  ActionList,
  GroupCreate,
  GroupEdit,
  DeviceList,
  DeviceDetails,
  ChannelsVisibility,
  GroupSort,
  GroupSortChannels,
  ActionCreate,
  ActionCreateTasks,
  ActionTasksTypes,
  ActionTasksVariants,
  ActionDeviceVariants,
  ActionChannelSettings,
  ActionEdit,
  ActionEditTasks,
} from './integration-routes';
import { ROUTES } from './routes';

export const RouterSwitch: React.FC = () => (
  <Routes>
    <Route path={ROUTES.Root()} element={<Dashboard />} />
    <Route path={ROUTES.Notification()} element={<NotificationList />} />
    <Route path={ROUTES.Settings()} element={<Settings />} />
    <Route path={ROUTES.Channel()} element={<ChannelList />} />
    <Route path={ROUTES.ChannelsVisibility()} element={<ChannelsVisibility />} />
    <Route path={ROUTES.ChannelDetails()} element={<ChannelDetails />} />
    <Route path={ROUTES.Device()} element={<DeviceList />} />
    <Route path={ROUTES.DeviceSettings()} element={<DeviceDetails />} />
    <Route path={ROUTES.DeviceWifiConfiguration()} element={<DeviceWifiConfiguration />} />
    <Route path={ROUTES.ChannelSettings()} element={<DeviceDetails />} />
    <Route path={ROUTES.FavouritesForm()} element={<GroupEdit />} />
    <Route path={ROUTES.Group()} element={<GroupList />} />
    <Route path={ROUTES.GroupSort()} element={<GroupSort />} />
    <Route path={ROUTES.GroupSortChannels()} element={<GroupSortChannels />} />
    <Route path={ROUTES.GroupCreate()} element={<GroupCreate />} />
    <Route path={ROUTES.GroupEdit()} element={<GroupEdit />} />
    <Route path={ROUTES.InstallationGroupsShare()} element={<GroupsShare />} />
    <Route path={ROUTES.InstallationChannelsShare()} element={<ChannelsShare />} />
    <Route path={ROUTES.Terms()} element={<TermsPage />} />
    <Route path={ROUTES.Privacy()} element={<PrivacyPage />} />
    <Route path={ROUTES.Support()} element={<SupportPage />} />
    <Route path={ROUTES.Rodo()} element={<RodoPage />} />
    <Route path={ROUTES.Store()} element={<Store />} />
    <Route path={ROUTES.StoreDeviceDetails()} element={<StoreDeviceDetails />} />
    <Route path={ROUTES.Installation()} element={<InstallationList />} />
    <Route path={ROUTES.InstallationCreate()} element={<InstallationCreate />} />
    <Route path={ROUTES.InstallationCreated()} element={<InstallationCreated />} />
    <Route path={ROUTES.IntegrationsList()} element={<IntegrationsList />} />
    <Route path={ROUTES.InstallationSettings()} element={<InstallationSettings />} />
    <Route path={ROUTES.InstallationForwardSummary()} element={<InstallationSummaryForward />} />
    <Route path={ROUTES.InstallationRemove()} element={<RemoveInstallation />} />
    <Route path={ROUTES.InstallationShared()} element={<SharedInstallations />} />
    <Route path={ROUTES.SharedUser()} element={<SharedUser />} />
    <Route path={ROUTES.InstallationShareEmail()} element={<ShareEmail />} />
    <Route path={ROUTES.InstallationShareSubject()} element={<InstallationShareSubject />} />
    <Route path={ROUTES.InstallationShareRole()} element={<ShareRole />} />
    <Route path={ROUTES.UserSharedGroups()} element={<GroupsShareEdit />} />
    <Route path={ROUTES.UserSharedChannels()} element={<ChannelsShareEdit />} />
    <Route path={ROUTES.InstallationShareRestriction()} element={<ShareRestriction />} />
    <Route path={ROUTES.InstallationShareAccessCount()} element={<ShareAccessCount />} />
    <Route path={ROUTES.InstallationShareAccessTime()} element={<ShareAccessTime />} />
    <Route path={ROUTES.InstallationSummaryShare()} element={<ShareSummary />} />
    <Route path={ROUTES.InvitationSummary()} element={<InvitationSummary />} />
    <Route path={ROUTES.ActionList()} element={<ActionList />} />
    <Route path={ROUTES.ActionEditChannels()} element={<ActionEditChannels />} />
    <Route path={ROUTES.ActionEditName()} element={<ActionEditName />} />
    <Route path={ROUTES.ActionCreate()} element={<ActionCreate />} />
    <Route path={ROUTES.ActionCreateTasks()} element={<ActionCreateTasks />} />
    <Route path={ROUTES.ActionChannelsSort()} element={<ActionChannelSort />} />
    <Route path={ROUTES.ActionCreateBasic()} element={<ActionCreateBasic />} />
    <Route path={ROUTES.TriggerCreate()} element={<TriggerForm />} />
    <Route path={ROUTES.TriggerEdit()} element={<TriggerForm />} />
    <Route path={ROUTES.RgbSceneCreate()} element={<RgbSceneForm />} />
    <Route path={ROUTES.InvitationReceived()} element={<InvitationReceived />} />
    <Route path={ROUTES.InvitationAccepted()} element={<InvitationAccepted />} />
    <Route path={ROUTES.CreateAccountOffer()} element={<CreateAccountOffer />} />
    <Route path={ROUTES.ActionDetails()} element={<ActionDetails />} />
    <Route path={ROUTES.TriggerDetails()} element={<TriggerDetails />} />
    <Route path={ROUTES.ChannelConfiguration()} element={<ChannelConfiguration />} />
    <Route path={ROUTES.OptimizerSchedule()} element={<OptimizerSchedule />} />
    <Route path={ROUTES.OptimizerScheduleEdit()} element={<OptimizerSchedule />} />
    <Route path={ROUTES.ExalusIntegrationCreate()} element={<ExalusIntegrationCreate />} />
    <Route path={ROUTES.ExalusAuthorization()} element={<ExalusIntegrationLogin />} />
    <Route path={ROUTES.ExalusQRCodeAuthorization()} element={<ExalusQRCodeAuth />} />
    <Route path={ROUTES.ExalusLogin()} element={<ExalusLogin />} />
    <Route path={ROUTES.CentralAction()} element={<CentralActionDetails />} />
    <Route path={ROUTES.CentralActionByType()} element={<CentralActionByTypeDetails />} />
    <Route path={ROUTES.AddExalusDevices()} element={<AddDevices />} />
    <Route path={ROUTES.FacadeCalibration()} element={<ZfBidiCalibration />} />
    <Route path={ROUTES.Srp22Calibration()} element={<Srp22Calibration />} />
    <Route path={ROUTES.DeviceUpdate()} element={<DeviceUpdate />} />
    <Route path={ROUTES.BlindAutoCalibration()} element={<BlindAutoCalibration />} />
    <Route path={ROUTES.ExalusUsersList()} element={<ExalusUsersList />} />
    <Route path={ROUTES.UserDetails()} element={<ExalusUserDetails />} />
    <Route path={ROUTES.UserEdit()} element={<ExalusUserEdit />} />
    <Route path={ROUTES.ChangeUserPassword()} element={<ExalusChangePassword />} />
    <Route path={ROUTES.UserCreate()} element={<ExalusUserCreate />} />
    <Route path={ROUTES.UserManual()} element={<UserManual />} />
    <Route path={ROUTES.ExalusControllerSettings()} element={<ExalusControllerSettings />} />
    <Route path={ROUTES.ExalusNetworkSettings()} element={<ExalusNetworkSettings />} />
    <Route path={ROUTES.ExalusActionCreateConditions()} element={<ExalusActionCreateConditions />} />
    <Route path={ROUTES.ExalusActionConditionsTypes()} element={<ExalusActionConditionsTypes />} />
    <Route path={ROUTES.ActionTasksTypes()} element={<ActionTasksTypes />} />
    <Route path={ROUTES.ActionTasksVariants()} element={<ActionTasksVariants />} />
    <Route path={ROUTES.DeviceVariants()} element={<ActionDeviceVariants />} />
    <Route path={ROUTES.ActionChannelSettings()} element={<ActionChannelSettings />} />
    <Route path={ROUTES.ExalusActionTaskDelay()} element={<ExalusActionTaskDelay />} />
    <Route path={ROUTES.ExalusActionTaskLock()} element={<ExalusActionTaskLock />} />
    <Route path={ROUTES.ExalusActionTaskNotify()} element={<ExalusActionTaskNotify />} />
    <Route path={ROUTES.ExalusActionSources()} element={<ExalusActionSources />} />
    <Route path={ROUTES.ExalusDeviceSource()} element={<ExalusDeviceSource />} />
    <Route path={ROUTES.ExalusScheduleSource()} element={<ExalusScheduleSource />} />
    <Route path={ROUTES.ExalusScenarioSource()} element={<ExalusScenarioSource />} />
    <Route path={ROUTES.ExalusDeviceParameters()} element={<ExalusDeviceParameters />} />
    <Route path={ROUTES.ExalusActionDetails()} element={<ExalusActionDetails />} />
    <Route path={ROUTES.ActionEdit()} element={<ActionEdit />} />
    <Route path={ROUTES.ActionEditConditions()} element={<ExalusActionCreateConditions />} />
    <Route path={ROUTES.ActionEditTasks()} element={<ActionEditTasks />} />
    <Route path={ROUTES.DeviceRemotes()} element={<DeviceRemotes />} />
    <Route path={ROUTES.ExalusDnsAdd()} element={<ExalusAddDns />} />
    <Route path={ROUTES.ExalusNetworkStaticSettings()} element={<ExalusNetworkStaticSettings />} />
    <Route path={ROUTES.Chat()} element={<Chat />} />
    <Route path={ROUTES.MeasurementDetails()} element={<MeasurementDetails />} />
    <Route path={ROUTES.DeviceAddBluetooth()} element={<DeviceAddBluetooth />} />
    <Route path={ROUTES.DeviceAddBluetoothIdentify()} element={<DeviceAddBluetoothIdentify />} />
    <Route path={ROUTES.DeviceAddBluetoothNetwork()} element={<DeviceAddBluetoothNetwork />} />
    <Route path={ROUTES.DeviceAddBluetoothSuccess()} element={<DeviceAddBluetoothSuccess />} />
    <Route path={ROUTES.DeviceUpdateBluetoothSuccess()} element={<DeviceUpdateBluetoothSuccess />} />
    <Route path={ROUTES.DeviceAddBluetoothSetWifiSuccess()} element={<DeviceAddBluetoothSetWifiSuccess />} />
    <Route path={ROUTES.DeviceAddBluetoothUpdate()} element={<DeviceAddBluetoothUpdate />} />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiStart()} element={<WisniowskiDriveAddStart />} />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiTutorialStep0()} element={<WisniowskiDriveAddTutorialStep0 />} />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiTutorialStep1()} element={<WisniowskiDriveAddTutorialStep1 />} />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2()} element={<WisniowskiDriveAddTutorialStep2 />} />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiSuccess()} element={<WisniowskiDriveAddSuccess />} />
    <Route path="*" element={<Navigate to={ROUTES.Root()} replace />} />
  </Routes>
);
