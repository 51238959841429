import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useOptimizerSetConfig, useOptimizerSetMode } from '../../../../../api/optimizer/optimizer.hooks';
import { OptimizerModeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../../../context/backdrop';
import { ChannelType } from '../../../../../types';
import { getEnumKeyByValue } from '../../../../../../../utils/helpers';
import { toastSuccess } from '../../../../../../../utils/toast';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { ComfortFormBody } from '../types';
import { prepareHoursRangesToSend, prepareOperatorAndTariffForRequest, prepareRelaysToSend } from '../utils';
import { useOptimizerErrors } from './use-optimizer-errors';

export const useOptimizerComfort = (channel: ChannelType) => {
  const { t } = useTranslation('channel-details');
  const { mutate: setMode } = useOptimizerSetMode();
  const { mutate: setConfig } = useOptimizerSetConfig();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleOptimizerErrors } = useOptimizerErrors();
  const { clearErrors } = useOptimizerConfigurationContext();

  const submitConfiguration = (
    mode: OptimizerModeInternal,
    formValues: ComfortFormBody,
    closeWarningDialog?: () => void,
  ) => {
    const {
      tempMax,
      tempMin,
      heaterPowerLimit,
      voltageThreshold1,
      voltageThreshold2,
      voltageThreshold3,
      relayConfig1,
      relayConfig2,
      relayConfig3,
      wholeWeekendCheaper,
      wholeHolidaysCheaper,
      ignoreSummerTimeChanges,
      hoursRanges,
      operator,
      tariffName,
    } = formValues;
    clearErrors();

    turnOnBackdrop();
    setMode(
      {
        deviceId: channel.deviceId,
        channelId: channel.id,
        mode: getEnumKeyByValue(OptimizerModeInternal, mode),
      },
      {
        onSuccess: ({ data }) => {
          if (data) {
            turnOnBackdrop();

            setConfig(
              {
                deviceId: channel.deviceId,
                channelId: channel.id,
                comfort: {
                  tempMax,
                  tempMin,
                  heaterPowerLimit,
                  voltageThreshold: [
                    { index: 1, value: voltageThreshold1 },
                    { index: 2, value: voltageThreshold2 },
                    { index: 3, value: voltageThreshold3 },
                  ],
                  relaysConfig: prepareRelaysToSend({ relayConfig1, relayConfig2, relayConfig3 }),
                  tariff: {
                    wholeWeekendCheaper,
                    wholeHolidaysCheaper,
                    ignoreSummerTimeChanges,
                    hoursRanges: prepareHoursRangesToSend(hoursRanges),
                    kind: prepareOperatorAndTariffForRequest(operator, tariffName),
                  },
                },
              },
              {
                onSuccess: () => {
                  turnOffBackdrop();
                  closeWarningDialog?.();
                  toastSuccess({ content: t('optimizer.configuration.configurationSaved') });
                },
                onError: (error: AxiosError) => {
                  closeWarningDialog?.();
                  handleOptimizerErrors(error);
                },
              },
            );
          } else {
            turnOffBackdrop();
          }
        },
      },
    );
  };

  return {
    submitConfiguration,
  };
};
