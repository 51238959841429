import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useInstallationContext } from '../../context/installation';
import {
  AllInvitationsQueryVariables,
  Query,
} from '../../data-access/gql-types/graphql';
import { ALL_INVITATIONS } from '../../data-access/queries/invitations';

type UseInvitationsParams = {
  installationId: string;
  createdByUserId: string;
};

export const useInvitations = ({ installationId, createdByUserId }: UseInvitationsParams) => {
  const { skipLavvaFetch } = useInstallationContext();
  const { data, loading } = useQuery<Query, AllInvitationsQueryVariables>(ALL_INVITATIONS, {
    variables: {
      includeCreatedByMe: true,
      includeInvitedMe: true,
      installationId,
      createdByUserId,
    },
    fetchPolicy: 'network-only',
    skip: skipLavvaFetch || !installationId || !createdByUserId,
  });

  const invitations = useMemo(() => data?.allInvitations || [], [data?.allInvitations]);

  return {
    invitations,
    loading,
  };
};
