import React from 'react';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { IconCallButtonCircle, IconDoorEntryCircle, IconGateEntryCircle } from '../../../../../components';
import { ChannelBoxControls } from '../../../../../components/channel-box-controls';

type EntryPhoneControlButtonsProps = {
  call: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  isListItem?: boolean;
  openGateway?: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  openGate?: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
};

export const EntryPhoneControlButtons: React.FC<EntryPhoneControlButtonsProps> = ({
  openGateway,
  openGate,
  call,
  isListItem,
}) => {
  const handleGateway = () => openGateway?.();
  const handleCall = () => call();
  const handleGate = () => openGate?.();

  return (
    <ChannelBoxControls
      kind="normal"
      isListItem={isListItem}
      controls={[
        ...(openGateway
          ? [
              {
                icon: <IconDoorEntryCircle />,
                handleAction: handleGateway,
              },
            ]
          : []),
        {
          icon: <IconCallButtonCircle />,
          handleAction: handleCall,
        },
        ...(openGate
          ? [
              {
                icon: <IconGateEntryCircle />,
                handleAction: handleGate,
              },
            ]
          : []),
      ]}
    />
  );
};
