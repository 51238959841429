import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  GlobalTimeParameters,
  IInputOutputSwitchParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { ISlr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr21ConfigService';
import { ISlr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr22ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { LedControllersLightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/SlrConfigParameters';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { LedTimeType } from '../../../device-details/types';
import DisablingTimeForm from '../on-off/disable-time';
import EnablingTimeForm from '../on-off/enable-time';
import FloatingTimeForm from '../on-off/floating-time';
import InputsModeForm from '../on-off/inputs-mode';
import TurnOnBehaviourForm from '../on-off/turn-behaviour';
import DimmingBrighteningTimeForm from './dimming-brightening-time';
import OnOffActivationControllerForm from './on-off-activation';
import { initialConfig, SlrConfigParams, SlrParamsEnum } from './types';

type SlrConfigFormProps = {
  device: IDevice;
  serviceName: string;
  channel?: IDeviceChannel;
};

const SlrConfigForm: React.FC<SlrConfigFormProps> = ({ device, serviceName, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<SlrConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetSwitchParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: SlrParamsEnum) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
      ISlr21ConfigService | ISlr22ConfigService
    >(serviceName);

    switch (panel) {
      case SlrParamsEnum.TURN_ON_BEHAVIOUR: {
        if (channel) {
          const data = await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetSwitchParamsResponse<StateOfTurnOnSwitchDeviceParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as StateOfTurnOnSwitchDeviceParameters });
            setOpen(true);
          });
        }
        break;
      }
      case SlrParamsEnum.DIMMING_AND_BRIGHTNESS: {
        if (channel) {
          const data = await configService.GetDimmingAndBrighteningTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<LedControllersLightTransitionTime>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as LedControllersLightTransitionTime });
            setOpen(true);
          });
        }
        break;
      }
      case SlrParamsEnum.TURN_ON_OFF_TIME_ACTIVATION: {
        const data = await configService.GetTurnOnOffTimeActivationAsync(device);

        handleGetSwitchParamsResponse<boolean>(data, () => {
          setConfig({ ...initialConfig, [panel]: data as boolean });
          setOpen(true);
        });
        break;
      }
      case SlrParamsEnum.ENABLE_TIME: {
        if (channel) {
          const data = await configService.GetTurnOnTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as GlobalTimeParameters });
            setOpen(true);
          });
        }
        break;
      }
      case SlrParamsEnum.DISABLE_TIME: {
        if (channel) {
          const data = await configService.GetTurnOffTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as GlobalTimeParameters });
            setOpen(true);
          });
        }
        break;
      }
      case SlrParamsEnum.INPUT_MODE: {
        if (channel) {
          const data = await configService.GetSwitchInputOutputParamAsync(device, channel.Number);

          handleGetSwitchParamsResponse<IInputOutputSwitchParameters[]>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as IInputOutputSwitchParameters[] });
            setOpen(true);
          });
        }
        break;
      }
      case SlrParamsEnum.FLOATING_TIME: {
        if (channel && serviceName === Slr22ConfigService.ServiceName) {
          const data = await (configService as ISlr22ConfigService).GetFloatingTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as GlobalTimeParameters });
            setOpen(true);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: SlrParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case SlrParamsEnum.TURN_ON_BEHAVIOUR: {
        return <TurnOnBehaviourForm device={device} turnOnBehaviour={config[activeConfigName]} />;
      }
      case SlrParamsEnum.DIMMING_AND_BRIGHTNESS: {
        return channel ? (
          <DimmingBrighteningTimeForm device={device} time={config[activeConfigName]} channel={channel} />
        ) : null;
      }
      case SlrParamsEnum.TURN_ON_OFF_TIME_ACTIVATION: {
        return <OnOffActivationControllerForm device={device} activation={config[activeConfigName]} />;
      }
      case SlrParamsEnum.ENABLE_TIME: {
        return <EnablingTimeForm device={device} timeParams={config[activeConfigName]} led={LedTimeType.LedTime} />;
      }
      case SlrParamsEnum.DISABLE_TIME: {
        return <DisablingTimeForm device={device} timeParams={config[activeConfigName]} led={LedTimeType.LedTime} />;
      }
      case SlrParamsEnum.INPUT_MODE: {
        return <InputsModeForm device={device} inputs={config[activeConfigName]} led={LedTimeType.LedTime} />;
      }
      case SlrParamsEnum.FLOATING_TIME: {
        return serviceName === Slr22ConfigService.ServiceName ? (
          <FloatingTimeForm device={device} timeParams={config[activeConfigName]} led={LedTimeType.FloatingTime} />
        ) : null;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.OnOffParams.TurnOnBehaviourParams')}
                onClick={() => handleOpen(SlrParamsEnum.TURN_ON_BEHAVIOUR)}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.DimmingAndBrighteningParams')}
                onClick={() => handleOpen(SlrParamsEnum.DIMMING_AND_BRIGHTNESS)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.EnableTimeParams')}
                onClick={() => handleOpen(SlrParamsEnum.ENABLE_TIME)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.DisableTimeParams')}
                onClick={() => handleOpen(SlrParamsEnum.DISABLE_TIME)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.InputModeParams')}
                onClick={() => handleOpen(SlrParamsEnum.INPUT_MODE)}
              />
              {serviceName === Slr22ConfigService.ServiceName && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.OnOffParams.FloatingTimeParams')}
                  onClick={() => handleOpen(SlrParamsEnum.FLOATING_TIME)}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              <ArrowButton
                className="m-t-24"
                title={t('exalus.params.OnOffParams.TurnOnOffTimeActivationParams')}
                onClick={() => handleOpen(SlrParamsEnum.TURN_ON_OFF_TIME_ACTIVATION)}
              />
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SlrConfigForm;
