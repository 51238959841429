import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import { IconEco } from '../../../../../../../../../components';
import { ecoLevels } from '../../../utils';
import './index.scss';

type EnergyConsumptionTotalValueProps = {
  totalValue: number | undefined;
  ecoIndicator?: number | null;
  unit?: string;
};

export const EnergyConsumptionTotalValue: React.FC<EnergyConsumptionTotalValueProps> = ({
  totalValue,
  ecoIndicator,
  unit,
}) => {
  const { t } = useTranslation('channel-details');
  const style = {
    '--stroke': ecoLevels.find((level) => (ecoIndicator || 0) <= level.maxValue)?.value,
  } as React.CSSProperties;

  return (
    <div className="energy-consumption-total-value">
      <div className="energy-consumption-total-value__text">{t('total')}</div>
      <div className="energy-consumption-total-value__value m-r-4">
        {totalValue !== undefined ? totalValue.toFixed(2) : '-'} {unit || t('kwh')}
      </div>
      {isNumber(ecoIndicator) && <IconEco size={20} style={style} />}
    </div>
  );
};
