import { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindPostionState,
  BlindRemoteButtonState,
  ChannelOnOffState,
  FacadePositionState,
  FacadeRemoteButtonState,
  GatePositionState,
  IDeviceState as IChannelState,
  LightBrightnessState,
  RemoteButtonState,
  RemoteButtonStateEnum,
  ResistanceOrOpticBarStatusEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { hsbToRgb, rgbToHsb } from '../../../../../utils/helpers';
import { GateType, LightType } from '../../../enums';
import { useSomethingWrong } from '../../../modules/channel-list/hooks/use-something-wrong';
import { useTasks } from '../../../modules/channel-list/hooks/use-tasks';
import { getChannelType, getLightType, getPreciseChannelType } from '../../../utils';
import { useMovementControl } from './use-movement-control';
import { useRgbControl } from './use-rgb-control';

export const useChannelBox = (channel: IDeviceChannel) => {
  const { batteryError, gateHealth, connectionIcon, calibrationNeed, channelOrientedError, channelOrientedWarning } =
    useSomethingWrong(channel);
  const { state: rgbState } = useRgbControl(channel);
  const { active } = useTasks(channel.ChannelId);
  const { isTamperProtection } = useMovementControl(channel);

  const isWarning = useMemo(() => !!gateHealth?.Data.PhotoCellsActive, [gateHealth?.Data.PhotoCellsActive]);
  const isError = useMemo(
    () =>
      !!(
        isTamperProtection ||
        batteryError ||
        gateHealth?.Data.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.Active ||
        gateHealth?.Data.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.UnconnectedOrMalfunction ||
        gateHealth?.Data.InertionBreaksOk === false
      ),
    [gateHealth?.Data, batteryError, isTamperProtection],
  );

  const remoteButtonStateTwo = useMemo(() => {
    return channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.RemoteButtonState) as
      | IChannelState<RemoteButtonState>
      | undefined;
  }, [channel.States]);

  const remoteButtonState = useMemo(() => {
    return channel.States?.find(
      (state) =>
        state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState,
    ) as IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState> | undefined;
  }, [channel.States]);

  const percentageBadge = useMemo(() => {
    const channelType = getPreciseChannelType(channel);
    if (channelType === GateType.Gate) return '';

    const positionState = channel.States?.find(
      (state) =>
        state.TypeAsEnum === ChannelResponseType.BlindPosition ||
        state.TypeAsEnum === ChannelResponseType.FacadePosition ||
        state.TypeAsEnum === ChannelResponseType.GatePosition,
    );

    if (positionState !== undefined) {
      const position = (positionState.Data as BlindPostionState | FacadePositionState | GatePositionState).Position;
      return position <= 100 ? `${position}%` : '';
    }

    const brightnessState = channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.LightBrightness);

    if (brightnessState !== undefined) return `${(brightnessState.Data as LightBrightnessState).Brightness}%`;
  }, [channel.States]);

  const lightState = useMemo(() => {
    return channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.ChannelOnOffState) as
      | IChannelState<ChannelOnOffState>
      | undefined;
  }, [channel.States]);

  const isRgb = useMemo(() => getLightType(channel) === LightType.Rgb, [channel]);
  const type = useMemo(() => getChannelType(channel), [channel]);

  const rgbStyle = useMemo(() => {
    let activeColor = '';

    if (rgbState) {
      const hsb = rgbToHsb([rgbState.R.toString(), rgbState.G.toString(), rgbState.B.toString()]);
      activeColor = hsbToRgb([hsb[0], hsb[1] / 100, rgbState.Brightness / 100]).join(',');
    }

    return activeColor
      ? ({
          '--rgb-color': activeColor ? `rgb(${activeColor})` : 'var(--color-element)',
        } as React.CSSProperties)
      : null;
  }, [rgbState]);

  const externalLimitSwitchActive = useMemo(
    () => !!(gateHealth?.Data.ExternalLimitSwitchAActive || gateHealth?.Data.ExternalLimitSwitchBActive),
    [gateHealth],
  );

  return {
    isWarning,
    isError,
    remoteButtonState,
    isTaskActive: active || remoteButtonStateTwo?.Data.State === RemoteButtonStateEnum.Pressed,
    percentageBadge,
    lightState,
    isRgb,
    type,
    rgbStyle,
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    channelOrientedWarning,
    externalLimitSwitchActive,
  };
};
