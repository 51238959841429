import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { EnergyMeasurementParameter } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { MessageByType } from '../../../../../types/actions';

export const supportedResponseType = [
  DeviceResponseType.MeasuredTemperature,
  DeviceResponseType.HumiditySensorState,
  DeviceResponseType.FloodSensorState,
  DeviceResponseType.MeasuredBrightness,
  DeviceResponseType.RemoteButtonState,
  DeviceResponseType.ChannelOnOffState,
  DeviceResponseType.MeasuredDistance,
  DeviceResponseType.DoorBellState,
  DeviceResponseType.MeasuredEnergy,
  DeviceResponseType.FacadePosition,
  DeviceResponseType.GatePosition,
  DeviceResponseType.LightBrightness,
  DeviceResponseType.MovementSensorState,
  DeviceResponseType.PressureSensorState,
  DeviceResponseType.ReedState,
  DeviceResponseType.SmokeSensorState,
  DeviceResponseType.TamperProtectionState,
  DeviceResponseType.MeasuredWindSpeed,
  DeviceResponseType.WindThreshold,
  DeviceResponseType.BlindPosition,
];

export const messageByType: Record<string, MessageByType> = {
  [DeviceResponseType.MeasuredTemperature]: {
    key: 'label_measuredTemperature',
    unit: '°C',
  },
  [DeviceResponseType.HumiditySensorState]: {
    key: 'label_humiditySensorState',
    unit: '%',
  },
  [DeviceResponseType.FloodSensorState]: {
    key: 'label_floodSensorState',
    unit: '',
  },
  [DeviceResponseType.MeasuredBrightness]: {
    key: 'label_measuredBrightness',
    unit: 'lux',
  },
  [DeviceResponseType.RemoteButtonState]: {
    key: 'label_remoteButtonState',
    unit: '',
  },
  [DeviceResponseType.ChannelOnOffState]: {
    key: 'label_channelOnOffState',
    unit: '',
  },
  [DeviceResponseType.MeasuredDistance]: {
    key: 'label_measuredDistance',
    unit: 'cm',
  },
  [DeviceResponseType.DoorBellState]: {
    key: 'label_doorBellState',
    unit: '',
  },
  [DeviceResponseType.MeasuredEnergy]: {
    key: 'label_measuredEnergy',
    unit: '',
    more: {
      [EnergyMeasurementParameter.Voltage]: {
        key: 'label_voltage',
        unit: 'V',
      },
      [EnergyMeasurementParameter.Current]: {
        key: 'label_current',
        unit: 'A',
      },
      [EnergyMeasurementParameter.PhaseShift]: {
        key: 'label_phaseShift',
        unit: '°',
      },
      [EnergyMeasurementParameter.ActivePower]: {
        key: 'label_activePower',
        unit: 'W',
      },
      [EnergyMeasurementParameter.ReactivePower]: {
        key: 'label_reactivePower',
        unit: 'var',
      },
      [EnergyMeasurementParameter.ApparentPower]: {
        key: 'label_apparentPower',
        unit: 'VA',
      },
      [EnergyMeasurementParameter.PowerFactor]: {
        key: 'label_powerFactor',
        unit: '',
      },
      [EnergyMeasurementParameter.ActiveForwardEnergy]: {
        key: 'label_activeForwardEnergy',
        unit: 'kWh',
      },
      [EnergyMeasurementParameter.ActiveReverseEnergy]: {
        key: 'label_activeReverseEnergy',
        unit: 'J',
      },
      [EnergyMeasurementParameter.ApparentEnergy]: {
        key: 'label_apparentEnergy',
        unit: 'J',
      },
      [EnergyMeasurementParameter.ReactiveForwardEnergy]: {
        key: 'label_reactiveForwardEnergy',
        unit: 'kvarh',
      },
      [EnergyMeasurementParameter.ReactiveReverseEnergy]: {
        key: 'label_reactiveReverseEnergy',
        unit: 'J',
      },
      [EnergyMeasurementParameter.Frequency]: {
        key: 'label_frequency',
        unit: 'Hz',
      },
      [EnergyMeasurementParameter.SPIErrorRate]: {
        key: 'label_sPIErrorRate',
        unit: '',
      },
      [EnergyMeasurementParameter.CRCErrorRate]: {
        key: 'label_cRCErrorRate',
        unit: '',
      },
      [EnergyMeasurementParameter.Timestamp]: {
        key: 'label_timestamp',
        unit: '',
      },
      [EnergyMeasurementParameter.FailedMeasurementsRate]: {
        key: 'label_failedMeasurementsRate',
        unit: '',
      },
      [EnergyMeasurementParameter.FundamentalActivePower]: {
        key: 'label_fundamentalActivePower',
        unit: 'W',
      },
      [EnergyMeasurementParameter.HarmonicActivePower]: {
        key: 'label_harmonicActivePower',
        unit: 'W',
      },
    },
  },
  [DeviceResponseType.FacadePosition]: {
    key: 'label_facadePosition',
    unit: '%',
  },
  [DeviceResponseType.GatePosition]: {
    key: 'label_gatePosition',
    unit: '%',
  },
  [DeviceResponseType.LightBrightness]: {
    key: 'label_lightBrightness',
    unit: '%',
  },
  [DeviceResponseType.MovementSensorState]: {
    key: 'label_movementSensorState',
    unit: '',
  },
  [DeviceResponseType.PressureSensorState]: {
    key: 'label_pressureSensorState',
    unit: 'hPa',
  },
  [DeviceResponseType.ReedState]: {
    key: 'label_reedState',
    unit: '',
  },
  [DeviceResponseType.SmokeSensorState]: {
    key: 'label_smokeSensorState',
    unit: '',
  },
  [DeviceResponseType.TamperProtectionState]: {
    key: 'label_tamperProtectionState',
    unit: '',
  },
  [DeviceResponseType.MeasuredWindSpeed]: {
    key: 'label_measuredWindSpeed',
    unit: 'm/s',
  },
  [DeviceResponseType.WindThreshold]: {
    key: 'label_windThreshold',
    unit: '',
  },
  [DeviceResponseType.BlindPosition]: {
    key: 'label_blindPosition',
    unit: '%',
  },
};

export const specialPhrasePattern = /\{[^}]+\}/g;
