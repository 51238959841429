import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/IRob21ConfigService';
import {
  LimitSwitchConfiguration,
  TypeOfGateChannel,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { toastError } from '../../../../../../utils/toast';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import LimitingSwitchesForm from './limiting-switches';
import OpeningTimeForm from './opening-time';
import PulseTimeForm from './pulse-time';
import TimeBetweenForm from './time-between';
import { initialConfig, Rob21ConfigParams, Rob21ParamsEnum } from './types';
import WorkingModeForm from './working-mode';

type Rob21ConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const Rob21ConfigForm: React.FC<Rob21ConfigFormProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<Rob21ConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<TypeOfGateChannel | undefined>(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  useEffect(() => {
    const getMode = async () => {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
        Rob21ConfigService.ServiceName,
      );

      if (channel) {
        const data = await configService.GetGateModeAsync(device, channel.Number);

        handleGetParamsResponse<TypeOfGateChannel>(data, () => {
          if (
            (data as TypeOfGateChannel) !== TypeOfGateChannel.Gate &&
            (data as TypeOfGateChannel) !== TypeOfGateChannel.Gateway &&
            (data as TypeOfGateChannel) !== TypeOfGateChannel.Monostable
          ) {
            toastError({ content: 'Error' });
            setMode(undefined);
          } else {
            setMode(data as TypeOfGateChannel);
          }
        });
      }

      turnOffBackdrop();
    };

    getMode();
  }, [channel]);

  const getDefaultParams = async (panel: Rob21ParamsEnum) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
      Rob21ConfigService.ServiceName,
    );

    switch (panel) {
      case Rob21ParamsEnum.WORKING_MODE: {
        setConfig({ ...initialConfig, [panel]: mode as TypeOfGateChannel });
        setOpen(true);
        break;
      }
      case Rob21ParamsEnum.PULSE_TIME: {
        if (channel) {
          const data = await configService.GetPulseTimeAsync(device, channel.Number);

          handleGetParamsResponse<number>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as number });
            setOpen(true);
          });
        }
        break;
      }
      case Rob21ParamsEnum.LIMITING_SWITCHES: {
        if (channel) {
          const data = await configService.GetLimitingSwitchesConfigurationsAsync(device, channel.Number);

          handleGetParamsResponse<LimitSwitchConfiguration[]>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as LimitSwitchConfiguration[] });
            setOpen(true);
          });
        }
        break;
      }
      case Rob21ParamsEnum.TIME_BETWEEN_PULSES: {
        if (channel) {
          const data = await configService.GetTimeBetweenPulsesAsync(device, channel.Number);

          handleGetParamsResponse<number>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as number });
            setOpen(true);
          });
        }
        break;
      }
      case Rob21ParamsEnum.OPENING_TIME: {
        if (channel) {
          const data = await configService.GetOpeningTime(device, channel.Number);

          handleGetParamsResponse<number>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as number });
            setOpen(true);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: Rob21ParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case Rob21ParamsEnum.WORKING_MODE: {
        return channel ? (
          <WorkingModeForm device={device} channel={channel} workingMode={config[activeConfigName]} />
        ) : null;
      }
      case Rob21ParamsEnum.PULSE_TIME: {
        return channel ? (
          <PulseTimeForm device={device} channel={channel} pulseTime={config[activeConfigName]} />
        ) : null;
      }
      case Rob21ParamsEnum.TIME_BETWEEN_PULSES: {
        return channel ? (
          <TimeBetweenForm device={device} channel={channel} timeBetween={config[activeConfigName]} />
        ) : null;
      }
      case Rob21ParamsEnum.OPENING_TIME: {
        return channel ? (
          <OpeningTimeForm device={device} channel={channel} openingTime={config[activeConfigName]} />
        ) : null;
      }
      case Rob21ParamsEnum.LIMITING_SWITCHES: {
        return <LimitingSwitchesForm device={device} switches={config[activeConfigName]} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.Rob21Params.WorkingMode')}
                onClick={() => handleOpen(Rob21ParamsEnum.WORKING_MODE)}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rob21Params.PulseTimeParams')}
                onClick={() => handleOpen(Rob21ParamsEnum.PULSE_TIME)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rob21Params.LimitingSwitches')}
                onClick={() => handleOpen(Rob21ParamsEnum.LIMITING_SWITCHES)}
              />
              {mode === TypeOfGateChannel.Gateway && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.Rob21Params.TimeBetweenPulsesParams')}
                  onClick={() => handleOpen(Rob21ParamsEnum.TIME_BETWEEN_PULSES)}
                />
              )}
              {mode === TypeOfGateChannel.Gateway && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.Rob21Params.OpeningTimeParams')}
                  onClick={() => handleOpen(Rob21ParamsEnum.OPENING_TIME)}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rob21ConfigForm;
