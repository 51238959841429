import {
  ChannelTypeInternal,
  GateKindInternal,
  GatePersonalization,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { getGateMode } from '../../../modules/channel-details/gate/utils';
import { ChannelType, ChannelStateType, GateChannelStateResponseCustom } from '../../../types';

export const parseGateChannel = (userChannel: UserChannel): ChannelType => {
  const channelPayload = userChannel.payload as GateChannelStateResponseCustom | undefined;
  const personalization = userChannel.personalization as GatePersonalization | undefined;

  return {
    id: userChannel.id,
    installationId: userChannel.installationId,
    isVisible: userChannel.isVisible,
    alias: userChannel.alias,
    iconName: userChannel.iconName || ChannelIconTypeEnum.ICON_VERTICAL_GATE,
    deviceId: userChannel.deviceId,
    currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
    data: {
      type: ChannelTypeInternal.Gate,
      gateMode: getGateMode(channelPayload?.supportedGateFeatures || [], channelPayload?.gateMode),
      supportedGateFeatures: channelPayload?.supportedGateFeatures || [],
      gateKind: channelPayload?.gateKind || GateKindInternal.Unknown,
      sbsSignalTimeInMs: channelPayload?.sbsSignalTimeInMs || null,
      partialSbsTimeInMs: channelPayload?.partialSbsTimeInMs || null,
      tiltTimeInMs: channelPayload?.tiltTimeInMs || null,
      alertConfig: channelPayload?.alertConfig || null,
      history: channelPayload?.history || undefined,
      isQuickControl: personalization?.isQuickControl || false,
      isPartialControlModeVisible: personalization?.isPartialControlModeVisible || false,
      isDailyModeVisible: personalization?.isDailyModeVisible || false,
      onEventAction: personalization?.onEventAction || null,
      dependantChannels: channelPayload?.dependantChannels || [],
    },
  };
};

export const parseGateChannelState = (userChannel: UserChannel): ChannelStateType => {
  const channelPayload = userChannel.payload as GateChannelStateResponseCustom | undefined;

  return {
    direction: channelPayload?.gateDirection,
    position: channelPayload?.position,
    partialControlModeStatus: !!channelPayload?.partialControlModeStatus,
    dailyModeStatus: channelPayload?.dailyModeStatus || null,
    lockStatus: channelPayload?.lockStatus || null,
    calibrateState: channelPayload?.calibrateState || undefined,
    movingParams: {
      predictedTimeInMs: null,
      targetPosition: null,
    },
    deviceConnectionState: userChannel.payload?.deviceConnectionState,
    usesCounter: userChannel.usesCounter,
    grantedUses: userChannel.grantedUses || undefined,
  };
};
