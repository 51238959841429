import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import './index.scss';

type EmptyStateProps = {
  missingData?: boolean;
};

export const EmptyState: React.FC<EmptyStateProps> = ({ missingData }) => {
  const { t } = useTranslation('channel-details');
  const list = [0, 1, 2, 3, 4, 5, 6];

  return (
    <div className="empty-state">
      <div className="empty-state__background">
        <div className="empty-state__background-row">
          <div className="empty-state__background-vertical">
            {list.map((index) => (
              <span key={index}>X</span>
            ))}
          </div>
          <div className="empty-state__background-bars">
            {list.map((index) => (
              <span
                className={c(`empty-state__background-bar empty-state__background-bar--${index + 1}`)}
                key={index}
              ></span>
            ))}
          </div>
        </div>
        <div className="empty-state__background-horizontal">
          {list.map((index) => (
            <span key={index}>X</span>
          ))}
        </div>
      </div>
      <div className="empty-state__heading">
        <p>{t('chartEmptyState.header')}</p>
        {!missingData && <p>{t('chartEmptyState.subHeader')}</p>}
      </div>
    </div>
  );
};
