import React, { Fragment } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Checkbox,
  DialogConfirmation,
  FromToInputForm,
  IconActions,
  IconInfinity,
  NavHeader,
  Page,
  SelectInputController,
  SubmitButton,
} from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { DialogValuePicker } from '../../../../../components/dialog-value-picker';
import { useSelectedDays } from '../../trigger-form/hooks/use-selected-days';
import { useAddEditSchedule } from '../hooks/use-add-edit-schedule';
import { useScheduleValues } from '../hooks/use-schedule-values';
import './index.scss';

export const OptimizerScheduleStep1: React.FC = () => {
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const { daysOfWeek } = useSelectedDays();
  const { actionValueParameters } = useScheduleValues();
  const {
    form,
    actions,
    actionValuePopup,
    action,
    actionValue,
    repeatPopup,
    selectedDaysTemp,
    selectedDaysTitle,
    scheduleId,
    errors,
    handleOpenRepeatPopup,
    handleCheckDay,
    setRepeatPopup,
    setActionValuePopup,
    handleScheduleSubmit,
    handleRepeatDaysSave,
  } = useAddEditSchedule();

  return (
    <Page
      className="optimizer-schedule step1"
      isStickyHeader
      header={<NavHeader title={t(`schedule.${scheduleId ? 'edit' : 'add'}Schedule`)} />}
    >
      <FormProvider {...form}>
        <form onSubmit={handleScheduleSubmit}>
          <FromToInputForm />
          <hr className="m-b-24" />
          <ArrowButton
            title={selectedDaysTitle || t('schedule.repeatEveryDay')}
            icon={<IconInfinity />}
            onClick={handleOpenRepeatPopup}
            className={classNames('m-b-24', { error: errors.days })}
          />
          <SelectInputController
            name="action"
            label={t('schedule.selectAction')}
            options={actions}
            isRequired
            inputComponent={
              <ArrowButton
                title={
                  actionValue ? `${actionValue} ${actionValueParameters[action].unit}` : t('schedule.selectAction')
                }
                icon={actionValueParameters[action].icon || <IconActions />}
                className={classNames('m-b-24', { error: errors.action })}
              />
            }
          />
          {actionValuePopup && (
            <DialogValuePicker
              open={actionValuePopup}
              setOpen={setActionValuePopup}
              title={t(`schedule.actions.${action.toLowerCase()}`)}
              onSave={(value) => form.setValue('actionValue', value)}
              value={actionValue}
              unit={actionValueParameters[action].unit}
              {...(actionValueParameters[action].step !== undefined
                ? { step: actionValueParameters[action].step }
                : {})}
              {...(actionValueParameters[action].min !== undefined ? { min: actionValueParameters[action].min } : {})}
              {...(actionValueParameters[action].max !== undefined ? { max: actionValueParameters[action].max } : {})}
            />
          )}
          <DialogConfirmation
            show={repeatPopup}
            setShow={setRepeatPopup}
            header={t('schedule.repeatingOnSelectedDays')}
            secondaryBtnText={tc('buttons.cancel')}
            primaryBtnText={tc('buttons.save')}
            secondaryBtnAction={() => setRepeatPopup(false)}
            primaryBtnAction={handleRepeatDaysSave}
          >
            {daysOfWeek.map((x, index) => (
              <Fragment key={x.label}>
                <Checkbox
                  reverse
                  checked={selectedDaysTemp.includes(x.value)}
                  onChange={() => handleCheckDay(x.value)}
                  labelBold
                >
                  {x.label}
                </Checkbox>
                {index !== daysOfWeek.length - 1 && <hr className="m-b-24" />}
              </Fragment>
            ))}
          </DialogConfirmation>
          <SubmitButton>{tc(`buttons.${scheduleId ? 'edit' : 'add'}`)}</SubmitButton>
        </form>
      </FormProvider>
    </Page>
  );
};
