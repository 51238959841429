import React from 'react';
import { useBackdropContext } from '../../../../../context/backdrop';
import LinearProgressCustom from '../../linear-progress';

export const DownloadContent: React.FC = () => {
  const { exalusPopupContent } = useBackdropContext();

  if (!exalusPopupContent?.downloadContent) return null;

  return (
    <>
      <div className="backdrop-popup--info">{exalusPopupContent?.message}</div>
      <LinearProgressCustom
        isCorrectLoader
        progress={exalusPopupContent?.downloadContent?.progress}
        updateName={''}
        speedMbps={exalusPopupContent?.downloadContent?.speedMbps}
      />
    </>
  );
};
