import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useNotifications } from '../../../hooks';
import { useNotificationsOperations } from '../../../hooks/notifications/use-notifications-operations';
import { ROUTES } from '../../../routes';

export const useNotificationList = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
  const [showClearAllDialog, setShowClearAllDialog] = useState<boolean>(false);
  const { markAllDisplayed, markAllHidden } = useNotificationsOperations();
  const { notifications, hasNextPage, loading, fetchMoreData, setNotifications } =
    useNotifications(activeTabIndex);

  useEffect(() => {
    const tabIndex = new URLSearchParams(search).get('tab');
    setActiveTabIndex(tabIndex ? parseInt(tabIndex) : 0);
  }, [search]);

  const handleMarkAllAsDisplayed = () =>
    markAllDisplayed({
      onSuccess: () => {
        setNotifications((prev) => {
          const tempList = cloneDeep(prev);

          return tempList.map((x) => ({
            ...x,
            wasDisplayed: true,
          }));
        });
      },
    });

  const onClearAllCancel = () => setShowClearAllDialog(false);

  const onClearAll = () => {
    markAllHidden({
      onSuccess: () => {
        setNotifications((prev) => {
          const tempList = cloneDeep(prev);

          return tempList.map((x) => ({
            ...x,
            isHidden: true,
          }));
        });

        setShowClearAllDialog(false);
      },
    });
  };

  const handleChangeTab = (tabIndex: number) => {
    navigate(`${ROUTES.Notification()}?tab=${tabIndex}`, { replace: true });
  };

  return {
    activeTabIndex,
    showClearAllDialog,
    notificationList: notifications,
    hasNextPage,
    loading,
    onClearAll,
    onClearAllCancel,
    handleChangeTab,
    setShowClearAllDialog,
    handleMarkAllAsDisplayed,
    fetchMoreData,
    setNotifications,
  };
};
