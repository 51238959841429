import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../../../../../../components';
import { OptimizerConfigurationPhaseLoadInput } from './input';

export const OptimizerConfigurationPhaseLoad: React.FC = () => {
  const { t } = useTranslation('channel-details');

  return (
    <>
      <Header title={t('optimizer.configuration.phaseLoad')} />
      <div className="grid-list-24">
        {[0, 1, 2].map((index) => (
          <OptimizerConfigurationPhaseLoadInput key={index} label={`L${index + 1}`} name={`phase${index + 1}`} />
        ))}
      </div>
    </>
  );
};
