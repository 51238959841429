import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DialogConfirmation, Header, Input, NavHeader, Page, SubmitButton } from '../../../../components';
import { useInstallationContext } from '../../../../context/installation';
import {
  ChannelAction,
  EditActionMutation,
  EditActionMutationVariables,
  UserAction,
} from '../../../../data-access/gql-types/graphql';
import { EDIT_ACTION } from '../../../../data-access/mutations/action';
import { ACTION_DETAILS, ALL_ACTIONS } from '../../../../data-access/queries/actions';
import { useMutationErrors } from '../../../../hooks';
import { useRefetchData } from '../../../../hooks/refresh-data';
import { MutationErrorType } from '../../../../types';
import { toastError } from '../../../../utils/toast';
import { useApi } from '../../hooks';

const ActionEditName: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallationContext();
  const { actionId } = useParams<{ actionId: string }>();
  const { userActions } = useApi();
  const { handleErrors } = useMutationErrors(MutationErrorType.Action);
  const [action, setAction] = useState<UserAction>();
  const { refetchActions } = useRefetchData();
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange', defaultValues: { name: '' } });

  const [editAction, { loading }] = useMutation<EditActionMutation, EditActionMutationVariables>(EDIT_ACTION, {
    refetchQueries: [
      {
        query: ACTION_DETAILS,
        variables: {
          actionId,
          installationId: selectedInstallationId,
        },
      },
      {
        query: ALL_ACTIONS,
        variables: {
          installationId: selectedInstallationId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: async (data) => {
      if (!data || !data.editAction) {
        toastError({ content: tc('errors.somethingWentWrong') });
        return;
      }

      if (data.editAction.idResponse?.id) {
        await refetchActions();
        navigate(-1);
      } else {
        handleErrors(data.editAction?.errors || []);
      }
    },
  });

  useEffect(() => {
    const data = userActions?.find((userAction) => userAction.action.id === actionId) as UserAction;
    if (data) {
      setAction(data);
    }
  }, [setAction, userActions, selectedInstallationId]);

  const onSubmit = () => setShowSaveDialog(true);

  const onCancel = () => setShowSaveDialog(false);

  const onSave = ({ name }) => {
    if (action) {
      editAction({
        variables: {
          input: {
            id: actionId,
            installationId: action?.action.installationId,
            iconName: action.iconName,
            channels: action.action.channelActions.map((channel: ChannelAction) => ({
              channelId: channel.channelId,
              channelType: channel.channelType,
              order: channel.order,
              parameter: { activityType: channel.activityType, value: channel.value },
            })),
            name,
          },
        },
      });
    }
  };

  if (!action) return null;

  return (
    <Page
      header={
        <>
          <NavHeader />
          <Header title={t('basicActionForm.edit')} isUnderline />
        </>
      }
    >
      <div className="action-form__devices">
        <form>
          <Controller
            name="name"
            control={control}
            rules={{ required: tc('isRequired') as string }}
            defaultValue={action.action.name || ''}
            render={({ field, fieldState: { error } }) => (
              <Input
                defaultValue={action?.action.name || ''}
                label={t('basicActionForm.label')}
                placeholder={t('basicActionForm.label')}
                {...(error ? { errorMessage: error.message } : {})}
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <SubmitButton type={'button'} disabled={!isDirty || !isValid} isLoading={loading} onClick={onSubmit} />
        </form>
        <DialogConfirmation
          show={showSaveDialog}
          setShow={setShowSaveDialog}
          header={t('confirmDialog.header')}
          primaryBtnText={tc('buttons.save')}
          secondaryBtnText={tc('buttons.cancel')}
          primaryBtnAction={handleSubmit(onSave)}
          secondaryBtnAction={onCancel}
        />
      </div>
    </Page>
  );
};

export default ActionEditName;
