import React, { useMemo, useState, PointerEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { FacadeType, FacadeTypeDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceControlFeature } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { FacadeActionEnum, SetFacadePosition } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, CoverButton, IconChevron, Slider, SubmitButton } from '../../../../../../../../components';
import { defaultFavouritePositionList } from '../../../../../../../../const';
import { DeviceTaskParams, FacadeStateTask } from '../../../../../../../../types/actions';
import { SlatsSection } from '../../../../../../components/channel/controls/facade/details/components/slats-section';
import { useExalusCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';

export const FacadeSettings: React.FC = () => {
  const { t } = useTranslation('common');
  const { channels } = useExalusCreateActionContext();
  const [facadeAction, setFacadeAction] = useState<FacadeActionEnum>(FacadeActionEnum.TiltAndPercentage);
  const [tiltValue, setTiltValue] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      const action = ((task.taskParams as DeviceTaskParams).state as FacadeStateTask).action;
      setFacadeAction(action);
      setSliderValue(((task.taskParams as DeviceTaskParams).state as FacadeStateTask).position);
      setTiltValue(((task.taskParams as DeviceTaskParams).state as FacadeStateTask).tilt);

      switch (action) {
        case FacadeActionEnum.Open:
          setSliderValue(0);
          break;
        case FacadeActionEnum.Close:
          setSliderValue(100);
          break;
        default:
          setSliderValue(((task.taskParams as DeviceTaskParams).state as FacadeStateTask).position);
      }
    }
  }, [task]);

  const facadeType = useMemo(() => {
    if (channels.length)
      return channels[0]?.States.find(
        (state) => state.TypeAsEnum === ChannelResponseType.FacadeType,
      ) as FacadeTypeDeviceState;
  }, [channels]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFacadeAction(FacadeActionEnum.TiltAndPercentage);
    setSliderValue(Number(event.target.value));
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    setFacadeAction(FacadeActionEnum.TiltAndPercentage);
    setSliderValue(Number((event.target as HTMLInputElement).value));
  };

  const handleConstPercentageChange = (pos: number) => {
    setFacadeAction(FacadeActionEnum.TiltAndPercentage);
    setSliderValue(pos);
  };

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task = new SetFacadePosition();
      task.DeviceGuid = channels[i].GetDevice().Guid;
      task.Channel = channels[i].Number;
      task.TaskType = DeviceTaskType.SetFacadePosition;
      task.FeatureType = DeviceControlFeature.SetFacadePositionAndTilt;
      task.FacadeAction = facadeAction;
      task.Tilt = tiltValue;
      if (facadeAction === FacadeActionEnum.TiltAndPercentage) task.Position = sliderValue;

      finalizeDeviceTaskSubmit(
        task,
        channels[i],
        {
          position: sliderValue,
          action: facadeAction,
          tilt: tiltValue,
        } as FacadeStateTask,
        i === channels.length - 1,
      );
    }
  };

  return (
    <>
      <ControlWrapper className="control-wrapper--space-evenly control-wrapper--full-space">
        <div className="slider-section">
          <Slider
            onPointerUp={handleSliderEvent}
            value={sliderValue}
            onChange={onChange}
            showValue={false}
            className="slider--width-auto"
          />
        </div>
        <SlatsSection
          tiltValue={tiltValue}
          fullRange={facadeType?.Data.FType === FacadeType.Facade180}
          defaultValue={100 - tiltValue}
          value={100 - tiltValue}
          onChange={(_, value) => {
            setTiltValue(100 - (value as number));
          }}
        />
      </ControlWrapper>
      <div className="blind-direction-buttons p-l-24 p-r-24">
        <button>
          <IconChevron
            withCircle
            direction="up"
            onClick={() => {
              setSliderValue(0);
              setFacadeAction(FacadeActionEnum.Open);
            }}
          />
        </button>
        <button onClick={() => setFacadeAction(FacadeActionEnum.Stop)}>{t('status.stop').toUpperCase()}</button>
        <button>
          <IconChevron
            withCircle
            direction="down"
            onClick={() => {
              setSliderValue(100);
              setFacadeAction(FacadeActionEnum.Close);
            }}
          />
        </button>
      </div>
      <div className="blind-percentage-buttons p-l-24 p-r-24">
        {defaultFavouritePositionList.map((pos) => (
          <CoverButton key={pos} position={pos} onClick={() => handleConstPercentageChange(pos)}>
            <span>{pos}%</span>
          </CoverButton>
        ))}
      </div>
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
