import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Carousel, CarouselItem, EmptyStateBox, IconHeart, IconWarning } from '../../../../../components';
import { useInstallationContext } from '../../../../../context/installation';
import { UserGroup, UserPermissionType } from '../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../routes';
import { isDevelopment } from '../../../../../utils/helpers/environment';
import { EcoBox } from '../../../components/eco/box';
import { useApi } from '../../../hooks';
import { DashboardItems } from './items';

type DashboardCarouselProps = {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  isListView: boolean;
};

export const DashboardCarousel: React.FC<DashboardCarouselProps> = ({
  activeTabIndex,
  setActiveTabIndex,
  isListView,
}) => {
  const { t: tg } = useTranslation('groups');
  const { dashboardLoading, groups } = useApi();
  const { installationsLoading } = useInstallationContext();
  const { permissions } = useUserPermissions();

  const canEdit = (userGroup: UserGroup) =>
    (userGroup.group.isPublicGroup && permissions[UserPermissionType.PublicGroupEdit]) ||
    (!userGroup.group.isPublicGroup && permissions[UserPermissionType.GroupEdit]);

  return (
    <>
      {!dashboardLoading && !installationsLoading && (
        <Carousel setActiveIndex={setActiveTabIndex} activeIndex={activeTabIndex}>
          {groups?.map((userGroup) => {
            return (
              <CarouselItem key={userGroup.id} className={'m-t-8'}>
                {userGroup.userGroupItems.length === 0 && (
                  <>
                    {userGroup.group.isDefaultGroup ? (
                      <EmptyStateBox
                        header={tg('favourites.header')}
                        btnText={tg('favourites.add')}
                        content={tg('favourites.emptyState')}
                        icon={<IconHeart isEmpty />}
                        linkTo={ROUTES.FavouritesForm(userGroup.id)}
                      />
                    ) : (
                      <EmptyStateBox
                        header={tg('custom.header')}
                        btnText={tg('custom.add')}
                        content={tg(`custom.${canEdit(userGroup) ? 'emptyState' : 'emptyStateNoPermission'}`)}
                        icon={<IconWarning />}
                        {...(canEdit(userGroup) ? { linkTo: ROUTES.GroupEdit(userGroup.id) } : {})}
                      />
                    )}
                  </>
                )}
                {userGroup.userGroupItems.length > 0 && (
                  <div
                    className={classNames('p-b-24 dashboard__device-list', {
                      'grid grid--2 grid--small-gap': !isListView,
                      'grid-list-16': isListView,
                    })}
                  >
                    {userGroup.group.isDefaultGroup && isDevelopment ? (
                      <EcoBox level={0} isListItem={isListView} />
                    ) : null}
                    <DashboardItems group={userGroup} isListView={isListView} />
                  </div>
                )}
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
    </>
  );
};
