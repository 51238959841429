import { useTranslation } from 'react-i18next';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { UpdateErrorCode } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { IconError, IconWarning } from '../../../../../components';
import { useBackdropContext } from '../../../../../context/backdrop';
import { toastError, toastInfo } from '../../../../../utils/toast';

export const useUpdateErrors = () => {
  const { t } = useTranslation('device-info');
  const { turnOffBackdrop } = useBackdropContext();

  const showUpdateError = (data: ResponseResult<UpdateErrorCode>, responseMessage?: boolean) => {
    turnOffBackdrop();

    switch (data.Type) {
      case UpdateErrorCode.DeviceIsUpToDate: {
        if (responseMessage) return { message: t('exalus.updateResult.DeviceIsUpToDate'), icon: <IconWarning /> };
        else toastInfo({ content: t('exalus.updateResult.DeviceIsUpToDate') });
        break;
      }
      case UpdateErrorCode.CannotFindDevice: {
        if (responseMessage) return { message: t('exalus.updateResult.CannotFindDevice'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotFindDevice') });
        break;
      }
      case UpdateErrorCode.UnsupportedApiVersion: {
        if (responseMessage) return { message: t('exalus.updateResult.UnsupportedApiVersion'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.UnsupportedApiVersion') });
        break;
      }
      case UpdateErrorCode.UnsupportedDevice: {
        if (responseMessage) return { message: t('exalus.updateResult.UnsupportedDevice'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.UnsupportedDevice') });
        break;
      }
      case UpdateErrorCode.CannotGetFirmwareVersion: {
        if (responseMessage) return { message: t('exalus.updateResult.CannotGetFirmwareVersion'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotGetFirmwareVersion') });
        break;
      }
      case UpdateErrorCode.CannotGetUpdates: {
        if (responseMessage) return { message: t('exalus.updateResult.CannotGetUpdates'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotGetUpdates') });
        break;
      }
      case UpdateErrorCode.CannotProcessUpdate: {
        if (responseMessage) return { message: t('exalus.updateResult.CannotProcessUpdate'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotProcessUpdate') });
        break;
      }
      case UpdateErrorCode.UpdateActionsNotSupported: {
        if (responseMessage)
          return { message: t('exalus.updateResult.UpdateActionsNotSupported'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.UpdateActionsNotSupported') });
        break;
      }
      case UpdateErrorCode.UnsupportedExtensionVersion: {
        if (responseMessage)
          return { message: t('exalus.updateResult.UnsupportedExtensionVersion'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.UnsupportedExtensionVersion') });
        break;
      }
      case UpdateErrorCode.UpdatesPartiallyInstalled: {
        if (responseMessage)
          return { message: t('exalus.updateResult.UpdatesPartiallyInstalled'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.UpdatesPartiallyInstalled') });
        break;
      }
      case UpdateErrorCode.ProtocolRadioUnsupported: {
        if (responseMessage) return { message: t('exalus.updateResult.ProtocolRadioUnsupported'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.ProtocolRadioUnsupported') });
        break;
      }
      case UpdateErrorCode.CannotGetCurrentControllerVersionTimeout: {
        if (responseMessage)
          return { message: t('exalus.updateResult.CannotGetCurrentControllerVersionTimeout'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotGetCurrentControllerVersionTimeout') });
        break;
      }
      case UpdateErrorCode.CannotGetCurrentControllerVersion: {
        if (responseMessage)
          return { message: t('exalus.updateResult.CannotGetCurrentControllerVersion'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotGetCurrentControllerVersion') });
        break;
      }
      case UpdateErrorCode.FeatureUnsupportedInCurrentVersion: {
        if (responseMessage)
          return { message: t('exalus.updateResult.FeatureUnsupportedInCurrentVersion'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.FeatureUnsupportedInCurrentVersion') });
        break;
      }
      case UpdateErrorCode.CannotGetUpdatesBranch: {
        if (responseMessage) return { message: t('exalus.updateResult.CannotGetUpdatesBranch'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotGetUpdatesBranch') });
        break;
      }
      case UpdateErrorCode.CannotSetUpdatesBranch: {
        if (responseMessage) return { message: t('exalus.updateResult.CannotSetUpdatesBranch'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.CannotSetUpdatesBranch') });
        break;
      }
      case UpdateErrorCode.UpdateDownloadFailed: {
        if (responseMessage) return { message: t('exalus.updateResult.UpdateDownloadFailed'), icon: <IconError /> };
        else toastError({ content: t('exalus.updateResult.UpdateDownloadFailed') });
        break;
      }
      default:
        break;
    }
  };

  const handleUpdatesResponse = <T,>(data: T | ResponseResult<UpdateErrorCode>, onSuccess: () => void) => {
    if (typeof (data as ResponseResult<UpdateErrorCode>).Type === 'number') {
      showUpdateError(data as ResponseResult<UpdateErrorCode>);
    } else {
      onSuccess();
    }
  };

  return {
    handleUpdatesResponse,
    showUpdateError,
  };
};
