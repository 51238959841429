import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import googleMapReact from 'google-map-react';
import GoogleMapReact from 'google-map-react';
import { CoordinatesWithSourceInfo } from 'lavva.exalushome/build/js/Services/Controller/IGeolocationService';
import { CustomBackdrop, Header, NavHeader, Page, PopUpNav } from '../../../../../components';
import { IconLocation } from '../../../../../components/popup-nav/icons';
import TextInfo from '../../../../../components/text-info';
import { useInstallationContext } from '../../../../../context/installation';
import { environment } from '../../../../../environment';
import { ROUTES } from '../../../../../routes';
import { isLocalApp } from '../../../../../utils/helpers/local-app';
import Marker from '../../../../../modules/installation-create/components/map/components/marker';
import { useExalusGeolocation } from '../../../hooks';

const LocationMap: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const mapRef = useRef<googleMapReact>(null);
  const { data: location, isLoading: locationLoading } = useExalusGeolocation();
  const { selectedInstallationId } = useInstallationContext();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader>
            {selectedInstallationId && !isLocalApp && (
              <PopUpNav
                dark
                className="popup-nav--reverse"
                links={[
                  {
                    label: t('exalus.editLocation'),
                    onClick: () => navigate(ROUTES.InstallationSettings(selectedInstallationId)),
                    icon: <IconLocation />,
                  },
                ]}
              />
            )}
          </NavHeader>
          <Header title={t('exalus.location')} isUnderline />
        </>
      }
    >
      <TextInfo
        label={t('exalus.latitude')}
        value={
          (location as CoordinatesWithSourceInfo)?.Latitude ? (location as CoordinatesWithSourceInfo).Latitude : '-'
        }
        reverse
        column
        className="m-b-16"
      />
      <TextInfo
        label={t('exalus.longitude')}
        value={
          (location as CoordinatesWithSourceInfo)?.Longitude ? (location as CoordinatesWithSourceInfo).Longitude : '-'
        }
        reverse
        column
        className="m-b-24"
      />
      {!isLocalApp && (
        <div style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: environment.MAPS_API_KEY }}
            zoom={16}
            center={{
              lat: (location as CoordinatesWithSourceInfo)?.Latitude || 0,
              lng: (location as CoordinatesWithSourceInfo)?.Longitude || 0,
            }}
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: false,
            }}
          >
            {(location as CoordinatesWithSourceInfo)?.Latitude &&
              (location as CoordinatesWithSourceInfo)?.Longitude && (
                <Marker
                  lat={(location as CoordinatesWithSourceInfo)?.Latitude}
                  lng={(location as CoordinatesWithSourceInfo)?.Longitude}
                />
              )}
          </GoogleMapReact>
        </div>
      )}
      <CustomBackdrop loading={locationLoading} />
    </Page>
  );
};

export default LocationMap;
