import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import MigrationPage from '../integrations/exalus/modules/migration';
import LoginPage from '../modules/login';
import PrivacyPage from '../modules/privacy';
import RodoPage from '../modules/rodo';
import SupportPage from '../modules/support';
import TermsPage from '../modules/terms';
import { ROUTES } from './routes';

export const UnauthenticatedRouter: React.FC = () => (
  <BrowserRouter future={{ v7_relativeSplatPath: false, v7_startTransition: false }}>
    <Routes>
      <Route path={ROUTES.Terms()} element={<TermsPage />} />
      <Route path={ROUTES.Privacy()} element={<PrivacyPage />} />
      <Route path={ROUTES.Support()} element={<SupportPage />} />
      <Route path={ROUTES.Rodo()} element={<RodoPage />} />
      <Route path={ROUTES.Root()} element={<LoginPage />} />
      <Route path={ROUTES.Migration()} element={<MigrationPage />} />
      <Route path="*" element={<Navigate to={ROUTES.Root()} replace />} />
    </Routes>
  </BrowserRouter>
);
