import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import { CustomBackdrop, EmptyStateBox, Header, IconWarning, NavHeader, Page } from '../../components';
import { useSupportedDeviceById } from '../../hooks/supported-devices/supported-devices';
import { DeviceParameters } from './components/device-parameters';
import { ManualsAndDocuments } from './components/manuals-and-documents';
import { WhereBuy } from './components/where-buy';
import './index.scss';

const StoreDeviceDetails: React.FC = () => {
  const { t } = useTranslation('store');
  const { modelId } = useParams<{ modelId: string }>();
  const { data, isLoading } = useSupportedDeviceById(modelId);

  const slideLinks = useMemo(() => {
    let urls = (data?.Pictures || []).map((x) => ({ url: x, type: 'image' }));
    if (data?.PromoVideo) urls = [...urls, { url: data.PromoVideo, type: 'video' }];
    return urls;
  }, [data]);

  return (
    <Page
      className="store-device-details"
      containerClassName="page__container--no-padding-top"
      whiteContent
      isStickyHeader
      header={<NavHeader />}
    >
      <>
        {!isLoading && data ? (
          <>
            {data ? (
              <>
                <div className="store-device-details--pictures">
                  <Carousel
                    className="device-carousel-pictures"
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    infiniteLoop={false}
                    swipeable
                    centerMode
                    centerSlidePercentage={100}
                  >
                    {slideLinks.map((slide, i) => (
                      <div key={i} className="slide-item">
                        {slide.type === 'image' ? (
                          <img src={slide.url} alt={data.Name} />
                        ) : (
                          <iframe
                            width="100%"
                            height="200"
                            src={slide.url}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>
                        )}
                      </div>
                    ))}
                  </Carousel>
                </div>
                <p className="store-device-details--title">{data.Name}</p>
                <p className="store-device-details--model">{data.ModelName}</p>

                <WhereBuy buySources={data.WhereToBuy} />

                <hr className="m-t-24 m-b-24" />

                <Header title={t('description')} />
                <div className="store-device-details--description">{data.Description}</div>

                <DeviceParameters device={data} />

                <ManualsAndDocuments documents={data.ManualsAndDocuments} />
              </>
            ) : (
              <EmptyStateBox content={t('notFoundDevice')} icon={<IconWarning />} />
            )}
          </>
        ) : null}
      </>

      <CustomBackdrop loading={isLoading} />
    </Page>
  );
};

export default StoreDeviceDetails;
