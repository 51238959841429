import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { AllUserNotificationsQueryVariables, NotificationSenderType, Query } from '../../data-access/gql-types/graphql';
import { ALL_USER_NOTIFICATIONS } from '../../data-access/queries/notification';
import { useDevicesAndChannelsContext } from '../../integrations/lavva/context/devices-and-channels';
import { NotificationType } from '../../types';
import { isLocalApp } from '../../utils/helpers';
import { mapNotification } from '../../utils/notifications';

export const useNotifications = (activeTabIndex: number | null) => {
  const { t } = useTranslation('notifications');
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const { channelList } = useDevicesAndChannelsContext();
  const skipFetching = isLocalApp || activeTabIndex === null;
  const { data, loading, fetchMore } = useQuery<Query, AllUserNotificationsQueryVariables>(ALL_USER_NOTIFICATIONS, {
    variables: {
      take: 20,
      skip: 0,
      where: {
        isHidden: { eq: false },
        senderType: { eq: NotificationSenderType.QueryOnly },
        ...(activeTabIndex === 1 && { wasDisplayed: { eq: false } }),
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: skipFetching,
  });

  useEffect(() => {
    if (data?.allUserNotifications) {
      const list = (data.allUserNotifications.items || [])
        .map((receivedNotification) => mapNotification(receivedNotification, channelList, t))
        .filter(Boolean) as NotificationType[];

      setNotifications(uniqBy(list, 'id'));
      setHasNextPage(data.allUserNotifications.pageInfo.hasNextPage);
    }
  }, [data, channelList]);

  const fetchMoreData = () => {
    if (!hasNextPage) return;

    fetchMore({
      variables: {
        take: 20,
        skip: data?.allUserNotifications?.items?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.allUserNotifications) return prev;

        return {
          ...prev,
          allUserNotifications: {
            ...prev.allUserNotifications,
            ...fetchMoreResult.allUserNotifications,
            items: [
              ...(prev.allUserNotifications?.items || []),
              ...(fetchMoreResult.allUserNotifications?.items || []),
            ],
            pageInfo: fetchMoreResult.allUserNotifications?.pageInfo ||
              prev.allUserNotifications?.pageInfo || {
                hasNextPage: false,
                hasPreviousPage: false,
              },
          },
        };
      },
    });
  };

  return {
    notifications,
    hasNextPage,
    loading,
    setNotifications,
    fetchMoreData,
  };
};
