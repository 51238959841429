import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DialogConfirmation, Header, NavHeader, Page } from '../../../../../components';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useInstallationContext } from '../../../../../context/installation';
import {
  BluetoothLoggingEnabledQuery,
  BluetoothLoggingEnabledQueryVariables,
} from '../../../../../data-access/gql-types/graphql';
import { BLUETOOTH_LOGGING } from '../../../../../data-access/queries/register';
import { DialogScanning } from '../components/dialog-scanning';
import { useBluetoothContext } from '../context';
import './index.scss';

const DeviceAddBluetooth: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const [bluetoothAvailable, setBluetoothAvailable] = useState<boolean | null>(null);
  const { selectedInstallationId } = useInstallationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { searchBluetooth, disconnect, isCustomBluetooth, dialogScanning, setDialogScanning, setBtLogLevel } =
    useBluetoothContext();
  const { data: bluetoothLog, loading } = useQuery<BluetoothLoggingEnabledQuery, BluetoothLoggingEnabledQueryVariables>(
    BLUETOOTH_LOGGING,
    {
      variables: { installationId: selectedInstallationId },
      fetchPolicy: 'no-cache',
      skip: !selectedInstallationId,
    },
  );

  useEffect(() => {
    if (bluetoothLog) setBtLogLevel(bluetoothLog.bluetoothLoggingEnabled);
  }, [bluetoothLog]);

  useEffect(() => {
    const getAvailability = async () => {
      turnOnBackdrop();
      const availability = await navigator.bluetooth.getAvailability();
      setBluetoothAvailable(availability);
      turnOffBackdrop();
    };

    disconnect();
    getAvailability();
  }, []);

  const handleSearch = () => searchBluetooth(isCustomBluetooth);

  return (
    <Page
      isStickyHeader
      className="add-bluetooth"
      header={
        <>
          <NavHeader />
          <Header title={t('bluetooth.bluetoothDevices')} isUnderline />
        </>
      }
    >
      <div className="button-container">
        <button type="button" className="button" onClick={handleSearch} disabled={loading}>
          {t('bluetooth.searchDevices')}
        </button>
      </div>
      <DialogScanning show={dialogScanning} setShow={setDialogScanning} />
      <DialogConfirmation
        show={bluetoothAvailable === false}
        setShow={() => console.log(false)}
        header={t('bluetooth.bluetoothAvailability.header')}
        content={t('bluetooth.bluetoothAvailability.content')}
        primaryBtnText={tc('buttons.return')}
        primaryBtnAction={() => navigate(-1)}
      />
    </Page>
  );
};

export default DeviceAddBluetooth;
