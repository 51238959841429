import React, { ChangeEvent, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Checkbox, DialogTimePicker, IconEdit, Input } from '../../../../../../../../components';
import { RelayConfig } from '../../../../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../../../../context/devices-and-channels';
import { useOptimizerConfigurationContext } from '../../../context/configuration-context';
import { InputSliderDialog } from '../input-slider-dialog';
import './index.scss';

type OptimizerConfigurationRelayConfigBoxProps = {
  name: string;
  thresholdPercent?: boolean;
};

export const OptimizerConfigurationRelayConfigBox: React.FC<OptimizerConfigurationRelayConfigBoxProps> = ({
  name,
  thresholdPercent = true,
}) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [timeOnPicker, setTimeOnPicker] = useState<boolean>(false);
  const [timeOffPicker, setTimeOffPicker] = useState<boolean>(false);
  const { control, setValue, watch } = useFormContext();
  const { optimizerConfigValidation } = useDevicesAndChannelsContext();
  const { errors } = useOptimizerConfigurationContext();

  const relay = useMemo(() => watch()[name] as Omit<RelayConfig, '__typename'>, [name, watch()]);

  const handleChangeEnabled = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(`${name}.enabled`, e.target.checked);

    if (name === 'relayConfig4') {
      setValue('relayConfig1.enabled', false);
      setValue('relayConfig2.enabled', false);
      setValue('relayConfig3.enabled', false);
    } else {
      setValue('relayConfig4.enabled', false);
    }
  };

  const getMaxTime = (property: string) => {
    if (optimizerConfigValidation) {
      const maxSeconds = optimizerConfigValidation.relay[property].max;
      const maxHours = Math.round(maxSeconds / 60 / 60);
      if (maxHours > 0 && maxHours <= 24) return { maxHours, maxMinutes: null };

      const maxMinutes = Math.round(maxSeconds / 60);
      if (maxMinutes > 0 && maxMinutes < 60) return { maxHours: 0, maxMinutes };
    }

    return { maxHours: null, maxMinutes: null };
  };

  const maxTimeOn = useMemo(() => getMaxTime('timeOn'), [optimizerConfigValidation]);
  const maxTimeOff = useMemo(() => getMaxTime('timeOff'), [optimizerConfigValidation]);

  return (
    <div className={classNames('relay-config-box', { [name]: true })}>
      <Checkbox checked={relay.enabled} onChange={handleChangeEnabled} reverse>
        {tc('active')}
      </Checkbox>

      <div className="optimizer-configuration-form__item optimizer-configuration-form__item-row-flex">
        {thresholdPercent ? (
          <InputSliderDialog
            propertyName={`${name}.thresholdValue`}
            propertyValue={relay.thresholdValue || 0}
            unit="%"
            dialogTitle={t('optimizer.configuration.relays.relayReleaseThreshold')}
            inputLabel={t('optimizer.configuration.relays.relayReleaseThreshold')}
            reverse
            {...(optimizerConfigValidation ? { min: optimizerConfigValidation.relay.thresholdPercent.min } : {})}
            {...(optimizerConfigValidation ? { max: optimizerConfigValidation.relay.thresholdPercent.max } : {})}
            {...(optimizerConfigValidation?.relay.thresholdPercent.step
              ? { step: optimizerConfigValidation.relay.thresholdPercent.step }
              : {})}
            {...(errors[name]?.thresholdValue ? { errorMessage: errors[name]?.thresholdValue } : {})}
          />
        ) : (
          <Controller
            name={`${name}.thresholdValue`}
            control={control}
            rules={{
              required: tc('isRequired') as string,
              ...(optimizerConfigValidation
                ? {
                    validate: {
                      minThan: (value: string) =>
                        parseFloat(value) >= optimizerConfigValidation.relay.thresholdPower.min ||
                        tc('errors.minNumber', { min: optimizerConfigValidation.relay.thresholdPower.min }),
                      maxThan: (value: string) =>
                        parseFloat(value) <= optimizerConfigValidation.relay.thresholdPower.max ||
                        tc('errors.maxNumber', { max: optimizerConfigValidation.relay.thresholdPower.max }),
                    },
                  }
                : {}),
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <Input
                  inputType={'number'}
                  defaultValue={field.value?.toString() || '0'}
                  value={field.value?.toString() || '0'}
                  onChange={(e) => field.onChange(e.target.value)}
                  label={`${t('optimizer.configuration.relays.powerThreshold')} [W]`}
                  reverse
                  {...(optimizerConfigValidation?.relay.thresholdPower.step
                    ? { step: optimizerConfigValidation.relay.thresholdPower.step.toString() }
                    : {})}
                  {...(error
                    ? { errorMessage: error.message }
                    : { ...(errors[name]?.thresholdValue ? { errorMessage: errors[name]?.thresholdValue } : {}) })}
                />
              );
            }}
          />
        )}
      </div>

      <div className="optimizer-configuration-form__item optimizer-configuration-form__item-row">
        <Controller
          name={`${name}.timeOn`}
          control={control}
          render={({ field }) => (
            <>
              <Input
                defaultValue={field.value}
                value={field.value}
                required
                readOnly
                onClickInput={() => setTimeOnPicker(true)}
                endIcon={<IconEdit />}
                reverse
                label={t('optimizer.configuration.relays.minTimeOn')}
                {...(errors[name]?.timeOn ? { errorMessage: errors[name]?.timeOn } : {})}
              />
              <DialogTimePicker
                header={t('optimizer.configuration.relays.minTimeOn')}
                open={timeOnPicker}
                setOpen={setTimeOnPicker}
                onSave={(value) => field.onChange(value)}
                time={field.value}
                showSeconds
                {...(optimizerConfigValidation ? { minSeconds: optimizerConfigValidation.relay.timeOn.min } : {})}
                {...(maxTimeOn.maxHours === 0 ? { disableHours: true } : {})}
                {...(maxTimeOn.maxHours !== null ? { maxHours: maxTimeOn.maxHours } : {})}
                {...(maxTimeOn.maxMinutes !== null ? { maxMinutes: maxTimeOn.maxMinutes } : {})}
              />
            </>
          )}
        />
        <Controller
          name={`${name}.timeOff`}
          control={control}
          render={({ field }) => (
            <>
              <Input
                defaultValue={field.value}
                value={field.value}
                required
                readOnly
                onClickInput={() => setTimeOffPicker(true)}
                endIcon={<IconEdit />}
                reverse
                label={t('optimizer.configuration.relays.breakBetween')}
                {...(errors[name]?.timeOff ? { errorMessage: errors[name]?.timeOff } : {})}
              />
              <DialogTimePicker
                header={t('optimizer.configuration.relays.breakBetween')}
                open={timeOffPicker}
                setOpen={setTimeOffPicker}
                onSave={(value) => field.onChange(value)}
                time={field.value}
                showSeconds
                {...(optimizerConfigValidation ? { minSeconds: optimizerConfigValidation.relay.timeOff.min } : {})}
                {...(maxTimeOff.maxHours === 0 ? { disableHours: true } : {})}
                {...(maxTimeOff.maxHours !== null ? { maxHours: maxTimeOff.maxHours } : {})}
                {...(maxTimeOff.maxMinutes !== null ? { maxMinutes: maxTimeOff.maxMinutes } : {})}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};
