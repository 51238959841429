import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconChevron } from '../../../icons';
import './index.scss';

type DetailsBlindControl = {
  handleClick: () => void;
  active?: boolean;
};

type BlindDetailsControlsProps = {
  downButton: DetailsBlindControl;
  stopButton: DetailsBlindControl;
  upButton: DetailsBlindControl;
  disabledPadding?: boolean;
};

export const BlindDetailsControls: React.FC<BlindDetailsControlsProps> = ({
  downButton,
  stopButton,
  upButton,
  disabledPadding,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('blind-details__controls', { 'disable-padding': disabledPadding })}>
      <button
        className={classNames('blind-details__controls--down', { active: downButton.active })}
        onClick={downButton.handleClick}
      >
        <IconChevron withCircle direction="down" />
      </button>
      <button
        className={classNames('blind-details__controls--stop', { active: stopButton.active })}
        onClick={stopButton.handleClick}
      >
        {t('status.stop')}
      </button>
      <button
        className={classNames('blind-details__controls--up', { active: upButton.active })}
        onClick={upButton.handleClick}
      >
        <IconChevron withCircle direction="up" />
      </button>
    </div>
  );
};
