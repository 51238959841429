import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/client';
import { IconArrowLink, IconEco } from '../../../../../../components/icons';
import { useInstallationContext } from '../../../../../../context/installation';
import {
  MeasurementChannelKind,
  OnMeasurementSubscriptionVariables,
  SubPriority,
  Subscription,
  VectorParameterTypeInternal,
} from '../../../../../../data-access/gql-types/graphql';
import { ON_MEASUREMENT } from '../../../../../../data-access/subscriptions/on-measurement';
import { useApi } from '../../../../hooks';
import { ChannelType } from '../../../../types';
import './index.scss';

type MeterControlProps = {
  channel: ChannelType;
};

export const MeterControl: React.FC<MeterControlProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { measurementMap, convertMeasurementToNumber } = useApi();
  const { skipLavvaFetch } = useInstallationContext();
  const { data } = useSubscription<Subscription, OnMeasurementSubscriptionVariables>(ON_MEASUREMENT, {
    variables: {
      input: {
        channelId: channel.id,
        kind: MeasurementChannelKind.Meter,
        priority: SubPriority.Medium,
        scalarParameterTypes: [],
        vectorParameterTypes: [
          convertMeasurementToNumber(MeasurementChannelKind.Meter)('vectors', VectorParameterTypeInternal.ActivePower),
        ],
        indices: [],
      },
    },
    skip: !measurementMap || skipLavvaFetch,
  });

  const value = useMemo(() => {
    if (data) {
      if ('vectorMeasurements' in data.onMeasurement.data) {
        const sum = data.onMeasurement.data.vectorMeasurements.sum((x) => x.value);
        if (sum !== undefined) return sum;
      }
    }

    return null;
  }, [data]);

  if (value === null) return null;

  return (
    <div className="meter-control">
      {value !== undefined ? (
        <div className="meter-control__value-wrapper">
          {value < 0 ? <IconEco /> : null}
          <span className="meter-control__value">
            {Math.abs(value / 1000).toFixed(1) !== '0.0' ? (value / 1000).toFixed(1) : '0.0'}
          </span>
          <span className="meter-control__unit">{t('kW')}</span>
          {value ? <IconArrowLink size={22} direction={value < 0 ? 'up' : 'down'} /> : null}
        </div>
      ) : null}
    </div>
  );
};
