import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetTimeZones } from '../../integrations/lavva/api/installation/installation.hooks';
import { Header, InstallationColorBtn, NavHeader, Page, SubmitButton } from '../../components';
import { DEFAULT_TIME_ZONE } from '../../const';
import { environment } from '../../environment';
import { ROUTES } from '../../routes';
import { IntegrationDefaultEnum } from '../../types';
import { getTimeZoneFromBrowser } from '../../utils/location';
import { setBodyStyle } from '../../utils/theme';
import InstallationIconEdit from './components/installation-icon';
import InstallationName from './components/installation-name';
import LocationEdit from './components/location';
import { DialogContextProvider } from './components/location/context/location.context';
import TimeZoneEdit from './components/time-zone';
import { useCreateInstallationContext } from './context';
import { useCreateInstallation } from './hooks/use-create-installation';
import './index.scss';
import { CreateInstallationControlForm } from './types';

const InstallationCreate: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { handleSaveCreateInstallationForm } = useCreateInstallation();
  const { state } = useCreateInstallationContext();
  const { data, isLoading } = useGetTimeZones();

  const form = useForm<CreateInstallationControlForm>({
    defaultValues: {
      installation_name: state.installationName,
      icon_name: state.iconName,
      hex_color: state.hexColor,
      time_zone: state.timeZone,
      location: state.locationLat && state.locationLong ? `${state.locationLat}, ${state.locationLong}` : '',
    },
  });

  const { hex_color } = form.watch();

  useEffect(() => {
    if (data) {
      if (state.timeZone) form.setValue('time_zone', state.timeZone);
      else {
        const browserTimeZone = getTimeZoneFromBrowser();
        const timeZone = data.find((item) => item.id === browserTimeZone);
        form.setValue('time_zone', timeZone?.id || DEFAULT_TIME_ZONE);
      }
    }
  }, [data, state]);

  useEffect(() => setBodyStyle('--color-accent', hex_color), [hex_color]);

  const onSubmit = form.handleSubmit((values: CreateInstallationControlForm) => {
    handleSaveCreateInstallationForm(values);
  });

  const handleBack = () => navigate(ROUTES.Installation(), { replace: true });

  return (
    <Page
      header={
        <>
          <NavHeader onClick={handleBack} />
          <Header title={t('newInstallation')} />
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} className="installation_create">
          <InstallationIconEdit />

          <div className="grid-list-24">
            <InstallationName />
            <InstallationColorBtn />
            <TimeZoneEdit />
            {environment.INTEGRATION_DEFAULT !== IntegrationDefaultEnum.Exalus && (
              <DialogContextProvider>
                <LocationEdit />
              </DialogContextProvider>
            )}
          </div>
          <SubmitButton disabled={isLoading}>{tc('buttons.next')}</SubmitButton>
        </form>
      </FormProvider>
    </Page>
  );
};

export default InstallationCreate;
