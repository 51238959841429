import { DeviceConnectionState } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelCoverStateType, ChannelGateStateType } from '../../types';
import { PublishStatus } from '../../../../types';
import { DeviceResponse } from '../device/device.types';

export const useUpdateChannel = () => {
  const setChannelState = useChannelsStateStore((s) => s.setChannelState);
  const setAllChannelsState = useChannelsStateStore((s) => s.setAllChannelsState);
  const getChannelState = useChannelsStateStore((s) => s.getChannelState);
  const { channelList } = useDevicesAndChannelsContext();

  const setChannelAsDisconnected = (deviceId: string) => {
    const channelsToUpdate = channelList.filter((ch) => ch.deviceId === deviceId);
    const states = {};

    for (let i = 0; i < channelsToUpdate.length; i++) {
      states[channelsToUpdate[i].id] = {
        deviceConnectionState: DeviceConnectionState.Disconnected,
      };
    }

    setAllChannelsState(states);
  };

  const resetCalibrateState = <T extends ChannelCoverStateType | ChannelGateStateType>(
    channelId: string,
    data: DeviceResponse,
  ) => {
    if (data.publishStatus === PublishStatus.Ok) {
      const calibrateState = (getChannelState(channelId) as T | null)?.calibrateState;

      setChannelState(channelId, {
        calibrateState: {
          openingMilliseconds: calibrateState?.openingMilliseconds || 0,
          closingMilliseconds: calibrateState?.closingMilliseconds || 0,
          error: calibrateState?.error,
          progress: calibrateState?.progress,
          started: false,
        },
      } as T);
    }
  };

  return {
    setChannelAsDisconnected,
    resetCalibrateState,
  };
};
