import { useMemo, useState } from 'react';
import { useGetTimeZones } from '../../../integrations/lavva/api/installation/installation.hooks';
import { SelectOptionType } from '../../../components';
import { TimeZonesHook } from '../types';
import { mapTimeZonesToOptions } from '../utils';

export const useTimeZones = (): TimeZonesHook => {
  const { data, isLoading } = useGetTimeZones();
  const [search, setSearch] = useState<string>('');

  const timeZonesOptions: SelectOptionType<string>[] = useMemo(() => mapTimeZonesToOptions(data, search), [data, search]);

  return {
    timeZonesOptions,
    search,
    isLoading,
    setSearch,
  };
};
