import {
  GateFeatureTypeInternal,
  GateLockStateInternal,
  GateModeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelGateStateType, ChannelGateType } from '../../../types';

export const getGateMode = (gateChannelData: ChannelGateType) => {
  const supported = gateChannelData.supportedGateFeatures;

  if (supported.includes(GateFeatureTypeInternal.StepByStep) && supported.includes(GateFeatureTypeInternal.SetDir)) {
    return GateModeInternal.RollUp;
  }

  return gateChannelData.gateMode;
};

export const getGateStep = (state: ChannelGateStateType | null, lockReportingModeSupported: boolean) => {
  const position = state?.position;

  if (lockReportingModeSupported) {
    switch (state?.lockStatus) {
      case GateLockStateInternal.Locked:
        return 1;
      case GateLockStateInternal.Opened:
        return 3;
      default:
        return 2;
    }
  } else {
    if (!position) return 3;
    return position === 100 ? 1 : 2;
  }
};

export const checkVentilationSupported = (gateMode: GateModeInternal | undefined, supportedFeatures) => {
  if (supportedFeatures.length) return supportedFeatures.includes(GateFeatureTypeInternal.Tilt);
  return gateMode === GateModeInternal.RollUp;
};

export const checkSetDirectionSupported = (gateMode: GateModeInternal | undefined, supportedFeatures) => {
  if (supportedFeatures.length) return supportedFeatures.includes(GateFeatureTypeInternal.SetDir);
  return gateMode === GateModeInternal.RollUp;
};

export const checkSetPositionSupported = (gateMode: GateModeInternal | undefined, supportedFeatures) => {
  if (supportedFeatures.length) return supportedFeatures.includes(GateFeatureTypeInternal.SetPos);
  return gateMode === GateModeInternal.RollUp;
};

export const checkStepByStepSupported = (gateMode: GateModeInternal | undefined, supportedFeatures) => {
  if (supportedFeatures.length) return supportedFeatures.includes(GateFeatureTypeInternal.StepByStep);
  return gateMode === GateModeInternal.StepByStep;
};
