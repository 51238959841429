import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { StateHistoryErrorCode } from 'lavva.exalushome/build/js/Services/StatesHistory/IStatesHistoryService';
import {
  AveragingStateValues,
  StateDataResponse,
} from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ControlWrapper, DatePicker, TimeRanges } from '../../../../../../components';
import { useInstallationContext } from '../../../../../../context/installation';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useExalusServicesContext } from '../../../../context/services';
import { useNavigateState } from '../../hooks/use-history-state';
import ChartPagination from '../components/chart-pagination';
import ChartSummary from '../components/chart-summary';
import CustomizedXTick from '../components/customized-x-tick';
import LegendCustom from '../components/legend';
import { SensorChartTooltip } from '../components/tooltip';
import { useNavigateCharts } from '../hooks/use-history-charts';
import { stateInterval } from '../types';

type HumidityChartProps = {
  channel: IDeviceChannel;
  active?: boolean;
};

const limit = 744;

const HumidityChart: React.FC<HumidityChartProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const [state, setState] = useState<StateDataResponse<AveragingStateValues> | null>(null);
  const { turnOnBackdrop, turnOffBackdrop, backdropOpen } = useBackdropContext();
  const { historyStatesApi } = useExalusServicesContext();
  const { handleHistoryError } = useNavigateState(channel);
  const { selectedInstallation } = useInstallationContext();
  const { offSet, setOffSet, activeRange, onClickActiveMeasurementRange, formatDate } = useNavigateCharts();

  useEffect(() => {
    const getStates = async () => {
      turnOnBackdrop();
      const result = await historyStatesApi.GetStatesByIntervalAsync<AveragingStateValues>(
        channel.GetDevice(),
        channel.Number,
        DeviceResponseType.HumiditySensorState,
        stateInterval[activeRange],
        limit,
        offSet,
        true,
      );

      if ((result as ResponseResult<StateHistoryErrorCode>).Type) {
        handleHistoryError(result as ResponseResult<StateHistoryErrorCode>);
      } else {
        setState(result as StateDataResponse<AveragingStateValues>);
        turnOffBackdrop();
      }
    };

    getStates();
  }, [activeRange, offSet]);

  const data = useMemo(() => {
    return state?.Data.reverse().map((x) => ({
      date: formatDate(x.Time),
      [t('exalus.humidity')]: x.Values?.Average,
      unit: '%',
    }));
  }, [state]);

  const aggregatedData = useMemo(() => {
    return state?.AggregateDataList.find((x) => x.DataEntryIdentity === 'IHumiditySensor')?.AggregateData;
  }, [state?.AggregateDataList]);

  return (
    <>
      <ControlWrapper className={classNames('control-wrapper--full-space no-center', { active: active })}>
        <div className="exalus-chart-container">
          <TimeRanges
            activeMeasurementRange={activeRange}
            setActiveMeasurementRange={onClickActiveMeasurementRange}
            disableYear
          />
          <DatePicker
            showDateButtons={false}
            activeDay={new Date()}
            activeMeasurementRange={activeRange}
            setActiveDay={() => null}
          />
          {data && data.length > 0 ? (
            <div className="binary-chart">
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  width={500}
                  height={250}
                  data={data}
                  margin={{
                    top: 15,
                    right: 20,
                    left: 5,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    dataKey="date"
                    tick={<CustomizedXTick />}
                    height={44}
                    interval="preserveStartEnd"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis dataKey={t('exalus.humidity')} unit="%" domain={['dataMin', 'dataMax + 0.5']} />
                  <Tooltip content={<SensorChartTooltip />} />
                  <Legend wrapperStyle={{ bottom: -20 }} content={<LegendCustom />} />
                  <Area
                    type="monotone"
                    dataKey={t('exalus.humidity')}
                    stroke={selectedInstallation?.hexColor}
                    fill={selectedInstallation?.hexColor}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <ChartPagination
                offSet={offSet}
                limit={limit}
                setOffSet={setOffSet}
                isNextAvailable={!!state?.IsNextPageAvailable}
              />
            </div>
          ) : (
            <>{!backdropOpen && <p className="empty-states">{t('chartEmptyState.header')}</p>}</>
          )}
        </div>
      </ControlWrapper>
      {aggregatedData && <ChartSummary activeRange={activeRange} aggregateData={aggregatedData} unit="%" />}
    </>
  );
};

export default HumidityChart;
