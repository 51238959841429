import { ChannelType } from '../../../../../types';
import { DailyMode } from '../daily-mode';
import { PartialMode } from '../partial-mode';

type AdditionalModesProps = {
  channel: ChannelType;
};

export const AdditionalModes: React.FC<AdditionalModesProps> = ({ channel }) => (
  <>
    <PartialMode channel={channel} />
    <DailyMode channel={channel} />
  </>
);
