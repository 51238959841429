import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { GateActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { FieldChangeResultType } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { BlindDetailsFavourites } from '../../../../../../../../../components/channel-details-controls/blind/favourites';
import { defaultFavouritePositionList } from '../../../../../../../../../const';
import { CustomChannelDataEnum } from '../../../../../../../enums/custom-channel-data';
import { getValueFromChannelCustomData } from '../../../../../../../utils';
import { useGatePreciseControl } from '../../../../../hooks/use-gate-precise-control';

type FavouritesProps = {
  channel: IDeviceChannel;
  position: number;
  setTargetValue: Dispatch<SetStateAction<number>>;
};

const Favourites: React.FC<FavouritesProps> = ({ channel, position, setTargetValue }) => {
  const [favouritePositionList, setFavouritePositionList] = useState<number[]>(defaultFavouritePositionList);
  const { handleAction } = useGatePreciseControl(channel);

  const isFovouritesMemoryAvailable = useMemo(() => channel.IsCustomDataAndRolesSupported(), [channel]);

  useEffect(() => {
    const positionList = getValueFromChannelCustomData(CustomChannelDataEnum.favouritePositions, channel);
    if (positionList) setFavouritePositionList(JSON.parse(positionList));
  }, []);

  const onFavouriteClick = (position: number) => {
    setTargetValue(position);
    handleAction(GateActionEnum.Percentage, position);
  };

  const onFavouritePositionHold = async (fieldIndex: number) => {
    const newFavouritePositionList = favouritePositionList.map((el, index) =>
      index !== fieldIndex ? el : position !== null ? position : defaultFavouritePositionList[index],
    );

    const result = await channel.SetCustomDataAsync(
      CustomChannelDataEnum.favouritePositions,
      JSON.stringify(newFavouritePositionList),
    );

    if (result.Type === FieldChangeResultType.Changed) setFavouritePositionList(newFavouritePositionList);
  };

  return (
    <BlindDetailsFavourites
      favouritePositionList={favouritePositionList}
      onFavouriteClick={onFavouriteClick}
      {...(isFovouritesMemoryAvailable ? { onFavouriteHold: onFavouritePositionHold } : {})}
      lastPosition={position}
    />
  );
};

export default Favourites;
