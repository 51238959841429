import { environment } from '../../environment';
import { IntegrationDefaultEnum } from '../../types';
import { isWisniowski } from '../helpers';
import * as storage from '../../integrations/lavva/storage';

export const setTheme = () => {
  const nativeURLTheme = new URLSearchParams(location.search).get('theme')?.replace(/['"]+/g, '');

  if (nativeURLTheme === 'dark') {
    addBodyClass(nativeURLTheme as string);
    storage.setItem('theme', 'dark');
  } else if (nativeURLTheme === 'light') {
    removeBodyClass(nativeURLTheme as string);
    storage.setItem('theme', 'light');
  } else if (nativeURLTheme === 'device') {
    if (getThemeFromDevice() === 'dark') {
      addBodyClass('dark');
      storage.setItem('theme', 'device');
    }
  } else if (!storage.getItem('theme')) {
    storage.setItem('theme', 'device');
  } else {
    if (environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus) {
      toggleBodyClass('exalus');
    }

    toggleBodyClass(
      'dark',
      storage.getItem('theme') === 'dark' || (storage.getItem('theme') === 'device' && getThemeFromDevice() === 'dark'),
    );
  }
};

export const setBodyStyle = (key: string, value: string) => document.body.style.setProperty(key, value);
export const addBodyClass = (className: string) => document.body.classList.add(className);
export const removeBodyClass = (className: string) => document.body.classList.remove(className);
export const toggleBodyClass = (className: string, force?: boolean) => {
  document.body.classList.toggle(className, force);
};

export const getThemeFromDevice = () => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
    return 'dark';
  } else {
    return 'light';
  }
};

export const getSelectedTheme = () => {
  const storageTheme = storage.getItem('theme');

  if (storageTheme === 'device') return getThemeFromDevice();
  return storageTheme;
};

export const getPageStyle = (className?: string) => {
  const isDashboard = className?.includes('dashboard');
  if (!isDashboard) return undefined;
  const isDark = getSelectedTheme() === 'dark';

  return {
    backgroundImage: !isWisniowski
      ? `url(/images/${isDark ? 'dark' : 'light'}.svg)`
      : `url(/images/wisniowski${isDark ? '-dark' : ''}.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
  } as React.CSSProperties;
};

export const getPageHeaderStyle = (className?: string, isStickyHeader?: boolean) => {
  const isDashboard = className?.includes('dashboard');
  if (!isDashboard || !isStickyHeader) return undefined;
  const isDark = getSelectedTheme() === 'dark';

  return {
    backgroundImage: !isWisniowski
      ? `url(/images/${isDark ? 'dark' : 'light'}.svg)`
      : `url(/images/wisniowski${isDark ? '-dark' : ''}.svg)`,
  } as React.CSSProperties;
};
