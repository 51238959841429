import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Header, IconToastError, TabType, Tabs } from '../../../../../../../../components';
import { RelayConfig } from '../../../../../../../../data-access/gql-types/graphql';
import { useOptimizerConfigurationContext } from '../../../context/configuration-context';
import { OptimizerConfigurationRelayConfigBox } from '../relay-config-box';

type OptimizerConfigurationRelaysProps = {
  thresholdPercent?: boolean;
  sumExists?: boolean;
};

export const OptimizerConfigurationRelays: React.FC<OptimizerConfigurationRelaysProps> = ({
  thresholdPercent,
  sumExists,
}) => {
  const { watch } = useFormContext();
  const { t } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { errors } = useOptimizerConfigurationContext();
  const { relayConfig1, relayConfig2, relayConfig3, relayConfig4 } = watch();
  const tabs: TabType[] = [
    { label: '1', indicator: relayConfig1.enabled, ...(errors.relayConfig1 && { icon: <IconToastError /> }) },
    { label: '2', indicator: relayConfig2.enabled, ...(errors.relayConfig2 && { icon: <IconToastError /> }) },
    { label: '3', indicator: relayConfig3.enabled, ...(errors.relayConfig3 && { icon: <IconToastError /> }) },
    ...(sumExists
      ? [{ label: 'Σ', indicator: relayConfig4.enabled, ...(errors.relayConfig4 && { icon: <IconToastError /> }) }]
      : []),
  ];

  useEffect(() => {
    const foundIndex = (
      [relayConfig1, relayConfig2, relayConfig3, relayConfig4] as Omit<RelayConfig, '__typename'>[]
    ).findIndex((x) => x.enabled);
    if (foundIndex !== -1) handleTabChange(foundIndex);
  }, []);

  const handleTabChange = (tabIndex: number) => setActiveTabIndex(tabIndex);

  return (
    <div {...(sumExists ? { className: 'sum-exists' } : {})}>
      <Header title={t('optimizer.configuration.relays.heading')} className="m-b-16" />
      <Tabs
        tabList={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={handleTabChange}
        tabsType="pills"
        isDivider={false}
        tabsListClassName="p-b-8 item-flex"
      />
      {activeTabIndex === 0 && (
        <OptimizerConfigurationRelayConfigBox name="relayConfig1" thresholdPercent={thresholdPercent} />
      )}
      {activeTabIndex === 1 && (
        <OptimizerConfigurationRelayConfigBox name="relayConfig2" thresholdPercent={thresholdPercent} />
      )}
      {activeTabIndex === 2 && (
        <OptimizerConfigurationRelayConfigBox name="relayConfig3" thresholdPercent={thresholdPercent} />
      )}
      {activeTabIndex === 3 && sumExists && (
        <OptimizerConfigurationRelayConfigBox name="relayConfig4" thresholdPercent={thresholdPercent} />
      )}
    </div>
  );
};
