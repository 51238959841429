import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/IRcm21ConfigService';
import { MultisensorIntervalParams } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';
import { Rcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type IntervalFormProps = {
  device: IDevice;
  channel: IDeviceChannel;
  interval: MultisensorIntervalParams | null;
};

const IntervalForm: React.FC<IntervalFormProps> = ({ device, channel, interval }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<MultisensorIntervalParams>();

  useEffect(() => {
    if (interval !== null) {
      form.setValue('Interval', interval.Interval);
    }
  }, [interval]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcm21ConfigService>(
      Rcm21ConfigService.ServiceName,
    );

    const result = await configService.SetFramesIntervalAsync(device, channel.Number, values.Interval);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="Interval"
          label={t('exalus.params.Rcm21Params.Interval')}
          placeholder={t('exalus.params.Rcm21Params.Interval')}
          inputType="number"
          min={1}
          max={6480}
        />

        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default IntervalForm;
