import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Header, Input, NavHeader, Page, SubmitButton } from '../../../../../../components';
import { patterns } from '../../../../../../utils/validation';
import { useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';

type RgbSceneChangeNameProps = {
  onNameChange: (name: string) => void;
};

export const RgbSceneChangeName: React.FC<RgbSceneChangeNameProps> = ({ onNameChange }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { name, setSelectedView } = useRgbSceneFormContext();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange', defaultValues: { name } });

  const onSubmit = (formData) => onNameChange(formData.name);

  return (
    <Page
      header={
        <>
          <NavHeader onClick={() => setSelectedView(RgbSceneFormView.SETTINGS)} />
          <Header title={t('rgbScenes.sceneName')} isUnderline />
        </>
      }
    >
      <div className="m-t-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: tc('isRequired') as string,
              maxLength: {
                value: 40,
                message: tc('tooLongName'),
              },
              pattern: {
                value: patterns.name,
                message: tc('invalidName'),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                defaultValue={name}
                label={t('rgbScenes.sceneName')}
                placeholder={t('rgbScenes.sceneName')}
                {...(error ? { errorMessage: error.message } : {})}
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <SubmitButton disabled={!isValid}>{tc('buttons.save')}</SubmitButton>
        </form>
      </div>
    </Page>
  );
};
