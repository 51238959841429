import { useTranslation } from 'react-i18next';
import { AuthorizationInfo, ConnectionResult } from 'lavva.exalushome/build/js/Services/IExalusConnectionService';
import { LoginError } from 'lavva.exalushome/build/js/Services/Session/ISessionService';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useBackdropContext } from '../../../context/backdrop';
import { ROUTES } from '../../../routes';
import { useLoggedUserStore } from '../store/logged-user';
import { ExalusInstallationCredentials } from '../types/exalus';
import { installationExalusLocalState } from '../../../utils/helpers/local-app';
import { toastError } from '../../../utils/toast';
import { useExalusServicesContext } from '../context/services';
import { removeExalusCredentials, saveExalusCredentials } from '../utils';

export const useExalusConnection = () => {
  const { t } = useTranslation('login');
  const { t: tc } = useTranslation('common');
  const { connectionApi, sessionApi } = useExalusServicesContext();
  const { turnOnBackdropPopup, turnOffBackdrop } = useBackdropContext();
  const setLoggedUser = useLoggedUserStore((s) => s.setLoggedUser);

  const goToInstallationList = () => {
    if (window.location.pathname === ROUTES.Root()) {
      window.location.href = ROUTES.Installation();
    }
  };

  const goToExalusLogin = () => {
    if (window.location.pathname === ROUTES.Root()) {
      window.location.href = ROUTES.ExalusIntegrationCreate(installationExalusLocalState);
    }
  };

  const connect = async (exalusCredentials: ExalusInstallationCredentials, showError?: boolean) => {
    const controllerConnected: ConnectionResult = await connectionApi.ConnectAndAuthorizeAsync(
      new AuthorizationInfo(exalusCredentials.serialNumber, exalusCredentials.pin),
    );

    if (controllerConnected === ConnectionResult.Connected) {
      const logged: IUser | LoginError = await sessionApi.UserLogInAsync(
        exalusCredentials.email,
        exalusCredentials.password,
      );

      if ((logged as IUser).Guid) {
        connectionApi.EnablePacketsLogging();
        connectionApi.DisablePacketsLogging();
        saveExalusCredentials(exalusCredentials);
        setLoggedUser(logged as IUser);
        return true;
      } else {
        if (logged === LoginError.WrongAuthData) {
          removeExalusCredentials(exalusCredentials.id);
        }

        toastError({ content: t(`exalus.${LoginError[logged as LoginError]}`) });
        turnOffBackdrop();
        goToInstallationList();
        return false;
      }
    } else {
      turnOffBackdrop();

      if (
        ![
          ROUTES.Installation(),
          ROUTES.InstallationCreate(),
          ROUTES.IntegrationsList(),
          ROUTES.InstallationCreated(),
        ].includes(window.location.pathname) ||
        showError
      ) {
        toastError({ content: t(`exalus.${ConnectionResult[controllerConnected]}`) });
      }

      turnOnBackdropPopup({
        message: tc('exalus.appState.Disconnected'),
        buttonTitle: tc('exalus.appState.reload'),
        handleClick: () => window.location.reload(),
        changeInstallationButton: true,
      });

      return false;
    }
  };

  return { connect, goToInstallationList, goToExalusLogin };
};
