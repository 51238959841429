import React, { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconSortHandle } from '../../../../../../../components';
import { TriggerActionRequestInput, TriggerActionType } from '../../../../../../../data-access/gql-types/graphql';
import { ChannelItem } from '../../../../../components';
import { TriggerChannelParameters } from '../../../../../components/trigger/channel-parameters';
import { DelayItem } from '../../delay-item';

type ActionItemSortProps = {
  list: TriggerActionRequestInput[];
  id: string;
};

export const ActionItemSort: React.FC<ActionItemSortProps> = ({ list, id }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  const item = useMemo(() => list.find((item: TriggerActionRequestInput) => item.id === id), [id]);

  if (!item) return null;

  return (
    <div className="action-item" style={style} ref={setNodeRef}>
      {item.actionType === TriggerActionType.DelayInSeconds ? (
        <DelayItem action={item} />
      ) : (
        <ChannelItem id={item.id} isOn details={<TriggerChannelParameters action={item} />} />
      )}
      <div className="action-item__draggable-handle" {...attributes} {...listeners}>
        <IconSortHandle />
      </div>
    </div>
  );
};
