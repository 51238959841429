import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { ROUTES } from '../../../../../routes';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelType } from '../../../types';
import { getChannelActive, getChannelIsOn } from '../../../utils/channels/helpers/channel-box';
import { Body, HeadContent } from '../elements';
import './index.scss';

type ChannelBoxProps = {
  channel: ChannelType;
  isListItem?: boolean;
  isCheckbox?: boolean;
  isDraggable?: boolean;
  checked?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  onChannelToggle?: (id: string, value: boolean) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  noRedirect?: boolean;
};

export const ChannelBox: React.FC<ChannelBoxProps> = ({
  channel,
  isDraggable = false,
  isCheckbox = false,
  isListItem = false,
  checked = false,
  onChannelToggle,
  setNodeRef,
  style,
  attributes,
  listeners,
  noRedirect,
}) => {
  const navigate = useNavigate();
  const { id, data, isVisible } = channel;
  const state = useChannelsStateStore((s) => s.channelState[id]);
  const taskActive = getChannelActive(data.type, state);
  const isOn = getChannelIsOn(data.type, state);

  const redirectToDetails = () => navigate(ROUTES.ChannelDetails(id));

  return (
    <div
      className={classNames('device-box max-width-desktop', {
        'device-box--checkbox': isCheckbox,
        'device-box--list-item': isListItem,
        'device-box--turn-on': isOn,
        'task-active': taskActive,
        'no-interaction': noRedirect,
      })}
      style={style}
      ref={setNodeRef}
    >
      <div
        className={classNames('device-box__head', { invisible: !isVisible })}
        {...(isDraggable || noRedirect ? {} : { onClick: redirectToDetails })}
      >
        <HeadContent channel={channel} />
      </div>
      <Body
        channel={channel}
        isDraggable={isDraggable}
        onChannelToggle={onChannelToggle}
        isCheckbox={isCheckbox}
        checked={checked}
        attributes={attributes}
        listeners={listeners}
        isListItem={isListItem}
      />
    </div>
  );
};
