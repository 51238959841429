import React from 'react';
import { SubmitButton } from '../..';

type VentilationChannelSettingsProps = {
  handleSubmit: () => void;
};

export const VentilationChannelSettings: React.FC<VentilationChannelSettingsProps> = ({ handleSubmit }) => (
  <>
    <SubmitButton onClick={handleSubmit} />
  </>
);
