import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  VersionedFeatureType,
} from '../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useDevicesAndChannels } from '../../../hooks';
import { useVersionMap } from '../../../hooks/api/version-map';
import { useChannelsStateStore } from '../../../store/channels-state';
import { checkDeviceStatus } from '../../../utils/devices/status';

export const useDeviceSettings = () => {
  const { t } = useTranslation('channel-list');
  const { t: ts } = useTranslation('device-settings');
  const { checkSupport, versionMapData } = useVersionMap();
  const { deviceId } = useParams<{ deviceId: string; channelId: string }>();
  const { device, channelList } = useDevicesAndChannelsContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId });
  const channelState = useChannelsStateStore((s) => s.channelState);
  const isDeviceInRecovery = checkDeviceStatus(deviceChannels, channelState, DeviceConnectionState.Recovery);

  const inputs = useMemo(() => {
    return deviceId
      ? channelList.filter((x) => x.deviceId === deviceId && x.data.type === ChannelTypeInternal.GenericInput)
      : [];
  }, [deviceId, channelList]);

  const bindingsEnabled = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.DeviceBindingsV2,
        supportKey: 'Core',
        currentVersion: device?.payload.currentFirmwareVersion,
      }),
    [device?.payload.currentFirmwareVersion, inputs.length, versionMapData],
  );

  const rssiSupported = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.GetRssi,
        supportKey: 'Core',
        currentVersion: device?.payload.currentFirmwareVersion,
      }),
    [device?.payload.currentFirmwareVersion, versionMapData],
  );

  const tabs = [
    { label: t('configuration') },
    ...(!isDeviceInRecovery ? [{ label: t('channels') }] : []),
    ...(inputs.length && !isDeviceInRecovery ? [{ label: t('inputs') }] : []),
    ...(inputs.length && bindingsEnabled && !isDeviceInRecovery ? [{ label: ts('bindings.bindings') }] : []),
  ];

  return {
    inputs,
    bindingsEnabled,
    rssiSupported,
    tabs,
  };
};
