import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SubmitButton, Tabs } from '../../../../../../components';
import { ActionType } from '../../../../../../data-access/gql-types/graphql';
import { useApi, useDevicesAndChannels } from '../../../../hooks';
import { Visibility } from '../../../../types';
import { useFormChannelsContext } from '../../context';
import GroupActions from './actions';
import GroupChannels from './channels';

type GroupFormChannelsProps = {
  defaultChannels?: string[];
  defaultActions?: string[];
  forceSubmit?: boolean;
  loading?: boolean;
  submitButton?: () => void;
};

const GroupFormChannels: React.FC<GroupFormChannelsProps> = ({
  defaultChannels = [],
  defaultActions = [],
  submitButton,
  loading = false,
}) => {
  const { t } = useTranslation('groups');
  const { userActions } = useApi();
  const { channelGroups } = useDevicesAndChannels({ visibility: Visibility.All });
  const [activeTabs, setActiveTabs] = useState<number>(0);
  const { selectedChannels, setSelectedChannels, selectedActions, setSelectedActions, isPristine, setIsPristine } =
    useFormChannelsContext();

  useEffect(() => {
    setSelectedChannels(defaultChannels);
    setSelectedActions(defaultActions);
  }, [defaultChannels, defaultChannels]);

  useEffect(() => {
    const isFormPristine =
      [...defaultChannels, ...defaultActions].sort().toString() ===
      [...selectedChannels, ...selectedActions].sort().toString();

    setIsPristine(isFormPristine);
  }, [selectedChannels, selectedActions, defaultChannels, defaultActions]);

  const totalGroupsChannels = useMemo(
    () => channelGroups.map((group) => group.channels).flat().length,
    [channelGroups],
  );

  const filteredActions = useMemo(() => {
    return userActions?.filter((item) => item.action.actionType !== ActionType.Central);
  }, [userActions]);

  const tabs = useMemo(
    () => [
      { label: t('channels') + ` (${selectedChannels.length}/${totalGroupsChannels})` },
      { label: t('actions') + ` (${selectedActions.length}/${filteredActions?.length})` },
    ],
    [selectedActions, selectedChannels, totalGroupsChannels, filteredActions, t],
  );

  const handleSelectedChannel = (channels) => setSelectedChannels(channels);
  const handleSelectedActions = (actions) => setSelectedActions(actions);

  return (
    <>
      <Tabs
        tabList={tabs}
        activeTabIndex={activeTabs}
        setActiveTabIndex={setActiveTabs}
        tabsType="default"
        autoScrollDisabled
      />
      <div className={classNames('form-devices__form', { 'form-devices__form--active': activeTabs === 0 })}>
        <GroupChannels
          handleSelectedChannel={handleSelectedChannel}
          defaultChannels={defaultChannels}
          items={channelGroups}
        />
      </div>
      <div className={classNames('form-devices__form', { 'form-devices__form--active': activeTabs === 1 })}>
        <GroupActions
          handleSelectedActions={handleSelectedActions}
          defaultActions={selectedActions}
          userActions={filteredActions}
        />
      </div>
      {submitButton && <SubmitButton disabled={isPristine} isLoading={loading} type="button" onClick={submitButton} />}
    </>
  );
};

export default GroupFormChannels;
