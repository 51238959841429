import { format } from 'date-fns';
import { format as formatTZ, toZonedTime } from 'date-fns-tz';
import { enUS, pl, de, fr, lt, es, it, hu, ro, fi, sv, nl, pt } from 'date-fns/locale';
import { AvailableLanguage } from '../../types';
import i18n from '../translations/i18n';

export const getLocaleByLanguage = () => {
  switch (i18n.language) {
    case AvailableLanguage.PL:
      return pl;
    case AvailableLanguage.FR:
      return fr;
    case AvailableLanguage.DE:
      return de;
    case AvailableLanguage.ES:
      return es;
    case AvailableLanguage.IT:
      return it;
    case AvailableLanguage.HU:
      return hu;
    case AvailableLanguage.RO:
      return ro;
    case AvailableLanguage.LT:
      return lt;
    case AvailableLanguage.FI:
      return fi;
    case AvailableLanguage.SV:
      return sv;
    case AvailableLanguage.NL:
      return nl;
    case AvailableLanguage.PT:
      return pt;
    default:
      return enUS;
  }
};

export const convertDateToString = (date: number, dateFormat: string): string =>
  format(date, dateFormat, {
    locale: getLocaleByLanguage(),
  });

export const convertDateToFormat = (date: Date, dateFormat: string): string =>
  format(date, dateFormat, {
    locale: getLocaleByLanguage(),
  });

export const getFormatInTimeZone = (date: Date, timezone: string, dateFormat: string): string =>
  formatTZ(toZonedTime(date, timezone), dateFormat, {
    timeZone: timezone,
    locale: getLocaleByLanguage(),
  });

export const convertSecondsToMinutes = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return minutes > 0 ? `${minutes}min ${seconds.toString().padStart(2, '0')}s` : `${seconds}s`;
};
