import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../../../components';
import { useExalusCreateActionContext } from '../../../context';
import { ExalusComparisonContextProvider } from '../../../context/comparison';
import { ConditionSourceType } from '../../../../../../../types/actions';
import { BlindRemotesParameters } from './blind-remotes';
import { BrightnessParameters } from './brightness';
import { EnergyParameters } from './energy';
import { FacadeRemotesParameters } from './facade-remotes';
import { FloodParameters } from './flood';
import { GateParameters } from './gate';
import { HumidityParameters } from './humidity';
import { MovementParameters } from './movement';
import { PressureParameters } from './pressure';
import { ReedParameters } from './reed';
import { RemotesParameters } from './remotes';
import { TemperatureParameters } from './temperature';
import { WindSpeedParameters } from './wind/wind-speed';
import { WindThresholdParameters } from './wind/wind-threshold';

const ExalusDeviceParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { sourceType } = useExalusCreateActionContext();

  const renderCurrentView = () => {
    switch (sourceType) {
      case ConditionSourceType.Remotes:
        return <RemotesParameters />;
      case ConditionSourceType.BlindRemotes:
        return <BlindRemotesParameters />;
      case ConditionSourceType.FacadeRemotes:
        return <FacadeRemotesParameters />;
      case ConditionSourceType.Temperature:
        return <TemperatureParameters />;
      case ConditionSourceType.Energy:
        return <EnergyParameters />;
      case ConditionSourceType.Humidity:
        return <HumidityParameters />;
      case ConditionSourceType.WindSpeed:
        return <WindSpeedParameters />;
      case ConditionSourceType.WindThreshold:
        return <WindThresholdParameters />;
      case ConditionSourceType.Brightness:
        return <BrightnessParameters />;
      case ConditionSourceType.Pressure:
        return <PressureParameters />;
      case ConditionSourceType.FloodSensor:
        return <FloodParameters />;
      case ConditionSourceType.ReedSensor:
        return <ReedParameters />;
      case ConditionSourceType.MovementSensor:
        return <MovementParameters />;
      case ConditionSourceType.Gate:
        return <GateParameters />;
      default:
        return null;
    }
  };

  return (
    <ExalusComparisonContextProvider>
      <Page
        isStickyHeader
        header={
          <>
            <NavHeader />
            <Header title={t('action.create.conditions.sources.device.state')} />
          </>
        }
      >
        {renderCurrentView()}
      </Page>
    </ExalusComparisonContextProvider>
  );
};

export default ExalusDeviceParameters;
