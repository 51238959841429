import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Query, UserNotificationQueryVariables } from '../../data-access/gql-types/graphql';
import { USER_NOTIFICATION } from '../../data-access/queries/notification';
import { useDevicesAndChannelsContext } from '../../integrations/lavva/context/devices-and-channels';
import { isLocalApp } from '../../utils/helpers';
import { mapNotification } from '../../utils/notifications';

export const useNotification = (id?: string) => {
  const { t } = useTranslation('notifications');
  const { channelList } = useDevicesAndChannelsContext();
  const { data, loading } = useQuery<Query, UserNotificationQueryVariables>(USER_NOTIFICATION, {
    variables: {
      notificationId: id,
    },
    fetchPolicy: 'network-only',
    skip: isLocalApp || !id,
  });

  const notificationData = useMemo(() => {
    if (!data?.userNotification) return null;

    return mapNotification(data.userNotification, channelList, t);
  }, [data?.userNotification]);

  return {
    notification: notificationData,
    loading,
  };
};
