import React, { Dispatch, SetStateAction, useEffect, useMemo, useState, PointerEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { DialogConfirmation, Slider } from '../../../../../../../components';
import { BlindDetailsFavourites } from '../../../../../../../components/channel-details-controls/blind/favourites';
import { defaultFavouritePositionList } from '../../../../../../../const';
import { useBackdropContext } from '../../../../../../../context/backdrop';
import { CustomChannelDataEnum } from '../../../../../enums/custom-channel-data';
import { useLoggedUser } from '../../../../../hooks/common/logged-user';
import { useDeviceConfiguration } from '../../../../../modules/device-details/hooks/use-device-configuration';
import { getValueFromChannelCustomData } from '../../../../../utils';
import { useBlindControl } from '../../../hooks/use-blind-control';
import './index.scss';

type FavouritesProps = {
  channel: IDeviceChannel;
  position: number;
  setTargetValue: Dispatch<SetStateAction<number>>;
  active: boolean;
};

const Favourites: React.FC<FavouritesProps> = ({ channel, position, setTargetValue, active }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [favouritePositionList, setFavouritePositionList] = useState<number[]>(defaultFavouritePositionList);
  const [microventilationPosition, setMicroventilationPosition] = useState<number>(91);
  const [microventilationDialog, setMicroventilationDialog] = useState<boolean>(false);
  const [sliderMicroventialation, setSliderMicroventialation] = useState<number>(0);
  const { handleAction, handleMicroventilation } = useBlindControl(channel);
  const { handleFieldChangeErrors } = useDeviceConfiguration();
  const { canAddRemoveConfigureDevice } = useLoggedUser();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const isFavouritesMemoryAvailable = useMemo(() => channel.IsCustomDataAndRolesSupported(), [channel]);

  useEffect(() => {
    const positionList = getValueFromChannelCustomData(CustomChannelDataEnum.favouritePositions, channel);
    if (positionList) setFavouritePositionList(JSON.parse(positionList));

    const favouriteMicroventilationPosition = getValueFromChannelCustomData(
      CustomChannelDataEnum.favouriteMicroventilationPosition,
      channel,
    );

    if (favouriteMicroventilationPosition) {
      setMicroventilationPosition(parseInt(favouriteMicroventilationPosition));
      setSliderMicroventialation(parseInt(favouriteMicroventilationPosition));
    }
  }, []);

  const onFavouriteClick = (position: number) => {
    setTargetValue(position);
    handleAction(BlindActionEnum.Percentage, position);
  };

  const onFavouritePositionHold = async (fieldIndex: number) => {
    turnOnBackdrop();
    const newFavouritePositionList = favouritePositionList.map((el, index) =>
      index !== fieldIndex ? el : position !== null ? position : defaultFavouritePositionList[index],
    );

    const result = await channel.SetCustomDataAsync(
      CustomChannelDataEnum.favouritePositions,
      JSON.stringify(newFavouritePositionList),
    );
    turnOffBackdrop();

    handleFieldChangeErrors(result, () => {
      setFavouritePositionList(newFavouritePositionList);
    });
  };

  const onFavouriteMicroventilationSave = async () => {
    turnOnBackdrop();

    const result = await channel.SetCustomDataAsync(
      CustomChannelDataEnum.favouriteMicroventilationPosition,
      sliderMicroventialation.toString(),
    );
    turnOffBackdrop();

    handleFieldChangeErrors(result, () => {
      setMicroventilationPosition(sliderMicroventialation);
      handleCloseDialog();
    });
  };

  const onFavouriteMicroventilationHold = () => setMicroventilationDialog(true);
  const handleCloseDialog = () => setMicroventilationDialog(false);

  const onChangeMicroventialation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderMicroventialation(Number(event.target.value));
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    const pos = Number((event.target as HTMLInputElement).value);
    setSliderMicroventialation(pos);
    handleMicroventilation(pos);
  };

  const canSaveFavourite = isFavouritesMemoryAvailable && canAddRemoveConfigureDevice && !active;

  return (
    <>
      <BlindDetailsFavourites
        favouritePositionList={favouritePositionList}
        onFavouriteClick={onFavouriteClick}
        lastPosition={position}
        icon="blind"
        microventilation={{
          position: microventilationPosition,
          lastPosition: position,
          handleMicroventilation: () => handleMicroventilation(microventilationPosition),
          ...(canSaveFavourite && { onHold: onFavouriteMicroventilationHold }),
        }}
        {...(canSaveFavourite && { onFavouriteHold: onFavouritePositionHold })}
      />
      <DialogConfirmation
        show={microventilationDialog}
        setShow={handleCloseDialog}
        header={t('microventilation')}
        primaryBtnText={tc(`buttons.${!active ? 'save' : 'loading'}`)}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onFavouriteMicroventilationSave}
        secondaryBtnAction={handleCloseDialog}
        primaryBtnDisabled={active}
      >
        <div className="microventilation__popup--content">
          <Slider
            onPointerUp={handleSliderEvent}
            value={sliderMicroventialation}
            onChange={onChangeMicroventialation}
            active={active}
            showValue={false}
          />
        </div>
      </DialogConfirmation>
    </>
  );
};

export default Favourites;
