import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, IconError } from '../../../../../components';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { GateModeInternal, UserPermissionType } from '../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { ChannelType } from '../../../types';
import { ChannelGateType } from '../../../types/channel/gate';
import { GateHistory } from './components/gate-history';
import { GateRoll } from './components/gate-roll';
import { GateStepByStep } from './components/gate-step-by-step';

type ChannelDetailsGateProps = {
  channel: ChannelType;
};

const ChannelDetailsGate: React.FC<ChannelDetailsGateProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [preventSwipeViews, setPreventSwipeViews] = useState<boolean>(false);
  const { permissions } = useUserPermissions();
  const historyVisible = permissions[UserPermissionType.GateHistory];
  const gateMode = (channel.data as ChannelGateType).gateMode;

  const renderGateDetailsContent = () => {
    switch (gateMode) {
      case GateModeInternal.RollUp:
        return <GateRoll channel={channel} setPreventSwipeViews={setPreventSwipeViews} />;
      case GateModeInternal.StepByStep:
        return <GateStepByStep channel={channel} />;
      default:
        return (
          <ControlWrapper>
            <IconError />
            <p style={{ marginLeft: 12, textTransform: 'uppercase' }}>{t('unknowMode')}</p>
          </ControlWrapper>
        );
    }
  };

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={preventSwipeViews}
      {...(gateMode !== GateModeInternal.Unknown && {
        tabs: [{ label: t('control') }, ...(historyVisible ? [{ label: t('history') }] : [])],
        additionalTabs: [...(historyVisible ? [<GateHistory key={1} channelId={channel.id} />] : [])],
      })}
    >
      {renderGateDetailsContent()}
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsGate;
