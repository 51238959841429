import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Accordion } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { ChannelBox } from '../../../../../components';
import { useDevicesAndChannels } from '../../../../../hooks';
import { ChannelGroupItem, ChannelType, Visibility } from '../../../../../types';
import DeviceVariants from '../../../../../../../components/action-create/device-variants';
import { useLavvaCreateActionContext } from '../../../context';
import { useActionTaskVariants } from '../../../hooks/use-action-task-variants';

const LavvaDeviceVariants: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const { channelGroups } = useDevicesAndChannels({ visibility: Visibility.All });
  const { taskVariant, setChannels } = useLavvaCreateActionContext();
  const { getChannelsByVariant } = useActionTaskVariants();
  const [selectedChannels, setSelectedChannels] = useState<ChannelType[]>([]);

  const onChangeSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value);
  };

  const debouncedResults = useMemo(() => debounce(onChangeSearch, 300), []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const channelsByVariants = useMemo(() => {
    const channels = getChannelsByVariant(taskVariant).filter((x) =>
      x.alias.toLowerCase().includes(search.toLowerCase()),
    );

    return channelGroups
      .map((x) => ({ ...x, channels: x.channels.filter((a) => channels.some((b) => a.id === b.id)) }))
      .filter((x) => x.channels.length);
  }, [channelGroups, taskVariant, search]);

  const handleNext = () => {
    setChannels(selectedChannels);
    navigate(ROUTES.ActionChannelSettings());
  };

  const handleChannelOnChange = (id: string) => {
    const channel = channelGroups.flatMap((x) => x.channels).find((x) => x.id === id);

    if (channel) {
      const foundIndex = selectedChannels.findIndex((x) => x.id === channel.id);

      if (foundIndex === -1) {
        setSelectedChannels([...selectedChannels, channel]);
      } else {
        setSelectedChannels(selectedChannels.filter((_, index) => index !== foundIndex));
      }
    }
  };

  return (
    <DeviceVariants handleNext={handleNext} search={search} debouncedResults={debouncedResults}>
      <ul className="page-list">
        {channelsByVariants.map((item: ChannelGroupItem, typeIndex: number) => {
          return (
            <Accordion kind="custom" key={typeIndex} title={item.label} hideExpandedBorder expanded>
              <ul className="page-list__items-list items-list">
                {item.channels.map((ch: ChannelType) => {
                  const checked = !!selectedChannels.find((item) => item && item.id === ch.id);

                  return (
                    <li key={ch.id} className="items-list__item">
                      <ChannelBox
                        channel={ch}
                        isListItem
                        isCheckbox
                        checked={checked}
                        onChannelToggle={handleChannelOnChange}
                        noRedirect
                      />
                    </li>
                  );
                })}
              </ul>
            </Accordion>
          );
        })}
      </ul>
    </DeviceVariants>
  );
};

export default LavvaDeviceVariants;
