import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import * as uuid from 'uuid';
import { useLazyQuery } from '@apollo/client';
import { DEFAULT_TIME_ZONE } from '../../../../../const';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ChannelTypeInternal,
  Query,
  TriggerActionRequestInput,
  TriggerActionType,
  TriggerDetailsQueryVariables,
  TriggerValidationPartType,
  TriggerOnFailureMode,
} from '../../../../../data-access/gql-types/graphql';
import { TRIGGER_DETAILS } from '../../../../../data-access/queries/triggers';
import { ChildrenProps } from '../../../../../types';
import { leadingZero } from '../../../../../utils/helpers';
import { toastError } from '../../../../../utils/toast';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { TriggerPriceConditionType, TriggerStateConditionType, TriggerTimeConditionType } from '../../../types';
import { PriceConditionType } from '../condition-types/price-condition/enums';
import {
  RepeatType,
  SunAction,
  SunActionModification,
  TimeEndType,
  TimeType,
} from '../condition-types/time-condition/enums';
import { ChosenConditionType } from '../enums';
import { PriceConditionConfig, TimeConditionConfig, TriggerChannel, TriggerProviderState } from '../types';
import {
  getDate,
  getRepeatType,
  getSunAction,
  getSunModification,
  getSunTimeValue,
  getTimeEndType,
  getTimeRepeat,
  getTimeType,
} from '../utils';

const initialTimeConditionConfig: TimeConditionConfig = {
  timeType: TimeType.HOUR,
  sunAction: SunAction.SUNRISE,
  sunActionModification: SunActionModification.NONE,
  repeatType: RepeatType.DAILY,
  sunTimeValue: 1,
  timeRepeat: [new Date().getHours(), new Date().getMinutes()].map(leadingZero).join(':'),
  timeEndType: TimeEndType.NEVER,
  repeatCount: 1,
  selectedDays: [],
  startDate: new Date(),
  endDate: new Date(),
};

export const initialPriceConditionConfig: PriceConditionConfig = {
  repeatType: RepeatType.DAILY,
  timeRepeat: [new Date().getHours(), new Date().getMinutes()].map(leadingZero).join(':'),
  timeEndType: TimeEndType.NEVER,
  repeatCount: 1,
  selectedDays: [],
  startDate: new Date(),
  endDate: new Date(),
  priceConditionTypes: [],
  betweenHoursFromInclusive: `${new Date().getHours()}:00`,
  betweenHoursToInclusive: '23:00',
  numberOfCheapestPeriodsInDay: 1,
  priceGreaterThanOrEqual: 0,
  priceLesserThanOrEqual: 10,
};

const initialState: TriggerProviderState = {
  actions: [],
  setActions: () => null,
  addAction: () => null,
  stateConditions: [],
  setStateConditions: () => null,
  timeCondition: {},
  setTimeCondition: () => null,
  timeConditionConfig: initialTimeConditionConfig,
  setTimeConditionConfig: () => null,
  priceCondition: {},
  setPriceCondition: () => null,
  priceConditionConfig: initialPriceConditionConfig,
  setPriceConditionConfig: () => null,
  removeTimeCondition: () => null,
  removePriceCondition: () => null,
  channels: [],
  setChannels: () => null,
  removeChannel: () => null,
  onFailureMode: TriggerOnFailureMode.Undefined,
  name: '',
  setName: () => null,
  initialName: '',
  removeAction: () => null,
  transformChannelsToActions: () => null,
  updateAction: () => null,
  addTimeDelay: () => null,
  addStateCondition: () => null,
  removeStateCondition: () => null,
  setOnFailureMode: () => null,
  shouldRedirect: false,
};

export const TriggerFormContext = createContext<TriggerProviderState>(initialState);

export const useTriggerFormContext = (): TriggerProviderState => useContext(TriggerFormContext);

export const TriggerFormContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId, selectedInstallation } = useInstallationContext();
  const { channelList } = useDevicesAndChannelsContext();

  const { state } = useLocation();
  const { triggerId } = useParams<{ triggerId: string }>();
  const [actions, setActions] = useState(() => initialState.actions);
  const [stateConditions, setStateConditions] = useState(initialState.stateConditions);
  const [onFailureMode, setOnFailureMode] = useState(initialState.onFailureMode);
  const [timeCondition, setTimeCondition] = useState(initialState.timeCondition);
  const [timeConditionConfig, setTimeConditionConfig] = useState(initialState.timeConditionConfig);
  const [priceCondition, setPriceCondition] = useState(initialState.priceCondition);
  const [priceConditionConfig, setPriceConditionConfig] = useState(initialState.priceConditionConfig);
  const [channels, setChannels] = useState(initialState.channels);
  const [name, setName] = useState(initialState.name);
  const [initialName, setInitialName] = useState(initialState.name);
  const [shouldRedirect, setShouldRedirect] = useState(initialState.shouldRedirect);

  const [getTriggerDetails, { data }] = useLazyQuery<Query, TriggerDetailsQueryVariables>(TRIGGER_DETAILS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: () => toastError({ content: tc('errors.downloadData') }),
  });

  const getTrigger = (id: string) => {
    if (!selectedInstallationId) return;
    return getTriggerDetails({ variables: { id, installationId: selectedInstallationId } });
  };

  useEffect(() => {
    if (state?.triggerId || triggerId) {
      const id = triggerId ? triggerId : state?.triggerId;
      if (id) getTrigger(id);
    }
  }, [selectedInstallationId]);

  useEffect(() => {
    if (data?.trigger) {
      setShouldRedirect(true);
      const timeCondition = data?.trigger.timeCondition;
      if (timeCondition) {
        const newTimeConditionConfig: TimeConditionConfig = {
          selectedDays: timeCondition?.daysOfWeek ?? [],
          timeType: getTimeType(timeCondition as TriggerTimeConditionType),
          sunAction: getSunAction(timeCondition as TriggerTimeConditionType),
          sunActionModification: getSunModification(timeCondition as TriggerTimeConditionType),
          sunTimeValue: getSunTimeValue(timeCondition as TriggerTimeConditionType),
          repeatType: getRepeatType(timeCondition as TriggerTimeConditionType),
          timeRepeat: getTimeRepeat(
            timeCondition?.timeOfDay,
            initialState.timeConditionConfig.timeRepeat,
            selectedInstallation?.payload?.timeZone || DEFAULT_TIME_ZONE,
          ),
          timeEndType: getTimeEndType(timeCondition as TriggerTimeConditionType),
          repeatCount: timeCondition?.targetExecutionCount || 1,
          startDate: timeCondition?.startAt ? getDate(timeCondition?.startAt) : new Date(),
          endDate: timeCondition?.endAt ? getDate(timeCondition?.endAt) : new Date(),
        };
        setTimeConditionConfig(newTimeConditionConfig);
        setTimeCondition(data?.trigger.timeCondition as TriggerTimeConditionType);
      }
      const priceCondition = data?.trigger.priceAndTimeCondition;
      if (priceCondition) {
        const priceConditionTypes: PriceConditionType[] = [];

        if (priceCondition.betweenHoursFromInclusive != null && priceCondition.betweenHoursToInclusive != null) {
          priceConditionTypes.push(PriceConditionType.HOURS_FROM_TO);
        }
        if (priceCondition.numberOfCheapestPeriodsInDay) {
          priceConditionTypes.push(PriceConditionType.NUMBER_OF_CHEAPEST_PERIODS);
        }
        if (priceCondition.priceGreaterThanOrEqual != null) {
          priceConditionTypes.push(PriceConditionType.PRICE_GREATER_OR_EQUAL);
        }
        if (priceCondition.priceLesserThanOrEqual != null) {
          console.log(priceCondition);
          priceConditionTypes.push(PriceConditionType.PRICE_LESSER_OR_EQUAL);
        }

        const newPriceConditionConfig: PriceConditionConfig = {
          ...initialPriceConditionConfig,
          selectedDays: priceCondition?.daysOfWeek ?? [],
          repeatType: getRepeatType(priceCondition as TriggerPriceConditionType),
          timeRepeat: getTimeRepeat(
            null,
            initialState.timeConditionConfig.timeRepeat,
            selectedInstallation?.payload?.timeZone || DEFAULT_TIME_ZONE,
          ),
          timeEndType: getTimeEndType(priceCondition as TriggerPriceConditionType),
          repeatCount: priceCondition?.targetExecutionCount || 1,
          startDate: priceCondition?.startAt ? getDate(priceCondition?.startAt) : new Date(),
          endDate: priceCondition?.endAt ? getDate(priceCondition?.endAt) : new Date(),
          priceConditionTypes,
          ...(priceCondition.betweenHoursFromInclusive != null && priceCondition.betweenHoursToInclusive != null
            ? {
                betweenHoursFromInclusive: `${priceCondition.betweenHoursFromInclusive}:00`,
                betweenHoursToInclusive: `${priceCondition.betweenHoursToInclusive}:00`,
              }
            : {}),
          ...(priceCondition.numberOfCheapestPeriodsInDay
            ? {
                numberOfCheapestPeriodsInDay: priceCondition.numberOfCheapestPeriodsInDay,
              }
            : {}),
          ...(priceCondition.priceGreaterThanOrEqual != null
            ? {
                priceGreaterThanOrEqual: priceCondition.priceGreaterThanOrEqual,
              }
            : {}),
          ...(priceCondition.priceLesserThanOrEqual != null
            ? {
                priceLesserThanOrEqual: priceCondition.priceLesserThanOrEqual,
              }
            : {}),
        };
        setPriceConditionConfig(newPriceConditionConfig);
        setPriceCondition(data?.trigger.priceAndTimeCondition as TriggerPriceConditionType);
      }
      setStateConditions(
        data?.trigger.stateConditions.filter((stateCondition) => {
          return !data?.trigger?.validationErrors.find(
            (validationError) =>
              validationError.partType === TriggerValidationPartType.StateCondition &&
              validationError.value === stateCondition.id,
          );
        }) as TriggerStateConditionType[],
      );
      setActions(
        data?.trigger.actions.filter((action) => {
          return !data?.trigger?.validationErrors.find(
            (validationError) =>
              validationError.partType === TriggerValidationPartType.Action && validationError.value === action.id,
          );
        }) as TriggerActionRequestInput[],
      );
      setChannels(transformActionsToChannels(data?.trigger.actions as TriggerActionRequestInput[]));
      setName(data?.trigger.name || '');
      setInitialName(data?.trigger.name || '');
      setOnFailureMode(data?.trigger.onFailureMode);
    }
  }, [data]);

  useEffect(() => {
    setTimeConditionConfig(() => ({
      ...timeConditionConfig,
      timeRepeat: [new Date().getHours(), new Date().getMinutes()].map(leadingZero).join(':'),
    }));
  }, []);

  const addAction = (action: TriggerActionRequestInput) => actions.push(action);

  const removeTimeCondition = () => {
    setTimeCondition({});
    setTimeConditionConfig(initialTimeConditionConfig);
  };

  const removePriceCondition = () => {
    setPriceCondition({});
    setPriceConditionConfig(initialPriceConditionConfig);
  };

  const removeChannel = useCallback(
    (channelId: string) => {
      const newChannels = channels.map((channel) => {
        if (channel.id === channelId) {
          return {
            ...channel,
            value: false,
          };
        }
        return channel;
      });

      setChannels([...newChannels]);
    },
    [channels],
  );

  const updateAction = useCallback(
    (id: string, data: TriggerActionRequestInput) => {
      const newActions = [...actions];
      const actionIndex = newActions.findIndex((action) => action.id === id);

      if (actionIndex > -1) {
        newActions[actionIndex] = data;
      }

      setActions(newActions);
    },
    [actions],
  );

  const transformChannelsToActions = useCallback(
    (channels: TriggerChannel[], conditionType: ChosenConditionType) => {
      setChannels([...channels]);
      const selectedChannels = channels.filter((channel) => channel.value);
      const mappedChannelsToActions = selectedChannels.map((channel) => {
        const foundAction = actions.find((action) => action.id === channel.id);
        if (foundAction) {
          return foundAction;
        } else {
          switch (channel.type) {
            case ChannelTypeInternal.Blind:
              return {
                actionType: TriggerActionType.SetBlindPosition,
                id: channel.id,
                integerValue: 0,
              };
            case ChannelTypeInternal.Gate:
              return {
                actionType: TriggerActionType.GateSetPosition,
                id: channel.id,
                integerValue: 0,
              };
            case ChannelTypeInternal.Light:
              return conditionType === ChosenConditionType.PRICE
                ? {
                    actionType: TriggerActionType.SetLightSetOnTime,
                    id: channel.id,
                    integerValue: 3600 * 1000,
                  }
                : { actionType: TriggerActionType.SetLightState, id: channel.id, booleanValue: true };
            case ChannelTypeInternal.Switch:
            default:
              return conditionType === ChosenConditionType.PRICE
                ? {
                    actionType: TriggerActionType.SetSwitchSetOnTime,
                    id: channel.id,
                    integerValue: 3600 * 1000,
                  }
                : { actionType: TriggerActionType.SetSwitchState, id: channel.id, booleanValue: true };
          }
        }
      });
      setActions((prevState) => uniqBy([...prevState, ...mappedChannelsToActions], 'id'));
    },
    [actions, channels],
  );

  const transformActionsToChannels = useCallback(
    (actions: TriggerActionRequestInput[]) => {
      const channels: TriggerChannel[] = [];
      actions.forEach((action) => {
        const channel = channelList.find((channel) => channel && channel.id === action.id);
        if (channel) {
          channels.push({
            id: channel.id,
            type: channel?.data.type,
            value: true,
            index: 0,
          });
        }
      });
      return channels;
    },
    [actions, channels],
  );

  const removeAction = useCallback(
    (id: string) => {
      const action = actions.find((action) => action.id === id);
      const filteredActions = actions.filter((action) => action.id !== id);
      setActions([...filteredActions]);

      if (action?.actionType !== TriggerActionType.DelayInSeconds) {
        const newChannels = channels.map((channel) => {
          if (channel.id === id) {
            return {
              ...channel,
              value: false,
            };
          }
          return channel;
        });

        setChannels([...newChannels]);
      }
    },
    [actions, channels],
  );

  const addTimeDelay = useCallback(
    (value: number) => {
      setActions((prevState) => [
        ...prevState,
        {
          id: uuid.v4(),
          actionType: TriggerActionType.DelayInSeconds,
          integerValue: value,
        },
      ]);
    },
    [actions, channels],
  );

  const addStateCondition = useCallback(
    (condition: TriggerStateConditionType) => {
      const conditionIndex = stateConditions.findIndex((stateCondition) => stateCondition.id === condition.id);
      if (conditionIndex > -1) {
        const newStateConditions = [...stateConditions];
        newStateConditions[conditionIndex] = condition;
        setStateConditions([...newStateConditions]);
      } else {
        setStateConditions([...stateConditions, condition]);
      }
    },
    [stateConditions],
  );

  const removeStateCondition = useCallback(
    (id: string) => {
      const filteredConditions = stateConditions.filter((stateCondition) => stateCondition.id !== id);
      setStateConditions([...filteredConditions]);
    },
    [stateConditions],
  );

  const values: TriggerProviderState = {
    actions,
    setActions,
    stateConditions,
    setStateConditions,
    timeCondition,
    setTimeCondition,
    timeConditionConfig,
    setTimeConditionConfig,
    priceCondition,
    setPriceCondition,
    priceConditionConfig,
    setPriceConditionConfig,
    removeTimeCondition,
    removePriceCondition,
    addAction,
    channels,
    setChannels,
    removeChannel,
    name,
    setName,
    initialName,
    transformChannelsToActions,
    removeAction,
    updateAction,
    addTimeDelay,
    addStateCondition,
    removeStateCondition,
    shouldRedirect,
    onFailureMode,
    setOnFailureMode,
  };

  return <TriggerFormContext.Provider value={values}>{children}</TriggerFormContext.Provider>;
};
