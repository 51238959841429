import { IconThermometer } from '../../../../../components';
import { ActionValueParameters, ScheduleAction } from '../types';

export const useScheduleValues = () => {
  const actionValueParameters: Record<ScheduleAction, ActionValueParameters> = {
    [ScheduleAction.None]: {
      min: undefined,
      max: undefined,
      default: undefined,
      step: undefined,
      unit: '',
      icon: null,
    },
    [ScheduleAction.Teperature]: {
      min: 15,
      max: 30,
      default: 21,
      step: 0.5,
      unit: '°C',
      icon: <IconThermometer isOn />,
    },
  };

  return {
    actionValueParameters,
  };
};
