import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, dragOverlayStyle, SubmitButton } from '../../../../../components';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ChangeGroupsOrderMutation,
  ChangeGroupsOrderMutationVariables,
  UserGroup,
} from '../../../../../data-access/gql-types/graphql';
import { CHANGE_GROUPS_ORDER } from '../../../../../data-access/mutations/groups';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useRefetchData } from '../../../../../hooks/refresh-data';
import { GroupItem, GroupItemSortable } from '../../../components/group';

type GroupSortBodyProps = {
  sortableGroups: UserGroup[];
  setSortableGroups: (value: UserGroup[]) => void;
};

export const GroupSortBody: React.FC<GroupSortBodyProps> = ({ sortableGroups, setSortableGroups }) => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { selectedInstallationId } = useInstallationContext();
  const { refetchDashboard } = useRefetchData();
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);

  const [changeDashboardGroupOrder, { loading }] = useMutation<
    ChangeGroupsOrderMutation,
    ChangeGroupsOrderMutationVariables
  >(CHANGE_GROUPS_ORDER);

  const onDragStart = (event: dndCore.DragStartEvent) => setDndActiveId(event.active.id);

  const onDragEnd = (event: dndCore.DragEndEvent) => {
    if (!event.active || !event.over) {
      return;
    }

    if (event.active.id !== event.over.id && sortableGroups) {
      const newList = Array.from(sortableGroups);
      const oldIndex = newList.findIndex((group: UserGroup) => group.id === event.active.id);
      const newIndex = newList.findIndex((group: UserGroup) => group.id === event.over?.id);

      const sortedGroups = dndSortable.arrayMove(newList, oldIndex, newIndex);
      setSortableGroups(sortedGroups);
    }

    setIsValid(true);
    setDndActiveId(null);
  };

  const onSave = () => {
    if (!sortableGroups || sortableGroups.length === 0) return;
    turnOnBackdrop();
    const dashboardGroupInput = sortableGroups.map((el, index) => ({ groupId: el.id, order: index }));
    changeDashboardGroupOrder({
      variables: {
        input: {
          installationId: selectedInstallationId,
          groups: dashboardGroupInput,
        },
      },
      onCompleted: async (data) => {
        turnOffBackdrop();
        if (data.changeGroupsOrder.id) {
          await refetchDashboard();
          navigate(-1);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  return (
    <>
      <ul className="group-list grid-list-16">
        <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={sortableGroups}>
          {sortableGroups.map((group: UserGroup) => (
            <GroupItemSortable key={group.id} sortedGroupId={group.id} groups={sortableGroups} />
          ))}
          <dndCore.DragOverlay>
            {dndActiveId && <GroupItem style={dragOverlayStyle} id={dndActiveId} groups={sortableGroups} isDraggable />}
          </dndCore.DragOverlay>
        </DragAndDrop>
      </ul>
      <SubmitButton disabled={!isValid} isLoading={loading} onClick={onSave} />
    </>
  );
};
