import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { useMutation } from '@apollo/client';
import { DialogConfirmation, EmptyStateBox, Header, IconWarning } from '../../../components';
import { useInstallationContext } from '../../../context/installation';
import { useNativeFunctionsContext } from '../../../context/native-functions';
import {
  DemoPushMutation,
  DemoPushMutationVariables,
  IntegrationType,
} from '../../../data-access/gql-types/graphql';
import { DEMO_PUSH } from '../../../data-access/mutations/notification';
import { isDevelopment } from '../../../utils/helpers/environment';
import { useNotifications } from '../hooks/use-notifications';
import './index.scss';
import { NotificationOption } from './notification-option';
import { NotificationTutorial } from './notification-tutorial';

type NotificationsProps = {
  tutorial: boolean;
  openTutorial: Dispatch<SetStateAction<boolean>>;
};

const Notifications: React.FC<NotificationsProps> = ({ tutorial, openTutorial }) => {
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const [demoVisible, setDemoVisible] = useState<boolean>(false);
  const {
    settings,
    userEmail,
    permissionInfoDialog,
    handleToggle,
    setPermissionInfoDialog,
    controllerNotificationsSupported,
    notificationsSupported,
  } = useNotifications();
  const { selectedInstallation, integrationType } = useInstallationContext();
  const { nativeCtx } = useNativeFunctionsContext();
  const [demoPush] = useMutation<DemoPushMutation, DemoPushMutationVariables>(DEMO_PUSH);

  const sendDemoPush = () => demoPush();

  if (notificationsSupported === null) return null;

  if (notificationsSupported === false) {
    return <EmptyStateBox content={tc('errors.notSupported')} icon={<IconWarning />} />;
  }

  return (
    <>
      {!tutorial ? (
        <div className="notification-settings--container">
          <div className="notification-settings max-width-desktop">
            <Header title={t('notificationSettings.permissions')} />

            <NotificationOption
              label={t('notificationSettings.push')}
              checked={settings.push}
              option="push"
              handleToggle={handleToggle}
            />

            <div className="m-l-24">
              <NotificationOption
                label={t('notificationSettings.devicePush')}
                checked={!!settings.devicePush}
                option="devicePush"
                handleToggle={handleToggle}
              >
                {settings.devicePush !== null && nativeCtx !== null && (
                  <p className={classNames('message', { red: !settings.devicePush })}>
                    {t(`notificationSettings.infos.${settings.devicePush ? 'stillNot' : 'setPermissions'}`)}
                    <span onClick={() => openTutorial(true)}>{t('notificationSettings.infos.more')}</span>
                  </p>
                )}
              </NotificationOption>
            </div>

            <NotificationOption
              label={t('notificationSettings.email')}
              checked={settings.email}
              option="email"
              handleToggle={handleToggle}
            >
              <p className="email" onClick={() => setDemoVisible(!demoVisible)}>
                {userEmail}
              </p>
            </NotificationOption>

            <Header title={t('notificationSettings.kind')} />

            {integrationType === IntegrationType.Lavva && (
              <NotificationOption
                label={t('notificationSettings.installation', {
                  name: selectedInstallation ? `(${selectedInstallation.name})` : '',
                })}
                checked={settings.installation}
                option="installation"
                handleToggle={handleToggle}
              />
            )}

            {integrationType === IntegrationType.Exalus && controllerNotificationsSupported && (
              <NotificationOption
                label={t('notificationSettings.controllerPush')}
                checked={settings.controllerPush}
                option="controllerPush"
                handleToggle={handleToggle}
              />
            )}

            <NotificationOption
              label={t('notificationSettings.shareForward')}
              checked={settings.shareForwardInstallation}
              option="shareForwardInstallation"
              handleToggle={handleToggle}
            />

            {isDevelopment && demoVisible && (
              <button style={{ border: '1px solid black' }} onClick={sendDemoPush}>
                Send demo push
              </button>
            )}
          </div>
        </div>
      ) : (
        <NotificationTutorial setOpen={openTutorial} />
      )}
      <DialogConfirmation
        show={permissionInfoDialog}
        setShow={setPermissionInfoDialog}
        header={t('notificationSettings.devicePush')}
        content={t(
          `notificationSettings.infos.${nativeCtx === NativeContext.None ? 'web' : 'device'}PermissionMessage`,
        )}
        primaryBtnText={`${tc('buttons.understand')}`}
        primaryBtnAction={() => setPermissionInfoDialog(false)}
      />
    </>
  );
};

export default Notifications;
