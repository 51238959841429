import { InstallationAttachmentError } from '../../data-access/gql-types/graphql';
import { useGraphqlContext } from '../../context/graphql';

export const useInstallationAttachementsErrors = () => {
  const { updateBrokerUrl } = useGraphqlContext();

  const handleErrors = (errors: InstallationAttachmentError[]) => {
    if (errors.length > 0) {
      if (errors[0].validBrokerUrl) {
        console.error(errors[0].message);
        updateBrokerUrl(errors[0].validBrokerUrl);
      }
    }
  };

  return {
    handleErrors,
  };
};
