import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EntryPhoneControlHook } from '../types';

export const useEntryPhoneControl = (channel?: IDeviceChannel): EntryPhoneControlHook => {
  const openGateway = async () => {
    console.log(channel);
    alert('COMING SOON');
  };

  const openGate = async () => {
    console.log(channel);
    alert('COMING SOON');
  };

  const call = async () => {
    console.log(channel);
    alert('COMING SOON');
  };

  return {
    openGateway,
    openGate,
    call,
  };
};
