import React from 'react';
import { ChannelBoxControls } from '../../../../../../../components/channel-box-controls';
import {
  IconCirclePadlockClose,
  IconCirclePadlockOpen,
  IconCirclePadlockOpenFull,
} from '../../../../../../../components/icons';
import { ChannelTypeInternal, GateModeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { ChannelGateType, ChannelGateStateType, ChannelType } from '../../../../../types';
import { getGateStep } from '../../../../../utils/channels/helpers/gate';
import { useGate } from '../../../../../hooks';
import { useGateSupportedFeatures } from '../../../../../modules/channel-details/gate/hooks/use-gate-supported';
import { useChannelsStateStore } from '../../../../../store/channels-state';
import { RollerControls } from '../../../../roller-controls';

type GateChannelControlBoxProps = {
  channel: ChannelType;
  isListItem?: boolean;
};

const stepByStepIcon = {
  1: <IconCirclePadlockClose />,
  2: <IconCirclePadlockOpen />,
  3: <IconCirclePadlockOpenFull />,
};

export const GateChannelControlBox: React.FC<GateChannelControlBoxProps> = ({ isListItem, channel }) => {
  const { handleAction, handleStepByStep } = useGate(channel);
  const { lockReportingModeSupported } = useGateSupportedFeatures(channel);
  const state = useChannelsStateStore((s) => s.channelState[channel.id]) as ChannelGateStateType | null;
  const channelData = channel.data as ChannelGateType;
  const gateMode = channelData.gateMode;
  const isStepByStep = channelData.gateMode === GateModeInternal.StepByStep;
  const isQuickControl = channelData.isQuickControl;
  const gateStep = getGateStep(state, !!lockReportingModeSupported);

  const handleGateAction = (type: string) => handleAction(type);

  if (gateMode === GateModeInternal.Unknown) return null;

  if (gateMode === GateModeInternal.RollUp) {
    return <RollerControls handleAction={handleGateAction} isListItem={isListItem} type={ChannelTypeInternal.Gate} />;
  }

  if (isStepByStep && isQuickControl) {
    return (
      <ChannelBoxControls
        kind="normal"
        controls={[{ icon: stepByStepIcon[gateStep], handleAction: handleStepByStep }]}
      />
    );
  }

  return null;
};
