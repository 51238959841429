import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceTask } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import {
  DelayTask,
  LockExecutionTask,
  LockType,
  NotifyTask,
} from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { useBackdropContext } from '../../../../../context/backdrop';
import { toastError } from '../../../../../utils/toast';
import { useExalusCreateActionContext } from '../context';
import { DelayTaskParams, DeviceTaskParams, LockTaskParams, NotifyTaskParams, Task, TaskState } from '../../../../../types/actions';

export const useTaskEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t: tc } = useTranslation('common');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { builder, taskList, taskVariant, addTaskToList, editDeviceTask, editDelayTask, editLockTask, editNotifyTask } =
    useExalusCreateActionContext();
  const [task, setTask] = useState<Task | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    if (id) {
      const index = taskList.findIndex((x) => x.id === id);

      if (index !== -1) {
        setTask(taskList[index]);
      }
    }
  }, []);

  const finalizeDeviceTaskSubmit = async (
    channelTask: IDeviceTask,
    channel: IDeviceChannel,
    taskState: TaskState,
    back: boolean,
  ) => {
    if (!builder) {
      toastError({ content: tc('errors.somethingWentWrong') });
      return;
    }

    turnOnBackdrop();

    if (!task) {
      const commitTask = builder.Commit();
      const deviceTask = commitTask.DeviceTask(channelTask);

      deviceTask.AddTask();
      addTaskToList({
        id: deviceTask.TaskId,
        taskType: SceneTaskType.DeviceTask,
        taskParams: {
          state: taskState,
          channel,
          variant: taskVariant,
        } as DeviceTaskParams,
      });

      turnOffBackdrop();
      if (back) navigate(-4);
    } else {
      const deviceTask = builder.Commit().DeviceTask(channelTask);

      deviceTask.EditTask(task.id);
      editDeviceTask(task.id, taskState);
      turnOffBackdrop();
      navigate(-1);
    }
  };

  const finalizeDelayTaskSubmit = async (delay: DelayTask) => {
    if (!builder) {
      toastError({ content: tc('errors.somethingWentWrong') });
      return;
    }

    turnOnBackdrop();

    if (!task) {
      const commitTask = builder.Commit();
      const delayTask = commitTask.DelayTask(delay);

      delayTask.AddTask();
      addTaskToList({
        id: delayTask.TaskId,
        taskType: SceneTaskType.DelayTask,
        taskParams: {
          delay: delay.Delay.Value,
        } as DelayTaskParams,
      });

      turnOffBackdrop();
      navigate(-2);
    } else {
      const delayTask = builder.Commit().DelayTask(delay);
      delayTask.EditTask(task.id);
      editDelayTask(task.id, delay.Delay.Value);
      turnOffBackdrop();
      navigate(-1);
    }
  };

  const finalizeLockTaskSubmit = async (lock: LockExecutionTask) => {
    if (!builder) {
      toastError({ content: tc('errors.somethingWentWrong') });
      return;
    }

    turnOnBackdrop();

    if (!task) {
      const commitTask = builder.Commit();
      const lockTask = commitTask.LockExecutionTask(lock);

      lockTask.AddTask();
      addTaskToList({
        id: lockTask.TaskId,
        taskType: SceneTaskType.LockExecution,
        taskParams: {
          lockType: lock.LockType,
          resetLockScenesGuids: lock.ResetLockScenesGuids,
          lockStart: lock.LockStartHour.Value,
          ...(lock.LockType === LockType.TimeRange && { lockEnd: lock.LockEndHour.Value }),
        } as LockTaskParams,
      });

      turnOffBackdrop();
      navigate(-2);
    } else {
      const lockTask = builder.Commit().LockExecutionTask(lock);
      lockTask.EditTask(task.id);
      editLockTask(task.id, lock);
      turnOffBackdrop();
      navigate(-1);
    }
  };

  const finalizeNotifyTaskSubmit = async (notify: NotifyTask) => {
    if (!builder) {
      toastError({ content: tc('errors.somethingWentWrong') });
      return;
    }

    turnOnBackdrop();

    if (!task) {
      const commitTask = builder.Commit();
      const notifyTask = commitTask.NotifyTask(notify);

      notifyTask.AddTask();
      addTaskToList({
        id: notifyTask.TaskId,
        taskType: SceneTaskType.NotifyTask,
        taskParams: {
          message: notify.Message,
          notifyType: notify.NotifyType,
          notificationClients: notify.NotificationClients,
        } as NotifyTaskParams,
      });

      turnOffBackdrop();
      navigate(-2);
    } else {
      const notifyTask = builder.Commit().NotifyTask(notify);

      notifyTask.EditTask(task.id);
      editNotifyTask(task.id, notify);
      turnOffBackdrop();
      navigate(-1);
    }
  };

  return {
    task,
    finalizeDeviceTaskSubmit,
    finalizeDelayTaskSubmit,
    finalizeLockTaskSubmit,
    finalizeNotifyTaskSubmit,
  };
};
