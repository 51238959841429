import React from 'react';
import classNames from 'classnames';
import { Page } from '../../../../components';
import { GroupTabs } from '../../components/group';
import { UpdateDevicesDialog } from '../device-add/components/update-devices-dialog';
import { DashboardBody, DashboardLavvaHeader } from './components';
import { useDashboard } from './hooks/use-dashboard';

const Dashboard: React.FC = () => {
  const {
    isListView,
    activeGroup,
    noInstallationSelected,
    activeTabIndex,
    isCarouselContent,
    groupTabsVisible,
    updateDeviceDialogVisible,
    toggleListView,
    handleSetActiveGroupIndex,
  } = useDashboard();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      carouselContent={isCarouselContent}
      className={classNames('dashboard', { 'dashboard--no-selected-installation': !noInstallationSelected })}
      header={
        <>
          <DashboardLavvaHeader isListView={isListView} toggleListView={toggleListView} activeGroup={activeGroup} />
          {groupTabsVisible && (
            <GroupTabs activeTabIndex={activeTabIndex} setActiveTabIndex={handleSetActiveGroupIndex} />
          )}
        </>
      }
    >
      <DashboardBody
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={handleSetActiveGroupIndex}
        isListView={isListView}
      />
      {updateDeviceDialogVisible && <UpdateDevicesDialog />}
    </Page>
  );
};

export default Dashboard;
