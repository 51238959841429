import {
  ChannelTypeInternal,
  OptimizerChannelStateResponse,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { ChannelStateType, ChannelType } from '../../../types';

export const parseOptimizerChannel = (userChannel: UserChannel): ChannelType => {
  const channelPayload = userChannel.payload as OptimizerChannelStateResponse | undefined;

  return {
    id: userChannel.id,
    installationId: userChannel.installationId,
    isVisible: userChannel.isVisible,
    alias: userChannel.alias,
    iconName: userChannel.iconName || ChannelIconTypeEnum.METER,
    deviceId: userChannel.deviceId,
    currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
    data: {
      type: ChannelTypeInternal.Optimizer,
      payload: channelPayload,
      supportedVectorParameters: channelPayload?.supportedVectorParameters || [],
      supportedScalarParameters: channelPayload?.supportedScalarParameters || [],
      supportedAggregateVectorParameters: channelPayload?.supportedAggregateVectorParameters || [],
    },
  };
};

export const parseOptimizerStateChannel = (userChannel: UserChannel): ChannelStateType => ({
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
