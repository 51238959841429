import {
  BlindChannelStateResponse,
  ChannelTypeInternal,
  PositionFavourites,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { ChannelStateType, ChannelType, FavouritePositions } from '../../../types';

export const parseCoverChannel = (userChannel: UserChannel): ChannelType => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.COVER,
  deviceId: userChannel.deviceId,
  currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
  data: {
    type: ChannelTypeInternal.Blind,
    favouritePositionList: (userChannel.favouritePositions as PositionFavourites[])?.map((position) =>
      position.value ? Math.round(position.value * 100) : 0,
    ) as FavouritePositions,
    measuredBy: (userChannel.payload as BlindChannelStateResponse)?.measuredBy || undefined,
  },
});

export const parseCoverChannelState = (userChannel: UserChannel): ChannelStateType => {
  const channelPayload = userChannel.payload as BlindChannelStateResponse | undefined;

  return {
    lavvaStateDirection: channelPayload?.direction,
    lavvaStatePosition: channelPayload?.position,
    calibrateState: channelPayload?.calibrateState || undefined,
    movingParams: {
      predictedTimeInMs: null,
      targetPosition: null,
      slatsPredictedTimeInMs: null,
    },
    deviceConnectionState: channelPayload?.deviceConnectionState,
    usesCounter: userChannel.usesCounter,
    grantedUses: userChannel.grantedUses || undefined,
  };
};
