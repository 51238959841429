import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { ControlWrapper, EmptyStateBox, IconError, IconWarning } from '../../../../../../../components';
import { GateKindInternal } from '../../../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../../../context/devices-and-channels';
import { ChannelGateType } from '../../../../../types';
import { useGateHistory } from '../../hooks/use-gate-history';
import { gateHasVentilation } from '../../utils';
import { HistoryItem } from './history-item';
import './index.scss';

type GateHistoryProps = {
  channelId: string;
};

export const GateHistory: React.FC<GateHistoryProps> = ({ channelId }) => {
  const { t } = useTranslation('channel-details');
  const { t: tb } = useTranslation('backend-validation');
  const { t: tc } = useTranslation('common');
  const { channel } = useDevicesAndChannelsContext();
  const { historyError, emptyData, loading, entries, totalCount, loadMore } = useGateHistory(channelId);

  const hasVentilation = useMemo(
    () => gateHasVentilation((channel?.data as ChannelGateType)?.gateKind || GateKindInternal.Unknown),
    [channel?.data],
  );

  const historyData = useMemo(() => {
    return [...(entries || [])].map((item) => (
      <HistoryItem key={item.id} item={item} hasVentilation={hasVentilation} />
    ));
  }, [entries, hasVentilation]);

  return (
    <ControlWrapper className="control-wrapper--column control-wrapper--full-space gate-history">
      {historyError && (
        <div className="history-error">
          <IconError />
          <p>{tb(historyError.errorCode)}</p>
        </div>
      )}
      {emptyData !== null && (
        <>{emptyData && !loading ? <EmptyStateBox content={t('noHistory')} icon={<IconWarning />} /> : historyData}</>
      )}

      <div className="load-more-container">
        {loading ? (
          <CircularProgress color="inherit" />
        ) : entries.length < totalCount ? (
          <button className="button" onClick={loadMore}>
            {tc('buttons.loadMore')}
          </button>
        ) : null}
      </div>
    </ControlWrapper>
  );
};
