import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Markup } from 'react-render-markup';
import { useLocation } from 'react-router-dom';
import { Header, NavHeader, Page } from '../../components';
import { useFetchDocuments } from '../../hooks';
import './index.scss';

const TermsPage: React.FC = () => {
  const { i18n, t } = useTranslation('terms');
  const { content } = useFetchDocuments('terms');
  const { search } = useLocation();

  useEffect(() => {
    const lang = new URLSearchParams(search).get('lang');
    if (lang) i18n.changeLanguage(lang);
  }, [search]);

  return (
    <Page
      className="terms"
      header={
        <>
          <NavHeader />
          <Header title={t('termsHeader')} />
        </>
      }
    >
      <Markup markup={content} />
    </Page>
  );
};

export default TermsPage;
