import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNumber } from 'lodash';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  RecoveryDeviceStatus,
  RssiQuery,
  RssiQueryVariables,
} from '../../../../../data-access/gql-types/graphql';
import { RSSI } from '../../../../../data-access/queries/devices';
import { convertDateToFormat } from '../../../../../utils/helpers';
import { getSignalStrength } from '../../../../../utils/helpers/network';
import { useGetDeviceStatus } from '../../../api/recovery/recovery.hooks';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useDevicesAndChannels } from '../../../hooks';
import { useChannelsStateStore } from '../../../store/channels-state';
import { checkDeviceStatus } from '../../../utils/devices/status';

export const useConfigurationDetails = () => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { deviceId } = useParams<{ channelType: ChannelTypeInternal; deviceId: string }>();
  const { device } = useDevicesAndChannelsContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId });
  const channelState = useChannelsStateStore((s) => s.channelState);
  const { mutate } = useGetDeviceStatus();
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const { data, networkStatus, refetch } = useQuery<RssiQuery, RssiQueryVariables>(RSSI, {
    variables: { installationId: selectedInstallationId, deviceId: deviceId },
    fetchPolicy: 'network-only',
    skip: skipLavvaFetch,
    notifyOnNetworkStatusChange: true,
  });

  const status = device?.recoveryMode?.status;
  const progress = device?.recoveryMode?.progress;

  const isDeviceDisconnected = checkDeviceStatus(deviceChannels, channelState, DeviceConnectionState.Disconnected);
  const isDeviceInRecovery = checkDeviceStatus(deviceChannels, channelState, DeviceConnectionState.Recovery);
  const isDeviceConnected = checkDeviceStatus(deviceChannels, channelState, DeviceConnectionState.Connected);

  useEffect(() => {
    if (isDeviceInRecovery && device) mutate({ deviceId: device.id });
  }, [isDeviceInRecovery]);

  const registeredAt = device?.payload.registeredAtUtc
    ? convertDateToFormat(new Date(device.payload.registeredAtUtc), 'dd.MM.yy, HH:mm:ss')
    : '-';

  const lastConnectedAt = device?.payload.lastConnectedAtUtc
    ? convertDateToFormat(new Date(device.payload.lastConnectedAtUtc), 'dd.MM.yy, HH:mm:ss')
    : '-';

  const rssi = data?.rssi && isNumber(data.rssi.rssi) ? getSignalStrength(data.rssi.rssi, true) : null;

  const buttonContent =
    status === RecoveryDeviceStatus.DownloadingFirmware ? tc('buttons.loading') : t('updateSoftware.update');

  return {
    isDeviceInRecovery,
    registeredAt,
    status,
    buttonContent,
    progress,
    isDeviceConnected,
    isDeviceDisconnected,
    lastConnectedAt,
    rssi,
    rssiLoading: networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch,
    refetchRssi: refetch,
  };
};
