import React, { useState, useEffect, useCallback, ReactNode, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { useNotificationsOperations } from '../../hooks/notifications/use-notifications-operations';
import { ROUTES } from '../../routes';
import { NotificationType, NotificationInvitationType, NotificationTypeEnum } from '../../types';
import { CircularProgress } from '../circular-progress';
import { IconDelete, IconNotification } from '../icons';
import './index.scss';

type NotificationItemProps = {
  notification: NotificationType;
  title: string;
  setNotifications: Dispatch<SetStateAction<NotificationType[]>>;
  children: ReactNode;
};

const ONE_MIN_IN_MS = 1000 * 60;
const ONE_HOUR_IN_MS = 1000 * 60 * 60;
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  title,
  children,
  setNotifications,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('notifications');
  const { markAsHidden, markAsDisplayed, markAsHiddenLoading, markAsDisplayedLoading } = useNotificationsOperations();
  const [timeElepse, setTimeElapse] = useState<string>('');
  const [timeElapseTimeout, setTimeElapseTimeout] = useState<NodeJS.Timeout>();
  const [isSwiped, setSwiped] = useState<boolean>(false);

  const onDeleteNotification = () =>
    markAsHidden(notification.correlationId, {
      onSuccess: () => {
        setNotifications((prev) => {
          const tempList = cloneDeep(prev);
          const index = tempList.findIndex((item) => item.id === notification.id);
          if (index !== -1) tempList[index].isHidden = true;

          return tempList;
        });
      },
    });

  const handlers = useSwipeable({
    onSwipedLeft: () => setSwiped(false),
    onSwipedRight: () => setSwiped(true),
    trackMouse: true,
    preventDefaultTouchmoveEvent: true,
  });

  const calculateElapsedTime = useCallback(() => {
    if (!notification || !notification.createdTimestampUTC) return;

    clearTimeout(timeElapseTimeout as number | undefined);

    const timeNow = new Date().getTime();
    const timeDiff = timeNow - notification.createdTimestampUTC;
    let delay = 0;

    if (timeDiff < ONE_MIN_IN_MS) {
      setTimeElapse(t('now'));
      delay = ONE_MIN_IN_MS - timeDiff;
    } else if (timeDiff < ONE_HOUR_IN_MS) {
      setTimeElapse(Math.floor(timeDiff / ONE_MIN_IN_MS) + 'm ' + t('ago'));
      delay = ONE_MIN_IN_MS - (timeDiff % ONE_MIN_IN_MS);
    } else if (timeDiff < ONE_DAY_IN_MS) {
      setTimeElapse(Math.floor(timeDiff / ONE_HOUR_IN_MS) + 'h ' + t('ago'));
      delay = ONE_HOUR_IN_MS - (timeDiff % ONE_HOUR_IN_MS);
    } else {
      setTimeElapse(Math.floor(timeDiff / ONE_DAY_IN_MS) + 'd ' + t('ago'));
      delay = ONE_DAY_IN_MS - (timeDiff % ONE_DAY_IN_MS);
    }

    setTimeElapseTimeout(setTimeout(() => calculateElapsedTime(), delay));
  }, [timeElapseTimeout, setTimeElapseTimeout, notification]);

  const onClick = () => {
    markAsDisplayed(notification.correlationId, {
      onSuccess: () => {
        setNotifications((prev) => {
          const tempList = cloneDeep(prev);
          const index = tempList.findIndex((item) => item.id === notification.id);
          if (index !== -1) tempList[index].wasDisplayed = true;

          return tempList;
        });

        if (notification.data.type === NotificationTypeEnum.INVITATION) {
          switch (notification.data.type) {
            case NotificationTypeEnum.INVITATION:
              switch (notification.data.invitationType) {
                case NotificationInvitationType.InvitationReceived:
                  navigate(ROUTES.InvitationReceived(notification.id));
                  break;
                case NotificationInvitationType.InvitationAcceptedByInvitee:
                  navigate(ROUTES.InvitationAccepted(notification.id));
                  break;
                default:
                  break;
              }
              break;
          }
        }
      },
    });
  };

  useEffect(calculateElapsedTime, [notification?.createdTimestampUTC]);

  if (!notification) return null;

  const loading = markAsHiddenLoading || markAsDisplayedLoading;

  return (
    <div className="notification-item-wrapper">
      <div className="notification-item-wrapper__delete-section" onClick={onDeleteNotification}>
        <IconDelete className="icon-delete--element" />
      </div>
      <div
        className={classNames('notification-item box box--rounder', {
          'notification-item--was-displayed': notification.wasDisplayed,
          'notification-item--is-swiped': isSwiped,
        })}
        onClick={onClick}
        {...handlers}
      >
        <header className="notification-item__header">
          <h4 className="notification-item__header-title">{title}</h4>
          <div className="notification-item__header-icon">
            {loading ? <CircularProgress size={20} /> : <IconNotification isOn={!notification.wasDisplayed} />}
          </div>
        </header>
        {children}
        {notification.createdTimestampUTC && <p className="notification-item__time-elapse">{timeElepse}</p>}
      </div>
    </div>
  );
};
