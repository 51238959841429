import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CustomBackdrop, Header, NavHeader, Page, PopUpNav, Tabs } from '../../../../components';
import { IconShare } from '../../../../components/popup-nav/icons';
import { ChannelTypeInternal, UserPermissionType } from '../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../hooks/user-permissions';
import { useShareRedirect } from '../../../../modules/installation-share/hooks/use-share-redirect';
import { ROUTES } from '../../../../routes';
import { pathnameWithParameters } from '../../../../utils/location';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';
import { useGetChannelDetails } from '../channel-details/hooks/use-get-channel-details';
import ChannelConfigurationForm from './channel-form';
import InfoPage from './components/info-page';
import ChannelGeneralConfiguration from './general-configuration';
import './index.scss';
import { InfoPageType } from './types';
import { configurationExists } from './utils';

const ChannelConfiguration: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('configuration');
  const { t: td } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { channelType, channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const ChannelId = channelId ?? '';
  const { permissions } = useUserPermissions();
  const { channel, setChannelDetails, clearChannelDetails } = useDevicesAndChannelsContext();
  const { data, loading } = useGetChannelDetails(ChannelId);
  const [infoPage, setInfoPage] = useState<InfoPageType>(InfoPageType.UNDEFINED);
  const { shareChannel } = useShareRedirect();

  useEffect(() => {
    if (data?.userChannel) setChannelDetails(data.userChannel);
    return () => clearChannelDetails();
  }, [data]);

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, [search]);

  const configurationVisible = useMemo(
    () =>
      channelType && configurationExists.includes(channelType) && permissions[UserPermissionType.ChannelConfiguration],
    [channelType, permissions],
  );

  const tabs = useMemo(
    () => [{ label: t('general') }, ...(configurationVisible ? [{ label: t('configuration') }] : [])],
    [configurationVisible, t],
  );

  const popupNavOptions = useMemo(
    () =>
      permissions[UserPermissionType.ChannelShare]
        ? [
            {
              onClick: () => shareChannel(channel),
              icon: <IconShare />,
              label: td('shareChannel'),
            },
          ]
        : [],
    [permissions[UserPermissionType.ChannelShare]],
  );

  const handleTabChange = (tabIndex: number) => {
    navigate(
      pathnameWithParameters(ROUTES.ChannelConfiguration(channelType, ChannelId), search, {
        key: 'tab',
        value: tabIndex,
      }),
      { replace: true },
    );
  };

  const renderCurrentTabContent = () => {
    switch (activeTabIndex) {
      case 1:
        return <ChannelConfigurationForm setInfoPage={setInfoPage} />;
      default:
        return <ChannelGeneralConfiguration setInfoPage={setInfoPage} />;
    }
  };

  return (
    <>
      {infoPage === InfoPageType.UNDEFINED ? (
        <Page
          isStickyHeader
          className="channel_configuration"
          header={
            <>
              <NavHeader>
                <PopUpNav links={popupNavOptions} />
              </NavHeader>
              <Header title={t('channelSettings')} />
              <Tabs
                tabList={tabs}
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={handleTabChange}
                tabsType="pills"
                isDivider={false}
              />
            </>
          }
        >
          <div className="channel_configuration--content max-width-desktop">{renderCurrentTabContent()}</div>
        </Page>
      ) : (
        <InfoPage infoPage={infoPage} changePage={setInfoPage} />
      )}
      <CustomBackdrop loading={loading} />
    </>
  );
};

export default ChannelConfiguration;
