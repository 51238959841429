import React from 'react';
import { ControlWrapper, IconInitiate, InitiateButton, SubmitButton } from '../..';

type OnOffChannelSettingsProps = {
  isOn: boolean;
  setIsOn: (isOn: boolean) => void;
  handleSubmit: () => void;
};

export const OnOffChannelSettings: React.FC<OnOffChannelSettingsProps> = ({ isOn, setIsOn, handleSubmit }) => (
  <>
    <ControlWrapper>
      <InitiateButton isOn={isOn} onClickControl={() => setIsOn(!isOn)}>
        <IconInitiate />
      </InitiateButton>
    </ControlWrapper>
    <SubmitButton onClick={handleSubmit} />
  </>
);
