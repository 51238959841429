import React from 'react';
import { defaultFavouriteSetOnTimeList } from '../../../../../../const';
import { SwitchDetailsTimeActivations } from '../../../../components/channel-details/switch/time-activatons';
import { useSwitchAction } from '../../../../hooks';
import { ChannelType } from '../../../../types';

type ActionTimesActivationsProps = {
  channels: ChannelType[];
};

export const ActionTimesActivations: React.FC<ActionTimesActivationsProps> = ({ channels }) => {
  const { handleSetOnTime } = useSwitchAction(channels);

  const onTimeChange = (value: number) => handleSetOnTime(value);

  return (
    <SwitchDetailsTimeActivations
      setOnTimeList={defaultFavouriteSetOnTimeList}
      onTimeChange={onTimeChange}
      handleSetOnTime={handleSetOnTime}
    />
  );
};
