import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { CheckboxesOptions } from '../../../../../../../components/checkbox-select';
import { PHASES_COLORS } from '../../../../../../../const';
import { useInstallationContext } from '../../../../../../../context/installation';
import {
  AggregateVectorMeasurementResponseItem,
  AggregateVectorParameterTypeInternal,
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeterAggregatedMeasurementsQueryVariables,
  MeterTypeInternal,
  Query,
  TariffKindInternal,
} from '../../../../../../../data-access/gql-types/graphql';
import { METER_AGGREGATED_MEASUREMENTS } from '../../../../../../../data-access/queries/meter';
import { MeasurementRange, SelectedPhase } from '../../../../../../../types';
import { useApi } from '../../../../../hooks';
import { ChannelMeterType } from '../../../../../types';
import { AnalysisHookParams, ChartDataItem } from '../types';
import { getParameterColor, predictionParameters, prepareParametersForRequest } from '../utils/helpers';

export const useMeterAnalysis = ({ channel }: AnalysisHookParams) => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId } = useInstallationContext();
  const [selectedPhases, setSelectedPhases] = useState<SelectedPhase[]>();
  const { convertMeasurementToNumber, measurementMap } = useApi();
  const [aggregatedParameters, setAggregatedParameters] = useState<
    CheckboxesOptions<AggregateVectorParameterTypeInternal>[]
  >([]);
  const [activeMeasurementRange, setActiveMeasurementRange] = useState<MeasurementRange>(MeasurementRange.DayInHours);
  const [page, setPage] = useState<number>(0);
  const [getAggregatedMeasurements, { data, loading }] = useLazyQuery<Query, MeterAggregatedMeasurementsQueryVariables>(
    METER_AGGREGATED_MEASUREMENTS,
  );
  const channelData = channel?.data as ChannelMeterType | undefined;
  const tariffKind = channelData?.tariff?.kind;
  const supported = channelData?.supportedAggregateVectorParameters || [];

  const checkIfSupported = (param: AggregateVectorParameterTypeInternal) =>
    supported.includes(convertMeasurementToNumber(MeasurementChannelKind.Meter)('aggregates', param));

  useEffect(() => {
    if (supported.length && !aggregatedParameters.length && measurementMap) {
      setAggregatedParameters([
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ForwardActiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
          checked: true,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ForwardActiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ReverseActiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ReverseActiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ForwardReactiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ForwardReactiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ReverseReactiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ReverseReactiveEnergy),
        },
        {
          label: t('energyMeasurements.types.summary.VECTOR_BALANCED_ACTIVE_ENERGY'),
          value: AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming),
        },
        {
          label: t('price'),
          value: AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
          checked: false,
          disabled: !tariffKind || tariffKind === TariffKindInternal.None,
        },
      ]);
    }
  }, [supported, t, convertMeasurementToNumber, measurementMap, aggregatedParameters, tariffKind]);

  useEffect(() => {
    const aggregateVectorRequestItems = prepareParametersForRequest(
      aggregatedParameters,
      activeMeasurementRange,
      checkIfSupported,
    );

    getAggregatedMeasurements({
      variables: {
        installationId: selectedInstallationId,
        input: {
          channelId: channel.id,
          deviceId: channel.deviceId,
          indices: selectedPhases?.length ? selectedPhases.map((x) => x.value) : [0],
          aggregateVectorRequestItems,
        },
      },
    });
  }, [aggregatedParameters, activeMeasurementRange, supported, selectedPhases]);

  const aggregatedMeasurements = useMemo(() => {
    return (data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements || []).filter((measurement) => {
      return !(predictionParameters.includes(measurement.type) && !measurement.values.length);
    });
  }, [data?.meterAggregatedMeasurements]);

  const mappedChartData: ChartDataItem[] = useMemo(() => {
    const itemsData: ChartDataItem[] = [];

    if (aggregatedMeasurements.length) {
      const obj = groupBy(aggregatedMeasurements, 'type') as Record<string, AggregateVectorMeasurementResponseItem[]>;

      Object.keys(obj).forEach((key) => {
        obj[key].forEach((measurement) => {
          for (let i = 0; i < measurement.values.length; i++) {
            if (!itemsData[i]) {
              itemsData[i] = { values: [] };
            }

            if (measurement.values[i] !== undefined) {
              itemsData[i].values.push({
                type: measurement.type,
                index: measurement.index,
                value: measurement.values[i] / 1000,
                color: measurement.index ? PHASES_COLORS[measurement.index] : getParameterColor(measurement.type),
              });
            }
          }
        });
      });

      return itemsData;
    }

    return [];
  }, [activeMeasurementRange, aggregatedMeasurements]);

  const onClickActiveMeasurementRange = (value: MeasurementRange) => {
    setActiveMeasurementRange(value);
    setPage(0);

    setAggregatedParameters((prev) => {
      return prev.map((x) => {
        if (value === MeasurementRange.DayInHours) {
          return {
            ...x,
            checked: x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice ? false : x.checked,
            disabled:
              x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice
                ? !tariffKind || tariffKind === TariffKindInternal.None
                : x.disabled,
          };
        }

        return {
          ...x,
          checked: x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice ? false : x.checked,
          disabled: x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice ? true : x.disabled,
        };
      });
    });
  };

  const handleSetAggregatedParameters = (options: CheckboxesOptions<AggregateVectorParameterTypeInternal>[]) => {
    setSelectedPhases([{ value: 0, label: t('sumOfPhases'), color: PHASES_COLORS[0] }]);
    setAggregatedParameters(options);
  };

  const isStandalone =
    channelData?.type === ChannelTypeInternal.Meter && channelData?.meterType === MeterTypeInternal.Standalone;

  return {
    data: data?.meterAggregatedMeasurements?.data,
    isLoading: loading,
    selectedPhases,
    setSelectedPhases,
    mappedChartData,
    onClickActiveMeasurementRange,
    handleSetAggregatedParameters,
    aggregatedParameters,
    activeMeasurementRange,
    page,
    setPage,
    isStandalone,
  };
};
