import React from 'react';
import { ChannelType } from '../../../enums';
import { CentralActionType } from '../../../modules/dashboard/types';
import { CentralActionBlindControlBox } from './blind/control-box';
import { CentralActionFacadeControlBox } from './facade/control-box';
import { CentralActionSwitchControlBox } from './switch/control-box';

type CentralActionControlProps = {
  centralAction: CentralActionType;
  isListItem?: boolean;
};

export const CentralActionControl: React.FC<CentralActionControlProps> = ({ centralAction, isListItem = false }) => (
  <div className="action-control">
    {centralAction.type === ChannelType.Blind ? (
      <CentralActionBlindControlBox channels={centralAction.items} isListItem={isListItem} />
    ) : centralAction.type === ChannelType.Facade ? (
      <CentralActionFacadeControlBox channels={centralAction.items} isListItem={isListItem} />
    ) : centralAction.type === ChannelType.Switch || centralAction.type === ChannelType.Light ? (
      <CentralActionSwitchControlBox channels={centralAction.items} isListItem={isListItem} />
    ) : null}
  </div>
);
