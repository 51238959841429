import React from 'react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import './index.scss';

type QRCodeReaderProps = {
  onHandleScan: (data: string) => void;
};

export const QRCodeReader: React.FC<QRCodeReaderProps> = ({ onHandleScan }) => {
  const handleScan = (data: string | null) => {
    if (data) onHandleScan(data);
  };

  return (
    <div className="qr-reader">
      <QrScanner
        audio={false}
        viewFinderBorder={18}
        scanDelay={300}
        onResult={(result) => {
          if (result) handleScan(result.toString());
        }}
      />
    </div>
  );
};
