import { useTranslation } from 'react-i18next';
import { ActivityType, ChannelTypeInternal, UserAction } from '../../../../data-access/gql-types/graphql';
import { toastInfo } from '../../../../utils/toast';
import { useActionBatch } from '../../api/action/action.hooks';
import {
  ActionBatchBody,
  BatchChannel,
  CoverDirectionBatchChannel,
  CoverPositionBatchChannel,
  GateDirectionBatchChannel,
  GatePositionBatchChannel,
  LightBrightnessBatchChannel,
  LightRGBBatchChannel,
  SwitchLightOnOffBatchChannel,
  TypeOfActionType,
} from '../../api/action/action.types';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';
import { DashboardUserAction } from '../../types';
import { CoverDirection, GateDirection } from '../../types/channel';

export const useAdvancedAction = (userAction: DashboardUserAction | UserAction) => {
  const { t } = useTranslation('action');
  const actionBatch = useActionBatch();
  const { channelList } = useDevicesAndChannelsContext();

  const handleAction = () => {
    const allControls = userAction.action.channelActions
      .map((channelAction) => {
        const deviceId = channelList.find((x) => x.id === channelAction.channelId)?.deviceId;

        if (deviceId) {
          switch (channelAction.activityType) {
            case ActivityType.On:
              return {
                deviceId,
                channelId: channelAction.channelId,
                value: true,
                $type:
                  channelAction.channelType === ChannelTypeInternal.Light
                    ? TypeOfActionType.BatchLightToggleOnOffRequest
                    : TypeOfActionType.BatchSwitchToggleOnOffRequest,
              } as SwitchLightOnOffBatchChannel;
            case ActivityType.Off:
              return {
                deviceId,
                channelId: channelAction.channelId,
                value: false,
                $type:
                  channelAction.channelType === ChannelTypeInternal.Light
                    ? TypeOfActionType.BatchLightToggleOnOffRequest
                    : TypeOfActionType.BatchSwitchToggleOnOffRequest,
              } as SwitchLightOnOffBatchChannel;
            case ActivityType.Open: {
              if (channelAction.channelType === ChannelTypeInternal.Blind) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  direction: CoverDirection.open,
                  $type: TypeOfActionType.BatchBlindSetDirectionRequest,
                } as CoverDirectionBatchChannel;
              }

              if (channelAction.channelType === ChannelTypeInternal.Gate) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  direction: GateDirection.open,
                  $type: TypeOfActionType.BatchGateSetDirectionRequest,
                } as GateDirectionBatchChannel;
              }

              return;
            }
            case ActivityType.Close: {
              if (channelAction.channelType === ChannelTypeInternal.Blind) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  direction: CoverDirection.close,
                  $type: TypeOfActionType.BatchBlindSetDirectionRequest,
                } as CoverDirectionBatchChannel;
              }

              if (channelAction.channelType === ChannelTypeInternal.Gate) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  direction: GateDirection.close,
                  $type: TypeOfActionType.BatchGateSetDirectionRequest,
                } as GateDirectionBatchChannel;
              }

              return;
            }
            case ActivityType.Stop: {
              if (channelAction.channelType === ChannelTypeInternal.Blind) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  direction: CoverDirection.stop,
                  $type: TypeOfActionType.BatchBlindSetDirectionRequest,
                } as CoverDirectionBatchChannel;
              }

              if (channelAction.channelType === ChannelTypeInternal.Gate) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  direction: GateDirection.stop,
                  $type: TypeOfActionType.BatchGateSetDirectionRequest,
                } as GateDirectionBatchChannel;
              }

              return;
            }

            case ActivityType.SetPosition: {
              if (channelAction.channelType === ChannelTypeInternal.Blind) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  position: parseInt(channelAction.value),
                  $type: TypeOfActionType.BatchBlindSetPositionRequest,
                } as CoverPositionBatchChannel;
              }

              if (channelAction.channelType === ChannelTypeInternal.Gate) {
                return {
                  deviceId,
                  channelId: channelAction.channelId,
                  position: parseInt(channelAction.value),
                  $type: TypeOfActionType.BatchGateSetPositionRequest,
                } as GatePositionBatchChannel;
              }

              return;
            }

            case ActivityType.SetDim:
              return {
                deviceId,
                channelId: channelAction.channelId,
                brightness: parseInt(channelAction.value),
                $type: TypeOfActionType.BatchLightSetBrightnessRequest,
              } as LightBrightnessBatchChannel;
            case ActivityType.SetLightColor: {
              const [R, G, B] = channelAction.value.split(',').map((x) => parseInt(x));

              return {
                deviceId,
                channelId: channelAction.channelId,
                r: R,
                g: G,
                b: B,
                $type: TypeOfActionType.BatchLightSetRgbRequest,
              } as LightRGBBatchChannel;
            }
            case ActivityType.Tilt:
              return {
                deviceId,
                channelId: channelAction.channelId,
                $type: TypeOfActionType.BatchGateTiltRequest,
              } as BatchChannel;
          }
        }
      })
      .filter((x) => x?.channelId);

    if (allControls.length) {
      actionBatch.mutate({
        controls: allControls,
      } as ActionBatchBody);
    }
  };

  const setActionOn = () => {
    if (!userAction?.action.channelActions.length) {
      toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
      return;
    }

    handleAction();
  };

  return { setActionOn };
};
