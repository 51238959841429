import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { environment } from '../../../../environment';
import { useErrors } from '../../../../hooks/errors';
import { useRestClient } from '../../../../utils/clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import {
  OptimizerErrorGlobal,
  OptimizerSetConfigBody,
  OptimizerSetModeBody,
  OptimizerSetTestModeLoadBody,
  tariffData,
  TariffItem,
} from './optimizer.types';

export const useOptimizerSetMode = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  OptimizerSetModeBody
> => {
  const { t } = useTranslation('backend-validation');
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, OptimizerSetModeBody>(
    ['optimizer-setMode'],
    async (body) => await restClient.post<DeviceResponse>('/optimizer/setMode', body),
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error, t((error.response?.data as OptimizerErrorGlobal).errorCode));
      },
    },
  );
};

export const useOptimizerSetTestModeLoad = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  OptimizerSetTestModeLoadBody
> => {
  const restClient = useRestClient();
  const { t } = useTranslation('backend-validation');
  const { handleRestErrors, handleLavvaResolve } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, OptimizerSetTestModeLoadBody>(
    ['optimizer-setTestModeLoad'],
    async (body) => await restClient.post<DeviceResponse>('/optimizer/setTestModeLoad', body),
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error, t((error.response?.data as OptimizerErrorGlobal).errorCode));
      },
    },
  );
};

export const useOptimizerSetConfig = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  OptimizerSetConfigBody
> => {
  const restClient = useRestClient();
  const { handleLavvaResolve } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, OptimizerSetConfigBody>(
    ['optimizer-setConfig'],
    async (body) => await restClient.post<DeviceResponse>('/optimizer/setConfig', body),
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
    },
  );
};

export const useGetTariffs = (type: ChannelTypeInternal): UseQueryResult<TariffItem[], AxiosError> => {
  const restClient = useRestClient({ noInstallationIdHeader: true });
  const { handleRestErrors } = useErrors();

  return useQuery<TariffItem[], AxiosError>(
    'query-tariffs',
    async () => {
      const response = await restClient.get<TariffItem[]>(`/api/tariffs-${tariffData[type]}.json`, {
        baseURL: environment.PUBLIC_URL,
      });

      return response.data;
    },
    {
      enabled: true,
      retry: false,
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
