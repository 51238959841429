import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { CustomBackdrop } from './components';
import { ExalusBackdropPopup } from './integrations/exalus/components/exalus-backdrop-popup';
import GlobalProviders from './providers';
import { AppRoute, UnauthenticatedRouter } from './routes';
import './styles/main.scss';

declare global {
  interface Window {
    configuration: Record<string, string>;
    loglevel: number;
    chrome: any;
    opr?: any;
  }
}

const DefaultRouterApp: React.FC = () => (
  <GlobalProviders>
    <AppRoute />
    <CustomBackdrop />
    <ExalusBackdropPopup />
  </GlobalProviders>
);

export const AppLocal: React.FC = () => <DefaultRouterApp />;

export const App: React.FC = () => {
  const { keycloak } = useKeycloak();

  return <>{keycloak.authenticated ? <DefaultRouterApp /> : <UnauthenticatedRouter />}</>;
};
