import React, { useMemo } from 'react';
import { RgbSceneIconTypeEnum } from '../../../../../types';
import { availableRgbSceneIcons } from '../edit-icon/available-icons';

type RgbSceneIconProps = {
  iconName: RgbSceneIconTypeEnum;
};

export const RgbSceneIcon: React.FC<RgbSceneIconProps> = ({ iconName }) => {
  const SceneIcon = useMemo(() => {
    const icon = availableRgbSceneIcons.find((icon) => icon.iconName === iconName);
    if (icon) return icon.component;
    return availableRgbSceneIcons[0].component;
  }, [iconName]);

  return <>{SceneIcon}</>;
};
