import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/IZfBidiConfigService';
import { FacadeLockTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import {
  FacadeConfigurationErrorCode,
  ZfBidiConfigService,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { Checkbox, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { toastSuccess } from '../../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { ReactionWindParams } from '../../../device-details/types';

type ReactionWindFormProps = {
  device: IDevice;
  channel: IDeviceChannel;
  reactionWind: ReactionWindParams | null;
};

const ReactionWindForm: React.FC<ReactionWindFormProps> = ({ device, channel, reactionWind }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleError } = useHandleDataFrameErrors();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<ReactionWindParams>();

  const { active, blindLocked } = form.watch();

  useEffect(() => {
    if (reactionWind !== null) {
      form.setValue('active', reactionWind.active);
      form.setValue('lockTime', reactionWind.lockTime);
      form.setValue('blindLocked', reactionWind.blindLocked);
    }
  }, [reactionWind]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IZfBidiConfigService>(
      ZfBidiConfigService.ServiceName,
    );

    const resultActive = await configService.SetWindSensorActiveAsync(device, channel.Number, values.active);

    if (resultActive === Status.OK) {
      const resultLockTime = await configService.SetWindSensorLockTimeAsync(
        device,
        channel.Number,
        values.lockTime as FacadeLockTime,
      );
      handleSubmitResponse(resultLockTime);
    } else {
      handleError(resultActive);
    }

    turnOffBackdrop();
  });

  const unblockFacade = async () => {
    if (blindLocked && channel) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IZfBidiConfigService>(
        ZfBidiConfigService.ServiceName,
      );

      const result = await configService.ClearBlindLockByWindSensorAsync(device, channel.Number);
      const blindLocked = await configService.IsBlindLockedByWindSensorAsync(device, channel.Number);

      if (!(blindLocked as ResponseResult<FacadeConfigurationErrorCode>).Type) {
        if (result === Status.OK) {
          form.setValue('blindLocked', blindLocked as boolean);
          toastSuccess({ content: t('xalus.params.ZfBidiParams.unblockedSuccessfully') });
        } else {
          handleError(result);
        }
      }

      turnOffBackdrop();
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <Checkbox
          checked={active}
          onChange={(e) => {
            form.setValue('active', e.target.checked);
          }}
          reverse
        >
          {t('exalus.params.ZfBidiParams.windReactionSensor')}
        </Checkbox>
        {active && (
          <>
            <ConfigurationInput
              name="lockTime"
              label={t('exalus.params.ZfBidiParams.timeLock')}
              placeholder={t('exalus.params.ZfBidiParams.timeLock')}
              inputType="number"
              min={0}
              max={256}
            />
            <div className="row-container">
              <p>{t('exalus.params.ZfBidiParams.blindLocked')}</p>
              <p>{t(`exalus.params.ZfBidiParams.${blindLocked ? 'active' : 'inactive'}`)}</p>
            </div>
            {blindLocked && (
              <button className="button button--secondary" onClick={unblockFacade}>
                {t('exalus.params.ZfBidiParams.unblock')}
              </button>
            )}
          </>
        )}
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default ReactionWindForm;
