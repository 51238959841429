import {
  GateFeatureTypeInternal,
  GateKindInternal,
  GateModeInternal,
} from '../../../../../../data-access/gql-types/graphql';

export const gateHasVentilation = (gateKind: GateKindInternal) => {
  return [
    GateKindInternal.OnePieceDoor,
    GateKindInternal.Sectional,
    GateKindInternal.RollUp,
    GateKindInternal.Unknown,
  ].includes(gateKind);
};

export const getGateMode = (supported: GateFeatureTypeInternal[], gateMode?: GateModeInternal | null) => {
  if (supported.length) {
    if (supported.includes(GateFeatureTypeInternal.StepByStep) && supported.includes(GateFeatureTypeInternal.SetDir)) {
      return GateModeInternal.RollUp;
    }

    if (supported.includes(GateFeatureTypeInternal.StepByStep)) return GateModeInternal.StepByStep;
    if (supported.includes(GateFeatureTypeInternal.SetDir)) return GateModeInternal.RollUp;
  }

  return gateMode || GateModeInternal.Unknown;
};

export const checkIfMicroventilationVisible = (ventilationSupported: boolean, calibrationNeeded: boolean) => {
  if (ventilationSupported && calibrationNeeded) return false;
  return ventilationSupported;
};
