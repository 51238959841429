import React from 'react';
import { ControlWrapper, LightSlider, SubmitButton } from '../..';

type DimChannelSettingsProps = {
  brightness: number;
  handleChangeBrightness: (val: number) => void;
  handleSubmit: () => void;
};

export const DimChannelSettings: React.FC<DimChannelSettingsProps> = ({
  brightness,
  handleChangeBrightness,
  handleSubmit,
}) => (
  <>
    <ControlWrapper>
      <LightSlider value={brightness} onChange={handleChangeBrightness} />
    </ControlWrapper>
    <SubmitButton onClick={handleSubmit} />
  </>
);
