import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { MeasuredBrightnessState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { CustomBackdrop, InputSelect, SubmitButton } from '../../../../../../../../components';
import { useExalusUtils } from '../../../../../../hooks/scenarios';
import { ComparisonMethodSelect } from '../../../../components/comparison-method-select';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useExalusComparisonContext } from '../../../../context/comparison';
import { useBrightnessScale } from '../../../../hooks/use-brightness-scale';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { BrightnessMethod, TriggerMethodType } from '../../../../../../../../types/actions';
import { useActionParameterOptions } from '../../../../utils/use-action-parameter-options';
import { BrightnessConditionChart } from './chart';
import './index.scss';

export const BrightnessParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { channels } = useExalusCreateActionContext();
  const [brightnessMethod, setBrightnessMethod] = useState<BrightnessMethod>(BrightnessMethod.State);
  const [brightness, setBrightness] = useState<string>('0');
  const [brightnessPercentage, setBrightnessPercentage] = useState<string>('0');
  const { getBrightnessStateByScale, brightnessOptions, linearScaleMedium } = useBrightnessScale();
  const { brightnessMethodOptions } = useActionParameterOptions();
  const { paramValue } = useExalusComparisonContext();
  const { data } = useExalusUtils();
  const {
    triggerMethod,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    changeableAtMeetCondition,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit({ supportedParam: SupportedArgumentTypes.ArgumentAsDeviceState });

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDeviceState;

    if (argument && onlyParametersEdit) {
      const state = argument.GetCheckDeviceState<MeasuredBrightnessState>();
      setBrightness(state.Brightness.toString());
      const scale = data?.parseLuxToLinearScale(state.Brightness);
      if (scale !== undefined) setBrightnessPercentage(Math.round(scale * 100).toString());
    }
  }, [seq, data, onlyParametersEdit]);

  const linearText = useMemo(() => {
    const scale = data?.parseLuxToLinearScale(parseInt(brightness || '0'));

    return scale !== undefined ? getBrightnessStateByScale(scale) : '';
  }, [brightness, data]);

  const handleSubmit = () => {
    if (channels.length && data) {
      const deltaValue = parseFloat(paramValue) / 100;

      const deviceArgument = createDeviceArgumentWithComparison(deltaValue);
      deviceArgument.Type = DeviceResponseType.MeasuredBrightness;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;
      const sensorState = new MeasuredBrightnessState();
      sensorState.Channel = channels[0].Number;
      sensorState.Brightness = parseInt(brightness);
      deviceArgument.SetCheckDeviceState<MeasuredBrightnessState>(sensorState);

      saveConditionParams(deviceArgument);
    }
  };

  const onChangeBrightnessMethod = (value: BrightnessMethod) => {
    setBrightnessMethod(value);
  };

  const onChangeBrightnessLinear = (value: string) => {
    const scale = linearScaleMedium[value];

    const luxValue = data?.parseLinearScaleToLux(scale);
    if (luxValue !== undefined) {
      setBrightness(luxValue.toString());
      const scale = data?.parseLuxToLinearScale(luxValue);
      if (scale !== undefined) setBrightnessPercentage(Math.round(scale * 100).toString());
    }
  };

  const handleChangeBrightness = (value: string) => {
    if (brightnessMethod === BrightnessMethod.Number) {
      setBrightness(value);

      const scale = data?.parseLuxToLinearScale(parseInt(value || '0'));
      if (scale !== undefined) setBrightnessPercentage(Math.round(scale * 100).toString());
    } else {
      setBrightnessPercentage(value);
      const scale = parseFloat(value) / 100;
      const luxValue = data?.parseLinearScaleToLux(scale);
      if (luxValue !== undefined) setBrightness(luxValue.toString());
    }
  };

  return (
    <div className="brightness-parameters">
      {changeableAtMeetCondition ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && changeableAtMeetCondition && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[
          ConditionsTypes.Equal,
          ConditionsTypes.NotEqueal,
          ConditionsTypes.BiggerThan,
          ConditionsTypes.BiggerThanOrEqual,
          ConditionsTypes.SmallerThan,
          ConditionsTypes.SmallerThanOrEqual,
        ]}
      />
      <ComparisonMethodSelect
        unit="%"
        maxPercent={20}
        valueParamLabel={`${t('action.create.conditions.sources.device.deviation')} (%)`}
      />
      <InputSelect
        options={brightnessMethodOptions}
        value={brightnessMethod}
        onChange={onChangeBrightnessMethod}
        label={t('action.create.conditions.brightnessMethod.label')}
      />
      {brightnessMethod !== BrightnessMethod.State ? (
        <InputString
          value={brightnessMethod === BrightnessMethod.Percent ? brightnessPercentage : brightness}
          setValue={handleChangeBrightness}
          label={`${t('action.create.conditions.sources.device.brightness')} (${
            brightnessMethod === BrightnessMethod.Percent ? '%' : 'lx'
          })`}
          inputType="number"
          min={0}
          percentValue={brightnessMethod === BrightnessMethod.Percent}
        />
      ) : (
        <InputSelect
          options={brightnessOptions}
          value={linearText}
          onChange={onChangeBrightnessLinear}
          label={t('action.create.conditions.sources.device.brightness')}
        />
      )}
      <BrightnessConditionChart current={linearText} />
      <SubmitButton onClick={handleSubmit} />
      {!data && <CustomBackdrop />}
    </div>
  );
};
