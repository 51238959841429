import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Dialog } from '../../../../components/dialog/base';
import { ExalusIntegrationForm } from '../../modules/integration-create/types';
import './index.scss';

type ControllerQrCodeProps = {
  exalusQrCode: ExalusIntegrationForm;
  setShow: Dispatch<SetStateAction<ExalusIntegrationForm>>;
};

export const ControllerQrCode: React.FC<ControllerQrCodeProps> = ({ exalusQrCode, setShow }) => {
  const { t } = useTranslation('common');
  const { t: ti } = useTranslation('installation');

  const handleHide = () => setShow({ serialNumber: '', pin: '' });

  return (
    <Dialog
      show={!!(exalusQrCode.serialNumber && exalusQrCode.pin)}
      setShow={handleHide}
      className="controller-qr-code"
      title={ti('sharingTitle')}
    >
      <div style={{ height: 'auto', margin: '0 auto', maxWidth: 200, width: '100%' }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={`https://app.tr7.pl/#connect=${exalusQrCode.serialNumber}:${exalusQrCode.pin}`}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div className="buttons-container">
        <button onClick={handleHide}>{t('buttons.close')}</button>
      </div>
    </Dialog>
  );
};
