import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInstallationContext } from '../../../../../context/installation';
import { UserPermissionType } from '../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../routes';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useTriggers } from '../../../hooks/triggers';
import * as storage from '../../../storage';
import { useAddDevice } from '../../device-add/hooks/use-add-device';
import { permissionEnumForActionTab } from '../utils';

export const useActionList = () => {
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(Number(storage.getItem('lastActiveActionTab')));
  const [triggerNumberExceededOpen, setTriggerNumberExceededOpen] = useState<boolean>(false);
  const { addDevice } = useAddDevice();
  const { channelList } = useDevicesAndChannelsContext();
  const { permissions } = useUserPermissions();
  const { selectedInstallation, installationList } = useInstallationContext();
  const { allTriggers, loading } = useTriggers();

  const channelListCount = channelList.length;

  const addButtonVisible =
    installationList.length !== 0 &&
    selectedInstallation !== undefined &&
    permissions[permissionEnumForActionTab[activeTabIndex]];

  const tipAvailable = [0, 1, 2].includes(activeTabIndex);

  const handleTabChange = (tabIndex: number) => {
    storage.setItem('lastActiveActionTab', String(tabIndex));
    setActiveTabIndex(tabIndex);
  };

  const handleAction = () => {
    const triggerNumberExceeded = activeTabIndex === 2 && allTriggers?.length >= 20;

    if (triggerNumberExceeded) {
      setTriggerNumberExceededOpen(true);
      return;
    }

    if (channelListCount === 0 && permissions[UserPermissionType.DeviceAdd]) addDevice();
    else {
      switch (activeTabIndex) {
        case 0:
          navigate(ROUTES.ActionCreateBasic());
          break;
        case 1:
          navigate(ROUTES.ActionCreate());
          break;
        case 2:
          navigate(ROUTES.TriggerCreate());
          break;
        default:
          break;
      }
    }
  };

  return {
    handleTabChange,
    handleAction,
    setTriggerNumberExceededOpen,
    activeTabIndex,
    triggerNumberExceededOpen,
    channelListCount,
    allTriggers,
    loading,
    addButtonVisible,
    tipAvailable,
  };
};
