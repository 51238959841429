import React, { useEffect, useState } from 'react';
import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceControlFeature } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { TurnOff, TurnOn } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, IconInitiate, InitiateButton, SubmitButton } from '../../../../../../../../components';
import { useExalusCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, OnOffStateTask } from '../../../../../../../../types/actions';

export const OnOffSettings: React.FC = () => {
  const { channels } = useExalusCreateActionContext();
  const [isOn, setIsOn] = useState<boolean>(false);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      setIsOn(((task.taskParams as DeviceTaskParams).state as OnOffStateTask).isOn);
    }
  }, [task]);

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task: TurnOn | TurnOff = isOn ? new TurnOn() : new TurnOff();
      task.DeviceGuid = channels[i].GetDevice().Guid;
      task.Channel = channels[i].Number;
      task.TaskType = isOn ? DeviceTaskType.TurnOn : DeviceTaskType.TurnOff;
      task.FeatureType = isOn ? DeviceControlFeature.TurnOn : DeviceControlFeature.TurnOff;

      finalizeDeviceTaskSubmit(task, channels[i], { isOn } as OnOffStateTask, i === channels.length - 1);
    }
  };

  return (
    <>
      <ControlWrapper>
        <InitiateButton isOn={isOn} onClickControl={() => setIsOn(!isOn)}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
