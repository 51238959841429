import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useBackdropContext } from '../../context/backdrop';
import {
  NotificationMarkAllAsDisplayedMutation,
  NotificationMarkAllAsDisplayedMutationVariables,
  NotificationMarkAllAsHiddenMutation,
  NotificationMarkAllAsHiddenMutationVariables,
  NotificationMarkAsDisplayedMutation,
  NotificationMarkAsDisplayedMutationVariables,
  NotificationMarkAsHiddenMutation,
  NotificationMarkAsHiddenMutationVariables,
} from '../../data-access/gql-types/graphql';
import {
  NOTIFICATION_MARK_ALL_AS_DISPLAYED,
  NOTIFICATION_MARK_ALL_AS_HIDDEN,
  NOTIFICATION_MARK_AS_DISPLAYED,
  NOTIFICATION_MARK_AS_HIDDEN,
} from '../../data-access/mutations/notification';
import { useNotificationsStore } from '../../store/notifications';
import { NotificationCallback } from '../../types';
import { toastError } from '../../utils/toast';

export const useNotificationsOperations = () => {
  const { t: tc } = useTranslation('common');
  const setCountOfUnreadNotifications = useNotificationsStore((s) => s.setCountOfUnreadNotifications);
  const decrementCountOfUnreadNotifications = useNotificationsStore((s) => s.decrementCountOfUnreadNotifications);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [notificationMarkAsDisplayed, { loading: markAsDisplayedLoading }] = useMutation<
    NotificationMarkAsDisplayedMutation,
    NotificationMarkAsDisplayedMutationVariables
  >(NOTIFICATION_MARK_AS_DISPLAYED);
  const [notificationMarkAsHidden, { loading: markAsHiddenLoading }] = useMutation<
    NotificationMarkAsHiddenMutation,
    NotificationMarkAsHiddenMutationVariables
  >(NOTIFICATION_MARK_AS_HIDDEN);
  const [notificationMarkAllAsDisplayed, { loading: markAllAsDisplayedLoading }] = useMutation<
    NotificationMarkAllAsDisplayedMutation,
    NotificationMarkAllAsDisplayedMutationVariables
  >(NOTIFICATION_MARK_ALL_AS_DISPLAYED);
  const [notificationMarkAllAsHidden, { loading: markAllAsHiddenLoading }] = useMutation<
    NotificationMarkAllAsHiddenMutation,
    NotificationMarkAllAsHiddenMutationVariables
  >(NOTIFICATION_MARK_ALL_AS_HIDDEN);

  const markAsDisplayed = (id: string, callback?: NotificationCallback) => {
    if (!id) return;

    notificationMarkAsDisplayed({
      variables: { correlationIds: [id] },
      onCompleted: (data) => {
        if (data.markAsDisplayed) {
          decrementCountOfUnreadNotifications();
          if (callback?.onSuccess) callback.onSuccess();
        } else {
          if (callback?.onError) callback.onError();
        }
      },
    });
  };

  const markAsHidden = (id: string, callback?: NotificationCallback) => {
    if (!id) return;

    notificationMarkAsHidden({
      variables: { correlationIds: [id] },
      onCompleted: (data) => {
        if (data.markAsHidden) {
          decrementCountOfUnreadNotifications();
          if (callback?.onSuccess) callback.onSuccess();
        } else {
          turnOffBackdrop();
          if (callback?.onError) callback.onError();
        }
      },
    });
  };

  const markAllDisplayed = (callback?: NotificationCallback) => {
    turnOnBackdrop();

    notificationMarkAllAsDisplayed({
      onCompleted: (data) => {
        if (data.markAllAsDisplayed) {
          setCountOfUnreadNotifications(0);
          turnOffBackdrop();
          if (callback?.onSuccess) callback.onSuccess();
        } else {
          turnOffBackdrop();
          if (callback?.onError) callback.onError();
        }
      },
      onError: () => {
        toastError({ content: tc('errors.somethingWentWrong') });
        turnOffBackdrop();
      },
    });
  };

  const markAllHidden = (callback?: NotificationCallback) => {
    turnOnBackdrop();

    notificationMarkAllAsHidden({
      onCompleted: (data) => {
        if (data.markAllAsHidden) {
          setCountOfUnreadNotifications(0);
          turnOffBackdrop();
          if (callback?.onSuccess) callback.onSuccess();
        } else {
          turnOffBackdrop();
          if (callback?.onError) callback.onError();
        }
      },
      onError: () => {
        toastError({ content: tc('errors.somethingWentWrong') });
        turnOffBackdrop();
      },
    });
  };

  return {
    markAsDisplayed,
    markAsHidden,
    markAllDisplayed,
    markAllHidden,
    markAsDisplayedLoading,
    markAsHiddenLoading,
    markAllAsDisplayedLoading,
    markAllAsHiddenLoading,
  };
};
