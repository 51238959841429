import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ROUTES } from '../../../../../routes';
import { ActionType, TaskVariant } from '../../../../../types/actions';
import { canChannelDo } from '../../../utils';
import { useExalusCreateActionContext } from '../context';

export const useActionTaskVariants = () => {
  const navigate = useNavigate();
  const { actionType, taskVariant, setTaskVariant } = useExalusCreateActionContext();

  useEffect(() => {
    if (actionType === ActionType.Undefined) navigate(ROUTES.ActionList(), { replace: true });
  }, []);

  const selectTaskVariant = (taskVariant: TaskVariant) => {
    setTaskVariant(taskVariant);
    navigate(ROUTES.DeviceVariants());
  };

  const getChannelsByVariant = (channels: IDeviceChannel[]) => {
    const all = [];

    switch (taskVariant) {
      case TaskVariant.OnOff: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.TurnOn, DeviceTaskType.TurnOff]));
      }
      case TaskVariant.Dim: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.SetLightBrightness]));
      }
      case TaskVariant.SetMicrowave: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.SetBlindMicroventilation]));
      }
      case TaskVariant.SetRGBW: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.LightRGBW]));
      }
      case TaskVariant.SetBlind: {
        return channels.filter(
          (x) =>
            canChannelDo(x, [DeviceTaskType.SetBlindPosition]) ||
            canChannelDo(x, [DeviceTaskType.SetBlindPositionSimple]),
        );
      }
      case TaskVariant.SetFacade: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.SetFacadePosition]));
      }

      default: {
        return all;
      }
    }
  };

  return {
    selectTaskVariant,
    getChannelsByVariant,
  };
};
