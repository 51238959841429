import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { environment } from '../../environment';
import { Tokens } from '../../types/auth';

const clientId = environment.KEYCLOAK_CLIENT_ID || '';
const realm = environment.KEYCLOAK_REALM || '';

export const keycloakInstance = new Keycloak({ url: `${environment.KEYCLOAK_URL}`, clientId, realm });

export const getKeycloakInitOptions = (): KeycloakInitOptions => {
  const tokenObject = JSON.parse(String(window.localStorage.getItem('tokens')));

  return {
    pkceMethod: 'S256',
    onLoad: 'check-sso',
    checkLoginIframe: false,
    ...(tokenObject?.token ? { token: tokenObject.token } : {}),
    ...(tokenObject?.idToken ? { idToken: tokenObject.idToken } : {}),
    ...(tokenObject?.refreshToken ? { refreshToken: tokenObject.refreshToken } : {}),
  };
};

export const setTokens = ({ token, idToken, refreshToken }: Tokens) => {
  localStorage.removeItem('local-app');

  window.localStorage.setItem(
    'tokens',
    JSON.stringify({
      token,
      idToken,
      refreshToken,
    }),
  );
};
