import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import c from 'classnames';
import { StatusIcon, SubmitButton } from '../../../../components';
import HeaderWithHelp from '../../../../components/header-with-help';
import { useRouter } from '../../../../hooks/router/use-router';
import { ROUTES } from '../../../../routes';
import { StatusTypeEnum } from '../../../../types';
import { isLocalApp } from '../../../../utils/helpers/local-app';
import { getParameterByName } from '../../../../utils/location';
import { StateCreateInstallationType, useCreateInstallationContext } from '../../../../modules/installation-create/context';
import {
  SET_EXALUS_CREDENTIALS,
  SET_HEX_COLOR,
  SET_ICON_NAME,
  SET_INSTALLATION_NAME,
  SET_INTEGRATION_TYPE,
  SET_LOCATION_LAT,
  SET_LOCATION_LONG,
  SET_TIME_ZONE,
} from '../../../../modules/installation-create/context/constants';
import { ControllerQrReader } from './components/controller-qr-reader';
import { FormError } from './components/form-error';
import { Pin } from './components/pin';
import { QrButton } from './components/qr-button';
import { SerialNumber } from './components/serial-number';
import { useIntegrationCreate } from './hooks/use-integration-create';
import './index.scss';

const ExalusIntegrationCreate: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { dispatch } = useCreateInstallationContext();
  const { isBackAvailable, handleReplaceRedirect } = useRouter();
  const { setQrPageOpen, qrPageOpen, form, onSubmit, handleScan, showError } = useIntegrationCreate();

  useEffect(() => {
    const urlState = getParameterByName('state');

    if (urlState) {
      const stateParams = JSON.parse(decodeURIComponent(urlState)) as StateCreateInstallationType;

      if (!stateParams.installationName) {
        navigate(ROUTES.InstallationCreate(), { replace: true });
      } else {
        dispatch({ type: SET_INSTALLATION_NAME, payload: stateParams.installationName });
        dispatch({ type: SET_HEX_COLOR, payload: stateParams.hexColor });
        dispatch({ type: SET_ICON_NAME, payload: stateParams.iconName });
        dispatch({ type: SET_TIME_ZONE, payload: stateParams.timeZone });
        dispatch({ type: SET_INTEGRATION_TYPE, payload: stateParams.integrationType });
        dispatch({ type: SET_EXALUS_CREDENTIALS, payload: stateParams.exalusCredentials });

        if (stateParams.locationLat && stateParams.locationLong) {
          dispatch({ type: SET_LOCATION_LAT, payload: stateParams.locationLat });
          dispatch({ type: SET_LOCATION_LONG, payload: stateParams.locationLong });
        }

        if (stateParams.exalusCredentials.serialNumber && stateParams.exalusCredentials.pin) {
          form.setValue('serialNumber', stateParams.exalusCredentials.serialNumber);
          form.setValue('pin', stateParams.exalusCredentials.pin);
        }
      }
    } else {
      navigate(ROUTES.InstallationCreate(), { replace: true });
    }
  }, []);

  const handleBack = () => {
    if (isBackAvailable) navigate(-2);
    else handleReplaceRedirect(ROUTES.InstallationCreate());
  };

  const { serialNumber, pin } = form.watch();

  return (
    <>
      {!qrPageOpen ? (
        <HeaderWithHelp
          kind="main"
          name="controller"
          headerTitle={t('exalus.controller.header')}
          helpTitle={t('exalus.controller.header')}
          helpContent={t('exalus.controller.helpContent')}
          handleClose={handleBack}
          noBackButton={isLocalApp}
        >
          <div className="integration-create">
            <div className="center-content">
              <div className="integration-create__icon max-width-desktop">
                <StatusIcon type={StatusTypeEnum.INTEGRATION_CONTROLLER} />
              </div>
            </div>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={c('integration_form')}>
                <SerialNumber serialNumber={serialNumber} />
                <Pin pin={pin} />
                {showError ? <FormError /> : null}
                <SubmitButton>{tc('buttons.next')}</SubmitButton>
              </form>
            </FormProvider>
            <div className="center-content">
              <div className="max-width-desktop">
                <div className="integration-create__label m-b-24">{t('exalus.or')}</div>
                <QrButton setQrPageOpen={setQrPageOpen} />
              </div>
            </div>
          </div>
        </HeaderWithHelp>
      ) : (
        <ControllerQrReader setQrPageOpen={setQrPageOpen} handleScan={handleScan} />
      )}
    </>
  );
};

export default ExalusIntegrationCreate;
