import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import {
  useGateSetDirection,
  useGateSetPosition,
  useGateStepByStep,
  useGateTilt,
} from '../../api/gate/gate.hooks';
import { SetQuickControlMutation, SetQuickControlMutationVariables } from '../../../../data-access/gql-types/graphql';
import { SET_QUICK_CONTROL } from '../../../../data-access/mutations/channels';
import { useMutationErrors } from '../../../../hooks';
import { MutationErrorType } from '../../../../types';
import { getChannelActive } from '../../utils/channels/helpers/channel-box';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelGateStateType, ChannelGateType, ChannelType, GateDirection } from '../../types/channel';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';

export const useGate = (channel: ChannelType) => {
  const { t: td } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleErrors } = useMutationErrors(MutationErrorType.Channel);
  const { setChannelList } = useDevicesAndChannelsContext();
  const setGateDirection = useGateSetDirection();
  const setGatePosition = useGateSetPosition();
  const setGateTilt = useGateTilt();
  const stepByStepMutate = useGateStepByStep();
  const { id: channelId, data, deviceId } = channel;
  const state = useChannelsStateStore((s) => s.channelState[channelId]) as ChannelGateStateType | null;
  const gateActive = getChannelActive(data.type, state);
  const [setQuickControl] = useMutation<SetQuickControlMutation, SetQuickControlMutationVariables>(SET_QUICK_CONTROL);

  const handleAction = (value: string | number) => {
    if (typeof value === 'string') {
      setGateDirection.mutate({
        channelId,
        deviceId,
        direction: GateDirection[value],
      });
    } else {
      setGatePosition.mutate({ channelId, deviceId, position: value });
    }
  };

  const handleVentilation = () => setGateTilt.mutate({ channelId, deviceId });

  const handleStepByStep = () => stepByStepMutate.mutate({ channelId, deviceId });

  const handleQuickControl = (value: boolean) => {
    setQuickControl({
      variables: {
        input: {
          channelId: channel.id,
          value,
        },
      },
      onCompleted: (data) => {
        if (data.setQuickControl.result?.ok) {
          setChannelList((prev) => {
            const tempList = cloneDeep(prev);
            const index = tempList.findIndex((x) => x.id === channel.id);

            if (index !== -1) {
              (tempList[index].data as ChannelGateType).isQuickControl = value;
            }

            return tempList;
          });

          toastSuccess({ content: td('quickControlSuccess') });
        } else {
          handleErrors(data.setQuickControl.errors || []);
        }
      },
      onError: () => {
        toastError({ content: tc('errors.somethingWentWrong') });
      },
    });
  };

  return {
    gateActive,
    handleAction,
    handleVentilation,
    handleStepByStep,
    handleQuickControl,
  };
};
