import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, Tabs } from '../../../../../../../../components';
import { ChannelTypeInternal, GateModeInternal } from '../../../../../../../../data-access/gql-types/graphql';
import { ChannelBoxSimple } from '../../../../../../components';
import { useDevicesAndChannels } from '../../../../../../hooks';
import { ChannelGateType, ChannelType } from '../../../../../../types';
import { ChannelGroupItem } from '../../../../../../types/devices-and-channels';
import { useTriggerFormContext } from '../../../../context';
import { ChosenConditionType } from '../../../../enums';

type ChannelListProps = {
  setConditionType: (type: ChosenConditionType) => void;
  setSelectedChannel: (value: string | null) => void;
};

export const ChannelList: React.FC<ChannelListProps> = ({ setConditionType, setSelectedChannel }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { channelGroups } = useDevicesAndChannels({
    channelTypes: [
      ChannelTypeInternal.Switch,
      ChannelTypeInternal.Blind,
      ChannelTypeInternal.Light,
      ChannelTypeInternal.Gate,
    ],
  });
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { stateConditions, channels } = useTriggerFormContext();

  const onNavHeaderClick = () => {
    setConditionType(ChosenConditionType.NONE);
  };

  return (
    <Page
      header={
        <>
          <NavHeader onClick={onNavHeaderClick} />
          <Header
            title={t('trigger.selectChannel')}
            titleClassName={'m-b-8'}
            subtitle={t('trigger.selectChannelSubtitle')}
            column
          />
        </>
      }
    >
      <Tabs
        tabList={[{ label: tc('all') }, ...channelGroups]}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        tabsType="pills"
      />

      <ul className="m-t-24 page-list">
        {channelGroups.map((item: ChannelGroupItem, typeIndex: number) => {
          if (activeTabIndex !== 0 && activeTabIndex - 1 !== typeIndex) {
            return null;
          }

          return (
            <li key={typeIndex} className="page-list__item">
              <Header title={item.label} subheader />
              <ul className="page-list__items-list items-list">
                {item.channels
                  .filter((x) => {
                    if (x.data.type === ChannelTypeInternal.Gate) {
                      return (x.data as ChannelGateType).gateMode !== GateModeInternal.StepByStep;
                    }

                    return true;
                  })
                  .map((ch: ChannelType) => {
                    const isConditionSelected = stateConditions.find((stateCondition) => stateCondition.id === ch.id);
                    const isChannelSelected = channels.find((channel) => channel.id === ch.id && channel.value);

                    return (
                      <li
                        key={ch.id}
                        className="items-list__item"
                        onClick={() => {
                          if (!isConditionSelected && !isChannelSelected) {
                            setSelectedChannel(ch.id);
                          }
                        }}
                      >
                        <ChannelBoxSimple channel={ch} isSelected={!!isConditionSelected || !!isChannelSelected} />
                      </li>
                    );
                  })}
              </ul>
            </li>
          );
        })}
      </ul>
    </Page>
  );
};
