import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { PHASES_COLORS } from '../../../../../../../const';
import { ROUTES } from '../../../../../../../routes';
import { SelectedPhase } from '../../../../../../../types';

export const useMeterPhases = (channel?: IDeviceChannel) => {
  const navigate = useNavigate();
  const { t } = useTranslation('channel-details');

  const mappedPhases: SelectedPhase[] = useMemo(() => {
    if (channel) {
      return channel?.GetDevice().Channels?.map((channel) => {
        const label =
          'PowerMeterPhasesSummary' in channel.Configurations ? t('sumOfPhases') : `${t('phase')} ${channel.Number}`;
        return {
          value: channel.Number,
          label,
          color: PHASES_COLORS[channel.Number - 1],
        };
      });
    }

    return [];
  }, [channel?.GetDevice().Channels.length]);

  const handlePhaseChange = (val: number) => {
    const channelId = channel?.GetDevice().Channels.find((x) => x.Number === val)?.ChannelId;

    if (channelId) {
      navigate(ROUTES.ChannelDetails(channelId), { replace: true });
    }
  };

  return {
    mappedPhases,
    handlePhaseChange,
  };
};
