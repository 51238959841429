import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BlindActionEnum, FacadeActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { LockType, NotifyType } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import ActionConditionItemDetails from '../../../../../components/conditions/condition-item-details';
import { useExalusChannels } from '../../../hooks';
import { useExalusSunTimes } from '../../../hooks/scenarios';
import { useExalusCreateActionContext } from '../../action-create/context';
import { useNotifyTask } from '../../action-create/hooks/use-notify-task';
import {
  BlindStateTask,
  DelayTaskParams,
  DeviceTaskParams,
  DimStateTask,
  FacadeStateTask,
  LockTaskParams,
  MicroventilationStateTask,
  NotifyTaskParams,
  OnOffStateTask,
  RGBWStateTask,
  Task,
  TaskVariant,
} from '../../../../../types/actions';
import { conditionIcon, conditionName } from '../../action-create/utils/action-conditions';
import { actionTaskVariants, actionTasks } from '../../action-create/utils/action-tasks';
import { ConditionDetails } from '../types';
import { useGetConditionParams } from './get-condition-params';

export const useActionDetailsPresentation = () => {
  const { t } = useTranslation('action');
  const { conditionList, taskList } = useExalusCreateActionContext();
  const { getConditionParams } = useGetConditionParams();
  const { data: sunTimes } = useExalusSunTimes();
  const { data: channels } = useExalusChannels();
  const { getMessageParsed } = useNotifyTask();

  const getTaskState = (task: Task) => {
    switch (task.taskType) {
      case SceneTaskType.DelayTask: {
        return (task.taskParams as DelayTaskParams).delay;
      }
      case SceneTaskType.LockExecution: {
        if ((task.taskParams as LockTaskParams).lockType === LockType.Timeout) {
          return (task.taskParams as LockTaskParams).lockStart;
        }

        return `${(task.taskParams as LockTaskParams).lockStart} - ${(task.taskParams as LockTaskParams).lockEnd}`;
      }
      case SceneTaskType.NotifyTask: {
        const params = task.taskParams as NotifyTaskParams;

        return `${t(`action.create.tasks.states.${NotifyType[params.notifyType]}`)}: ${getMessageParsed(
          params.message,
        )}\r\n ${t('action.create.tasks.states.users')}: ${
          params.notificationClients.length ? params.notificationClients.join(', ') : '-'
        }`;
      }
      case SceneTaskType.DeviceTask: {
        const deviceParams = task.taskParams as DeviceTaskParams;

        switch (deviceParams.variant) {
          case TaskVariant.OnOff: {
            return t(`action.create.tasks.states.${(deviceParams.state as OnOffStateTask).isOn ? 'on' : 'off'}`);
          }
          case TaskVariant.Dim: {
            return `${t('action.create.tasks.states.brightness')} ${(deviceParams.state as DimStateTask).brightness}%`;
          }
          case TaskVariant.SetMicrowave: {
            return `${t('action.create.tasks.states.microventilation')} ${
              (deviceParams.state as MicroventilationStateTask).position
            }%`;
          }
          case TaskVariant.SetRGBW: {
            const paramW = (deviceParams.state as RGBWStateTask).W;

            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{`${t('action.create.tasks.states.color')}: `}</span>
                <div
                  style={{
                    marginLeft: 6,
                    marginRight: 6,
                    height: 12,
                    width: 12,
                    borderRadius: '50%',
                    border: '1px solid #cbcbd1',
                    background: `rgb(${(deviceParams.state as RGBWStateTask).R},${
                      (deviceParams.state as RGBWStateTask).G
                    },${(deviceParams.state as RGBWStateTask).B})`,
                  }}
                />
                <span>{`${t('action.create.tasks.states.brightnessColor')}: ${
                  (deviceParams.state as RGBWStateTask).Brightness
                }%`}</span>
                {paramW && (
                  <span>{`, ${t('action.create.tasks.states.wLed')}: ${
                    (deviceParams.state as RGBWStateTask).W
                  }%`}</span>
                )}
              </div>
            );
          }
          case TaskVariant.SetBlind: {
            return (deviceParams.state as BlindStateTask<BlindActionEnum>).action === BlindActionEnum.Percentage
              ? `${(deviceParams.state as BlindStateTask<BlindActionEnum>).position}%`
              : `${t(
                  `action.create.tasks.states.${BlindActionEnum[
                    (deviceParams.state as BlindStateTask<BlindActionEnum>).action
                  ]?.toLowerCase()}`,
                )}`;
          }
          case TaskVariant.SetFacade: {
            if ((deviceParams.state as FacadeStateTask).action === FacadeActionEnum.Percentage) {
              return `${t('action.create.tasks.states.position')} ${(deviceParams.state as FacadeStateTask).position}%`;
            } else if ((deviceParams.state as FacadeStateTask).action === FacadeActionEnum.TiltAndPercentage) {
              return `${t('action.create.tasks.states.position')} ${
                (deviceParams.state as FacadeStateTask).position
              }%, ${t('action.create.tasks.states.tilt')} ${(deviceParams.state as FacadeStateTask).tilt}%`;
            }

            return `${t(
              `action.create.tasks.states.${FacadeActionEnum[
                (deviceParams.state as FacadeStateTask).action
              ]?.toLowerCase()}`,
            )}`;
          }
          default: {
            return '';
          }
        }
      }
    }
  };

  const detailsConditionList = useMemo(
    () =>
      conditionList.map((c) => {
        const nameCondition = conditionName[c.LeftArgumentType];
        const conditionParams: ConditionDetails | null = getConditionParams(c, channels);

        if (conditionParams) {
          return (
            <ActionConditionItemDetails
              key={c.Guid}
              icon={conditionParams.icon || conditionIcon[c.LeftArgumentType]}
              title={conditionParams.title ? conditionParams.title : t(nameCondition)}
              repeatOption={conditionParams.description}
              {...(conditionParams.secondLine
                ? { secondLine: <span className="action-condition__text">{conditionParams.secondLine}</span> }
                : {})}
            />
          );
        }

        return null;
      }),
    [conditionList, sunTimes, channels],
  );

  const detailsTaskList = useMemo(
    () =>
      taskList.map((ts, i) => {
        const taskState = getTaskState(ts);

        switch (ts.taskType) {
          case SceneTaskType.DelayTask: {
            const icon = actionTasks.find((x) => x.type === ts.taskType)?.icon;

            return (
              <ActionConditionItemDetails
                key={i}
                icon={icon}
                title={t('action.create.tasks.delay')}
                repeatOption={taskState}
              />
            );
          }
          case SceneTaskType.LockExecution: {
            const icon = actionTasks.find((x) => x.type === ts.taskType)?.icon;

            return (
              <ActionConditionItemDetails
                key={i}
                icon={icon}
                title={t('action.create.tasks.lockExecution')}
                repeatOption={taskState}
              />
            );
          }
          case SceneTaskType.NotifyTask: {
            const icon = actionTasks.find((x) => x.type === ts.taskType)?.icon;

            return (
              <ActionConditionItemDetails
                key={i}
                icon={icon}
                title={t('action.create.tasks.notify')}
                repeatOption={taskState}
              />
            );
          }
          case SceneTaskType.DeviceTask: {
            const taskVariant = actionTaskVariants.find(
              (x) => x.variant === (ts.taskParams as DeviceTaskParams).variant,
            );
            if (taskVariant) {
              return (
                <ActionConditionItemDetails
                  key={i}
                  icon={taskVariant.icon}
                  title={(ts.taskParams as DeviceTaskParams).channel.Name}
                  repeatOption={taskState}
                />
              );
            }

            return null;
          }
          default: {
            return null;
          }
        }
      }),
    [taskList],
  );

  return { detailsConditionList, detailsTaskList, getTaskState };
};
