import {
  ChannelTypeInternal,
  DependantChannelResponse,
  DeviceConnectionState,
  GateChannelAlertConfigResponse,
  GateChannelCalibrateStateResponse,
  GateChannelStateResponse,
  GateFeatureTypeInternal,
  GateKindInternal,
  GateLockStateInternal,
  GateModeInternal,
  GateResponseOfGateHistory,
  GateStateDirectionInternal,
  NotificationSenderType,
} from '../../../../data-access/gql-types/graphql';

export type ChannelGateType = {
  type: ChannelTypeInternal.Gate;
  gateMode: GateModeInternal;
  gateKind: GateKindInternal;
  sbsSignalTimeInMs: number | null;
  partialSbsTimeInMs: number | null;
  tiltTimeInMs: number | null;
  alertConfig: GateChannelAlertConfigResponse | null;
  supportedGateFeatures: GateFeatureTypeInternal[];
  isQuickControl: boolean;
  isPartialControlModeVisible: boolean;
  onEventAction: NotificationSenderType | null;
  isDailyModeVisible: boolean;
  dependantChannels: DependantChannelResponse[];
  history?: GateResponseOfGateHistory;
};

export type ChannelGateStateType = {
  movingParams: {
    predictedTimeInMs: number | null;
    targetPosition: number | null;
  };
  direction: GateStateDirectionInternal;
  position?: number;
  partialControlModeStatus: boolean;
  dailyModeStatus?: boolean | null;
  lockStatus?: GateLockStateInternal | null;
  usesCounter: number;
  grantedUses?: number;
  calibrateState?: GateChannelCalibrateStateResponse;
  deviceConnectionState?: DeviceConnectionState;
};

export type GateChannelStateResponseCustom = Omit<GateChannelStateResponse, 'direction'> & {
  gateDirection: GateStateDirectionInternal;
};

export enum GateDirection {
  unknown = 0,
  stop = 1,
  open = 2,
  close = 3,
}

export enum GateKindParam {
  Channel,
  Action,
}
