import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ControllerData, DataMigrationService } from 'lavva.exalushome/build/js/Services/DataMigrationService';
import { uniqBy } from 'lodash';
import * as uuid from 'uuid';
import { useMutation } from '@apollo/client';
import { DEFAULT_TIME_ZONE, INSTALLATION_COLOR_LIST } from '../../../../const';
import {
  AddMultipleInstallationsAfterMigrationMutation,
  AddMultipleInstallationsAfterMigrationMutationVariables,
  ExalusInstallationPayload,
  InstallationAccessType,
  IntegrationType,
  UserInstallation,
} from '../../../../data-access/gql-types/graphql';
import { ADD_MULTIPLE_INSTALLATIONS_AFTER_MIGRATION } from '../../../../data-access/mutations/installations';
import { ROUTES } from '../../../../routes';
import { InstallationIconTypeEnum } from '../../../../types';
import * as storage from '../../../lavva/storage';
import { InstallationMigrationPrepareParams } from '../../types/migration';
import { saveExalusCredentials } from '../../utils';

export const useExalusMigrationData = () => {
  const { i18n } = useTranslation('common');
  const [addMultipleInstallationsAfterMigration] = useMutation<
    AddMultipleInstallationsAfterMigrationMutation,
    AddMultipleInstallationsAfterMigrationMutationVariables
  >(ADD_MULTIPLE_INSTALLATIONS_AFTER_MIGRATION);

  const prepareInstallationFromMigrationData = ({ data, order, colorIndex }: InstallationMigrationPrepareParams) => {
    const installationId = uuid.v4();

    return {
      accessType: InstallationAccessType.Owner,
      createdAt: new Date(),
      externalPayload: {
        installationId,
        pin: data.PIN,
        serialNumber: data.SerialId,
      },
      hexColor: INSTALLATION_COLOR_LIST[colorIndex],
      iconName: InstallationIconTypeEnum.INSTALLATION_0,
      installationId,
      integrationType: IntegrationType.Exalus,
      isSelected: false,
      name: data.Name || data.SerialId,
      order,
      partialAccess: false,
      userId: '',
    } as UserInstallation;
  };

  const checkIfExalusMigrationAvailableLocal = async () => {
    try {
      const migration = Api.Get<DataMigrationService>(DataMigrationService.ServiceName);
      const migrationAvailable: boolean = await migration.IsMigrationDataAvailableAsync();

      if (migrationAvailable) {
        const migrationControllerData: ControllerData[] | null = await migration.GetControllersDataAsync();

        if (migrationControllerData?.length) {
          let colorIndex = 0;
          const localInstallations = storage.getLocalItem('installationList') || [];

          const installationList: UserInstallation[] = migrationControllerData
            .filter(
              (x) =>
                !localInstallations.find(
                  (y) => (y.externalPayload as ExalusInstallationPayload).serialNumber === x.SerialId,
                ),
            )
            .map((data, index) => {
              const installation = prepareInstallationFromMigrationData({
                data,
                colorIndex,
                order: localInstallations.length + index,
              });
              colorIndex = colorIndex < INSTALLATION_COLOR_LIST.length - 1 ? colorIndex + 1 : 0;
              return installation;
            });

          if (installationList.length) {
            const migrationLanguageCode: string | null = await migration.GetLanguageCodeAsync();

            if (migrationLanguageCode) {
              const codes = migrationLanguageCode.split('-');
              if (codes.length) i18n.changeLanguage(codes[0].toLowerCase());
            }
          }

          storage.setLocalItem(
            'installationList',
            uniqBy(
              [...localInstallations, ...installationList],
              (x) => (x.externalPayload as ExalusInstallationPayload).serialNumber,
            ),
          );

          installationList.map((installation) => {
            const foundData = migrationControllerData.find(
              (migration) =>
                installation.name === migration.Name &&
                (installation.externalPayload as ExalusInstallationPayload).pin === migration.PIN &&
                (installation.externalPayload as ExalusInstallationPayload).serialNumber === migration.SerialId,
            );

            if (foundData) {
              saveExalusCredentials({
                id: installation.installationId,
                email: foundData.AutoLoginUser.Email,
                password: foundData.AutoLoginUser.Password,
                serialNumber: foundData.SerialId,
                pin: foundData.PIN,
              });
            }
          });

          if (!localInstallations.length) {
            const autoLogged = migrationControllerData.find((x) => x.AutoLogin);

            if (autoLogged) {
              const autoLoggedInstallation = installationList.find(
                (x) =>
                  x.name === autoLogged?.Name &&
                  (x.externalPayload as ExalusInstallationPayload).pin === autoLogged.PIN &&
                  (x.externalPayload as ExalusInstallationPayload).serialNumber === autoLogged.SerialId,
              );

              if (autoLoggedInstallation) {
                localStorage.setItem('selectedInstallationId', autoLoggedInstallation.installationId);
              }
            }
          }
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const checkIfExalusMigrationAvailable = async (currentInstallationList: UserInstallation[]) => {
    try {
      const migration = Api.Get<DataMigrationService>(DataMigrationService.ServiceName);
      const migrationAvailable: boolean = await migration.IsMigrationDataAvailableAsync();

      if (migrationAvailable) {
        const migrationControllerData: ControllerData[] | null = await migration.GetControllersDataAsync();

        if (migrationControllerData?.length) {
          let colorIndex = 0;

          const installationList: UserInstallation[] = migrationControllerData
            .filter(
              (x) =>
                !currentInstallationList
                  .filter((x) => x.integrationType === IntegrationType.Exalus)
                  .find((y) => (y.externalPayload as ExalusInstallationPayload).serialNumber === x.SerialId),
            )
            .map((data, index) => {
              const installation = prepareInstallationFromMigrationData({
                data,
                colorIndex,
                order: currentInstallationList.length
                  ? currentInstallationList[currentInstallationList.length - 1].order + index + 1
                  : currentInstallationList.length + index,
              });
              colorIndex = colorIndex < INSTALLATION_COLOR_LIST.length - 1 ? colorIndex + 1 : 0;
              return installation;
            });

          if (installationList.length) {
            const migrationLanguageCode: string | null = await migration.GetLanguageCodeAsync();

            if (migrationLanguageCode) {
              const codes = migrationLanguageCode.split('-');
              if (codes.length) i18n.changeLanguage(codes[0].toLowerCase());
            }
          }

          installationList.map((installation) => {
            const foundData = migrationControllerData.find(
              (migration) =>
                installation.name === migration.Name &&
                (installation.externalPayload as ExalusInstallationPayload).pin === migration.PIN &&
                (installation.externalPayload as ExalusInstallationPayload).serialNumber === migration.SerialId,
            );

            if (foundData) {
              saveExalusCredentials({
                id: installation.installationId,
                email: foundData.AutoLoginUser.Email,
                password: foundData.AutoLoginUser.Password,
                serialNumber: foundData.SerialId,
                pin: foundData.PIN,
              });
            }
          });

          if (!currentInstallationList.length) {
            const autoLogged = migrationControllerData.find((x) => x.AutoLogin);

            if (autoLogged) {
              const autoLoggedInstallation = installationList.find(
                (x) =>
                  x.name === autoLogged?.Name &&
                  (x.externalPayload as ExalusInstallationPayload).pin === autoLogged.PIN &&
                  (x.externalPayload as ExalusInstallationPayload).serialNumber === autoLogged.SerialId,
              );

              if (autoLoggedInstallation) {
                localStorage.setItem('selectedInstallationId', autoLoggedInstallation.installationId);
              }
            }
          }

          if (installationList.length) {
            try {
              const result = await addMultipleInstallationsAfterMigration({
                variables: {
                  inputs: installationList.map((installation) => ({
                    installationId: installation.installationId,
                    iconName: installation.iconName,
                    hexColor: installation.hexColor,
                    name: installation.name,
                    timeZone: DEFAULT_TIME_ZONE,
                    integrationType: installation.integrationType,
                    externalInstallationPayload: {
                      exalusInstallationPayload: {
                        serialNumber: (installation.externalPayload as ExalusInstallationPayload).serialNumber,
                        pin: (installation.externalPayload as ExalusInstallationPayload).pin,
                      },
                    },
                  })),
                },
              });

              if (!currentInstallationList.length) {
                window.location.href = ROUTES.Installation();
              }

              return result.data
                ? [
                    ...currentInstallationList,
                    ...(result.data.addMultipleInstallationsAfterMigration as UserInstallation[]),
                  ]
                : [...currentInstallationList];
            } catch {
              return [...currentInstallationList];
            }
          }
        }
      }

      return [...currentInstallationList];
    } catch (error) {
      console.log('Error', error);
      return [...currentInstallationList];
    }
  };

  return {
    checkIfExalusMigrationAvailableLocal,
    checkIfExalusMigrationAvailable,
  };
};

export const useExalusMigrationWhenOnlineUnauthorized = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const checkOnlineMigrationWhenUnauthorized = async () => {
    try {
      const migration = Api.Get<DataMigrationService>(DataMigrationService.ServiceName);
      const migrationAvailable: boolean = await migration.IsMigrationDataAvailableAsync();
      const noMigPage = new URLSearchParams(search).get('noMigPage');

      if (migrationAvailable && !noMigPage) {
        const migrationControllerData: ControllerData[] | null = await migration.GetControllersDataAsync();

        if (migrationControllerData?.length) {
          navigate(ROUTES.Migration(), { replace: true });
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  return {
    checkOnlineMigrationWhenUnauthorized,
  };
};
