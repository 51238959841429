import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetActivationTimeDialog } from '../set-activation-time-dialog';
import { TimeActivationButton } from '../time-activation-button';

type TimeActivationProps = {
  value: number;
  index: number;
  onBtnClick: (value: number) => void;
  onTimeChange?: (value: number, index: number) => void;
  active?: number | null;
};

export const TimeActivation: React.FC<TimeActivationProps> = ({ value, index, onTimeChange, onBtnClick, active }) => {
  const { t } = useTranslation('channel-details');
  const [showActivationTimeDialog, setShowActivationTimeDialog] = useState<boolean>(false);

  const onHold = () => setShowActivationTimeDialog(true);

  return (
    <>
      <TimeActivationButton
        value={value}
        {...(onTimeChange && { onHold })}
        onClick={() => onBtnClick(value)}
        active={active === value}
      />
      {onTimeChange && (
        <SetActivationTimeDialog
          open={showActivationTimeDialog}
          setOpen={setShowActivationTimeDialog}
          title={t('timeActivationDialog')}
          onSave={(value) => onTimeChange(value, index)}
          miliseconds={value}
        />
      )}
    </>
  );
};
