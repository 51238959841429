import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CustomBackdrop, EmptyStateBox, IconWarning } from '../../../../../../components';
import { useInstallationContext } from '../../../../../../context/installation';
import {
  MeasuredBy,
  MeasurementChannelKind,
  MeterCommonMeasurementsQueryVariables,
  Query,
  VectorParameterTypeInternal,
} from '../../../../../../data-access/gql-types/graphql';
import { METER_COMMON_MEASUREMENTS } from '../../../../../../data-access/queries/meter';
import { useApi } from '../../../../hooks';
import { ChannelType } from '../../../../types';
import { MeasurementItem, MeasurementGroup } from '../../measurements/components/measurement-item';
import { measurementParameters } from '../../measurements/utils';

type ChannelDetailsMeasurementsProps = {
  channel: ChannelType;
  measurementChannel: MeasuredBy;
};

export const ChannelDetailsMeasurements: React.FC<ChannelDetailsMeasurementsProps> = ({
  channel,
  measurementChannel,
}) => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const { convertNumberToMeasurement } = useApi();
  const { data, loading } = useQuery<Query, MeterCommonMeasurementsQueryVariables>(METER_COMMON_MEASUREMENTS, {
    variables: {
      installationId: selectedInstallationId,
      input: {
        channelId: measurementChannel.meterChannelId,
        deviceId: channel.deviceId, // TODO: In future it will be measurementChannel.meterDeviceId for external meters
        indices: [measurementChannel.phase],
        kind: MeasurementChannelKind.Meter,
      },
    },
    skip: skipLavvaFetch,
  });

  if (loading && !data) return <CustomBackdrop loading />;

  if (!data?.meterCommonMeasurements.data) {
    return (
      <div className="p-t-16 p-l-24 p-r-24">
        <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
      </div>
    );
  }

  return (
    <div className="list-measurement p-l-24 p-r-24 grid-list-16">
      {data?.meterCommonMeasurements.data?.vectorMeasurements?.map((phaseMeasurement) => {
        const measurement = convertNumberToMeasurement(MeasurementChannelKind.Meter)(
          'vectors',
          phaseMeasurement.type,
        ) as VectorParameterTypeInternal;
        if (measurementParameters[measurement]) {
          return (
            <MeasurementItem
              key={phaseMeasurement.type}
              channelId={measurementChannel.meterChannelId}
              measurement={measurement}
              measurementGroup={MeasurementGroup.SinglePhase}
              text={t(`energyMeasurements.types.phase.${measurement}`)}
              value={phaseMeasurement.value}
              parameters={measurementParameters[measurement]}
              phase={1}
              kind={MeasurementChannelKind.Meter}
            />
          );
        }
      })}
    </div>
  );
};
