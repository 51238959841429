import { gql } from '@apollo/client';

export const ALL_USER_NOTIFICATIONS = gql`
  query AllUserNotifications($skip: Int!, $take: Int!, $where: NotificationFilterInput!) {
    allUserNotifications(
      skip: $skip
      take: $take
      where: $where
      order: { createdAt: DESC }
    ) {
      items {
        id
        correlationId
        code
        wasDisplayed
        isHidden
        createdAt
        params {
          name
          value
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const USER_NOTIFICATION = gql`
  query UserNotification($notificationId: UUID!) {
    userNotification(where: { id: { eq: $notificationId } }) {
      id
      correlationId
      code
      wasDisplayed
      isHidden
      createdAt
      params {
        name
        value
      }
    }
  }
`;
