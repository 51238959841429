import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { environment } from '../../../../environment';
import { useBackdropContext } from '../../../../context/backdrop';
import { useErrors } from '../../../../hooks/errors';
import { useGraphqlContext } from '../../../../context/graphql';
import { useRestClient } from '../../../../utils/clients/rest-client';
import { InstallationBroker, TimeZone, TimeZoneResponse } from './installation.types';

export const useGetTimeZones = (): UseQueryResult<TimeZone[], AxiosError> => {
  const restClient = useRestClient({ noInstallationIdHeader: true });
  const { handleRestErrors } = useErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<TimeZone[], AxiosError>(
    'query-time-zones',
    async () => {
      turnOnBackdrop();
      const response = await restClient.get<TimeZoneResponse>('/api/time-zones.json', {
        baseURL: environment.PUBLIC_URL,
      });

      turnOffBackdrop();
      return response.data.zones;
    },
    {
      enabled: true,
      retry: false,
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useGetBrokerToCreateInstallation = () => {
  const restClient = useRestClient({ noInstallationIdHeader: true });
  const { updateBrokerUrl } = useGraphqlContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const getBroker = async () => {
    try {
      turnOnBackdrop();
      const { data } = await restClient.get<InstallationBroker[]>('/brokerInfo/nextBrokers/1');

      if (data.length) updateBrokerUrl(data[0].brokerExternalUrl);
      turnOffBackdrop();
    } catch (error) {
      turnOffBackdrop();
      console.log(error);
    }
  };

  return {
    getBroker,
  };
};

export const useGetInstallationBroker = () => {
  const restClient = useRestClient({ noInstallationIdHeader: true });
  const { updateBrokerUrl } = useGraphqlContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const getBroker = async (installationId: string) => {
    try {
      turnOnBackdrop();
      const { data } = await restClient.get<InstallationBroker>(`/brokerInstance/${installationId}`);

      if (data.brokerExternalUrl) updateBrokerUrl(data.brokerExternalUrl);
      turnOffBackdrop();
    } catch (error) {
      turnOffBackdrop();
      console.log(error);
    }
  };

  return {
    getBroker,
  };
};
