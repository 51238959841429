import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { OptimizerScheduleStep0 } from './step-0';
import { OptimizerScheduleStep1 } from './step-1';
import { OptimizerScheduleContextProvider } from './context';

const OptimizerSchedule: React.FC = () => {
  const location = useLocation();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    const step = new URLSearchParams(location.search).get('step');
    if (step) setStep(parseInt(step));
    else setStep(0);
  }, [location.search]);

  const renderCurrentView = () => {
    switch (step) {
      case 0:
        return <OptimizerScheduleStep0 />;
      case 1:
        return <OptimizerScheduleStep1 />;
      default:
        return <OptimizerScheduleStep0 />;
    }
  };

  return <OptimizerScheduleContextProvider>{renderCurrentView()}</OptimizerScheduleContextProvider>;
};

export default OptimizerSchedule;
