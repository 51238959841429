import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconInitiate, ControlWrapper, InitiateButton } from '../../../../../components';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { ChannelTypeInternal, DeviceConnectionState } from '../../../../../data-access/gql-types/graphql';
import { useSwitch } from '../../../hooks';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelType } from '../../../types';
import { ChannelTimesActivations } from '../components/channel-times-activation';
import { ChannelDetailsMeasurements } from './measurements';

type ChannelDetailsSwitchProps = {
  channel: ChannelType;
};

const ChannelDetailsSwitch: React.FC<ChannelDetailsSwitchProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const state = useChannelsStateStore((s) => s.channelState[channel.id]);
  const { handleControlOn: onSwitch, isOn: isOnSwitch } = useSwitch({
    channelId: channel.id,
    deviceId: channel.deviceId,
  });

  const handleControl = (status: boolean) => onSwitch(status);

  return (
    <LavvaDetailsWrapper
      tabs={[{ label: t('control') }, { label: t('measurement') }]}
      additionalTabs={
        channel.data.type === ChannelTypeInternal.Switch &&
        channel.data.measuredBy &&
        state?.deviceConnectionState !== DeviceConnectionState.Recovery
          ? [<ChannelDetailsMeasurements key={2} channel={channel} measurementChannel={channel.data.measuredBy} />]
          : []
      }
    >
      <ControlWrapper>
        <InitiateButton isOn={isOnSwitch} onClickControl={handleControl}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <ChannelTimesActivations channel={channel} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsSwitch;
