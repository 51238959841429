import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/client';
import { useInstallationContext } from '../../context/installation';
import {
  Notification,
  NotificationCode,
  OnNotifiedSubscription,
  OnNotifiedSubscriptionVariables,
} from '../../data-access/gql-types/graphql';
import { ON_NOTIFIED } from '../../data-access/subscriptions/on-notified';
import { useDevicesAndChannelsContext } from '../../integrations/lavva/context/devices-and-channels';
import { useUserPermissionsContext } from '../../integrations/lavva/context/user-permissions';
import { useNotificationsStore } from '../../store/notifications';
import { isDev, isLocalhost } from '../../utils/helpers/environment';
import { addedOrRemovedOrExpired } from '../../utils/notifications';
import { toastError } from '../../utils/toast';
import { useRefetchData } from '../refresh-data';

export const useNotify = () => {
  const { t } = useTranslation('notifications');
  const { setSelectedInstallationId, selectedInstallationId } = useInstallationContext();
  const incrementCountOfUnreadNotifications = useNotificationsStore((s) => s.incrementCountOfUnreadNotifications);
  const { refetchAll } = useRefetchData();
  const { refetchUserMe } = useUserPermissionsContext();
  const { channelList } = useDevicesAndChannelsContext();
  const { data } = useSubscription<OnNotifiedSubscription, OnNotifiedSubscriptionVariables>(ON_NOTIFIED);

  useEffect(() => {
    if (data?.onNotified) {
      const notification = data.onNotified as Notification;
      if (isLocalhost || isDev) console.log('NOTIFICATION', notification);

      const shouldIncrement = notification.params.find((x) => x.name === 'ShouldIncrement');
      if (shouldIncrement?.value === 'True') incrementCountOfUnreadNotifications();

      if (
        [NotificationCode.RemovedFromInstallation, NotificationCode.UserInstallationExpired].includes(notification.code)
      ) {
        const foundInstallationId = notification.params.find((x) => x.name === 'InstallationId');
        if (foundInstallationId?.value === selectedInstallationId) {
          setSelectedInstallationId(undefined);
        }
      }

      if (notification.code === NotificationCode.GateAlert) {
        const alertType = notification.params.find((x) => x.name === 'AlertType')?.value;
        const channelId = notification.params.find((x) => x.name === 'ChannelId')?.value;

        if (alertType && alertType !== 'Unknown') {
          const alias = channelList.find((x) => x.id === channelId)?.alias;

          toastError({
            content: t(`${notification.code}.gateAlerts.${alertType}`, {
              alias: alias ? ` ${alias}` : '',
            }),
          });
        }
      }

      if (addedOrRemovedOrExpired.find((x) => x === notification.code)) {
        refetchAll();
        refetchUserMe();
      }
    }
  }, [data]);
};
