import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { DriveFlowType } from '../../../types';

export const WisniowskiDriveAddStartSmartAWSC: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('drive-add');

  const handleRemote = () => {
    navigate(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep0(DriveFlowType.Remote));
  };

  const handleDrive = () => {
    navigate(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep0(DriveFlowType.Drive));
  };

  return (
    <>
      <Header title={t('driveAddTitle')} column subtitle={t('driveAddSubTitle.smartAWSC')} center className="m-b-48" />
      <button className="button max-width-desktop m-b-24" onClick={handleRemote}>
        {t('remote')}
      </button>
      <button className="button button--secondary max-width-desktop" onClick={handleDrive}>
        {t('drive')}
      </button>
    </>
  );
};
