import React from 'react';
import { Markup } from 'react-render-markup';
import { useFetchDocuments } from '../../../hooks';

const Policy: React.FC = () => {
  const { content } = useFetchDocuments('privacy');

  return <Markup markup={content} />;
};

export default Policy;
