import React from 'react';
import classNames from 'classnames';
import { Action } from '../../../../../data-access/gql-types/graphql';
import { BasicIcon } from '../base-icon';
import './index.scss';

type ActionIconProps = {
  action: Action;
  quantity?: number;
  className?: string;
};

export const ActionIcon: React.FC<ActionIconProps> = ({ action, quantity = 2, className }) => {
  const icons = new Array(quantity).fill(true);

  return (
    <div
      className={classNames('action-icon', `action-icon--quantity-${quantity}`, { [className as string]: className })}
    >
      {icons.map((el, index) => (
        <div key={index} className="action-icon__icon">
          <BasicIcon action={action} />
        </div>
      ))}
    </div>
  );
};
