import React, { useState } from 'react';
import { FacadeType } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, Slider } from '../../../../../../components';
import { BlindDetailsControls } from '../../../../../../components/channel-details-controls/blind/controls';
import { BlindDetailsFavourites } from '../../../../../../components/channel-details-controls/blind/favourites';
import { defaultFavouritePositionList } from '../../../../../../const';
import { SlatsSection } from '../../../channel/controls/facade/details/components/slats-section';

type FacadeDetailsSpecificControlButtonsProps = {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  handleAction: (action, position?: number) => Promise<ChannelTaskPairExecutionResult[]>;
  handleSliderEvent: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleTiltEvent: (value: number) => void;
  facadeType: FacadeType;
};

export const FacadeDetailsSpecificControlButtons: React.FC<FacadeDetailsSpecificControlButtonsProps> = ({
  close,
  stop,
  open,
  handleAction,
  handleSliderEvent,
  handleTiltEvent,
  facadeType,
}) => {
  const [sliderValue, setSliderValue] = useState(50);
  const [tiltValue, setTiltValue] = useState(50);

  const onFavouriteChange = (position: number) => {
    setSliderValue(position);
    handleAction(BlindActionEnum.Percentage, position);
  };

  return (
    <div className="cover-detail-view facade">
      <ControlWrapper className="control-wrapper--space-evenly control-wrapper--full-space">
        <div className="slider-section">
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            showValue={false}
            className="slider--width-auto"
          />
        </div>
        <SlatsSection
          tiltValue={tiltValue}
          fullRange={facadeType === FacadeType.Facade180}
          defaultValue={100 - tiltValue}
          value={100 - tiltValue}
          onChange={(_, value) => {
            setTiltValue(100 - (value as number));
          }}
          onChangeCommitted={() => {
            setTiltValue(tiltValue);
            handleTiltEvent(tiltValue);
          }}
        />
      </ControlWrapper>
      <BlindDetailsControls
        downButton={{
          handleClick: close,
        }}
        stopButton={{
          handleClick: stop,
        }}
        upButton={{
          handleClick: open,
        }}
      />
      <BlindDetailsFavourites
        favouritePositionList={defaultFavouritePositionList}
        onFavouriteClick={onFavouriteChange}
      />
    </div>
  );
};
