import { useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { useInstallationContext } from '../../../../context/installation';
import { ChannelsGetAllQueryVariables, Query } from '../../../../data-access/gql-types/graphql';
import { CHANNELS_GET_ALL } from '../../../../data-access/queries/channels';
import { ON_USES_DEPLETED } from '../../../../data-access/subscriptions/on-uses-depleted';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelType, ChannelStateType } from '../../types';
import { parseChannel, parseChannelState } from '../../utils/channels/helpers';
import { useUpdateCommonLavvaState } from '../../utils/channels/subscriptions/common-lavva-state';

export const useChannelsGetAll = () => {
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const { setChannelList } = useDevicesAndChannelsContext();
  const setAllChannelsState = useChannelsStateStore((s) => s.setAllChannelsState);
  const { usesDepleted } = useUpdateCommonLavvaState();
  const { data: channelsData, loading: channelsLoading } = useQuery<Query, ChannelsGetAllQueryVariables>(
    CHANNELS_GET_ALL,
    {
      variables: { installationId: selectedInstallationId },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      skip: skipLavvaFetch,
    },
  );
  const { data } = useSubscription(ON_USES_DEPLETED, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (data) usesDepleted(data.onUsesDepleted);
  }, [data]);

  useEffect(() => {
    if (channelsData?.allUserChannels) {
      if (selectedInstallationId) {
        const newChannelList: ChannelType[] = [];
        let channelStates: Record<string, ChannelStateType> = {};

        channelsData.allUserChannels.forEach((userChannel) => {
          if (userChannel.installationId !== selectedInstallationId) return;
          const channel = parseChannel(userChannel);
          const state = parseChannelState(userChannel);

          if (channel) {
            newChannelList.push(channel);

            if (state) {
              channelStates = { ...channelStates, [channel.id]: state };
            }
          }
        });

        setChannelList(newChannelList);
        setAllChannelsState(channelStates);
      }
    }
  }, [channelsData]);

  return {
    channelsLoading,
  };
};
