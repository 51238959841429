import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserPermissionType } from '../../../../../data-access/gql-types/graphql';
import { useNative } from '../../../../../hooks';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../routes';
import { toastError } from '../../../../../utils/toast';
import { AddDeviceHook } from '../types';

export const useAddDevice = (): AddDeviceHook => {
  const navigate = useNavigate();
  const { t } = useTranslation('channel-settings');
  const { setShowBluetoothNotSupportedDialog } = useNative();
  const { permissions } = useUserPermissions();

  const addDevice = () => {
    if (permissions[UserPermissionType.DeviceAdd]) {
      if ('bluetooth' in window.navigator) navigate(ROUTES.DeviceAddBluetooth());
      else setShowBluetoothNotSupportedDialog(true);
    } else {
      toastError({ content: t('toast.noPermissions') });
    }
  };

  return {
    addDevice,
  };
};
