import { useNavigate } from 'react-router-dom';
import { IDevice, ManuallyPairedDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { useBackdropContext } from '../../../../../context/backdrop';
import { ROUTES } from '../../../../../routes';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusManuallyDevices } from '../../../hooks';
import { useDeviceTaskExecuted } from './use-device-task-executed';

export const useManuallyPaired = () => {
  const navigate = useNavigate();
  const { devicesApi } = useExalusServicesContext();
  const { data } = useExalusManuallyDevices();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleResult } = useDeviceTaskExecuted();

  const addManuallyDevice = async (manuallyPaired: ManuallyPairedDevice) => {
    turnOnBackdrop();
    const result = await devicesApi.AddManuallyPairedDevice(manuallyPaired);
    handleResult(result, manuallyPaired.Model, () => {
      turnOffBackdrop();
    });
  };

  const listenToDeviceRegistered = (device: IDevice) => {
    navigate(ROUTES.DeviceSettings(device.Guid), { replace: true });
  };

  return {
    data,
    addManuallyDevice,
    listenToDeviceRegistered,
  };
};
