import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSentry } from '..';
import { useInstallationContext } from '../../context/installation';
import { InstallationPermissions, Query, UserProfileQueryVariables } from '../../data-access/gql-types/graphql';
import { USER_ME, USER_PROFILE } from '../../data-access/queries/user';
import { useNotificationsStore } from '../../store/notifications';
import { isLocalApp } from '../../utils/helpers/local-app';
import { mapPermissions } from '../../utils/helpers/permissions';

export const useProfile = () => {
  const { selectedInstallationId } = useInstallationContext();
  const setCountOfUnreadNotifications = useNotificationsStore((s) => s.setCountOfUnreadNotifications);

  const { setSentryUser } = useSentry();
  const {
    data,
    refetch: refetchUser,
    loading: loadingMe,
  } = useQuery<Query>(USER_ME, {
    fetchPolicy: 'cache-first',
    skip: isLocalApp,
    onCompleted: (data) => {
      const profile = data.me?.profile;

      if (profile) {
        setSentryUser(profile.id, profile.email);
      }
    },
  });

  const {
    data: userProfileData,
    refetch: refetchProfile,
    loading: loadingProfile,
  } = useQuery<Query, UserProfileQueryVariables>(USER_PROFILE, {
    variables: { installationId: selectedInstallationId },
    fetchPolicy: 'cache-first',
    skip: isLocalApp || !selectedInstallationId,
  });

  const refetchUserMe = () => refetchUser();
  const refetchUserProfile = () => refetchProfile();

  const meData = data?.me;
  const userInitial = meData?.profile?.firstName.charAt(0) || '';
  const userId = meData?.profile?.id;

  useEffect(() => {
    if (userProfileData?.userProfile?.countOfUnreadNotifications) {
      setCountOfUnreadNotifications(userProfileData.userProfile.countOfUnreadNotifications);
    }
  }, [userProfileData?.userProfile?.countOfUnreadNotifications]);

  const permissions = useMemo(() => {
    if (meData?.permissions) {
      return mapPermissions((meData.permissions || []) as InstallationPermissions[]);
    }

    return {};
  }, [meData?.permissions]);

  const tips = (userProfileData?.userProfile?.displayedLearningScenarios as string[]) || undefined;

  return {
    user: {
      profile: {
        firstName: meData?.profile?.firstName || '',
        id: meData?.profile?.id || '',
        email: meData?.profile?.email || '',
      },
      profileImage: {
        imageUrl: meData?.profileImage?.imageUrl || '',
      },
    },
    userInitial,
    userId,
    userProfileData,
    tips,
    refetchUserMe,
    refetchUserProfile,
    loadingUser: loadingMe || loadingProfile,
    permissions,
  };
};
