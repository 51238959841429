import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useInstallationContext } from '../../../../../../context/installation';
import {
  OnGateConfigurationChangeSubscription,
  OnGateConfigurationChangeSubscriptionVariables,
  OnGateStatusChangedSubscription,
  OnGateStatusChangedSubscriptionVariables,
} from '../../../../../../data-access/gql-types/graphql';
import {
  ON_GATE_CALIBRATE_STATUS_CHANGE,
  ON_GATE_CONFIGURATION_CHANGE,
  ON_GATE_STATUS_CHANGED,
  ON_REQUEST_ACK_RECEIVED,
} from '../../../../../../data-access/subscriptions/lavva-devices';
import { useUpdateCommonLavvaState } from '../common-lavva-state';
import { ChannelSubscriptionParams } from '../types';
import { useLavvaDeviceGateUpdate } from './update';

export const useGateSubscriptions = ({ channelId }: ChannelSubscriptionParams) => {
  const { updateLavvaGateStatus, onGateCalibrateStatusChange, updateLavvaGateConfiguration } =
    useLavvaDeviceGateUpdate();
  const { onRequestAckReceived } = useUpdateCommonLavvaState();
  const { skipLavvaFetch } = useInstallationContext();
  const { data: gateStatus } = useSubscription<
    OnGateStatusChangedSubscription,
    OnGateStatusChangedSubscriptionVariables
  >(ON_GATE_STATUS_CHANGED, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateConfiguration } = useSubscription<
    OnGateConfigurationChangeSubscription,
    OnGateConfigurationChangeSubscriptionVariables
  >(ON_GATE_CONFIGURATION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateCalibration } = useSubscription(ON_GATE_CALIBRATE_STATUS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (gateStatus) updateLavvaGateStatus(gateStatus.onGateStatusChanged);
  }, [gateStatus]);

  useEffect(() => {
    if (gateConfiguration) updateLavvaGateConfiguration(gateConfiguration.onGateConfigurationChange);
  }, [gateConfiguration]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);

  useEffect(() => {
    if (gateCalibration) onGateCalibrateStatusChange(gateCalibration.onGateCalibrateStatusChange);
  }, [gateCalibration]);
};
