import {
  IconBarrier,
  IconBulb,
  IconDoor,
  IconHorizontalDoubleGate,
  IconHorizontalGate,
  IconRGBCircles,
  IconSmallBulb,
  IconSquareMeter,
  IconSun,
  IconVerticalGate,
  IconWoodenBlind,
} from '../../../../../components/icons';
import { IconGateway } from '../../../../../components/icons/icon-gateway';
import { IconMultibox } from '../../../../../components/icons/icon-multibox';
import { IconOnePieceDoor } from '../../../../../components/icons/icon-one-piece-door';
import { LogLevel } from '../../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../../routes';
import { BluetoothData, OSSystem, DriveFlowType } from '../types';
import { BluetoothProviderState, GateTypePrefix } from '../types';
import { FindWebBluetoothDevicesView } from './FindWebBluetoothDevicesView';

export const initialBluetoothDataState: BluetoothData = {
  ssid: '',
  password: '',
  name: '',
  deviceName: '',
  mac: '',
  type: '',
  identifyIsAvailable: false,
  isAlreadyAdded: false,
  diagnostic: null,
  subTypes: [],
};

export const initialContextState: BluetoothProviderState = {
  deviceNameBT: '',
  bluetoothData: initialBluetoothDataState,
  deviceConnected: false,
  identifing: false,
  wifiList: [],
  typeOptions: [],
  wifiLoading: false,
  isAlreadyAdded: false,
  redoRegisteringDisabled: false,
  checkList: [],
  registerErrorMessage: '',
  updateBluetoothData: () => null,
  checkIfConnected: () => null,
  searchBluetooth: () => null,
  getWifiNetworks: () => null,
  getDeviceSettings: () => null,
  identifyDevice: () => null,
  registerDevice: () => null,
  releaseLock: () => null,
  resetBluetoothData: () => null,
  disconnect: () => null,
  setRedoRegisteringDisabled: () => null,
  getKnowsWifiNetworks: () => null,
  updateByWifi: () => null,
  setWifi: () => null,
  removeWifi: () => null,
  updatePayload: { version: '', progress: 0, status: null, errorCode: '' },
  percentage: 0,
  isCustomBluetooth: false,
  foundDevices: [],
  dialogScanning: false,
  scanning: false,
  setDialogScanning: () => null,
  handleDeviceConnect: () => null,
  WebBluetooth: new FindWebBluetoothDevicesView(),
  updateWifiData: () => null,
  returnToStartPage: () => null,
  errorCode: '',
  advancedSettings: null,
  setBtLogLevel: () => null,
};

export const appStoreLink = {
  [OSSystem.Desktop]: {
    href: '',
    store: '',
  },
  [OSSystem.Android]: {
    href: 'https://play.google.com/store/apps/details?id=com.zamel.lavva',
    store: 'Google Play',
  },
  [OSSystem.Ios]: {
    href: 'https://apps.apple.com/pl/app/lavva-home/id6475717795',
    store: 'App Store',
  },
};

export const deviceTypeIcon = {
  Switch: <IconSun isOn />,
  Blind: <IconWoodenBlind position={33} />,
  Meter: <IconSquareMeter isOn />,
  Optimizer: <IconSquareMeter isOn />,
  Light: <IconSun isOn />,
  Gate: <IconHorizontalGate position={33} />,
  GateModule: <IconVerticalGate position={33} />,
  TwoGates: <IconHorizontalDoubleGate position={33} />,
  GateAndWicket: <IconGateway position={33} />,
  TwoDoorsWithElectricStrikes: <IconDoor position={33} />,
  RisingArmBarrier: <IconBarrier position={33} />,
  W: <IconBulb isOn />,
  Rgb: <IconRGBCircles isOn />,
  Ww: <IconSmallBulb isOn />,
  GateWithPartialOpen: <IconVerticalGate position={33} />,
  GateAndMultibox: <IconMultibox position={33} />,
  Door: <IconDoor position={33} isSunIcon />,
};

export const deviceSubTypesIcon = {
  Sliding: <IconHorizontalGate position={33} />,
  DoubleLeaf: <IconHorizontalDoubleGate position={33} />,
  Sectional: <IconVerticalGate position={33} />,
  OnePieceDoor: <IconOnePieceDoor position={33} />,
  DoorDailyFunc: <IconDoor position={33} isSunIcon />,
  Industrial: <IconVerticalGate position={33} />,
};

export const smartAWGateWithNoPhotoCell = ['TWODOORSWITHELECTRICSTRIKES', 'DOOR'];

export const checkIfAddingDeviceEnableMessageActive = (deviceName: string) => {
  const addingDeviceEnabledMessageRoutes = {
    [GateTypePrefix.SmartAWSC]: [
      ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2(DriveFlowType.Drive),
      ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2(DriveFlowType.Remote),
    ],
    [GateTypePrefix.SmartAwGate]: [ROUTES.DeviceAddBluetoothWisniowskiStart()],
  };

  return Object.entries(addingDeviceEnabledMessageRoutes).some(
    ([prefix, routes]) => deviceName.includes(prefix) && routes.includes(window.location.pathname),
  );
};

export const logMessage = (message: string, logLevel: LogLevel) => {
  switch (logLevel) {
    case LogLevel.Critical:
    case LogLevel.Error:
      console.error(message);
      break;
    case LogLevel.Debug:
      console.debug(message);
      break;
    case LogLevel.Information:
      console.info(message);
      break;
    case LogLevel.Trace:
      console.trace(message);
      break;
    case LogLevel.Warning:
      console.warn(message);
      break;
    case LogLevel.None:
      break;
    default:
      break;
  }
};
