import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { ScenesServiceErrorCode } from 'lavva.exalushome/build/js/Services/Scenes/IScenesService';
import { ScenesUsedIn } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { useBackdropContext } from '../../../../../context/backdrop';
import { ROUTES } from '../../../../../routes';
import { ActionType } from '../../../../../types';
import { toastSuccess } from '../../../../../utils/toast';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusChannels, useHandleDataFrameErrors } from '../../../hooks';
import { useExalusScenario } from '../../../hooks/scenarios';
import { useExalusCreateActionContext } from '../../action-create/context';
import { useScenariosErrors } from '../../action-list/hooks/use-scenarios-errors';
import { SetParametersType } from '../types';
import { useActionSetParameters } from './use-action-set-parameters';

export const useActionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('action');
  const { actionId } = useParams<{ actionId: string }>();
  const ActionId = actionId ?? '';
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [impossiblePopup, setImpossiblePopup] = useState<boolean>(false);
  const [usedInScenarios, setUsedInScenarios] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const { data } = useExalusScenario(ActionId);
  const { scenesApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { setActionParameters } = useActionSetParameters();
  const { data: channels } = useExalusChannels();
  const { builder } = useExalusCreateActionContext();
  const { handleScenariosErrors } = useScenariosErrors();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isDisabled = params.get('isDisabled');
    if (isDisabled) setChecked(isDisabled === 'false');
    setActionParameters(SetParametersType.Edit, channels || [], data);
  }, [data, channels]);

  const isAction = useMemo(() => data?.LeftArgumentType === 'Null', [data]);

  const goToEditScenario = () => {
    if (data) {
      navigate(`${ROUTES.ActionEdit(ActionId)}?type=${isAction ? ActionType.Action : ActionType.Automation}`);
    }
  };

  const duplicateScenario = () => {
    const newBuilder = builder?.CloneCurrentBuilder();

    if (newBuilder) {
      setActionParameters(SetParametersType.Duplicate, channels || [], newBuilder);
      navigate(`${ROUTES.ActionCreate()}?type=${isAction ? ActionType.Action : ActionType.Automation}`);
    }
  };

  const handleDeletePopupClose = () => setDeletePopup(false);

  const toggleActive = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    saveEnabled(e.target.checked);
  };

  const submitDelete = useCallback(async () => {
    turnOnBackdrop();
    setUsedInScenarios('');
    const checkResult = await scenesApi.CheckIfSequenceIsUsedInAnotherSequenceAsync(ActionId);

    if ((checkResult as ResponseResult<ScenesServiceErrorCode>).Type) {
      handleScenariosErrors(checkResult as ResponseResult<ScenesServiceErrorCode>);
    } else {
      const usedNames = [
        ...(checkResult as ScenesUsedIn).ConditionsAsSceneExecuted,
        ...(checkResult as ScenesUsedIn).ConditionsAsSceneLock,
      ].join(', ');
      setUsedInScenarios(usedNames);

      if (!usedNames) {
        const result = await scenesApi.DeleteSequenceAsync(ActionId);
        turnOffBackdrop();

        if (result === Status.OK) {
          toastSuccess({ content: t(`action.delete.deleteSuccess${isAction ? 'Action' : 'Automation'}`) });
          handleDeletePopupClose();
          navigate(-1);
        } else {
          handleError(result);
        }
      } else {
        setDeletePopup(false);
        turnOffBackdrop();
        setImpossiblePopup(true);
      }
    }
  }, [ActionId, isAction]);

  const saveEnabled = useCallback(
    async (enabled: boolean) => {
      turnOnBackdrop();
      let result;

      if (enabled) {
        result = await scenesApi.EnableSequenceAsync(ActionId);
      } else {
        result = await scenesApi.DisableSequenceAsync(ActionId);
      }

      turnOffBackdrop();
      if (result !== Status.OK) handleError(result);
    },
    [ActionId, isAction],
  );

  return {
    data,
    goToEditScenario,
    duplicateScenario,
    deletePopup,
    impossiblePopup,
    usedInScenarios,
    isAction: data ? isAction : true,
    checked,
    setDeletePopup,
    setImpossiblePopup,
    handleDeletePopupClose,
    submitDelete,
    toggleActive,
  };
};
