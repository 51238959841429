import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  GateEventTypeInternal,
  GateHistoryEntry,
  GateKindInternal,
} from '../../../../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../../../../context/devices-and-channels';
import { ChannelGateType } from '../../../../../../types';
import { HistoryGateIcon } from '../gate-icon';
import './index.scss';

type HistoryItemProps = {
  item: GateHistoryEntry;
  hasVentilation: boolean;
};

export const HistoryItem: React.FC<HistoryItemProps> = ({ item, hasVentilation }) => {
  const { t } = useTranslation('channel-details');
  const { channel } = useDevicesAndChannelsContext();

  const titleTranslateKey = useMemo(() => {
    switch (item.eventType) {
      case GateEventTypeInternal.SetTilt: {
        return `${item.eventType}${hasVentilation ? '_VENTILATION' : ''}`;
      }
      case GateEventTypeInternal.SetOpen:
      case GateEventTypeInternal.SetClose:
      case GateEventTypeInternal.Sbs: {
        return `${item.eventType}${
          (channel?.data as ChannelGateType)?.gateKind === GateKindInternal.Doors ? '_DOORS' : ''
        }`;
      }
      default: {
        return item.eventType;
      }
    }
  }, [item.eventType, channel?.data]);

  const sourceName = useMemo(() => {
    const name = item.controlledBy.name;
    if (!name) return 'N/A';

    return name.includes('t.') ? t(`gateHistory.source.${name.replace('t.', '')}`) : name;
  }, [item.controlledBy.name]);

  return (
    <>
      <div className="gate-history-item">
        <div className="history-icon">
          <HistoryGateIcon
            eventType={GateEventTypeInternal.SetPartialOpen}
            source={item.controlledBy.controlSource}
            value={item.value || null}
          />
        </div>
        <div className="history-details">
          <p className="title">
            {`${t(`gateHistory.event.${titleTranslateKey}`, {
              position: item.value !== undefined ? item.value : '?',
              time: item.value ? `(${item.value / 1000}s)` : '',
            })} ${t(`gateHistory.controlledBy.${item.controlledBy.controlSource}`, {
              name: sourceName,
            })}`}
          </p>
          {item.occuredAt !== '1970-01-01T00:00:00.000Z' && (
            <p className="date">{item.occuredAt ? format(new Date(item.occuredAt), 'dd.MM.yy, HH:mm') : ''}</p>
          )}
        </div>
      </div>
      <hr />
    </>
  );
};
