import React, { useEffect, useMemo, useState } from 'react';
import { ControlWrapper, Slider, SubmitButton } from '../../../../../../../components';
import { BlindDetailsControls } from '../../../../../../../components/channel-details-controls/blind/controls';
import { BlindDetailsFavourites } from '../../../../../../../components/channel-details-controls/blind/favourites';
import { defaultFavouritePositionList } from '../../../../../../../const';
import {
  ChannelTypeInternal,
  TriggerActionRequestInput,
  TriggerActionType,
} from '../../../../../../../data-access/gql-types/graphql';
import { ChannelType } from '../../../../../types';
import { useTriggerFormContext } from '../../../context';

type CoverSetupProps = {
  setAction: (value: TriggerActionRequestInput) => void;
  channel: ChannelType;
  defaultParameters: TriggerActionRequestInput | undefined;
};

enum BlindDirection {
  Unknown = -1,
  Stop = -2,
  Up = 0,
  Down = 100,
}

const ValueMapper = {
  '-1': 0,
  '-2': 1,
  '0': 2,
  '100': 3,
};

const CoverSetup: React.FC<CoverSetupProps> = ({ setAction, channel, defaultParameters }) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [activeControl, setActiveControl] = useState<BlindDirection>(BlindDirection.Unknown);
  const [channelParameters, setChannelParameters] = useState<TriggerActionRequestInput | null>(null);
  const { updateAction } = useTriggerFormContext();

  const setPosition = (value: number) => {
    setSliderValue(value);
    setActiveControl(BlindDirection.Unknown);
    setChannelParameters(() => ({
      id: channel.id,
      actionType: TriggerActionType.SetBlindPosition,
      integerValue: value,
    }));
  };

  useEffect(() => {
    if (defaultParameters) {
      if (defaultParameters.actionType === TriggerActionType.SetBlindPosition) {
        setSliderValue(defaultParameters.integerValue || 0);
        setPosition(defaultParameters.integerValue || 0);
      } else {
        const value = Object.keys(ValueMapper).find((key) => ValueMapper[key] === defaultParameters.integerValue);
        if (value !== undefined) {
          const sliderVal = parseInt(value);
          if (sliderVal >= 0) setSliderValue(sliderVal);

          setChannelParameters(() => ({
            id: channel.id,
            actionType: TriggerActionType.SetBlindDirection,
            integerValue: defaultParameters.integerValue,
          }));
          setActiveControl(sliderVal || 0);
        }
      }
    }
  }, [setSliderValue, defaultParameters]);

  const favouritePositionList = useMemo(() => {
    if (
      channel.data.type === ChannelTypeInternal.Blind &&
      channel.data.favouritePositionList?.length === 3 &&
      channel.data.favouritePositionList.every((value: number) => Number(value))
    ) {
      return channel.data.favouritePositionList;
    } else if (channel.data.type === ChannelTypeInternal.Blind && channel.data.favouritePositionList?.length) {
      return [
        channel.data.favouritePositionList[0] || defaultFavouritePositionList[0],
        channel.data.favouritePositionList[1] || defaultFavouritePositionList[1],
        channel.data.favouritePositionList[2] || defaultFavouritePositionList[2],
      ];
    } else {
      return defaultFavouritePositionList;
    }
  }, [channel, defaultFavouritePositionList]);

  const onFavouriteClick = (position: number) => {
    setActiveControl(BlindDirection.Unknown);
    setPosition(position);
  };

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    setActiveControl(BlindDirection.Unknown);
    setPosition(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setSliderValue(Number(event.target.value));

  const onSubmit = () => {
    updateAction(channel.id, {
      id: channel.id,
      actionType: channelParameters?.actionType || TriggerActionType.SetBlindPosition,
      integerValue: channelParameters?.integerValue,
    });
    setAction({
      id: channel.id,
      actionType: channelParameters?.actionType || TriggerActionType.SetBlindPosition,
      integerValue: channelParameters?.integerValue,
    });
  };

  const handleControl = (value: BlindDirection) => {
    if (value >= 0) setSliderValue(value);

    setChannelParameters(() => ({
      id: channel.id,
      actionType: TriggerActionType.SetBlindDirection,
      integerValue: ValueMapper[value.toString()],
    }));
    setActiveControl(value);
  };

  return (
    <>
      <ControlWrapper>
        <div className="cover-detail-view__slider">
          <Slider value={sliderValue} onPointerUp={handleSliderEvent} onChange={onChange} />
        </div>
      </ControlWrapper>
      <BlindDetailsControls
        downButton={{
          handleClick: () => handleControl(BlindDirection.Down),
          active: activeControl === BlindDirection.Down,
        }}
        stopButton={{
          handleClick: () => handleControl(BlindDirection.Stop),
          active: activeControl === BlindDirection.Stop,
        }}
        upButton={{
          handleClick: () => handleControl(BlindDirection.Up),
          active: activeControl === BlindDirection.Up,
        }}
      />
      <BlindDetailsFavourites
        favouritePositionList={favouritePositionList}
        onFavouriteClick={onFavouriteClick}
        lastPosition={sliderValue}
        microventilation={{
          position: 99,
          lastPosition: sliderValue,
          handleMicroventilation: () => setPosition(99),
        }}
      />
      <SubmitButton onClick={onSubmit} />
    </>
  );
};

export default CoverSetup;
