import { useEffect } from 'react';
import { useNavigate, useLocation, useNavigationType, NavigationType } from 'react-router-dom';
import { toastInfo } from '../../utils/toast';

export const usePreventBack = (preventMessage?: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === NavigationType.Pop && preventMessage) {
      // Prevent going back by pushing the current location again
      navigate(location.pathname, { replace: true });
      toastInfo({ content: preventMessage });
    }
  }, [location, navigationType, preventMessage]);
};
