import {
  IconBulb,
  IconDevices,
  IconPower,
  IconRGBCircles,
  IconWoodenBlind,
  IconWoodenGate,
} from '../../../../../components/icons';
import { ActionTaskOption, ActionTaskVariantOption, SceneTaskType, TaskVariant } from '../types';

export const actionTaskVariants: ActionTaskVariantOption[] = [
  {
    title: 'action.create.tasks.variants.onOff',
    icon: <IconPower colorAccent />,
    variant: TaskVariant.OnOff,
  },
  {
    title: 'action.create.tasks.variants.dim',
    icon: <IconBulb isOn />,
    variant: TaskVariant.Dim,
  },
  {
    title: 'action.create.tasks.variants.setRGBW',
    icon: <IconRGBCircles isOn={true} />,
    variant: TaskVariant.SetRGBW,
  },
  {
    title: 'action.create.tasks.variants.setBlind',
    icon: <IconWoodenBlind position={33} />,
    variant: TaskVariant.SetBlind,
  },
  {
    title: 'action.create.tasks.variants.setGate',
    icon: <IconWoodenGate position={33} />,
    variant: TaskVariant.SetGate,
  },
  // {
  //   title: 'action.create.tasks.variants.setVentilation',
  //   icon: <IconVent />,
  //   variant: TaskVariant.SetVentilation,
  // },
];

export const actionTasks: ActionTaskOption[] = [
  {
    title: 'action.create.tasks.list.stateChange',
    icon: <IconDevices />,
    type: SceneTaskType.ChannelState,
  },
];
