import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { enqueueSnackbar, VariantType, useSnackbar } from 'notistack';

export type ToastProps = {
  content: string;
  icon?: ReactNode;
  btnText?: string;
  onAction?: () => void;
  className?: string;
  onClose?: () => void;
};

let useSnackbarRef;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(toastContent: ToastProps) {
    this.toast(toastContent, 'success');
  },
  error(toastContent: ToastProps) {
    this.toast(toastContent, 'error');
  },
  info(toastContent: ToastProps) {
    this.toast(toastContent, 'info');
  },
  toast(toastContent: ToastProps, variant: VariantType = 'default') {
    const action = (snackbarId: number | string) => (
      <button
        className="close_toast"
        onClick={() => {
          if (toastContent.onAction) toastContent.onAction();
          useSnackbarRef.closeSnackbar(snackbarId);
        }}
        onTouchStart={() => {
          if (toastContent.onAction) toastContent.onAction();
          useSnackbarRef.closeSnackbar(snackbarId);
        }}
      >
        {toastContent.btnText}
      </button>
    );

    const toastContentWithIcon = (
      <div className="toast-content">
        {toastContent.icon && <span className="toast-icon">{toastContent.icon}</span>}
        <span className="toast-message">{toastContent.content}</span>
      </div>
    );

    enqueueSnackbar(toastContentWithIcon, {
      ...(toastContent.btnText ? { action } : {}),
      variant,
      style: { whiteSpace: 'pre-line' },
      preventDuplicate: true,
      disableWindowBlurListener: true,
      className: classNames(toastContent.className, { 'icon-hide': toastContent.icon }),
      ...(toastContent.onClose ? { onClose: toastContent.onClose } : {}),
    });
  },
};
