import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  IconCirclePadlockClose,
  IconCirclePadlockOpen,
  IconVent,
  IconVerticalGate,
} from '../../../../components/icons';
import { DependantChannelResponse } from '../../../../data-access/gql-types/graphql';
import { GateActionInternal } from '../../modules/action-create/types';
import { gateIcons } from '../../modules/channel-configuration/components/icon-edit/icons/gate';
import DependantChannel from '../../modules/channel-details/gate/components/dependant-channel';
import { gateHasVentilation } from '../../modules/channel-details/gate/utils';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelGateStateType, ChannelGateType, ChannelType } from '../../types';
import './index.scss';

type GateControlsCommonProps = {
  open: () => void;
  close: () => void;
  stop: () => void;
  ventilation: () => void;
  ventilationVisible: boolean;
  directionVisible: boolean;
  activeButton?: GateActionInternal;
  active?: boolean;
};

type ChannelDetailsProps = GateControlsCommonProps & {
  kind: 'channel';
  channel: ChannelType;
  dependantChannels: DependantChannelResponse[];
};

type ActionDetailsProps = GateControlsCommonProps & {
  kind: 'action';
};

type GateControlsProps = ChannelDetailsProps | ActionDetailsProps;

export const GateControls: React.FC<GateControlsProps> = (props) => {
  const { open, close, stop, ventilation, ventilationVisible, directionVisible, kind, active } = props;
  const { t } = useTranslation('channel-details');
  const channelState = useChannelsStateStore((s) => s.channelState);
  const direction =
    kind === 'channel' ? (channelState[props.channel.id] as ChannelGateStateType | null)?.direction : '';

  const hasVentilation =
    props.kind === 'channel' && gateHasVentilation((props.channel.data as ChannelGateType).gateKind);

  const repealIcon = useMemo(() => {
    if (props.kind === 'channel') {
      const channel = props.channel;
      const availableIcons = gateIcons((channel.data as ChannelGateType)?.gateKind, 70);
      const icon = availableIcons.find((x) => x.iconName === channel.iconName || x.oldIconName === channel.iconName);
      if (icon) return icon.component;
      return availableIcons[0].component;
    }

    return <IconVerticalGate position={70} />;
  }, [props]);

  return (
    <div className="gate-controls m-b-24">
      {direction && (
        <div className="direction m-b-16">
          <p>{t(`direction.${direction}`)}</p>
        </div>
      )}
      <div className={classNames('gate-controls__background', { active: active })}>
        <div className="gate-controls__content">
          {directionVisible && (
            <>
              <div className="on-off-wrapper m-b-16">
                <button
                  onClick={open}
                  className={classNames('on-off-wrapper__btn on-off-wrapper__btn--on', {
                    'on-off-wrapper__btn--active': props.activeButton === GateActionInternal.Open,
                  })}
                >
                  <IconCirclePadlockOpen big />
                </button>
                <div className="on-off-wrapper__divider" />
                <button
                  onClick={close}
                  className={classNames('on-off-wrapper__btn on-off-wrapper__btn--off', {
                    'on-off-wrapper__btn--active': props.activeButton === GateActionInternal.Closed,
                  })}
                >
                  <IconCirclePadlockClose big />
                </button>
              </div>
              <button
                onClick={stop}
                className={classNames('btn-custom shadow', {
                  active: props.activeButton === GateActionInternal.Stopped,
                })}
              >
                STOP
              </button>
            </>
          )}

          {kind === 'channel' && props.dependantChannels.length ? (
            <div className={classNames('dependant-channels', { 'm-t-16': directionVisible })}>
              {props.dependantChannels.map((ch) => (
                <DependantChannel key={ch.channelId} channel={ch} deviceId={props.channel.deviceId} />
              ))}
            </div>
          ) : null}
          {ventilationVisible && (
            <button
              onClick={ventilation}
              className={classNames('btn-custom m-t-16', {
                active: props.activeButton === GateActionInternal.Tilt,
              })}
            >
              <div className="icon">{hasVentilation ? <IconVent /> : repealIcon}</div>
              <span>{t(hasVentilation ? 'ventilation' : 'repeal')}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
