import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { every } from 'lodash';
import { Checkbox } from '../../../../../../components';
import { DaysOfWeekOption, DaysPeriod } from '../../../../../../types/actions';

type DaysProps = {
  days: DaysOfWeekOption[];
  updateDays: Dispatch<SetStateAction<DaysOfWeekOption[]>>;
};

export const Days: React.FC<DaysProps> = ({ days, updateDays }) => {
  const { t } = useTranslation('action');

  const handleActionsOnChange = (e: ChangeEvent<HTMLInputElement>, daysPeriod?: DaysPeriod) => {
    let items = Array.from(days);
    const { checked, value } = e.target;

    if (!daysPeriod) {
      const foundIndex = items.findIndex((x) => x.value === parseInt(value));

      if (foundIndex !== -1) {
        items[foundIndex].checked = checked;
      }
    } else {
      if (daysPeriod === DaysPeriod.Work) {
        items = items.map((x) => {
          if (x.value !== 6 && x.value !== 7) {
            return {
              ...x,
              checked,
            };
          }

          return { ...x };
        });
      } else {
        items = items.map((x) => {
          if (x.value === 6 || x.value === 7) {
            return {
              ...x,
              checked,
            };
          }

          return { ...x };
        });
      }
    }

    updateDays(items);
  };

  return (
    <>
      <Checkbox
        checked={every(days.slice(0, 5), (x) => x.checked)}
        reverse
        onChange={(e) => handleActionsOnChange(e, DaysPeriod.Work)}
      >
        <p>{t('action.create.conditions.sources.schedule.workDays')}</p>
      </Checkbox>
      <div className="days-period">
        {days.slice(0, 5).map((x) => (
          <Checkbox id={x.value.toString()} key={x.value} checked={x.checked} reverse onChange={handleActionsOnChange}>
            <p>{x.label}</p>
          </Checkbox>
        ))}
      </div>
      <Checkbox
        checked={every(days.slice(5, 7), (x) => x.checked)}
        reverse
        onChange={(e) => handleActionsOnChange(e, DaysPeriod.Weekend)}
      >
        <p>{t('action.create.conditions.sources.schedule.weekendDays')}</p>
      </Checkbox>
      <div className="days-period">
        {days.slice(5, 7).map((x) => (
          <Checkbox id={x.value.toString()} key={x.value} checked={x.checked} reverse onChange={handleActionsOnChange}>
            <p>{x.label}</p>
          </Checkbox>
        ))}
      </div>
    </>
  );
};
