import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeviceResponseType as ChannelResponseType,
  DeviceResponseType,
  DeviceTaskType,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  MeasuredEnergyDeviceState,
  OvercurrentProtectionDeviceState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { NavHeader, Page, PopUpNav } from '../../../../components';
import LavvaDetailsWrapper from '../../../../components/details-wrapper';
import { IconSettings } from '../../../../components/popup-nav/icons';
import { useBackdropContext } from '../../../../context/backdrop';
import { ROUTES } from '../../../../routes';
import { toastInfo } from '../../../../utils/toast';
import { ChannelEnergyMeasurementList } from '../../components/channel/controls/energy-measurement/details';
import SensorHistory from '../../components/channel/sensor-history';
import { useExalusChannel, useExalusChannelStateChanged } from '../../hooks';
import { useLoggedUser } from '../../hooks/common/logged-user';
import { canChannelDo, getPreciseChannelType } from '../../utils';
import { ChannelDetailsControls } from './controls';
import { ChannelDetailsHeader } from './header';

const ChannelDetails: React.FC = () => {
  const navigate = useNavigate();
  const { t: tc } = useTranslation('configuration');
  const { t: td } = useTranslation('channel-details');
  const { channelId } = useParams<{ channelId: string }>();
  const { data: channel } = useExalusChannel((channelId || '').split('_')[0], channelId || '');
  const { carouselDisabled } = useBackdropContext();
  const { canDeviceEdit } = useLoggedUser();
  const type = getPreciseChannelType(channel);
  useExalusChannelStateChanged(channel);

  const tabs = [{ label: td('currently') }, { label: td('measurement') }, { label: td('history') }];

  const state = useMemo(() => {
    return channel?.States?.find(
      (state) => state.TypeAsEnum === DeviceResponseType.MeasuredEnergy,
    ) as MeasuredEnergyDeviceState;
  }, [channel?.States]);

  const showTabsWithMeasurements = useMemo(() => {
    return channel ? canChannelDo(channel, [DeviceTaskType.GetPowerMeasurements]) : false;
  }, [state, channel]);

  const overcurrentState = channel?.States?.find(
    (state) => state.TypeAsEnum === ChannelResponseType.OvercurrentProtectionState,
  ) as OvercurrentProtectionDeviceState;

  if (overcurrentState?.Data?.OverrcurrentDetected) {
    toastInfo({ content: td('exalus.overcurrentDetected') });
  }

  return (
    <Page
      contentClassName={'page__content--flex'}
      header={
        <NavHeader>
          {channel && canDeviceEdit && (
            <PopUpNav
              links={[
                {
                  onClick: () => navigate(ROUTES.ChannelConfiguration(type, channelId)),
                  label: tc('channelSettings'),
                  icon: <IconSettings />,
                },
              ]}
            />
          )}
        </NavHeader>
      }
    >
      <ChannelDetailsHeader channel={channel} />
      {channel && (
        <>
          {showTabsWithMeasurements ? (
            <LavvaDetailsWrapper
              preventSwipeEvent={carouselDisabled}
              tabs={tabs}
              additionalTabs={[
                <ChannelEnergyMeasurementList key={1} channel={channel} />,
                <SensorHistory key={1} channel={channel} />,
              ]}
            >
              {channel && <ChannelDetailsControls channel={channel} />}
            </LavvaDetailsWrapper>
          ) : (
            <ChannelDetailsControls channel={channel} />
          )}
        </>
      )}
    </Page>
  );
};

export default ChannelDetails;
