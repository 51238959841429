import { GateFeatureTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { ChannelGateType, ChannelType } from '../../../../types';
import {
  checkSetDirectionSupported,
  checkSetPositionSupported,
  checkStepByStepSupported,
  checkVentilationSupported,
} from '../../../../utils/channels/helpers/gate';

export const useGateSupportedFeatures = (channel?: ChannelType) => {
  const gateChannel = channel?.data as ChannelGateType | undefined;
  const gateMode = gateChannel?.gateMode;
  const supportedFeatures = gateChannel?.supportedGateFeatures || [];

  const ventilationSupported = checkVentilationSupported(gateMode, supportedFeatures);
  const setDirectionSupported = checkSetDirectionSupported(gateMode, supportedFeatures);
  const setPositionSupported = checkSetPositionSupported(gateMode, supportedFeatures);
  const calibrationSupported = supportedFeatures.includes(GateFeatureTypeInternal.Calibrate);
  const stepByStepSupported = checkStepByStepSupported(gateMode, supportedFeatures);
  const partialControlModeSupported = supportedFeatures.includes(GateFeatureTypeInternal.PartialControlMode);
  const dailyModeModeSupported = supportedFeatures.includes(GateFeatureTypeInternal.DailyMode);
  const lockReportingModeSupported = supportedFeatures.includes(GateFeatureTypeInternal.LockReporting);
  const setTiltTimeSupported = supportedFeatures.includes(GateFeatureTypeInternal.SetTiltTime);

  return {
    ventilationSupported,
    setDirectionSupported,
    setPositionSupported,
    calibrationSupported,
    stepByStepSupported,
    partialControlModeSupported,
    dailyModeModeSupported,
    lockReportingModeSupported,
    setTiltTimeSupported,
  };
};
