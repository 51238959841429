import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StatusScreen } from '../../../../components';
import { ROUTES } from '../../../../routes';
import { StatusTypeEnum } from '../../../../types';

const InstallationShareConfirmation: React.FC = () => {
  const { t } = useTranslation('installation');
  const navigate = useNavigate();

  return (
    <StatusScreen
      header={t('sharing.confirmation.heading')}
      subTitle={t('sharing.confirmation.message')}
      type={StatusTypeEnum.SENT}
      secondaryBackground
      primaryBtnText={t('sharing.confirmation.action')}
      onPrimaryBtnClick={() => navigate(ROUTES.Root(), { replace: true })}
      noBackButton
    />
  );
};

export default InstallationShareConfirmation;
