import React, { useMemo } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { IconSortHandle } from '../../../../../../../components';
import { TriggerActionRequestInput, TriggerActionType } from '../../../../../../../data-access/gql-types/graphql';
import { ChannelItem } from '../../../../../components';
import { TriggerChannelParameters } from '../../../../../components/trigger/channel-parameters';
import { DelayItem } from '../../delay-item';

type ActionItemProps = {
  list: TriggerActionRequestInput[];
  isDraggable?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  id: string;
};

export const ActionItem: React.FC<ActionItemProps> = ({
  list,
  isDraggable = false,
  listeners,
  attributes,
  setNodeRef,
  style,
  id,
}) => {
  const item = useMemo(() => list.find((item: TriggerActionRequestInput) => item.id === id), [id]);

  if (!item) return null;

  return (
    <div className="action-item" style={style} ref={setNodeRef}>
      {item.actionType === TriggerActionType.DelayInSeconds ? (
        <DelayItem action={item} />
      ) : (
        <ChannelItem id={item.id} isOn details={<TriggerChannelParameters action={item} />} />
      )}
      {isDraggable && (
        <div className="action-item__draggable-handle" {...attributes} {...listeners}>
          <IconSortHandle />
        </div>
      )}
    </div>
  );
};
