import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  FirmwareService,
  FirmwareVersion,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/FirmwareService';
import { IFirmwareService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/IFirmwareService';
import { IRcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/IRcm21ConfigService';
import {
  MultisensorChannelOffsetParams,
  MultisensorDetectionThresholdParams,
  MultisensorIntervalParams,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';
import {
  Rcm21ConfigService,
  VersionedOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rcm21VersionedService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/VersionedOptions/^1.6.0.34/Rcm21VersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { SensorType } from '../../../../enums';
import { getSensorType } from '../../../../utils';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { getVersionService, parseFirmware, sensorTypeToRcm21 } from '../../../device-details/utils';
import HysteresisForm from './hysteresis';
import IntervalForm from './interval';
import OffsetForm from './offset';
import { initialConfig, Rcm21ConfigParams, Rcm21ParamsEnum } from './types';

type Rcm21ConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const Rcm21ConfigForm: React.FC<Rcm21ConfigFormProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<Rcm21ConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const [versionService, setVersionService] = useState<string>('');
  const [currentFirmware, setCurrentFirmware] = useState<string>('');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  useEffect(() => {
    const getFirmwareVersion = async () => {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const firmwareService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IFirmwareService>(
        FirmwareService.ServiceName,
      );
      const currentFirmware = await firmwareService.GetFirmwareVersionAsync(device);

      handleGetParamsResponse<FirmwareVersion>(currentFirmware, () => {
        setCurrentFirmware(parseFirmware(currentFirmware as FirmwareVersion));
      });

      turnOffBackdrop();
    };

    if (firmwareService) getFirmwareVersion();
  }, [firmwareService]);

  const sensorType = useMemo(() => {
    if (channel) return getSensorType(channel);
  }, [channel]);

  const getDefaultParams = async (panel: Rcm21ParamsEnum) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcm21ConfigService>(
      Rcm21ConfigService.ServiceName,
    );

    const versionedOptions: VersionedOptions = await configService.VersionedConfigurationOptions();

    const serviceVersion = await getVersionService(currentFirmware, versionedOptions);
    setVersionService(serviceVersion);

    switch (panel) {
      case Rcm21ParamsEnum.MULTISENSOR_INTERVAL: {
        if (channel) {
          const interval = await configService.GetFramesIntervalAsync(device, channel.Number);

          handleGetParamsResponse<MultisensorIntervalParams>(interval, () => {
            setConfig({ ...initialConfig, [panel]: interval as MultisensorIntervalParams });
            setOpen(true);
          });
        }
        break;
      }
      case Rcm21ParamsEnum.HYSTERESIS: {
        if (channel && sensorType) {
          const detectionThreshold = await configService.GetDetectionThresholdDataAsync(
            device,
            sensorTypeToRcm21[sensorType],
          );

          handleGetParamsResponse<MultisensorDetectionThresholdParams>(detectionThreshold, () => {
            setConfig({ ...initialConfig, [panel]: detectionThreshold as MultisensorDetectionThresholdParams });
            setOpen(true);
          });
        }
        break;
      }
      case Rcm21ParamsEnum.OFFSET: {
        if (channel && sensorType) {
          const offset = await (versionedOptions[serviceVersion] as Rcm21VersionedService).GetChannelOffsetAsync(
            device,
            sensorTypeToRcm21[sensorType],
          );

          handleGetParamsResponse<MultisensorChannelOffsetParams>(offset, () => {
            setConfig({ ...initialConfig, [panel]: offset as MultisensorChannelOffsetParams });
            setOpen(true);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: Rcm21ParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case Rcm21ParamsEnum.MULTISENSOR_INTERVAL: {
        return channel ? <IntervalForm device={device} channel={channel} interval={config[activeConfigName]} /> : null;
      }
      case Rcm21ParamsEnum.OFFSET: {
        return <OffsetForm device={device} versionService={versionService} offset={config[activeConfigName]} />;
      }
      case Rcm21ParamsEnum.HYSTERESIS: {
        return <HysteresisForm device={device} versionService={versionService} hysteresis={config[activeConfigName]} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.Rcm21Params.HysteresisParameters')}
                onClick={() => handleOpen(Rcm21ParamsEnum.HYSTERESIS)}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rcm21Params.MultisensorIntervalParameters')}
                onClick={() => handleOpen(Rcm21ParamsEnum.MULTISENSOR_INTERVAL)}
              />
              {currentFirmware >= '^1.6.0.34' && sensorType === SensorType.Temperature && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.Rcm21Params.OffsetParameters')}
                  onClick={() => handleOpen(Rcm21ParamsEnum.OFFSET)}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rcm21ConfigForm;
