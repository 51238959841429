import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, IconLocation3, IconState, IconTime, IconWeather, NavHeader, Page } from '../../../../../components';
import ConditionListItem from '../../../../../components/conditions/condition-list-item';
import { ChosenConditionType } from '../enums';
import './index.scss';

type ConditionListProps = {
  setConditionType: (type: ChosenConditionType) => void;
  isEdit: boolean;
  goToSummary: () => void;
};

export const ConditionList: React.FC<ConditionListProps> = ({ setConditionType, isEdit, goToSummary }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('action');

  type BoxType = {
    icon: ReactNode;
    title: string;
    content: string;
    onClick: () => void;
    disabled: boolean;
  };

  const automaticTypes: BoxType[] = useMemo(
    () => [
      {
        icon: <IconTime />,
        title: t('trigger.type.time.header'),
        content: t('trigger.type.time.content'),
        onClick: () => setConditionType(ChosenConditionType.TIME),
        disabled: false,
      },
      {
        icon: <IconState />,
        title: t('trigger.type.state.header'),
        content: t('trigger.type.state.content'),
        onClick: () => setConditionType(ChosenConditionType.STATE),
        disabled: false,
      },
      {
        icon: <IconTime />,
        title: t('trigger.type.price.header'),
        content: t('trigger.type.price.content'),
        onClick: () => setConditionType(ChosenConditionType.PRICE),
        disabled: false,
      },
      {
        icon: <IconLocation3 />,
        title: t('trigger.type.location.header'),
        content: t('trigger.type.location.content'),
        onClick: () => setConditionType(ChosenConditionType.LOCATION),
        disabled: true,
      },
      {
        icon: <IconWeather />,
        title: t('trigger.type.weather.header'),
        content: t('trigger.type.weather.content'),
        onClick: () => setConditionType(ChosenConditionType.WEATHER),
        disabled: true,
      },
    ],
    [t],
  );

  const onNavHeaderClick = () => {
    if (isEdit) {
      setConditionType(ChosenConditionType.STATE);
      goToSummary();
    } else {
      navigate(-1);
    }
  };

  return (
    <Page
      header={
        <>
          <NavHeader onClick={onNavHeaderClick} />
          <Header title={t('trigger.conditions.select')} isUnderline />
        </>
      }
    >
      <h2 className="condition-list__title">{t('trigger.if')}</h2>
      <div className="grid-list-24">
        {automaticTypes
          .filter((x) => !x.disabled)
          .map((item) => (
            <ConditionListItem key={item.title} {...item} />
          ))}
      </div>
    </Page>
  );
};
