import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconEdit, Input } from '../../../../../../../components';
import { DialogValuePicker } from '../../../../../../../components/dialog-value-picker';

type PositionActionProps = {
  value: number;
  bindingIndex: number;
  bindingListIndex: number;
  listName: string;
};

export const PositionAction: React.FC<PositionActionProps> = ({ value, bindingIndex, bindingListIndex, listName }) => {
  const { t } = useTranslation('device-settings');
  const { setValue } = useFormContext();
  const [popup, setPopup] = useState<boolean>(false);

  const handleSetPosition = (time: number) => {
    setValue(`bindings.${bindingIndex}.${listName}.${bindingListIndex}.position`, time);
  };

  return (
    <>
      <Input
        defaultValue={value.toString()}
        value={value.toString()}
        required
        readOnly
        label={`${t('bindings.position')} (%)`}
        onClickInput={() => setPopup(true)}
        endIcon={<IconEdit />}
        reverse
      />
      {popup && (
        <DialogValuePicker
          open
          setOpen={setPopup}
          title={`${t('bindings.position')} (%)`}
          onSave={(value) => handleSetPosition(value)}
          value={value}
          unit="%"
        />
      )}
    </>
  );
};
