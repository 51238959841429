import { useEffect, useMemo, useRef, useState } from 'react';
import { Api } from 'lavva.exalushome';
import { PictureInPictureService, IPictureInPictureService } from 'lavva.exalushome/build/js/Services/PictureInPicture';

const PiPSupported = 'pictureInPictureEnabled' in document;

export const useVideo = () => {
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(true);
  const [nativePiPSupported, setNativePiPSupported] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const pipService = useMemo(() => Api.Get<IPictureInPictureService>(PictureInPictureService.ServiceName), []);

  const checkIfNativePiPSupported = async () => {
    try {
      const supported: boolean = await pipService.IsSupported();
      setNativePiPSupported(supported);
    } catch (error) {
      console.log('Pip error', error);
    }
  };

  const onEnteredPictureInPicture = () => {
    console.log('OnEnteredPictureInPicture');
    setFullScreen(true);
    setButtonsVisible(false);
  };

  const onExitedPictureInPicture = () => {
    console.log('OnExitedPictureInPicture');
    setFullScreen(false);
    setButtonsVisible(true);
  };

  useEffect(() => {
    pipService?.OnEnteredPictureInPicture.Subscribe(onEnteredPictureInPicture);
    pipService?.OnExitedPictureInPicture.Subscribe(onExitedPictureInPicture);

    return () => {
      pipService?.OnEnteredPictureInPicture.Unsubscribe(onEnteredPictureInPicture);
      pipService?.OnExitedPictureInPicture.Unsubscribe(onExitedPictureInPicture);
    };
  }, [pipService]);

  useEffect(() => {
    if (pipService) checkIfNativePiPSupported();
  }, [pipService]);

  const handleEsc = (event) => {
    if (event.key === 'Escape') setFullScreen(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);

    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, [handleEsc]);

  const handlePipClick = async () => {
    if (videoRef.current) {
      if (nativePiPSupported) {
        await pipService.RequestPictureInPictureAsync();
      } else {
        try {
          if (document.pictureInPictureElement) {
            await document.exitPictureInPicture();
          } else {
            await videoRef.current.requestPictureInPicture();
          }
        } catch (err) {
          // Video failed to enter/leave Picture-in-Picture mode.
        }
      }
    }
  };

  const toggleVideo = () => {
    if (isPaused) {
      videoRef.current?.play();
      setIsPaused(false);
    } else {
      videoRef.current?.pause();
      setIsPaused(true);
    }
  };

  return {
    videoRef,
    pipSupported: nativePiPSupported || PiPSupported,
    handlePipClick,
    fullScreen,
    setFullScreen,
    toggleVideo,
    isPaused,
    buttonsVisible,
  };
};
