import { useTranslation } from 'react-i18next';
import { InitiateButtonRectangle } from '../../../../../../../components';
import { useSetPartialControlMode } from '../../../../../api/gate/gate.hooks';
import { useChannelsStateStore } from '../../../../../store/channels-state';
import { ChannelGateType, ChannelGateStateType, ChannelType } from '../../../../../types';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';

type PartialModeProps = {
  channel: ChannelType;
};

export const PartialMode: React.FC<PartialModeProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { mutate } = useSetPartialControlMode();
  const { partialControlModeSupported } = useGateSupportedFeatures(channel);
  const state = useChannelsStateStore((s) => s.channelState[channel.id]) as ChannelGateStateType | null;

  const handleSetPartialMode = () => {
    mutate({
      channelId: channel.id,
      deviceId: channel.deviceId,
      value: !state?.partialControlModeStatus,
    });
  };

  if (!(channel.data as ChannelGateType).isPartialControlModeVisible || !partialControlModeSupported) return null;

  return (
    <InitiateButtonRectangle
      isOn={!!state?.partialControlModeStatus}
      onClickControl={handleSetPartialMode}
      className="m-t-16"
    >
      {t('partialMode')}
    </InitiateButtonRectangle>
  );
};
