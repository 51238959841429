import React, { useState, PointerEvent, useEffect } from 'react';
import * as uuid from 'uuid';
import { GateStateTask } from '../../../../../../../../types/actions';
import { GateChannelSettings } from '../../../../../../../../components/action-create/channel-settings/gate';
import { useLavvaCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, GateActionInternal, SceneTaskType, Task } from '../../../../types';

export const GateSettings: React.FC = () => {
  const { channels, taskVariant } = useLavvaCreateActionContext();
  const [gateAction, setGateAction] = useState<GateActionInternal>(GateActionInternal.Open);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      const gateTaskState = (task.taskParams as DeviceTaskParams).state as GateStateTask;
      const action = gateTaskState.action;
      setGateAction(gateTaskState.action);

      switch (action) {
        case GateActionInternal.Open:
          setSliderValue(0);
          break;
        case GateActionInternal.Closed:
          setSliderValue(100);
          break;
        case GateActionInternal.Tilt:
          setGateAction(GateActionInternal.Tilt);
          break;
        default:
          setGateAction(GateActionInternal.Unknown);
          if (gateTaskState.position !== null) setSliderValue(gateTaskState.position);
      }
    }
  }, [task]);

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    setGateAction(GateActionInternal.Unknown);
    setSliderValue(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGateAction(GateActionInternal.Unknown);
    setSliderValue(Number(event.target.value));
  };

  const handleConstPercentageChange = (pos: number) => {
    setGateAction(GateActionInternal.Unknown);
    setSliderValue(pos);
  };

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task: Task = {
        id: uuid.v4(),
        taskType: SceneTaskType.ChannelState,
        taskParams: {
          state: {
            action: gateAction,
            position: gateAction === GateActionInternal.Unknown ? sliderValue : null,
          } as GateStateTask,
          channel: channels[i],
          variant: taskVariant,
        } as DeviceTaskParams,
      };

      finalizeDeviceTaskSubmit(task, i === channels.length - 1);
    }
  };

  return (
    <GateChannelSettings
      handleConstPercentageChange={handleConstPercentageChange}
      handleSliderEvent={handleSliderEvent}
      handleSubmit={handleSubmit}
      sliderValue={sliderValue}
      gateAction={gateAction}
      onChange={onChange}
      setGateAction={setGateAction}
      setSliderValue={setSliderValue}
    />
  );
};
