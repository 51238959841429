import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomBackdrop, StatusScreen } from '../../components';
import { useBackdropContext } from '../../context/backdrop';
import { useInvitationsOperations } from '../../hooks/invitations/use-invitations-operations';
import { useNotification } from '../../hooks/notifications/use-notification';
import { useNotificationsOperations } from '../../hooks/notifications/use-notifications-operations';
import { useRefetchData } from '../../hooks/refresh-data';
import { NotificationTypeEnum, StatusTypeEnum } from '../../types';
import InvitationReceivedConfirmation from './confirmation';

const InvitationReceived: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('invitation');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { notificationId } = useParams<{ notificationId: string }>();
  const { notification, loading } = useNotification(notificationId);
  const { markAsHidden } = useNotificationsOperations();
  const { acceptInvitation, rejectInvitation } = useInvitationsOperations();
  const { refetchAll } = useRefetchData();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const data = notification?.data.type === NotificationTypeEnum.INVITATION ? notification.data : null;
  const correlationId = notification?.correlationId || null;

  const onAccept = () => {
    if (!data?.invitationId || !correlationId) return;

    acceptInvitation(data?.invitationId, () => {
      markAsHidden(correlationId, {
        onSuccess: () => {
          turnOnBackdrop();

          setTimeout(() => {
            turnOffBackdrop();
            refetchAll();
            setShowConfirmation(true);
          }, 3000);
        },
      });
    });
  };

  const onReject = () => {
    if (!data?.invitationId || !correlationId) return;

    turnOnBackdrop();
    rejectInvitation(data?.invitationId, () => {
      markAsHidden(correlationId, {
        onSuccess: () => {
          turnOffBackdrop();
          navigate(-1);
        },
      });
    });
  };

  if (showConfirmation) return <InvitationReceivedConfirmation />;
  if (loading) return <CustomBackdrop />;
  if (!data) return null;

  return (
    <StatusScreen
      header={t('received.title')}
      subTitle={t('received.message', { creatorName: data.userName, installationName: data.installationName })}
      note={t('received.expirationMessage')}
      type={StatusTypeEnum.JOIN}
      secondaryBackground
      onPrimaryBtnClick={onAccept}
      primaryBtnText={t('received.acceptInvitation')}
      onSecondaryBtnClick={onReject}
      secondaryBtnText={t('received.rejectInvitation')}
    />
  );
};

export default InvitationReceived;
