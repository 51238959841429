import { useLoggedUserStore } from '../../store/logged-user';
import '../users';

export const useLoggedUser = () => {
  const user = useLoggedUserStore((s) => s.user);
  const permissions = useLoggedUserStore((s) => s.permissions);

  return {
    user,
    ...permissions,
  };
};
