import React from 'react';
import { ChannelType, GateKindParam } from '../../../../types/channel';
import { GateActionControlBox } from './action';
import { GateChannelControlBox } from './channel';

type GateControlBoxCommonProps = {
  isListItem?: boolean;
};

type ChannelParams = GateControlBoxCommonProps & {
  kind: GateKindParam.Channel;
  channel: ChannelType;
};

type ActionParams = GateControlBoxCommonProps & {
  kind: GateKindParam.Action;
  channels: ChannelType[];
};

type GateControlBoxProps = ChannelParams | ActionParams;

export const GateControlBox: React.FC<GateControlBoxProps> = (props) => {
  const { kind, isListItem } = props;

  return kind === GateKindParam.Channel ? (
    <GateChannelControlBox isListItem={isListItem} channel={props.channel} />
  ) : (
    <GateActionControlBox isListItem={isListItem} channels={props.channels} />
  );
};
