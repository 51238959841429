import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ROUTES } from '../../../../../routes';
import { CentralActionType } from '../../../modules/dashboard/types';
import { CentralActionBoxIcon } from '../box-icon';
import { CentralActionControl } from '../control';
import './index.scss';

type CentralActionBoxProps = {
  centralAction: CentralActionType;
  isListItem?: boolean;
  isDraggable?: boolean;
};

export const CentralActionBox: React.FC<CentralActionBoxProps> = ({
  centralAction,
  isListItem = false,
  isDraggable,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames('action-box action-box--exalus', {
        'action-box--list-item': isListItem,
      })}
    >
      <div className="action-box__content">
        <div
          onClick={() =>
            navigate(
              centralAction.groupId
                ? ROUTES.CentralAction(centralAction.type, centralAction.groupId)
                : ROUTES.CentralActionByType(centralAction.type),
            )
          }
          className={classNames('action-box__head', { 'action-box__head--box': !isListItem })}
        >
          <CentralActionBoxIcon type={centralAction.type} />
          <h4 className="action-box__head-action-name text-ellipsis">{centralAction.name}</h4>
        </div>
      </div>
      {!isDraggable && <CentralActionControl centralAction={centralAction} />}
    </div>
  );
};
