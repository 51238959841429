import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  ControllerChatSender,
  IControllerChatMessage,
} from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/ControllerChat/IControllerChat';
import { ChatType, IMessage } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { IconHomeCircle } from '../../../../../../components';
import DotsLoader from '../../../../../../components/dots-loader';
import { useInstallationContext } from '../../../../../../context/installation';
import { hexToRgb } from '../../../../../../utils/helpers';
import { ChatMessage } from '../../types';

type ComponentProps<T> = {
  chat: ChatMessage<T>[];
  chatType: ChatType;
};

export const ChatList = <T extends IMessage | IControllerChatMessage>({ chat, chatType }: ComponentProps<T>) => {
  const { t } = useTranslation('chat');
  const { selectedInstallation } = useInstallationContext();

  const emptyGroupLabel = useMemo(() => {
    switch (chatType) {
      case ChatType.ControllerChat:
        return 'home';
      case ChatType.FamilyChat:
        return 'family';
      default:
        return '';
    }
  }, [chatType]);

  return (
    <>
      {chat.length ? (
        chat.map((chat, i) => (
          <div key={i}>
            <div className="group-date">{chat.dateGroup}</div>
            {chat.messages.map((msg, j) => {
              const sender = (msg as IControllerChatMessage).Sender;
              const messageText = msg.MessageText;

              return (
                <div
                  className="message-container"
                  key={j}
                  style={{
                    justifyContent: sender === ControllerChatSender.Controller ? 'flex-start' : 'flex-end',
                  }}
                >
                  <div className="message-with-icon">
                    {sender === ControllerChatSender.Controller && <IconHomeCircle />}
                    <div>
                      <span className="message-date">{`${
                        chatType === ChatType.ControllerChat
                          ? sender === ControllerChatSender.Controller
                            ? `${t('home')}, `
                            : `${msg.SenderName ? `${msg.SenderName}, ` : ''}`
                          : `${msg.SenderName ? `${msg.SenderName}, ` : ''}`
                      }${msg.DateTime ? format(new Date(msg.DateTime), 'HH:mm') : ''}`}</span>
                      <div
                        className={classNames('message', { dots: messageText === 'DOTS' })}
                        style={{
                          ...(selectedInstallation
                            ? {
                                ...(sender === ControllerChatSender.Controller
                                  ? { backgroundColor: `rgba(${hexToRgb(selectedInstallation?.hexColor)}, 0.1)` }
                                  : {}),
                                border: `1px solid rgba(${hexToRgb(selectedInstallation?.hexColor)}, 0.3)`,
                              }
                            : {}),
                        }}
                      >
                        {messageText !== 'DOTS' ? messageText : <DotsLoader />}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))
      ) : (
        <div className="empty-chat-message">
          {t('startConversation.label', { type: t(`startConversation.${emptyGroupLabel}`) })}
        </div>
      )}
    </>
  );
};
