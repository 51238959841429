import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';

export enum SharedItemType {
  Undefined = 'UNDEFINED',
  Group = 'GROUP',
  Channel = 'CHANNEL',
  Installation = 'INSTALLATION',
}

export enum SharingType {
  Undefined = 'UNDEFINED',
  Permanent = 'PERMANENT',
  Timed = 'TIMED',
  Quantitative = 'QUANTITATIVE',
}

export type ChannelSharingInfo = {
  channelId: string;
  channelName: string;
  iconName: string;
  channelType: ChannelTypeInternal;
  sharedFrom: Date;
  sharedUntil: Date;
  grantedUses?: number;
};
