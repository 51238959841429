import React from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { IconInfo, IconRecovery } from '../../../../../components';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ChangeDeviceNameMutation,
  ChangeDeviceNameMutationVariables,
  ChannelTypeInternal,
  EditUserChannelMutation,
  EditUserChannelMutationVariables,
} from '../../../../../data-access/gql-types/graphql';
import { EDIT_USER_CHANNEL } from '../../../../../data-access/mutations/channels';
import { CHANGE_DEVICE_NAME } from '../../../../../data-access/mutations/devices';
import { useMutationErrors } from '../../../../../hooks';
import { useErrors } from '../../../../../hooks/errors';
import { useRefetchData } from '../../../../../hooks/refresh-data';
import { MutationErrorType, publishStatusMap } from '../../../../../types';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useBlindCalibrate, useBlindSwapOut } from '../../../api/blind/blind.hooks';
import { useDeviceSwapIn, useUpdateFirmware } from '../../../api/device/device.hooks';
import { useGateCalibrate } from '../../../api/gate/gate.hooks';
import { useUpdateRecoveryFirmware } from '../../../api/recovery/recovery.hooks';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { ChannelGateType } from '../../../types';
import { useUpdateCommonLavvaState } from '../../../utils/channels/subscriptions/common-lavva-state';
import { ConfigurationHookParams, ConfigurationHook, ChannelGeneralConfiguration } from '../types';

export const useConfiguration = ({ channel, device, devices }: ConfigurationHookParams): ConfigurationHook => {
  const { t: ts } = useTranslation('device-settings');
  const { t: td } = useTranslation('channel-settings');
  const { t: tc } = useTranslation('common');
  const { handleErrors } = useMutationErrors(MutationErrorType.Channel);
  const { selectedInstallationId } = useInstallationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const calibrationBlind = useBlindCalibrate();
  const calibrationGate = useGateCalibrate();
  const updateFirmware = useUpdateFirmware();
  const updateRecoveryFirmware = useUpdateRecoveryFirmware();
  const { setDeviceAsUpdating } = useUpdateCommonLavvaState();
  const { updateChannelDetails, setChannelList } = useDevicesAndChannelsContext();
  const { refetchDevices } = useRefetchData();
  const [changeDeviceName] = useMutation<ChangeDeviceNameMutation, ChangeDeviceNameMutationVariables>(
    CHANGE_DEVICE_NAME,
  );
  const [editUserChannel] = useMutation<EditUserChannelMutation, EditUserChannelMutationVariables>(EDIT_USER_CHANNEL);
  const swapIn = useDeviceSwapIn();
  const swapOutBlind = useBlindSwapOut();
  const { handleLavvaResolve } = useErrors();

  const autoCalibrate = () => {
    if (channel) {
      if (channel.data.type === ChannelTypeInternal.Blind) {
        calibrationBlind.mutate({ deviceId: channel.deviceId, channelId: channel.id });
      } else if (channel.data.type === ChannelTypeInternal.Gate) {
        calibrationGate.mutate({ deviceId: channel.deviceId, channelId: channel.id });
      }
    }
  };

  const editChannel = (values: ChannelGeneralConfiguration) => {
    if (channel) {
      turnOnBackdrop();

      editUserChannel({
        variables: {
          input: {
            channelId: channel.id,
            alias: values.name,
            iconName: values.icon_name,
            isVisible: !values.hidden,
            ...(channel.data.type === ChannelTypeInternal.Gate
              ? {
                  personalization: {
                    gate: {
                      isQuickControl: (channel.data as ChannelGateType).isQuickControl,
                      isVentVisible: false,
                      isPartialControlModeVisible: !!values.isPartialControlModeVisible,
                      isDailyModeVisible: !!values.isDailyModeVisible,
                    },
                  },
                }
              : {}),
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();
          if (!data || !data.editUserChannel) {
            toastError({ content: tc('errors.somethingWentWrong') });
            return;
          }

          if (data.editUserChannel.result?.ok) {
            setChannelList((prev) => {
              const tempList = cloneDeep(prev);
              const index = tempList.findIndex((x) => x.id === channel.id);

              if (index !== -1) {
                tempList[index].alias = values.name;
                tempList[index].iconName = values.icon_name;
                tempList[index].isVisible = !values.hidden;

                if (tempList[index].data.type === ChannelTypeInternal.Gate) {
                  (tempList[index].data as ChannelGateType).isPartialControlModeVisible =
                    !!values.isPartialControlModeVisible;
                  (tempList[index].data as ChannelGateType).isDailyModeVisible = !!values.isDailyModeVisible;
                }
              }

              return tempList;
            });

            updateChannelDetails((prev) => {
              const temp = cloneDeep(prev);
              if (temp) {
                temp.alias = values.name;
                temp.iconName = values.icon_name;
                temp.isVisible = !values.hidden;

                if (temp.data.type === ChannelTypeInternal.Gate) {
                  (temp.data as ChannelGateType).isPartialControlModeVisible = !!values.isPartialControlModeVisible;
                  (temp.data as ChannelGateType).isDailyModeVisible = !!values.isDailyModeVisible;
                }
              }

              return temp;
            });
            toastSuccess({ content: td('toast.editConfigurationSuccess') });
          } else {
            handleErrors(data.editUserChannel.errors || []);
          }
        },
        onError: () => {
          toastError({ content: td('toast.editError') });
        },
      });
    } else {
      toastError({ content: td('toast.editError') });
    }
  };

  const swapInDevice = () => {
    if (channel) {
      turnOnBackdrop();
      swapIn.mutate(
        { deviceId: channel.deviceId },
        {
          onSuccess: ({ data }) => {
            turnOffBackdrop();
            handleLavvaResolve({
              status: data.publishStatus,
              deviceId: channel.deviceId,
              onSuccess: () => {
                toastSuccess({ content: `${td('toast.swapInSuccess')}` });
              },
            });
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  const swapOut = () => {
    if (channel) {
      turnOnBackdrop();
      swapOutBlind.mutate(
        { deviceId: channel.deviceId, channelId: channel.id },
        {
          onSuccess: ({ data }) => {
            handleLavvaResolve({
              status: data.publishStatus,
              deviceId: channel.deviceId,
              onSuccess: () => {
                toastSuccess({ content: `${td('toast.swapOutSuccess')}` });
                turnOffBackdrop();
              },
            });
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  const updateRecoveryDevice = () => {
    if (device) {
      updateRecoveryFirmware.mutate(
        { deviceId: device.id },
        {
          onSuccess: ({ data }) => {
            handleLavvaResolve({
              status: data,
              deviceId: device.id,
              onSuccess: async () => {
                await refetchDevices();
                setDeviceAsUpdating(device.id);
                toastSuccess({ icon: <IconRecovery iconToastError />, content: ts('updateDeviceToast') });
              },
            });
          },
        },
      );
    }
  };

  const updateDevice = () => {
    if (device) {
      updateFirmware.mutate(
        { deviceId: device.id },
        {
          onSuccess: ({ data }) => {
            handleLavvaResolve({
              status: data.publishStatus,
              deviceId: device.id,
              onSuccess: async () => {
                await refetchDevices();
                setDeviceAsUpdating(device.id);
                toastSuccess({ icon: <IconInfo />, content: ts('updateDeviceToast') });
              },
            });
          },
        },
      );
    }
  };

  const updateDevices = () => {
    const connectedDevices: string[] = [];
    if (devices?.length) {
      devices.forEach((device) => {
        const isDeviceConnected = true;
        if (isDeviceConnected) {
          connectedDevices.push(device.id);
          updateFirmware.mutate(
            { deviceId: device.id },
            {
              onSuccess: ({ data }) => {
                handleLavvaResolve({
                  status: data.publishStatus,
                  deviceId: device.id,
                  onSuccess: async () => {
                    await refetchDevices();
                    toastSuccess({ icon: <IconInfo />, content: ts('updateDevicesToast') });
                  },
                });
              },
            },
          );

          updateRecoveryFirmware.mutate(
            { deviceId: device.id },
            {
              onSuccess: ({ data }) => {
                handleLavvaResolve({
                  status: data,
                  deviceId: device.id,
                  onSuccess: async () => {
                    await refetchDevices();
                    toastSuccess({ icon: <IconRecovery iconToastError />, content: ts('updateDeviceToast') });
                  },
                });
              },
            },
          );
        }
      });
    }
  };

  const setDeviceName = (name: string) => {
    if (device) {
      turnOnBackdrop();
      changeDeviceName({
        variables: { installationId: selectedInstallationId, deviceId: device.id, name },
        onCompleted: (data) => {
          turnOffBackdrop();
          handleLavvaResolve({
            status: publishStatusMap[data.changeDeviceName.publishStatus],
            deviceId: device.id,
          });
        },
        onError: () => {
          turnOffBackdrop();
          toastError({ content: td('toast.editError') });
        },
      });
    }
  };

  return {
    autoCalibrate,
    editChannel,
    swapInDevice,
    swapOut,
    updateRecoveryDevice,
    updateDevice,
    updateDevices,
    setDeviceName,
    calibrateLoading: calibrationBlind.isLoading || calibrationGate.isLoading,
    updateDeviceLoading: updateFirmware.isLoading,
  };
};
