import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { IDevice, ManuallyPairedDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { FieldChangeResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useBackdropContext } from '../../../context/backdrop';
import { useExalusContext } from '../context';
import { useExalusServicesContext } from '../context/services';

export const useExalusDevices = (): UseQueryResult<IDevice[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { devicesApi } = useExalusServicesContext();

  return useQuery<IDevice[]>(
    ['exalus-devices', selectedExalusCredentials?.id, synchronized],
    async () => {
      return await devicesApi.GetDevicesAsync();
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusDevice = (id: string): UseQueryResult<IDevice | undefined> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { devicesApi } = useExalusServicesContext();

  return useQuery<IDevice | undefined>(
    ['exalus-device', id, selectedExalusCredentials?.id, synchronized],
    async () => {
      return devicesApi.GetDevice(id);
    },
    {
      enabled: !!id && connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusDeviceChangeName = (
  device: IDevice | undefined,
): UseMutationResult<FieldChangeResult | undefined, unknown, string> => {
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<FieldChangeResult | undefined, unknown, string>(
    ['exalus-channel-change-name'],
    async (name) => {
      if (device) {
        turnOnBackdrop();
        return await device.ChangeDeviceNameAsync(name);
      }
    },
    { onSuccess: () => turnOffBackdrop(), onError: () => turnOffBackdrop() },
  );
};

export const useExalusFoundDevices = (): UseQueryResult<IDevice[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { devicesApi } = useExalusServicesContext();

  return useQuery<IDevice[]>(
    ['exalus-found-devices', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const devices = await devicesApi.GetFoundDevicesAsync();
      turnOffBackdrop();
      return devices;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusManuallyDevices = (): UseQueryResult<ManuallyPairedDevice[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { devicesApi } = useExalusServicesContext();

  return useQuery<ManuallyPairedDevice[]>(
    ['exalus-manually-devices', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const devices = await devicesApi.GetDevicesForManualPairingAsync();
      turnOffBackdrop();
      return devices;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};
