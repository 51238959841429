import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { ControlWrapper, Slider } from '../../../../../components';
import { defaultFavouritePositionList } from '../../../../../const';
import {
  Action,
  FavouritePositionType,
  SetFavouriteActionPositionsMutation,
  SetFavouriteActionPositionsMutationVariables,
} from '../../../../../data-access/gql-types/graphql';
import { SET_FAVOURITE_ACTION_POSITIONS } from '../../../../../data-access/mutations/action';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { BlindDetailsControls } from '../../../../../components/channel-details-controls/blind/controls';
import { BlindDetailsFavourites } from '../../../../../components/channel-details-controls/blind/favourites';
import { useCoverAction } from '../../../hooks';
import { ChannelType, FavouritePositions } from '../../../types';

type CoverActionProps = {
  channels: ChannelType[];
  action: Action;
  favouritePositions: FavouritePositions | null;
};

const CoverAction: React.FC<CoverActionProps> = ({ channels, favouritePositions, action }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleAction } = useCoverAction(channels);
  const [sliderValue, setSliderValue] = useState<number>(50);
  const [favouritePositionList, setFavouritePositionList] = useState<number[]>([]);

  const [setFavouritePositions] = useMutation<
    SetFavouriteActionPositionsMutation,
    SetFavouriteActionPositionsMutationVariables
  >(SET_FAVOURITE_ACTION_POSITIONS);

  useEffect(() => {
    setFavouritePositionList(
      favouritePositions?.length || favouritePositions !== null
        ? favouritePositions.map((position) => (position > 1 ? position : Math.round(position * 100)))
        : defaultFavouritePositionList,
    );
  }, [defaultFavouritePositionList, favouritePositions, setFavouritePositionList]);

  const onFavouriteHold = (fieldIndex: number) => {
    const newFavouritePositionList = favouritePositionList.map((el, index) =>
      index !== fieldIndex ? el : sliderValue,
    );

    if (newFavouritePositionList.length) {
      setFavouritePositionList(newFavouritePositionList);
      setFavouritePositions({
        variables: {
          input: {
            favouritePositions: newFavouritePositionList.map((position) => ({
              favouritePositionType: FavouritePositionType.Position,
              value: position / 100,
            })),
            actionId: action?.id,
          },
        },
        onCompleted: (data) => {
          if (data.setFavouriteActionPositions.ok) {
            toastSuccess({ content: t('favouriteSetSuccess') });
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
            // TODO errors
          }
        },
      });
    }
  };

  const onChange = (value: string | number) => {
    setSliderValue(typeof value === 'number' ? value : value === 'close' ? 100 : 0);
    handleAction(value);
  };

  const onFavouriteClick = (position: number) => onChange(position);

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    handleAction(Number((event.target as HTMLInputElement).value));
  };

  return (
    <>
      <div className="cover-detail-view">
        <ControlWrapper>
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
          />
        </ControlWrapper>
        <BlindDetailsControls
          downButton={{
            handleClick: () => onChange('close'),
          }}
          stopButton={{
            handleClick: () => onChange('stop'),
          }}
          upButton={{
            handleClick: () => onChange('open'),
          }}
        />
      </div>
      <BlindDetailsFavourites
        favouritePositionList={favouritePositionList}
        onFavouriteClick={onFavouriteClick}
        {...(action.id !== 'BLIND' && { onFavouriteHold })}
        lastPosition={sliderValue}
        microventilation={{
          position: 99,
          lastPosition: sliderValue,
          handleMicroventilation: () => onChange(99),
        }}
      />
    </>
  );
};

export default CoverAction;
