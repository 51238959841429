import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconMessage, IconPhone } from '../../../components/icons/scenario';
import { IconAddDevice } from '../../../components/icons/status-screen-icon/add-device';
import { IntegrationDefaultEnum } from '../../../types';
import { supportData } from '../utils/support-data';
import './index.scss';

type SupportContentProps = {
  type: IntegrationDefaultEnum;
  image?: boolean;
};

const SupportContent: React.FC<SupportContentProps> = ({ type, image }) => {
  const { t } = useTranslation('support');
  const data = supportData[type];

  return (
    <div className="support">
      {image && <IconAddDevice />}
      <div className="m-b-24">
        {`${t('supportContent.messageProducts')} `}
        <a href={data.websiteUrl} target="_blank" rel="noopener noreferrer">
          {data.websiteUrl}
        </a>
      </div>
      <div className="m-b-24 max-width-desktop">
        {`${t('supportContent.messageContact')}:`}
        <br />
        <div className="contacts">
          {data.contacts.map((x) => (
            <div key={x.email} className="contact-item">
              {x.phones.map((phone) => (
                <div key={phone.phoneNumber}>
                  <div className="icon">
                    <IconPhone />
                  </div>
                  <a href={`tel:${phone.phoneNumber}`}>{phone.phoneLabel}</a>
                </div>
              ))}
              <div>
                <div className="icon">
                  <IconMessage />
                </div>
                <a rel="noopener noreferrer" href={`mailto:${x.email}`}>
                  {x.email}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {`${t('supportContent.messageUrl')} `}
        <a href={data.supportUrl} target="_blank" rel="noopener noreferrer">
          {data.supportUrl}
        </a>
      </div>
    </div>
  );
};

export default SupportContent;
