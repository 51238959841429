import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hours, Minutes, Seconds } from 'lavva.exalushome/build/js/Helpers';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { SubmitButton } from '../../../../../../../../components';
import { getTimeParts, leadingZero } from '../../../../../../../../utils/helpers';
import { InputString } from '../../../../components/input-string';
import { TimeInput } from '../../../../components/time-input';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';

export const TimerSource: React.FC = () => {
  const { t } = useTranslation('action');
  const { builder } = useExalusCreateActionContext();
  const [daysQuantity, setDaysQuantity] = useState<string>('0');
  const [time, setTime] = useState<string>('00:00:00');
  const { finalizeSequenceSubmit, seq } = useConditionEdit({
    supportedParam: SupportedArgumentTypes.ArgumentAsTimeSpan,
  });

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsTimeSpan;

    if (argument) {
      const time = [argument.hours, argument.minutes, argument.seconds].map(leadingZero).join(':');
      setTime(time);

      setDaysQuantity(argument.days.toString());
    }
  }, [seq]);

  const handleSubmit = () => {
    const timeParts = getTimeParts(time);
    const argument = builder?.ArgumentAsTimeSpan({
      hours: timeParts.hours as Hours,
      minutes: timeParts.minutes as Minutes,
      seconds: timeParts.seconds as Seconds,
      days: parseInt(daysQuantity),
    });

    finalizeSequenceSubmit(argument, -3);
  };

  return (
    <div className="grid-list-24">
      <InputString
        value={daysQuantity}
        setValue={setDaysQuantity}
        label={t('action.create.conditions.sources.schedule.days')}
        inputType="number"
        min={0}
      />
      <TimeInput time={time} setTime={setTime} />
      <SubmitButton onClick={handleSubmit} />
    </div>
  );
};
