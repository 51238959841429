import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { EcoBar } from '../../../../../../components/channel/eco-bar';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../../../../const';
import { OptimizerVectorParameterTypeInternal } from '../../../../../../../../data-access/gql-types/graphql';
import { measurementParametersOptimizer } from '../../../../measurements/utils';
import { Balance } from '../../../current/components/balance';
import { OptimizerCurrentDataMap } from '../../../current/types';
import './index.scss';

type PreviewProps = {
  optimizerData: Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]> | undefined;
};

export const Preview: React.FC<PreviewProps> = ({ optimizerData }) => {
  const { t } = useTranslation('channel-details');

  const getOrderedValue = (key: OptimizerVectorParameterTypeInternal, index: number) => {
    if (!optimizerData?.[key]) return 0;
    if (!optimizerData?.[key].length) return 0;
    return orderBy(optimizerData?.[key], 'index')[index - 1]?.value;
  };

  const renderPhase = (nr: number) => {
    const scrValue = getOrderedValue(OptimizerVectorParameterTypeInternal.ScrFilling, nr);
    const voltage = getOrderedValue(OptimizerVectorParameterTypeInternal.Voltage, nr);
    const activePower = getOrderedValue(OptimizerVectorParameterTypeInternal.ActivePower, nr);

    return (
      <div key={nr} className="phase-preview">
        <div className="phase-title">{`${t('phase')} ${nr}`}</div>
        <div>{`${voltage.toFixed(measurementParametersOptimizer.VOLTAGE.fixed)} ${
          measurementParametersOptimizer.VOLTAGE.unit
        }`}</div>
        <div>{`${(activePower / 1000).toFixed(measurementParametersOptimizer.ACTIVE_POWER.fixed)} ${
          measurementParametersOptimizer.ACTIVE_POWER.unit
        }`}</div>
        <div className="load m-t-4">
          <EcoBar
            value={scrValue !== WRONG_MEASUREMENT_VALUE ? Math.abs(scrValue) : 0}
            maxValue={100}
            disabled={scrValue === WRONG_MEASUREMENT_VALUE}
          />
          {`${
            scrValue !== WRONG_MEASUREMENT_VALUE
              ? Math.abs(scrValue).toFixed(measurementParametersOptimizer.SCR_FILLING.fixed)
              : 0
          } ${measurementParametersOptimizer.SCR_FILLING.unit}`}
        </div>
      </div>
    );
  };

  return optimizerData ? (
    <div className="optimizer-preview">
      <div className="phases m-b-8">{[1, 2, 3].map(renderPhase)}</div>
      <Balance
        kind="only-power"
        selectedParameter={OptimizerVectorParameterTypeInternal.ActivePower}
        optimizerData={optimizerData}
      />
    </div>
  ) : null;
};
