import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrors } from '../../../../hooks/errors';
import { useRestClient } from '../../../../utils/clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import { GenericInputTypeBody, GenericInputTypesBatchBody } from './generic-input.types';

export const useSetInputType = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  GenericInputTypeBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GenericInputTypeBody>(
    ['genericInput-setInputType'],
    async (body) => await restClient.post<DeviceResponse>('/genericInput/setInputType', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useSetInputTypesBatch = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  GenericInputTypesBatchBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GenericInputTypesBatchBody>(
    ['genericInput-setInputTypesBatch'],
    async (body) => await restClient.post<DeviceResponse>('/genericInput/setInputTypesBatch', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
