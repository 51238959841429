import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SetLightBrightness } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { DimmerControlHook } from '../types';

export const useDimmerControl = (channel: IDeviceChannel): DimmerControlHook => {
  const handleAction = async (brightness?: number) => {
    const task = new SetLightBrightness();
    if (typeof brightness === 'number' && brightness >= 0) {
      task.Brightness = brightness;
    }
    await channel.ExecuteTaskAsync(task);
  };

  const handleSliderEvent = (value: number) => handleAction(value);

  return {
    handleAction,
    handleSliderEvent,
  };
};
