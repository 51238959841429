import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { Header, NavHeader, Page } from '../../../../../../components';
import ConditionListItem from '../../../../../../components/conditions/condition-list-item';
import { useExalusCreateActionContext } from '../../context';
import { useActionConditions } from '../../hooks/use-action-conditions';
import { ConditionType } from '../../../../../../types/actions';
import { actionConditionTypes } from '../../utils/action-conditions';

const ExalusActionConditionsTypes: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectConditionType } = useActionConditions();
  const { allowedArguments } = useExalusCreateActionContext();

  const getVisibility = (conditionType: ConditionType) => {
    switch (conditionType) {
      case ConditionType.Devices: {
        return !!allowedArguments.find((x) => x.ArgumentType === SupportedArgumentTypes.ArgumentAsDeviceState);
      }
      case ConditionType.Scenarios: {
        return !!allowedArguments.find((x) => x.ArgumentType === SupportedArgumentTypes.ArgumentAsSceneExecuted);
      }
      case ConditionType.Schedule: {
        return !!allowedArguments.find((x) =>
          [
            SupportedArgumentTypes.ArgumentAsAstronomicalClockWithOffset,
            SupportedArgumentTypes.ArgumentAsDaysOfWeek,
            SupportedArgumentTypes.ArgumentAsTime,
            SupportedArgumentTypes.ArgumentAsTimeSpan,
          ].includes(x.ArgumentType),
        );
      }
      default: {
        return false;
      }
    }
  };

  const list = useMemo(() => {
    return actionConditionTypes.map((condition) => {
      return { ...condition, visible: !allowedArguments.length ? true : getVisibility(condition.type) };
    });
  }, [allowedArguments]);

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.conditions.selectCondition')} />
        </>
      }
    >
      <div className="grid-list-24">
        {list
          .filter((x) => x.visible)
          .map((x) => (
            <ConditionListItem
              key={x.title}
              title={t(x.title)}
              content={t(x.content)}
              icon={x.icon}
              className="m-b-24"
              onClick={() => selectConditionType(x.type)}
              noBackgroundIcon
            />
          ))}
      </div>
    </Page>
  );
};

export default ExalusActionConditionsTypes;
