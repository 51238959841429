import { create } from 'zustand';

type NotificationsStore = {
  countOfUnreadNotifications: number;
  setCountOfUnreadNotifications: (count: number) => void;
  incrementCountOfUnreadNotifications: () => void;
  decrementCountOfUnreadNotifications: () => void;
};

export const useNotificationsStore = create<NotificationsStore>((set) => ({
  countOfUnreadNotifications: 0,
  setCountOfUnreadNotifications: (count: number) => set(() => ({ countOfUnreadNotifications: count })),
  incrementCountOfUnreadNotifications: () =>
    set((state) => ({ countOfUnreadNotifications: state.countOfUnreadNotifications + 1 })),
  decrementCountOfUnreadNotifications: () =>
    set((state) => ({
      countOfUnreadNotifications: state.countOfUnreadNotifications === 0 ? 0 : state.countOfUnreadNotifications - 1,
    })),
}));
