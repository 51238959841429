import React, { ReactNode, createContext, useContext, useMemo, useState, useCallback } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { createClient } from 'graphql-ws';
import { ApolloClient, HttpLink, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useKeycloak } from '@react-keycloak/web';
import { environment } from '../environment';
import { useSentry, useErrors } from '../hooks';
import { GraphqlState, Sub } from '../types/grapql';

const { link: networkStatusNotifierLink } = createNetworkStatusNotifier();

type GraphqlProviderProps = {
  children: ReactNode;
};

const initialState: GraphqlState = {
  client: new ApolloClient({
    cache: new InMemoryCache(),
  }),
  API_URL: environment.API_URL || '',
  LAVVA_MANAGER: `${environment.API_URL}graphql/` || '',
  updateBrokerUrl: () => null,
  subs: [],
  addSub: () => null,
};

export const GraphqlContext = createContext(initialState);

export const useGraphqlContext = (): GraphqlState => useContext(GraphqlContext);

export const GraphqlProvider: React.FC<GraphqlProviderProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { handleGraphQLError } = useErrors();
  const [brokerUrl, setBrokerUrl] = useState<string>(initialState.API_URL);
  const [subs, setSubs] = useState<Sub[]>([]);
  const { setSentryTags } = useSentry();

  const addSub = useCallback(
    (sub) => {
      setSubs((prev: Sub[]) => [...prev, sub]);
    },
    [subs],
  );

  const API_URL = useMemo(() => {
    return brokerUrl.endsWith('/') ? brokerUrl : `${brokerUrl}/graphql/`;
  }, [brokerUrl]);

  const LAVVA_MANAGER = useMemo(() => API_URL.replace('graphql/', ''), [API_URL]);

  const authLink = setContext(async ({ operationName }, { headers }) => {
    const token = await Promise.resolve(keycloak.token);
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'APOLLO-QUERY-NAME': operationName,
      },
    };
  });

  const reset = async () => {
    await keycloak.updateToken(-1);
    await client.resetStore();

    await client.setLink(from([onError((error) => handleGraphQLError(error)), link]));
  };

  const wsLink = useMemo(() => {
    return new GraphQLWsLink(
      createClient({
        url: (location.protocol === 'http:' ? API_URL.replace('https', 'ws') : API_URL.replace('https', 'wss')) || '',
        retryAttempts: Infinity, // Infinite reconnection attempts
        shouldRetry: () => true, // Always retry on disconnect
        disablePong: true,
        connectionParams: async () => {
          const token = await Promise.resolve(keycloak.token);
          return { Authorization: `Bearer ${token}` };
        },
        onNonLazyError: async (error) => {
          if (error) {
            await reset();
            window.location.reload();
          }
        },
      }),
    );
  }, [API_URL, keycloak]);

  const link = useMemo(() => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      networkStatusNotifierLink.concat(
        authLink.concat(new HttpLink({ uri: (context) => `${API_URL}?opname=${context.operationName}` })),
      ),
    );
  }, [API_URL, keycloak]);

  const client = useMemo(() => {
    return new ApolloClient({
      link: from([onError((error) => handleGraphQLError(error)), link]),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
      },
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
  }, [link, API_URL, keycloak]);

  keycloak.onAuthRefreshSuccess = async () => {
    await client.resetStore();
  };

  keycloak.onAuthRefreshError = async () => window.location.reload();
  keycloak.onAuthError = async () => window.location.reload();

  const updateBrokerUrl = (url: string) => {
    setBrokerUrl(url);
    setSentryTags({ brokerUrl: url });
  };

  const values = {
    client,
    API_URL,
    LAVVA_MANAGER,
    updateBrokerUrl,
    subs,
    addSub,
  };

  return <GraphqlContext.Provider value={values}>{children}</GraphqlContext.Provider>;
};
