import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ChannelType, SensorType } from '../../../enums';
import { getChannelType, getSensorType } from '../../../utils';
import BlindControls from './blind/controls';
import CameraControls from './camera/controls';
import EntryPhoneControls from './entry-phone/controls';
import FacadeControls from './facade/controls';
import FloodControl from './flood/controls';
import GateControls from './gate/controls';
import HumidityControl from './humidity/controls';
import './index.scss';
import MeasuredBrightnessControl from './measured-brightness/controls';
import MeterControl from './meter/controls';
import MovementControl from './movement/controls';
import PressureControl from './pressure/controls';
import ReedControl from './reed/controls';
import SwitchControls from './switch/controls';
import TemperatureControl from './temperature/controls';
import WindControl from './wind/controls';

type ChannelBoxControlsProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

export const ChannelBoxControls: React.FC<ChannelBoxControlsProps> = ({ channel, isListItem = false }) => {
  switch (getChannelType(channel)) {
    case ChannelType.Switch:
    case ChannelType.Light:
      return <SwitchControls channel={channel} isListItem={isListItem} />;
    case ChannelType.Blind:
      return <BlindControls channel={channel} isListItem={isListItem} />;
    case ChannelType.Facade:
      return <FacadeControls channel={channel} isListItem={isListItem} />;
    case ChannelType.Camera:
      return <CameraControls channel={channel} isListItem={isListItem} />;
    case ChannelType.EntryPhone:
      return <EntryPhoneControls channel={channel} isListItem={isListItem} />;
    case ChannelType.Meter:
      return <MeterControl channel={channel} isListItem={isListItem} />;
    case ChannelType.Gate:
      return <GateControls channel={channel} />;
    case ChannelType.Sensor:
      switch (getSensorType(channel)) {
        case SensorType.Temperature:
          return <TemperatureControl channel={channel} isListItem={isListItem} />;
        case SensorType.Pressure:
          return <PressureControl channel={channel} isListItem={isListItem} />;
        case SensorType.Wind:
          return <WindControl channel={channel} isListItem={isListItem} />;
        case SensorType.Humidity:
          return <HumidityControl channel={channel} isListItem={isListItem} />;
        case SensorType.MeasuredBrightness:
          return <MeasuredBrightnessControl channel={channel} isListItem={isListItem} />;
        case SensorType.Flood:
          return <FloodControl channel={channel} isListItem={isListItem} />;
        case SensorType.Movement:
          return <MovementControl channel={channel} isListItem={isListItem} />;
        case SensorType.Reed:
          return <ReedControl channel={channel} />;
        default:
          return null;
      }
    default:
      return null;
  }
};
