import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useInstallationContext } from '../../context/installation';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { useLoggedUser } from '../../integrations/exalus/hooks/common/logged-user';
import { ROUTES } from '../../routes';
import { IconActions, IconDashboard, IconDeviceList, IconProfile, IconChat } from '../icons';
import './index.scss';

export const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('common');
  const { integrationType } = useInstallationContext();
  const { hasAccessToScenarios, user: exalusUser } = useLoggedUser();
  const isExalus = integrationType === IntegrationType.Exalus;

  const links = [
    {
      href: ROUTES.Root(),
      Icon: IconDashboard,
      title: t('navigation.dashboard'),
    },
    {
      href: ROUTES.Channel(),
      Icon: IconDeviceList,
      title: t('navigation.channels'),
    },
    ...(isExalus && exalusUser?.AccessLevel !== AccessLevel.Guest
      ? [
          {
            href: ROUTES.Chat(),
            Icon: IconChat,
            title: t('navigation.chat'),
          },
        ]
      : []),
    ...(integrationType === IntegrationType.Lavva || hasAccessToScenarios
      ? [
          {
            href: ROUTES.ActionList(),
            Icon: IconActions,
            title: t('navigation.actions'),
          },
        ]
      : []),
    {
      href: ROUTES.Settings(),
      Icon: IconProfile,
      title: t('navigation.settings'),
    },
  ];

  return (
    <nav className={classNames('navigation', { 'with-chat': isExalus })}>
      <ul className="navigation__list">
        {links.map((link) => (
          <li key={link.title} className="navigation__list-item">
            <button
              onClick={() => navigate(link.href)}
              className={classNames('', { 'navigation__list-item--active': pathname === link.href })}
            >
              <link.Icon />
              <p className="nav_title">{link.title}</p>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
