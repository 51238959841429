import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../../../../../../components';
import Info from '../../../../../../components/channel-configuration/info';
import { millisecondsToTime } from '../../../../../../utils/helpers';
import { ChannelGateType, ChannelType } from '../../../../types';

type SignalTimeProps = {
  channel: ChannelType;
  handleSave: (time: string) => void;
};

const TiltTime: React.FC<SignalTimeProps> = ({ channel, handleSave }) => {
  const { t } = useTranslation('configuration');
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const tiltTimeInMs = millisecondsToTime((channel?.data as ChannelGateType)?.tiltTimeInMs || 0);

  return (
    <>
      <Info title={`${t('tiltTime')} (s)`} />
      <Input
        defaultValue={tiltTimeInMs}
        value={tiltTimeInMs}
        required
        readOnly
        label=""
        onClickInput={() => setShowTimePicker(true)}
        endIcon={<IconEdit />}
      />
      <DialogTimePicker
        header={`${t('tiltTime')} (s)`}
        open={showTimePicker}
        setOpen={setShowTimePicker}
        onSave={(value) => handleSave(value)}
        time={tiltTimeInMs}
        showSeconds
        disableHours
        disableMinutes
        minSeconds={1}
        maxSeconds={120}
      />
    </>
  );
};

export default TiltTime;
