import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper, IconConnectionError, Slider } from '../../../../../../../../../components';
import { BlindDetailsControls } from '../../../../../../../../../components/channel-details-controls/blind/controls';
import { IconToastWarning2 } from '../../../../../../../../../components/icons/icon-toast-warning-2';
import { useGatePreciseControl } from '../../../../../hooks/use-gate-precise-control';
import { useGatePreciseDetails } from '../../hooks/use-gate-precise-details';
import Favourites from './favourites';

type GatePreciseDetailsControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const GatePreciseDetailsControls: React.FC<GatePreciseDetailsControlsProps> = ({ channel, active }) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const { open, close, stop, handleSliderEvent } = useGatePreciseControl(channel);
  const {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    errorMessage,
    isWarning,
    position,
    externalLimitSwitchActive,
  } = useGatePreciseDetails(channel);

  useEffect(() => {
    if (typeof position === 'number') {
      if (position === sliderValue && targetValue !== sliderValue) return;
      setSliderValue(position);
      setTargetValue(position);
    }
  }, [position]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setTargetValue(Number(event.target.value));

  return (
    <div
      className={classNames('cover-detail-view', {
        'cover-detail-view--full-space': calibrationNeed,
      })}
    >
      <>
        <ControlWrapper>
          {errorMessage ? (
            <IconToastWarning2 colorError size={16} text={errorMessage} />
          ) : (
            isWarning && <IconToastWarning2 colorWarning size={16} />
          )}
          <div className="cover-detail-view__slider">
            {connectionIcon && <IconConnectionError big size={14} />}
            <Slider
              onPointerUp={handleSliderEvent}
              value={sliderValue}
              targetValue={targetValue}
              onChange={onChange}
              active={active}
              error={!!channelOrientedError}
              showValue={false}
              accentBar={externalLimitSwitchActive}
            />
          </div>
        </ControlWrapper>
        <BlindDetailsControls
          downButton={{
            handleClick: close,
          }}
          stopButton={{
            handleClick: stop,
          }}
          upButton={{
            handleClick: open,
          }}
        />
        <Favourites channel={channel} setTargetValue={setTargetValue} position={position || 0} />
      </>
    </div>
  );
};
