import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ControllerConfigurationErrorCode } from 'lavva.exalushome/build/js/Services/Controller/IControllerConfigurationService';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useBackdropContext } from '../../../../../context/backdrop';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useExalusContext } from '../../../context';
import { useExalusServicesContext } from '../../../context/services';
import { useHandleDataFrameErrors } from '../../../hooks';
import { useLoggedUser } from '../../../hooks/common/logged-user';

export const useImportExport = (backIfNotEnabled?: boolean) => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogExport, setDialogExport] = useState<boolean>(false);
  const [dialogImport, setDialogImport] = useState<boolean>(false);
  const [exportHref, setExportHref] = useState<HTMLAnchorElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { synchronized } = useExalusContext();
  const { hasAccessToControllerSettings } = useLoggedUser();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configurationApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();

  const checkIfEnabled = async () => {
    turnOnBackdrop();
    const result = await configurationApi.CanExportOrImportControllerConfigurationAsync();
    turnOffBackdrop();
    setEnabled(result);

    if (backIfNotEnabled && !result) navigate(-1);
  };

  useEffect(() => {
    if (synchronized && hasAccessToControllerSettings) checkIfEnabled();
  }, [synchronized, hasAccessToControllerSettings]);

  const handleOpenImport = async () => setDialogImport(true);

  const handleExport = async () => {
    setDialogExport(true);
    setLoading(true);
    const result = await configurationApi.ExportControllerConfigurationAsync();
    setLoading(false);

    if ((result as ResponseResult<ControllerConfigurationErrorCode>).Type) {
      switch ((result as ResponseResult<ControllerConfigurationErrorCode>).Type) {
        case ControllerConfigurationErrorCode.CannotExportConfiguration: {
          toastError({ content: t('exalus.exportConfigurationFailed') });
          break;
        }
        default: {
          toastError({ content: tc('errors.somethingWentWrong') });
          break;
        }
      }
    } else {
      setExportHref(result as HTMLAnchorElement);
    }
  };

  const handleDownload = () => {
    if (exportHref) {
      exportHref.click();
      setDialogExport(false);
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  const handleImport = async () => {
    if (file) {
      setDialogImport(true);
      turnOnBackdrop();
      const result = await configurationApi.ImportControllerConfigurationAsync(file);
      turnOffBackdrop();

      if (result === Status.OK) {
        setDialogImport(false);
        toastSuccess({ content: t('exalus.importConfigurationSuccess') });
      } else {
        handleError(result);
      }
    } else {
      toastError({ content: t('errors.noFile') });
    }
  };

  const dialogTitle = useMemo(() => {
    if (loading) return t('exalus.exporting');
    return exportHref ? t('exalus.dataExported') : tc('errors.somethingWentWrong');
  }, [loading, exportHref, t, tc]);

  const onChangeFile = (event) => {
    if (event.target.files.length) {
      setFile(event.target.files[0]);
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  return {
    importExportEnabled: enabled,
    exportHref,
    loading,
    dialogExport,
    dialogImport,
    dialogTitle,
    file,
    setDialogImport,
    setDialogExport,
    handleImport,
    handleExport,
    handleDownload,
    handleOpenImport,
    onChangeFile,
  };
};
