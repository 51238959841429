import {
  ChannelTypeInternal,
  LightChannelStateResponse,
  LightToggleValueInternal,
  PositionFavourites,
  SupplyReturnBehaviorInternal,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { ChannelStateType, ChannelType } from '../../../types';

export const parseLightChannel = (userChannel: UserChannel): ChannelType => {
  const channelPayload = userChannel.payload as LightChannelStateResponse | undefined;

  return {
    id: userChannel.id,
    installationId: userChannel.installationId,
    isVisible: userChannel.isVisible,
    alias: userChannel.alias,
    iconName: userChannel.iconName || ChannelIconTypeEnum.LIGHT,
    deviceId: userChannel.deviceId,
    currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
    data: {
      type: ChannelTypeInternal.Light,
      supportedModes: channelPayload?.supportedModes || [],
      favouritePositionList: (userChannel.favouritePositions as PositionFavourites[])?.map(
        (position) => position.value || 0,
      ) as [number, number, number],
      supplyReturnBehavior: channelPayload?.supplyReturnBehavior || SupplyReturnBehaviorInternal.AlwaysOff,
      measuredBy: channelPayload?.measuredBy || undefined,
    },
  };
};

export const parseLightChannelState = (userChannel: UserChannel): ChannelStateType => {
  const channelPayload = userChannel.payload as LightChannelStateResponse | undefined;

  return {
    brightness: channelPayload?.brightness || 0,
    temperature: channelPayload?.temperature || 0,
    toggleValue: channelPayload?.toggleValue ?? LightToggleValueInternal.Off,
    color: channelPayload?.color ?? null,
    deviceConnectionState: userChannel.payload?.deviceConnectionState,
    usesCounter: userChannel.usesCounter,
    grantedUses: userChannel.grantedUses || undefined,
  };
};
