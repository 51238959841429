import { predictionRangesAvailable } from '.';
import { CheckboxesOptions } from '../../../../../../../components/checkbox-select';
import {
  AggregateVectorParameterTypeInternal,
  MeasurementDataRangeInternal,
} from '../../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../../types';
import { getSelectedTheme } from '../../../../../../../utils/theme';
import { TariffHoursRange } from '../../../../../api/optimizer/optimizer.types';
import { AggregatedParameters } from '../../../measurements/types';

export const getParameterColor = (measurementType: AggregateVectorParameterTypeInternal) => {
  const isDark = getSelectedTheme() === 'dark';

  switch (measurementType) {
    case AggregateVectorParameterTypeInternal.ForwardActiveEnergy:
      return '#FF4016';
    case AggregateVectorParameterTypeInternal.ReverseActiveEnergy:
      return '#00B191';
    case AggregateVectorParameterTypeInternal.ForwardReactiveEnergy:
      return '#9c27b0';
    case AggregateVectorParameterTypeInternal.ReverseReactiveEnergy:
      return '#FCAF00';
    case AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming:
      return '#2196f3';
    case AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing:
      return '#0B6AB5';
    case AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice:
      return isDark ? '#F7F3E4' : '#452929';
    case AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy:
      return isDark ? '#EDECE8' : '#242423';
    case AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy:
      return isDark ? '#F7F3E4' : '#452929';
    default:
      return '#FF4016';
  }
};

export const sortedChartsParameters = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
];

export const predictionParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
];

export const clearMergeParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
];

export const lineChartParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
];

export const summaryExludedParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
];

export const noPredictionValues = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
];

export const getWeekDate = (startDate: Date, index: number) => {
  const startOfWeek = new Date(startDate);

  const currentDay = new Date(startOfWeek);
  currentDay.setDate(startOfWeek.getDate() + index);

  return currentDay;
};

export const getMonday = (date = new Date()) => {
  const dayOfWeek = date.getDay();
  const diffToMonday = (dayOfWeek + 6) % 7;
  const lastMonday = new Date(date);
  lastMonday.setDate(date.getDate() - diffToMonday);
  return lastMonday;
};

export const getPricingTier = (currentHour: number, timeRanges: TariffHoursRange[]) => {
  for (const range of timeRanges) {
    if (range.from <= range.to) {
      // Normal range (e.g., 13 to 16)
      if (currentHour >= range.from && currentHour < range.to) {
        return range.pricingTier;
      }
    } else {
      // Overnight range (e.g., 22 to 7)
      if (currentHour >= range.from || currentHour < range.to) {
        return range.pricingTier;
      }
    }
  }
  return 2; // Default pricingTier when no match is found
};

export const formatMeasurementWithUnit = (value: number, paramConfig: AggregatedParameters) => {
  return `${value.toFixed(paramConfig.fixed)} ${paramConfig.unit}`;
};

const getRelativeActiveMeasurementRange = (activeMeasurementRange: MeasurementRange) => {
  switch (activeMeasurementRange) {
    case MeasurementRange.DayInHours: {
      return MeasurementDataRangeInternal.InHours;
    }
    case MeasurementRange.YearInMonths: {
      return MeasurementDataRangeInternal.InMonths;
    }
    case MeasurementRange.TenYearsInYears: {
      return MeasurementDataRangeInternal.InYears;
    }
    default: {
      return MeasurementDataRangeInternal.InDays;
    }
  }
};

export const prepareParametersForRequest = (
  aggregatedParameters: CheckboxesOptions<AggregateVectorParameterTypeInternal>[],
  activeMeasurementRange: MeasurementRange,
  checkIfSupported: (param: AggregateVectorParameterTypeInternal) => boolean,
) => {
  const relativeActiveMeasurementRange = getRelativeActiveMeasurementRange(activeMeasurementRange);

  const aggregateVectorRequestItems = aggregatedParameters
    .filter(
      (x) => !x.disabled && x.checked && x.value !== AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
    )
    .map((param) => ({
      type: param.value,
      range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
    }));

  if (
    aggregateVectorRequestItems.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy) &&
    checkIfSupported(AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy) &&
    predictionRangesAvailable.includes(relativeActiveMeasurementRange)
  ) {
    aggregateVectorRequestItems.push({
      type: AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
      range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
    });
  }

  if (
    aggregateVectorRequestItems.find(
      (x) => x.type === AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
    )
  ) {
    if (
      checkIfSupported(AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy) &&
      predictionRangesAvailable.includes(relativeActiveMeasurementRange)
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }

    if (
      checkIfSupported(AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing) &&
      activeMeasurementRange !== MeasurementRange.DayInHours
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }
  }

  return aggregateVectorRequestItems;
};
