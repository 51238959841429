import React from 'react';
import classNames from 'classnames';
import { IconEdit, IconRadio, SelectOptionType } from '../../../../../../../../components';
import { Dialog } from '../../../../../../../../components/dialog/base';
import { PriceConditionType } from '../../enums';
import '../../index.scss';

type PriceConditionDialogProps = {
  showPriceTypeConditionPicker: PriceConditionType | true | null;
  handleClose: () => void;
  title: string;
  options: SelectOptionType<PriceConditionType>[];
  selectedOptionIndex: number;
  onChange: (priceConditionType: PriceConditionType) => void;
  renderOptionDetails: (val: PriceConditionType) => JSX.Element | null;
  priceConditionTypes: PriceConditionType[];
};

export const PriceConditionDialog: React.FC<PriceConditionDialogProps> = ({
  showPriceTypeConditionPicker,
  handleClose,
  title,
  options,
  selectedOptionIndex,
  priceConditionTypes,
  onChange,
  renderOptionDetails,
}) => {
  return (
    <Dialog show={showPriceTypeConditionPicker !== null} setShow={handleClose}>
      <div className="dialog-select">
        <h2 className="dialog-select__header">{title}</h2>
        <ul>
          {options
            .filter((x) => !priceConditionTypes.includes(x.value))
            .map((option, optionIndex) => {
              const isSelected = optionIndex === selectedOptionIndex;

              return (
                <li
                  key={option.label}
                  className={classNames('dialog-select__option-item', {
                    'dialog-select__option-item--selected': isSelected,
                  })}
                  onClick={() => onChange(option.value)}
                >
                  <IconRadio className="dialog-select__option-item-icon" selected={isSelected} />
                  <span className="dialog-select__option-item-label">
                    <span>
                      {option.label}
                      {isSelected && renderOptionDetails(option.value as PriceConditionType)}
                    </span>
                  </span>
                  {isSelected ? <IconEdit /> : null}
                </li>
              );
            })}
        </ul>
      </div>
    </Dialog>
  );
};
