import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/IRcm21ConfigService';
import { MultisensorDetectionThresholdParams } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';
import {
  Rcm21ConfigService,
  VersionedOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rcm21VersionedService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/VersionedOptions/^1.5.0.30/Rcm21VersionedService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { Checkbox, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useHandleDataFrameErrors } from '../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type HysteresisFormProps = {
  device: IDevice;
  hysteresis: MultisensorDetectionThresholdParams | null;
  versionService: string;
};

const HysteresisForm: React.FC<HysteresisFormProps> = ({ device, versionService, hysteresis }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleError } = useHandleDataFrameErrors();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<MultisensorDetectionThresholdParams>();

  useEffect(() => {
    if (hysteresis !== null) {
      form.setValue('Threshold', hysteresis.Threshold);

      if (hysteresis.IsHysteresisOptionAvailable) form.setValue('Hysteresis', hysteresis.Hysteresis);
    }
  }, [hysteresis]);

  const { Hysteresis } = form.watch();

  const onSubmit = form.handleSubmit(async (values) => {
    if (hysteresis) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcm21ConfigService>(
        Rcm21ConfigService.ServiceName,
      );
      const versionedOptions: VersionedOptions = await configService.VersionedConfigurationOptions();

      const resultThreshold = await configService.SetDetectionThresholdAsync(
        device,
        hysteresis.Channel,
        values.Threshold,
      );

      if (resultThreshold === Status.OK) {
        if (hysteresis.IsHysteresisOptionAvailable && versionService) {
          const resultHysteresis = await (versionedOptions[versionService] as Rcm21VersionedService).SetHysteresisAsync(
            device,
            hysteresis.Channel,
            values.Hysteresis,
          );

          handleSubmitResponse(resultHysteresis);
        }
      } else handleError(resultThreshold);
    }

    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <ConfigurationInput
          name="Threshold"
          label={t('exalus.params.Rcm21Params.hysteresisRange')}
          placeholder={t('exalus.params.Rcm21Params.hysteresisRange')}
          inputType="number"
          min={1}
          max={6480}
        />
        {hysteresis?.IsHysteresisOptionAvailable && (
          <Checkbox
            checked={Hysteresis}
            onChange={(e) => {
              form.setValue('IsHysteresisOptionAvailable', e.target.checked);
            }}
            reverse
          >
            {t('exalus.params.Rcm21Params.hysteresisEnable')}
          </Checkbox>
        )}

        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default HysteresisForm;
