import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useInstallationContext } from '../../../../../../context/installation';
import {
  OnLightStateChangedSubscriptionVariables,
  Subscription,
} from '../../../../../../data-access/gql-types/graphql';
import {
  ON_LIGHT_STATE_CHANGE,
  ON_REQUEST_ACK_RECEIVED,
} from '../../../../../../data-access/subscriptions/lavva-devices';
import { useUpdateCommonLavvaState } from '../common-lavva-state';
import { ChannelSubscriptionParams } from '../types';
import { useLavvaDeviceLightUpdate } from './update';

export const useLightSubscriptions = ({ channelId }: ChannelSubscriptionParams) => {
  const { updateLavvaLightState } = useLavvaDeviceLightUpdate();
  const { onRequestAckReceived } = useUpdateCommonLavvaState();
  const { skipLavvaFetch } = useInstallationContext();
  const { data: lightStateChange } = useSubscription<Subscription, OnLightStateChangedSubscriptionVariables>(
    ON_LIGHT_STATE_CHANGE,
    {
      variables: { channelId },
      skip: skipLavvaFetch,
    },
  );
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (lightStateChange) {
      updateLavvaLightState(lightStateChange.onLightStateChanged);
    }
  }, [lightStateChange]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);
};
