import { ChannelTypeInternal, GateKindInternal } from '../../../../../../data-access/gql-types/graphql';
import { AvailableIcon } from '../../../../../../types';
import { coverIcons } from './icons/cover';
import { gateIcons } from './icons/gate';
import { lightIcons } from './icons/light';
import { meterIcons } from './icons/meter';
import { optimizerIcons } from './icons/optimizer';
import { switchIcons } from './icons/switch';

export const availableIcons = (channelType: ChannelTypeInternal, param?: string): AvailableIcon[] => {
  switch (channelType) {
    case ChannelTypeInternal.Blind:
      return coverIcons();
    case ChannelTypeInternal.Switch:
      return switchIcons();
    case ChannelTypeInternal.Meter:
      return meterIcons();
    case ChannelTypeInternal.Optimizer:
      return optimizerIcons();
    case ChannelTypeInternal.Light:
      return lightIcons();
    case ChannelTypeInternal.Gate:
      return gateIcons(param as GateKindInternal);
    default:
      return [];
  }
};
