import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useInstallationContext } from '../../../../../../context/installation';
import {
  ON_REQUEST_ACK_RECEIVED,
  ON_SWITCH_STATE_CHANGE,
} from '../../../../../../data-access/subscriptions/lavva-devices';
import { useUpdateCommonLavvaState } from '../common-lavva-state';
import { ChannelSubscriptionParams } from '../types';
import { useLavvaDeviceSwitchUpdate } from './update';

export const useSwitchSubscriptions = ({ channelId }: ChannelSubscriptionParams) => {
  const { updateLavvaSwitchState } = useLavvaDeviceSwitchUpdate();
  const { onRequestAckReceived } = useUpdateCommonLavvaState();
  const { skipLavvaFetch } = useInstallationContext();
  const { data: switchChange } = useSubscription(ON_SWITCH_STATE_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (switchChange) updateLavvaSwitchState(switchChange.onSwitchStateChange);
  }, [switchChange]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);
};
