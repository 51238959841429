import {
  AmpTransformersInternal,
  MeasurementChannelKind,
  MeterPurposeInternal,
  TimeZoneKindInternal,
} from '../../../../data-access/gql-types/graphql';

export const AmpTransformValueRequest = {
  [AmpTransformersInternal.Unknown]: 0,
  [AmpTransformersInternal.OneHundredAmps]: 1,
  [AmpTransformersInternal.TwoHundredsAmps]: 2,
  [AmpTransformersInternal.FourHundredsAmps]: 3,
  [AmpTransformersInternal.OneThousandAmps]: 4,
};

export const PurposeValueRequest = {
  [MeterPurposeInternal.Unknown]: 0,
  [MeterPurposeInternal.Main]: 1,
  [MeterPurposeInternal.Pv]: 2,
  [MeterPurposeInternal.EnergyStorage]: 3,
  [MeterPurposeInternal.Consumption]: 4,
};

export type MeasurementsResetBody = {
  channelId: string;
  kind: keyof typeof MeasurementChannelKind;
};

export type MeterErrorGlobal = {
  description: string;
  errorCode: string;
};

export type MeterAmpTransformers = {
  channelId: string;
  value: number;
};

export type MeterPurpose = {
  channelId: string;
  value: number;
};

export type MeterHoursRangeConfig = {
  from: number;
  to: number;
  timeZoneKind: keyof typeof TimeZoneKindInternal;
  pricingTier: number;
};

export type MeterTariffBody = {
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  hoursRanges: MeterHoursRangeConfig[];
  kind: number;
};

export type MeterTariffConfigRequest = {
  channelId: string;
  tariff: MeterTariffBody;
};
