import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptionType } from '../../../../../../../components';
import { OptimizerModeInternal } from '../../../../../../../data-access/gql-types/graphql';

export const useOptimizerModes = () => {
  const { t } = useTranslation('channel-details');

  const workModeOptions: SelectOptionType<OptimizerModeInternal>[] = useMemo(
    () => [
      { value: OptimizerModeInternal.TestMode, label: t('optimizer.configuration.workModeOptions.test') },
      { value: OptimizerModeInternal.Eco, label: t('optimizer.configuration.workModeOptions.eco') },
      { value: OptimizerModeInternal.Comfort, label: t('optimizer.configuration.workModeOptions.comfort') },
      {
        value: OptimizerModeInternal.FullSelfConsumption,
        label: t('optimizer.configuration.workModeOptions.autoConsumptionFull'),
      },
      {
        value: OptimizerModeInternal.SelfConsumptionWithPowerLimit,
        label: t('optimizer.configuration.workModeOptions.autoConsumptionLimited'),
      },
      {
        value: OptimizerModeInternal.SelfConsumptionWithRelays,
        label: t('optimizer.configuration.workModeOptions.autoConsumptionRelays'),
      },
    ],
    [],
  );

  return {
    workModeOptions,
  };
};
