import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { DeviceTasksInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ChildrenProps } from '../../../types';
import { useAppState, useExalusConnection, useExalusGroups } from '../hooks';
import { ExalusInstallationCredentials } from '../types/exalus';
import { useExalusServicesContext } from './services';
import { useExalusStorageContext } from './storage';
import { useChannelTasksStore } from '../store/tasks';

type ExalusProviderState = {
  connected: boolean;
  connectionExists: boolean;
  synchronized: boolean;
  statesSynced: boolean;
  selectedExalusCredentials: ExalusInstallationCredentials | null;
  setConnectionExists: Dispatch<SetStateAction<boolean>>;
  setConnected: Dispatch<SetStateAction<boolean>>;
  setSelectedExalusCredentials: Dispatch<SetStateAction<ExalusInstallationCredentials | null>>;
  activeGroup: IChannelsGroup | undefined;
  allGroups: IChannelsGroup[];
  groupsFetched: boolean;
  refetchAllGroups: () => any;
  setInited: Dispatch<SetStateAction<boolean>>;
  inited: boolean;
};

const initialState: ExalusProviderState = {
  connected: false,
  connectionExists: false,
  synchronized: false,
  statesSynced: false,
  selectedExalusCredentials: null,
  setConnectionExists: () => null,
  setConnected: () => null,
  setSelectedExalusCredentials: () => null,
  activeGroup: undefined,
  allGroups: [],
  groupsFetched: false,
  refetchAllGroups: () => null,
  setInited: () => null,
  inited: false,
};

export const ExalusContext = createContext<ExalusProviderState>(initialState);

export const useExalusContext = (): ExalusProviderState => useContext(ExalusContext);

export const ExalusContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const { activeGroupIndex, getStorageData } = useExalusStorageContext();
  const setChannelTasks = useChannelTasksStore(s => s.setChannelTasks);
  const { connect } = useExalusConnection();
  const [connected, setConnected] = useState<boolean>(false);
  const [connectionExists, setConnectionExists] = useState<boolean>(false);
  const [synchronized, setSynchronized] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);
  const [statesSynced, setStatesSynced] = useState<boolean>(false);
  const [selectedExalusCredentials, setSelectedExalusCredentials] = useState<ExalusInstallationCredentials | null>(
    null,
  );
  const { devicesApi, groupsApi } = useExalusServicesContext();
  const {
    data: groups,
    refetch: refetchGroups,
    isFetched,
  } = useExalusGroups({
    selectedExalusCredentials,
    connected,
    synchronized,
  });
  const { initObservableAppStateChange, initObservableControllerConfigurationChange } = useAppState(setInited);

  const connectionStatus = async (selectedExalusCredentials: ExalusInstallationCredentials) => {
    const res = !connectionExists ? await connect(selectedExalusCredentials) : true;
    setConnected(res);
    setConnectionExists(res);

    await groupsApi.WaitForSynchronizationAsync();
    await devicesApi.WaitForSynchronizationAsync();
    setSynchronized(true);

    await devicesApi.WaitForDevicesStatesSynchronizationAsync();
    setStatesSynced(true);
  };

  useEffect(() => {
    setConnected(false);
    if (selectedExalusCredentials?.id) {
      connectionStatus(selectedExalusCredentials);
      initObservableAppStateChange();
      initObservableControllerConfigurationChange();
    }
  }, [selectedExalusCredentials]);

  useEffect(() => {
    if (connected) getStorageData();
  }, [connected]);

  const updateChannelTasks = (deviceTasksInfo: DeviceTasksInfo[]) => {
    setChannelTasks(deviceTasksInfo);
  };

  const activeGroup = useMemo(() => (groups ? groups[activeGroupIndex] : undefined), [activeGroupIndex, groups]);

  const allGroups = useMemo(() => groups || [], [groups]);

  const refetchAllGroups = () => refetchGroups();

  useEffect(() => {
    devicesApi.OnDevicesTasksExecutionChangeEvent().Subscribe(updateChannelTasks);

    return () => {
      devicesApi.OnDevicesTasksExecutionChangeEvent().Unsubscribe(updateChannelTasks);
    };
  }, []);

  const values: ExalusProviderState = {
    connected,
    connectionExists,
    synchronized,
    statesSynced,
    setConnectionExists,
    setConnected,
    selectedExalusCredentials,
    setSelectedExalusCredentials,
    activeGroup,
    allGroups,
    groupsFetched: isFetched,
    refetchAllGroups,
    setInited,
    inited,
  };

  return <ExalusContext.Provider value={values}>{children}</ExalusContext.Provider>;
};
