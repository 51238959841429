import React from 'react';
import { ChannelTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../../store/channels-state';
import { ChannelType, ChannelLightStateType } from '../../../../types';

type ChannelBoxPreNameProps = {
  channel: ChannelType;
};

export const ChannelBoxPreName: React.FC<ChannelBoxPreNameProps> = ({ channel }) => {
  const { id, data } = channel;
  const state = useChannelsStateStore((s) => s.channelState[id]);

  if (data.type === ChannelTypeInternal.Light) {
    const lightState = state as ChannelLightStateType | null;
    const color = `${lightState?.color?.r}, ${lightState?.color?.g}, ${lightState?.color?.b}`;

    return color ? <span className="rgb-value" style={{ backgroundColor: `rgb(${color})` }}></span> : null;
  }

  return null;
};
