import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  ISbrConfigService,
  SbrAutoCloseTime,
  SbrServiceInfo,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/ISbrConfigService';
import { SbrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/SbrConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { AdvanceDelayDialog, DialogTimePicker, IconEdit, Input, SubmitButton } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { millisecondsToTime, timeToMilliseconds } from '../../../../../../utils/helpers';
import { toastError } from '../../../../../../utils/toast';
import { useLoggedUser } from '../../../../hooks/common/logged-user';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { useDeviceConfiguration } from '../../../device-details/hooks/use-device-configuration';
import { SbrBidiStatistics } from './statistics';
import { SbrParamsEnum } from './types';

type SbrConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const SbrConfigForm: React.FC<SbrConfigFormProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [showTimeDialog, setShowTimeDialog] = useState<boolean>(false);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const { handleFieldChangeErrors } = useDeviceConfiguration();
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<SbrParamsEnum | null>(null);
  const [statistics, setStatistics] = useState<SbrServiceInfo | null>(null);
  const [value, setValue] = useState<number>(60);
  const [stringValue, setStringValue] = useState<string>('00:00:00');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { user } = useLoggedUser();
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const statisticsAvailable = useMemo(() => user?.AccessLevel === AccessLevel.Support, [user?.AccessLevel]);

  const getDefaultParams = async (panel: SbrParamsEnum) => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISbrConfigService>(
      SbrConfigService.ServiceName,
    );

    if (configService) {
      turnOnBackdrop();

      switch (panel) {
        case SbrParamsEnum.AUTO_CLOSE_TIME: {
          if (channel) {
            const data = await configService.GetAutoCloseTimeAsync(channel);

            handleGetParamsResponse<SbrAutoCloseTime>(data, () => {
              setValue((data as SbrAutoCloseTime).TimeInMiliseconds / 1000);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        case SbrParamsEnum.ALARM_LIGHT_DELAY: {
          if (channel) {
            const data = await configService.GetAlarmLightDelayAsync(channel);

            handleGetParamsResponse<number>(data, () => {
              setValue((data as number) / 1000);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        case SbrParamsEnum.ALARM_LIGHT_DURATION: {
          if (channel) {
            const data = await configService.GetAlarmLightDurationAsync(channel);

            handleGetParamsResponse<number>(data, () => {
              setStringValue(millisecondsToTime(data as number));
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        case SbrParamsEnum.SERVICE_INFO: {
          if (channel) {
            const data = await configService.GetServiceInfoAsync(channel);

            handleGetParamsResponse<SbrServiceInfo>(data, () => {
              setStatistics(data as SbrServiceInfo);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        default:
          break;
      }
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: SbrParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType(null);
  };

  const handleSubmit = async () => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISbrConfigService>(
        SbrConfigService.ServiceName,
      );

      switch (configType) {
        case SbrParamsEnum.AUTO_CLOSE_TIME: {
          const data = await configService.SetAutoCloseTimeAsync(channel, value * 1000, false);

          handleFieldChangeErrors(data, () => handleBack);
          break;
        }
        case SbrParamsEnum.ALARM_LIGHT_DELAY: {
          const data = await configService.SetAlarmLightDelayAsync(channel, value * 1000);

          handleFieldChangeErrors(data, () => handleBack);
          break;
        }
        case SbrParamsEnum.ALARM_LIGHT_DURATION: {
          const data = await configService.SetAlarmLightDurationAsync(channel, timeToMilliseconds(stringValue));

          handleFieldChangeErrors(data, () => handleBack);
          break;
        }
        default:
          break;
      }

      turnOffBackdrop();
    }
  };

  const renderCurrentConfig = () => {
    switch (configType) {
      case SbrParamsEnum.SERVICE_INFO:
        return <SbrBidiStatistics statistics={statistics} />;
      case SbrParamsEnum.ALARM_LIGHT_DURATION:
        return (
          <>
            <Input
              defaultValue={stringValue}
              value={stringValue}
              required
              readOnly
              label={t(`exalus.params.SbrParams.${configType}`)}
              onClickInput={() => setShowTimePicker(true)}
              endIcon={<IconEdit />}
            />
            <DialogTimePicker
              header={t(`exalus.params.SbrParams.${configType}`)}
              open={showTimePicker}
              setOpen={setShowTimePicker}
              onSave={(value) => setStringValue(value)}
              time={stringValue}
              showSeconds
              maxHours={1}
            />
          </>
        );
      default:
        return (
          <>
            <Input
              defaultValue={value.toString()}
              value={value.toString()}
              required
              readOnly
              label={`${t(`exalus.params.SbrParams.${configType}`)} [s]`}
              onClickInput={() => setShowTimeDialog(true)}
            />
            <AdvanceDelayDialog
              open={showTimeDialog}
              setOpen={setShowTimeDialog}
              title={t(`exalus.params.SbrParams.${configType}`)}
              onSave={(val) => setValue(val)}
              time={value}
              min={0}
              max={60}
              type="seconds"
            />
          </>
        );
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage
          handleBack={handleBack}
          {...(configType === SbrParamsEnum.SERVICE_INFO ? { header: t('exalus.params.SbrParams.ServiceInfo') } : {})}
        >
          {renderCurrentConfig()}
          {configType !== SbrParamsEnum.SERVICE_INFO && <SubmitButton onClick={handleSubmit} />}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.SbrParams.AutoCloseTime')}
                onClick={() => handleOpen(SbrParamsEnum.AUTO_CLOSE_TIME)}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SbrParams.AlarmLightDelay')}
                onClick={() => handleOpen(SbrParamsEnum.ALARM_LIGHT_DELAY)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SbrParams.AlarmLightDuration')}
                onClick={() => handleOpen(SbrParamsEnum.ALARM_LIGHT_DURATION)}
              />
              {statisticsAvailable && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.SbrParams.ServiceInfo')}
                  onClick={() => handleOpen(SbrParamsEnum.SERVICE_INFO)}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SbrConfigForm;
