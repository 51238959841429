import React, { PointerEvent } from 'react';
import { ControlWrapper, Slider, SubmitButton } from '../..';
import { GateControls } from '../../../integrations/lavva/components';
import { useActionGatesSupportedFeatures } from '../../../integrations/lavva/modules/action-details/hooks/use-gates-supported';
import { useLavvaCreateActionContext } from '../../../integrations/lavva/modules/action-create/context';
import { GateActionInternal } from '../../../integrations/lavva/modules/action-create/types';
import './blind.scss';

type GateChannelSettingsProps = {
  sliderValue: number;
  handleSliderEvent: (event: PointerEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSliderValue: (value: React.SetStateAction<number>) => void;
  handleConstPercentageChange: (pos: number) => void;
  handleSubmit: () => void;
  gateAction: GateActionInternal;
  setGateAction: (value: React.SetStateAction<GateActionInternal>) => void;
};

export const GateChannelSettings: React.FC<GateChannelSettingsProps> = (props) => {
  const { sliderValue, handleSliderEvent, onChange, setSliderValue, handleSubmit } = props;
  const { channels } = useLavvaCreateActionContext();
  const { setDirectionSupported, setPositionSupported, ventilationSupported } =
    useActionGatesSupportedFeatures(channels);

  return (
    <ControlWrapper className="control-wrapper--full-space">
      <div>
        {(setDirectionSupported || ventilationSupported) && (
          <GateControls
            kind="action"
            open={() => {
              setSliderValue(0);
              props.setGateAction(GateActionInternal.Open);
            }}
            close={() => {
              setSliderValue(100);
              props.setGateAction(GateActionInternal.Closed);
            }}
            stop={() => props.setGateAction(GateActionInternal.Stopped)}
            ventilation={() => props.setGateAction(GateActionInternal.Tilt)}
            ventilationVisible={ventilationSupported}
            directionVisible={setDirectionSupported}
            activeButton={props.gateAction}
          />
        )}
        {setPositionSupported && (
          <Slider
            onPointerUp={handleSliderEvent}
            value={sliderValue}
            onChange={onChange}
            position="horizontal"
            noBgElement
          />
        )}
      </div>
      <SubmitButton onClick={handleSubmit} />
    </ControlWrapper>
  );
};
