import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmptyStateBox, IconWarning } from '../../../../../components';
import { DeviceConnectionState } from '../../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../../routes';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelType } from '../../../types';

type RecoveryInfoDetailsProps = {
  channel: ChannelType;
};

export const RecoveryInfoDetails: React.FC<RecoveryInfoDetailsProps> = ({ channel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { t: tco } = useTranslation('configuration');
  const state = useChannelsStateStore((s) => s.channelState[channel.id]);

  const handleRecovery = () => {
    if (channel) navigate(ROUTES.DeviceSettings(channel?.deviceId));
  };

  if (state?.deviceConnectionState !== DeviceConnectionState.Recovery) return null;

  return (
    <EmptyStateBox
      header={tc('status.recoveryMode')}
      btnText={tco('goToDevice')}
      content={t('recoveryNeeded')}
      icon={<IconWarning />}
      onClick={handleRecovery}
      className="m-b-16"
    />
  );
};
