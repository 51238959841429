import { TariffKindInternal } from '../../../../../../../data-access/gql-types/graphql';
import { TariffName, TariffOperator } from '../types/tariff-kind';

export const tariffKind = {
  [TariffKindInternal.None]: {
    operator: '',
    tariff: '',
  },
  [TariffKindInternal.Custom]: {
    operator: TariffOperator.Custom,
    tariff: TariffName.Custom,
  },
  [TariffKindInternal.TauronG11]: {
    operator: TariffOperator.Tauron,
    tariff: TariffName.G11,
  },
  [TariffKindInternal.TauronG12]: {
    operator: TariffOperator.Tauron,
    tariff: TariffName.G12,
  },
  [TariffKindInternal.TauronG12W]: {
    operator: TariffOperator.Tauron,
    tariff: TariffName.G12W,
  },
  [TariffKindInternal.TauronG13]: {
    operator: TariffOperator.Tauron,
    tariff: TariffName.G13,
  },
  [TariffKindInternal.EneaG11]: {
    operator: TariffOperator.Enea,
    tariff: TariffName.G11,
  },
  [TariffKindInternal.EneaG12]: {
    operator: TariffOperator.Enea,
    tariff: TariffName.G12,
  },
  [TariffKindInternal.EneaG12W]: {
    operator: TariffOperator.Enea,
    tariff: TariffName.G12W,
  },
  [TariffKindInternal.PgeG11]: {
    operator: TariffOperator.Pge,
    tariff: TariffName.G11,
  },
  [TariffKindInternal.PgeG12]: {
    operator: TariffOperator.Pge,
    tariff: TariffName.G12,
  },
  [TariffKindInternal.PgeG12W]: {
    operator: TariffOperator.Pge,
    tariff: TariffName.G12W,
  },
  [TariffKindInternal.PgeG12As]: {
    operator: TariffOperator.Pge,
    tariff: TariffName.G12AS,
  },
  [TariffKindInternal.EonG11]: {
    operator: TariffOperator.Eon,
    tariff: TariffName.G11,
  },
  [TariffKindInternal.EonG12]: {
    operator: TariffOperator.Eon,
    tariff: TariffName.G12,
  },
  [TariffKindInternal.EonG12W]: {
    operator: TariffOperator.Eon,
    tariff: TariffName.G12W,
  },
  [TariffKindInternal.EonG12As]: {
    operator: TariffOperator.Eon,
    tariff: TariffName.G12AS,
  },
  [TariffKindInternal.EnergaG11]: {
    operator: TariffOperator.Energa,
    tariff: TariffName.G11,
  },
  [TariffKindInternal.EnergaG12]: {
    operator: TariffOperator.Energa,
    tariff: TariffName.G12,
  },
  [TariffKindInternal.EnergaG12W]: {
    operator: TariffOperator.Energa,
    tariff: TariffName.G12W,
  },
  [TariffKindInternal.EnergaG12R]: {
    operator: TariffOperator.Energa,
    tariff: TariffName.G12R,
  },
  [TariffKindInternal.TauronDynamic]: {
    operator: TariffOperator.Tauron,
    tariff: TariffName.Dynamic,
  },
  [TariffKindInternal.EneaDynamic]: {
    operator: TariffOperator.Enea,
    tariff: TariffName.Dynamic,
  },
  [TariffKindInternal.PgeDynamic]: {
    operator: TariffOperator.Pge,
    tariff: TariffName.Dynamic,
  },
  [TariffKindInternal.EonDynamic]: {
    operator: TariffOperator.Eon,
    tariff: TariffName.Dynamic,
  },
  [TariffKindInternal.EnergaDynamic]: {
    operator: TariffOperator.Energa,
    tariff: TariffName.Dynamic,
  },
};

export const tariffsForRequest = [
  { kind: 0, operator: '', tariff: '', enum: '' },
  { kind: 1, operator: TariffOperator.Custom, tariff: TariffName.Custom, enum: TariffKindInternal.Custom },
  { kind: 2, operator: TariffOperator.Tauron, tariff: TariffName.G11, enum: TariffKindInternal.TauronG11 },
  { kind: 3, operator: TariffOperator.Tauron, tariff: TariffName.G12, enum: TariffKindInternal.TauronG12 },
  { kind: 4, operator: TariffOperator.Tauron, tariff: TariffName.G12W, enum: TariffKindInternal.TauronG12W },
  { kind: 5, operator: TariffOperator.Tauron, tariff: TariffName.G13, enum: TariffKindInternal.TauronG13 },
  { kind: 6, operator: TariffOperator.Enea, tariff: TariffName.G11, enum: TariffKindInternal.EneaG11 },
  { kind: 7, operator: TariffOperator.Enea, tariff: TariffName.G12, enum: TariffKindInternal.EneaG12 },
  { kind: 8, operator: TariffOperator.Enea, tariff: TariffName.G12W, enum: TariffKindInternal.EneaG12W },
  { kind: 9, operator: TariffOperator.Pge, tariff: TariffName.G11, enum: TariffKindInternal.PgeG11 },
  { kind: 10, operator: TariffOperator.Pge, tariff: TariffName.G12, enum: TariffKindInternal.PgeG12 },
  { kind: 11, operator: TariffOperator.Pge, tariff: TariffName.G12W, enum: TariffKindInternal.PgeG12W },
  { kind: 12, operator: TariffOperator.Pge, tariff: TariffName.G12AS, enum: TariffKindInternal.PgeG12As },
  { kind: 13, operator: TariffOperator.Eon, tariff: TariffName.G11, enum: TariffKindInternal.EonG11 },
  { kind: 14, operator: TariffOperator.Eon, tariff: TariffName.G12, enum: TariffKindInternal.EonG12 },
  { kind: 15, operator: TariffOperator.Eon, tariff: TariffName.G12W, enum: TariffKindInternal.EonG12W },
  { kind: 16, operator: TariffOperator.Eon, tariff: TariffName.G12AS, enum: TariffKindInternal.EonG12As },
  { kind: 17, operator: TariffOperator.Energa, tariff: TariffName.G11, enum: TariffKindInternal.EnergaG11 },
  { kind: 18, operator: TariffOperator.Energa, tariff: TariffName.G12, enum: TariffKindInternal.EnergaG12 },
  { kind: 19, operator: TariffOperator.Energa, tariff: TariffName.G12W, enum: TariffKindInternal.EnergaG12W },
  { kind: 20, operator: TariffOperator.Energa, tariff: TariffName.G12R, enum: TariffKindInternal.EnergaG12R },
  { kind: 21, operator: TariffOperator.Tauron, tariff: TariffName.Dynamic, enum: TariffKindInternal.TauronDynamic },
  { kind: 22, operator: TariffOperator.Enea, tariff: TariffName.Dynamic, enum: TariffKindInternal.EneaDynamic },
  { kind: 23, operator: TariffOperator.Pge, tariff: TariffName.Dynamic, enum: TariffKindInternal.PgeDynamic },
  { kind: 24, operator: TariffOperator.Eon, tariff: TariffName.Dynamic, enum: TariffKindInternal.EonDynamic },
  { kind: 25, operator: TariffOperator.Energa, tariff: TariffName.Dynamic, enum: TariffKindInternal.EneaDynamic },
];
