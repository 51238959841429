import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { INSTALLATION_COLOR_LIST } from '../../../const';
import { useBackdropContext } from '../../../context/backdrop';
import { useInstallationContext } from '../../../context/installation';
import {
  ChangeInstallationColorMutation,
  ChangeInstallationColorMutationVariables,
  InstallationModelResponse,
  UserInstallation,
} from '../../../data-access/gql-types/graphql';
import { CHANGE_INSTALLATION_COLOR } from '../../../data-access/mutations/installations';
import { useMutationErrors } from '../../../hooks';
import { CreateInstallationControlForm } from '../../../modules/installation-create/types';
import { MutationErrorType } from '../../../types';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { toastError, toastSuccess } from '../../../utils/toast';
import { InstallationColor } from '../installation-color';
import './index.scss';

type InstallationColorBtnProps = {
  updateInstallation?: (key: keyof UserInstallation, value: string | InstallationModelResponse) => void;
};

export const InstallationColorBtn: React.FC<InstallationColorBtnProps> = ({ updateInstallation }) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { setValue, watch } = useFormContext<CreateInstallationControlForm>();
  const [open, setOpen] = useState<boolean>(false);
  const { selectedInstallationId, selectedInstallation } = useInstallationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Installation);
  const [changeInstallationColor] = useMutation<
    ChangeInstallationColorMutation,
    ChangeInstallationColorMutationVariables
  >(CHANGE_INSTALLATION_COLOR);
  const { hex_color } = watch();

  const colorIndex = useMemo(() => INSTALLATION_COLOR_LIST.findIndex((color) => color === hex_color) + 1, [hex_color]);

  const onChangeColor = (value: string) => {
    setValue('hex_color', value);
    setOpen(false);

    if (updateInstallation && selectedInstallation?.hexColor !== value) {
      if (!isLocalApp) {
        turnOnBackdrop();
        changeInstallationColor({
          variables: {
            input: {
              installationId: selectedInstallationId,
              hexColor: value,
            },
          },
          onCompleted: (data) => {
            turnOffBackdrop();
            if (!data || !data.changeInstallationColor) {
              toastError({ content: tc('errors.somethingWentWrong') });
              return;
            }

            if (data.changeInstallationColor.result?.ok) {
              updateInstallation('hexColor', value);
              toastSuccess({ content: t('colorEditSuccess') });
            } else {
              handleErrors(data.changeInstallationColor.errors || []);
            }
          },
          onError: () => turnOffBackdrop(),
        });
      } else {
        updateInstallation('hexColor', value);
        toastSuccess({ content: t('colorEditSuccess') });
      }
    }
  };

  return (
    <>
      {!open ? (
        <div className="installation-color-btn">
          <span className="installation-color-btn__label">{t('mainColor')}</span>
          <button type="button" onClick={() => setOpen(true)} className="installation-color-btn__btn">
            <div>{colorIndex > 0 ? `${t('color')} ${colorIndex}` : null}</div>
            <div className="installation-color-btn__icon" style={{ backgroundColor: hex_color }} />
          </button>
        </div>
      ) : (
        <InstallationColor activeColor={hex_color} onClose={() => setOpen(false)} onChoose={onChangeColor} />
      )}
    </>
  );
};
