import {
  ChannelTypeInternal,
  PositionFavourites,
  SupplyReturnBehaviorInternal,
  SwitchChannelStateResponse,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { ChannelStateType, ChannelType } from '../../../types';

export const parseSwitchChannel = (userChannel: UserChannel): ChannelType => {
  const channelPayload = userChannel.payload as SwitchChannelStateResponse | undefined;

  return {
    id: userChannel.id,
    installationId: userChannel.installationId,
    isVisible: userChannel.isVisible,
    alias: userChannel.alias,
    iconName: userChannel.iconName || ChannelIconTypeEnum.LIGHT,
    deviceId: userChannel.deviceId,
    currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
    data: {
      type: ChannelTypeInternal.Switch,
      favouritePositionList: (userChannel.favouritePositions as PositionFavourites[])?.map(
        (position) => position.value || 0,
      ) as [number, number, number],
      supplyReturnBehavior: channelPayload?.supplyReturnBehavior || SupplyReturnBehaviorInternal.AlwaysOff,
      measuredBy: channelPayload?.measuredBy || undefined,
    },
  };
};

export const parseSwitchChannelState = (userChannel: UserChannel): ChannelStateType => {
  const channelPayload = userChannel.payload as SwitchChannelStateResponse | undefined;

  return {
    lavvaState: channelPayload?.state,
    deviceConnectionState: channelPayload?.deviceConnectionState,
    usesCounter: userChannel.usesCounter,
    grantedUses: userChannel.grantedUses || undefined,
  };
};
