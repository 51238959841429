import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import { ActionType } from '../../../../../types/actions';
import { useExalusScenarios } from '../../../hooks/scenarios';
import { useExalusCreateActionContext } from '../../action-create/context';

export const useActions = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('action');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data: actions, isFetched, isLoading } = useExalusScenarios();
  const { setNewBuilder } = useExalusCreateActionContext();

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, [search]);

  const tabs = [{ label: t('action.tabs.actions') }, { label: t('action.tabs.automations') }];

  const addNewAction = () => {
    setNewBuilder();
    if (activeTabIndex === 0) navigate(`${ROUTES.ActionCreate()}?type=${ActionType.Action}`);
    else navigate(`${ROUTES.ActionCreate()}?type=${ActionType.Automation}`);
  };

  const setTab = (index: number) => {
    setActiveTabIndex(index);
    navigate(`${ROUTES.ActionList()}?tab=${index}`, { replace: true });
  };

  return {
    tabs,
    actions,
    isFetched: isFetched && !isLoading,
    activeTabIndex,
    setTab,
    addNewAction,
  };
};
