import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { ChildrenProps } from '../../../../../types';
import { ChannelType } from '../../../types';
import { DeviceTaskParams, SceneTaskType, Task, TaskState, TaskVariant } from '../types';

type LavvaCreateActionProviderState = {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  icon: string;
  setIcon: Dispatch<SetStateAction<string>>;
  isDuplicate: boolean;
  taskType: SceneTaskType;
  setTaskList: Dispatch<SetStateAction<Task[]>>;
  setTaskType: Dispatch<SetStateAction<SceneTaskType>>;
  taskVariant: TaskVariant;
  setTaskVariant: Dispatch<SetStateAction<TaskVariant>>;
  channels: ChannelType[];
  setChannels: Dispatch<SetStateAction<ChannelType[]>>;
  setIsDuplicate: Dispatch<SetStateAction<boolean>>;
  taskList: Task[];
  addTaskToList: (task: Task) => void;
  editDeviceTask: (id: string, taskState: TaskState) => void;
  removeTask: (id: string) => void;
  clearAll: () => void;
};

const initialState: LavvaCreateActionProviderState = {
  name: '',
  setName: () => null,
  icon: '',
  setIcon: () => null,
  isDuplicate: false,
  taskType: SceneTaskType.Unknown,
  setTaskList: () => null,
  setTaskType: () => null,
  taskVariant: TaskVariant.Undefined,
  setTaskVariant: () => null,
  channels: [],
  setChannels: () => null,
  taskList: [],
  addTaskToList: () => null,
  editDeviceTask: () => null,
  removeTask: () => null,
  clearAll: () => null,
  setIsDuplicate: () => null,
};

export const LavvaCreateActionContext = createContext<LavvaCreateActionProviderState>(initialState);

export const useLavvaCreateActionContext = (): LavvaCreateActionProviderState => useContext(LavvaCreateActionContext);

export const LavvaCreateActionContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [name, setName] = useState<string>(initialState.name);
  const [icon, setIcon] = useState<string>(initialState.icon);
  const [taskType, setTaskType] = useState<SceneTaskType>(initialState.taskType);
  const [taskVariant, setTaskVariant] = useState<TaskVariant>(initialState.taskVariant);
  const [channels, setChannels] = useState<ChannelType[]>(initialState.channels);
  const [taskList, setTaskList] = useState<Task[]>(initialState.taskList);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(initialState.isDuplicate);

  const addTaskToList = (task: Task) => setTaskList((prevState) => [...prevState, task]);

  const editDeviceTask = useCallback(
    (id: string, taskState: TaskState) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === id);

      if (index !== -1) {
        (taskListTemp[index].taskParams as DeviceTaskParams).state = taskState;
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const removeTask = useCallback(
    (taskId: string) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === taskId);

      if (index !== -1) {
        taskListTemp.splice(index, 1);
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const clearAll = () => {
    setName('');
    setIcon('');
    setIsDuplicate(false);
    setChannels([]);
    setTaskList([]);
    setTaskVariant(TaskVariant.Undefined);
    setTaskType(SceneTaskType.Unknown);
  };

  const values: LavvaCreateActionProviderState = {
    name,
    icon,
    taskType,
    taskVariant,
    channels,
    taskList,
    isDuplicate,
    editDeviceTask,
    setName,
    setIcon,
    setTaskType,
    setTaskVariant,
    setChannels,
    addTaskToList,
    removeTask,
    setTaskList,
    clearAll,
    setIsDuplicate,
  };

  return <LavvaCreateActionContext.Provider value={values}>{children}</LavvaCreateActionContext.Provider>;
};
