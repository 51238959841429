import React, { ReactNode } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { useNavigateState } from '../hooks/use-history-state';
import SensorHistory from '../sensor-history';

type SensorDetailsWrapperProps = {
  channel: IDeviceChannel;
  children: ReactNode;
  active?: boolean;
};

const SensorDetailsWrapper: React.FC<SensorDetailsWrapperProps> = ({ channel, children, active }) => {
  const { tabs } = useNavigateState(channel);

  return (
    <LavvaDetailsWrapper tabs={tabs} additionalTabs={[<SensorHistory key={2} channel={channel} active={active} />]}>
      <div className="p-l-24 p-r-24">{children}</div>
    </LavvaDetailsWrapper>
  );
};

export default SensorDetailsWrapper;
