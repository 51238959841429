import { useTranslation } from 'react-i18next';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { useBackdropContext } from '../../../context/backdrop';
import { toastError } from '../../../utils/toast';

export const useHandleDataFrameErrors = () => {
  const { t } = useTranslation('common');
  const { turnOffBackdrop } = useBackdropContext();

  const handleError = (result: Status, message?: string) => {
    turnOffBackdrop();

    switch (result) {
      case Status.UnknownError: {
        toastError({ content: t('exalus.responseStatus.unknownError') });
        break;
      }
      case Status.FatalError: {
        toastError({ content: t('exalus.responseStatus.fatalError') });
        break;
      }
      case Status.WrongData: {
        toastError({ content: message || t('exalus.responseStatus.wrongData') });
        break;
      }
      case Status.ResourceDoesNotExists: {
        toastError({ content: t('exalus.responseStatus.resourceDoesNotExists') });
        break;
      }
      case Status.NoPermissionToPerformThisOperation: {
        toastError({ content: t('exalus.responseStatus.noPermissionToPerformThisOperation') });
        break;
      }
      case Status.OperationNotPermitted: {
        toastError({ content: t('exalus.responseStatus.operationNotPermitted') });
        break;
      }
      case Status.NoPermissionsToCallGivenResource: {
        toastError({ content: t('exalus.responseStatus.noPermissionsToCallGivenResource') });
        break;
      }
      case Status.ResourceIsNotAvailable: {
        toastError({ content: t('exalus.responseStatus.resourceIsNotAvailable') });
        break;
      }
      case Status.Error: {
        toastError({ content: t('exalus.responseStatus.error') });
        break;
      }
      case Status.UserIsNotLoggedIn: {
        toastError({ content: t('exalus.responseStatus.userIsNotLoggedIn') });
        break;
      }
      case Status.NoData: {
        toastError({ content: t('exalus.responseStatus.noData') });
        break;
      }
      case Status.SessionHasAlreadyLoggedOnUser: {
        toastError({ content: t('exalus.responseStatus.sessionHasAlreadyLoggedOnUser') });
        break;
      }
      case Status.NotSupportedMethod: {
        toastError({ content: t('exalus.responseStatus.notSupportedMethod') });
        break;
      }
      case Status.MultiDataResponseStart: {
        toastError({ content: t('exalus.responseStatus.multiDataResponseStart') });
        break;
      }
      case Status.MultiDataResponse: {
        toastError({ content: t('exalus.responseStatus.multiDataResponse') });
        break;
      }
      case Status.MultiDataResponseStop: {
        toastError({ content: t('exalus.responseStatus.multiDataResponseStop') });
        break;
      }
      default: {
        toastError({ content: t('errors.somethingWentWrong') });
        break;
      }
    }
  };

  return {
    handleError,
  };
};
