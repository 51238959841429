import { useTranslation } from 'react-i18next';
import { Accordion, IconDashboard } from '../../../../../../../../../components';
import { IconSettings } from '../../../../../../../../../components/popup-nav/icons';
import { OptimizerModeInternal } from '../../../../../../../../../data-access/gql-types/graphql';
import './index.scss';

type VideoWorkModeProps = {
  selectedWorkMode: OptimizerModeInternal;
};

const videos: { title: string; url: string }[] = [
  {
    title: 'optimizer.configuration.workModeOptions.test',
    url: 'https://www.youtube.com/embed/f1_3qmCyVQM?si=7e_Ri5x-yIyuKYQy',
  },
  {
    title: 'optimizer.configuration.workModeOptions.eco',
    url: 'https://www.youtube.com/embed/q6vpnh9a8lI?si=zH4EMT1EiaThadn9',
  },
  {
    title: 'optimizer.configuration.workModeOptions.comfort',
    url: 'https://www.youtube.com/embed/cCpXMjhWiWM?si=MyvgsHFRgeTP2SPl',
  },
  {
    title: 'optimizer.configuration.workModeOptions.autoConsumptionFull',
    url: 'https://www.youtube.com/embed/CDU1Q7yN36A?si=XW4vxqDOnzVsXG4n',
  },
  {
    title: 'optimizer.configuration.workModeOptions.autoConsumptionLimited',
    url: 'https://www.youtube.com/embed/SQO0tUIZ5oQ?si=ObJBUZRyZmFnNx9e',
  },
  {
    title: 'optimizer.configuration.workModeOptions.autoConsumptionRelays',
    url: 'https://www.youtube.com/embed/H-ccfSYXlRE?si=rCGhnc2gEnsQ8ZlB',
  },
];

export const VideoWorkMode: React.FC<VideoWorkModeProps> = () => {
  const { t } = useTranslation('channel-details');

  return (
    <div className="video-work-mode">
      <div className="urls">
        <a href="https://zamel.io/eko-oze-pv" target="_blank" rel="noopener noreferrer">
          <IconDashboard />
          <span>{t('optimizer.configuration.website')}</span>
        </a>
        <a href="https://zamel.com/eko-oze-pv/eko-oze-pv-instrukcja.pdf" target="_blank" rel="noopener noreferrer">
          <IconSettings />
          <span>{t('optimizer.configuration.userManual')}</span>
        </a>
      </div>
      {videos.map((mode) => (
        <Accordion key={mode.title} kind="custom" title={t(mode.title)}>
          <iframe
            key={mode.title}
            style={{ display: 'block' }}
            width="100%"
            height="315"
            src={mode.url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Accordion>
      ))}
    </div>
  );
};
