import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../../components';
import { RgbColorListItem } from '../../components/color-list-item';
import { RgbSceneDetails } from '../../components/scene-details';
import { RgbSceneColor, useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';

export const RgbSceneRemoveColor: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { setSelectedView, colors, setColors } = useRgbSceneFormContext();
  const [tempColors, setTempColors] = useState<RgbSceneColor[]>(colors);

  const removeColor = (id: string) => {
    const filteredColors = tempColors.filter((color) => color.id !== id);
    setTempColors([...filteredColors]);
  };

  const onSubmit = () => {
    setColors(tempColors);
    setSelectedView(RgbSceneFormView.LIST);
  };

  return (
    <Page
      header={
        <>
          <NavHeader onClick={() => setSelectedView(RgbSceneFormView.LIST)} />
          <Header title={t('rgbScenes.removeColor')} />
        </>
      }
      containerClassName={'p-t-0 p-r-0 p-b-0 p-l-0'}
      headerClassName={'p-t-24 p-r-24 p-b-0 p-l-24'}
      contentClassName={'p-r-24 p-b-24 p-l-24 page__content--background-element page__content--scrollable'}
    >
      <div className="rgb-scene-form-change-order">
        <RgbSceneDetails colors={tempColors} />

        {tempColors.map((color) => (
          <RgbColorListItem id={color.id} colorItem={color} key={color.id} isRemoveMode onClick={removeColor} />
        ))}
      </div>
      <SubmitButton backgroundElement type={'button'} onClick={onSubmit}>
        {tc('buttons.save')}
      </SubmitButton>
    </Page>
  );
};
