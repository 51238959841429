import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, IconDriveAddSuccess, IconLogoWisniowskiSmall, Page } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { useBluetoothContext } from '../../../context';
import './index.scss';

const WisniowskiDriveAddSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('drive-add');
  const { returnToStartPage } = useBluetoothContext();

  const handleReady = () => navigate(ROUTES.Channel());

  return (
    <Page contentClassName="drive-add success" isStickyHeader>
      <div>
        <Header title={t('success.header')} />
        <p className="sub-header">{t('success.content')}</p>
        <IconDriveAddSuccess />
        <IconLogoWisniowskiSmall className="m-t-48" />
      </div>
      <div className="buttons-container">
        <button className="button button--secondary max-width-desktop" onClick={returnToStartPage}>
          {t('success.addNextDevice')}
        </button>
        <button className="max-width-desktop" onClick={handleReady}>
          {t('success.ready')}
        </button>
      </div>
    </Page>
  );
};

export default WisniowskiDriveAddSuccess;
