import {
  ChannelTypeInternal,
  OptimizerModeInternal,
  RelayConfig,
  TimeZoneKindInternal,
  VoltageThreshold,
} from '../../../../data-access/gql-types/graphql';

export type OptimizerSetModeBody = {
  deviceId: string;
  channelId: string;
  mode: keyof typeof OptimizerModeInternal;
};

export type OptimizerSetTestModeLoadBody = {
  deviceId: string;
  channelId: string;
  scr: number[];
};

export type OptimizerErrorGlobal = {
  description: string;
  errorCode: string;
};

export type WorkingHours = {
  from: string;
  to: string;
};

export type OptimizerSetConfigBody = {
  deviceId: string;
  channelId: string;
  eco?: OptimizerEcoConfigBody;
  comfort?: OptimizerComfortConfigBody;
  fullSelfConsumption?: OptimizerFullConsumptionConfigBody;
  selfConsumptionWithPowerLimit?: OptimizerLimitConsumptionConfigBody;
  selfConsumptionWithRelays?: OptimizerRelaysConsumptionConfigBody;
};

export type OptimizerEcoConfigBody = {
  tempMax: number;
  voltageThreshold: Pick<VoltageThreshold, 'index' | 'value'>[];
  relaysConfig: Omit<RelayConfig, '__typename'>[];
};

type BaseConsumptionConfigBody = {
  tempMax: number;
  tempMin: number;
  heaterPowerLimit: number;
  voltageThreshold: Pick<VoltageThreshold, 'index' | 'value'>[];
  relaysConfig: Omit<RelayConfig, '__typename'>[];
};

type TariffBody = {
  hoursRanges: HoursRangeConfig[];
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  kind: number;
};

export type HoursRangeConfig = {
  from: number;
  to: number;
  timeZoneKind: keyof typeof TimeZoneKindInternal;
};

type TariffConfig = {
  tariff: TariffBody;
};

type TariffOptionalConfig = {
  tariff?: TariffBody;
};

export type OptimizerComfortConfigBody = BaseConsumptionConfigBody & TariffConfig;

export type OptimizerFullConsumptionConfigBody = BaseConsumptionConfigBody &
  TariffOptionalConfig & {
    tempSelfConsumption: number;
    tempMinEnabled: boolean;
    workingHours: WorkingHours;
  };

export type OptimizerLimitConsumptionConfigBody = BaseConsumptionConfigBody &
  TariffOptionalConfig & {
    tempSelfConsumption: number;
    tempMinEnabled: boolean;
    powerLimit: number;
    workingHours: WorkingHours;
  };

export type OptimizerRelaysConsumptionConfigBody = {
  relaysConfig: Omit<RelayConfig, '__typename'>[];
  workingHours: WorkingHours;
};

export type TariffHoursRange = {
  from: number;
  to: number;
  timeZoneKind: TimeZoneKindInternal;
  pricingTier: number;
};

export type TariffData = {
  name: string;
  hoursRanges: TariffHoursRange[];
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
};

export type TariffItem = {
  operator: string;
  tariffs: TariffData[];
};

export const tariffData = {
  [ChannelTypeInternal.Optimizer]: 'optimizer',
  [ChannelTypeInternal.Meter]: 'meter',
};
