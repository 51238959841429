import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { ControlWrapper, Slider } from '../../../../../components';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { defaultFavouritePositionList } from '../../../../../const';
import {
  FavouritePositionType,
  ChannelTypeInternal,
  SetFavouriteChannelPositionsMutation,
  SetFavouriteChannelPositionsMutationVariables,
  BlindStateDirectionInternal,
} from '../../../../../data-access/gql-types/graphql';
import { CHANNEL_SET_FAVOURITE_POSITIONS } from '../../../../../data-access/mutations/channels';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { BlindDetailsControls } from '../../../../../components/channel-details-controls/blind/controls';
import { BlindDetailsFavourites } from '../../../../../components/channel-details-controls/blind/favourites';
import { useChannel } from '../../../components/channel/hooks/use-channel';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useCover } from '../../../hooks';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelCoverType, ChannelCoverStateType, ChannelType } from '../../../types';
import { parseFavouritePositions } from '../../../utils/channels/helpers';
import CalibrationInfoDetails from '../controls/calibration';

type ChannelDetailsCoverProps = {
  channel: ChannelType;
};

const ChannelDetailsCover: React.FC<ChannelDetailsCoverProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleAction } = useCover(channel);
  const { calibrationNeeded } = useChannel({ channel });
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const { updateChannelDetails, setChannelList } = useDevicesAndChannelsContext();
  const [setFavouritePositions] = useMutation<
    SetFavouriteChannelPositionsMutation,
    SetFavouriteChannelPositionsMutationVariables
  >(CHANNEL_SET_FAVOURITE_POSITIONS);
  const state = useChannelsStateStore((s) => s.channelState[channel.id]) as ChannelCoverStateType | null;
  const channelData = channel.data as ChannelCoverType;

  if (channelData.type !== ChannelTypeInternal.Blind) return null;

  useEffect(() => {
    if (state?.movingParams?.targetPosition != null) {
      setTargetValue(state.movingParams.targetPosition);
    } else {
      if (
        state?.lavvaStatePosition != null &&
        (state.lavvaStateDirection === BlindStateDirectionInternal.Stopped ||
          state.lavvaStateDirection === BlindStateDirectionInternal.Open ||
          state.lavvaStateDirection === BlindStateDirectionInternal.Closed)
      ) {
        setTargetValue(state.lavvaStatePosition);
      }
    }
  }, [state?.movingParams?.targetPosition, state?.lavvaStateDirection]);

  useEffect(() => {
    setSliderValue(state?.lavvaStatePosition || 0);
  }, [state?.lavvaStatePosition]);

  const favouritePositionList = parseFavouritePositions(
    channelData.favouritePositionList,
    defaultFavouritePositionList,
    true,
  );

  const onFavouriteClick = (position: number) => {
    handleAction(position);
    setTargetValue(position);
  };

  const onFavouriteHold = (favoritePositionIndex: number) => {
    if (!channel) return;
    const newFavouriteList = cloneDeep(favouritePositionList);
    newFavouriteList[favoritePositionIndex] = state?.lavvaStatePosition || 0;

    setFavouritePositions({
      variables: {
        input: {
          favouritePositions: newFavouriteList.map((position) => ({
            favouritePositionType: FavouritePositionType.Position,
            value: position / 100,
          })),
          channelId: channel.id,
        },
      },
      onCompleted: (data) => {
        if (data.setFavouriteChannelPositions.ok) {
          toastSuccess({ content: t('favouriteSetSuccess') });

          setChannelList((prev) => {
            const tempList = cloneDeep(prev);
            const index = tempList.findIndex((x) => x.id === channel.id);

            if (index !== -1) {
              (tempList[index].data as ChannelCoverType).favouritePositionList = [...newFavouriteList];
            }

            return tempList;
          });

          updateChannelDetails((prev) => {
            const temp = cloneDeep(prev);
            if (temp) (temp.data as ChannelCoverType).favouritePositionList = [...newFavouriteList];

            return temp;
          });
        } else {
          toastError({ content: tc('errors.somethingWentWrong') });
          // TODO errors
        }
      },
    });
  };

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    handleAction(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetValue(Number(event.target.value));
  };

  return (
    <LavvaDetailsWrapper>
      <div className="cover-detail-view">
        <>
          {!calibrationNeeded ? (
            <ControlWrapper>
              <div className="cover-detail-view__slider">
                <Slider
                  onPointerUp={handleSliderEvent}
                  value={sliderValue}
                  active={!!(state?.movingParams.predictedTimeInMs || state?.movingParams.slatsPredictedTimeInMs)}
                  targetValue={targetValue}
                  onChange={onChange}
                />
              </div>
            </ControlWrapper>
          ) : (
            <CalibrationInfoDetails channel={channel} />
          )}
          <BlindDetailsControls
            downButton={{
              handleClick: () => {
                handleAction('close');
                setTargetValue(100);
              },
            }}
            stopButton={{
              handleClick: () => handleAction('stop'),
            }}
            upButton={{
              handleClick: () => {
                handleAction('open');
                setTargetValue(0);
              },
            }}
          />
          {!calibrationNeeded && (
            <BlindDetailsFavourites
              favouritePositionList={favouritePositionList}
              onFavouriteClick={onFavouriteClick}
              onFavouriteHold={onFavouriteHold}
              lastPosition={channel.data.type === ChannelTypeInternal.Blind ? state?.lavvaStatePosition : 0}
              icon="blind"
              microventilation={{
                position: 99,
                lastPosition: state?.lavvaStatePosition,
                handleMicroventilation: () => {
                  handleAction(99);
                  setTargetValue(99);
                },
              }}
            />
          )}
        </>
      </div>
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsCover;
