import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hours, Minutes, Seconds } from 'lavva.exalushome/build/js/Helpers';
import { TimeSpanParam } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { LockExecutionTask, LockType } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { Checkbox, Header, InputSelect, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { ActionAdvancedIconTypeEnum } from '../../../../../../../types';
import { ActionChecked, LockTaskParams } from '../../../../../../../types/actions';
import { getTimeParts } from '../../../../../../../utils/helpers';
import { toastError } from '../../../../../../../utils/toast';
import { ActionAdvancedIcon } from '../../../../../../../components/advanced-icon';
import { useActions } from '../../../../action-list/hooks/use-actions';
import { TimeInput } from '../../../components/time-input';
import { useTaskEdit } from '../../../hooks/use-task-edit';
import './index.scss';

const LockView: React.FC = () => {
  const { t } = useTranslation('action');
  const [startTime, setStartTime] = useState<string>('00:00:00');
  const [endTime, setEndTime] = useState<string>('00:00:00');
  const [lockType, setLockType] = useState<LockType>(LockType.Timeout);
  const [actionList, setActionList] = useState<ActionChecked[]>([]);
  const { task, finalizeLockTaskSubmit } = useTaskEdit();
  const { actions, isFetched } = useActions();

  useEffect(() => {
    if (task) {
      const type = (task.taskParams as LockTaskParams).lockType;
      const lockEnd = (task.taskParams as LockTaskParams).lockEnd;
      setLockType(type);
      setStartTime((task.taskParams as LockTaskParams).lockStart);

      if (lockEnd) setEndTime(lockEnd);
    }
  }, [task]);

  useEffect(() => {
    if (actions) {
      const checkedActions: ActionChecked[] = actions.map((action) => ({
        deviceGuid: action.DeviceGuid,
        icon: <ActionAdvancedIcon iconName={action.Icon || ActionAdvancedIconTypeEnum.DEFAULT} />,
        title: action.Name,
        checked: !task
          ? false
          : !!(task.taskParams as LockTaskParams).resetLockScenesGuids.find((x) => x === action.DeviceGuid),
      }));

      setActionList(checkedActions);
    }
  }, [actions, task?.taskParams]);

  const handleActionCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const foundIndex = actionList.findIndex((x) => x.deviceGuid === value);

    if (foundIndex !== -1) {
      const newList = [...actionList];
      newList[foundIndex].checked = !newList[foundIndex].checked;
      setActionList(newList);
    }
  };

  const handleSubmit = () => {
    if (lockType === LockType.TimeRange && startTime >= endTime) {
      toastError({ content: t('action.create.tasks.errors.timeStartEnd') });
      return;
    }

    const task = new LockExecutionTask();
    task.LockType = lockType;
    task.ResetLockScenesGuids = actionList.filter((x) => x.checked).map((x) => x.deviceGuid);

    const startTimeParts = getTimeParts(startTime);
    const startHours = startTimeParts.hours as Hours;
    const startMinutes = startTimeParts.minutes as Minutes;
    const startSeconds = startTimeParts.seconds as Seconds;
    task.LockStartHour = new TimeSpanParam(startSeconds, startMinutes, startHours, 0);

    if (lockType === LockType.TimeRange) {
      const endTimeParts = getTimeParts(endTime);
      const endHours = endTimeParts.hours as Hours;
      const endMinutes = endTimeParts.minutes as Minutes;
      const endSeconds = endTimeParts.seconds as Seconds;
      task.LockEndHour = new TimeSpanParam(endSeconds, endMinutes, endHours, 0);
    }

    finalizeLockTaskSubmit(task);
  };

  return (
    <Page
      isStickyHeader
      className="lock-view"
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.lockExecution')} />
        </>
      }
    >
      <>
        <div className="grid-list-24">
          <InputSelect
            options={[
              {
                label: t('action.create.tasks.time'),
                value: LockType.Timeout,
              },
              {
                label: t('action.create.tasks.timeRange'),
                value: LockType.TimeRange,
              },
            ]}
            value={lockType}
            onChange={setLockType}
            label={`${t('action.create.tasks.lockAt')}:`}
          />
          <TimeInput
            time={startTime}
            setTime={setStartTime}
            label={t(`action.create.tasks.${lockType === LockType.Timeout ? 'time' : 'startTime'}`)}
          />
          {lockType === LockType.TimeRange && (
            <TimeInput time={endTime} setTime={setEndTime} label={t('action.create.tasks.endTime')} />
          )}
        </div>
        {isFetched && actionList.length ? (
          <div className="action-list">
            <h3>{t('action.create.tasks.resetLockScenesGuids.header')}</h3>
            <div className="reset-description">{t('action.create.tasks.resetLockScenesGuids.content')}</div>
            <div className="grid-list-24">
              {actionList.map((action) => (
                <Checkbox
                  id={action.deviceGuid}
                  key={action.deviceGuid}
                  checked={action.checked}
                  onChange={handleActionCheck}
                >
                  <div className="action-checkItem">
                    <div className="icon">{action.icon}</div>
                    <div className="title">{action.title}</div>
                  </div>
                </Checkbox>
              ))}
            </div>
          </div>
        ) : null}
        <SubmitButton onClick={handleSubmit} />
      </>
    </Page>
  );
};

export default LockView;
