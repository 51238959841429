import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDevicesAndChannelsContext } from '../../../../../../context/devices-and-channels';
import { InputSliderDialog } from '../input-slider-dialog';

export const OptimizerConfigurationHeaterPowerLimit: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { watch } = useFormContext();
  const { optimizerConfigValidation } = useDevicesAndChannelsContext();
  const { heaterPowerLimit } = watch();

  return (
    <>
      <InputSliderDialog
        propertyName="heaterPowerLimit"
        propertyValue={heaterPowerLimit}
        unit="%"
        dialogTitle={t('optimizer.configuration.heaterPowerLimit.heading')}
        headerTitle={t('optimizer.configuration.heaterPowerLimit.heading')}
        noBottomMargin
        maxWidth
        {...(optimizerConfigValidation ? { min: optimizerConfigValidation.heaterPowerLimit.min } : {})}
        {...(optimizerConfigValidation ? { max: optimizerConfigValidation.heaterPowerLimit.max } : {})}
        {...(optimizerConfigValidation?.heaterPowerLimit.step
          ? { step: optimizerConfigValidation.heaterPowerLimit.step }
          : {})}
      />
    </>
  );
};
