import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType as ChannelTaskType,
  IDeviceTaskTypeInfo as IChannelTaskTypeInfo,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ChannelType, GateType, LightType, SensorType } from '../../enums';
import { canChannelDo, canChannelReturnAny, canOnlyChannelReturnAny } from '../channel/can-channel-do';

export const getChannelType = (channel: IDeviceChannel): ChannelType => {
  if (!channel?.GetDevice) return ChannelType.Unknown;
  if (canChannelDo(channel, [ChannelTaskType.TurnOff, ChannelTaskType.TurnOn])) {
    if (
      canChannelDo(channel, [ChannelTaskType.SetLightBrightness]) ||
      canChannelDo(channel, [ChannelTaskType.LightRGBW])
    )
      return ChannelType.Light;
    return ChannelType.Switch;
  } else if (
    channel?.AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetBlindPosition) ||
    channel?.AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetBlindPositionSimple) ||
    channel
      ?.GetDevice()
      .AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetBlindPositionSimple)
  ) {
    return ChannelType.Blind;
  } else if (
    channel?.AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetFacadePosition) ||
    channel
      ?.GetDevice()
      .AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === ChannelTaskType.SetFacadePosition)
  ) {
    return ChannelType.Facade;
  } else if (
    (canChannelDo(channel, [ChannelTaskType.ChangeGatePositionPulse]) ||
      canChannelDo(channel, [ChannelTaskType.PreciseGateControl]) ||
      canChannelDo(channel, [ChannelTaskType.SetGatePosition]) ||
      canChannelDo(channel, [ChannelTaskType.SetGatewayPosition])) &&
    !canChannelReturnAny(channel, [ChannelResponseType.IntercomState])
  ) {
    return ChannelType.Gate;
  } else if (canChannelReturnAny(channel, [ChannelResponseType.IntercomState])) {
    return ChannelType.EntryPhone;
  } else if (canChannelReturnAny(channel, [ChannelResponseType.OnlineCamera])) {
    return ChannelType.Camera;
  } else if (canChannelReturnAny(channel, [ChannelResponseType.MeasuredEnergy])) {
    return ChannelType.Meter;
  } else if (
    canChannelReturnAny(channel, [
      ChannelResponseType.BlindRemoteButtonState,
      ChannelResponseType.FacadeRemoteButtonState,
      ChannelResponseType.RemoteButtonState,
    ])
  ) {
    return ChannelType.Remote;
  } else if (
    canChannelReturnAny(channel, [
      ChannelResponseType.MeasuredWindSpeed,
      ChannelResponseType.WindThreshold,
      ChannelResponseType.HumiditySensorState,
      ChannelResponseType.MeasuredBrightness,
      ChannelResponseType.MeasuredTemperature,
      ChannelResponseType.MovementSensorState,
      ChannelResponseType.FloodSensorState,
      ChannelResponseType.PressureSensorState,
      ChannelResponseType.ReedState,
    ])
  ) {
    return ChannelType.Sensor;
  } else {
    return ChannelType.Unknown;
  }
};

export const getSensorType = (channel: IDeviceChannel): SensorType => {
  switch (true) {
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.MeasuredWindSpeed, ChannelResponseType.WindThreshold]):
      return SensorType.Wind;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.HumiditySensorState]):
      return SensorType.Humidity;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.MeasuredBrightness]):
      return SensorType.MeasuredBrightness;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.MeasuredTemperature]):
      return SensorType.Temperature;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.MovementSensorState]):
      return SensorType.Movement;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.FloodSensorState]):
      return SensorType.Flood;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.PressureSensorState]):
      return SensorType.Pressure;
    case canOnlyChannelReturnAny(channel, [ChannelResponseType.ReedState]):
      return SensorType.Reed;
    default:
      return SensorType.Unknown;
  }
};

export const getLightType = (channel: IDeviceChannel): LightType => {
  switch (true) {
    case canChannelDo(channel, [ChannelTaskType.LightRGBW]):
      return LightType.Rgb;
    default:
      return LightType.Dimmer;
  }
};

export const getGateType = (channel: IDeviceChannel): GateType => {
  switch (true) {
    case canChannelDo(channel, [ChannelTaskType.PreciseGateControl]):
      return GateType.GatePrecise;
    default:
      return GateType.Gate;
  }
};

export const getPreciseChannelType = (channel?: IDeviceChannel) => {
  if (channel) {
    const type = getChannelType(channel);
    if (type === ChannelType.Light) return getLightType(channel);
    if (type === ChannelType.Sensor) return getSensorType(channel);
    if (type === ChannelType.Gate) return getGateType(channel);
    else return type;
  }

  return ChannelType.Unknown;
};
