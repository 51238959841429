import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { useQuery } from '@apollo/client';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ActionDetailsQueryVariables,
  ActivityType,
  BlindStateDirectionInternal,
  ChannelTypeInternal,
  Query,
} from '../../../../../data-access/gql-types/graphql';
import { ACTION_DETAILS } from '../../../../../data-access/queries/actions';
import {
  BlindStateTask,
  DimStateTask,
  GateStateTask,
  OnOffStateTask,
  RGBWStateTask,
} from '../../../../../types/actions';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useLavvaCreateActionContext } from '../context';
import { DeviceTaskParams, GateActionInternal, SceneTaskType, Task, TaskVariant } from '../types';

export const useActionEdit = () => {
  const location = useLocation();
  const { setName, setIcon, setTaskList, clearAll, setIsDuplicate } = useLavvaCreateActionContext();
  const { selectedInstallationId } = useInstallationContext();
  const { channelList } = useDevicesAndChannelsContext();
  const { actionId } = useParams<{ actionId: string }>();
  const id = actionId || location.state?.actionId;
  const { data } = useQuery<Query, ActionDetailsQueryVariables>(ACTION_DETAILS, {
    variables: { actionId: id, installationId: selectedInstallationId },
    skip: !id,
  });

  useEffect(() => {
    clearAll();
  }, []);

  const getVariantByActivityType = (type: ActivityType, channelType: ChannelTypeInternal) => {
    switch (type) {
      case ActivityType.On:
      case ActivityType.Off:
        return TaskVariant.OnOff;
      case ActivityType.Open:
      case ActivityType.Close:
      case ActivityType.Stop:
      case ActivityType.SetPosition:
        return channelType === ChannelTypeInternal.Gate ? TaskVariant.SetGate : TaskVariant.SetBlind;
      case ActivityType.SetDim:
        return TaskVariant.Dim;
      case ActivityType.SetLightColor:
        return TaskVariant.SetRGBW;
      default:
        return TaskVariant.Undefined;
    }
  };

  const getStateByVariant = (variant: TaskVariant, activityType: ActivityType, value: string) => {
    switch (variant) {
      case TaskVariant.OnOff:
        return { isOn: activityType === ActivityType.On } as OnOffStateTask;
      case TaskVariant.Dim:
        return { brightness: parseInt(value) } as DimStateTask;
      case TaskVariant.SetRGBW: {
        const [R, G, B] = value.split(',').map((x) => parseInt(x));
        return { R, G, B } as RGBWStateTask;
      }
      case TaskVariant.SetBlind: {
        if (activityType === ActivityType.Open) {
          return {
            action: BlindStateDirectionInternal.Open,
            position: null,
          } as BlindStateTask<BlindStateDirectionInternal>;
        }

        if (activityType === ActivityType.Close) {
          return {
            action: BlindStateDirectionInternal.Closed,
            position: null,
          } as BlindStateTask<BlindStateDirectionInternal>;
        }

        if (activityType === ActivityType.Stop) {
          return {
            action: BlindStateDirectionInternal.Stopped,
            position: null,
          } as BlindStateTask<BlindStateDirectionInternal>;
        }

        return {
          action: BlindStateDirectionInternal.Unknown,
          position: value ? parseInt(value) : 0,
        } as BlindStateTask<BlindStateDirectionInternal>;
      }
      case TaskVariant.SetGate: {
        if (activityType === ActivityType.Open) {
          return {
            action: GateActionInternal.Open,
            position: null,
          } as GateStateTask;
        }

        if (activityType === ActivityType.Close) {
          return {
            action: GateActionInternal.Closed,
            position: null,
          } as GateStateTask;
        }

        if (activityType === ActivityType.Stop) {
          return {
            action: GateActionInternal.Stopped,
            position: null,
          } as GateStateTask;
        }

        return {
          action: GateActionInternal.Unknown,
          position: value ? parseInt(value) : 0,
        } as GateStateTask;
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (data?.userAction) {
      setName(data.userAction.action.name);
      setIcon(data.userAction.iconName);

      if (location.state?.actionId) setIsDuplicate(true);

      const tasks = data.userAction.action.channelActions.map((action) => {
        const channel = channelList.find((c) => c.id === action.channelId);
        const variant = getVariantByActivityType(action.activityType, action.channelType);
        const state = getStateByVariant(variant, action.activityType, action.value);

        if (channel && state) {
          return {
            id: uuid.v4(),
            taskType: SceneTaskType.ChannelState,
            taskParams: {
              channel,
              state,
              variant,
            } as DeviceTaskParams,
          };
        }
      }) as Task[];

      setTaskList(tasks);
    }
  }, [data, channelList, location.state?.actionId]);
};
