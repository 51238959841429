import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../../../../components';
import { UserAction } from '../../../../../../data-access/gql-types/graphql';
import { ActionBox } from '../../../../components';

type GroupActionsProps = {
  defaultActions: string[];
  userActions: UserAction[];
  handleSelectedActions: (items: string[]) => void;
};

const GroupActions: React.FC<GroupActionsProps> = ({ userActions, defaultActions, handleSelectedActions }) => {
  const [selectedActions, setSelectedActions] = useState<string[]>(defaultActions);
  const { t } = useTranslation('groups');

  useEffect(() => setSelectedActions(defaultActions), [defaultActions]);

  const handleActionsOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (checked && !selectedActions.includes(value)) {
      const values = [...selectedActions, value];
      handleSelectedActions(values);
      setSelectedActions(values);
    }

    if (!checked && selectedActions.includes(value)) {
      const values = selectedActions.filter((v) => v !== value);
      handleSelectedActions(values);
      setSelectedActions(values);
    }
  };

  if (!userActions?.length) return <p className="form-actions__placeholder-text">{t('actionsPlaceholder')}</p>;

  return (
    <ul className="form-actions grid-list-16">
      {userActions?.map((item: UserAction) => (
        <li key={item.action.id}>
          <Checkbox
            isFullWidth
            id={item.action.id}
            checked={selectedActions.includes(item.action.id)}
            onChange={handleActionsOnChange}
          >
            <ActionBox showControl={false} actionId={item.action.id} isListItem actionData={item} />
          </Checkbox>
        </li>
      ))}
    </ul>
  );
};

export default GroupActions;
