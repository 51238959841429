import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Input, InputEdit } from '../../../../components';
import { useInstallationContext } from '../../../../context/installation';
import {
  ChangeInstallationNameMutation,
  ChangeInstallationNameMutationVariables,
  InstallationModelResponse,
  UserInstallation,
} from '../../../../data-access/gql-types/graphql';
import { CHANGE_INSTALLATION_NAME } from '../../../../data-access/mutations/installations';
import { useBackdropContext } from '../../../../context/backdrop';
import { useMutationErrors } from '../../../../hooks';
import { MutationErrorType } from '../../../../types';
import { isLocalApp } from '../../../../utils/helpers/local-app';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { patterns } from '../../../../utils/validation';
import { CreateInstallationControlForm } from '../../types';

type InstallationNameProps = {
  disabled?: boolean;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
  updateInstallation?: (key: keyof UserInstallation, value: string | InstallationModelResponse) => void;
};

const InstallationName: React.FC<InstallationNameProps> = ({ disabled = false, setDisabled, updateInstallation }) => {
  const { control, getValues } = useFormContext<CreateInstallationControlForm>();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId, selectedInstallation } = useInstallationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Installation);
  const [changeInstallationName] = useMutation<ChangeInstallationNameMutation, ChangeInstallationNameMutationVariables>(
    CHANGE_INSTALLATION_NAME,
  );

  const onSaveName = () => {
    const name = getValues('installation_name');

    if (updateInstallation && selectedInstallation?.name !== name) {
      if (!isLocalApp) {
        turnOnBackdrop();
        changeInstallationName({
          variables: {
            input: {
              installationId: selectedInstallationId,
              name,
            },
          },
          onCompleted: (data) => {
            turnOffBackdrop();
            if (!data || !data.changeInstallationName) {
              toastError({ content: tc('errors.somethingWentWrong') });
              return;
            }

            if (data.changeInstallationName.result?.ok) {
              updateInstallation('name', name);
              toastSuccess({ content: t('nameEditSuccess') });
              if (setDisabled) setDisabled(true);
            } else {
              handleErrors(data.changeInstallationName.errors || []);
            }
          },
          onError: () => turnOffBackdrop(),
        });
      } else {
        updateInstallation('name', name);
        toastSuccess({ content: t('nameEditSuccess') });
        if (setDisabled) setDisabled(true);
      }
    } else {
      if (setDisabled) setDisabled(true);
    }
  };

  return (
    <Controller
      name="installation_name"
      control={control}
      rules={{
        required: {
          value: true,
          message: tc('isRequired'),
        },
        pattern: {
          value: patterns.name,
          message: tc('invalidName'),
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return !setDisabled ? (
          <Input
            defaultValue={field.value}
            placeholder={t('namePlaceholder')}
            onChange={field.onChange}
            label={t('name')}
            {...(error ? { errorMessage: error.message, hasError: true } : {})}
          />
        ) : (
          <InputEdit
            disabled={disabled}
            defaultValue={field.value}
            onChange={field.onChange}
            setDisabled={setDisabled}
            onSave={onSaveName}
            label={t('name')}
            {...(error ? { errorMessage: error.message } : {})}
          />
        );
      }}
    />
  );
};

export default InstallationName;
