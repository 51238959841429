import { ExalusInstallationCredentials } from '../types/exalus';
import * as exalusStorage from '../storage';

export const saveExalusCredentials = ({
  id,
  email,
  password,
  serialNumber,
  pin,
}: ExalusInstallationCredentials) => {
  const installationsCredentials = exalusStorage.getItem('installationsCredentials') || [];
  const foundInstallationIndex = installationsCredentials.findIndex((x) => x.id === id);

  if (foundInstallationIndex === -1) {
    installationsCredentials.push({ id, email, password, serialNumber, pin });
  }

  exalusStorage.setItem('installationsCredentials', installationsCredentials);
};

export const removeExalusCredentials = (id: string) => {
  const installationsCredentials = exalusStorage.getItem('installationsCredentials') || [];
  const foundInstallationIndex = installationsCredentials.findIndex((x) => x.id === id);

  if (foundInstallationIndex !== -1) {
    installationsCredentials.splice(foundInstallationIndex, 1);
    exalusStorage.setItem('installationsCredentials', installationsCredentials);
  }
};

export const getExalusInstallationCredentials = (installationId?: string): ExalusInstallationCredentials | null => {
  if (!installationId) return null;
  const installationsCredentials = exalusStorage.getItem('installationsCredentials') || [];
  const foundInstallationCredentials = installationsCredentials.find((x) => x.id === installationId);

  return foundInstallationCredentials || null;
};
