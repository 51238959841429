import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Header,
  NavHeader,
  Page,
  SubmitButton,
  PopUpNav,
  DialogConfirmation,
  CustomBackdrop,
} from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { IconDelete, IconEdit, IconExit } from '../../../components/popup-nav/icons';
import { useInstallationContext } from '../../../context/installation';
import { InstallationAccessType, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useProfile } from '../../../hooks';
import { useUserPermissions } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { SharedItemType } from '../../../types';
import UserBox from '../components/user-box';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { getSharedItemType } from '../utils';
import './index.scss';

const SharedUser: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { user } = useProfile();
  const {
    state,
    installationName,
    methods: { handleSetSharePermission, handleSetUserId, handleResetShareInstallation },
  } = useInstallationShare();
  const { permissions } = useUserPermissions(state.installationId);
  const { userSharing, userSharingLoading, deleteUserFromInstallation } = useMember(true);
  const { setSelectedInstallationId } = useInstallationContext();
  const [removePopup, setRemovePopup] = useState<boolean>(false);
  const [signOffPopup, setSignOffPopup] = useState<boolean>(false);

  const forwardInstallation = () => {
    handleSetUserId(userSharing?.sharingInfo?.sharedToUserId);
    navigate(ROUTES.InstallationForwardSummary(state.installationId));
  };

  const removeUserFromInstallation = () => {
    handleSetUserId(userSharing?.sharingInfo?.sharedToUserId || '');
    setRemovePopup(true);
  };

  const editGroups = () => {
    navigate(ROUTES.UserSharedGroups(userSharing?.sharingInfo?.sharedToUserId || ''));
  };

  const editChannels = () => {
    navigate(ROUTES.UserSharedChannels(userSharing?.sharingInfo?.sharedToUserId || ''));
  };

  const sharedItemType = useMemo(() => {
    if (userSharing?.sharingInfo) return getSharedItemType(userSharing?.sharingInfo);
  }, [userSharing]);

  const contentView = useMemo(() => {
    if (!userSharing?.sharingInfo) return <></>;

    switch (userSharing.sharingInfo.installation.accessType) {
      case InstallationAccessType.Admin:
        return <div className="info">{t('shared_user.admin_info')}</div>;
      default:
        return (
          <div className="user-content">
            <hr className="m-b-24" />
            {sharedItemType === SharedItemType.Installation ? (
              <div className="info">{t('shared_user.all_installation_info')}</div>
            ) : (
              <div className="grid-list-24">
                <ArrowButton
                  title={t('shared_user.groups')}
                  onClick={editGroups}
                  subTitle={userSharing.sharingInfo.groups.length.toString()}
                />
                <ArrowButton
                  title={t('shared_user.channels')}
                  onClick={editChannels}
                  subTitle={userSharing.sharingInfo.channels.length.toString()}
                />
              </div>
            )}
          </div>
        );
    }
  }, [userSharing, sharedItemType]);

  const changeRole = () => {
    if (userSharing?.sharingInfo?.installation.accessType === InstallationAccessType.Owner) return;
    if (userSharing?.sharingInfo?.sharedToUserId) {
      handleResetShareInstallation();
      handleSetUserId(userSharing.sharingInfo.sharedToUserId);
      handleSetSharePermission(userSharing.sharingInfo.installation.accessType);
      navigate(ROUTES.InstallationShareRole());
    }
  };

  const signOffFromInstallation = (admin?: boolean) => {
    deleteUserFromInstallation(state.userId, state.installationId, () => {
      setSignOffPopup(false);
      if (admin) {
        navigate(ROUTES.Installation(), { replace: true });
        setSelectedInstallationId(undefined);
      } else navigate(-1);
    });
  };

  const goBack = () => {
    handleResetShareInstallation();
    navigate(-1);
  };

  return (
    <Page
      className="shared-user"
      header={
        <>
          <NavHeader onClick={goBack}>
            {userSharing?.sharingInfo?.installation.accessType !== InstallationAccessType.Owner && (
              <PopUpNav
                links={[
                  ...(permissions[UserPermissionType.ChangeUserRole]
                    ? [{ onClick: changeRole, label: t('shared_user.change_role'), icon: <IconEdit /> }]
                    : []),
                  {
                    onClick: () => setSignOffPopup(true),
                    icon: <IconExit />,
                    hasLineBelow: userSharing?.sharingInfo?.sharedToUserId !== user.profile.id,
                    label:
                      userSharing?.sharingInfo?.sharedToUserId === user.profile.id &&
                      userSharing.sharingInfo.installation.accessType === InstallationAccessType.Admin
                        ? t('signOffInstallation')
                        : '',
                  },
                  ...(permissions[UserPermissionType.RemoveUserFromInstallation] &&
                  userSharing?.sharingInfo?.sharedToUserId !== user.profile.id
                    ? [
                        {
                          onClick: removeUserFromInstallation,
                          icon: <IconDelete />,
                          label: t('shared_user.remove_user_from_installation'),
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </NavHeader>
          <Header isUnderline>
            {userSharing?.sharingInfo && (
              <UserBox
                key={userSharing.sharingInfo.sharedToUserId}
                id={userSharing.sharingInfo.sharedToUserId || ''}
                name={userSharing.sharingInfo.sharedToUser?.firstName || ''}
                email={userSharing.sharingInfo.sharedToUser?.email || ''}
                img={userSharing.sharingInfo.sharedToUser?.profileImage?.imageUrl || ''}
                accessType={userSharing.sharingInfo.installation.accessType}
                sharedItemType={sharedItemType || SharedItemType.Undefined}
              />
            )}
          </Header>
        </>
      }
    >
      <>
        {!userSharingLoading ? (
          <>
            <div className="shared-user-content">
              <div className="dates max-width-desktop">
                <div>
                  <div>
                    <p className="shared-user--label">{t('shared_user.shared_from')}</p>
                    <p className="shared-user--value">
                      {userSharing?.sharingInfo?.installation.sharedFrom
                        ? format(new Date(userSharing?.sharingInfo?.installation.sharedFrom), 'dd.MM.yyyy, HH:mm')
                        : userSharing?.sharingInfo?.createdAt
                        ? format(new Date(userSharing?.sharingInfo?.createdAt), 'dd.MM.yyyy, HH:mm')
                        : '-'}
                    </p>
                  </div>
                  {userSharing?.sharingInfo?.installation.sharedUntil && (
                    <div className="m-t-16">
                      <p className="shared-user--label">{t('shared_user.shared_until')}</p>
                      <p className="shared-user--value">
                        {format(new Date(userSharing.sharingInfo.installation.sharedUntil), 'dd.MM.yyyy, HH:mm')}
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  <p className="shared-user--label">{t('shared_user.last_activity')}</p>
                  <p className="shared-user--value">
                    {userSharing?.sharingInfo?.sharedToUser?.lastActivity
                      ? format(new Date(userSharing?.sharingInfo?.sharedToUser?.lastActivity), 'dd.MM.yyyy')
                      : '-'}
                  </p>
                </div>
              </div>
              {userSharing?.sharingInfo?.sharingUser && (
                <>
                  <p className="shared-user--label">{t('shared_user.shared_from')}</p>
                  <UserBox
                    id={userSharing?.sharingInfo?.sharingUser?.id}
                    name={userSharing?.sharingInfo?.sharingUser?.firstName}
                    email={userSharing?.sharingInfo?.sharingUser?.email}
                    img={userSharing?.sharingInfo?.sharingUser?.profileImage?.imageUrl || ''}
                  />
                </>
              )}
              {contentView}
            </div>
            {permissions[UserPermissionType.PassOwnership] &&
              userSharing?.sharingInfo?.sharedToUserId !== user.profile.id && (
                <SubmitButton type="button" onClick={forwardInstallation}>
                  {t('forward.forward_installation')}
                </SubmitButton>
              )}
          </>
        ) : (
          <CustomBackdrop loading={userSharingLoading} />
        )}
      </>
      <DialogConfirmation
        show={removePopup}
        setShow={setRemovePopup}
        header={t('shared_user.remove_user_from_installation')}
        content={t('shared_user.remove_user_content')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnAction={() => setRemovePopup(false)}
        primaryBtnAction={() => signOffFromInstallation()}
        direction="column"
      />
      <DialogConfirmation
        show={signOffPopup}
        setShow={setSignOffPopup}
        header={t('signOffTitle')}
        content={t('signOffAdminContent', { name: installationName })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={t('signOffInstallation')}
        secondaryBtnAction={() => setSignOffPopup(false)}
        primaryBtnAction={() => signOffFromInstallation(true)}
        direction="column"
      />
    </Page>
  );
};

export default SharedUser;
