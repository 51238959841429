import { useTranslation } from 'react-i18next';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import {
  NotificationClientRegistration,
  NotificationsServiceErrorCode,
} from 'lavva.exalushome/build/js/Services/Notifications/IControllerNotificationsService';
import { useBackdropContext } from '../../../context/backdrop';
import { toastError } from '../../../utils/toast';
import { useExalusServicesContext } from '../context/services';

type EnableDisableParams = {
  lavvaUserName: string;
  onSuccess: () => void;
  showError: boolean;
};

export const useExalusNotifications = () => {
  const { t } = useTranslation('notifications');
  const { notificationsApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleNotificationsError = (data: ResponseResult<NotificationsServiceErrorCode>) => {
    switch ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      case NotificationsServiceErrorCode.UnknownError: {
        toastError({ content: t('exalus.error.UnknownError') });
        break;
      }
      case NotificationsServiceErrorCode.NoData: {
        toastError({ content: t('exalus.error.NoData') });
        break;
      }
      case NotificationsServiceErrorCode.IncorrectGuid: {
        toastError({ content: t('exalus.error.IncorrectGuid') });
        break;
      }
      case NotificationsServiceErrorCode.IdentityIsEmpty: {
        toastError({ content: t('exalus.error.IdentityIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.DeviceTokenIsEmpty: {
        toastError({ content: t('exalus.error.DeviceTokenIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.ClientNameIsEmpty: {
        toastError({ content: t('exalus.error.ClientNameIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.LavvaUserNameIsEmpty: {
        toastError({ content: t('exalus.error.LavvaUserNameIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.TokenNotFound: {
        // toastError({ content: t('exalus.error.TokenNotFound') });
        break;
      }
      case NotificationsServiceErrorCode.FeatureUnsupported: {
        // toastError({ content: t('exalus.error.FeatureUnsupported') });
        break;
      }
    }
  };

  const registerNotificationClient = async (registration: NotificationClientRegistration) => {
    const data = await notificationsApi.RegisterNotificationsClientAsync(registration);

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      console.log('TOKEN REGISTER ERROR', data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      console.log('TOKEN REGISTERED', registration, data);
    }
  };

  const getAllControllerNotificationTokens = async () => {
    const data = await notificationsApi.GetRegisteredNotificationsClientsMetadataAsync();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    }

    return data;
  };

  const getNotificationTokenState = async (lavvaUserName: string) => {
    const data = await notificationsApi.IsNotificationsEnabledForAllUserTokensAsync(lavvaUserName);

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    }

    return data;
  };

  const enableControllerNotifications = async ({ lavvaUserName, onSuccess, showError }: EnableDisableParams) => {
    turnOnBackdrop();
    const data = await notificationsApi.EnableNotificationsForAllUserTokensAsync(lavvaUserName);
    turnOffBackdrop();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      if (showError) handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      onSuccess();
    }
  };

  const disableControllerNotifications = async ({ lavvaUserName, onSuccess, showError }: EnableDisableParams) => {
    turnOnBackdrop();
    const data = await notificationsApi.DisableNotificationsForAllUserTokensAsync(lavvaUserName);
    turnOffBackdrop();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      if (showError) handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      onSuccess();
    }
  };

  return {
    registerNotificationClient,
    getAllControllerNotificationTokens,
    getNotificationTokenState,
    enableControllerNotifications,
    disableControllerNotifications,
  };
};
