import { useInstallationContext } from '../../context/installation';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { isLocalApp } from '../../utils/helpers';

type IntegrationPageParams = {
  Lavva: React.ReactElement;
  Exalus: React.ReactElement;
};

export const useIntegrationRoute = ({ Lavva, Exalus }: IntegrationPageParams) => {
  const { integrationType } = useInstallationContext();

  if (isLocalApp) return Exalus;
  if (integrationType === IntegrationType.Exalus) return Exalus;
  return Lavva;
};
