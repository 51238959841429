import { ChannelTypeInternal, UserChannel } from '../../../../../data-access/gql-types/graphql';
import { AvailableIcon } from '../../../../../types';
import { FavouritePositions } from '../../../types';
import {
  parseCoverChannel,
  parseCoverChannelState,
  parseGateChannel,
  parseGateChannelState,
  parseGenericInput,
  parseGenericInputStateChannel,
  parseLightChannel,
  parseLightChannelState,
  parseMeterChannel,
  parseMeterStateChannel,
  parseOptimizerChannel,
  parseOptimizerStateChannel,
  parseSwitchChannel,
  parseSwitchChannelState,
} from '../parse';

export const parseFavouritePositions = (
  favouritePositionList: FavouritePositions,
  defaultList: FavouritePositions,
  allowZero: boolean,
): FavouritePositions => {
  if (favouritePositionList) {
    if (favouritePositionList?.length === 3 && favouritePositionList.every((value) => Number(value))) {
      return favouritePositionList;
    } else {
      if (!allowZero) {
        return [
          favouritePositionList[0] ? favouritePositionList[0] : defaultList[0],
          favouritePositionList[1] ? favouritePositionList[1] : defaultList[1],
          favouritePositionList[2] ? favouritePositionList[2] : defaultList[2],
        ];
      }

      return [
        favouritePositionList[0] !== null ? favouritePositionList[0] : defaultList[0],
        favouritePositionList[1] !== null ? favouritePositionList[1] : defaultList[1],
        favouritePositionList[2] !== null ? favouritePositionList[2] : defaultList[2],
      ];
    }
  } else return defaultList;
};

export const parseChannel = (userChannel: UserChannel) => {
  switch (userChannel.channelType) {
    case ChannelTypeInternal.Blind:
      return parseCoverChannel(userChannel);
    case ChannelTypeInternal.Switch:
      return parseSwitchChannel(userChannel);
    case ChannelTypeInternal.Meter:
      return parseMeterChannel(userChannel);
    case ChannelTypeInternal.Optimizer:
      return parseOptimizerChannel(userChannel);
    case ChannelTypeInternal.Light:
      return parseLightChannel(userChannel);
    case ChannelTypeInternal.Gate:
      return parseGateChannel(userChannel);
    case ChannelTypeInternal.GenericInput:
      return parseGenericInput(userChannel);
    default:
      return undefined;
  }
};

export const parseChannelState = (userChannel: UserChannel) => {
  switch (userChannel.channelType) {
    case ChannelTypeInternal.Blind:
      return parseCoverChannelState(userChannel);
    case ChannelTypeInternal.Switch:
      return parseSwitchChannelState(userChannel);
    case ChannelTypeInternal.Light:
      return parseLightChannelState(userChannel);
    case ChannelTypeInternal.Gate:
      return parseGateChannelState(userChannel);
    case ChannelTypeInternal.Meter:
      return parseMeterStateChannel(userChannel);
    case ChannelTypeInternal.Optimizer:
      return parseOptimizerStateChannel(userChannel);
    case ChannelTypeInternal.GenericInput:
      return parseGenericInputStateChannel(userChannel);
    default:
      return undefined;
  }
};

export const findChannelIcon = (availableIcons: AvailableIcon[], iconName?: string): JSX.Element => {
  const icon = availableIcons.find((x) => x.iconName === iconName || x.oldIconName === iconName);
  return icon ? icon.component : availableIcons[0].component;
};
