import { useApolloClient } from '@apollo/client';

export const useRefetchData = () => {
  const apolloClient = useApolloClient();

  const clearCache = (fieldName: string) => {
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName,
    });
  };

  const refetchDevices = async () => {
    clearCache('allUserDevices');
    apolloClient.cache.gc();

    return await apolloClient.refetchQueries({ include: ['DevicesGetAll'] });
  };

  const refetchActions = async () => {
    clearCache('allUserActions');
    apolloClient.cache.gc();

    return await apolloClient.refetchQueries({ include: ['AllActions'] });
  };

  const refetchDashboard = async () => {
    clearCache('allUserGroups');
    apolloClient.cache.gc();

    return await apolloClient.refetchQueries({ include: ['DashboardItems'] });
  };

  const refetchTriggers = async () => {
    return await apolloClient.refetchQueries({ include: ['AllTriggers'] });
  };

  const refetchChannelDetails = async () => {
    clearCache('userChannel');
    apolloClient.cache.gc();
    return await apolloClient.refetchQueries({ include: ['ChannelsDetails'] });
  };

  const refetchAll = async () => {
    clearCache('allUserChannels');
    clearCache('allUserDevices');
    clearCache('allUserGroups');
    clearCache('allUserActions');
    apolloClient.cache.gc();

    return await apolloClient.refetchQueries({
      include: ['InstallationGetAll', 'ChannelsGetAll', 'DevicesGetAll', 'DashboardItems', 'AllActions'],
    });
  };

  return {
    refetchDevices,
    refetchActions,
    refetchDashboard,
    refetchTriggers,
    refetchChannelDetails,
    refetchAll,
  };
};
