import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { CustomBackdrop, StatusScreen } from '../../components';
import { useNotification } from '../../hooks/notifications/use-notification';
import { ROUTES } from '../../routes';
import { NotificationTypeEnum, StatusTypeEnum } from '../../types';

const InvitationAccepted: React.FC = () => {
  const { t } = useTranslation('invitation');
  const navigate = useNavigate();
  const { notificationId } = useParams<{ notificationId: string }>();
  const { notification, loading } = useNotification(notificationId);
  const data = notification && notification.data.type === NotificationTypeEnum.INVITATION ? notification.data : null;

  const onAccept = () => navigate(ROUTES.Root(), { replace: true });

  if (loading) return <CustomBackdrop />;
  if (!data) return null;

  return (
    <StatusScreen
      header={t('accepted.title')}
      subTitle={t('accepted.message', { inviteeName: data.userName })}
      type={StatusTypeEnum.SHARE}
      secondaryBackground
      primaryBtnText={t('accepted.acceptInvitation')}
      onPrimaryBtnClick={onAccept}
    />
  );
};

export default InvitationAccepted;
