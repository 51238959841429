import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, DialogConfirmation, EmptyStateBox, IconWarning } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { ChannelTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { ChannelIcon } from '../../../../components';
import { useEvents } from '../../hooks/use-events';
import { GateDialogConfiguration } from './dialog-configurations/gate';
import './index.scss';

export const Events: React.FC = () => {
  const { t } = useTranslation('configuration');
  const { t: ta } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const {
    deviceTypes,
    eventsChannels,
    selectedChannel,
    pushActive,
    handleCloseModal,
    togglePush,
    selectChannel,
    handleSave,
  } = useEvents();

  const renderDialogContent = () => {
    switch (selectedChannel?.data.type) {
      case ChannelTypeInternal.Gate:
        return (
          <GateDialogConfiguration pushActive={pushActive} selectedChannel={selectedChannel} togglePush={togglePush} />
        );
      default: {
        return null;
      }
    }
  };

  return (
    <div className="events-list">
      {deviceTypes.length > 0 ? (
        <>
          {deviceTypes.map((type) => (
            <Accordion key={type} kind="custom" title={tc(`typesPlural.${type}`)} expanded={deviceTypes.length === 1}>
              <div className="grid-list-16">
                {eventsChannels
                  .filter((channel) => channel.data.type === type)
                  .map((channel) => (
                    <ArrowButton
                      key={channel.id}
                      title={channel.alias}
                      onClick={() => selectChannel(channel)}
                      className="m-b-16"
                      small
                      icon={<ChannelIcon channel={channel} />}
                    />
                  ))}
              </div>
            </Accordion>
          ))}
          <DialogConfirmation
            show={!!selectedChannel?.id}
            setShow={handleCloseModal}
            header={t('configuration')}
            primaryBtnText={tc('buttons.save')}
            secondaryBtnText={tc('buttons.cancel')}
            primaryBtnAction={handleSave}
            secondaryBtnAction={handleCloseModal}
            contentClassName="events-dialog"
          >
            {renderDialogContent()}
          </DialogConfirmation>
        </>
      ) : (
        <EmptyStateBox header={ta('events.empty.header')} content={ta('events.empty.content')} icon={<IconWarning />} />
      )}
    </div>
  );
};
