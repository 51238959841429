import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { TriggerOnFailureMode } from '../../../../../../data-access/gql-types/graphql';
import { useTriggerFormContext } from '../../context';
import { useTrigger } from '../../hooks';
import { StepComponentProps } from '../../steps';
import { OnFailureModeStepForm } from '../../types';
import './index.scss';

export const TriggerFailureModeStep: React.FC<StepComponentProps> = ({ isEdit, goToName }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { onFailureMode, setOnFailureMode } = useTriggerFormContext();
  const { isLoading, failureModes } = useTrigger();
  const { control, setValue, handleSubmit, watch } = useForm<OnFailureModeStepForm>({
    mode: 'onChange',
    defaultValues: { failureMode: onFailureMode },
  });
  const { failureMode } = watch();

  useEffect(() => {
    setValue('failureMode', onFailureMode);
  }, [onFailureMode]);

  const onSubmit = (formData: OnFailureModeStepForm) => {
    setOnFailureMode(formData.failureMode);

    if (!isEdit && goToName) {
      goToName();
      return;
    }
  };

  return (
    <div className="failure-mode m-t-24">
      <form onSubmit={handleSubmit(onSubmit)} className="max-width-desktop">
        <Controller
          name="failureMode"
          control={control}
          rules={{
            required: tc('isRequired') as string,
          }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              onChange={field.onChange}
              value={field.value}
              options={failureModes}
              label={t('trigger.failureMode.label')}
              name={field.name}
              {...(error ? { errorMessage: error.message } : {})}
            />
          )}
        />
        {failureMode !== TriggerOnFailureMode.Undefined && (
          <p className="description">{t(`trigger.failureMode.${failureMode}-description`)}</p>
        )}
        <SubmitButton isLoading={isLoading} disabled={failureMode === TriggerOnFailureMode.Undefined}>
          {tc('buttons.next')}
        </SubmitButton>
      </form>
    </div>
  );
};
