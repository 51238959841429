import {
  ChannelTypeInternal,
  GenericInputChannelStateResponse,
  GenericInputTypeInternal,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { ChannelStateType, ChannelType } from '../../../types';

export const parseGenericInput = (userChannel: UserChannel): ChannelType => {
  const channelPayload = userChannel.payload as GenericInputChannelStateResponse | undefined;

  return {
    id: userChannel.id,
    installationId: userChannel.installationId,
    isVisible: userChannel.isVisible,
    alias: userChannel.alias,
    iconName: userChannel.iconName || ChannelIconTypeEnum.ICON_VERTICAL_GATE,
    deviceId: userChannel.deviceId,
    currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
    data: {
      type: ChannelTypeInternal.GenericInput,
      genericInputType: channelPayload?.genericInputType || GenericInputTypeInternal.Unknown,
      monostableMode: channelPayload?.monostableMode || undefined,
      bistableMode: channelPayload?.bistableMode || undefined,
    },
  };
};

export const parseGenericInputStateChannel = (userChannel: UserChannel): ChannelStateType => ({
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  status: null,
});
