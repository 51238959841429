import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  StateOfTurnOnSwitchDeviceParameters,
  SwitchTurnOnState,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import { IRog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/IRog21ConfigService';
import { IRomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/IRomConfigService';
import { IRopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/IRopConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type TurnOnBehaviourFormProps = {
  device: IDevice;
  turnOnBehaviour: StateOfTurnOnSwitchDeviceParameters | null;
};

const TurnOnBehaviourForm: React.FC<TurnOnBehaviourFormProps> = ({ device, turnOnBehaviour }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configService: serviceName, handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<StateOfTurnOnSwitchDeviceParameters>();

  const { State } = form.watch();

  useEffect(() => {
    if (turnOnBehaviour !== null) form.setValue('State', turnOnBehaviour.State);
  }, [turnOnBehaviour]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (turnOnBehaviour) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
        IRdp21ConfigService | IRog21ConfigService | IRopConfigService | IRomConfigService
      >(serviceName);

      const result = await configService.SetTurnOnBehaviourAsync(device, {
        State: values.State,
        Channel: turnOnBehaviour.Channel,
      });
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeState = (value: SwitchTurnOnState) => {
    form.setValue('State', value);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeState}
          value={State}
          options={[
            { label: t('exalus.params.OnOffParams.TurnOnBehaviourSteps.Disabled'), value: SwitchTurnOnState.Disabled },
            { label: t('exalus.params.OnOffParams.TurnOnBehaviourSteps.Enabled'), value: SwitchTurnOnState.Enabled },
            {
              label: t('exalus.params.OnOffParams.TurnOnBehaviourSteps.PreviousState'),
              value: SwitchTurnOnState.PreviousState,
            },
          ]}
          label={t('exalus.params.OnOffParams.TurnOnBehaviourParams')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default TurnOnBehaviourForm;
