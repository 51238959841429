import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, NavHeader } from '../../../../../components';

export const GroupSortHeader: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('groups');

  return (
    <>
      <NavHeader isIconClose onClick={() => navigate(-1)} />
      <Header title={t('sort')} />
    </>
  );
};
