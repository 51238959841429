import { iconsBackwardCompatibility } from '../../components/available-icons';
import { AvailableIcon } from '../../types';

export const getIcon = (iconsList: AvailableIcon[], iconName: string) => {
  const savedIcon = iconsList.find((icon) => icon.iconName === iconName);

  if (savedIcon) return savedIcon;
  const backwardIcon = iconsBackwardCompatibility.find((icon) => icon.iconName === iconName);

  if (backwardIcon) return backwardIcon;

  const defaultIcon = iconsList.find((icon) => icon.iconName === '');
  if (defaultIcon) return defaultIcon;

  if (iconsList.length > 0) return iconsList[0];
};
