import { useRef } from 'react';
import { UseMutationResult, useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNative } from '../../../../hooks';
import { useErrors } from '../../../../hooks/errors';
import { useRestClient } from '../../../../utils/clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import { ActionBatchBody } from './action.types';

export const useActionBatch = (): UseMutationResult<AxiosResponse<DeviceResponse[]>, AxiosError, ActionBatchBody> => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse[]>, AxiosError, ActionBatchBody>(
    ['action-batch'],
    async (body) => {
      fireVibration();
      if (abortControllerRef.current) abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      return await restClient.post<DeviceResponse[]>('/batchControl/batch', body, {
        signal: abortControllerRef.current.signal,
      });
    },
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
