import React, { createContext, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigationType, useNavigate, NavigationType } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { ChildrenProps } from '../../types';

export const RouterHistoryStackContext = createContext({});

const RouterHistoryStackProvider: React.FC<ChildrenProps> = ({ children }) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [stack, setStack] = useState<string[]>([]);

  const dashboardRoutes = [ROUTES.Root(), ROUTES.Channel(), ROUTES.ActionList(), ROUTES.Settings()];

  useLayoutEffect(() => {
    if (navigationType === NavigationType.Pop) {
      if (stack.length > 1) {
        const updatedStack = [...stack];
        const lastRoute = updatedStack.pop();
        setStack(updatedStack);

        if (lastRoute && dashboardRoutes.includes(lastRoute)) {
          navigate(ROUTES.Root(), { replace: true });
          setStack([]);
        }
      }
    } else if (navigationType === NavigationType.Push) {
      setStack((prevStack) => [...prevStack, location.pathname]);
    }
  }, [location, navigationType]);

  return <RouterHistoryStackContext.Provider value={{ stack }}>{children}</RouterHistoryStackContext.Provider>;
};

export default RouterHistoryStackProvider;
