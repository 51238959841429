import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../../components';
import { DeviceType } from '../../../types';
import { useConfiguration } from '../../channel-configuration/hooks/use-configuration';
import './index.scss';

type UpdateDevicesProps = {
  devicesToUpdate: DeviceType[];
};

export const UpdateDevices: React.FC<UpdateDevicesProps> = ({ devicesToUpdate }) => {
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const [showUpdateAllDialog, setShowUpdateAllDialog] = useState<boolean>(false);
  const { updateDevices } = useConfiguration({ devices: devicesToUpdate });

  const onUpdateAllCancel = () => setShowUpdateAllDialog(false);

  const onUpdateAll = () => {
    updateDevices();
    setShowUpdateAllDialog(false);
  };

  return (
    <>
      <span className="devices-update" onClick={() => setShowUpdateAllDialog(true)}>
        {t('updateAll')}
      </span>
      <DialogConfirmation
        show={showUpdateAllDialog}
        setShow={setShowUpdateAllDialog}
        header={t('updateAllContent')}
        primaryBtnText={t('update')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onUpdateAll}
        secondaryBtnAction={onUpdateAllCancel}
      />
    </>
  );
};
