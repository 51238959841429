import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/IZfBidiConfigService';
import { FacadeDetection } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import {
  FacadeConfigurationErrorCode,
  ZfBidiConfigService,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { ROUTES } from '../../../../../../routes';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { ReactionWindParams } from '../../../device-details/types';
import FacadeDetencionForm from './facade-detection';
import ReactionWindForm from './reaction-wind';
import { initialConfig, ZfBidiConfigParams, ZfBidiParamsEnum } from './types';

type ZfBidiConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const ZfBidiConfigForm: React.FC<ZfBidiConfigFormProps> = ({ device, channel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<ZfBidiConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    showGetFacadeParamsError,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: ZfBidiParamsEnum) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IZfBidiConfigService>(
      ZfBidiConfigService.ServiceName,
    );

    switch (panel) {
      case ZfBidiParamsEnum.FACADE_DETECTION: {
        if (channel) {
          const data = await configService.GetFacadeTypeDetectionAsync(device, channel.Number);

          if ((data as ResponseResult<FacadeConfigurationErrorCode>).Type) {
            showGetFacadeParamsError(data as ResponseResult<FacadeConfigurationErrorCode>);
          } else {
            setConfig({ ...initialConfig, [panel]: data as FacadeDetection });
            setOpen(true);
          }
        }
        break;
      }
      case ZfBidiParamsEnum.REACTION_WIND: {
        if (channel) {
          const active = await configService.GetWindSensorActiveAsync(device, channel.Number);

          if ((active as ResponseResult<FacadeConfigurationErrorCode>).Type) {
            showGetFacadeParamsError(active as ResponseResult<FacadeConfigurationErrorCode>);
          } else {
            const lockTime = await configService.GetWindSensorLockTimeAsync(device, channel.Number);

            if ((lockTime as ResponseResult<FacadeConfigurationErrorCode>).Type) {
              showGetFacadeParamsError(lockTime as ResponseResult<FacadeConfigurationErrorCode>);
            } else {
              const blindLocked = await configService.IsBlindLockedByWindSensorAsync(device, channel.Number);

              if ((blindLocked as ResponseResult<FacadeConfigurationErrorCode>).Type) {
                showGetFacadeParamsError(blindLocked as ResponseResult<FacadeConfigurationErrorCode>);
              } else {
                setConfig({ ...initialConfig, [panel]: { active, lockTime, blindLocked } as ReactionWindParams });
                setOpen(true);
              }
            }
          }
        }

        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: ZfBidiParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const goToCalibration = () => {
    if (channel) navigate(ROUTES.FacadeCalibration(channel.ChannelId));
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case ZfBidiParamsEnum.FACADE_DETECTION: {
        return channel ? (
          <FacadeDetencionForm device={device} facadeDetection={config[activeConfigName]} channel={channel} />
        ) : null;
      }
      case ZfBidiParamsEnum.REACTION_WIND: {
        return channel ? (
          <ReactionWindForm device={device} reactionWind={config[activeConfigName]} channel={channel} />
        ) : null;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton title={t('exalus.calibration')} onClick={goToCalibration} className="m-b-24" />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.ZfBidiParams.FacadeDetencion')}
                onClick={() => handleOpen(ZfBidiParamsEnum.FACADE_DETECTION)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.ZfBidiParams.ReactionWind')}
                onClick={() => handleOpen(ZfBidiParamsEnum.REACTION_WIND)}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default ZfBidiConfigForm;
