import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { Api } from 'lavva.exalushome';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { App, AppLocal } from './App';
import queryClient from './utils/clients/query-client';
import { SplashScreen } from './components';
import { BackdropSuspense } from './components/backdrop-suspense';
import { environment } from './environment';
import { initSentry } from './sentry';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Tokens } from './types/auth';
import { isLocalhost, isProd } from './utils/helpers/environment';
import { isLocalApp, registerServiceWorkerShouldRegister } from './utils/helpers/local-app';
import { getKeycloakInitOptions, keycloakInstance, setTokens } from './utils/keycloak';
import { setTheme } from './utils/theme';
import './utils/translations/i18n';

if (isProd || isLocalhost) window.loglevel = 4;
Api.Init();
const keycloakInitOptions = getKeycloakInitOptions();

const Providers: React.FC = () => (
  <Suspense fallback={<BackdropSuspense />}>
    <QueryClientProvider client={queryClient}>
      {!isLocalApp ? <App /> : <AppLocal />}
      {!isProd && <code className="app-version">v.{environment.VERSION}</code>}
    </QueryClientProvider>
  </Suspense>
);

const Root: React.FC = () => {
  const [tokenUpdateCount, setTokenUpdateCount] = useState<number>(0);

  useEffect(() => setTheme(), []);

  const onTokens = useCallback(
    ({ token, idToken, refreshToken }: Tokens) => {
      if (token && idToken && refreshToken) {
        setTokens({ token, idToken, refreshToken });
        setTokenUpdateCount((value) => value + 1);
      }
    },
    [tokenUpdateCount],
  );

  return !isLocalApp ? (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={keycloakInitOptions}
      LoadingComponent={<SplashScreen />}
      onTokens={onTokens}
    >
      <Providers />
    </ReactKeycloakProvider>
  ) : (
    <Providers />
  );
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);
initSentry();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (registerServiceWorkerShouldRegister()) serviceWorkerRegistration.register();
