import React from 'react';
import classNames from 'classnames';
import CircularProgressMui from '@mui/material/CircularProgress';
import './index.scss';

type CircularProgressProps = {
  size?: number;
  className?: string;
};

export const CircularProgress: React.FC<CircularProgressProps> = ({ size = 20, className = '' }) => (
  <div className="circular-progress">
    <CircularProgressMui className={classNames('circular-progress--spinner', className)} size={size} />
  </div>
);
