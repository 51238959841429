import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
import { CustomBackdrop, DialogConfirmation, NavHeader, Page, PopUpNav } from '../../../../components';
import LavvaDetailsWrapper from '../../../../components/details-wrapper';
import { useInstallationContext } from '../../../../context/installation';
import {
  ActionDetailsQuery,
  ActionDetailsQueryVariables,
  ActionType,
  DeleteActionMutation,
  DeleteActionMutationVariables,
  DeviceConnectionState,
  UserAction,
  Action,
  FavouritePosition,
  ChannelTypeInternal,
} from '../../../../data-access/gql-types/graphql';
import { DELETE_ACTION } from '../../../../data-access/mutations/action';
import { ACTION_DETAILS } from '../../../../data-access/queries/actions';
import { useMutationErrors } from '../../../../hooks';
import { useRefetchData } from '../../../../hooks/refresh-data';
import { ROUTES } from '../../../../routes';
import { ActionAdvancedIconTypeEnum, MutationErrorType } from '../../../../types';
import { sortByOrder } from '../../../../utils/helpers';
import { toastError, toastInfo, toastSuccess } from '../../../../utils/toast';
import { ActionDetailsHeader } from '../../components';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelActionDetails } from '../../types';
import { checkActionStatus } from '../../utils/actions/status';
import { GroupsModifyPopup } from '../channel-details/components/groups-modify-popup';
import { RecoveryModeWrapper } from '../channel-details/recovery/wrapper';
import { useCentralAction } from '../channel-list/hooks/use-central-action';
import ActionChannelList from './channels';
import ActionControl from './controls';
import Favourite from './favourite';
import { useActionDetails } from './hooks/use-action-details';
import './index.scss';

const ActionDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { actionId } = useParams<{ actionId: string }>();
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const { channelList } = useDevicesAndChannelsContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Action);
  const channelState = useChannelsStateStore((s) => s.channelState);
  const { refetchActions, refetchDashboard } = useRefetchData();
  const { hasCentralAction, createCentralAction } = useCentralAction();
  const {
    popupLinks,
    groupList,
    handleSaveGroup,
    toggleGroup,
    groupPopup,
    setGroupPopup,
    userAction,
    setUserAction,
    showDeleteDialog,
    showDuplicateDialog,
    setShowDeleteDialog,
    setShowDuplicateDialog,
  } = useActionDetails();

  const isCentral = useMemo(() => !!(actionId && (hasCentralAction as string[]).includes(actionId)), [actionId]);

  const { data, loading } = useQuery<ActionDetailsQuery, ActionDetailsQueryVariables>(ACTION_DETAILS, {
    variables: { actionId, installationId: selectedInstallationId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: () => toastError({ content: tc('errors.downloadData') }),
    skip: skipLavvaFetch || isCentral,
  });

  const onBack = () => {
    if (location.state?.back) navigate(-location.state.back);
    else navigate(-1);
  };

  const [deleteAction] = useMutation<DeleteActionMutation, DeleteActionMutationVariables>(DELETE_ACTION, {
    onCompleted: async (data) => {
      if (!data || !data.deleteAction) {
        toastError({ content: tc('errors.somethingWentWrong') });
        return;
      }

      if (data.deleteAction.result?.ok) {
        await refetchActions();
        await refetchDashboard();
        onBack();
        if (data.deleteAction?.__typename === 'DeleteActionPayload') {
          toastSuccess({ content: t(`${userAction?.action.actionType.toLowerCase()}.removeSuccess`) });
        }
      } else {
        handleErrors(data.deleteAction?.errors || []);
      }
    },
  });

  const duplicateAction = (actionId: string) => {
    navigate(ROUTES.ActionCreate(), { state: { actionId } });
  };

  useEffect(() => {
    if (data) {
      if (!data.userAction) {
        return onBack();
      }

      const newAction: Action = {
        channelType: data.userAction.action.channelType,
        channelActions: data.userAction.action.channelActions,
        id: data.userAction.action.id,
        installationId: data.userAction.action.installationId,
        actionType: data.userAction.action.actionType,
        name: data.userAction.action.name !== 'Favourites' ? data.userAction.action.name : `${t('favourites')}`,
        ownerId: data.userAction.action.ownerId,
        sourceGroupId: data.userAction.action.sourceGroupId,
      };

      const newUserAction: UserAction = {
        iconName: data?.userAction.iconName || ActionAdvancedIconTypeEnum.DEFAULT,
        action: { ...newAction },
        id: data?.userAction.id,
        installationId: data?.userAction.action.installationId,
        userId: data?.userAction.userId,
        favouritePositions:
          (data?.userAction.favouritePositions?.map((position) => {
            return position.__typename === 'PositionFavourites' ? position.value : 0;
          }) as unknown as FavouritePosition[]) || null,
      };

      if (!data.userAction.action.channelActions.length) {
        toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
      }

      setUserAction(newUserAction);
    } else {
      if (actionId && (hasCentralAction as string[]).includes(actionId)) {
        const central = createCentralAction(
          actionId as ChannelTypeInternal,
          channelList.filter((x) => x.data.type === actionId),
        );
        if (central) setUserAction(central);
        else onBack();
      }
    }
  }, [data, actionId, channelList]);

  const mappedArray = useMemo(
    () =>
      userAction?.action.channelActions
        .map((channel) => ({
          ...channel,
          ...channelList.find((element) => element && element.id === channel.channelId),
        }))
        .map((channel) => {
          if (userAction?.action?.actionType === ActionType.Advanced) {
            const channelsActionsArray = userAction?.action?.channelActions;
            const indexOfChannelActions = channelsActionsArray.findIndex(
              (channelAction) => channelAction.channelId === channel.channelId,
            );
            const { activityType, value } = channelsActionsArray[indexOfChannelActions];

            return { ...channel, parameter: { activityType, value } };
          } else {
            return channel;
          }
        })
        .filter((channel) => channel.id)
        .sort(sortByOrder) as ChannelActionDetails[],
    [userAction, channelList],
  );

  const onDuplicateCancel = () => setShowDuplicateDialog(false);
  const onDuplicate = () => {
    if (actionId) duplicateAction(actionId);
  };
  const onDeleteCancel = () => setShowDeleteDialog(false);
  const onDelete = () => deleteAction({ variables: { input: { actionId, installationId: selectedInstallationId } } });

  const duplicateDialogHeader =
    userAction?.action.actionType === ActionType.Advanced
      ? 'advanced.duplicateDialog.header'
      : 'basic.duplicateDialog.header';
  const deleteDialogHeader =
    userAction?.action.actionType === ActionType.Advanced
      ? 'advanced.removeDialog.header'
      : 'basic.removeDialog.header';

  const isRecoveryStatus = checkActionStatus(mappedArray, channelState, DeviceConnectionState.Recovery);

  if (loading && !data) return <CustomBackdrop loading />;
  if (!userAction) return null;

  return (
    <Page
      containerClassName={'p-t-0 p-r-0 p-b-0 p-l-0'}
      headerClassName={'p-t-24 p-r-24 p-b-0 p-l-24'}
      contentClassName={classNames('p-r-24 p-b-24 p-l-24')}
      header={
        <NavHeader onClick={onBack}>
          {!isCentral && actionId && (
            <>
              <Favourite actionId={actionId} actionType={userAction?.action.actionType} />
              <PopUpNav links={[...popupLinks]} />
            </>
          )}
        </NavHeader>
      }
    >
      <ActionDetailsHeader userAction={userAction} channels={mappedArray} />

      <LavvaDetailsWrapper>
        <>
          <RecoveryModeWrapper active={!mappedArray?.length || isRecoveryStatus}>
            <ActionControl userAction={userAction} channels={mappedArray} />
          </RecoveryModeWrapper>

          <ActionChannelList
            channels={mappedArray}
            heading={userAction?.action.actionType === ActionType.Advanced ? t('advanced.control') : t('basic.control')}
          />
        </>
      </LavvaDetailsWrapper>

      <DialogConfirmation
        show={showDuplicateDialog}
        setShow={setShowDuplicateDialog}
        header={t(duplicateDialogHeader, { name: userAction?.action.name || '' })}
        primaryBtnText={tc('buttons.duplicate')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onDuplicate}
        secondaryBtnAction={onDuplicateCancel}
      />
      <DialogConfirmation
        show={showDeleteDialog}
        setShow={setShowDeleteDialog}
        header={t(deleteDialogHeader, { name: userAction?.action.name || '' })}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onDelete}
        secondaryBtnAction={onDeleteCancel}
      />
      <GroupsModifyPopup
        show={groupPopup}
        setShow={setGroupPopup}
        handleSave={handleSaveGroup}
        toggleGroup={toggleGroup}
        groupList={groupList}
      />
    </Page>
  );
};

export default ActionDetails;
