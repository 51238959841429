import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import { NewActionForm } from '../../../../../types';
import { useLavvaCreateActionContext } from '../context';

export const useActionCreate = () => {
  const navigate = useNavigate();
  const { actionId } = useParams<{ actionId: string }>();
  const { setName, setIcon } = useLavvaCreateActionContext();

  useEffect(() => {
    setName('');
    setIcon('');
  }, []);

  const onSubmitNameIcon = (formData: NewActionForm) => {
    setName(formData.name);
    setIcon(formData.icon_name);

    navigate(!actionId ? ROUTES.ActionCreateTasks() : ROUTES.ActionEditTasks(actionId));
  };

  return {
    onSubmitNameIcon,
  };
};
