import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useSubscription } from '@apollo/client';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ChannelTypeInternal,
  OnPartialConfigChangedSubscription,
  OnPartialConfigChangedSubscriptionVariables,
} from '../../../../../data-access/gql-types/graphql';
import { ON_PARTIAL_CONFIG_CHANGED } from '../../../../../data-access/subscriptions/lavva-devices';
import { useTimeout } from '../../../../../hooks/timeout/use-timeout';
import { timeToMilliseconds } from '../../../../../utils/helpers';
import { toastSuccess } from '../../../../../utils/toast';
import {
  useGateSetAlertConfig,
  useGateSetPartialSignalTime,
  useGateSetSbsSignalTime,
  useGateSetTiltTime,
} from '../../../api/gate/gate.hooks';
import { AlertConfigValue } from '../../../api/gate/gate.types';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { ChannelGateType } from '../../../types';
import { getNumericalValue } from '../../../utils/channels/helpers/state';

export const useGateConfiguration = () => {
  const { t } = useTranslation('channel-settings');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel, updateChannelDetails } = useDevicesAndChannelsContext();
  const { skipLavvaFetch } = useInstallationContext();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const setSbsSignalTime = useGateSetSbsSignalTime();
  const setPartialSignalTime = useGateSetPartialSignalTime();
  const setTiltTime = useGateSetTiltTime();
  const setGateSetAlertConfig = useGateSetAlertConfig();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { data: configurationData } = useSubscription<
    OnPartialConfigChangedSubscription,
    OnPartialConfigChangedSubscriptionVariables
  >(ON_PARTIAL_CONFIG_CHANGED, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (configurationData?.onPartialConfigChanged) {
      clearTimeoutError();
      const { numericalProperties, channelId } = configurationData.onPartialConfigChanged;

      const sbsSignalTimeInMs = getNumericalValue(numericalProperties, 'SbsSignalTimeInMs');
      const partialSbsTimeInMs = getNumericalValue(numericalProperties, 'PartialSbsTimeInMs');
      const tiltTimeInMs = getNumericalValue(numericalProperties, 'TiltTimeInMs');
      const barrierAlertEnabled = getNumericalValue(numericalProperties, 'BarrierAlertEnabled');
      const misdirectionAlertEnabled = getNumericalValue(numericalProperties, 'MisdirectionAlertEnabled');
      const externalSourceTriggeredAlertEnabled = getNumericalValue(
        numericalProperties,
        'ExternalSourceTriggeredAlertEnabled',
      );
      const barrierTime = getNumericalValue(numericalProperties, 'OpenedIntervalToNotifyInMs');

      updateChannelDetails((prev) => {
        const tempChannel = channelId === prev?.id ? cloneDeep(prev) : undefined;
        if (!tempChannel) return;

        (tempChannel.data as ChannelGateType) = {
          ...(tempChannel.data as ChannelGateType),
          ...(sbsSignalTimeInMs?.value ? { sbsSignalTimeInMs: Number(sbsSignalTimeInMs.value) } : {}),
          ...(partialSbsTimeInMs?.value ? { partialSbsTimeInMs: Number(partialSbsTimeInMs.value) } : {}),
          ...(tiltTimeInMs?.value ? { tiltTimeInMs: Number(tiltTimeInMs.value) } : {}),
          ...(barrierAlertEnabled?.value ||
          barrierTime?.value ||
          misdirectionAlertEnabled?.value ||
          externalSourceTriggeredAlertEnabled?.value
            ? {
                alertConfig: {
                  barrierAlertEnabled: barrierAlertEnabled?.value === 'True',
                  misdirectionAlertEnabled: misdirectionAlertEnabled?.value === 'True',
                  externalSourceTriggeredAlertEnabled: externalSourceTriggeredAlertEnabled?.value === 'True',
                  openedIntervalToNotifyInMs: Number(barrierTime?.value || 0),
                },
              }
            : {}),
        };

        return tempChannel;
      });

      if (numericalProperties.length) {
        toastSuccess({ content: t('toast.editConfigurationSuccess') });
      }

      turnOffBackdrop();
    }
  }, [configurationData]);

  const handleSaveSignalTime = (time: string) => {
    if (channel) {
      turnOnBackdrop();

      setSbsSignalTime.mutate(
        {
          channelId: channel.id,
          deviceId: channel.deviceId,
          value: timeToMilliseconds(time),
        },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  const handleSaveAlert = (values: AlertConfigValue) => {
    if (channel) {
      turnOnBackdrop();

      setGateSetAlertConfig.mutate(
        {
          channelId: channel.id,
          value: values,
        },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  const handleSavePartialSignalTime = (time: string) => {
    if (channel) {
      turnOnBackdrop();
      const ms = timeToMilliseconds(time);

      setPartialSignalTime.mutate(
        {
          channelId: channel.id,
          deviceId: channel.deviceId,
          value: ms || null,
        },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  const handleSaveTiltTime = (time: string) => {
    if (channel) {
      turnOnBackdrop();

      setTiltTime.mutate(
        {
          channelId: channel.id,
          deviceId: channel.deviceId,
          value: timeToMilliseconds(time),
        },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  return {
    handleSaveSignalTime,
    handleSaveAlert,
    handleSavePartialSignalTime,
    handleSaveTiltTime,
  };
};
