import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconHeart, Tabs, TabType } from '../../../../../components';
import { UserGroup, UserPermissionType } from '../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../routes';
import { useApi } from '../../../hooks';
import { availableGroupIcons } from '../../../../../components/available-icons';

type GroupTabsProps = {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  biggerTabs?: boolean;
};

export const GroupTabs: React.FC<GroupTabsProps> = ({ activeTabIndex, setActiveTabIndex, biggerTabs }) => {
  const navigate = useNavigate();
  const { permissions } = useUserPermissions();
  const { groups, dashboardLoading } = useApi();
  const canCreateGroup = permissions[UserPermissionType.GroupCreate];

  const tabs = useMemo(() => {
    if (dashboardLoading) return [];

    const items = groups?.map(
      ({ group }: UserGroup) =>
        ({
          label: group.name,
          ...(group.isDefaultGroup
            ? { icon: <IconHeart /> }
            : { icon: availableGroupIcons.find((x) => x.iconName === group.iconName)?.component }),
        } as TabType),
    );

    return canCreateGroup
      ? [...items, { label: '+', onClick: () => navigate(ROUTES.GroupCreate()), addNew: true }]
      : [...items];
  }, [groups, dashboardLoading, canCreateGroup]);

  return (
    <Tabs
      tabList={tabs}
      activeTabIndex={activeTabIndex}
      setActiveTabIndex={setActiveTabIndex}
      tabsType="pills"
      isDivider={false}
      biggerTabs={biggerTabs}
      moreButton="secondary"
    />
  );
};
