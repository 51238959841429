import { Api } from 'lavva.exalushome';
import { DevicesService } from 'lavva.exalushome/build/js/Services/Devices/DevicesService';
import { IDevicesService } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { AccessLevel, IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { create } from 'zustand';

type UserPermissions = {
  canAddRemoveConfigureDevice: boolean;
  canDeviceEdit: boolean;
  canAddEditGroup: boolean;
  hasAccessToScenarios: boolean;
  hasAccessToControllerSettings: boolean;
};

type LoggedUserStore = {
  user: IUser | null;
  permissions: UserPermissions;
  setLoggedUser: (user: IUser) => void;
};

export const useLoggedUserStore = create<LoggedUserStore>((set) => ({
  user: null,
  permissions: {
    canAddRemoveConfigureDevice: false,
    canDeviceEdit: false,
    canAddEditGroup: false,
    hasAccessToScenarios: false,
    hasAccessToControllerSettings: false,
  },
  setLoggedUser: (user: IUser | null) => {
    if (user) {
      const accessLevel = user.AccessLevel;
      const devicesApi = Api.Get<IDevicesService>(DevicesService.ServiceName);

      const canAddRemoveConfigureDevice = devicesApi.CanAddAndRemoveAndConfigureDevices();
      const canDeviceEdit = devicesApi.CanEditDevicesInfo();
      const canAddEditGroup = [AccessLevel.Support, AccessLevel.Admin, AccessLevel.Installator].includes(accessLevel);
      const hasAccessToScenarios = [AccessLevel.Support, AccessLevel.Admin, AccessLevel.Installator].includes(
        accessLevel,
      );
      const hasAccessToControllerSettings = [AccessLevel.Support, AccessLevel.Admin, AccessLevel.Installator].includes(
        accessLevel,
      );

      set({
        user,
        permissions: {
          canAddRemoveConfigureDevice,
          canDeviceEdit,
          canAddEditGroup,
          hasAccessToScenarios,
          hasAccessToControllerSettings,
        },
      });
    }
  },
}));
