import React, { useEffect, useMemo, useState } from 'react';
import { ControlWrapper, IconInitiate, InitiateButton, SubmitButton } from '../../../../../../../components';
import { SwitchDetailsTimeActivations } from '../../../../../components/channel-details/switch/time-activatons';
import { defaultFavouriteSetOnTimeList } from '../../../../../../../const';
import {
  ChannelActionParameterInput,
  ChannelTypeInternal,
  TriggerActionRequestInput,
  TriggerActionType,
} from '../../../../../../../data-access/gql-types/graphql';
import { ChannelType, ChannelSwitchType } from '../../../../../types';
import { parseFavouritePositions } from '../../../../../utils/channels/helpers';
import { useTriggerFormContext } from '../../../context';
import { useOnOffControl } from '../helpers/use-control';

type SwitchSetupProps = {
  setAction: (value: TriggerActionRequestInput) => void;
  channel: ChannelType;
  defaultParameters: TriggerActionRequestInput | undefined;
};

const SwitchSetup: React.FC<SwitchSetupProps> = ({ setAction, channel, defaultParameters }) => {
  const [ChannelParameters, setChannelParameters] = useState<{ [key: string]: ChannelActionParameterInput }>({});
  const [statusOn, setStatusOn] = useState<boolean>(false);
  const [activeOnTime, setActiveOnTime] = useState<number | null>(null);
  const { setOff, setOn } = useOnOffControl(ChannelParameters);
  const { updateAction } = useTriggerFormContext();

  useEffect(() => {
    if (defaultParameters) {
      switch (defaultParameters.actionType) {
        case TriggerActionType.SetSwitchState: {
          setStatusOn(defaultParameters?.booleanValue ?? false);
          setChannelParameters(defaultParameters?.booleanValue ? setOn() : setOff());
          break;
        }
        case TriggerActionType.SetSwitchSetOnTime: {
          setStatusOn(true);
          setChannelParameters(setOn());
          setActiveOnTime(defaultParameters.integerValue || 0);
          break;
        }
      }
    }
  }, [setStatusOn]);

  const handleControlOn = (value: boolean) => {
    setChannelParameters(value ? setOn() : setOff());
    setStatusOn(value);
    if (!value) {
      setActiveOnTime(null);
    }
  };

  const onSubmit = () => {
    const data = {
      id: channel.id,
      actionType: activeOnTime ? TriggerActionType.SetSwitchSetOnTime : TriggerActionType.SetSwitchState,
      ...(activeOnTime ? { integerValue: activeOnTime } : { booleanValue: statusOn }),
    };

    updateAction(channel.id, data);
    setAction(data);
  };

  const favouriteSetOnTimeList = useMemo(() => {
    if (channel.data.type === ChannelTypeInternal.Switch) {
      return parseFavouritePositions(channel.data.favouritePositionList, defaultFavouriteSetOnTimeList, false);
    }

    return defaultFavouriteSetOnTimeList;
  }, [(channel.data as ChannelSwitchType).favouritePositionList]);

  const onFavouriteClick = (value) => {
    if (activeOnTime === value) {
      setActiveOnTime(null);
    } else {
      setActiveOnTime(value);
      setChannelParameters(setOn());
      setStatusOn(true);
    }
  };

  return (
    <>
      <ControlWrapper>
        <InitiateButton isOn={statusOn} onClickControl={handleControlOn}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <SwitchDetailsTimeActivations
        setOnTimeList={favouriteSetOnTimeList}
        handleSetOnTime={onFavouriteClick}
        active={activeOnTime}
      />
      <SubmitButton onClick={onSubmit} />
    </>
  );
};

export default SwitchSetup;
