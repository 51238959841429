import React from 'react';
import { ChannelTypeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { useGateAction } from '../../../../../hooks';
import { ChannelType } from '../../../../../types';
import { checkIfRollUpControls } from '../../../../../utils/actions/gate';
import { RollerControls } from '../../../../roller-controls';

type GateActionControlBoxProps = {
  channels: ChannelType[];
  isListItem?: boolean;
};

export const GateActionControlBox: React.FC<GateActionControlBoxProps> = ({ isListItem, channels }) => {
  const { handleAction } = useGateAction(channels);
  const isRollUpControls = checkIfRollUpControls(channels);

  const handleGateAction = (type: string) => handleAction(type);

  if (!isRollUpControls) return null;

  return <RollerControls handleAction={handleGateAction} isListItem={isListItem} type={ChannelTypeInternal.Gate} />;
};
