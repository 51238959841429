import React, { useEffect, useMemo, useState } from 'react';
import { isAfter, isSameDay, isSameMonth, isSameWeek, isSameYear } from 'date-fns';
import { DatePickerFormat } from '../../integrations/lavva/types';
import { MeasurementRange } from '../../types';
import { convertDateToFormat } from '../../utils/helpers';
import { IconChevron } from '../icons';
import './index.scss';

export const dataPickerFormat: DatePickerFormat = {
  [MeasurementRange.DayInHours]: {
    format: ['eeee, dd.MM', 'eeee, dd.MM HH:mm'],
    onNext: (date: Date) => date.setDate(date.getDate() + 1),
    onPrev: (date: Date) => date.setDate(date.getDate() - 1),
  },
  [MeasurementRange.WeekInDays]: {
    format: ['dd.MM.yy'],
    onNext: (date: Date) => date.setDate(date.getDate() + 7),
    onPrev: (date: Date) => date.setDate(date.getDate() - 7),
  },
  [MeasurementRange.MonthInDays]: {
    format: ['LLLL, yyyy'],
    onNext: (date: Date) => date.setMonth(date.getMonth() + 1),
    onPrev: (date: Date) => date.setMonth(date.getMonth() - 1),
  },
  [MeasurementRange.YearInMonths]: {
    format: ['yyyy'],
    onNext: (date: Date) => date.setFullYear(date.getFullYear() + 1),
    onPrev: (date: Date) => date.setFullYear(date.getFullYear() - 1),
  },
  [MeasurementRange.TenYearsInYears]: {
    format: ['yyyy'],
    onNext: (date: Date) => date.setFullYear(date.getFullYear() + 1),
    onPrev: (date: Date) => date.setFullYear(date.getFullYear() - 1),
  },
};

type DatePickerProps = {
  setActiveDay: (date: Date) => void;
  activeMeasurementRange: MeasurementRange;
  activeDay: Date;
  showDateButtons?: boolean;
  showHours?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  activeMeasurementRange,
  activeDay,
  setActiveDay,
  showDateButtons = true,
  showHours,
}) => {
  const [dateValue, setDateValue] = useState<string>('');
  const [disabledNext, setDisabledNext] = useState<boolean>(false);

  const data = useMemo(() => dataPickerFormat[activeMeasurementRange], [dataPickerFormat, activeMeasurementRange]);

  useEffect(() => {
    const date = new Date();
    if (activeMeasurementRange) {
      if (activeMeasurementRange === MeasurementRange.DayInHours) {
        setDisabledNext(isSameDay(date, activeDay) || isAfter(activeDay, date));
      }
      if (activeMeasurementRange === MeasurementRange.WeekInDays) {
        setDisabledNext(isSameWeek(date, activeDay, { weekStartsOn: 1 }) || isAfter(activeDay, date));
      }
      if (activeMeasurementRange === MeasurementRange.MonthInDays) {
        setDisabledNext(isSameMonth(date, activeDay) || isAfter(activeDay, date));
      }
      if (activeMeasurementRange === MeasurementRange.YearInMonths) {
        setDisabledNext(isSameYear(date, activeDay) || isAfter(activeDay, date));
      }
    }
    if (activeMeasurementRange === MeasurementRange.WeekInDays) {
      const day = new Date().getDate();
      setDateValue(
        `${convertDateToFormat(
          day > 7 ? new Date(new Date().setDate(day - 6)) : new Date(new Date().setDate(1)),
          data.format[0],
        )} - ${convertDateToFormat(activeDay, data.format[0])}`,
      );
    } else {
      setDateValue(convertDateToFormat(activeDay, data.format[showHours ? 1 : 0]));
    }
  }, [activeDay, activeMeasurementRange, showHours]);

  const onPrev = () => {
    const newDate = data.onPrev(activeDay);
    setActiveDay(new Date(newDate));
  };

  const onNext = () => {
    const newDate = data.onNext(activeDay);
    setActiveDay(new Date(newDate));
  };

  return (
    <div className="date-picker">
      {showDateButtons ? (
        <button disabled onClick={onPrev} className="date-picker__button">
          <IconChevron />
        </button>
      ) : null}
      <div className="date-picker__date">
        <h1 className="date-picker__date-value">{dateValue}</h1>
      </div>
      {showDateButtons ? (
        <button disabled={disabledNext} onClick={onNext} className="date-picker__button date-picker__button--next">
          <IconChevron />
        </button>
      ) : null}
    </div>
  );
};
