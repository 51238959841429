import { isNumber } from 'lodash';
import { AggregateVectorParameterTypeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { aggregatedParameters } from '../../../measurements/utils';
import { ChartDataValues, CostEnum, EnergyStats } from '../types';

export const calculateSummary = (
  enStats: Record<string, Record<string, EnergyStats>>,
  val: ChartDataValues,
  value: number,
  type: string,
) => {
  const stats = { ...enStats };

  if (!stats[val.index]) {
    stats[val.index] = {};

    if (!stats[val.index][type]) {
      stats[val.index][type] = { sum: 0, max: value, count: 0, color: val.color };
    }

    if (val.empty) return stats;

    stats[val.index][type].sum += value;

    const max = stats[val.index][type].max;
    stats[val.index][type].max = isNumber(max) ? Math.max(max, value) : value;

    stats[val.index][type].count++;
  } else {
    if (!stats[val.index][type]) {
      stats[val.index][type] = { sum: 0, max: null, count: 0, color: val.color };
    }

    if (val.empty) return stats;

    stats[val.index][type].sum += value;

    const max = stats[val.index][type].max;
    stats[val.index][type].max = isNumber(max) ? Math.max(max, value) : value;

    stats[val.index][type].count++;
  }

  return stats;
};

export const getSummaryParameters = (type: string) => {
  if (type === CostEnum.Cost || type === CostEnum.PredictedCost) {
    return aggregatedParameters[AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice];
  }

  return aggregatedParameters[type];
};
