import {
  AmpTransformersInternal,
  ChannelTypeInternal,
  MeterChannelStateResponse,
  MeterPurposeInternal,
  MeterSharedChannelSettings,
  StandaloneMeasurementPayload,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum } from '../../../../../types';
import { ChannelStateType, ChannelType } from '../../../types';

export const parseMeterChannel = (userChannel: UserChannel): ChannelType => {
  const channelPayload = userChannel.payload as MeterChannelStateResponse | undefined;

  return {
    id: userChannel.id,
    installationId: userChannel.installationId,
    isVisible: userChannel.isVisible,
    alias: userChannel.alias,
    iconName: userChannel.iconName || ChannelIconTypeEnum.METER,
    deviceId: userChannel.deviceId,
    currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
    data: {
      type: ChannelTypeInternal.Meter,
      meterType: channelPayload?.meterType || undefined,
      measuredChannels: channelPayload?.measuredChannels || [],
      ampTransformers: channelPayload?.ampTransformers || AmpTransformersInternal.Unknown,
      meterPurpose: channelPayload?.meterPurpose || MeterPurposeInternal.Unknown,
      payload: (channelPayload?.payload as StandaloneMeasurementPayload) || null,
      supportedVectorParameters: channelPayload?.supportedVectorParameters || [],
      supportedScalarParameters: channelPayload?.supportedScalarParameters || [],
      tariff: channelPayload?.tariff || undefined,
      supportedAggregateVectorParameters: channelPayload?.supportedAggregateVectorParameters || [],
      sharedChannelSettings: (userChannel.sharedChannelSettings as MeterSharedChannelSettings) || {},
    },
  };
};

export const parseMeterStateChannel = (userChannel: UserChannel): ChannelStateType => ({
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
