import React from 'react';
import { EcoLevel } from '../../../types';
import './index.scss';

type IndicatorElementProps = {
  ecoLevel: EcoLevel;
};

export const IndicatorElement: React.FC<IndicatorElementProps> = ({ ecoLevel }) => {
  const style = {
    '--background': ecoLevel.value,
  } as React.CSSProperties;

  return <li className="indicator-element" style={style} />;
};
