import { create } from 'zustand';
import { ChannelStateType } from '../types';

type ChannelsStore = {
  channelState: Record<string, ChannelStateType | null>;
  getChannelState: (channelId: string) => ChannelStateType | null;
  setAllChannelsState: (newState: Record<string, ChannelStateType>) => void;
  setChannelState: (channelId: string, newState: ChannelStateType) => void;
};

export const useChannelsStateStore = create<ChannelsStore>((set, get) => ({
  channelState: {},
  getChannelState: (channelId: string) => get().channelState[channelId] || null,
  setAllChannelsState: (newState: Record<string, ChannelStateType>) =>
    set((state) => ({
      channelState: {
        ...state.channelState,
        ...newState,
      },
    })),
  setChannelState: (channelId: string, newState: ChannelStateType) =>
    set((state) => ({
      channelState: {
        ...state.channelState,
        [channelId]: {
          ...(state.channelState[channelId] ?? {}),
          ...newState,
        },
      },
    })),
}));
