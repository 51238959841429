import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, CustomBackdrop, EmptyStateBox, IconWarning } from '../../../../../components';
import { useInstallationContext } from '../../../../../context/installation';
import { ROUTES } from '../../../../../routes';
import { useApi, useDevicesAndChannels } from '../../../hooks';
import { useAddDevice } from '../../device-add/hooks/use-add-device';

export enum PageType {
  Channels = 'Channels',
  Dashboard = 'Dashboard',
  Devices = 'Devices',
}

type CheckContentWrapperProps = {
  children: ReactNode;
  page?: PageType;
};

const CheckContentWrapper: React.FC<CheckContentWrapperProps> = ({ children, page }) => {
  const { t } = useTranslation('installation');
  const { t: tch } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-list');
  const { selectedInstallationId, installationList, installationsLoading } = useInstallationContext();
  const { channelsLoading, devicesLoading, dashboardLoading } = useApi();
  const { addDevice } = useAddDevice();
  const { channelGroups, deviceList, channelList } = useDevicesAndChannels();
  const loading = installationsLoading || channelsLoading || devicesLoading || dashboardLoading;

  const renderContent = () => {
    if (loading) return <CircularProgress />;

    if (installationList.length === 0)
      return (
        <EmptyStateBox
          header={t('installation')}
          content={t('noInstallations.message')}
          btnText={t('noInstallations.button')}
          icon={<IconWarning />}
          linkTo={ROUTES.InstallationCreate()}
          showAllways
        />
      );

    if (!selectedInstallationId)
      return (
        <EmptyStateBox
          header={t('installation')}
          content={t('noSelectedInstallation.message')}
          btnText={t('noSelectedInstallation.button')}
          icon={<IconWarning />}
          linkTo={ROUTES.InstallationCreate()}
          showAllways
        />
      );

    if (page === PageType.Channels && channelGroups.length === 0)
      return (
        <EmptyStateBox
          header={tch('devicesEmptyState.header')}
          btnText={tch('devicesEmptyState.missingDevice.btnText')}
          content={tch('devicesEmptyState.missingDevice.content')}
          icon={<IconWarning />}
          onClick={addDevice}
        />
      );

    if (page === PageType.Dashboard && channelList.length === 0)
      return (
        <EmptyStateBox
          header={tch('devicesEmptyState.header')}
          btnText={tch('devicesEmptyState.missingDevice.btnText')}
          content={tch('devicesEmptyState.missingDevice.content')}
          icon={<IconWarning />}
          onClick={addDevice}
        />
      );

    if (page === PageType.Devices && deviceList.length === 0)
      return (
        <EmptyStateBox
          header={td('devicesEmptyState.header')}
          content={td('devicesEmptyState.content')}
          icon={<IconWarning />}
        />
      );

    return children;
  };

  return (
    <>
      {renderContent()}
      <CustomBackdrop loading={loading} />
    </>
  );
};

export default CheckContentWrapper;
