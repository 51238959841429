import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWakeLock } from 'react-screen-wake-lock';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { ISsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/ISsrConfigService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { BlindCalibrationState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { CustomBackdrop, Header, NavHeader, Page } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { toastInfo, toastSuccess } from '../../../../../../../utils/toast';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { useCalibration } from '../hooks/use-calibration';
import './index.scss';

type AutoCalibrationBlind2Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const AutoCalibrationBlind2: React.FC<AutoCalibrationBlind2Props> = ({ setStep }) => {
  const { t: td } = useTranslation('device-add');
  const [fetching, setFetching] = useState<boolean>(false);
  const { channel, navigate, t } = useCalibration();
  const { configService: serviceName, handleGetBlindParamsResponse } = useConfigurationService(channel?.GetDevice());
  const { handleError } = useHandleDataFrameErrors();
  const { request, release } = useWakeLock();
  usePreventBack(fetching ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  useEffect(() => {
    request();

    return () => {
      release();
    };
  }, []);

  const runAutoCalibration = async () => {
    if (channel) {
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      setFetching(true);
      toastSuccess({ content: t('exalus.calibrationStarted') });
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
        ISsrConfigService | ISrp30ConfigService
      >(serviceName);

      const result = await configService.RunAutomaticCalibrationAsync(channel.GetDevice(), channel.Number);

      handleGetBlindParamsResponse<BlindCalibrationState>(result, () => {
        toastSuccess({ content: t('exalus.calibrationFinished') });
        setStep(3);
        navigate(`${ROUTES.BlindAutoCalibration(channel.ChannelId)}?step=3`);
      });

      setFetching(false);
    }
  };

  const stopAutoCalibration = async () => {
    if (channel) {
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
        ISsrConfigService | ISrp30ConfigService
      >(serviceName);

      const result = await configService.StopAutomaticCalibrationAsync(channel.GetDevice());

      if (result === Status.OK) {
        toastInfo({ content: t('exalus.calibrationStopped') });
      } else {
        handleError(result);
      }

      setFetching(false);
    }
  };

  return (
    <Page
      className="auto-calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="blind-icon" />}
      <p className="info m-t-40">{t('exalus.autoCalibrationFlow.instruction')}</p>
      <button className="button button--secondary m-t-24 m-b-48" onClick={runAutoCalibration}>
        {t('exalus.autoCalibrationFlow.start')}
      </button>
      {fetching && <CustomBackdrop loading btnTitle={td('exalus.searchStop')} onClick={stopAutoCalibration} />}
    </Page>
  );
};

export default AutoCalibrationBlind2;
