import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputEdit } from '../../../../../../components';

type ConfigurationNameProps = {
  label: string;
  placeholder: string;
  edited?: boolean;
  disabled?: boolean;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
};

const ConfigurationName: React.FC<ConfigurationNameProps> = ({ label, placeholder, edited, disabled, setDisabled }) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();

  return (
    <Controller
      name="name"
      control={control}
      rules={{
        required: {
          value: true,
          message: t('isRequired'),
        },
      }}
      render={({ field, fieldState: { error } }) =>
        !edited ? (
          <Input
            defaultValue={field.value}
            onChange={field.onChange}
            label={label}
            placeholder={placeholder}
            {...(error ? { errorMessage: error.message } : {})}
          />
        ) : (
          <InputEdit
            defaultValue={field.value}
            onChange={field.onChange}
            label={label}
            disabled={disabled}
            setDisabled={setDisabled}
            {...(error ? { errorMessage: error.message } : {})}
          />
        )
      }
    />
  );
};

export default ConfigurationName;
