import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { IRck21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/ReedSwitch/IRck21ConfigService';
import { Rck21ConfigParams } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/ReedSwitch/Rck21ConfigParams';
import { Rck21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/ReedSwitch/Rck21ConfigService';
import { ExtaLifeService } from 'lavva.exalushome.extalife/build/js/ExtaLife';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { Checkbox, SubmitButton } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type Rck21ConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const Rck21ConfigForm: React.FC<Rck21ConfigFormProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse, handleGetParamsResponse, firmwareService } = useConfigurationService(device);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);
  const form = useForm<Rck21ConfigParams>();

  const { LogicNegation } = form.watch();

  const getDefaultParams = async () => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRck21ConfigService>(
        Rck21ConfigService.ServiceName,
      );

      const data = await configService.GetAllParamsAsync(device, channel.Number);
      turnOffBackdrop();

      handleGetParamsResponse<Rck21ConfigParams>(data, () => {
        Object.entries(data as Rck21ConfigParams).forEach(([name, value]) => {
          form.setValue(name as keyof Rck21ConfigParams, value);
        });
        setOpen(true);
      });
    }
  };

  const onSubmit = form.handleSubmit(async (values) => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const params = new Rck21ConfigParams();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRck21ConfigService>(
        Rck21ConfigService.ServiceName,
      );

      const result = await configService.SetAllParamsAsync({ ...params, ...values }, channel.Number);
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const handleOpen = () => getDefaultParams();

  const handleBack = () => setOpen(false);

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          <FormProvider {...form}>
            <form onSubmit={onSubmit}>
              <ConfigurationInput
                name="SyncTime"
                label={t('exalus.params.Rck21Params.SyncTime')}
                placeholder={t('exalus.params.Rck21Params.SyncTime')}
                inputType="number"
              />
              <Checkbox
                checked={LogicNegation}
                onChange={(e) => {
                  form.setValue('LogicNegation', e.target.checked);
                }}
                reverse
              >
                {t('exalus.params.Rck21Params.logicNegation')}
              </Checkbox>
              <SubmitButton />
            </form>
          </FormProvider>
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton title={t('exalus.moreConfiguration')} onClick={() => handleOpen()} className="m-b-24" />
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Rck21ConfigForm;
