import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Info from '../../../../../components/channel-configuration/info';
import { GateModeInternal, VersionedFeatureType } from '../../../../../data-access/gql-types/graphql';
import { ChannelBox } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useVersionMap } from '../../../hooks/api/version-map';
import { ChannelGateType } from '../../../types';
import { useGateSupportedFeatures } from '../../channel-details/gate/hooks/use-gate-supported';
import { Calibration } from '../../device-settings/device-settings-form/sections';
import AlertConfig from '../components/alert-config';
import PartialSbsSignalTime from '../components/partial-sbs-time';
import SbsSignalTime from '../components/sbs-signal-time';
import TiltTime from '../components/tilt-time';
import { useGateConfiguration } from '../hooks/use-gate-configuration';
import { InfoPageType } from '../types';

type GateConfigurationProps = {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
};

const GateConfiguration: React.FC<GateConfigurationProps> = ({ setInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();
  const { partialControlModeSupported, setTiltTimeSupported, calibrationSupported } = useGateSupportedFeatures(channel);
  const { checkSupport } = useVersionMap();
  const { handleSaveSignalTime, handleSaveAlert, handleSavePartialSignalTime, handleSaveTiltTime } =
    useGateConfiguration();

  const alertSupported = checkSupport({
    feature: VersionedFeatureType.GateAlerts,
    supportKey: 'Gate',
    currentVersion: channel?.currentFirmwareVersion,
  });

  const gateMode = (channel?.data as ChannelGateType)?.gateMode;

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => setInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem noRedirect />
      {gateMode === GateModeInternal.StepByStep && (
        <>
          <hr />
          <SbsSignalTime channel={channel} handleSave={handleSaveSignalTime} />
        </>
      )}
      {partialControlModeSupported && (
        <>
          <hr />
          <PartialSbsSignalTime channel={channel} handleSave={handleSavePartialSignalTime} />
        </>
      )}
      {setTiltTimeSupported && (
        <>
          <hr />
          <TiltTime channel={channel} handleSave={handleSaveTiltTime} />
        </>
      )}
      {alertSupported && (
        <>
          <hr />
          <AlertConfig channel={channel} handleSave={handleSaveAlert} />
        </>
      )}

      {calibrationSupported && (
        <>
          <hr />
          <Info title={t('calibration')} onClick={() => setInfoPage(InfoPageType.CALIBRATION)} />
          <Calibration channel={channel} />
        </>
      )}
    </>
  );
};

export default GateConfiguration;
