import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDevicesAndChannelsContext } from '../../../../../../../context/devices-and-channels';
import { InputSliderDialog } from '../../input-slider-dialog';

type OptimizerConfigurationPhaseVoltageThresholdSliderProps = {
  name: string;
  label: string;
};

export const OptimizerConfigurationPhaseVoltageThresholdSlider: React.FC<
  OptimizerConfigurationPhaseVoltageThresholdSliderProps
> = ({ label, name }) => {
  const { t } = useTranslation('channel-details');
  const { watch } = useFormContext();
  const { optimizerConfigValidation } = useDevicesAndChannelsContext();

  return (
    <InputSliderDialog
      propertyName={name}
      propertyValue={parseInt(watch()[name])}
      unit="V"
      dialogTitle={`${t('optimizer.configuration.voltageThreshold.heading')} ${label}`}
      inputLabel={`${t('optimizer.configuration.voltageThreshold.heading')} ${label} [V]`}
      {...(optimizerConfigValidation ? { min: optimizerConfigValidation.voltageThreshold.min } : {})}
      {...(optimizerConfigValidation ? { max: optimizerConfigValidation.voltageThreshold.max } : {})}
      {...(optimizerConfigValidation?.voltageThreshold.step
        ? { step: optimizerConfigValidation.voltageThreshold.step }
        : {})}
    />
  );
};
