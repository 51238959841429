import { rgbToHex } from '@mui/material';
import {
  DeviceConnectionState,
  LightChannelColorStateResponse,
  LightStateChangedPayload,
} from '../../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../../store/channels-state';
import { ChannelLightStateType } from '../../../../types';

export const useLavvaDeviceLightUpdate = () => {
  const setChannelState = useChannelsStateStore((s) => s.setChannelState);

  const updateLavvaLightState = ({
    channelId,
    toggleValue,
    brightness,
    temperature,
    color,
  }: LightStateChangedPayload) => {
    setChannelState(channelId, {
      deviceConnectionState: DeviceConnectionState.Connected,
      toggleValue,
      brightness,
      temperature,
      color: color
        ? ({
            ...color,
            hex: rgbToHex(`rgb(${color.r}, ${color.g}, ${color.b})`),
          } as LightChannelColorStateResponse)
        : null,
    } as ChannelLightStateType);
  };

  return {
    updateLavvaLightState,
  };
};
