import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../../../const';
import { ChannelType } from '../../../../../types';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { OptimizerConfigurationPhaseVoltageThreshold } from '../components/phase-voltage-threshold';
import { OptimizerConfigurationRelays } from '../components/relays';
import { OptimizerConfigurationTemperature } from '../components/temperature';
import { useOptimizerEco } from '../hooks/use-optimizer-eco';
import { useOptimizerSetData } from '../hooks/use-optimizer-set-data';
import { EcoFormBody, initialRelay } from '../types';

type OptimizerConfigurationWorkModeEcoProps = {
  channel: ChannelType;
  temperature?: number;
};

export const OptimizerConfigurationWorkModeEco: React.FC<OptimizerConfigurationWorkModeEcoProps> = ({
  channel,
  temperature,
}) => {
  const { t: tc } = useTranslation('common');
  const { submitConfiguration } = useOptimizerEco(channel);
  const { selectedWorkMode } = useOptimizerConfigurationContext();
  const form = useForm<EcoFormBody>({
    defaultValues: {
      tempMax: 0,
      voltageThreshold1: 0,
      voltageThreshold2: 0,
      voltageThreshold3: 0,
      relayConfig1: { ...initialRelay, index: 1 },
      relayConfig2: { ...initialRelay, index: 2 },
      relayConfig3: { ...initialRelay, index: 3 },
    },
  });
  const { setOptimizerData } = useOptimizerSetData(form.setValue, 'eco');

  useEffect(() => {
    if (channel) setOptimizerData(channel);
  }, [channel]);

  const onSubmit = form.handleSubmit((values) => {
    submitConfiguration(selectedWorkMode, values);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div
          className={classNames('optimizer-configuration-form__item', {
            disabled: temperature === WRONG_MEASUREMENT_VALUE,
          })}
        >
          <OptimizerConfigurationTemperature />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationPhaseVoltageThreshold />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationRelays />
        </div>

        <div className="button-container">
          <button className="button m-t-48 max-width-desktop" type="submit">
            {tc('buttons.save')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
