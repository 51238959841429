import React from 'react';
import { IconMissing } from '../../../../../components/icons';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  LightToggleValueInternal,
  SwitchStateInternal,
} from '../../../../../data-access/gql-types/graphql';
import { coverIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/cover';
import { gateIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/gate';
import { lightIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/light';
import { meterIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/meter';
import { optimizerIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/optimizer';
import { switchIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/switch';
import { useChannelsStateStore } from '../../../store/channels-state';
import {
  ChannelCoverStateType,
  ChannelType,
  ChannelLightStateType,
  ChannelSwitchStateType,
  ChannelGateStateType,
  ChannelGateType,
} from '../../../types';
import { findChannelIcon } from '../../../utils/channels/helpers';

type ChannelIconProps = {
  channel: ChannelType;
  statusOn?: boolean;
};

export const ChannelIcon: React.FC<ChannelIconProps> = ({ channel, statusOn }) => {
  const { id, data, iconName } = channel;
  const state = useChannelsStateStore((s) => s.channelState[id]);

  switch (data.type) {
    case ChannelTypeInternal.Switch: {
      return findChannelIcon(
        switchIcons(statusOn || (state as ChannelSwitchStateType)?.lavvaState === SwitchStateInternal.On),
        iconName,
      );
    }
    case ChannelTypeInternal.Light:
      return findChannelIcon(
        lightIcons(statusOn || (state as ChannelLightStateType)?.toggleValue === LightToggleValueInternal.On),
        iconName,
      );
    case ChannelTypeInternal.Blind:
      return findChannelIcon(coverIcons((state as ChannelCoverStateType)?.lavvaStatePosition || 0));
    case ChannelTypeInternal.Gate:
      return findChannelIcon(
        gateIcons((channel.data as ChannelGateType)?.gateKind, (state as ChannelGateStateType)?.position || 0, {
          iconSun: !!(state as ChannelGateStateType)?.dailyModeStatus,
        }),
        iconName,
      );
    case ChannelTypeInternal.Meter:
      return findChannelIcon(
        meterIcons(statusOn || state?.deviceConnectionState !== DeviceConnectionState.Disconnected),
        iconName,
      );
    case ChannelTypeInternal.Optimizer:
      return findChannelIcon(
        optimizerIcons(statusOn || state?.deviceConnectionState !== DeviceConnectionState.Disconnected),
        iconName,
      );
    default:
      return <IconMissing />;
  }
};
