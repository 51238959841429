import React from 'react';
import { IconPadlock } from '../../../../../../components';
import { IconSignal } from '../../../../../../components/icons/icon-signal';
import './index.scss';

type NetworkStateProps = {
  signal: number;
  remembered?: boolean;
};

export const NetworkState: React.FC<NetworkStateProps> = ({ signal, remembered }) => (
  <div className="network-state">
    {remembered && (
      <div className="remembered">
        <IconPadlock isOn={false} />
      </div>
    )}
    <IconSignal signal={signal} />
  </div>
);
