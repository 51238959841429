import {
  GateControllerHealthState,
  IDeviceState,
  ResistanceOrOpticBarStatusEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';

export const getGatePreciseErrorMessage = (gateHealth: IDeviceState<GateControllerHealthState> | undefined) => {
  if (gateHealth?.Data) {
    const health = gateHealth.Data;
    const messagePrefixKey = 'exalus.gateHealth';

    if (health.AutotestSwitchOn === false) return `${messagePrefixKey}.AutotestSwitchOn`;
    if (health.LastAutotestFailed) return `${messagePrefixKey}.LastAutotestFailed`;
    if (health.InertionBreaksOk === false) return `${messagePrefixKey}.InertionBreaksOk`;
    if (health.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.Active) {
      return `${messagePrefixKey}.ResistanceOrOpticBarActive`;
    }
    if (health.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.UnconnectedOrMalfunction) {
      return `${messagePrefixKey}.ResistanceOrOpticBarUnconnected`;
    }
    if (health.PhotoCellsActive) return `${messagePrefixKey}.PhotoCellsActive`;
    if (health.ObstacleDetected) return `${messagePrefixKey}.ObstacleDetected`;
  }

  return '';
};
