import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  EmptyStateBox,
  NavHeader,
  NotificationItem,
  Page,
  Header,
  DialogConfirmation,
  Tabs,
  PopUpNav,
  CircularProgress,
} from '../../components';
import { IconCkeckMarks, IconRubber } from '../../components/popup-nav/icons';
import { NotificationTypeEnum, NotificationInvitationType } from '../../types';
import { useNotificationList } from './hooks/use-notification-list';

const NotificationList: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('notifications');
  const {
    activeTabIndex,
    showClearAllDialog,
    notificationList,
    hasNextPage,
    loading,
    onClearAll,
    onClearAllCancel,
    handleChangeTab,
    setShowClearAllDialog,
    handleMarkAllAsDisplayed,
    fetchMoreData,
    setNotifications,
  } = useNotificationList();

  const tabs = [{ label: t('allNotifications') }, { label: t('unread') }];

  const popUpNavLinks = [
    { label: t('clearAll'), onClick: () => setShowClearAllDialog(true), icon: <IconRubber /> },
    { label: t('markAllAsDisapled'), onClick: handleMarkAllAsDisplayed, icon: <IconCkeckMarks /> },
  ];

  const notifications = notificationList.filter((x) => !x.isHidden);
  const showEmptyMessage = !loading && !notifications.length;

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader>
            <PopUpNav links={popUpNavLinks} />
          </NavHeader>
          <Header title={t('notifications')} />
          {activeTabIndex !== null && (
            <Tabs tabList={tabs} activeTabIndex={activeTabIndex} setActiveTabIndex={handleChangeTab} tabsType="pills" />
          )}
        </>
      }
    >
      <div className="notifications p-b-24">
        {showEmptyMessage ? (
          <EmptyStateBox header={t('noNotifications.header')} content={t('noNotifications.content')} />
        ) : (
          <>
            {!loading ? (
              <InfiniteScroll
                dataLength={notificationList.length}
                next={fetchMoreData}
                hasMore={hasNextPage}
                loader={<CircularProgress />}
              >
                {notifications
                  .sort((a, b) => b.createdTimestampUTC - a.createdTimestampUTC)
                  .map((notification) => {
                    if (notification.data.type === NotificationTypeEnum.INVITATION) {
                      switch (notification.data.invitationType) {
                        case NotificationInvitationType.InvitationReceived:
                          return (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                              setNotifications={setNotifications}
                              title={t('invitationReceived.title')}
                            >
                              <p>
                                {t('invitationReceived.message', {
                                  installationName: notification.data.installationName,
                                })}
                              </p>
                            </NotificationItem>
                          );
                        case NotificationInvitationType.InvitationAcceptedByInvitee:
                          return (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                              setNotifications={setNotifications}
                              title={t('invitationAcceptedByInvitee.title')}
                            >
                              <p>
                                {t('invitationAcceptedByInvitee.message', {
                                  installationName: notification.data.installationName,
                                  inviteeName: notification.data.userName,
                                })}
                              </p>
                            </NotificationItem>
                          );
                        case NotificationInvitationType.InvitationRejectedByInvitee:
                          return (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                              setNotifications={setNotifications}
                              title={t('invitationRejectedByInvitee.title')}
                            >
                              <p>
                                {t('invitationRejectedByInvitee.message', {
                                  installationName: notification.data.installationName,
                                  inviteeName: notification.data.userName,
                                })}
                              </p>
                            </NotificationItem>
                          );
                        case NotificationInvitationType.InvitationCanceledByCreator:
                          return (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                              setNotifications={setNotifications}
                              title={t('InvitationCanceledByCreator.title')}
                            >
                              <p>
                                {t('InvitationCanceledByCreator.message', {
                                  installationName: notification.data.installationName,
                                  creatorName: notification.data.userName,
                                })}
                              </p>
                            </NotificationItem>
                          );
                        default:
                          return null;
                      }
                    } else {
                      return (
                        <NotificationItem
                          key={notification.id}
                          notification={notification}
                          title={notification.data.title}
                          setNotifications={setNotifications}
                        >
                          <p>{notification.data.message}</p>
                        </NotificationItem>
                      );
                    }
                  })}
              </InfiniteScroll>
            ) : (
              <CircularProgress className="m-b-24" />
            )}
          </>
        )}
      </div>
      <DialogConfirmation
        show={showClearAllDialog}
        setShow={setShowClearAllDialog}
        header={t('clearAllContent')}
        primaryBtnText={tc('buttons.clear')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onClearAll}
        secondaryBtnAction={onClearAllCancel}
      />
    </Page>
  );
};

export default NotificationList;
