import React from 'react';
import {
  IconChevron,
  IconCirclePadlockClose,
  IconCirclePadlockOpen,
  IconCircleStop,
  IconPause,
} from '../../../../components';
import { ChannelBoxControls } from '../../../../components/channel-box-controls';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';

type RollerControlsProps = {
  handleAction: (value: string) => void;
  type: ChannelTypeInternal.Blind | ChannelTypeInternal.Gate;
  isListItem?: boolean;
  stopIcon?: boolean;
};

export const RollerControls: React.FC<RollerControlsProps> = ({ handleAction, isListItem, type }) => {
  const isGate = type === ChannelTypeInternal.Gate;

  return (
    <ChannelBoxControls
      kind="normal"
      isListItem={isListItem}
      controls={[
        {
          icon: !isGate ? <IconChevron withCircle direction="down" /> : <IconCirclePadlockOpen />,
          handleAction: () => handleAction(!isGate ? 'close' : 'open'),
        },
        {
          icon: !isGate ? <IconPause /> : <IconCircleStop />,
          handleAction: () => handleAction('stop'),
        },
        {
          icon: !isGate ? <IconChevron withCircle direction="up" /> : <IconCirclePadlockClose />,
          handleAction: () => handleAction(!isGate ? 'open' : 'close'),
        },
      ]}
    />
  );
};
