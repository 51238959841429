import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import { LightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { BrighteningTimeParams } from '../../../device-details/types';

type BrighteningTimeFormProps = {
  device: IDevice;
  brighteningTime: LightTransitionTime | null;
  channel: IDeviceChannel;
};

const BrighteningTimeForm: React.FC<BrighteningTimeFormProps> = ({ device, brighteningTime, channel }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<BrighteningTimeParams>();

  useEffect(() => {
    if (brighteningTime !== null) form.setValue('brighteningTime', brighteningTime);
  }, [brighteningTime]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRdp21ConfigService>(
      Rdp21ConfigService.ServiceName,
    );

    const result = await configService.SetDimmingTimeAsync(device, channel.Number, values.brighteningTime);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="brighteningTime"
          label={t('exalus.params.OnOffParams.BrighteningTime')}
          placeholder={t('exalus.params.OnOffParams.BrighteningTime')}
          inputType="number"
          min={1}
          max={31}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default BrighteningTimeForm;
