import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { ZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { Rcz21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FloodSensor/Rcz21ConfigService';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { SbrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/SbrConfigService';
import { Slr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr21ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { Rcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/Rog21ConfigService';
import { Rck21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/ReedSwitch/Rck21ConfigService';
import { RomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/RomConfigService';
import { RopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/RopConfigService';
import { Srp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp22ConfigService';
import { Srp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp30ConfigService';
import { SsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/SsrConfigService';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { Portos433ConfigService } from 'lavva.exalushome.portos/build/js/Devices/DeviceServices/Portos433Mhz/Portos433ConfigService';
import { Header, NavHeader, Page, SubmitButton, Switch, Tabs } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import Info from '../../../../components/channel-configuration/info';
import { ROUTES } from '../../../../routes';
import { getParameterByName } from '../../../../utils/location';
import { ChannelType, LightType, SensorType } from '../../enums';
import { useExalusChannel } from '../../hooks';
import { useLoggedUser } from '../../hooks/common/logged-user';
import { getSensorType } from '../../utils';
import { useConfigurationService } from '../device-details/hooks/use-configuration-service';
import ChannelGroups from './channel-groups';
import ConfigIconEdit from './components/icon-edit';
import { Name } from './components/name';
import Config433Form from './forms/433';
import Rck21ConfigForm from './forms/rck21';
import Rcm21ConfigForm from './forms/rcm21';
import Rcr21ConfigForm from './forms/rcr21';
import Rcz21ConfigForm from './forms/rcz21';
import Rdp21ConfigForm from './forms/rdp21';
import Rob21ConfigForm from './forms/rob21';
import Rog21ConfigForm from './forms/rog21';
import RomConfigForm from './forms/rom';
import RopConfigForm from './forms/rop';
import SbrConfigForm from './forms/sbr';
import SlrConfigForm from './forms/slr';
import SrpConfigForm from './forms/srp';
import SsrConfigForm from './forms/ssr';
import WszfBidiConfigForm from './forms/wsz-bidi';
import ZfBidiConfigForm from './forms/zf-bidi';
import { useChannelConfiguration } from './hooks/use-channel-configuration';

const ChannelConfiguration: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const { channelId, channelType } = useParams<{
    channelType: ChannelType | SensorType | LightType;
    channelId: string;
  }>();
  const { data: channel, refetch } = useExalusChannel((channelId || '').split('_')[0], channelId || '');
  const { configService } = useConfigurationService(channel?.GetDevice());
  const { canAddRemoveConfigureDevice, canDeviceEdit } = useLoggedUser();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [editGroups, setEditGroups] = useState<boolean>(false);
  const { form, onSubmit, submitActive, handleChangeVisibility, values } = useChannelConfiguration(channel, editGroups);
  const isDeviceAvailable = !new URLSearchParams(location.search).get('redirect');

  useEffect(() => {
    const tab = getParameterByName('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const channelConfigForms = useMemo(() => {
    if (!channel) return null;

    const device = channel?.GetDevice();
    if (!device) return null;
    if (getSensorType(channel) === SensorType.Temperature && configService !== Rcm21ConfigService.ServiceName) {
      return null;
    }

    switch (configService) {
      case Rcz21ConfigService.ServiceName: {
        return <Rcz21ConfigForm device={device} channel={channel} />;
      }
      case Rck21ConfigService.ServiceName: {
        return <Rck21ConfigForm device={device} channel={channel} />;
      }
      case WszfBidiConfigService.ServiceName: {
        return <WszfBidiConfigForm device={device} channel={channel} />;
      }
      case Rcm21ConfigService.ServiceName: {
        return <Rcm21ConfigForm device={device} channel={channel} />;
      }
      case Rdp21ConfigService.ServiceName: {
        return <Rdp21ConfigForm device={device} channel={channel} />;
      }
      case Rog21ConfigService.ServiceName: {
        return <Rog21ConfigForm device={device} channel={channel} />;
      }
      case RopConfigService.ServiceName: {
        return <RopConfigForm device={device} channel={channel} />;
      }
      case RomConfigService.ServiceName: {
        return <RomConfigForm device={device} channel={channel} />;
      }
      case ZfBidiConfigService.ServiceName: {
        return <ZfBidiConfigForm device={device} channel={channel} />;
      }
      case Rob21ConfigService.ServiceName: {
        return <Rob21ConfigForm device={device} channel={channel} />;
      }
      case Rcr21ConfigService.ServiceName: {
        return <Rcr21ConfigForm device={device} channel={channel} />;
      }
      case Slr21ConfigService.ServiceName:
      case Slr22ConfigService.ServiceName: {
        return <SlrConfigForm device={device} serviceName={configService} channel={channel} />;
      }
      case Srp30ConfigService.ServiceName:
      case Srp22ConfigService.ServiceName: {
        return <SrpConfigForm device={device} channel={channel} />;
      }
      case SsrConfigService.ServiceName: {
        return <SsrConfigForm device={device} channel={channel} />;
      }
      case SbrConfigService.ServiceName: {
        return <SbrConfigForm device={device} channel={channel} />;
      }
      case Portos433ConfigService.ServiceName: {
        return <Config433Form device={device} channel={channel} />;
      }
      default: {
        return null;
      }
    }
  }, [configService, channel]);

  const tabs = useMemo(
    () => [
      { label: t('general') },
      ...(canAddRemoveConfigureDevice && channelConfigForms ? [{ label: t('configuration') }] : []),
    ],
    [canAddRemoveConfigureDevice, channelConfigForms, t],
  );

  const goToDevice = () => {
    navigate(ROUTES.DeviceSettings(channel?.GetDevice().Guid), { state: { back: 3 } });
  };

  const handleTabChange = (tabIndex: number) => {
    navigate(
      `${ROUTES.ChannelConfiguration(channelType, channelId)}?tab=${tabIndex}${
        !isDeviceAvailable ? '&redirect=true' : ''
      }`,
      { replace: true },
    );
    setActiveTabIndex(tabIndex);
  };

  return (
    <Page
      className="channel_configuration"
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('channelSettings')} />
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={handleTabChange}
            tabsType="pills"
            isDivider={false}
          />
        </>
      }
    >
      <>
        {activeTabIndex === 0 ? (
          <>
            {isDeviceAvailable && (
              <div className="max-width-desktop">
                <ArrowButton title={t('deviceSettings')} onClick={goToDevice} />
              </div>
            )}
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="max-width-desktop">
                <hr {...(!isDeviceAvailable ? { className: 'm-t-0' } : {})} />
                <div className="">
                  <Name
                    label={t('configurationNameLabel')}
                    placeholder={t('configurationName')}
                    disabled={!canDeviceEdit}
                  />
                </div>
                {channelType && channel && <ConfigIconEdit channelType={channelType} disabled={!canDeviceEdit} />}
                {canDeviceEdit && (
                  <>
                    <hr />
                    <Info title={t('visibility')} />
                    <div className="row_container">
                      <p>{t('hideNotUsed')}</p>
                      <Switch checked={values.hidden} onChange={handleChangeVisibility} />
                    </div>
                  </>
                )}
                <hr />
                {channel && (
                  <ChannelGroups
                    channel={channel}
                    refetchChannel={refetch}
                    editGroups={editGroups}
                    setEditGroups={setEditGroups}
                  />
                )}
                <SubmitButton disabled={!submitActive}>{tc(`buttons.save`)}</SubmitButton>
              </form>
            </FormProvider>
          </>
        ) : (
          <div className="grid-list-24">{channelConfigForms}</div>
        )}
      </>
    </Page>
  );
};

export default ChannelConfiguration;
