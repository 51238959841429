import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { DeviceType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DialogConfirmation, Header, IconDelete, NavHeader, Page, Tabs } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import { getParameterByName } from '../../../../utils/location';
import { DeviceIcon } from '../../components/device/icon';
import { DeletePopupType } from '../../enums/device';
import { useExalusDevice } from '../../hooks';
import { useLoggedUser } from '../../hooks/common/logged-user';
import ConfigurationDetails from './components/configuration-details';
import { DeviceChannels } from './components/device-channels';
import { useDeviceDetails } from './hooks/use-device-details';
import './index.scss';

const DeviceDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const { deviceId } = useParams<{ deviceId?: string }>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data: device } = useExalusDevice(deviceId || '');
  const { canAddRemoveConfigureDevice } = useLoggedUser();
  const { dialog, closeDialog, setShowDialog, removeDevice } = useDeviceDetails(device);

  useEffect(() => {
    const tab = getParameterByName('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const changeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    navigate(`${location.pathname}?tab=${tabIndex}`, { replace: true });
  };

  const tabs = [{ label: t('configuration') }, ...(device?.Channels.length ? [{ label: t('channels') }] : [])];

  return (
    <Page
      className="device-settings__page"
      isStickyHeader
      header={
        <>
          <NavHeader />
          {device && (
            <Header>
              <div className="device-settings__header">
                <div className="device-settings">
                  <div className="device-settings__icon">
                    <DeviceIcon iconType={device.IconType} />
                  </div>
                  <h4 className="device-settings__info">
                    <div className="device-title text-ellipsis">{device.Name}</div>
                    <div className="device-type text-ellipsis">
                      {`${tc(`types.exalus.${DeviceType[device.DeviceType]}`)} ${device.Model || ''}`}
                    </div>
                  </h4>
                </div>
              </div>
            </Header>
          )}
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={changeTab}
            tabsType="pills"
            isDivider={false}
          />
        </>
      }
    >
      <>
        {activeTabIndex === 0 ? (
          <>
            {device && <ConfigurationDetails device={device} />}
            {canAddRemoveConfigureDevice && (
              <div className="grid-list-24">
                <ArrowButton
                  title={td('removeDevice')}
                  onClick={() => setShowDialog(DeletePopupType.Delete)}
                  type="delete"
                  arrowIcon={<IconDelete />}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <Header subheader title={t('channels')} />
            {device && <DeviceChannels channels={device.Channels} />}
          </>
        )}
      </>
      <DialogConfirmation
        show={!!dialog}
        setShow={closeDialog}
        header={td('removeDeviceDialog.header')}
        content={td(`removeDeviceDialog.content${dialog === DeletePopupType.DeleteForce ? 'Force' : ''}`)}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={removeDevice}
        secondaryBtnAction={() => setShowDialog(null)}
      />
    </Page>
  );
};

export default DeviceDetails;
