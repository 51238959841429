import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboardButton } from '../..';
import './index.scss';

type DetailProps = {
  label: string;
  value: string;
  copyToClipboardAvailable?: boolean;
};

const Detail: React.FC<DetailProps> = ({ label, value, copyToClipboardAvailable }) => {
  const { t } = useTranslation('device-info');

  return (
    <div className="configuration_detail">
      <div className="label-container">
        <div className="label">{t(label)}</div>
        {copyToClipboardAvailable && value && value !== '-' && <CopyToClipboardButton text={value} />}
      </div>
      <p className="value">{value}</p>
    </div>
  );
};

export default Detail;
