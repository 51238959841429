import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { EmptyStateBox, IconWarning } from '../../../../../components';
import {
  OnGenericInputStatusChangedSubscription,
  OnGenericInputStatusChangedSubscriptionVariables,
  VersionedFeatureType,
} from '../../../../../data-access/gql-types/graphql';
import { ON_GENERIC_INPUT_STATUS_CHANGED } from '../../../../../data-access/subscriptions/lavva-devices';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { useVersionMap } from '../../../hooks/api/version-map';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelType } from '../../../types';
import { InputAccordion } from './components/input-accordion';
import './index.scss';

type DeviceInputsProps = {
  inputs: ChannelType[];
  bindingsEnabled: boolean;
};

const DeviceInputs: React.FC<DeviceInputsProps> = ({ inputs, bindingsEnabled }) => {
  const { t: td } = useTranslation('device-settings');
  const { checkSupport, versionMapData } = useVersionMap();
  const { deviceId } = useParams<{ deviceId: string }>();
  const { device } = useDevicesAndChannelsContext();
  const setChannelState = useChannelsStateStore((s) => s.setChannelState);
  const { data } = useSubscription<
    OnGenericInputStatusChangedSubscription,
    OnGenericInputStatusChangedSubscriptionVariables
  >(ON_GENERIC_INPUT_STATUS_CHANGED, {
    variables: { deviceId },
    skip: !deviceId,
  });

  useEffect(() => {
    if (data?.onGenericInputStatusChanged) {
      const { channelId, value } = data.onGenericInputStatusChanged;

      setChannelState(channelId, { status: value });
    }
  }, [data]);

  const monoBiConfigEnable = useMemo(() => {
    return checkSupport({
      feature: VersionedFeatureType.MonoBiModeConfig,
      supportKey: 'Core',
      currentVersion: device?.payload.currentFirmwareVersion,
    });
  }, [device?.payload.currentFirmwareVersion, versionMapData]);

  return (
    <div className="device-inputs">
      {bindingsEnabled ? (
        inputs.map((input) => <InputAccordion key={input.id} input={input} monoBiConfigEnable={monoBiConfigEnable} />)
      ) : (
        <EmptyStateBox content={td('updateToUseFeature')} icon={<IconWarning />} />
      )}
    </div>
  );
};

export default DeviceInputs;
