import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import {
  DeviceConnectionState,
  GateCalibrateStatusChangePayload,
  GateCalibrateStatusTypePayload,
  GateConfigurationChangePayload,
  GateStatusChangedPayload,
} from '../../../../../../data-access/gql-types/graphql';
import { toastError, toastSuccess } from '../../../../../../utils/toast';
import { useDevicesAndChannelsContext } from '../../../../context/devices-and-channels';
import { getGateMode } from '../../../../modules/channel-details/gate/utils';
import { useChannelsStateStore } from '../../../../store/channels-state';
import { ChannelGateStateType, ChannelGateType } from '../../../../types';

export const useLavvaDeviceGateUpdate = () => {
  const { t } = useTranslation('channel-settings');
  const { t: tr } = useTranslation('backend-validation');
  const { updateChannelDetails, channelList, setChannelList } = useDevicesAndChannelsContext();
  const setChannelState = useChannelsStateStore((s) => s.setChannelState);
  const getChannelState = useChannelsStateStore((s) => s.getChannelState);

  const updateLavvaGateStatus = async ({
    channelId,
    dailyModeStatus,
    direction,
    lockStatus,
    partialControlModeStatus,
    position,
    predictedTimeInMs,
    targetPosition,
  }: GateStatusChangedPayload) => {
    setChannelState(channelId, {
      position,
      direction,
      movingParams: {
        predictedTimeInMs: predictedTimeInMs != null ? predictedTimeInMs : null,
        targetPosition: targetPosition != null ? targetPosition : null,
      },
      dailyModeStatus,
      lockStatus,
      partialControlModeStatus,
      deviceConnectionState: DeviceConnectionState.Connected,
    } as ChannelGateStateType);
  };

  const updateLavvaGateConfiguration = async ({
    channelId,
    supportedGateFeatures,
  }: Pick<GateConfigurationChangePayload, 'channelId' | 'supportedGateFeatures'>) => {
    setChannelList((prev) => {
      const tempList = cloneDeep(prev);
      const index = tempList.findIndex((x) => x.id === channelId);

      if (index !== -1) {
        (tempList[index].data as ChannelGateType).supportedGateFeatures = supportedGateFeatures;
        (tempList[index].data as ChannelGateType).gateMode = getGateMode(
          supportedGateFeatures,
          (tempList[index].data as ChannelGateType).gateMode,
        );
      }

      return tempList;
    });

    updateChannelDetails((prev) => {
      const temp = cloneDeep(prev);
      if (temp) {
        (temp.data as ChannelGateType).supportedGateFeatures = supportedGateFeatures;
        (temp.data as ChannelGateType).gateMode = getGateMode(
          supportedGateFeatures,
          (temp.data as ChannelGateType).gateMode,
        );
      }

      return temp;
    });
  };

  const onGateCalibrateStatusChange = ({
    channelId,
    progress,
    openingMilliseconds,
    closingMilliseconds,
    status,
    error,
  }: GateCalibrateStatusChangePayload) => {
    if (error) {
      toastError({ content: tr(error) });

      const calibrateState = (getChannelState(channelId) as ChannelGateStateType | null)?.calibrateState;

      setChannelState(channelId, {
        calibrateState: {
          openingMilliseconds: calibrateState?.openingMilliseconds || 0,
          closingMilliseconds: calibrateState?.closingMilliseconds || 0,
          error: null,
          progress: null,
          started: calibrateState?.started,
        },
      } as ChannelGateStateType);
    } else {
      const prevState = getChannelState(channelId) as ChannelGateStateType | null;

      setChannelState(channelId, {
        calibrateState: {
          openingMilliseconds: openingMilliseconds || 0,
          closingMilliseconds: closingMilliseconds || 0,
          error: !error ? prevState?.calibrateState?.error : error,
          progress,
          started: !!progress,
        },
      } as ChannelGateStateType);

      const channelAlias = channelList.find((channel) => channel.id === channelId)?.alias;

      if (status === GateCalibrateStatusTypePayload.Data) {
        toastSuccess({
          content: `${t('toast.calibrationSuccess', { channel: channelAlias })}`,
        });
      }
    }
  };

  return {
    updateLavvaGateConfiguration,
    onGateCalibrateStatusChange,
    updateLavvaGateStatus,
  };
};
