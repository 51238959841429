import { sortBy } from 'lodash';
import { TimeItem } from '../../../../../types/actions';
import { leadingZero } from '../../../../../utils/helpers';

export const sunTimeArray = () => {
  const list: TimeItem[] = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 100; j++) {
      const floatValue = parseFloat(`${i}.${leadingZero(j)}`);

      list.push({
        hour: floatValue,
        height: (-1 / 24) * Math.pow(floatValue, 2) + floatValue,
      });
    }
  }

  return sortBy(list, 'hour');
};
