import { ActionType, DeviceConnectionState } from '../../../../data-access/gql-types/graphql';
import { ChannelStateType, ChannelType } from '../../types';

export const checkActionStatus = (
  channels: ChannelType[] | undefined,
  channelState: Record<string, ChannelStateType | null>,
  status: DeviceConnectionState,
) => {
  return !!(
    channels?.length &&
    channels.every((actionChannel) => channelState[actionChannel.id]?.deviceConnectionState === status)
  );
};

export const actionNameType = {
  [ActionType.Central]: 'defaultAction',
  [ActionType.Basic]: 'basicAction',
  [ActionType.Advanced]: 'advancedAction',
};
