import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBlindCalibrate } from '../../../../api/blind/blind.hooks';
import { useGateCalibrate } from '../../../../api/gate/gate.hooks';
import { EmptyStateBox, IconWarning } from '../../../../../../components';
import { ChannelTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../../store/channels-state';
import { ChannelCoverStateType, ChannelGateStateType, ChannelType } from '../../../../types';
import './index.scss';

type CalibrationInfoDetailsProps = {
  channel: ChannelType;
};

const CalibrationInfoDetails: React.FC<CalibrationInfoDetailsProps> = <
  T extends ChannelCoverStateType | ChannelGateStateType,
>({
  channel,
}) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const calibrationBlind = useBlindCalibrate();
  const calibrationGate = useGateCalibrate();
  const state = useChannelsStateStore((s) => s.channelState[channel.id]) as T | null;

  const handleCalibrate = () => {
    if (channel) {
      const variables = { deviceId: channel.deviceId, channelId: channel.id };

      if (channel.data.type === ChannelTypeInternal.Blind) {
        calibrationBlind.mutate(variables);
      } else if (channel.data.type === ChannelTypeInternal.Gate) {
        calibrationGate.mutate(variables);
      }
    }
  };

  return (
    <div className="calibration-window p-l-24 p-r-24 m-b-24">
      <EmptyStateBox
        header={tc('status.calibrate')}
        btnText={tc('status.calibrate')}
        content={
          state?.calibrateState?.progress
            ? `${t('calibrationProgress')} ${state?.calibrateState?.progress}%`
            : t('calibrationNeeded')
        }
        icon={<IconWarning />}
        {...(!state?.calibrateState?.progress ? { onClick: handleCalibrate } : {})}
      />
    </div>
  );
};

export default CalibrationInfoDetails;
