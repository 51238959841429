import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Input, InputEdit, IconChevron } from '../../../components';
import { useBackdropContext } from '../../../context/backdrop';
import { ChangeUserNameMutation, ChangeUserNameMutationVariables } from '../../../data-access/gql-types/graphql';
import { CHANGE_NAME } from '../../../data-access/mutations/user';
import { environment } from '../../../environment';
import { useProfile } from '../../../hooks';
import { toastError, toastSuccess } from '../../../utils/toast';
import { patterns } from '../../../utils/validation';
import * as storage from '../../../integrations/lavva/storage';

const SettingsForm: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const { t, i18n } = useTranslation('profile');
  const [disabled, setDisabled] = useState<boolean>(true);
  const { control, handleSubmit, setValue } = useForm({ defaultValues: { firstName: '' } });
  const { user, refetchUserMe } = useProfile();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [changeUserName] = useMutation<ChangeUserNameMutation, ChangeUserNameMutationVariables>(CHANGE_NAME);
  const theme = storage.getItem('theme');

  useEffect(() => setValue('firstName', user.profile.firstName), [user]);

  const onSubmit = ({ firstName }) => {
    turnOnBackdrop();
    setDisabled(true);
    changeUserName({
      variables: { input: { firstName } },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data.changeUserName.result?.ok) {
          refetchUserMe();
          toastSuccess({ content: t('toastNameSuccess') });
        } else {
          if (data.changeUserName.errors) {
            toastError({ content: tc('errors.somethingWentWrong') });
          }
        }
      },
      onError: () => {
        turnOffBackdrop();
        toastError({ content: t('toastError') });
      },
    });
  };

  return (
    <>
      <div className="grid-list-24">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: tc('isRequired') as string,
              maxLength: {
                value: 40,
                message: tc('tooLongName'),
              },
              pattern: {
                value: patterns.name,
                message: tc('invalidName'),
              },
            }}
            render={({ field, fieldState }) => (
              <InputEdit
                defaultValue={field.value}
                disabled={disabled}
                setDisabled={setDisabled}
                label={t('username')}
                onChange={field.onChange}
                value={field.value}
                hasError={!!fieldState.error}
              />
            )}
          />
        </form>
        <Input inputType="text" defaultValue={user.profile.email} label={t('email')} id="email" disabled />
        <div>
          <span className="caption">{t('password')}</span>
          <a
            href={`${environment.KEYCLOAK_CHANGE_PASSWORD_URL}?kc_locale=${i18n.language}&base_url=${window.location.origin}&theme=${theme}`}
            className="profile__btn-account"
          >
            {t('change-password')}
            <IconChevron direction="right" noSpace />
          </a>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;
