import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconChevron, IconPause } from '../../../../../../../../../components';
import { ChannelBoxControls } from '../../../../../../../../../components/channel-box-controls';
import { useGatePreciseControl } from '../../../../../hooks/use-gate-precise-control';

type GatePreciseControlsProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const GatePreciseControls: React.FC<GatePreciseControlsProps> = ({ channel, isListItem }) => {
  const { open, close, stop } = useGatePreciseControl(channel);

  return (
    <ChannelBoxControls
      kind="normal"
      isListItem={isListItem}
      controls={[
        {
          icon: <IconChevron withCircle direction="down" />,
          handleAction: close,
        },
        {
          icon: <IconPause />,
          handleAction: stop,
        },
        {
          icon: <IconChevron withCircle direction="up" />,
          handleAction: open,
        },
      ]}
    />
  );
};

export default GatePreciseControls;
