import React from 'react';
import { ChannelTypeInternal, GateModeInternal } from '../../../../../data-access/gql-types/graphql';
import { useRgb } from '../../../modules/channel-details/light/hooks/use-rgb';
import { useChannelsStateStore } from '../../../store/channels-state';
import {
  ChannelCoverStateType,
  ChannelType,
  ChannelLightStateType,
  ChannelGateStateType,
  ChannelGateType,
} from '../../../types';
import { useChannel } from '../hooks/use-channel';
import { ChannelIcon } from '../icon';
import { ChannelBoxPostName } from './post-name';
import { ChannelBoxPreName } from './pre-name';

type HeadContentProps = {
  channel: ChannelType;
};

export const HeadContent: React.FC<HeadContentProps> = ({ channel }) => {
  const { hasBrightness, hasRGB } = useRgb(channel);
  const { additionalInfo } = useChannel({ channel, details: true });
  const { id, data } = channel;
  const state = useChannelsStateStore((s) => s.channelState[id]);

  const getPercentageBadge = () => {
    switch (data.type) {
      case ChannelTypeInternal.Blind:
        return (state as ChannelCoverStateType)?.lavvaStatePosition
          ? `${(state as ChannelCoverStateType)?.lavvaStatePosition}%`
          : '0%';
      case ChannelTypeInternal.Gate: {
        if ((data as ChannelGateType).gateMode === GateModeInternal.RollUp) {
          return (state as ChannelGateStateType)?.position ? `${(state as ChannelGateStateType)?.position}%` : `0%`;
        }
        return null;
      }
      case ChannelTypeInternal.Light:
        return hasBrightness && (state as ChannelLightStateType)?.brightness !== null
          ? `${(state as ChannelLightStateType)?.brightness}%`
          : null;
      default:
        return null;
    }
  };

  const percentageBadge = getPercentageBadge();

  return (
    <>
      <div className="device-box__head-icon">
        <ChannelIcon channel={channel} />
        {additionalInfo}
        {percentageBadge !== null && <div className="channel-value-badge">{percentageBadge}</div>}
      </div>
      <div className="device-box__head-wrapper">
        {hasRGB && <ChannelBoxPreName channel={channel} />}
        <div className="device-box__head-title text-ellipsis">
          <span className="text-ellipsis">{channel.alias}</span>
          <ChannelBoxPostName channel={channel} />
        </div>
      </div>
    </>
  );
};
