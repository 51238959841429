import { ChannelTypeInternal, GateFeatureTypeInternal, GateModeInternal } from '../../../../data-access/gql-types/graphql';
import { ChannelGateType, ChannelType } from '../../types';

export const checkIfRollUpControls = (channels: ChannelType[]) => {
  if (
    channels.every(
      (x) => x.data.type === ChannelTypeInternal.Gate && (x.data as ChannelGateType).supportedGateFeatures.length,
    )
  ) {
    return channels.every((x) =>
      (x.data as ChannelGateType).supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir),
    );
  }

  return channels.every(
    (channel) =>
      channel.data.type === ChannelTypeInternal.Gate &&
      (channel.data as ChannelGateType).gateMode === GateModeInternal.RollUp,
  );
};
