import { useSwitchSetOff, useSwitchSetOn } from '../../api/switch/switch.hooks';
import { SwitchStateInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../store/channels-state';
import { ChannelSwitchStateType } from '../../types';

type SwitchHook = {
  channelId: string;
  deviceId: string;
};

export const useSwitch = ({ channelId, deviceId }: SwitchHook) => {
  const state = useChannelsStateStore((s) => s.channelState[channelId]);
  const switchOn = useSwitchSetOn();
  const switchOff = useSwitchSetOff();

  const isOn = (state as ChannelSwitchStateType)?.lavvaState === SwitchStateInternal.On;

  const handleControlOn = (status: boolean) => {
    if (!status) switchOff.mutate({ deviceId, channelId });
    else switchOn.mutate({ deviceId, channelId });
  };

  return { handleControlOn, isOn };
};
