import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import { ACCENT_COLOR_DEFAULT_INDEX_MAP, INSTALLATION_COLOR_LIST } from '../../../const';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { environment } from '../../../environment';
import { ChildrenProps, InstallationIconTypeEnum, IntegrationDefaultEnum } from '../../../types';
import { AuthorizeControllerBody } from '../../../integrations/exalus/types/auth';
import {
  SetInstallationNameAction,
  SetIconNameAction,
  SetTimeZoneAction,
  SetHexColorAction,
  SetIntegrationType,
  SetExalusCredentials,
  ResetInstallationCreateAction,
  SetCreateLoadingAction,
  SET_INSTALLATION_NAME,
  SET_ICON_NAME,
  SET_TIME_ZONE,
  SET_HEX_COLOR,
  RESET_INSTALLATION_CREATE,
  SET_CREATE_LOADING,
  SET_LOCATION_LAT,
  SET_LOCATION_LONG,
  SetLocationLatAction,
  SetLocationLongAction,
  SET_INTEGRATION_TYPE,
  SET_EXALUS_CREDENTIALS,
} from './constants';

export type StateCreateInstallationType = {
  integrationDefault: IntegrationDefaultEnum;
  installationName: string;
  iconName: string;
  timeZone: string;
  locationLat: number | null;
  locationLong: number | null;
  hexColor: string;
  integrationType: IntegrationType;
  createLoading: boolean;
  exalusCredentials: AuthorizeControllerBody;
};

type ActionType =
  | SetInstallationNameAction
  | SetIconNameAction
  | SetTimeZoneAction
  | SetHexColorAction
  | SetIntegrationType
  | SetExalusCredentials
  | ResetInstallationCreateAction
  | SetCreateLoadingAction
  | SetLocationLatAction
  | SetLocationLongAction;

type ContextType = {
  state: StateCreateInstallationType;
  dispatch: Dispatch<ActionType>;
};

export const initialState: StateCreateInstallationType = {
  integrationDefault: environment.INTEGRATION_DEFAULT,
  installationName: '',
  iconName: InstallationIconTypeEnum.INSTALLATION_0,
  timeZone: '',
  locationLat: null,
  locationLong: null,
  hexColor: INSTALLATION_COLOR_LIST[ACCENT_COLOR_DEFAULT_INDEX_MAP[environment.INTEGRATION_DEFAULT]],
  integrationType: IntegrationType.Undefined,
  createLoading: false,
  exalusCredentials: {
    serialNumber: '',
    pin: '',
  },
};

const CreateInstallationContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

const createInstallationReducer = (state: StateCreateInstallationType = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_INSTALLATION_NAME: {
      return {
        ...state,
        installationName: action.payload,
      };
    }
    case SET_ICON_NAME: {
      return {
        ...state,
        iconName: action.payload,
      };
    }
    case SET_TIME_ZONE: {
      return {
        ...state,
        timeZone: action.payload,
      };
    }
    case SET_LOCATION_LAT: {
      return {
        ...state,
        locationLat: action.payload,
      };
    }
    case SET_LOCATION_LONG: {
      return {
        ...state,
        locationLong: action.payload,
      };
    }
    case SET_HEX_COLOR: {
      return {
        ...state,
        hexColor: action.payload,
      };
    }
    case SET_INTEGRATION_TYPE: {
      return {
        ...state,
        integrationType: action.payload,
      };
    }
    case SET_EXALUS_CREDENTIALS: {
      return {
        ...state,
        exalusCredentials: action.payload,
      };
    }
    case RESET_INSTALLATION_CREATE: {
      return {
        ...initialState,
      };
    }
    case SET_CREATE_LOADING: {
      return {
        ...state,
        createLoading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const useCreateInstallationContext = (): ContextType => useContext(CreateInstallationContext);

export const CreateInstallationProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(createInstallationReducer, initialState);
  return (
    <CreateInstallationContext.Provider value={{ state, dispatch }}>{children}</CreateInstallationContext.Provider>
  );
};
