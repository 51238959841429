import React from 'react';
import { IconBulb } from '../../../../../../../components';
import { DependantChannelResponse } from '../../../../../../../data-access/gql-types/graphql';
import { useSwitch } from '../../../../../hooks';

type DependantChannelProps = {
  channel: DependantChannelResponse;
  deviceId: string;
};

const DependantChannel: React.FC<DependantChannelProps> = ({ channel, deviceId }) => {
  const { isOn, handleControlOn } = useSwitch({ channelId: channel.channelId, deviceId });

  return (
    <button key={channel.channelId} onClick={() => handleControlOn(!isOn)} className="btn-custom">
      <div className="icon">
        <IconBulb isOn={isOn} />
      </div>
      <span>{isOn ? 'OFF' : 'ON'}</span>
    </button>
  );
};

export default DependantChannel;
