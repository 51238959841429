import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { DeviceUsedInScenes } from 'lavva.exalushome/build/js/Services/Devices/DevicesService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceTaskExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/TaskExecutionResult';
import { useBackdropContext } from '../../../../../context/backdrop';
import { ROUTES } from '../../../../../routes';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useExalusServicesContext } from '../../../context/services';
import { DeletePopupType } from '../../../enums';
import { useHandleDataFrameErrors } from '../../../hooks';

export const useDeviceDetails = (device?: IDevice) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const [dialog, setShowDialog] = useState<DeletePopupType | null>(null);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { devicesApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();

  const removeDevice = async () => {
    if (device) {
      turnOnBackdrop();
      const usedInScene: DeviceUsedInScenes | Status = await devicesApi.CheckIfDeviceUsedInScenesAsync(device.Guid);

      if ((usedInScene as DeviceUsedInScenes).DeviceGuid) {
        if (
          !(usedInScene as DeviceUsedInScenes).ConditionsAsCondition.length &&
          !(usedInScene as DeviceUsedInScenes).ConditionsAsTask.length
        ) {
          switch (dialog) {
            case DeletePopupType.Delete: {
              const result = await device.RemoveDeviceAsync();
              handleResult(result);
              break;
            }
            case DeletePopupType.DeleteForce: {
              const result = await devicesApi.RemoveDeviceAsync(device, true);
              handleResult(result, true);
              break;
            }
            default: {
              toastError({ content: tc('errors.somethingWentWrong') });
              break;
            }
          }
        } else {
          const scenesFromConditions = (usedInScene as DeviceUsedInScenes).ConditionsAsCondition.map((x) => x.Name);
          const scenesFromTasks = (usedInScene as DeviceUsedInScenes).ConditionsAsTask.map((x) => x.Name);
          turnOffBackdrop();
          setShowDialog(null);

          toastError({
            content: `${td('toast.removeDeviceErrorScene')} ${scenesFromConditions.concat(scenesFromTasks).join(', ')}`,
          });
        }
      } else {
        handleError(usedInScene as Status);
      }
    }
  };

  const handleResult = (result: DeviceTaskExecutionResult, force?: boolean) => {
    turnOffBackdrop();

    switch (result) {
      case DeviceTaskExecutionResult.Executed:
        toastSuccess({ content: td('toast.removeDeviceSuccess') });

        if (location.state?.back) navigate(-location.state.back);
        else navigate(ROUTES.Root(), { replace: true });
        break;
      case DeviceTaskExecutionResult.ControllerResponseTimeout:
        toastError({ content: tc('exalus.taskResult.controllerResponseTimeout', { deviceName: name }) });
        break;
      case DeviceTaskExecutionResult.Failed:
        if (force) {
          toastError({ content: t('exalus.taskResult.failed', { deviceName: name }) });
        } else {
          setShowDialog(DeletePopupType.DeleteForce);
        }

        break;
      case DeviceTaskExecutionResult.DeviceResponseTimeout:
        if (force) {
          toastError({ content: t('exalus.taskResult.deviceResponseTimeout', { deviceName: name }) });
        } else {
          setShowDialog(DeletePopupType.DeleteForce);
        }

        break;
      default:
        break;
    }
  };

  const closeDialog = () => setShowDialog(null);

  return {
    dialog,
    closeDialog,
    setShowDialog,
    removeDevice,
  };
};
