import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrors } from '../../../../hooks/errors';
import { PublishStatus } from '../../../../types';
import { useRestClient } from '../../../../utils/clients/rest-client';
import { RecoveryBody } from './recovery.types';

export const useGetDeviceStatus = (): UseMutationResult<AxiosResponse<PublishStatus>, AxiosError, RecoveryBody> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<PublishStatus>, AxiosError, RecoveryBody>(
    ['recovery-getDeviceStatus'],
    async (body) => await restClient.post<PublishStatus>('/recovery/getDeviceStatus', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useUpdateRecoveryFirmware = (): UseMutationResult<
  AxiosResponse<PublishStatus>,
  AxiosError,
  RecoveryBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<PublishStatus>, AxiosError, RecoveryBody>(
    ['recovery-updateFirmware'],
    async (body) => await restClient.post<PublishStatus>('/recovery/updateFirmware', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
