import { parseChannelState } from '.';
import { ChannelTypeInternal, PartialConfigProperty, UserChannel } from '../../../../../data-access/gql-types/graphql';
import { ChannelCoverStateType, ChannelGateStateType, ChannelStateType } from '../../../types';

export const getParsedState = (currentState: ChannelStateType, userChannel: UserChannel) => {
  const state = parseChannelState(userChannel);

  if (userChannel.channelType === ChannelTypeInternal.Blind) {
    if ((currentState as ChannelCoverStateType)?.movingParams?.predictedTimeInMs) return null;
    if ((currentState as ChannelCoverStateType)?.calibrateState?.progress) return null;
  }

  if (userChannel.channelType === ChannelTypeInternal.Gate) {
    if ((currentState as ChannelGateStateType)?.movingParams?.predictedTimeInMs) return null;
    if ((currentState as ChannelGateStateType)?.calibrateState?.progress) return null;
  }

  return state;
};

export const getNumericalValue = (numericalProperties: PartialConfigProperty[], property: string) => {
  return numericalProperties.find((x) => x.property === property);
};
