import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomBackdrop, EmptyStateBox, IconWarning } from '../../../../../../components';
import { Trigger, UserPermissionType } from '../../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../../routes';
import { TriggerBox } from '../../../../components/trigger';
import { useAddDevice } from '../../../device-add/hooks/use-add-device';

type TriggerListProps = {
  list: Trigger[];
  loading: boolean;
  channelListCount: number;
};

export const TriggerList: React.FC<TriggerListProps> = ({ list, loading, channelListCount }) => {
  const { t } = useTranslation('action');
  const { addDevice } = useAddDevice();
  const { permissions } = useUserPermissions();

  if (loading) return <CustomBackdrop loading />;

  if (!channelListCount) {
    return (
      <EmptyStateBox
        header={t('automationActionEmptyState.header')}
        btnText={t('automationActionEmptyState.missingDevice.btnText')}
        content={t('automationActionEmptyState.missingDevice.content')}
        icon={<IconWarning />}
        onClick={addDevice}
      />
    );
  }

  if (!loading && !list?.length) {
    return (
      <EmptyStateBox
        header={t('automationActionEmptyState.header')}
        content={t('automationActionEmptyState.missingAction.content')}
        icon={<IconWarning />}
        {...(permissions[UserPermissionType.TriggersCreate]
          ? { btnText: t('automationActionEmptyState.missingAction.btnText'), linkTo: ROUTES.TriggerCreate() }
          : {})}
      />
    );
  }

  return (
    <div className="grid-list-16 p-b-24">
      {list?.map((trigger) => (
        <TriggerBox key={trigger.triggerId} triggerData={trigger} triggerId={trigger.triggerId} />
      ))}
    </div>
  );
};
