import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useMutation } from '@apollo/client';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useInstallationContext } from '../../../../../context/installation';
import {
  AddToFavouritesMutation,
  AddToFavouritesMutationVariables,
  DashboardItemType,
  RemoveFromFavouritesMutation,
  RemoveFromFavouritesMutationVariables,
} from '../../../../../data-access/gql-types/graphql';
import { ADD_TO_FAVOURITES, REMOVE_FROM_FAVOURITES } from '../../../../../data-access/mutations/groups';
import { useRefetchData } from '../../../../../hooks/refresh-data';
import { useApi } from '../../../hooks';
import { ChannelType } from '../../../types';

export const useFavourite = (channel: ChannelType) => {
  const [isChannelInGroup, setIsChannelInGroup] = useState<boolean>();
  const { favouriteGroup } = useApi();
  const { selectedInstallationId } = useInstallationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchDashboard } = useRefetchData();
  const [addToFavourite, { data: addData }] = useMutation<AddToFavouritesMutation, AddToFavouritesMutationVariables>(
    ADD_TO_FAVOURITES,
    {
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data?.addToFavourites?.id) refetchDashboard();
      },
    },
  );
  const [removeFromFavourite, { data: removeData }] = useMutation<
    RemoveFromFavouritesMutation,
    RemoveFromFavouritesMutationVariables
  >(REMOVE_FROM_FAVOURITES, {
    onCompleted: (data) => {
      turnOffBackdrop();
      if (data?.removeFromFavourites?.id) refetchDashboard();
    },
  });

  useEffect(() => {
    favouriteGroup
      ? setIsChannelInGroup(
          !!favouriteGroup?.userGroupItems?.find((item) => {
            if ('itemId' in item) return item.itemId === channel.id;
          }),
        )
      : setIsChannelInGroup(false);
  }, [favouriteGroup, setIsChannelInGroup]);

  useEffect(() => {
    if (removeData) setIsChannelInGroup(false);
  }, [removeData, setIsChannelInGroup]);

  useEffect(() => {
    if (addData) setIsChannelInGroup(true);
  }, [setIsChannelInGroup, addData]);

  const toggleFavouriteGroup = debounce(() => {
    turnOnBackdrop();

    const variables = {
      id: channel.id,
      itemType: DashboardItemType.Channel,
      installationId: selectedInstallationId,
    };

    isChannelInGroup
      ? removeFromFavourite({ variables: { input: variables } })
      : addToFavourite({ variables: { input: variables } });
  }, 400);

  return {
    isChannelInGroup,
    favouriteGroup,
    toggleFavouriteGroup,
  };
};
