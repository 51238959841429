import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, CarouselItem, CustomBackdrop, EmptyStateBox, IconWarning } from '../../../components';
import { useExalusStorageContext } from '../context/storage';
import { ROUTES } from '../../../routes';
import { useLoggedUser } from '../hooks/common/logged-user';
import ChannelGroup from '../modules/channel-list/components/channel-group';
import { GroupedChannel } from '../modules/channel-list/types';

type ExalusChannelsProps = {
  itemGroupsList: GroupedChannel[];
  isFetched: boolean;
  isLoading: boolean;
};

const ExalusChannels: React.FC<ExalusChannelsProps> = ({ itemGroupsList, isFetched, isLoading }) => {
  const { t } = useTranslation('channel-list');
  const { activeChannelTabIndex, setActiveChannelTab } = useExalusStorageContext();
  const { canAddRemoveConfigureDevice } = useLoggedUser();

  return (
    <>
      {isFetched ? (
        itemGroupsList.length ? (
          <Carousel setActiveIndex={setActiveChannelTab} activeIndex={activeChannelTabIndex}>
            {itemGroupsList.map((groupChannel: GroupedChannel, index: number) => (
              <CarouselItem key={groupChannel.label} className={'m-t-8'}>
                {activeChannelTabIndex === index && <ChannelGroup groupChannel={groupChannel} />}
              </CarouselItem>
            ))}
          </Carousel>
        ) : (
          <EmptyStateBox
            header={t('devicesEmptyState.header')}
            btnText={t('devicesEmptyState.missingDevice.btnText')}
            content={t('devicesEmptyState.missingDevice.content')}
            icon={<IconWarning />}
            {...(canAddRemoveConfigureDevice ? { linkTo: ROUTES.AddExalusDevices() } : {})}
          />
        )
      ) : null}
      <CustomBackdrop loading={isLoading} />
    </>
  );
};

export default ExalusChannels;
