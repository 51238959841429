import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  GateFeatureTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelCoverStateType, ChannelGateStateType, ChannelType } from '../../../types';
import { IconConnectionError, IconRecovery } from '../../../../../components/icons';
import { IconCalibrateInfo } from '../../../../../components/icons/calibrate-info';

type ChannelParams = {
  channel?: ChannelType;
  bigIcons?: boolean;
  details?: boolean;
};

export const useChannel = ({ channel, bigIcons, details }: ChannelParams) => {
  const channelId = channel?.id || '';
  const state = useChannelsStateStore((s) => s.channelState[channelId]);

  const calibrationNeeded = useMemo(() => {
    if (channel?.id) {
      if (channel.data.type === ChannelTypeInternal.Blind) {
        const calibrateState = (state as ChannelCoverStateType)?.calibrateState;
        return (
          !(calibrateState?.openingMilliseconds && calibrateState?.closingMilliseconds) || calibrateState?.progress
        );
      } else if (
        channel.data.type === ChannelTypeInternal.Gate &&
        channel.data.supportedGateFeatures.includes(GateFeatureTypeInternal.Calibrate)
      ) {
        const calibrateState = (state as ChannelGateStateType)?.calibrateState;
        return (
          !(calibrateState?.openingMilliseconds && calibrateState?.closingMilliseconds) || calibrateState?.progress
        );
      }
    }
  }, [(state as ChannelCoverStateType)?.calibrateState, (state as ChannelGateStateType)?.calibrateState]);

  const additionalInfo = useMemo(() => {
    if (state?.deviceConnectionState === DeviceConnectionState.Disconnected)
      return <IconConnectionError big={bigIcons} details={details} size={14} />;
    if (state?.deviceConnectionState === DeviceConnectionState.Recovery)
      return <IconRecovery big={bigIcons} details={details} />;
    if (calibrationNeeded) return <IconCalibrateInfo big={bigIcons} details={details} />;
    return null;
  }, [state?.deviceConnectionState, calibrationNeeded, bigIcons, details]);

  return {
    calibrationNeeded,
    additionalInfo,
  };
};
