import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControllerUpdateType,
  IControllerAvailableUpdateInfo,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { UpdateErrorCode } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { ControllerUpdateNotAvailableInfo } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import {
  DialogConfirmation,
  EmptyStateBox,
  Header,
  IconWarning,
  NavHeader,
  Page,
} from '../../../../../components';
import TextInfo from '../../../../../components/text-info';
import { usePreventBack } from '../../../../../hooks/navigation-guard/use-navigation-guard';
import { useExalusContext } from '../../../context';
import { PopupType } from '../../../enums/controller';
import { useLoggedUser } from '../../../hooks/common/logged-user';
import { useControllerSettings } from '../hooks/use-controller-settings';
import { langCode } from '../hooks/use-update-process';
import { useUpdates } from '../hooks/use-updates';
import './index.scss';

const ControllerUpdates: React.FC = () => {
  const { t, i18n } = useTranslation('device-info');
  const { t: ti } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { popup, popupType, setPopup, showDialog, handleConfirm } = useControllerSettings();
  const { synchronized } = useExalusContext();
  const { checkForControllerUpdates, controllerUpdate, loading, startAllControllerUpdates, startOneControllerUpdate } =
    useUpdates();
  const { user: userExalus } = useLoggedUser();
  usePreventBack(loading ? t('exalus.params.Version.noBackMessage') : '');

  useEffect(() => {
    if (synchronized) checkForControllerUpdates();
  }, [synchronized]);

  const additionalControllerButtonsVisible = useMemo(() => {
    if (userExalus?.AccessLevel) return userExalus.AccessLevel >= 30;
    return false;
  }, [userExalus]);

  const getProcessKey = (update: IControllerAvailableUpdateInfo) => {
    if (!update.UpdateIsDownloading && update.DownloadProgress === 100) return 'updateStart';
    if (!update.UpdateIsDownloading && !update.DownloadProgress) return 'downloadStart';
    if (update.UpdateIsDownloading && update.DownloadProgress > 0 && update.DownloadProgress < 100)
      return 'downloading';
    return null;
  };

  return (
    <Page
      isStickyHeader
      className="controller-updates"
      header={
        <>
          <NavHeader />
          <Header title={ti('softUpdate')} />
        </>
      }
    >
      {controllerUpdate ? (
        <>
          {controllerUpdate.UpdatesAvailable.length > 0 && (
            <div className="update-controller">
              <div className="update-controller--content">
                {controllerUpdate.UpdatesAvailable.map((update: IControllerAvailableUpdateInfo, i: number) => {
                  const key = getProcessKey(update);
                  return (
                    <div key={i} className="version-container">
                      <div className="resource-header">
                        <h3>{`${t(
                          `exalus.params.Version.${
                            update.UpdateType === ControllerUpdateType.SoftwareUpdate
                              ? 'SoftwareUpdate'
                              : 'RadioFirmware'
                          }`,
                        )} ${
                          update.UpdateType !== ControllerUpdateType.SoftwareUpdate ? update.UpdatedResourceName : ''
                        }`}</h3>
                        {key && (
                          <button
                            className="button button--secondary"
                            disabled={key === 'downloading'}
                            onClick={() => startOneControllerUpdate(update)}
                          >
                            {t(`exalus.params.Version.${key}`)}
                          </button>
                        )}
                      </div>
                      <div className="version-values">
                        <TextInfo
                          value={update.CurrentResourceVersion}
                          label={`${t('exalus.params.Version.currentVersion')}:`}
                          className="m-b-8"
                        />
                        <TextInfo
                          value={update.NewResourceVersion}
                          label={`${t('exalus.params.Version.newVersion')}:`}
                          className="m-b-8"
                        />
                        <TextInfo
                          value={
                            update.UpdateType !== null
                              ? t(`exalus.params.Version.${ControllerUpdateType[update.UpdateType]}`)
                              : '-'
                          }
                          label={`${t('exalus.params.Version.type')}:`}
                          className="m-b-8"
                        />
                      </div>
                      {additionalControllerButtonsVisible &&
                        update.UpdatedResourceName.get(langCode[i18n.language]) ===
                          t('exalus.params.Version.SoftwareUpdate') && (
                          <button
                            className="button button--secondary max-width-desktop"
                            onClick={() => showDialog(PopupType.Rollback)}
                          >
                            {ti('exalus.rollbackControllerUpdate')}
                          </button>
                        )}
                      <hr />
                    </div>
                  );
                })}
                <button
                  className="button button--secondary m-b-24 max-width-desktop"
                  onClick={() => startAllControllerUpdates(controllerUpdate.UpdatesAvailable)}
                >
                  {`${t('exalus.params.Version.updateAllStart')} (${controllerUpdate.UpdatesAvailable.length})`}
                </button>
              </div>
            </div>
          )}
          {controllerUpdate.UpdatesNotAvailable.length > 0 && (
            <div className="update-controller m-t-24">
              <div className="update-controller--content">
                {controllerUpdate.UpdatesNotAvailable.map((update: ControllerUpdateNotAvailableInfo, i: number) => (
                  <div key={i} className="version-container">
                    <h3>{`${t(
                      `exalus.params.Version.${
                        update.UpdateType === ControllerUpdateType.SoftwareUpdate ? 'SoftwareUpdate' : 'RadioFirmware'
                      }`,
                    )} ${
                      update.UpdateType !== ControllerUpdateType.SoftwareUpdate
                        ? update.UpdatedResourceName.get(langCode[i18n.language])
                        : ''
                    }`}</h3>
                    <div className="version-values">
                      <TextInfo
                        value={
                          update.ResponseResult
                            ? t(`exalus.updateResult.${UpdateErrorCode[update.ResponseResult.Type]}`)
                            : '-'
                        }
                        label={`${t('exalus.params.Version.status')}:`}
                        className="m-b-8"
                      />
                      <TextInfo
                        value={update.CurrentResourceVersion}
                        label={`${t('exalus.params.Version.currentVersion')}:`}
                        className="m-b-8"
                      />
                      <TextInfo
                        value={
                          update.UpdateType !== null
                            ? t(`exalus.params.Version.${ControllerUpdateType[update.UpdateType]}`)
                            : '-'
                        }
                        label={`${t('exalus.params.Version.type')}:`}
                        className="m-b-8"
                      />
                    </div>
                    {additionalControllerButtonsVisible &&
                      update.UpdateType === ControllerUpdateType.SoftwareUpdate && (
                        <button className="button button--secondary" onClick={() => showDialog(PopupType.Rollback)}>
                          {ti('exalus.rollbackControllerUpdate')}
                        </button>
                      )}
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <EmptyStateBox
          btnText={t('exalus.params.Version.checkForUpdates')}
          content={t('recoveryMode.status.FIRMWARE_IS_UP_TO_DATE')}
          icon={<IconWarning />}
          onClick={checkForControllerUpdates}
        />
      )}
      <DialogConfirmation
        show={popup}
        setShow={setPopup}
        header={ti(`exalus.${popupType?.toLowerCase()}DialogTitle`)}
        content={ti(`exalus.${popupType?.toLowerCase()}DialogContent`)}
        primaryBtnText={ti(`exalus.${popupType?.toLowerCase()}Controller`)}
        secondaryBtnText={tc('buttons.cancel')}
        secondaryBtnAction={() => setPopup(false)}
        primaryBtnAction={handleConfirm}
      />
    </Page>
  );
};

export default ControllerUpdates;
