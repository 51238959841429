import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { useInstallationContext } from '../../../../../context/installation';
import {
  ChannelsDetailsQueryVariables,
  OnOptimizerConfigurationUpdatedPayloadSubscription,
  OnOptimizerConfigurationUpdatedPayloadSubscriptionVariables,
  Query,
} from '../../../../../data-access/gql-types/graphql';
import { CHANNEL_DETAILS } from '../../../../../data-access/queries/channels';
import { ON_OPTIMIZER_CONFIGURATION_CHANGED } from '../../../../../data-access/subscriptions/lavva-devices';
import { ROUTES } from '../../../../../routes';

export const useGetChannelDetails = (channelId: string) => {
  const navigate = useNavigate();
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const { data, loading, refetch } = useQuery<Query, ChannelsDetailsQueryVariables>(CHANNEL_DETAILS, {
    variables: { installationId: selectedInstallationId, channelId: channelId },
    fetchPolicy: 'network-only',
    skip: skipLavvaFetch,
    onCompleted: (data) => {
      if (!data.userChannel) navigate(ROUTES.Root(), { replace: true });
    },
  });
  const { data: optimizerConfigurationData } = useSubscription<
    OnOptimizerConfigurationUpdatedPayloadSubscription,
    OnOptimizerConfigurationUpdatedPayloadSubscriptionVariables
  >(ON_OPTIMIZER_CONFIGURATION_CHANGED, {
    variables: { channelId },
    skip: skipLavvaFetch || !channelId,
  });

  useEffect(() => {
    if (optimizerConfigurationData?.onOptimizerConfigurationUpdatedPayload.channelId === channelId) {
      console.log('OPTIMIZER CONFIGURATION UPDATED');
      setTimeout(() => refetch(), 500);
    }
  }, [optimizerConfigurationData]);

  return {
    data,
    loading,
  };
};
