import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useInstallationContext } from '../../../../../context/installation';
import { CreateGroupMutation, CreateGroupMutationVariables } from '../../../../../data-access/gql-types/graphql';
import { CREATE_GROUP } from '../../../../../data-access/mutations/groups';
import { useBackdropContext } from '../../../../../context/backdrop';
import { useMutationErrors } from '../../../../../hooks';
import { useRefetchData } from '../../../../../hooks/refresh-data';
import { MutationErrorType } from '../../../../../types';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import * as storage from '../../../storage';
import { useFormChannelsContext } from '../context';

export const useCreateGroup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallationContext();
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { channelList } = useDevicesAndChannelsContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Group);
  const { refetchActions, refetchDashboard } = useRefetchData();
  const { selectedChannels, selectedActions, prepareGroupItems } = useFormChannelsContext();
  const [createGroups, { loading: createGroupLoading }] = useMutation<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >(CREATE_GROUP);
  const form = useForm({
    defaultValues: {
      name: '',
      iconName: '',
    },
  });

  const defaultChannels = useMemo(
    () =>
      [...selectedChannels, ...selectedActions].map(
        (item) => channelList.find((channel) => channel && channel.id === item)?.id || '',
      ),
    [],
  );

  const handleChange = () => setIsPublic(!isPublic);

  const handleChangeIcon = (value: string) => form.setValue('iconName', value);

  const onSubmit = form.handleSubmit((values) => {
    turnOnBackdrop();
    const items = prepareGroupItems();

    createGroups({
      variables: {
        input: {
          name: values.name,
          installationId: selectedInstallationId,
          groupItems: items,
          isPublic,
          iconName: values.iconName,
        },
      },
      onCompleted: async (data) => {
        turnOffBackdrop();
        if (!data || !data.createGroup) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.createGroup.idResponse?.id) {
          if (!isPublic) {
            await refetchActions();
            await refetchDashboard();
          }

          storage.setItem('lastActiveGroupId', String(data.createGroup.idResponse.id));
          toastSuccess({ content: t('groupCreated') });
          navigate(-1);
        } else {
          handleErrors(data.createGroup.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  });

  return {
    form,
    isPublic,
    createGroupLoading,
    defaultChannels,
    onSubmit,
    handleChange,
    handleChangeIcon,
  };
};
