import React, { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import c from 'classnames';
import { useInstallationContext } from '../../context/installation';
import './index.scss';

type EmptyStateBoxProps = {
  header?: string;
  content: string;
  btnText?: string;
  className?: string;
  linkTo?: string | { pathname: string; search: string };
  onClick?: () => void;
  icon?: ReactNode;
  showAllways?: boolean;
};

export const EmptyStateBox: React.FC<EmptyStateBoxProps> = ({
  header,
  btnText,
  className,
  content,
  linkTo,
  icon,
  onClick,
  showAllways,
}) => {
  const navigate = useNavigate();
  const { integrationType, installationsLoading } = useInstallationContext();

  const visible = useMemo(() => {
    if (installationsLoading) return false;
    else if (showAllways) return true;
    return !!integrationType;
  }, [installationsLoading, integrationType, showAllways]);

  return (
    <>
      {visible && (
        <div className={c('empty-state-box', className)}>
          {header && (
            <>
              <div className="empty-state-box__header">
                {header}
                {icon && icon}
              </div>
              <hr />
            </>
          )}
          <div className="empty-state-box__content">
            {content} {icon && !header ? icon : null}
          </div>
          {btnText && linkTo && (
            <button onClick={() => navigate(linkTo)} className="button m-t-16">
              {btnText}
            </button>
          )}
          {btnText && onClick && (
            <button onClick={onClick} className="button m-t-16">
              {btnText}
            </button>
          )}
        </div>
      )}
    </>
  );
};
