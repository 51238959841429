import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DialogConfirmation } from '../../../../../../../../components';
import { useInstallationContext } from '../../../../../../../../context/installation';
import { UserPermissionType } from '../../../../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../../../../routes';

type InstallationLocationDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const InstallationLocationDialog: React.FC<InstallationLocationDialogProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallationContext();
  const { permissions } = useUserPermissions();
  const canChangeGeolocation = permissions[UserPermissionType.ChangeGeolocation];

  const onClose = () => setOpen(false);

  const handleConfirm = () => navigate(ROUTES.InstallationSettings(selectedInstallationId));

  return (
    <DialogConfirmation
      show={open}
      setShow={onClose}
      header={t('trigger.installationLocation.header')}
      content={t(`trigger.installationLocation.content${!canChangeGeolocation ? 'Contact' : ''}`)}
      primaryBtnText={canChangeGeolocation ? t('trigger.installationLocation.button') : tc('buttons.understand')}
      primaryBtnAction={canChangeGeolocation ? handleConfirm : onClose}
      {...(canChangeGeolocation ? { secondaryBtnText: tc('buttons.cancel'), secondaryBtnAction: onClose } : {})}
      direction="column"
    />
  );
};
