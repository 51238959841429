import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, uniq } from 'lodash';
import { ChannelTypeInternal, GateModeInternal } from '../../../../data-access/gql-types/graphql';
import { isWisniowski } from '../../../../utils/helpers/wisniowski';
import { ChannelGateType, ChannelType, DevicesAndChannelsState, UseDevicesAndChannels, Visibility } from '../../types';
import { DevicesAndChannelsContext } from '../../context/devices-and-channels';

type UseDevicesAndChannelsProps = {
  channelId?: string;
  deviceId?: string;
  visibility?: Visibility;
  channelTypes?: ChannelTypeInternal[];
};

const sortedChannelTypes = [
  ChannelTypeInternal.Blind,
  ChannelTypeInternal.Switch,
  ChannelTypeInternal.Gate,
  ChannelTypeInternal.Meter,
  ChannelTypeInternal.Light,
  ChannelTypeInternal.Optimizer,
];

const wisniowskiSortedChannelTypes = [
  ChannelTypeInternal.Gate,
  ChannelTypeInternal.Blind,
  ChannelTypeInternal.Switch,
  ChannelTypeInternal.Meter,
  ChannelTypeInternal.Light,
  ChannelTypeInternal.Optimizer,
];

export const useDevicesAndChannels = ({
  channelId,
  deviceId,
  visibility = Visibility.Visible,
  channelTypes = [
    ChannelTypeInternal.Switch,
    ChannelTypeInternal.Blind,
    ChannelTypeInternal.Meter,
    ChannelTypeInternal.Light,
    ChannelTypeInternal.Optimizer,
    ChannelTypeInternal.Gate,
  ],
}: UseDevicesAndChannelsProps = {}): UseDevicesAndChannels => {
  const context = useContext(DevicesAndChannelsContext);
  const { t: tc } = useTranslation('common');
  const { channelList, deviceList, setDeviceList, updateDeviceDetails } = context;
  const sortedArray = !isWisniowski ? sortedChannelTypes : wisniowskiSortedChannelTypes;

  const channel = useMemo(() => {
    if (channelId) {
      return channelList.find((channel: ChannelType) => channel.id === channelId);
    }
  }, [channelId, channelList]);

  const device = useMemo(() => {
    if (deviceId) {
      return deviceList.find((device) => device.id === deviceId);
    }
  }, [deviceId, deviceList]);

  const editDeviceName = useCallback(
    (deviceId: string, name: string) => {
      setDeviceList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((d) => d?.id === deviceId);
        if (index !== -1) tempList[index].payload.name = name;

        return tempList;
      });

      updateDeviceDetails((prev) => {
        const temp = cloneDeep(prev);
        if (temp) temp.payload.name = name;
        return temp;
      });
    },
    [deviceList],
  );

  const deviceChannels = useMemo(() => {
    return (channelList || []).filter((channel) => channel?.deviceId === deviceId && channel) as ChannelType[];
  }, [channelList, deviceId]);

  const deviceChannelsById = useCallback(
    (id: string) => {
      return (channelList || []).filter((channel) => channel?.deviceId === id && channel) as ChannelType[];
    },
    [channelList],
  );

  const channelTypesList: string[] = useMemo(() => {
    if (channelList) {
      return uniq(
        channelList
          .filter(
            (x) =>
              x.data.type !== ChannelTypeInternal.Gate ||
              (x.data.type === ChannelTypeInternal.Gate &&
                (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp),
          )
          .filter((channel) => channel && channel.data.type !== undefined)
          .filter((channel) => channelTypes?.includes(channel.data.type))
          .map((x) => x?.data.type as string),
      );
    }

    return [];
  }, [channelList, sortedArray]);

  const visibilityCondition = useCallback(
    (channel: ChannelType) => {
      switch (visibility) {
        case Visibility.Visible:
          return channel.isVisible;
        case Visibility.InVisible:
          return !channel.isVisible;
        default:
          return channel;
      }
    },
    [visibility],
  );

  const getGroupsList = useCallback(() => {
    return sortedArray
      .sort((a: string, b: string) => sortedArray.findIndex((f) => f === a) - sortedArray.findIndex((f) => f === b))
      .map((type) => ({
        label: tc(`typesPlural.${type}`),
        type: type as ChannelTypeInternal,
        channels: channelList.filter((channel) => {
          return channel.data.type === type && visibilityCondition(channel);
        }) as ChannelType[],
      }))
      .filter((x) => x.channels.length > 0);
  }, [sortedArray, channelList, visibility]);

  const channelGroups = useMemo(() => getGroupsList(), [sortedArray, visibility, channelList]);

  const contextValue = useMemo((): DevicesAndChannelsState => context, Object.values(context));

  return {
    ...contextValue,
    channel,
    device,
    deviceChannels,
    deviceChannelsById,
    channelGroups,
    channelTypesList,
    editDeviceName,
  };
};
