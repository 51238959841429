import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useInstallationContext } from '../../../../../context/installation';
import { DeviceDetailsQueryVariables, Query } from '../../../../../data-access/gql-types/graphql';
import { DEVICE_DETAILS } from '../../../../../data-access/queries/devices';
import { ROUTES } from '../../../../../routes';

export const useGetDeviceDetails = (deviceId: string) => {
  const navigate = useNavigate();
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const { data, loading, refetch } = useQuery<Query, DeviceDetailsQueryVariables>(DEVICE_DETAILS, {
    variables: { installationId: selectedInstallationId, deviceId: deviceId },
    fetchPolicy: 'network-only',
    skip: skipLavvaFetch,
    onCompleted: (data) => {
      if (!data.userDevice) navigate(ROUTES.Root(), { replace: true });
    },
  });

  return {
    data,
    loading,
    refetch,
  };
};
