import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconDelay } from '../../../../../../components';
import { TriggerActionRequestInput } from '../../../../../../data-access/gql-types/graphql';
import { DelayDialog } from '../../condition-types/time-condition/components';
import { useTriggerFormContext } from '../../context';

type DelayItemProps = {
  action: TriggerActionRequestInput;
  showDialog?: boolean;
};

export const DelayItem: React.FC<DelayItemProps> = ({ action, showDialog = false }) => {
  const { t } = useTranslation('action');
  const [showDelayDialog, setShowDelayDialog] = useState<boolean>(false);
  const { updateAction } = useTriggerFormContext();

  if (!action) return null;

  return (
    <>
      <div
        className="device-item"
        onClick={() => {
          if (showDialog) {
            setShowDelayDialog(true);
          }
        }}
      >
        <h4 className="device-item__header">
          <div className="icon-box box">
            <IconDelay />
          </div>
          <div className="device-item__content-wrapper">
            <span className="device-item__name">{t('trigger.delay')}</span>
            <span className="device-item__details">
              {action.integerValue} {t('trigger.delayUnitShort')}
            </span>
          </div>
        </h4>
      </div>

      <DelayDialog
        open={showDelayDialog}
        setOpen={setShowDelayDialog}
        title={t('trigger.delay')}
        onSave={(value) => {
          updateAction(action.id, {
            ...action,
            integerValue: value,
          });
        }}
        time={action.integerValue || 1}
      />
    </>
  );
};
