import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateBox, Page } from '../../../../components';
import { UserGroup, UserPermissionType } from '../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../hooks/user-permissions';
import { ROUTES } from '../../../../routes';
import { useApi } from '../../hooks';
import { GroupSortBody, GroupSortHeader } from './components';

const GroupSort: React.FC = () => {
  const { t } = useTranslation('groups');
  const { groups } = useApi();
  const [sortableGroups, setSortableGroups] = useState<UserGroup[]>([]);
  const { permissions } = useUserPermissions();

  useEffect(() => setSortableGroups(groups), [groups.length]);

  return (
    <Page isStickyHeader header={<GroupSortHeader />}>
      {sortableGroups.length ? (
        <GroupSortBody sortableGroups={sortableGroups} setSortableGroups={setSortableGroups} />
      ) : (
        <EmptyStateBox
          header={t('groups')}
          content={t('descriptionEmptyList')}
          {...(permissions[UserPermissionType.GroupCreate]
            ? { btnText: t('create'), linkTo: ROUTES.GroupCreate() }
            : {})}
        />
      )}
    </Page>
  );
};

export default GroupSort;
