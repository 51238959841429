import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, dragOverlayStyle } from '../../../../../components';
import { DashboardItemType } from '../../../../../data-access/gql-types/graphql';
import { ActionBox, ActionBoxSortable, ChannelBox, ChannelBoxSortable } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { ChannelType } from '../../../types';
import { GroupSortable, UserGroupItemSortable } from '../types';

type GroupsSortChannelsBodyProps = {
  activeGroup: GroupSortable;
  setActiveGroup: (group: GroupSortable) => void;
  setIsValid: (value: boolean) => void;
};

export const GroupsSortChannelsBody: React.FC<GroupsSortChannelsBodyProps> = ({
  setActiveGroup,
  activeGroup,
  setIsValid,
}) => {
  const isListView = new URLSearchParams(location.search).get('is-list-view') === 'true';
  const { channelList } = useDevicesAndChannelsContext();
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);

  const onDragStart = (event: dndCore.DragStartEvent) => setDndActiveId(event.active.id);

  const onDragEnd = (event: dndCore.DragEndEvent) => {
    if (!event.active || !event.over) return;

    if (event.active.id !== event.over.id && activeGroup?.items) {
      const newList = Array.from(activeGroup.items);
      const oldIndex = newList.findIndex((el) => el.id === event.active.id);
      const newIndex = newList.findIndex((el) => el.id === event?.over?.id);
      const sortedItems = dndSortable.arrayMove(newList, oldIndex, newIndex);

      setActiveGroup({ ...activeGroup, items: sortedItems });
    }

    setDndActiveId(null);
    setIsValid(true);
  };

  const activeOverlayItem = useMemo(
    () => activeGroup?.items?.find((el: UserGroupItemSortable) => el.id === dndActiveId),
    [activeGroup, dndActiveId],
  );

  const channel = useMemo(
    () => channelList.find((channel) => channel.id === dndActiveId) as ChannelType,
    [channelList, dndActiveId],
  );

  return (
    <div className={classNames('grid-list-16', { 'grid grid--2 grid--small-gap': !isListView })}>
      {activeGroup.items && (
        <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={activeGroup.items}>
          {activeGroup.items?.map((item: UserGroupItemSortable) =>
            item.resourceType === DashboardItemType.Channel ? (
              <ChannelBoxSortable key={item.id} channelId={item.id || ''} isListItem={isListView} />
            ) : (
              <ActionBoxSortable key={item.id} actionId={item.id || ''} isListItem={isListView} />
            ),
          )}
          <dndCore.DragOverlay>
            {dndActiveId && (
              <>
                {activeOverlayItem?.resourceType === DashboardItemType.Channel ? (
                  <ChannelBox style={dragOverlayStyle} channel={channel} isListItem={isListView} isDraggable />
                ) : (
                  <ActionBox
                    style={dragOverlayStyle}
                    actionId={dndActiveId || ''}
                    isListItem={isListView}
                    isDraggable
                  />
                )}
              </>
            )}
          </dndCore.DragOverlay>
        </DragAndDrop>
      )}
    </div>
  );
};
