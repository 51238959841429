import React, { Dispatch, SetStateAction, createContext, useState } from 'react';
import { ChildrenProps } from '../types';
import { usePushNotifications } from '../hooks';
import { useVibrationManager } from '../hooks/native/vibration-manager';

const initialState: UseNativeState = {
  showBluetoothNotSupportedDialog: false,
  setShowBluetoothNotSupportedDialog: () => null,
  setVibration: () => null,
  fireVibration: () => null,
  checkForVibrationSupported: () => null,
  vibrationSupported: false,
  registerTokenForWeb: async () => console.log(),
};

export const NativeContext = createContext(initialState);

export type UseNativeState = {
  showBluetoothNotSupportedDialog: boolean;
  setShowBluetoothNotSupportedDialog: Dispatch<SetStateAction<boolean>>;
  setVibration: (value: boolean) => void;
  fireVibration: () => void;
  checkForVibrationSupported: () => void;
  vibrationSupported: boolean;
  registerTokenForWeb: () => Promise<void>;
};

const NativeProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [showBluetoothNotSupportedDialog, setShowBluetoothNotSupportedDialog] = useState<boolean>(false);
  const { fireVibration, setVibration, checkForVibrationSupported, vibrationSupported } = useVibrationManager();
  const { registerTokenForWeb } = usePushNotifications();

  const values = {
    showBluetoothNotSupportedDialog,
    setShowBluetoothNotSupportedDialog,
    fireVibration,
    setVibration,
    checkForVibrationSupported,
    vibrationSupported,
    registerTokenForWeb,
  };

  return <NativeContext.Provider value={values}>{children}</NativeContext.Provider>;
};

export default NativeProvider;
