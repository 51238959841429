import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  ComfortPositionParameters,
  InputOutputBlindParameters,
  StateOfTurnOnBlindDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { Srp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp22ConfigService';
import { Srp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp30ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { ROUTES } from '../../../../../../routes';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import AutoCalibration from '../common/auto-calibration/button';
import ComfortForm from './comfort';
import InputsMode from './inputs-mode';
import TurnOnBehaviourForm from './turn-behaviour';
import { initialConfig, SrpConfigParams, SrpParamsEnum } from './types';

type SrpConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const SrpConfigForm: React.FC<SrpConfigFormProps> = ({ device, channel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<SrpConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    configService: serviceName,
    handleGetBlindParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: SrpParamsEnum) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISrp30ConfigService>(
      Srp30ConfigService.ServiceName,
    );

    switch (panel) {
      case SrpParamsEnum.COMFORT_POSITION: {
        if (channel) {
          const data = await configService.GetComfortPositionsAsync(device, channel.Number);

          handleGetBlindParamsResponse<ComfortPositionParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as ComfortPositionParameters });
            setOpen(true);
          });
        }
        break;
      }
      case SrpParamsEnum.INPUTS_MODE: {
        if (channel) {
          const data = await configService.GetBlindInputOutputParamAsync(device, channel.Number);

          handleGetBlindParamsResponse<InputOutputBlindParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as InputOutputBlindParameters });
            setOpen(true);
          });
        }
        break;
      }
      case SrpParamsEnum.TURN_ON_BEHAVIOUR: {
        if (channel) {
          const data = await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetBlindParamsResponse<StateOfTurnOnBlindDeviceParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as StateOfTurnOnBlindDeviceParameters });
            setOpen(true);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const goToManualCalibration = () => {
    if (channel) {
      navigate(ROUTES.Srp22Calibration(channel.ChannelId));
    }
  };

  const handleOpen = (panel: SrpParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case SrpParamsEnum.COMFORT_POSITION: {
        return channel ? <ComfortForm device={device} comfort={config[activeConfigName]} channel={channel} /> : null;
      }
      case SrpParamsEnum.INPUTS_MODE: {
        return <InputsMode device={device} inputs={config[activeConfigName]} />;
      }
      case SrpParamsEnum.TURN_ON_BEHAVIOUR: {
        return <TurnOnBehaviourForm device={device} turnOnBehaviour={config[activeConfigName]} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              {serviceName === Srp30ConfigService.ServiceName && <AutoCalibration channel={channel} />}
              {serviceName === Srp22ConfigService.ServiceName && (
                <ArrowButton title={t('exalus.calibration')} onClick={goToManualCalibration} className="m-b-24" />
              )}
              <ArrowButton
                {...(serviceName === Srp30ConfigService.ServiceName || serviceName === Srp22ConfigService.ServiceName
                  ? { className: 'm-b-24' }
                  : {})}
                title={t('exalus.params.SrpParams.ComfortPosition')}
                onClick={() => handleOpen(SrpParamsEnum.COMFORT_POSITION)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SrpParams.InputsMode')}
                onClick={() => handleOpen(SrpParamsEnum.INPUTS_MODE)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SrpParams.TurnOnBehaviour')}
                onClick={() => handleOpen(SrpParamsEnum.TURN_ON_BEHAVIOUR)}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SrpConfigForm;
