import React, { createContext, useContext, useMemo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { ChildrenProps } from '../../../../../types';
import { LocationCoords, LocationViews } from '../../../types';

export type StateType = {
  coords: LocationCoords;
  currentLocation: LocationCoords;
  center: GoogleMapReact.Coords;
  zoom: number;
  currPage: string;
  setCoords: React.Dispatch<React.SetStateAction<LocationCoords>>;
  setCurrentLocation: React.Dispatch<React.SetStateAction<LocationCoords>>;
  setCenter: React.Dispatch<React.SetStateAction<GoogleMapReact.Coords>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  goTo: (route: string) => void;
  goBack: () => void;
  goHome: () => void;
};

const LocationContext = createContext<StateType | undefined>(undefined);

export const useLocationContext = (): StateType => useContext(LocationContext) as StateType;

export const DialogContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [locationRouter, updateLocationRouter] = useState<string[]>([LocationViews.BUTTON]);
  const [coords, setCoords] = useState<LocationCoords>({ latitude: null, longitude: null });
  const [currentLocation, setCurrentLocation] = useState<LocationCoords>({ latitude: null, longitude: null });
  const [center, setCenter] = useState<GoogleMapReact.Coords>({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState<number>(5);

  const currPage = useMemo(() => {
    return locationRouter[locationRouter.length - 1];
  }, [locationRouter]);

  const goTo = (route: string) => {
    updateLocationRouter([...locationRouter, route]);
  };

  const goBack = () => {
    const updated = [...locationRouter];
    updated.splice(-1, 1);
    updateLocationRouter(updated);
  };

  const goHome = () => {
    updateLocationRouter([LocationViews.BUTTON]);
  };

  const values = {
    coords,
    currentLocation,
    center,
    zoom,
    currPage,
    setCoords,
    setCurrentLocation,
    setCenter,
    setZoom,
    goTo,
    goBack,
    goHome,
  };

  return <LocationContext.Provider value={{ ...values }}>{children}</LocationContext.Provider>;
};
