import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { EmptyStateBox, Header, IconWarning, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { ActionAdvancedIcon } from '../../../../../../../components/advanced-icon';
import RadiosGroup from '../../../../../../../components/radios-group';
import { ActionAdvancedIconTypeEnum } from '../../../../../../../types';
import { toastError } from '../../../../../../../utils/toast';
import { useActions } from '../../../../action-list/hooks/use-actions';
import { useExalusCreateActionContext } from '../../../context';
import { useConditionEdit } from '../../../hooks/use-condition-edit';

const ExalusScenarioSource: React.FC = () => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { builder } = useExalusCreateActionContext();
  const { actions, isFetched } = useActions();
  const [selectedSeqId, setSelectedSeqId] = useState<string>('');
  const { seq, finalizeSequenceSubmit } = useConditionEdit({
    supportedParam: SupportedArgumentTypes.ArgumentAsSceneExecuted,
  });

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsConditionInfo;

    if (argument) {
      const found = actions?.find((x) => x.DeviceGuid === argument.DeviceGuid);

      if (found) setSelectedSeqId(found.Guid);
    }
  }, [seq]);

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSelectedSeqId(target.value);
  };

  const sequencesOptions = useMemo(
    () =>
      (actions || []).map((action) => ({
        value: action.Guid,
        label: action.Name,
        icon: <ActionAdvancedIcon iconName={action.Icon || ActionAdvancedIconTypeEnum.DEFAULT} />,
      })),
    [actions],
  );

  const handleSubmit = () => {
    try {
      const seq = actions?.find((x) => x.Guid === selectedSeqId);

      if (seq) {
        const argument = builder?.ArgumentAsSceneExecuted(seq);
        finalizeSequenceSubmit(argument, -3);
      } else {
        toastError({ content: tc('errors.somethingWentWrong') });
      }
    } catch (error) {
      toastError({ content: tc('errors.somethingWentWrong') });
      console.log(error);
    }
  };

  return (
    <Page
      isStickyHeader
      className="schedule-source"
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.conditions.sources.scenario.selectScenario')} />
        </>
      }
    >
      {actions?.length ? (
        <RadiosGroup
          options={sequencesOptions}
          value={selectedSeqId}
          onChange={onChange}
          textEllipsis
          withImage
          accent
        />
      ) : (
        <>
          {isFetched && (
            <EmptyStateBox
              header={t('action.create.conditions.sources.scenario.emptyList.header')}
              content={t('action.create.conditions.sources.scenario.emptyList.content')}
              icon={<IconWarning />}
            />
          )}
        </>
      )}
      <SubmitButton onClick={handleSubmit} />
    </Page>
  );
};

export default ExalusScenarioSource;
