import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { INetworkService } from 'lavva.exalushome/build/js/INetworkService';
import { useBackdropContext } from '../../../../../context/backdrop';
import { toastSuccess } from '../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../hooks';

export const useAddDns = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const [dns, setDns] = useState<string>('');
  const { handleError } = useHandleDataFrameErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    turnOnBackdrop();
    const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
    const result = await networkService.AddDnsServerAsync(dns);

    if (result === Status.OK) {
      toastSuccess({ content: t('exalusNetwork.dnsAdded') });
      navigate(-1);
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  };

  const handleChange = (ipAddress: string) => setDns(ipAddress);

  return {
    handleSubmit,
    handleChange,
  };
};
