import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StatusScreen } from '../../components';
import { useInstallationContext } from '../../context/installation';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { ROUTES } from '../../routes';
import { StatusTypeEnum } from '../../types';
import { useExalusContext } from '../../integrations/exalus/context';
import { getExalusInstallationCredentials } from '../../integrations/exalus/utils';
import { useAddDevice } from '../../integrations/lavva/modules/device-add/hooks/use-add-device';
import './index.scss';

const InstallationCreated: React.FC = () => {
  const { t } = useTranslation('installation');
  const { selectedInstallation, integrationType } = useInstallationContext();
  const { setSelectedExalusCredentials } = useExalusContext();
  const navigate = useNavigate();
  const { addDevice } = useAddDevice();

  const goToDashboard = () => {
    if (integrationType === IntegrationType.Exalus) {
      const exalusCredentials = getExalusInstallationCredentials(selectedInstallation?.installationId);
      setSelectedExalusCredentials(exalusCredentials);
    }

    navigate(ROUTES.Root());
  };

  const handleAddDevice = () => {
    if (integrationType === IntegrationType.Exalus) navigate(ROUTES.AddExalusDevices());
    else addDevice();
  };

  return (
    <StatusScreen
      header={t('exalus.createdStatusScreen.header')}
      subTitle={t('exalus.createdStatusScreen.subheader')}
      type={StatusTypeEnum.INSTALLATION_CREATED}
      secondaryBackground
      primaryBtnText={t('exalus.createdStatusScreen.addDevices')}
      onPrimaryBtnClick={handleAddDevice}
      secondaryBtnText={t('exalus.createdStatusScreen.skipConfiguration')}
      onSecondaryBtnClick={goToDashboard}
    />
  );
};

export default InstallationCreated;
