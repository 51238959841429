import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Checkbox, DialogConfirmation, EmptyStateBox, Header, NavHeader, Page } from '../../../../../../../components';
import TextInfo from '../../../../../../../components/text-info';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { useExalusContext } from '../../../../../context';
import { useExalusDevice } from '../../../../../hooks';
import { useUpdates } from '../../../../controller-settings/hooks/use-updates';
import './index.scss';

const DeviceUpdate: React.FC = () => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [check, toggleCheck] = useState<boolean>(false);
  const { deviceId } = useParams<{ deviceId?: string }>();
  const { data: device } = useExalusDevice((deviceId || '').split('_')[0]);
  const {
    checkForDeviceUpdates,
    updateStart,
    deviceUpdate,
    feedback,
    retransmitterPopup,
    loading,
    setRetransmitterPopup,
  } = useUpdates(device);
  const { synchronized } = useExalusContext();
  usePreventBack(loading ? t('exalus.params.Version.noBackMessage') : '');

  useEffect(() => {
    if (synchronized) checkForDeviceUpdates();
  }, []);

  const handleCheck = () => toggleCheck(!check);

  const updateEnabled = useMemo(() => {
    if (!deviceUpdate) return false;
    if (deviceUpdate.IsUpdateSafe) return true;
    else {
      return check;
    }
  }, [deviceUpdate, check]);

  return (
    <Page
      className="updates-device"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.params.Version.update')} />
        </>
      }
    >
      {deviceUpdate ? (
        <div className="m-t-24">
          <h3 className="m-b-24">{t('exalus.params.Version.updatesFound')}</h3>
          <TextInfo
            value={deviceUpdate?.UpdatedResourceName || '-'}
            label={t('exalus.params.Version.updatedResourceName')}
            className="m-b-8"
          />
          <TextInfo value={deviceUpdate?.Protocol || '-'} label={t('exalus.params.Version.protocol')} />
          <TextInfo
            value={deviceUpdate?.CurrentResourceVersion || '-'}
            label={t('exalus.params.Version.currentVersion')}
            className="m-b-8"
          />
          <TextInfo
            value={deviceUpdate?.NewResourceVersion || '-'}
            label={t('exalus.params.Version.newVersion')}
            className="m-b-8"
          />
          {!deviceUpdate.IsUpdateSafe && (
            <>
              <p className="safe-info">{t('exalus.params.Version.safeUpdateInfo')}</p>
              <Checkbox checked={check} onChange={handleCheck} reverse>
                {t('exalus.params.Version.safeConfirmation')}
              </Checkbox>
            </>
          )}
          <button className="button" onClick={() => updateStart(false)} disabled={!updateEnabled}>
            {t('exalus.params.Version.updateStart')}
          </button>
        </div>
      ) : (
        <>
          {feedback && (
            <EmptyStateBox
              btnText={t('exalus.params.Version.checkForUpdates')}
              content={feedback.message}
              icon={feedback.icon}
              onClick={checkForDeviceUpdates}
            />
          )}
        </>
      )}
      <DialogConfirmation
        show={retransmitterPopup}
        setShow={setRetransmitterPopup}
        header={t('exalus.updateResult.RetransmitterFound.header')}
        content={t('exalus.updateResult.RetransmitterFound.content')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={() => updateStart(true)}
        secondaryBtnAction={() => setRetransmitterPopup(false)}
      />
    </Page>
  );
};

export default DeviceUpdate;
