import React, { ReactNode } from 'react';
import c from 'classnames';
import classNames from 'classnames';
import { useDevicesAndChannels } from '../../../hooks';
import { useChannel } from '../hooks/use-channel';
import { ChannelIcon } from '../icon';
import './index.scss';

type ChannelItemProps = {
  id?: string;
  isOn: boolean;
  showIsHidden?: boolean;
  details?: ReactNode;
};

export const ChannelItem: React.FC<ChannelItemProps> = ({ id, isOn, showIsHidden, details }) => {
  const { channel } = useDevicesAndChannels({ channelId: id });
  const { additionalInfo } = useChannel({ channel, details: true });

  if (!channel) return null;

  return (
    <div className={c('device-item', { 'device-item--show-hidden': showIsHidden })}>
      <h4 className={classNames('device-item__header', { invisible: !channel.isVisible })}>
        <div className="icon-box box">
          <ChannelIcon channel={channel} statusOn={isOn} />
          {additionalInfo}
        </div>
        <div className="device-item__content-wrapper">
          <span className="device-item__name">{channel.alias || ''}</span>
          {details && <span className="device-item__details">{details}</span>}
        </div>
      </h4>
    </div>
  );
};
