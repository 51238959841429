import React from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { AggregateVectorParameterTypeInternal } from '../../../../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../../../../types';
import { aggregatedParameters } from '../../../../../measurements/utils';
import { ChartDataValues, CostEnum } from '../../../types';
import { prepareTooltipPayload } from '../../../utils';
import { formatMeasurementWithUnit } from '../../../utils/helpers';
import './index.scss';

type CustomTooltipProps = TooltipProps<ValueType, NameType> & {
  activeMeasurementRange: MeasurementRange;
  itemPrefix: string;
};

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  activeMeasurementRange,
  itemPrefix,
}) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');

  const getActiveEnergy = (parameters: ChartDataValues[]) => {
    return parameters.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy);
  };

  const formatValue = (el: ChartDataValues, parameters: ChartDataValues[], showPrice: boolean) => {
    const paramConfig = aggregatedParameters[el.type];
    if (!paramConfig) return null;

    if (el.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
      const activeEnergy = getActiveEnergy(parameters);

      if (activeEnergy !== undefined) {
        if (!activeEnergy.empty) {
          return `${formatMeasurementWithUnit(el.value * activeEnergy.value, paramConfig)} ${
            showPrice ? `(${formatMeasurementWithUnit(el.value, paramConfig)}/kWh)` : ''
          }`;
        } else {
          const predictionEnergy = parameters.find(
            (x) => x.type === AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
          );

          if (predictionEnergy !== undefined) {
            return `${formatMeasurementWithUnit(el.value * predictionEnergy.value, paramConfig)} ${
              showPrice ? `(${formatMeasurementWithUnit(el.value, paramConfig)}/kWh)` : ''
            } `;
          }
        }
      }

      return `${formatMeasurementWithUnit(el.value, paramConfig)}/kWh`;
    }

    return formatMeasurementWithUnit(el.value, paramConfig);
  };

  const getLabel = (el: ChartDataValues, parameters: ChartDataValues[]) => {
    if (el.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
      const activeEnergy = getActiveEnergy(parameters);
      const labelKey = activeEnergy?.empty ? `energyMeasurements.types.summary.${CostEnum.PredictedCost}` : 'price';

      return <span style={{ color: el.color }}>{`${t(labelKey)}: `}</span>;
    }

    let sectionLabel: string = el.type;

    if (
      activeMeasurementRange === MeasurementRange.DayInHours &&
      [
        AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
        AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
      ].includes(el.type)
    ) {
      sectionLabel = 'VECTOR_BALANCED_ACTIVE_ENERGY';
    }

    return <span style={{ color: el.color }}>{`${t(`energyMeasurements.types.summary.${sectionLabel}`)}: `}</span>;
  };

  if (active && payload?.length) {
    const values = prepareTooltipPayload(payload[0].payload.values);

    return (
      <div className="custom-tooltip">
        {payload[0].payload?.tooltipTime && (
          <div style={{ textAlign: 'center', width: '100%' }}>{payload[0].payload.tooltipTime}</div>
        )}
        {Object.entries(groupBy(values, 'index')).map(([key, group]) => (
          <div key={key} className="m-b-8">
            {key !== '0' && <span>{`${itemPrefix} ${key}`}</span>}
            {group.map((el) => (
              <div key={el.type} className="tooltip__element">
                <span style={{ color: el.color }}>{getLabel(el, group)}</span>
                <span>{!el.empty ? formatValue(el, group, key === '0') : tc('noData')}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return null;
};
