import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ControlWrapper } from '../../../../../../../components';
import { useGate } from '../../../../../hooks';
import { ChannelType } from '../../../../../types';
import { ChannelGateType } from '../../../../../types/channel/gate';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';
import { AdditionalModes } from '../additional-modes';
import { GateKindsControl } from './kinds';

type GateStepByStepProps = {
  channel: ChannelType;
};

export const GateStepByStep: React.FC<GateStepByStepProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [quickControl, setQuickControl] = useState<boolean>(false);
  const { handleStepByStep, handleQuickControl } = useGate(channel);
  const { stepByStepSupported } = useGateSupportedFeatures(channel);

  useEffect(() => {
    if (channel) setQuickControl((channel.data as ChannelGateType).isQuickControl);
  }, [(channel?.data as ChannelGateType)?.isQuickControl]);

  const gateKind = useMemo(
    () => (channel.data as ChannelGateType).gateKind,
    [(channel.data as ChannelGateType).gateKind],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuickControl(e.target.checked);
    handleQuickControl(e.target.checked);
  };

  const handleAction = () => handleStepByStep();

  return (
    <>
      <ControlWrapper>
        <div>
          {stepByStepSupported && (
            <GateKindsControl gateKind={gateKind} channel={channel} handleAction={handleAction} />
          )}
          <AdditionalModes channel={channel} />
        </div>
      </ControlWrapper>
      <div className="p-l-24 p-r-24 m-t-24">
        <Checkbox checked={quickControl} onChange={handleChange} reverse>
          <p style={{ fontSize: 16, marginLeft: 12 }}>{t('quickControl')}</p>
        </Checkbox>
      </div>
    </>
  );
};
