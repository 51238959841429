import { gql } from '@apollo/client';

export const ALL_INVITATIONS = gql`
  query AllInvitations(
    $includeCreatedByMe: Boolean!
    $includeInvitedMe: Boolean!
    $installationId: UUID!
    $createdByUserId: UUID!
  ) {
    allInvitations(
      includeCreatedByMe: $includeCreatedByMe
      includeInvitedMe: $includeInvitedMe
      where: { installationId: { eq: $installationId }, createdByUserId: { eq: $createdByUserId } }
    ) {
      id
      installationId
      invitedUserEmail
      createdByUserId
      invitedUserId
      status
      createdAt
      sharingPayload {
        wholeInstallationShare
        accessType
        sharedItems {
          id
          name
          itemType
          grantedUses
        }
      }
    }
  }
`;
