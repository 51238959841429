import {
  UserAction,
  UserGroup,
  MeasurementMap,
  LearningScenarios,
  SupplyReturnBehaviorInternal,
  PublishStatus as PublishStatusGraphQL,
  ChannelError,
  ActionError,
  DeviceError,
  GroupError,
  InstallationError,
  InvitationError,
  UserProfileError,
} from '../data-access/gql-types/graphql';

export type EnvironmentType = {
  ENV: string | undefined;
  PUBLIC_URL: string | undefined;
  VERSION: string | undefined;
  API_URL: string | undefined;
  KEYCLOAK_CHANGE_PASSWORD_URL: string | undefined;
  KEYCLOAK_URL: string | undefined;
  KEYCLOAK_CLIENT_ID: string | undefined;
  KEYCLOAK_REALM: string | undefined;
  UPLOAD_PHOTO: string | undefined;
  API_LAVVA_MANAGER: string | undefined;
  API_LAVVA_MANAGEMENT: string | undefined;
  MAPS_API_KEY: string;
  INTEGRATION_DEFAULT: IntegrationDefaultEnum;
  NATIVE_APP_ID: string;
  DISCOVERY_URL: string;
  SENTRY_DSN: string;
  BUILD_ID: string;
  VAPID_KEY: string;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
};

export type ApiState = {
  channelsLoading: boolean;
  devicesLoading: boolean;
  groups: UserGroup[];
  dashboardLoading: boolean;
  favouriteGroup: UserGroup | undefined;
  userActions: UserAction[];
  measurementMap: MeasurementMap | null;
  convertNumberToMeasurement: any;
  convertMeasurementToNumber: any;
};

export enum AvailableLanguage {
  EN = 'en',
  PL = 'pl',
  DE = 'de',
  FR = 'fr',
  LT = 'lt',
  ES = 'es',
  IT = 'it',
  HU = 'hu',
  RO = 'ro',
  NO = 'no',
  FI = 'fi',
  SV = 'sv',
  NL = 'nl',
  PT = 'pt',
  CZ = 'cz',
}

export enum StatusTypeEnum {
  NO_INSTALLATION = 'NO_INSTALLATION',
  INTEGRATION = 'INTEGRATION',
  SENT = 'SENT',
  JOIN = 'JOIN',
  WAIT = 'WAIT',
  UPDATE = 'UPDATE',
  SHARE = 'SHARE',
  INTEGRATION_CONTROLLER = 'INTEGRATION_CONTROLLER',
  INSTALLATION_CREATED = 'INSTALLATION_CREATED',
  MIGRATION = 'MIGRATION',
}

export enum PublishStatus {
  Ok,
  DeviceOrChannelNotFound,
  DeviceDisconnected,
  ContractNotSupported,
}

export const publishStatusMap = {
  [PublishStatusGraphQL.Ok]: PublishStatus.Ok,
  [PublishStatusGraphQL.DeviceOrChannelNotFound]: PublishStatus.DeviceOrChannelNotFound,
  [PublishStatusGraphQL.DeviceDisconnected]: PublishStatus.DeviceDisconnected,
  [PublishStatusGraphQL.ContractNotSupported]: PublishStatus.ContractNotSupported,
};

export const tipKey = {
  0: LearningScenarios.AddCentralAction,
  1: LearningScenarios.AddAction,
  2: LearningScenarios.AddAutomation,
};

export const tipTranslation = {
  0: 'CentralAddTip',
  1: 'ActionAddTip',
  2: 'AutomationAddTip',
};

export enum SupplyReturnBehavior {
  AlwaysOff = 0,
  AlwaysOn = 1,
  KeepState = 2,
}

export const convertedReturnBehavior = {
  [SupplyReturnBehaviorInternal.AlwaysOff]: SupplyReturnBehavior.AlwaysOff,
  [SupplyReturnBehaviorInternal.AlwaysOn]: SupplyReturnBehavior.AlwaysOn,
  [SupplyReturnBehaviorInternal.KeepState]: SupplyReturnBehavior.KeepState,
};

export enum IntegrationDefaultEnum {
  Exalus = 'EXALUS',
  Lavva = 'LAVVA',
  Wisniowski = 'WISNIOWSKI',
}

export enum EnvironmentName {
  Localhost = 'LOCALHOST',
  Dev = 'DEV',
  Stage = 'STAGE',
  Prod = 'PROD',
}

export type ErrorTypeMapping = {
  Channel: ChannelError;
  Action: ActionError;
  Device: DeviceError;
  Group: GroupError;
  Installation: InstallationError;
  Invitation: InvitationError;
  UserProfile: UserProfileError;
};

export enum MutationErrorType {
  Action = 'Action',
  Channel = 'Channel',
  Device = 'Device',
  Group = 'Group',
  Installation = 'Installation',
  Invitation = 'Invitation',
  UserProfile = 'UserProfile',
}

export type ChildrenProps = {
  children: React.ReactNode;
};

export type LavvaResolve = {
  status: PublishStatus | undefined;
  deviceId?: string;
  onSuccess?: () => void;
};
