import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  IInputOutputSwitchParameters,
  InputModeSwitch,
  ReactionToInput as ReactionToInputType,
  InputOutputSwitchParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { IRomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/IRomConfigService';
import { IRopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/IRopConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { Accordion, InputSelect, SelectOptionType, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { LedTimeType } from '../../../device-details/types';

type InputsModeFormProps = {
  device: IDevice;
  inputs: IInputOutputSwitchParameters[] | null;
  led?: LedTimeType;
};

const InputsModeForm: React.FC<InputsModeFormProps> = ({ device, inputs, led }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [expanded, setExpanded] = useState<string | false>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configService: serviceName, handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<IInputOutputSwitchParameters>();

  const { InputMode, ReactionToInput } = form.watch();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const data = (inputs || []).find((x) => x.InputChannel.toString() === panel);

    if (data) {
      form.setValue('InputMode', data.InputMode);
      form.setValue('ReactionToInput', data.ReactionToInput);
      form.setValue('InputChannel', data.InputChannel);

      setExpanded(isExpanded ? panel : false);
    }
  };

  const onSubmit = form.handleSubmit(async (values) => {
    const data = (inputs || []).find((x) => x.InputChannel === values.InputChannel);

    if (data) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
        IRopConfigService | IRomConfigService | Slr22ConfigService
      >(serviceName);

      const params = new InputOutputSwitchParameters();
      params.Channel = data.Channel;
      params.InputChannel = data.InputChannel;
      params.InputMode = values.InputMode;
      params.ReactionToInput = values.ReactionToInput;

      const result = await configService.SetSwitchInputOutputParamAsync(device, params);
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeInputMode = (value: InputModeSwitch) => {
    form.setValue('InputMode', value);
  };

  const onChangeReactionToInput = (value: ReactionToInputType) => {
    form.setValue('ReactionToInput', value);
  };

  return (
    <>
      {(inputs || []).map((x) => (
        <Accordion
          key={x.InputChannel}
          kind="controlled-base"
          title={`${t('exalus.params.OnOffParams.input')} ${x.InputChannel}`}
          expanded={expanded === x.InputChannel.toString()}
          handleExpand={handleChange(x.InputChannel.toString())}
          titleNotbold
        >
          <FormProvider {...form}>
            <form onSubmit={onSubmit} className="grid-list-24">
              <InputSelect
                onChange={onChangeInputMode}
                value={InputMode}
                options={[
                  {
                    label: t('exalus.params.OnOffParams.inputModeSteps.monostable'),
                    value: InputModeSwitch.Monostable,
                  },
                  { label: t('exalus.params.OnOffParams.inputModeSteps.bistable'), value: InputModeSwitch.Bistable },
                ]}
                label={t('exalus.params.OnOffParams.inputMode')}
              />
              <InputSelect
                onChange={onChangeReactionToInput}
                value={ReactionToInput}
                options={
                  !led
                    ? ([
                        {
                          label: t('exalus.params.OnOffParams.inputReactionSteps.none'),
                          value: ReactionToInputType.None,
                        },
                        {
                          label: t('exalus.params.OnOffParams.inputReactionSteps.off'),
                          value: ReactionToInputType.Off,
                        },
                        { label: t('exalus.params.OnOffParams.inputReactionSteps.on'), value: ReactionToInputType.On },
                        {
                          label: t('exalus.params.OnOffParams.inputReactionSteps.time'),
                          value: ReactionToInputType.Time,
                        },
                        {
                          label: t('exalus.params.OnOffParams.inputReactionSteps.mono'),
                          value: ReactionToInputType.Mono,
                        },
                        {
                          label: InputMode === 0 ? t('exalus.params.OnOffParams.inputReactionSteps.bi') : '',
                          value: '5',
                        },
                      ] as SelectOptionType<ReactionToInputType>[])
                    : ([
                        {
                          label: t('exalus.params.OnOffParams.inputReactionSteps.off'),
                          value: ReactionToInputType.Off,
                        },
                        { label: t('exalus.params.OnOffParams.inputReactionSteps.on'), value: ReactionToInputType.On },
                        {
                          label: t('exalus.params.OnOffParams.inputReactionSteps.onOff'),
                          value: ReactionToInputType.OnOff,
                        },
                      ] as SelectOptionType<ReactionToInputType>[])
                }
                label={t('exalus.params.OnOffParams.inputMode')}
              />
            </form>
          </FormProvider>
          <SubmitButton onClick={onSubmit}>{`${tc('buttons.save')} ${t('exalus.params.OnOffParams.input')} ${
            x.InputChannel
          }`}</SubmitButton>
        </Accordion>
      ))}
    </>
  );
};

export default InputsModeForm;
