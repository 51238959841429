import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeActivation } from './time-activation';
import { FavouritePositions } from '../../../../types';
import './index.scss';

export type SwitchDetailsTimeActivationsProps = {
  setOnTimeList: FavouritePositions;
  handleSetOnTime: (value: number) => void;
  onTimeChange?: (value: number, index: number) => void;
  active?: number | null;
};

export const SwitchDetailsTimeActivations: React.FC<SwitchDetailsTimeActivationsProps> = ({
  setOnTimeList,
  onTimeChange,
  handleSetOnTime,
  active,
}) => {
  const { t } = useTranslation('channel-details');

  return (
    <div className="switch-details__time-activations">
      <h3 className="m-t-16 m-b-16">{t('timeActivation')}</h3>
      <div className="time-list">
        {setOnTimeList.map((value, index) => (
          <TimeActivation
            key={index}
            value={value}
            index={index}
            onTimeChange={onTimeChange}
            onBtnClick={handleSetOnTime}
            active={active}
          />
        ))}
      </div>
    </div>
  );
};
