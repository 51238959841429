import React, { useState } from 'react';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, dragOverlayStyle, SubmitButton } from '../../../../../../components';
import { TriggerActionRequestInput } from '../../../../../../data-access/gql-types/graphql';
import { useTriggerFormContext } from '../../context';
import { ActionItemSort } from './action-item';
import { ActionItem } from './action-item/item';

type TriggerActionsSortProps = {
  goToSummary: () => void;
};

export const TriggerActionsSort: React.FC<TriggerActionsSortProps> = ({ goToSummary }) => {
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { actions, setActions } = useTriggerFormContext();
  const [sortedActions, setSortedActions] = useState<TriggerActionRequestInput[]>(actions);

  const onDragStart = (event: dndCore.DragStartEvent) => setDndActiveId(event.active.id);

  const onDragEnd = (event: dndCore.DragEndEvent) => {
    if (!event.active || !event.over) {
      return;
    }

    if (event.active.id !== event.over.id && sortedActions) {
      const newList = Array.from(sortedActions);
      const oldIndex = newList.findIndex((item) => item.id === event.active.id);
      const newIndex = newList.findIndex((item) => item.id === event.over?.id);

      const sortedChannels = dndSortable.arrayMove(newList, oldIndex, newIndex);
      setSortedActions(sortedChannels);
    }

    setIsValid(true);
    setDndActiveId(null);
  };

  const onSubmit = () => {
    setActions(sortedActions);
    goToSummary();
  };

  return (
    <div className="actions-sort">
      <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={sortedActions}>
        {sortedActions.map((action) => {
          return <ActionItemSort key={action.id} list={sortedActions} id={action.id} />;
        })}
        <dndCore.DragOverlay>
          {dndActiveId && <ActionItem isDraggable style={dragOverlayStyle} id={dndActiveId} list={sortedActions} />}
        </dndCore.DragOverlay>
      </DragAndDrop>
      <SubmitButton disabled={!isValid} onClick={onSubmit} />
    </div>
  );
};
