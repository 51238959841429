import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { IconEco2 } from '../../../../../components/icons/icon-eco-2';
import { ecoLevelsState } from '../../../modules/channel-details/meter/current/utils';
import './index.scss';

type EcoBoxProps = {
  level: number;
  isListItem?: boolean;
};

export const EcoBox: React.FC<EcoBoxProps> = ({ level, isListItem }) => {
  const { t } = useTranslation('dashboard');
  const ecoLevel = ecoLevelsState[level];
  const style = {
    '--color': ecoLevel.value,
  } as React.CSSProperties;

  return (
    <div
      style={style}
      className={c('eco-box', {
        'eco-box--list-item': isListItem,
      })}
    >
      <div className="eco-box__icon">
        <IconEco2 big={!isListItem} />
      </div>
      <div className="eco-box__content">
        <span className="eco-box__text">{t(`${ecoLevel.heading}.1`)}</span>
        <span className="eco-box__text eco-box__text-with-background">{t(`${ecoLevel.heading}.2`)}</span>
      </div>
    </div>
  );
};
