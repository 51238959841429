import React, { ChangeEvent, useState } from 'react';
import { IconPassword } from '../icons';
import { Input } from './input';

type InputPinProps = {
  defaultValue?: string;
  value?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  label: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  hasError?: boolean;
};

export const InputPin: React.FC<InputPinProps> = (props) => {
  const [showPin, setShowPin] = useState<boolean>(false);

  const toggleShowPin = (event: React.PointerEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPin(!showPin);
  };

  return (
    <Input {...props} inputType={showPin ? 'text' : 'password'} isPin>
      <button onClick={toggleShowPin} className="input__icon">
        <IconPassword isOn={!showPin} />
      </button>
    </Input>
  );
};
