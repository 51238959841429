import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSelect } from '../../../../../../../components';
import {
  GenericInputBistableModeInternal,
  GenericInputMonostableModeInternal,
  GenericInputTypeInternal,
} from '../../../../../../../data-access/gql-types/graphql';
import { ChannelType } from '../../../../../types';
import { ChannelGenericInputType } from '../../../../../types/channel/generic-input';
import { useUpdateInputType } from '../../hooks/use-update-input-type';
import './index.scss';

type InputTypeProps = {
  inputChannel: ChannelType;
  monoBiConfigEnable: boolean;
};

const InputType: React.FC<InputTypeProps> = ({ inputChannel, monoBiConfigEnable }) => {
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const [genericInputType, setGenericInputType] = useState<GenericInputTypeInternal>(GenericInputTypeInternal.Unknown);
  const [monostableMode, setMonostableMode] = useState<GenericInputMonostableModeInternal>(
    GenericInputMonostableModeInternal.Normal,
  );
  const [bistableMode, setBistableMode] = useState<GenericInputBistableModeInternal>(
    GenericInputBistableModeInternal.Normal,
  );
  const { editInputType } = useUpdateInputType(inputChannel);

  useEffect(() => {
    setGenericInputType((inputChannel.data as ChannelGenericInputType).genericInputType);

    const payload = inputChannel.data as ChannelGenericInputType;

    if (payload.monostableMode) setMonostableMode(payload.monostableMode);
    if (payload.bistableMode) setBistableMode(payload.bistableMode);
  }, []);

  const handleUpdateInputType = (value: GenericInputTypeInternal) => {
    setGenericInputType(value);
  };

  const handleUpdateMonostableMode = (value: GenericInputMonostableModeInternal) => {
    setMonostableMode(value);
  };

  const handleUpdateBistableMode = (value: GenericInputBistableModeInternal) => {
    setBistableMode(value);
  };

  const submitBtnEnabled = useMemo(() => {
    return (
      (inputChannel.data as ChannelGenericInputType).genericInputType !== genericInputType ||
      (genericInputType === GenericInputTypeInternal.Monostable &&
        (inputChannel.data as ChannelGenericInputType).monostableMode !== monostableMode) ||
      (genericInputType === GenericInputTypeInternal.Bistable &&
        (inputChannel.data as ChannelGenericInputType).bistableMode !== bistableMode)
    );
  }, [
    (inputChannel.data as ChannelGenericInputType).genericInputType,
    genericInputType,
    monostableMode,
    bistableMode,
  ]);

  const handleSubmit = () => {
    if (submitBtnEnabled) {
      editInputType(genericInputType, monostableMode, bistableMode);
    }
  };

  const renderSubmitBtn = () => (
    <div className="btn-container">
      <button
        className="button max-width-desktop"
        onClick={handleSubmit}
        disabled={genericInputType === GenericInputTypeInternal.Unknown || !submitBtnEnabled}
      >
        {tc('buttons.save')}
      </button>
    </div>
  );

  return (
    <div className="input-type">
      <div className="grid-list-24">
        <InputSelect
          label={t('inputType')}
          value={genericInputType}
          onChange={handleUpdateInputType}
          options={[
            { label: t('monoStable'), value: GenericInputTypeInternal.Monostable },
            { label: t('biStable'), value: GenericInputTypeInternal.Bistable },
          ]}
        />
        {monoBiConfigEnable ? (
          <>
            {genericInputType === GenericInputTypeInternal.Monostable ? (
              <InputSelect
                label={t('mode')}
                value={monostableMode}
                onChange={handleUpdateMonostableMode}
                options={[
                  { label: t('normal'), value: GenericInputMonostableModeInternal.Normal },
                  { label: t('reversed'), value: GenericInputMonostableModeInternal.Reversed },
                ]}
              />
            ) : (
              <InputSelect
                label={t('mode')}
                value={bistableMode}
                onChange={handleUpdateBistableMode}
                disabled={genericInputType === GenericInputTypeInternal.Unknown}
                options={[
                  { label: t('normal'), value: GenericInputBistableModeInternal.Normal },
                  { label: t('twoWay'), value: GenericInputBistableModeInternal.TwoWay },
                ]}
              />
            )}
          </>
        ) : (
          renderSubmitBtn()
        )}
      </div>
      {monoBiConfigEnable && renderSubmitBtn()}
    </div>
  );
};

export default InputType;
