import { NotificationCode, NotificationParam } from '../data-access/gql-types/graphql';

export enum NotificationTypeEnum {
  INVITATION = 'INVITATION',
  OTHER = 'OTHER',
}

export enum NotificationInvitationType {
  InvitationAcceptedByInvitee = NotificationCode.InvitationAcceptedByInvitee,
  InvitationCanceledByCreator = NotificationCode.InvitationCanceledByCreator,
  InvitationReceived = NotificationCode.InvitationReceived,
  InvitationRejectedByInvitee = NotificationCode.InvitationRejectedByInvitee,
}

export type NotificationInstallationInvitation = {
  type: NotificationTypeEnum.INVITATION;
  invitationType: NotificationInvitationType;
  invitationId: string;
  installationName: string;
  params: NotificationParam[];
  userName?: string;
};

export type NotificationOther = {
  type: NotificationTypeEnum.OTHER;
  title: string;
  message: string;
  params: NotificationParam[];
};

export type NotificationType = {
  id: string;
  correlationId: string;
  wasDisplayed: boolean;
  isHidden: boolean;
  createdTimestampUTC: number;
  data: NotificationInstallationInvitation | NotificationOther;
};

export type NotificationCallback = {
  onSuccess?: () => void;
  onError?: () => void;
};
