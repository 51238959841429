import { useMutation } from '@apollo/client';
import { useInstallationContext } from '../../../../../context/installation';
import {
  LoggingInfo,
  LogLevel,
  PushBluetoothLogMutation,
  PushBluetoothLogMutationVariables,
} from '../../../../../data-access/gql-types/graphql';
import { PUSH_BLUETOOTH_LOG } from '../../../../../data-access/mutations/logs';
import { PushBTLogPayload, ShowAndSendMethodPayload } from '../types';
import { logMessage } from '../utils';

export const useBTLogs = () => {
  const { selectedInstallationId } = useInstallationContext();
  const [pushBluetoothLog] = useMutation<PushBluetoothLogMutation, PushBluetoothLogMutationVariables>(
    PUSH_BLUETOOTH_LOG,
  );

  const pushBTLog = ({ macAddress, loggingInfo, messages }: PushBTLogPayload) => {
    pushBluetoothLog({
      variables: {
        input: {
          installationId: selectedInstallationId,
          macAddress,
          messages: [messages],
          loggingInfo,
        },
      },
    });
  };

  const showAndSendLog = ({ btLogLevel, loggingInfo, messages, macAddress }: ShowAndSendMethodPayload) => {
    if (btLogLevel === LogLevel.None || !macAddress) return;

    switch (loggingInfo) {
      case LoggingInfo.None:
        logMessage(`LOG > ${messages.join(' ')}`, btLogLevel);

        pushBTLog({
          macAddress,
          loggingInfo: LoggingInfo.None,
          messages: messages.map((message) => parseInt(message, 16)),
        });

        break;
      case LoggingInfo.Connected:
        logMessage(`LOG > Connected to device MAC: ${macAddress}`, btLogLevel);

        pushBTLog({
          macAddress,
          loggingInfo: LoggingInfo.Connected,
          messages: [],
        });

        break;
      case LoggingInfo.Disconnected:
        logMessage(`LOG > Disconnected device MAC: ${macAddress}`, btLogLevel);

        pushBTLog({
          macAddress,
          loggingInfo: LoggingInfo.Disconnected,
          messages: [],
        });

        break;
      case LoggingInfo.Supported:
        logMessage(`LOG > Logs supported for device MAC: ${macAddress}`, btLogLevel);

        pushBTLog({
          macAddress,
          loggingInfo: LoggingInfo.Supported,
          messages: [],
        });

        break;
      case LoggingInfo.NotSupported:
        logMessage(`LOG > Logs not supported for device MAC: ${macAddress}`, btLogLevel);

        pushBTLog({
          macAddress,
          loggingInfo: LoggingInfo.NotSupported,
          messages: [],
        });

        break;
      default:
        break;
    }
  };

  return {
    showAndSendLog,
  };
};
