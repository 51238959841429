import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import { useSubscription } from '@apollo/client';
import { IconDevice } from '../../../../../components/icons/device';
import { IconToastWarning2 } from '../../../../../components/icons/icon-toast-warning-2';
import { useInstallationContext } from '../../../../../context/installation';
import {
  DeviceConnectionState,
  OnDeviceConnectionStateChangeSubscription,
  OnDeviceConnectionStateChangeSubscriptionVariables,
} from '../../../../../data-access/gql-types/graphql';
import { ON_DEVICE_CONNECTION_STATE_CHANGE } from '../../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext } from '../../../../../context/backdrop';
import { getSignalStrength } from '../../../../../utils/helpers/network';
import { toastSuccess } from '../../../../../utils/toast';
import { useConnectToUnknownNetworkBatch, useGetWiFiNetworksBatch } from '../../../api/device/device.hooks';
import { DeviceNetworks, Network } from '../../../api/device/device.types';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';
import { AvailableDevice, NetworkForm } from '../types';

export const useWifiConfiguration = () => {
  const { t } = useTranslation('device-list');
  const { t: ta } = useTranslation('device-add');
  const [selectedNetwork, setSelectedNetwork] = useState<string>('');
  const [popup, setPopup] = useState<boolean>(false);
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();
  const [deviceConnectingPopup, setDeviceConnectingPopup] = useState<boolean>(false);
  const [availableDevices, setAvailableDevices] = useState<AvailableDevice[]>([]);
  const [connectedList, setConnectedList] = useState<string[]>([]);
  const { deviceList } = useDevicesAndChannelsContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { data, isLoading, refetch } = useGetWiFiNetworksBatch();
  const { mutate: connectToUnknownNetworkBatch } = useConnectToUnknownNetworkBatch();
  const { data: deviceConnection } = useSubscription<
    OnDeviceConnectionStateChangeSubscription,
    OnDeviceConnectionStateChangeSubscriptionVariables
  >(ON_DEVICE_CONNECTION_STATE_CHANGE, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });
  const form = useForm<NetworkForm>({
    defaultValues: {
      password: '',
    },
  });

  useEffect(() => {
    if (selectedNetwork) {
      const devices: AvailableDevice[] = (
        data?.filter((x) => x.networks.find((y) => y.ssid === selectedNetwork)) || []
      ).map((x) => {
        const network = x.networks.find((y) => y.ssid === selectedNetwork);
        const foundDevice = deviceList.find((device) => device.id === x.deviceId);

        return {
          deviceId: x.deviceId,
          deviceName: foundDevice?.payload.name || 'N/A',
          ssid: foundDevice?.payload.connectedToSsid || 'N/A',
          deviceIcon: <IconDevice />,
          signal: getSignalStrength(network?.rssi),
          remembered: network?.remembered || false,
          checked: false,
          connected: true,
        };
      });

      setAvailableDevices(devices);
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (deviceConnection?.onDeviceConnectionStateChange) {
      setAvailableDevices((prev) => {
        const index = prev.findIndex((x) => x.deviceId === deviceConnection.onDeviceConnectionStateChange.deviceId);

        if (index !== -1) {
          const timeout = setTimeout(() => {
            prev[index].connected =
              deviceConnection.onDeviceConnectionStateChange.deviceConnectionState === DeviceConnectionState.Connected;
          }, 2000);

          clearTimeout(timeout);
        }

        return [...prev];
      });
    }
  }, [deviceConnection]);

  const networkList = useMemo(() => {
    const all = uniqBy(
      data?.reduce((all: Network[], val: DeviceNetworks) => all.concat(val.networks as Network[]), []) || [],
      (x) => x.ssid,
    ).map((x) => {
      const count = (data || []).filter((deviceNetwork) =>
        deviceNetwork.networks.find((y) => y.ssid === x.ssid),
      ).length;

      return {
        label: x.ssid,
        value: x.ssid,
        subLabel: x.supportedAuth
          ? `${t('wifiConfigurattion.rangeDevices')}: ${
              (data || []).filter((deviceNetwork) => deviceNetwork.networks.find((y) => y.ssid === x.ssid)).length
            }`
          : ta('bluetooth.networkUnsupported'),
        ...(count !== data?.length ? { icon: <IconToastWarning2 /> } : {}),
        disabled: !x.supportedAuth,
      };
    });

    return all;
  }, [data]);

  const toggleChecked = (deviceid: string) => {
    setAvailableDevices((prev) => {
      const index = prev.findIndex((x) => x.deviceId === deviceid);

      if (index !== -1) {
        prev[index].checked = !prev[index].checked;
      }

      return [...prev];
    });
  };

  const selectedDevicesCount = useMemo(() => {
    return availableDevices.filter((x) => x.checked).length;
  }, [availableDevices]);

  const unselectAllDevices = () => {
    setAvailableDevices((prev) => {
      return prev.map((x) => ({ ...x, checked: false }));
    });
  };

  const handleSelectNetwork = (ssid: string) => {
    setSelectedNetwork(ssid);
    unselectAllDevices();
  };

  const onSubmit = (values: NetworkForm) => {
    turnOnBackdrop();
    setAvailableDevices((prev) => {
      let newList = Array.from(prev);
      newList = newList.map((x) => {
        if (x.checked)
          return {
            ...x,
            connected: false,
          };

        return { ...x };
      });

      return newList;
    });

    const deviceIds = availableDevices.filter((x) => x.checked).map((x) => x.deviceId);

    connectToUnknownNetworkBatch(
      { deviceIds, ssid: selectedNetwork, password: values.password },
      {
        onSuccess: () => {
          form.setValue('password', '');
          setPopup(false);
          toastSuccess({ content: t('wifiConfigurattion.updateNetworkSent') });
          turnOffBackdrop();
          setDeviceConnectingPopup(true);
        },
      },
    );
  };

  const handleRefresh = () => {
    refetch();
    setSelectedNetwork('');
    setPopup(false);
    setDeviceConnectingPopup(false);
    setAvailableDevices([]);
    setConnectedList([]);
  };

  return {
    networkList,
    isLoading,
    selectedNetwork,
    availableDevices,
    popup,
    form,
    deviceConnectingPopup,
    selectedDevicesCount,
    connectedList,
    setConnectedList,
    setAvailableDevices,
    handleSelectNetwork,
    toggleChecked,
    onSubmit,
    setPopup,
    setDeviceConnectingPopup,
    unselectAllDevices,
    handleRefresh,
  };
};
