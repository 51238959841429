import React, { useMemo } from 'react';
import LavvaDetailsWrapper from '../../../../../../components/details-wrapper';
import {
  ChannelTypeInternal,
  TriggerActionRequestInput,
  TriggerActionType,
} from '../../../../../../data-access/gql-types/graphql';
import { ChannelDetailsHeader } from '../../../../components';
import { useDevicesAndChannels } from '../../../../hooks';
import { useTriggerFormContext } from '../../context';
import ChannelControls from './controls';

type ChannelSetupProps = {
  channelId: string;
  setAction: (value: TriggerActionRequestInput) => void;
};

const ChannelSetup: React.FC<ChannelSetupProps> = ({ channelId, setAction }) => {
  const { channel } = useDevicesAndChannels({ channelId });
  const { actions } = useTriggerFormContext();

  const defaultParameters = useMemo(() => {
    const foundAction = actions.find((action) => action.id === channelId);
    if (foundAction) return foundAction;
    switch (channel?.data.type) {
      case ChannelTypeInternal.Blind:
        return {
          actionType: TriggerActionType.SetBlindPosition,
          id: channel.id,
          integerValue: 0,
        };
      case ChannelTypeInternal.Gate:
        return {
          actionType: TriggerActionType.GateSetPosition,
          id: channel.id,
          integerValue: 0,
        };
      case ChannelTypeInternal.Light:
        return {
          actionType: TriggerActionType.SetLightState,
          id: channel?.id,
          booleanValue: true,
        };
      case ChannelTypeInternal.Switch:
      default:
        return {
          actionType: TriggerActionType.SetSwitchState,
          id: channel?.id,
          booleanValue: true,
        };
    }
  }, [channel, channelId]);

  if (!channel) return null;

  return (
    <div className="device-setup">
      <ChannelDetailsHeader channel={channel} lightControlDisabled />
      <LavvaDetailsWrapper>
        <ChannelControls channel={channel} defaultParameters={defaultParameters} setAction={setAction} />
      </LavvaDetailsWrapper>
    </div>
  );
};

export default ChannelSetup;
