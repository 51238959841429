import React from 'react';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import './index.scss';

type ChannelBoxControl = {
  icon: React.ReactNode;
  handleAction: () => void;
  active?: boolean;
};

type ChannelBoxControlsCommonProps = {
  isListItem?: boolean;
};

type NormalControlsProps = ChannelBoxControlsCommonProps & {
  kind: 'normal';
  controls: ChannelBoxControl[];
};

type WithDataControlsProps = ChannelBoxControlsCommonProps & {
  kind: 'with-data';
  children: React.ReactNode;
  controls: [ChannelBoxControl, ChannelBoxControl];
};

type ChannelBoxControlsProps = NormalControlsProps | WithDataControlsProps;

export const ChannelBoxControls: React.FC<ChannelBoxControlsProps> = (props) => {
  const { isListItem, controls, kind } = props;

  const renderWithViewControls = () => {
    if (kind === 'with-data') {
      const [first, second] = controls;

      return (
        <>
          {isListItem && props.children}
          <IconButton
            className={classNames('channel-control-box__control', { active: !!first.active })}
            onClick={first.handleAction}
          >
            {first.icon}
          </IconButton>
          {!isListItem && props.children}
          <IconButton
            className={classNames('channel-control-box__control', { active: !!second.active })}
            onClick={second.handleAction}
          >
            {second.icon}
          </IconButton>
        </>
      );
    }
  };

  return (
    <div
      className={classNames('channel-control-box', {
        'channel-control-box--list-view': isListItem,
      })}
    >
      <div className={classNames('channel-control-box__controls', { center: controls.length === 1 })}>
        {kind === 'normal'
          ? controls.map(({ icon, handleAction, active }, index) => (
              <IconButton
                key={index}
                className={classNames('channel-control-box__control', { active: !!active })}
                onClick={handleAction}
              >
                {icon}
              </IconButton>
            ))
          : renderWithViewControls()}
      </div>
    </div>
  );
};
