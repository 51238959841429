import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import './index.scss';

export const SensorChartTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const {
      name,
      value,
      payload: { unit },
    } = payload[0];

    return (
      <div className="sensor-chart-tooltip">
        <p className="label">{label}</p>
        <p className="value">{`${name}: ${value} ${unit || ''}`}</p>
      </div>
    );
  }

  return null;
};
