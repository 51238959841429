import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useInstallationContext } from '../../../../context/installation';
import { AllTriggersQueryVariables, Query } from '../../../../data-access/gql-types/graphql';
import { ALL_TRIGGERS } from '../../../../data-access/queries/triggers';
import { toastError } from '../../../../utils/toast';

export const useTriggers = () => {
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId, skipLavvaFetch } = useInstallationContext();

  const { data, loading } = useQuery<Query, AllTriggersQueryVariables>(ALL_TRIGGERS, {
    variables: { installationId: selectedInstallationId },
    fetchPolicy: 'cache-and-network',
    onError: () => toastError({ content: tc('errors.downloadData') }),
    skip: skipLavvaFetch,
  });

  const allTriggers = useMemo(() => data?.allTriggers, [data?.allTriggers]);

  return {
    allTriggers: allTriggers || [],
    loading,
  };
};
