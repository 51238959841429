import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNative } from '../../../../hooks';
import { useErrors } from '../../../../hooks/errors';
import { useRestClient } from '../../../../utils/clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import { MeasurementsResetBody, MeterAmpTransformers, MeterPurpose, MeterTariffConfigRequest } from './meter.types';

export const useResetAggregateMeasurements = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  MeasurementsResetBody
> => {
  const { t } = useTranslation('backend-validation');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, MeasurementsResetBody>(
    ['optimizer-reset-aggregate-measurements'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/meter/resetAggregateMeasurements', body);
    },
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('DEVICE_SYNC_REQUEST_TIMEOUT'));
      },
    },
  );
};

export const useSetMeterAmpTransformers = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  MeterAmpTransformers
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, MeterAmpTransformers>(
    ['meter-setAmpTransformers'],
    async (body) => await restClient.post<DeviceResponse>('/meter/setAmpTransformers', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useSetMeterPurpose = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, MeterPurpose> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, MeterPurpose>(
    ['meter-setMeterPurpose'],
    async (body) => await restClient.post<DeviceResponse>('/meter/setMeterPurpose', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useMeterSetTariff = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  MeterTariffConfigRequest
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, MeterTariffConfigRequest>(
    ['meter-setMeterTariff'],
    async (body) => await restClient.post<DeviceResponse>('/meter/setMeterTariff', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
