import { useTranslation } from 'react-i18next';
import { Header } from '../../../../../../../components';

export const WisniowskiDriveAddStartSmartAWGate: React.FC = () => {
  const { t } = useTranslation('drive-add');

  return (
    <Header title={t('driveAddTitle')} column subtitle={t('driveAddSubTitle.smartAWGate')} center className="m-b-48" />
  );
};
