import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useHold } from '../../../../../../../hooks';
import { convertMSToMinutesSeconds, leadingZero } from '../../../../../../../utils/helpers';
import { IconTime } from '../../../../../../../components/icons';
import './index.scss';

type TimeActivationButtonProps = {
  value: number;
  onClick?: () => void;
  onHold?: () => void;
  active?: boolean;
};

export const TimeActivationButton: React.FC<TimeActivationButtonProps> = ({
  value,
  onClick,
  onHold,
  active = false,
}) => {
  const attributes = onHold
    ? useHold({
        holdMillisecond: 1000,
        onClick,
        onHold,
      })
    : { onClick };

  const valueConverted = useMemo(() => {
    const { minutes, seconds } = convertMSToMinutesSeconds(value);
    if (minutes) {
      if (!seconds) {
        return `${minutes} min`;
      }
      return `${minutes}:${leadingZero(seconds)}`;
    }
    return `${seconds} s`;
  }, [value]);

  return (
    <button
      {...attributes}
      className={classNames('time-activation-button', 'button', 'button--invert', {
        active,
      })}
    >
      <IconTime />
      <span className="time-activation-button__value">{valueConverted}</span>
    </button>
  );
};
