import { RgbSceneIcon0 } from '../../../../../components/icons/rgb-scene';
import {
  IconAlarm,
  IconApple,
  IconBag,
  IconBall,
  IconBird,
  IconBriefcase,
  IconCar,
  IconCart,
  IconCat,
  IconCinema,
  IconCloset,
  IconCoffee,
  IconDocument,
  IconDog,
  IconDrink,
  IconGuitar,
  IconGym,
  IconInside,
  IconLeaf,
  IconLock,
  IconMargarita,
  IconMessage,
  IconMusic,
  IconNight,
  IconOutside,
  IconPhone,
  IconPhoto,
  IconPlay,
  IconPuzzle,
  IconRain,
  IconShopping,
  IconSport,
  IconSun,
  IconTowel,
  IconTravel,
  IconTv,
} from '../../../../../components/icons/scenario';
import { AvailableIcon, RgbSceneIconTypeEnum } from '../../../../../types';

export const availableRgbSceneIcons: AvailableIcon[] = [
  { iconName: RgbSceneIconTypeEnum.DEFAULT, component: <RgbSceneIcon0 /> },
  { iconName: RgbSceneIconTypeEnum.OUTSIDE, component: <IconOutside /> },
  { iconName: RgbSceneIconTypeEnum.BALL, component: <IconBall /> },
  { iconName: RgbSceneIconTypeEnum.SPORT, component: <IconSport /> },
  { iconName: RgbSceneIconTypeEnum.NIGHT, component: <IconNight /> },
  { iconName: RgbSceneIconTypeEnum.BRIEFCASE, component: <IconBriefcase /> },
  { iconName: RgbSceneIconTypeEnum.DRINK, component: <IconDrink /> },
  { iconName: RgbSceneIconTypeEnum.TV, component: <IconTv /> },
  { iconName: RgbSceneIconTypeEnum.DOCUMENT, component: <IconDocument /> },
  { iconName: RgbSceneIconTypeEnum.BAG, component: <IconBag /> },
  { iconName: RgbSceneIconTypeEnum.SHOPPING, component: <IconShopping /> },
  { iconName: RgbSceneIconTypeEnum.LOCK, component: <IconLock /> },
  { iconName: RgbSceneIconTypeEnum.CAR, component: <IconCar /> },
  { iconName: RgbSceneIconTypeEnum.CLOSET, component: <IconCloset /> },
  { iconName: RgbSceneIconTypeEnum.MUSIC, component: <IconMusic /> },
  { iconName: RgbSceneIconTypeEnum.INSIDE, component: <IconInside /> },
  { iconName: RgbSceneIconTypeEnum.GYM, component: <IconGym /> },
  { iconName: RgbSceneIconTypeEnum.COFFEE, component: <IconCoffee /> },
  { iconName: RgbSceneIconTypeEnum.APPLE, component: <IconApple /> },
  { iconName: RgbSceneIconTypeEnum.TRAVEL, component: <IconTravel /> },
  { iconName: RgbSceneIconTypeEnum.TOWEL, component: <IconTowel /> },
  { iconName: RgbSceneIconTypeEnum.ALARM, component: <IconAlarm /> },
  { iconName: RgbSceneIconTypeEnum.PHONE, component: <IconPhone /> },
  { iconName: RgbSceneIconTypeEnum.PHOTO, component: <IconPhoto /> },
  { iconName: RgbSceneIconTypeEnum.LEAF, component: <IconLeaf /> },
  { iconName: RgbSceneIconTypeEnum.CART, component: <IconCart /> },
  { iconName: RgbSceneIconTypeEnum.RAIN, component: <IconRain /> },
  { iconName: RgbSceneIconTypeEnum.CAT, component: <IconCat /> },
  { iconName: RgbSceneIconTypeEnum.DOG, component: <IconDog /> },
  { iconName: RgbSceneIconTypeEnum.BIRD, component: <IconBird /> },
  { iconName: RgbSceneIconTypeEnum.PUZZLE, component: <IconPuzzle /> },
  { iconName: RgbSceneIconTypeEnum.GUITAR, component: <IconGuitar /> },
  { iconName: RgbSceneIconTypeEnum.MESSAGE, component: <IconMessage /> },
  { iconName: RgbSceneIconTypeEnum.SUN, component: <IconSun /> },
  { iconName: RgbSceneIconTypeEnum.PLAY, component: <IconPlay /> },
  { iconName: RgbSceneIconTypeEnum.MARGARITA, component: <IconMargarita /> },
  { iconName: RgbSceneIconTypeEnum.CINEMA, component: <IconCinema /> },
];
