import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, Header, NavHeader, Page, SubmitButton } from '../../../../../../components';
import TextInfo from '../../../../../../components/text-info';
import * as storage from '../../../../storage';
import DeviceAddBluetoothCheckList from '../check-list';
import ConfigurationInput from '../../components/input-controller';
import { useBluetoothContext } from '../../context';
import { NetworkFormValues } from '../../types';
import './index.scss';

const DeviceAddBluetoothNetwork: React.FC = () => {
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const [isLastConfiguration, setIsLastConfiguration] = useState<boolean>(false);
  const [lastSsid, setLastSsid] = useState<string>('');
  const [checkListOpen, setCheckListOpen] = useState<boolean>(false);
  const {
    checkIfConnected,
    removeWifi,
    getKnowsWifiNetworks,
    wifiList,
    wifiLoading,
    setWifi,
    registerDevice,
    bluetoothData,
    updateWifiData,
  } = useBluetoothContext();
  const form = useForm<NetworkFormValues>({
    mode: 'all',
    defaultValues: {
      ssid: '',
      password: '',
    },
  });

  useEffect(() => {
    checkIfConnected();

    const bluetoothNetwork = storage.getItem('bluetoothNetwork');
    if (bluetoothNetwork) {
      const parsed = JSON.parse(bluetoothNetwork);

      if (parsed.ssid && parsed.password) {
        setLastSsid(parsed.ssid);
        console.log('LAST CONFIGURATION', parsed.ssid);
        setIsLastConfiguration(true);
      }
    }
  }, []);

  const setLastConfigurationData = () => {
    const bluetoothNetwork = storage.getItem('bluetoothNetwork');

    if (bluetoothNetwork) {
      const parsed = JSON.parse(bluetoothNetwork);
      const ssid = parsed.ssid;
      const password = parsed.password.substring(1);

      form.setValue('ssid', ssid);
      form.setValue('password', password);
      setIsLastConfiguration(false);

      onSubmit({ ssid, password });
    }
  };

  const onSubmit = (formData: NetworkFormValues) => {
    updateWifiData(formData.ssid, formData.password);

    if (bluetoothData.isAlreadyAdded) {
      setWifi(formData.ssid, formData.password);
    } else {
      setCheckListOpen(true);
      registerDevice(formData.ssid, formData.password);
    }
  };

  const redoRegister = () => {
    const { ssid, password } = form.getValues();
    registerDevice(ssid, password);
  };

  return (
    <>
      {!checkListOpen ? (
        <Page
          isStickyHeader
          contentClassName="device-add-network"
          header={
            <>
              <NavHeader />
              <Header title={t('bluetooth.networkConfiguration')} isUnderline />
            </>
          }
        >
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid-list-24">
              <ConfigurationInput
                name="ssid"
                label={t('bluetooth.ssid')}
                wifiList={wifiList}
                wifiLoading={wifiLoading}
                handleGetWifiList={getKnowsWifiNetworks}
                removeWifi={removeWifi}
                readOnly
              />
              <ConfigurationInput name="password" label={t('bluetooth.password')} inputType="password" />
              <SubmitButton>{tc('buttons.next')}</SubmitButton>
            </form>
          </FormProvider>
          <DialogConfirmation
            show={isLastConfiguration}
            setShow={setIsLastConfiguration}
            header={t('bluetooth.useFoundConfiguration')}
            primaryBtnText={tc('buttons.yes')}
            secondaryBtnText={tc('buttons.no')}
            secondaryBtnAction={() => setIsLastConfiguration(false)}
            primaryBtnAction={setLastConfigurationData}
          >
            <p>{t('bluetooth.foundConfiguration', { ssid: lastSsid })}</p>
            <div className="default-configuration-content m-t-16">
              <TextInfo label={t('bluetooth.ssid')} value={lastSsid} column inputLabel />
            </div>
          </DialogConfirmation>
        </Page>
      ) : (
        <DeviceAddBluetoothCheckList redoRegister={redoRegister} setCheckListOpen={setCheckListOpen} />
      )}
    </>
  );
};

export default DeviceAddBluetoothNetwork;
