import { UseFormSetValue } from 'react-hook-form';
import * as duration from 'duration-fns';
import { OptimizerChannelStateResponse, RelayConfig, Tariff } from '../../../../../../../data-access/gql-types/graphql';
import { ChannelType } from '../../../../../types';
import { ChannelOptimizerType } from '../../../../../types/channel/optimizer';
import { convertSecondsToHMS, leadingZero } from '../../../../../../../utils/helpers';
import { tariffKind } from '../utils/tariff-kind';

export const useOptimizerSetData = (setValue: UseFormSetValue<any>, type: keyof OptimizerChannelStateResponse) => {
  const setOptimizerData = (channel: ChannelType) => {
    const payload = (channel.data as ChannelOptimizerType).payload;

    if (payload) {
      if (payload[type]) {
        if ('tempMin' in payload[type]) {
          setValue('tempMin', payload[type].tempMin || 0);
        }

        if ('tempMax' in payload[type]) {
          setValue('tempMax', payload[type].tempMax || 0);
        }

        if ('tariff' in payload[type]) {
          if (payload[type].tariff) {
            setValue('wholeWeekendCheaper', (payload[type].tariff as Tariff).wholeWeekendCheaper);
            setValue('wholeHolidaysCheaper', (payload[type].tariff as Tariff).wholeHolidaysCheaper);
            setValue('ignoreSummerTimeChanges', (payload[type].tariff as Tariff).ignoreSummerTimeChanges);
            setValue(
              'hoursRanges',
              (payload[type].tariff as Tariff).hoursRanges.map((x) => ({
                ...x,
                from: `${leadingZero(x.from)}:00`,
                to: `${leadingZero(x.to)}:00`,
              })),
            );

            const tariff = tariffKind[(payload[type].tariff as Tariff).kind];
            setValue('operator', tariff.operator);
            setValue('tariffName', tariff.tariff);
          }
        }

        if ('heaterPowerLimit' in payload[type]) {
          setValue('heaterPowerLimit', payload[type].heaterPowerLimit || 0);
        }

        if ('tempSelfConsumption' in payload[type]) {
          setValue('tempSelfConsumption', payload[type].tempSelfConsumption || 0);
        }

        if ('powerLimit' in payload[type]) {
          setValue('powerLimit', payload[type].powerLimit || 0);
        }

        if ('tempMinEnabled' in payload[type]) {
          setValue('tempMinEnabled', payload[type].tempMinEnabled || false);
        }

        if ('workingHours' in payload[type]) {
          setWorkingHours(payload);
        }

        if ('voltageThreshold' in payload[type]) {
          setVoltageThresholds(payload);
        }

        if ('relaysConfig' in payload[type]) {
          setRelaysConfig(payload);
        }
      }
    }
  };

  const setWorkingHours = (payload: OptimizerChannelStateResponse) => {
    const from = duration.parse(payload.fullSelfConsumption.workingHours.from);
    const to = duration.parse(payload.fullSelfConsumption.workingHours.to);

    setValue('from', `${leadingZero(from.hours)}:${leadingZero(from.minutes)}`);
    setValue('to', `${leadingZero(to.hours)}:${leadingZero(to.minutes)}`);
  };

  const setVoltageThresholds = (payload: OptimizerChannelStateResponse) => {
    setValue('voltageThreshold1', payload[type].voltageThreshold.find((x) => x.index === 1)?.value || 0);
    setValue('voltageThreshold2', payload[type].voltageThreshold.find((x) => x.index === 2)?.value || 0);
    setValue('voltageThreshold3', payload[type].voltageThreshold.find((x) => x.index === 3)?.value || 0);
  };

  const setRelaysConfig = (payload: OptimizerChannelStateResponse) => {
    const relayConfig1 = payload[type].relaysConfig.find((x) => x.index === 1) as RelayConfig | undefined;
    if (relayConfig1)
      setValue('relayConfig1', {
        ...relayConfig1,
        index: 1,
        timeOn: convertSecondsToHMS(relayConfig1.timeOn),
        timeOff: convertSecondsToHMS(relayConfig1.timeOff),
      });
    const relayConfig2 = payload[type].relaysConfig.find((x) => x.index === 2) as RelayConfig | undefined;
    if (relayConfig2)
      setValue('relayConfig2', {
        ...relayConfig2,
        index: 2,
        timeOn: convertSecondsToHMS(relayConfig2.timeOn),
        timeOff: convertSecondsToHMS(relayConfig2.timeOff),
      });
    const relayConfig3 = payload[type].relaysConfig.find((x) => x.index === 3) as RelayConfig | undefined;
    if (relayConfig3)
      setValue('relayConfig3', {
        ...relayConfig3,
        index: 3,
        timeOn: convertSecondsToHMS(relayConfig3.timeOn),
        timeOff: convertSecondsToHMS(relayConfig3.timeOff),
      });
    const relayConfig4 = payload[type].relaysConfig.find((x) => x.index === 4) as RelayConfig | undefined;
    if (relayConfig4)
      setValue('relayConfig4', {
        ...relayConfig4,
        index: 4,
        timeOn: convertSecondsToHMS(relayConfig4.timeOn),
        timeOff: convertSecondsToHMS(relayConfig4.timeOff),
      });
  };

  return {
    setOptimizerData,
  };
};
