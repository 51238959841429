import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { useBackdropContext } from '../../../../../../../context/backdrop';
import { convertErrorsToOneString, isLocalApp } from '../../../../../../../utils/helpers';
import { scrollTop } from '../../../../../../../utils/helpers/scroll';
import { toastError } from '../../../../../../../utils/toast';
import { PropertyNameError } from '../../../../channel-configuration/types';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';

export const useOptimizerErrors = () => {
  const { t } = useTranslation('backend-validation');
  const { turnOffBackdrop } = useBackdropContext();
  const { addNewError } = useOptimizerConfigurationContext();

  const handleOptimizerErrors = (error: AxiosError) => {
    turnOffBackdrop();
    scrollTop();
    const response = error.response;

    if (response) {
      if ('errors' in (response as AxiosResponse).data) {
        const errors: string[] = [];
        const errorObject = (response as AxiosResponse).data.errors as Record<string, string>;

        Object.keys(errorObject).map((x) => {
          const errorParsed = JSON.parse(errorObject[x]) as PropertyNameError;
          const propertyName = errorParsed.PropertyName;
          const errorCode = errorParsed.ErrorCode;
          const comparisonValue = errorParsed.FormattedMessagePlaceholderValues?.ComparisonValue || '';
          console.log(errorParsed);

          switch (true) {
            case propertyName.includes('RelaysConfig[0].ThresholdValue'): {
              addNewError('relayConfig1', t(errorCode, { value: comparisonValue }), 'thresholdValue');
              break;
            }
            case propertyName.includes('RelaysConfig[1].ThresholdValue'): {
              addNewError('relayConfig2', t(errorCode, { value: comparisonValue }), 'thresholdValue');
              break;
            }
            case propertyName.includes('RelaysConfig[2].ThresholdValue'): {
              addNewError('relayConfig3', t(errorCode, { value: comparisonValue }), 'thresholdValue');
              break;
            }
            case propertyName.includes('RelaysConfig[3].ThresholdValue'): {
              addNewError('relayConfig4', t(errorCode, { value: comparisonValue }), 'thresholdValue');
              break;
            }
            case propertyName.includes('RelaysConfig[0].TimeOn'): {
              addNewError('relayConfig1', t(errorCode, { value: `${comparisonValue}s` }), 'timeOn');
              break;
            }
            case propertyName.includes('RelaysConfig[1].TimeOn'): {
              addNewError('relayConfig2', t(errorCode, { value: `${comparisonValue}s` }), 'timeOn');
              break;
            }
            case propertyName.includes('RelaysConfig[2].TimeOn'): {
              addNewError('relayConfig3', t(errorCode, { value: `${comparisonValue}s` }), 'timeOn');
              break;
            }
            case propertyName.includes('RelaysConfig[3].TimeOn'): {
              addNewError('relayConfig4', t(errorCode, { value: `${comparisonValue}s` }), 'timeOn');
              break;
            }
            case propertyName.includes('RelaysConfig[0].TimeOff'): {
              addNewError('relayConfig1', t(errorCode, { value: `${comparisonValue}s` }), 'timeOff');
              break;
            }
            case propertyName.includes('RelaysConfig[1].TimeOff'): {
              addNewError('relayConfig2', t(errorCode, { value: `${comparisonValue}s` }), 'timeOff');
              break;
            }
            case propertyName.includes('RelaysConfig[2].TimeOff'): {
              addNewError('relayConfig3', t(errorCode, { value: `${comparisonValue}s` }), 'timeOff');
              break;
            }
            case propertyName.includes('RelaysConfig[3].TimeOff'): {
              addNewError('relayConfig4', t(errorCode, { value: `${comparisonValue}s` }), 'timeOff');
              break;
            }
            case propertyName.includes('WorkingHours.From'): {
              addNewError('from', t(errorCode, { value: comparisonValue }));
              break;
            }
            case propertyName.includes('WorkingHours.To'): {
              addNewError('to', t(errorCode, { value: comparisonValue }));
              break;
            }
            case propertyName.includes('TempMin'): {
              addNewError('tempMin', t(errorCode, { value: comparisonValue }));
              break;
            }
            case propertyName.includes('TempMax'): {
              addNewError('tempMax', t(errorCode, { value: comparisonValue }));
              break;
            }
            case propertyName.includes('TempSelfConsumption'): {
              addNewError('tempSelfConsumption', t(errorCode));
              break;
            }
            default: {
              errors.push(t(errorCode));

              if (!isLocalApp) {
                Sentry.captureMessage('OPTIMIZER CONFIGURATION: NO HANDLE ERROR', {
                  level: 'info',
                  extra: {
                    error: errorParsed,
                  },
                });
              }
            }
          }
        });

        if (errors.length) toastError({ content: convertErrorsToOneString(errors) });
      }
    }
  };

  return {
    handleOptimizerErrors,
  };
};
