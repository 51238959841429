import React from 'react';
import { IconToastError, IconToastSuccess } from '../../../../../../components';
import './index.scss';

type StatusItemProps = {
  label: string;
  value: boolean;
};

export const StatusItem: React.FC<StatusItemProps> = ({ label, value }) => (
  <div className="status-item">
    {value ? <IconToastSuccess /> : <IconToastError />} <p>{label}</p>
  </div>
);
