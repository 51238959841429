import React, { createContext } from 'react';
import { ApiState, ChildrenProps } from '../../../types';
import { useChannelsGetAll } from '../hooks/api/channels-get-all';
import { useDevicesGetAll } from '../hooks/api/devices-get-all';
import { useGroupAndAction } from '../hooks/api/group-and-action';
import { useMapVariables } from '../hooks/api/map-variables';
import { useUserActions } from '../hooks/api/user-actions';

const initialState: ApiState = {
  groups: [],
  userActions: [],
  dashboardLoading: false,
  channelsLoading: false,
  devicesLoading: false,
  favouriteGroup: undefined,
  measurementMap: null,
  convertNumberToMeasurement: () => null,
  convertMeasurementToNumber: () => null,
};

export const ApiContext = createContext(initialState);

const ApiContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const devicesGetAll = useDevicesGetAll();
  const channelsGetAll = useChannelsGetAll();
  const groupAndAction = useGroupAndAction();
  const userActions = useUserActions();
  const mapVariables = useMapVariables();

  const values: ApiState = {
    ...devicesGetAll,
    ...channelsGetAll,
    ...groupAndAction,
    ...userActions,
    ...mapVariables,
  };

  return <ApiContext.Provider value={values}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;
