import React, { ReactNode } from 'react';
import { SnackbarUtilsConfigurator } from './components/snackbar';
import ApolloProviderWrapper from './context/apollo';
import { BackdropContextProvider } from './context/backdrop';
import { GraphqlProvider } from './context/graphql';
import InstallationProvider from './context/installation';
import NativeProvider from './context/native';
import NativeFunctionsProvider from './context/native-functions';
import CustomSnackbarProvider from './hooks/custom-snackbar/provider';
import { ExalusContextProvider } from './integrations/exalus/context';
import { ExalusDevicesContextProvider } from './integrations/exalus/context/devices';
import { ExalusServicesContextProvider } from './integrations/exalus/context/services';
import { ExalusStorageContextProvider } from './integrations/exalus/context/storage';
import { ExalusCreateActionContextProvider } from './integrations/exalus/modules/action-create/context';
import ApiProvider from './integrations/lavva/context/api';
import DevicesAndChannelsProvider from './integrations/lavva/context/devices-and-channels';
import UserPermissionsProvider from './integrations/lavva/context/user-permissions';
import { LavvaCreateActionContextProvider } from './integrations/lavva/modules/action-create/context';
import { CreateInstallationProvider } from './modules/installation-create/context';
import { ShareInstallationProvider } from './modules/installation-share/context';
import { OverApp } from './modules/over-app';
import './styles/main.scss';
import { isLocalApp } from './utils/helpers/local-app';

type ProvidersProps = {
  children: ReactNode;
};

const ProvidersLocal: React.FC<ProvidersProps> = ({ children }) => (
  <CustomSnackbarProvider>
    <SnackbarUtilsConfigurator />
    <ApolloProviderWrapper>
      <BackdropContextProvider>
        <ExalusServicesContextProvider>
          <ExalusStorageContextProvider>
            <ExalusDevicesContextProvider>
              <ExalusContextProvider>
                <InstallationProvider>
                  <NativeFunctionsProvider>
                    <NativeProvider>
                      <UserPermissionsProvider>
                        <ApiProvider>
                          <CreateInstallationProvider>
                            <ExalusCreateActionContextProvider>
                              <LavvaCreateActionContextProvider>{children}</LavvaCreateActionContextProvider>
                            </ExalusCreateActionContextProvider>
                          </CreateInstallationProvider>
                        </ApiProvider>
                      </UserPermissionsProvider>
                    </NativeProvider>
                  </NativeFunctionsProvider>
                </InstallationProvider>
              </ExalusContextProvider>
            </ExalusDevicesContextProvider>
          </ExalusStorageContextProvider>
        </ExalusServicesContextProvider>
      </BackdropContextProvider>
    </ApolloProviderWrapper>
  </CustomSnackbarProvider>
);

const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <CustomSnackbarProvider>
    <SnackbarUtilsConfigurator />
    <GraphqlProvider>
      <ApolloProviderWrapper>
        <BackdropContextProvider>
          <DevicesAndChannelsProvider>
            <ExalusServicesContextProvider>
              <ExalusStorageContextProvider>
                <ExalusDevicesContextProvider>
                  <ExalusContextProvider>
                    <InstallationProvider>
                      <NativeFunctionsProvider>
                        <NativeProvider>
                          <UserPermissionsProvider>
                            <ApiProvider>
                              <CreateInstallationProvider>
                                <ShareInstallationProvider>
                                  <ExalusCreateActionContextProvider>
                                    <LavvaCreateActionContextProvider>
                                      {children}
                                      <OverApp />
                                    </LavvaCreateActionContextProvider>
                                  </ExalusCreateActionContextProvider>
                                </ShareInstallationProvider>
                              </CreateInstallationProvider>
                            </ApiProvider>
                          </UserPermissionsProvider>
                        </NativeProvider>
                      </NativeFunctionsProvider>
                    </InstallationProvider>
                  </ExalusContextProvider>
                </ExalusDevicesContextProvider>
              </ExalusStorageContextProvider>
            </ExalusServicesContextProvider>
          </DevicesAndChannelsProvider>
        </BackdropContextProvider>
      </ApolloProviderWrapper>
    </GraphqlProvider>
  </CustomSnackbarProvider>
);

const GlobalProviders: React.FC<ProvidersProps> = ({ children }) => {
  return !isLocalApp ? <Providers>{children}</Providers> : <ProvidersLocal>{children}</ProvidersLocal>;
};

export default GlobalProviders;
