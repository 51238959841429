import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { defaultFavouriteSetOnTimeList } from '../../../../../../const';
import {
  ChannelTypeInternal,
  FavouritePositionType,
  SetFavouriteChannelPositionsMutation,
  SetFavouriteChannelPositionsMutationVariables,
} from '../../../../../../data-access/gql-types/graphql';
import { CHANNEL_SET_FAVOURITE_POSITIONS } from '../../../../../../data-access/mutations/channels';
import { toastError, toastSuccess } from '../../../../../../utils/toast';
import { useLightSetOnTime } from '../../../../api/light/light.hooks';
import { useSwitchSetOnTime } from '../../../../api/switch/switch.hooks';
import { SwitchDetailsTimeActivations } from '../../../../components/channel-details/switch/time-activatons';
import { useDevicesAndChannelsContext } from '../../../../context/devices-and-channels';
import { useDevicesAndChannels } from '../../../../hooks';
import { ChannelType, ChannelLightType, ChannelSwitchType } from '../../../../types';
import { parseFavouritePositions } from '../../../../utils/channels/helpers';

const favouriteRequestType = {
  [ChannelTypeInternal.Switch]: FavouritePositionType.Switch,
  [ChannelTypeInternal.Light]: FavouritePositionType.Lights,
};

type ChannelTimesActivationsProps = {
  channel: ChannelType;
};

export const ChannelTimesActivations: React.FC<ChannelTimesActivationsProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { channelList, setChannelList } = useDevicesAndChannels();
  const switchOnTime = useSwitchSetOnTime();
  const lightOnTime = useLightSetOnTime();
  const channelData = channel.data;
  const { updateChannelDetails } = useDevicesAndChannelsContext();
  const [setFavouritePositions] = useMutation<
    SetFavouriteChannelPositionsMutation,
    SetFavouriteChannelPositionsMutationVariables
  >(CHANNEL_SET_FAVOURITE_POSITIONS);

  const favouriteSetOnTimeList = useMemo(() => {
    if (channelData.type === ChannelTypeInternal.Switch || channelData.type === ChannelTypeInternal.Light) {
      return parseFavouritePositions(channelData.favouritePositionList, defaultFavouriteSetOnTimeList, false);
    }

    return defaultFavouriteSetOnTimeList;
  }, [channelData, channelList, setChannelList]);

  const onFavouriteTimeChange = (value: number, index: number) => {
    if (!channel) return;
    const newFavouriteList = cloneDeep(favouriteSetOnTimeList);
    newFavouriteList[index] = value;

    setFavouritePositions({
      variables: {
        input: {
          favouritePositions: newFavouriteList.map((position) => ({
            favouritePositionType: favouriteRequestType[channelData.type],
            value: position,
          })),
          channelId: channel?.id,
        },
      },
      onCompleted: (data) => {
        if (data.setFavouriteChannelPositions.ok) {
          toastSuccess({ content: t('favouriteSetSuccess') });

          setChannelList((prev) => {
            const tempList = cloneDeep(prev);
            const index = tempList.findIndex((x) => x.id === channel.id);

            if (index !== -1) {
              (tempList[index].data as ChannelSwitchType | ChannelLightType).favouritePositionList = [
                ...newFavouriteList,
              ];
            }

            return tempList;
          });

          updateChannelDetails((prev) => {
            const temp = cloneDeep(prev);
            if (temp) {
              (temp.data as ChannelSwitchType | ChannelLightType).favouritePositionList = [...newFavouriteList];
            }

            return temp;
          });
        } else {
          toastError({ content: tc('errors.somethingWentWrong') });
          // TODO errors
        }
      },
    });
  };

  const onClick = (value) => {
    switch (channel.data.type) {
      case ChannelTypeInternal.Switch: {
        switchOnTime.mutate({
          channelId: channel.id,
          deviceId: channel.deviceId,
          timeInMilliseconds: value,
        });
        break;
      }
      case ChannelTypeInternal.Light: {
        lightOnTime.mutate({
          channelId: channel.id,
          deviceId: channel.deviceId,
          timeInMilliseconds: value,
        });
        break;
      }
    }
  };

  return (
    <SwitchDetailsTimeActivations
      setOnTimeList={favouriteSetOnTimeList}
      onTimeChange={onFavouriteTimeChange}
      handleSetOnTime={onClick}
    />
  );
};
