import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSchedule } from '../../../../../../../../components/icons';
import { AggregateVectorParameterTypeInternal } from '../../../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../../../types';
import { ChartDataItem, CostEnum, EnergyStats } from '../../types';
import { summaryExludedParameters } from '../../utils/helpers';
import { calculateSummary, getSummaryParameters } from '../../utils/summary';
import './index.scss';

type SummaryProps = {
  summaryData: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
  itemPrefix: string;
};

export const Summary: React.FC<SummaryProps> = ({ summaryData, activeMeasurementRange, itemPrefix }) => {
  const { t } = useTranslation('channel-details');
  const [energyStats, setEnergyStats] = useState<Record<string, Record<string, EnergyStats>>>({});

  useEffect(() => {
    let stats = {};

    summaryData.forEach((entry) => {
      entry.values.forEach((val) => {
        const type = val.type;

        if (summaryExludedParameters.includes(type)) return;
        let value = val.value;

        if (type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
          const activeEnergy = entry.values.find(
            (x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
          );

          if (activeEnergy !== undefined && !activeEnergy.empty) {
            value = parseFloat((val.value * activeEnergy.value).toFixed(2));
            stats = calculateSummary(stats, val, value, CostEnum.Cost);
          } else {
            const predictionEnergy = entry.values.find(
              (x) => x.type === AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
            );
            if (predictionEnergy !== undefined) {
              value = parseFloat((val.value * predictionEnergy.value).toFixed(2));
              stats = calculateSummary(stats, val, value, CostEnum.PredictedCost);
            }
          }
        } else {
          stats = calculateSummary(stats, val, value, type);
        }
      });
    });

    Object.keys(stats).forEach((phase) => {
      Object.keys(stats[phase]).forEach((type) => {
        const average = stats[phase][type].count ? stats[phase][type].sum / stats[phase][type].count : 0;
        stats[phase][type].average = average;
      });
    });

    setEnergyStats(stats);
  }, [summaryData]);

  const renderSummarySectionName = () => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return t('summary.daily');
      case MeasurementRange.WeekInDays:
        return t('summary.weekly');
      case MeasurementRange.MonthInDays:
        return t('summary.monthly');
      case MeasurementRange.YearInMonths:
        return t('summary.yearly');
      case MeasurementRange.TenYearsInYears:
        return t('summary.tenYears');
      default:
        return '';
    }
  };

  const renderSummarySectionRow = (label: string, value: string) => (
    <div className="summary__section-row">
      <span>{label}</span>
      <span className="summary__section-value">{value}</span>
    </div>
  );

  const renderParameterSection = (type: string, phase: string, color: string) => {
    const sectionVisible =
      (phase === '0' && Object.keys(energyStats[phase]).length !== 1) || ['1', '2', '3'].includes(phase);

    if (!sectionVisible) return null;
    let sectionLabel = type;

    if (
      activeMeasurementRange === MeasurementRange.DayInHours &&
      [
        AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
        AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
      ].includes(type as AggregateVectorParameterTypeInternal)
    ) {
      sectionLabel = 'VECTOR_BALANCED_ACTIVE_ENERGY';
    }

    return (
      <div className="summary__section-label">
        {phase === '0' ? t(`energyMeasurements.types.summary.${sectionLabel}`) : `${itemPrefix} ${phase}`}
        <div className="label-color" style={{ backgroundColor: color }} />
      </div>
    );
  };

  return (
    <div className="summary max-width-desktop">
      {Object.keys(energyStats).length ? (
        <div className="summary__caption">
          <IconSchedule />
          <span className="summary__caption-text">{renderSummarySectionName()}</span>
        </div>
      ) : null}
      {Object.keys(energyStats).map((phase) =>
        Object.keys(energyStats[phase]).map((type) => {
          const parameters = getSummaryParameters(type);
          const parameterStat = energyStats[phase][type];
          const { unit, fixed } = parameters;

          return (
            <div key={type} className="summary__section summary__section--border">
              {renderParameterSection(type, phase, parameterStat.color)}
              {renderSummarySectionRow(t('summary.sum'), `${parameterStat.sum.toFixed(fixed)} ${unit}`)}
              {renderSummarySectionRow(t('summary.average'), `${parameterStat.average?.toFixed(fixed)} ${unit}`)}
              {renderSummarySectionRow(
                t('summary.max'),
                `${parameterStat.max !== null ? parameterStat.max.toFixed(parameters.fixed) : '-'} ${unit}`,
              )}
            </div>
          );
        }),
      )}
    </div>
  );
};
