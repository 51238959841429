import { IntegrationDefaultEnum } from '../../../types';
import { SupportData } from '../types';

export const supportData: Record<IntegrationDefaultEnum, SupportData> = {
  [IntegrationDefaultEnum.Lavva]: {
    websiteUrl: 'https://zamel.com/',
    supportUrl: 'https://zamel.com/pl/wsparcie-techniczne',
    contacts: [
      {
        email: 'lavva@zamel.pl',
        phones: [{ phoneNumber: '+48322113555', phoneLabel: '32 211 35 55' }],
      },
    ],
  },
  [IntegrationDefaultEnum.Exalus]: {
    websiteUrl: 'https://www.tr7.pl/',
    supportUrl: 'https://www.tr7.pl/kontakt/',
    contacts: [
      {
        email: 'kontakt@tr7.pl',
        phones: [
          { phoneNumber: '+48669756161', phoneLabel: '669 756 161' },
          { phoneNumber: '+48603050262', phoneLabel: '603 050 262' },
        ],
      },
    ],
  },
  [IntegrationDefaultEnum.Wisniowski]: {
    websiteUrl: 'https://www.wisniowski.pl/ ',
    supportUrl: 'https://www.wisniowski.pl/wsparcie',
    contacts: [
      {
        email: 'tech.support@wisniowski.pl',
        phones: [
          {
            phoneNumber: '+48184483001',
            phoneLabel: '+48 18 44 83 001',
          },
        ],
      },
    ],
  },
};
