import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { DeviceStateComparisonMethod } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { ChildrenProps } from '../../../../../types';

type ExalusComparisonProviderState = {
  comparisonMethod: DeviceStateComparisonMethod;
  setComparisonMethod: Dispatch<SetStateAction<DeviceStateComparisonMethod>>;
  days: string;
  setDays: Dispatch<SetStateAction<string>>;
  timespan: string;
  setTimeSpan: Dispatch<SetStateAction<string>>;
  paramValue: string;
  setParamValue: Dispatch<SetStateAction<string>>;
};

const initialState: ExalusComparisonProviderState = {
  comparisonMethod: DeviceStateComparisonMethod.Default,
  setComparisonMethod: () => null,
  days: '0',
  setDays: () => null,
  timespan: '00:00:00',
  setTimeSpan: () => null,
  paramValue: '0',
  setParamValue: () => null,
};

export const ExalusComparisonContext = createContext<ExalusComparisonProviderState>(initialState);

export const useExalusComparisonContext = (): ExalusComparisonProviderState => useContext(ExalusComparisonContext);

export const ExalusComparisonContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [comparisonMethod, setComparisonMethod] = useState<DeviceStateComparisonMethod>(initialState.comparisonMethod);
  const [days, setDays] = useState<string>(initialState.days);
  const [timespan, setTimeSpan] = useState<string>(initialState.timespan);
  const [paramValue, setParamValue] = useState<string>(initialState.paramValue);

  const values: ExalusComparisonProviderState = {
    comparisonMethod,
    setComparisonMethod,
    days,
    setDays,
    timespan,
    setTimeSpan,
    paramValue,
    setParamValue,
  };

  return <ExalusComparisonContext.Provider value={values}>{children}</ExalusComparisonContext.Provider>;
};
