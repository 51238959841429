import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateBox, IconWarning } from '../../../../../../components';
import { UserAction, UserPermissionType } from '../../../../../../data-access/gql-types/graphql';
import { useUserPermissions } from '../../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../../routes';
import { ActionBox } from '../../../../components';
import { useAddDevice } from '../../../device-add/hooks/use-add-device';

type UserActionListProps = {
  list: UserAction[];
  channelListCount: number;
  isAdvanced: boolean;
};

export const UserActionList: React.FC<UserActionListProps> = ({ list, channelListCount, isAdvanced = false }) => {
  const { t } = useTranslation('action');
  const { addDevice } = useAddDevice();
  const { permissions } = useUserPermissions();

  if (!channelListCount) {
    return (
      <EmptyStateBox
        header={t('actionEmptyState.header')}
        btnText={t('actionEmptyState.missingDevice.btnText')}
        content={t('actionEmptyState.missingDevice.content')}
        icon={<IconWarning />}
        onClick={addDevice}
      />
    );
  }

  if (!list?.length) {
    if (isAdvanced) {
      return (
        <EmptyStateBox
          header={t('scenarioActionEmptyState.header')}
          content={t('scenarioActionEmptyState.missingAction.content')}
          icon={<IconWarning />}
          {...(permissions[UserPermissionType.ActionsCreate] && {
            btnText: t('scenarioActionEmptyState.missingAction.btnText'),
            linkTo: ROUTES.ActionCreate(),
          })}
        />
      );
    }
    return (
      <EmptyStateBox
        header={t('basicActionEmptyState.header')}
        content={t('basicActionEmptyState.missingAction.content')}
        icon={<IconWarning />}
        {...(permissions[UserPermissionType.ActionsCreate] && {
          btnText: t('basicActionEmptyState.missingAction.btnText'),
          linkTo: ROUTES.ActionCreateBasic(),
        })}
      />
    );
  }

  return (
    <div className="grid-list-16 p-b-24">
      {list?.map((userAction) => (
        <ActionBox
          key={userAction.action.id}
          actionData={userAction}
          actionId={userAction.action.id}
          isListItem
          showEvents={isAdvanced}
        />
      ))}
    </div>
  );
};
