import React from 'react';
import { orderBy } from 'lodash';
import { DashboardItemType, UserAction, UserGroup, UserGroupItem } from '../../../../../data-access/gql-types/graphql';
import { ActionBox, ChannelBox } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../context/devices-and-channels';

type DashboardItemsProps = {
  group: UserGroup;
  isListView: boolean;
};

export const DashboardItems: React.FC<DashboardItemsProps> = ({ group, isListView }) => {
  const { channelList } = useDevicesAndChannelsContext();

  return (
    <>
      {orderBy(group.userGroupItems, 'order').map((userItem: UserGroupItem) => {
        if (userItem) {
          const item = group.group.groupItems.find((x) => x.id === userItem.itemId);

          if (item) {
            if (item.resourceType === DashboardItemType.Channel) {
              const channel = channelList.find((device) => device && device.id === userItem.itemId);
              return channel && channel.isVisible ? (
                <ChannelBox key={userItem.itemId} channel={channel} isListItem={isListView} />
              ) : null;
            }
          }

          const newAction = group.group.groupItems.find((x) => x.id === userItem.itemId)?.payload;

          return newAction ? (
            <ActionBox
              key={userItem.itemId}
              isListItem={isListView}
              actionData={newAction as UserAction}
              actionId={userItem.itemId}
            />
          ) : null;
        }
      })}
    </>
  );
};
