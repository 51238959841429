import React from 'react';
import { DeviceConnectionState } from '../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../store/channels-state';
import { ChannelType } from '../../../types';
import ChannelControls from '../controls';
import { RecoveryInfoDetails } from '../recovery';
import { RecoveryModeWrapper } from '../recovery/wrapper';

type DetailsBodyProps = {
  channel: ChannelType;
};

const DetailsBody: React.FC<DetailsBodyProps> = ({ channel }) => {
  const state = useChannelsStateStore((s) => s.channelState[channel.id]);
  const recoveryActive = state?.deviceConnectionState === DeviceConnectionState.Recovery;

  return (
    <>
      <RecoveryInfoDetails channel={channel} />
      <RecoveryModeWrapper active={recoveryActive}>
        <ChannelControls channel={channel} />
      </RecoveryModeWrapper>
    </>
  );
};

export default DetailsBody;
