import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { IUpdatesService } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { DialogConfirmation } from '../../../../../../components';
import { useInstallationContext } from '../../../../../../context/installation';
import { ROUTES } from '../../../../../../routes';
import { diffHours } from '../../../../../../utils/helpers';
import { useExalusContext } from '../../../../context';
import * as exalusStorage from '../../../../storage';

type UpdateControllerDialogProps = {
  setUpdateControllerFinished: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UpdateControllerDialog: React.FC<UpdateControllerDialogProps> = ({ setUpdateControllerFinished }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('installation');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedInstallationId } = useInstallationContext();
  const { synchronized, connected } = useExalusContext();

  const handleSaveMemoryForInstallationId = () => {
    if (selectedInstallationId) {
      let updateControllerMemory = exalusStorage.getItem('updateControllerMemory');

      if (updateControllerMemory) {
        if (selectedInstallationId in updateControllerMemory) {
          updateControllerMemory[selectedInstallationId] = new Date();
        } else updateControllerMemory = { ...updateControllerMemory, [selectedInstallationId]: new Date() };
      } else updateControllerMemory = { [selectedInstallationId]: new Date() };

      exalusStorage.setItem('updateControllerMemory', updateControllerMemory);
    }
  };

  const checkControllerUpdateAvailability = async () => {
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result = await updateService.CheckControllerSoftwareUpdateAvailabilityAsync();

    handleSaveMemoryForInstallationId();
    if (result === true) setShowDialog(true);
    else {
      setUpdateControllerFinished(true);
    }
  };

  useEffect(() => {
    if (!selectedInstallationId || !synchronized || !connected) return;
    const updateControllerMemory = exalusStorage.getItem('updateControllerMemory');

    if (updateControllerMemory) {
      if (selectedInstallationId in updateControllerMemory) {
        if (diffHours(new Date(updateControllerMemory[selectedInstallationId]), 24)) {
          checkControllerUpdateAvailability();
        } else {
          setUpdateControllerFinished(true);
        }
      } else checkControllerUpdateAvailability();
    } else checkControllerUpdateAvailability();
  }, [selectedInstallationId, synchronized, connected]);

  const handleClose = () => {
    handleSaveMemoryForInstallationId();
    setUpdateControllerFinished(true);
    setShowDialog(false);
  };

  const handleAction = () => {
    handleClose();
    navigate(`${ROUTES.ExalusControllerSettings()}?view=updates`);
  };

  return (
    <DialogConfirmation
      show={showDialog}
      setShow={handleClose}
      header={t('exalus.updateControllerInfo.header')}
      content={t('exalus.updateControllerInfo.content')}
      primaryBtnText={t('exalus.updateControllerInfo.update')}
      secondaryBtnText={t('exalus.updateControllerInfo.skip')}
      primaryBtnAction={handleAction}
      secondaryBtnAction={handleClose}
      timerCancel
    />
  );
};
