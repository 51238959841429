import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input, InputSelect } from '../../../../../../components';
import Info from '../../../../../../components/channel-configuration/info';
import { GenericInputTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { millisecondsToTime } from '../../../../../../utils/helpers';
import { ChannelGateType, ChannelType } from '../../../../types';

type SignalTimeProps = {
  channel: ChannelType;
  handleSave: (time: string) => void;
};

const PartialSbsSignalTime: React.FC<SignalTimeProps> = ({ channel, handleSave }) => {
  const { t } = useTranslation('configuration');
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [type, setType] = useState<GenericInputTypeInternal>(GenericInputTypeInternal.Unknown);
  const [typeTemp, setTypeTemp] = useState<GenericInputTypeInternal>(GenericInputTypeInternal.Unknown);
  const channelData = channel?.data as ChannelGateType | null;
  const partialSbsTimeInMs = millisecondsToTime(channelData?.partialSbsTimeInMs || 0);

  useEffect(() => {
    setType(channelData?.partialSbsTimeInMs ? GenericInputTypeInternal.Monostable : GenericInputTypeInternal.Bistable);
  }, [channelData?.partialSbsTimeInMs]);

  const handleUpdateType = (value: GenericInputTypeInternal) => {
    switch (value) {
      case GenericInputTypeInternal.Monostable:
        setTypeTemp(value);
        setShowTimePicker(true);
        break;
      case GenericInputTypeInternal.Bistable:
        setType(value);
        handleSave('0');
        break;
    }
  };

  const handleSaveTime = (value: string) => {
    setType(typeTemp === GenericInputTypeInternal.Unknown ? type : typeTemp);
    handleSave(value);
  };

  return (
    <>
      <Info title={t('partialTime')} />
      <InputSelect
        label={t('channelType')}
        value={type}
        onChange={handleUpdateType}
        options={[
          { label: t('monoStable'), value: GenericInputTypeInternal.Monostable },
          { label: t('biStable'), value: GenericInputTypeInternal.Bistable },
        ]}
      />
      {channelData?.partialSbsTimeInMs ? (
        <Input
          defaultValue={partialSbsTimeInMs}
          value={partialSbsTimeInMs}
          required
          readOnly
          label=""
          onClickInput={() => setShowTimePicker(true)}
          endIcon={<IconEdit />}
        />
      ) : null}
      <DialogTimePicker
        header={`${t('partialTime')} (s)`}
        open={showTimePicker}
        setOpen={setShowTimePicker}
        onSave={handleSaveTime}
        time={partialSbsTimeInMs}
        showSeconds
        disableHours
        disableMinutes
        minSeconds={1}
        maxSeconds={120}
      />
    </>
  );
};

export default PartialSbsSignalTime;
