import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../../../../../../components';
import Info from '../../../../../../components/channel-configuration/info';
import { millisecondsToTime } from '../../../../../../utils/helpers';
import { ChannelGateType, ChannelType } from '../../../../types';

type SbsSignalTimeProps = {
  channel: ChannelType;
  handleSave: (time: string) => void;
};

const SbsSignalTime: React.FC<SbsSignalTimeProps> = ({ channel, handleSave }) => {
  const { t } = useTranslation('configuration');
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const sbsSignalTimeInMs = millisecondsToTime((channel?.data as ChannelGateType)?.sbsSignalTimeInMs || 0);

  return (
    <>
      <Info title={`${t('signalTime')} (s)`} />
      <Input
        defaultValue={sbsSignalTimeInMs}
        value={sbsSignalTimeInMs}
        required
        readOnly
        label=""
        onClickInput={() => setShowTimePicker(true)}
        endIcon={<IconEdit />}
      />
      <DialogTimePicker
        header={`${t('signalTime')} (s)`}
        open={showTimePicker}
        setOpen={setShowTimePicker}
        onSave={(value) => handleSave(value)}
        time={sbsSignalTimeInMs}
        showSeconds
        disableHours
        disableMinutes
        minSeconds={1}
      />
    </>
  );
};

export default SbsSignalTime;
