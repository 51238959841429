import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  IntroduceMutation,
  IntroduceMutationVariables,
  MarkActivityMutation,
  MarkActivityMutationVariables,
} from '../../data-access/gql-types/graphql';
import { INTRODUCE } from '../../data-access/mutations/invitation';
import { MARK_ACTVITY } from '../../data-access/mutations/user';
import { useNotify } from '../../hooks';
import { ChannelSubscriptions } from '../../integrations/lavva/modules/channel-subscriptions';
import * as storage from '../../integrations/lavva/storage';
import { toastError } from '../../utils/toast';

export const OverApp: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const [invitationIntroduce] = useMutation<IntroduceMutation, IntroduceMutationVariables>(INTRODUCE);
  const [markActivity] = useMutation<MarkActivityMutation, MarkActivityMutationVariables>(MARK_ACTVITY);
  useNotify();

  useEffect(() => {
    markActivity();

    if (!storage.getItem('wasIntroduced')) {
      invitationIntroduce({
        onCompleted: (res) => {
          if (res.introduce) storage.setItem('wasIntroduced', true);
          else toastError({ content: tc('errors.somethingWentWrong') });
        },
      });
    }
  }, []);

  return <ChannelSubscriptions />;
};
