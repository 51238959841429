import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { Checkbox, DialogConfirmation, Header, NavHeader, Page, SubmitButton } from '../../../../components';
import { useInstallationContext } from '../../../../context/installation';
import {
  Action,
  ActivityType,
  ChannelTypeInternal,
  EditActionMutation,
  EditActionMutationVariables,
  EditActionInput,
  UserAction,
} from '../../../../data-access/gql-types/graphql';
import { EDIT_ACTION } from '../../../../data-access/mutations/action';
import { ACTION_DETAILS } from '../../../../data-access/queries/actions';
import { useRefetchData } from '../../../../hooks/refresh-data';
import { toastSuccess } from '../../../../utils/toast';
import { ChannelIcon } from '../../components/channel';
import { useDevicesAndChannelsContext } from '../../context/devices-and-channels';
import { useApi } from '../../hooks';
import { ChannelType } from '../../types';

const ActionEditChannels: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { actionId } = useParams<{ actionId: string }>();
  const { userActions } = useApi();
  const { selectedInstallationId } = useInstallationContext();
  const { channelList } = useDevicesAndChannelsContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [action, setAction] = useState<Action>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const { refetchActions } = useRefetchData();
  const [editActionVariables, setEditActionVariables] = useState<EditActionInput>({
    installationId: selectedInstallationId,
    iconName: '',
    name: '',
    id: '',
    channels: [],
  });

  const [editAction, { loading }] = useMutation<EditActionMutation, EditActionMutationVariables>(EDIT_ACTION, {
    refetchQueries: [
      {
        query: ACTION_DETAILS,
        variables: {
          actionId,
          installationId: selectedInstallationId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: async (response) => {
      if (response.editAction?.idResponse?.id) {
        await refetchActions();
        toastSuccess({ content: t('basic.editSuccess') });
        navigate(-1);
      }
    },
  });

  useEffect(() => {
    const data = userActions?.find((userAction) => userAction.action.id === actionId) as UserAction;
    setAction(data.action);
    setEditActionVariables({
      installationId: selectedInstallationId,
      name: data.action.name,
      iconName: data.iconName,
      id: actionId,
      channels: [],
    });

    setSelectedChannels(data?.action.channelActions.map((channel) => channel.channelId || '') as string[]);
  }, [setAction, userActions, setSelectedChannels, selectedInstallationId]);

  const list = useMemo(
    () => channelList.filter((channel) => channel && channel.data.type === action?.channelType) as ChannelType[],
    [action, channelList],
  );

  useEffect(() => {
    setIsValid(selectedChannels?.length !== 0);
  }, [setIsValid, selectedChannels, action]);

  const onSubmit = (e) => {
    e.preventDefault();
    setShowSaveDialog(true);
    if (action && selectedChannels) {
      const newVariables = { ...editActionVariables };

      newVariables.channels = selectedChannels.map((channelId, index) => {
        const channel = channelList.find((channel) => channel && channel.id === channelId);
        const channelType = channel?.data.type;

        return {
          channelId,
          channelType: channelType as ChannelTypeInternal,
          order: index,
          parameter: { activityType: ActivityType.ClientControlled, value: '' },
        };
      });

      setEditActionVariables(newVariables);
    }
  };

  const onCancel = () => setShowSaveDialog(false);

  const onSave = () => {
    editAction({
      variables: {
        input: editActionVariables,
      },
    });
  };

  const handleChannelOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (checked && !selectedChannels.includes(value)) {
      const values = [...selectedChannels, value];
      setSelectedChannels(values);
    }

    if (!checked && selectedChannels.includes(value)) {
      const values = selectedChannels.filter((v) => v !== value);
      setSelectedChannels(values);
    }
  };

  if (!action) return null;

  return (
    <Page
      header={
        <>
          <NavHeader />
          <Header title={t('basicActionForm.edit')} isUnderline />
        </>
      }
    >
      <div className="action-form__devices">
        <form ref={formRef} onSubmit={onSubmit}>
          <div className="action-form__devices-header">{tc(`typesPlural.${action?.channelType}`)}</div>
          <div className="action-form__devices-list">
            {list.map((element) => (
              <Checkbox
                id={element.id}
                key={element.id}
                checked={selectedChannels.includes(element.id || '')}
                onChange={handleChannelOnChange}
              >
                <div className={classNames('action-form__devices-element', { invisible: !element.isVisible })}>
                  <div className="action-form__devices-icon">
                    <ChannelIcon channel={element} statusOn />
                  </div>
                  <span>{element.alias}</span>
                </div>
              </Checkbox>
            ))}
          </div>
          <SubmitButton disabled={!isValid} isLoading={loading} />
        </form>
        <DialogConfirmation
          show={showSaveDialog}
          setShow={setShowSaveDialog}
          header={t('confirmDialog.header')}
          primaryBtnText={tc('buttons.save')}
          secondaryBtnText={tc('buttons.cancel')}
          primaryBtnAction={onSave}
          secondaryBtnAction={onCancel}
        />
      </div>
    </Page>
  );
};

export default ActionEditChannels;
