import { DeviceConnectionState, SwitchStateChangePayload } from '../../../../../../data-access/gql-types/graphql';
import { useChannelsStateStore } from '../../../../store/channels-state';
import { ChannelSwitchStateType } from '../../../../types';

export const useLavvaDeviceSwitchUpdate = () => {
  const setChannelState = useChannelsStateStore((s) => s.setChannelState);

  const updateLavvaSwitchState = ({ channelId, state }: SwitchStateChangePayload) => {
    setChannelState(channelId, {
      deviceConnectionState: DeviceConnectionState.Connected,
      lavvaState: state,
    } as ChannelSwitchStateType);
  };

  return {
    updateLavvaSwitchState,
  };
};
