import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import { ActionType, NewActionForm } from '../../../../../types/actions';
import { useExalusCreateActionContext } from '../context';

export const useActionCreate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { actionId } = useParams<{ actionId: string }>();
  const { builder, actionType, setName, setIcon, setActionType } = useExalusCreateActionContext();

  useEffect(() => {
    const type = new URLSearchParams(search).get('type');

    if (type) setActionType(type as ActionType);
    else navigate(-1);
  }, [search]);

  const onSubmitNameIcon = (formData: NewActionForm) => {
    setName(formData.name);
    setIcon(formData.icon_name);
    builder?.SetSequenceName(formData.name);
    builder?.SetSequenceIcon(formData.icon_name);

    if (actionType === ActionType.Automation) {
      navigate(!actionId ? ROUTES.ExalusActionCreateConditions() : ROUTES.ActionEditConditions(actionId));
    } else {
      builder?.ArgumentAsActionWithEmptyCondition().AddArgument().Commit();
      navigate(!actionId ? ROUTES.ActionCreateTasks() : ROUTES.ActionEditTasks(actionId));
    }
  };

  return {
    onSubmitNameIcon,
  };
};
