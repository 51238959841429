import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISsr_4_0_0_0 } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/VersionedOptions/^4.0.0.0/ISsrVersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useBlindControl } from '../../../../components/channel/hooks/use-blind-control';
import { BlindDetailsSimpleControlButtons } from '../../../../../../components/channel-details-controls/blind-simple';
import { useTasks } from '../../../channel-list/hooks/use-tasks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type SsrDirectionsProps = {
  device: IDevice;
  channel: IDeviceChannel;
  configService: ISsr_4_0_0_0;
};

const SsrDirections: React.FC<SsrDirectionsProps> = ({ device, channel, configService }) => {
  const { t } = useTranslation('device-info');
  const { open, stop, close } = useBlindControl(channel);
  const { active } = useTasks(channel.ChannelId);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService(device);

  const changeDirections = async () => {
    turnOnBackdrop();

    const result = await configService.ChangeMovementDirectionAsync(device, channel.Number);
    handleSubmitResponse(result);
    turnOffBackdrop();
  };

  return (
    <div className="p-t-48">
      <BlindDetailsSimpleControlButtons open={open} stop={stop} close={close} active={active} />
      <SubmitButton type="button" onClick={changeDirections}>
        {t('exalus.autoCalibrationFlow.changeDirections')}
      </SubmitButton>
    </div>
  );
};

export default SsrDirections;
