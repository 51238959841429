import { IconCross, IconSadFace, IconWarning } from '../../../../../../components';
import { PHASES_COLORS } from '../../../../../../const';
import { OptimizerCurrentDataMap } from '../current/types';

export const getOptimizerCurrentState = (value: number, data?: OptimizerCurrentDataMap[]) => {
  if (data && data.length === 3) {
    if (
      Math.abs(data[2].value - data[1].value) > 20 ||
      Math.abs(data[2].value - data[0].value) > 20 ||
      Math.abs(data[1].value - data[0].value) > 20
    ) {
      return {
        color: '',
        icon: <IconSadFace />,
        className: 'error',
        label: PhaseState.Below,
      };
    }
  }

  if (value < 245) return { color: PHASES_COLORS[1], icon: null, className: 'normal', label: PhaseState.Normal };
  if (value > 251)
    return {
      color: PHASES_COLORS[4],
      icon: <IconCross />,
      className: 'error',
      label: PhaseState.Over,
    };

  return {
    color: PHASES_COLORS[3],
    icon: <IconWarning yellow />,
    className: 'warning',
    label: PhaseState.Warning,
  };
};

export const getOptimizerPowerState = (value: number) => {
  if (value > 0) {
    return {
      color: PHASES_COLORS[4],
      icon: null,
      className: '',
      label: '',
    };
  }
};
export const getOptimizerLoadState = (value: number) => {
  return {
    color: value > 0 && value <= 33 ? '' : value > 33 && value < 67 ? '#DEB342' : '#9F271F',
    icon: null,
    className: '',
    label: '',
  };
};

export enum PhaseState {
  Normal = '',
  Over = 'over',
  Warning = 'warning',
  Below = 'below',
}
