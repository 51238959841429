import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DeviceTaskType,
  DeviceType,
  IDevice,
  IDeviceTaskTypeInfo,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IdentifyDevice } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { IconButton } from '@mui/material';
import { IconAdd } from '../../../../../../components';
import { IconBulb } from '../../../../../../components/icons';
import { DeviceIcon } from '../../../../components/device/icon';
import './index.scss';

type FoundDeviceProps = {
  device: IDevice;
  registerDevice: (device: IDevice) => Promise<void>;
};

const FoundDevice: React.FC<FoundDeviceProps> = ({ device, registerDevice }) => {
  const { t } = useTranslation('common');

  const register = () => registerDevice(device);

  const canIdentify = useMemo(
    () => device.AvailableTaskTypes.find((task: IDeviceTaskTypeInfo) => task.Type === DeviceTaskType.IdentifyDevice),
    [device],
  );

  const handleIdenify = () => {
    const task = new IdentifyDevice();
    device.ExecuteDeviceTaskAsync(task);
  };

  return (
    <div className="driver m-b-24 found-driver">
      <div className={classNames('driver__header-icon')}>
        <DeviceIcon iconType={device.IconType} />
      </div>
      <h4 className="driver__header">
        <div className="driver__header-title">
          <div className="driver__header-title-name text-ellipsis">{device.Name}</div>
          <div className="driver__header-title-type">{t(`types.exalus.${DeviceType[device.DeviceType]}`)}</div>
        </div>
      </h4>
      {canIdentify && (
        <IconButton className="identify-btn" onClick={handleIdenify}>
          <IconBulb isOn />
        </IconButton>
      )}
      <IconButton className="register-btn" onClick={register}>
        <IconAdd />
      </IconButton>
    </div>
  );
};

export default FoundDevice;
