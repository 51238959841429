import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../../components';
import { InfoPageType } from '../../types';
import './index.scss';

const title = {
  UNDEFINED: '',
  CHANNEL: 'channelTest',
  INSERVICE: 'inService',
  VISIBILITY: 'visibility',
  INPUT_TYPE: 'inputType',
  CALIBRATION: 'calibration',
};

type IntegrationSettings = {
  changePage: Dispatch<SetStateAction<InfoPageType>>;
  infoPage: InfoPageType;
};

const InfoPage: React.FC<IntegrationSettings> = ({ changePage, infoPage }) => {
  const { t } = useTranslation('configuration');

  const goBack = () => changePage(InfoPageType.UNDEFINED);

  return (
    <Page
      className="info_page"
      contentClassName="page__content--flex"
      header={
        <>
          <NavHeader onClick={goBack} />
          <Header title={t(title[infoPage])} />
        </>
      }
    >
      <div>
        <Trans>{t(`helps.${infoPage}`)}</Trans>
      </div>
    </Page>
  );
};

export default InfoPage;
