import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import { IconEco } from '../../../../../../../../components';
import { OptimizerVectorParameterTypeInternal } from '../../../../../../../../data-access/gql-types/graphql';
import { OptimizerCurrentDataMap } from '../../types';
import './index.scss';

type DiagramSimpleProps = {
  optimizerData: Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]> | undefined;
};

export const DiagramSimple: FC<DiagramSimpleProps> = ({ optimizerData }) => {
  const sumPower = useMemo(() => (optimizerData ? sumBy(optimizerData.ACTIVE_POWER, 'value') : 0), [optimizerData]);
  const phasesLoad = optimizerData?.SCR_FILLING?.length ? optimizerData.SCR_FILLING : null;

  const loadExistsSumPowerIsZero = useMemo(() => {
    if (phasesLoad) {
      if (sumPower === 0 && phasesLoad.find((x) => x.value !== 0)) return true;
    }

    return false;
  }, [sumPower, phasesLoad]);

  return (
    <div className="eco-diagram-simple-container">
      {sumPower < 0 && <IconEco />}
      <svg
        width="344"
        height="148"
        className={classNames('eco-diagram-simple', {
          green: sumPower < 0 || loadExistsSumPowerIsZero,
          red: sumPower > 0,
        })}
        viewBox="0 0 344 148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 74L175 74"
          stroke="#CBCBD1"
          className={classNames('route-line', { green: sumPower < 0, red: sumPower > 0 })}
        />
        <path
          d="M175 74L340 74"
          stroke="#CBCBD1"
          className={classNames('route-line', { green: sumPower < 0 || loadExistsSumPowerIsZero })}
        />
        {(sumPower || loadExistsSumPowerIsZero) && (
          <circle
            className={classNames('energy-circle', { download: sumPower > 0, half: loadExistsSumPowerIsZero })}
            cx="0"
            cy="74"
            r="4"
            fill={sumPower < 0 || loadExistsSumPowerIsZero ? '#00B191' : '#cc0909'}
          />
        )}
        <rect
          x="16"
          y="16"
          width="88"
          height="116"
          rx="8"
          fill="white"
          className={classNames('border-1', { default: loadExistsSumPowerIsZero })}
        />
        <rect
          x="15.75"
          y="15.75"
          width="88.5"
          height="116.5"
          rx="8.25"
          stroke="#CBCBD1"
          strokeOpacity="0.5"
          strokeWidth="0.5"
        />
        <g clipPath="url(#clip0_22729_53338)">
          <path
            d="M66.1493 73.8648H54.2495L57.7286 48H62.6703L66.1493 73.8648Z"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.2502 73.8662L43.5483 96.3042H45.9687L60.1984 80.598"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M66.1494 73.8662L76.8513 96.3042H74.4309L60.1978 80.598"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.3418 80.5977H69.0575"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M39 63.71H81.3999" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M81.3994 63.71L66.1494 73.865"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.25 73.865L39 63.71"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.5942 63.7103L45.2476 56.8184"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M39 68.0388V63.71" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M81.3999 68.0388V63.71"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.2476 56.8184H60.1981"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.2476 56.8184V59.8691"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M64.8052 63.7103L75.1518 56.8184"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75.1518 56.8184H60.1978"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75.1523 56.8184V59.8691"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        {sumPower !== null && (
          <text x="35" y="122" fill="#55555C">
            {`${Math.abs(sumPower / 1000).toFixed(1)} kW`}
          </text>
        )}
        {sumPower && (
          <path
            d={
              sumPower < 0
                ? 'M91.6892 115.426L88.2011 111.938L84.7129 115.426'
                : 'M91.6892 118.426L88.2011 122.938L84.7129 118.426'
            }
            stroke={sumPower < 0 ? '#00B191' : '#cc0909'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        {sumPower && (
          <path
            d="M88.2012 112.326V121.046"
            stroke={sumPower < 0 ? '#00B191' : '#cc0909'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        <rect x="135.5" y="16" width="88" height="116" rx="8" fill="white" className="border-2" />
        <rect
          x="135.25"
          y="15.75"
          width="88.5"
          height="116.5"
          rx="8.25"
          stroke="#CBCBD1"
          strokeOpacity="0.5"
          strokeWidth="0.5"
        />
        <g clipPath="url(#clip1_22729_53338)">
          <path
            d="M152.5 70.489L179.5 48L206.5 70.489"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M193.436 52.3244V49.7295H202.145V60.109"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M156.855 67.1091V96.4377H165.565V80.5801H176.016V96.4377H202.145V67.1091"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M193.667 69.4314H183.215V79.8109H193.667V69.4314Z"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>

        <rect x="255" y="16" width="88" height="116" rx="8" fill="white" className="border-3" />
        <rect
          x="254.75"
          y="15.75"
          width="88.5"
          height="116.5"
          rx="8.25"
          stroke="#CBCBD1"
          strokeOpacity="0.5"
          strokeWidth="0.5"
        />
        <path
          d="M299 99.9998H273.8C272.769 99.9998 271.933 99.1641 271.933 98.1331V95.4026"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299 99.9998H324.2C325.23 99.9998 326.066 99.1641 326.066 98.1331V97.7012V95.4026"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M278.664 75.9873L271.933 95.2481H326.067L319.336 75.9873H278.664Z"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.503 95.2481L288.827 75.9873"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M299 95.2481V75.9873" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M312.497 95.2481L309.173 75.9873"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M321.055 80.9094H276.944"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M323.518 87.9548H274.482"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M298.95 62.4216C301.857 62.4216 304.213 60.0651 304.213 57.1583C304.213 54.2515 301.857 51.895 298.95 51.895C296.043 51.895 293.687 54.2515 293.687 57.1583C293.687 60.0651 296.043 62.4216 298.95 62.4216Z"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M298.95 46.3907V44" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M298.95 66.9807V69.3715"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M309.513 56.6858H311.966"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M288.386 56.6858H285.933"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M306.419 63.9651L308.154 65.6563"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.481 49.4061L289.746 47.7148"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M306.419 49.4061L308.154 47.7148"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.481 63.9651L289.746 65.6563"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
