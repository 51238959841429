import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import { useInstallationContext } from '../../../../../context/installation';
import { ExalusInstallationPayload } from '../../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../../routes';
import { useExalusContext } from '../../../context';
import { removeExalusCredentials } from '../../../utils';
import FormInput from '../components/form-input';
import { useUsers } from '../hooks/use-users';
import { ChangePasswordForm } from '../types';

const ExalusChangePassword: React.FC = () => {
  const { t } = useTranslation('profile');
  const { changePassword } = useUsers();
  const { selectedInstallation } = useInstallationContext();
  const { selectedExalusCredentials } = useExalusContext();
  const form = useForm<ChangePasswordForm>({
    defaultValues: {
      Password: '',
      PasswordConfirm: '',
    },
  });

  const { Password } = form.watch();

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      await changePassword(values);

      if (selectedExalusCredentials) {
        removeExalusCredentials(selectedExalusCredentials?.id);
      }

      setTimeout(() => {
        window.location.href = ROUTES.ExalusLogin(
          (selectedInstallation?.externalPayload as ExalusInstallationPayload).serialNumber,
          (selectedInstallation?.externalPayload as ExalusInstallationPayload).pin,
          selectedInstallation?.installationId,
        );
      }, 2000);
    } catch (e) {
      window.location.reload();
    }
  });

  return (
    <Page
      className="exalus-user-detail"
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.changePassword')} isUnderline />
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <FormInput name="Password" label={t('exalus.password')} inputType="password" />
          <FormInput
            name="PasswordConfirm"
            label={t('exalus.passwordConfirm')}
            inputType="password"
            theSame={Password}
          />
          <SubmitButton />
        </form>
      </FormProvider>
    </Page>
  );
};

export default ExalusChangePassword;
