import React from 'react';
import { useTranslation } from 'react-i18next';
import { FromToInputForm, Header } from '../../../../../../../../components';
import { useOptimizerConfigurationContext } from '../../../context/configuration-context';

export const WorkingHours: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { errors } = useOptimizerConfigurationContext();

  return (
    <>
      <Header title={t('optimizer.configuration.workingHours')} className="m-b-16" />
      <FromToInputForm fromError={errors.from} toError={errors.to} />
    </>
  );
};
