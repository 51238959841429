import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StatusScreen } from '../../components';
import { ROUTES } from '../../routes';
import { StatusTypeEnum } from '../../types';

const InvitationReceivedConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('invitation');

  return (
    <StatusScreen
      header={t('confirmation.title')}
      secondaryBackground
      type={StatusTypeEnum.WAIT}
      primaryBtnText={t('confirmation.action')}
      onPrimaryBtnClick={() => navigate(ROUTES.Root(), { replace: true })}
    />
  );
};

export default InvitationReceivedConfirmation;
