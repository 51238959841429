import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import {
  Checkbox,
  CustomBackdrop,
  DialogConfirmation,
  EmptyStateBox,
  Header,
  IconToastError,
  IconToastSuccess,
  IconWarning,
  InputPassword,
  InputSelect,
  NavHeader,
  Page,
  SubmitButton,
} from '../../../../components';
import { IconSynchronize } from '../../../../components/icons/icon-synchronize';
import DeviceListItem from './components/device-list-item';
import { useWifiConfiguration } from './hooks/use-wifi-configuration';
import './index.scss';

const DeviceWifiConfiguration: React.FC = () => {
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const {
    networkList,
    isLoading,
    availableDevices,
    popup,
    selectedNetwork,
    form,
    deviceConnectingPopup,
    selectedDevicesCount,
    connectedList,
    setConnectedList,
    setAvailableDevices,
    setDeviceConnectingPopup,
    handleSelectNetwork,
    toggleChecked,
    setPopup,
    onSubmit,
    handleRefresh,
    unselectAllDevices,
  } = useWifiConfiguration();

  const handleCloseConnectingPopup = () => {
    setConnectedList([]);
    setDeviceConnectingPopup(false);
    unselectAllDevices();
  };

  const handleToggleAll = () => {
    const allChecked = availableDevices.filter((x) => x.ssid !== selectedNetwork).every((x) => x.checked);

    if (!allChecked) {
      setAvailableDevices((prev) => prev.map((x) => ({ ...x, checked: x.ssid !== selectedNetwork })));
    } else {
      setAvailableDevices((prev) => prev.map((x) => ({ ...x, checked: false })));
    }
  };

  return (
    <Page
      className="wifi-configuration"
      header={
        <>
          <NavHeader />
          <Header title={t('wifiConfigurattion.label')} isUnderline>
            <button className="refresh-btn" onClick={handleRefresh}>
              <IconSynchronize />
            </button>
          </Header>
        </>
      }
    >
      {networkList.length ? (
        <div className="max-width-desktop">
          <InputSelect
            onChange={handleSelectNetwork}
            value={selectedNetwork}
            options={networkList}
            label={t('wifiConfigurattion.destinationNetwork')}
          />
          {availableDevices.length ? (
            <>
              <hr className="m-b-24" />
              <div className="all-checkbox">
                <Checkbox
                  checked={availableDevices.filter((x) => x.ssid !== selectedNetwork).every((x) => x.checked)}
                  onChange={handleToggleAll}
                />
              </div>
            </>
          ) : null}
          <div className="available-devices">
            {availableDevices.map((device) => (
              <DeviceListItem
                key={device.deviceId}
                device={device}
                setConnectedList={setConnectedList}
                toggleChecked={toggleChecked}
                setAvailableDevices={setAvailableDevices}
                selectedNetwork={selectedNetwork}
              />
            ))}
          </div>
          <SubmitButton onClick={() => setPopup(true)} disabled={!selectedDevicesCount}>
            {tc('buttons.next')}
          </SubmitButton>
        </div>
      ) : (
        <>{!isLoading && <EmptyStateBox content={t('wifiConfigurattion.empty')} icon={<IconWarning />} />}</>
      )}

      <CustomBackdrop loading={isLoading} />
      <DialogConfirmation
        show={popup}
        setShow={setPopup}
        header={selectedNetwork}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnAction={() => setPopup(false)}
        primaryBtnAction={form.handleSubmit(onSubmit)}
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Controller
              name="password"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: tc('isRequired'),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <InputPassword
                  defaultValue={field.value}
                  label={t('wifiConfigurattion.password')}
                  onChange={field.onChange}
                  name="password"
                  hasError={!!error}
                  reverse
                  {...(error ? { errorMessage: error.message } : {})}
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogConfirmation>
      <DialogConfirmation
        show={deviceConnectingPopup}
        setShow={handleCloseConnectingPopup}
        header={`${t('wifiConfigurattion.connecting', { ssid: selectedNetwork })}`}
        primaryBtnText={tc('buttons.close')}
        primaryBtnAction={handleCloseConnectingPopup}
        primaryBtnDisabled={availableDevices.filter((x) => !x.connected).length > 0}
      >
        <div className="connected-devices">
          {availableDevices
            .filter((x) => x.checked)
            .map((x) => (
              <div className="connected-item" key={x.deviceId}>
                <div className="status-icon">
                  {!x.connected ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : connectedList.includes(x.deviceId) ? (
                    <IconToastSuccess />
                  ) : (
                    <IconToastError />
                  )}
                </div>
                <span>{`${x.deviceName} ${x.ssid ? `${x.connected ? `(${x.ssid})` : ''}` : ''}`}</span>
              </div>
            ))}
        </div>
      </DialogConfirmation>
    </Page>
  );
};

export default DeviceWifiConfiguration;
