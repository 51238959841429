import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import omitDeep from 'omit-deep';
import { AlertConfigValue } from '../../../../api/gate/gate.types';
import {
  DialogTimePicker,
  Header,
  IconEdit,
  Input,
  NavHeader,
  Page,
  SubmitButton,
  Switch,
} from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { GateChannelAlertConfigResponse } from '../../../../../../data-access/gql-types/graphql';
import { ChannelGateType, ChannelType } from '../../../../types';
import { millisecondsToTime, timeToMilliseconds } from '../../../../../../utils/helpers';
import { pathnameWithParameters } from '../../../../../../utils/location';

type AlertConfigProps = {
  channel: ChannelType;
  handleSave: (values: AlertConfigValue) => void;
};

const AlertConfig: React.FC<AlertConfigProps> = ({ channel, handleSave }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('configuration');
  const [configurationVisible, setConfigurationVisible] = useState<boolean>(false);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [barrierEnabled, setBarrierEnabled] = useState<boolean>(false);
  const [misdirectionEnabled, setMisdirectionEnabled] = useState<boolean>(false);
  const [externalSourceEnabled, setExternalSourceEnabled] = useState<boolean>(false);
  const [time, setTime] = useState<string>(millisecondsToTime(0));

  useEffect(() => {
    const configuration = new URLSearchParams(search).get('barrier-alert');
    setConfigurationVisible(!!configuration);
  }, [search]);

  useEffect(() => {
    const config = (channel?.data as ChannelGateType)?.alertConfig;

    if (config) {
      setBarrierEnabled(config.barrierAlertEnabled);
      setMisdirectionEnabled(config.misdirectionAlertEnabled);
      setExternalSourceEnabled(config.externalSourceTriggeredAlertEnabled);
      setTime(millisecondsToTime(config?.openedIntervalToNotifyInMs || 0));
    }
  }, [(channel?.data as ChannelGateType)?.alertConfig]);

  const openConfiguration = () => {
    navigate(pathnameWithParameters(pathname, search, { key: 'barrier-alert', value: true }));
  };

  const handleSubmit = () => {
    handleSave({
      barrierAlertEnabled: barrierEnabled,
      misdirectionAlertEnabled: misdirectionEnabled,
      externalSourceTriggeredAlertEnabled: externalSourceEnabled,
      openedIntervalToNotifyInMs: timeToMilliseconds(time),
    });
  };

  const btnDisabled = useMemo(() => {
    const original = omitDeep(cloneDeep((channel?.data as ChannelGateType)?.alertConfig), ['__typename']);

    const newData: GateChannelAlertConfigResponse = {
      barrierAlertEnabled: barrierEnabled,
      externalSourceTriggeredAlertEnabled: externalSourceEnabled,
      misdirectionAlertEnabled: misdirectionEnabled,
      openedIntervalToNotifyInMs: timeToMilliseconds(time),
    };

    return isEqual(original, newData);
  }, [
    (channel?.data as ChannelGateType)?.alertConfig,
    barrierEnabled,
    misdirectionEnabled,
    externalSourceEnabled,
    time,
  ]);

  return (
    <>
      {!configurationVisible ? (
        <ArrowButton title={t('barrierAlert.label')} className="m-b-12" onClick={openConfiguration} />
      ) : (
        <Page
          kind="above"
          header={
            <>
              <NavHeader />
              <Header title={t('barrierAlert.label')} />
            </>
          }
        >
          <div className="row_container m-b-24">
            <p>{t('barrierAlert.barrierAlertEnabled')}</p>
            <Switch checked={barrierEnabled} onChange={() => setBarrierEnabled(!barrierEnabled)} />
          </div>
          <div className="row_container m-b-24">
            <p>{t('barrierAlert.misdirectionAlertEnabled')}</p>
            <Switch checked={misdirectionEnabled} onChange={() => setMisdirectionEnabled(!misdirectionEnabled)} />
          </div>
          <div className="row_container m-b-24">
            <p>{t('barrierAlert.externalSourceTriggeredAlertEnabled')}</p>
            <Switch checked={externalSourceEnabled} onChange={() => setExternalSourceEnabled(!externalSourceEnabled)} />
          </div>
          <Input
            defaultValue={time}
            value={time}
            required
            readOnly
            label={t('barrierAlert.alertTime')}
            onClickInput={() => setShowTimePicker(true)}
            endIcon={<IconEdit />}
          />
          <DialogTimePicker
            header={`${t('barrierAlert.alertTime')}`}
            open={showTimePicker}
            setOpen={setShowTimePicker}
            onSave={(value) => setTime(value)}
            time={time}
            showSeconds
          />
          <SubmitButton disabled={btnDisabled} type="button" onClick={handleSubmit} />
        </Page>
      )}
    </>
  );
};

export default AlertConfig;
