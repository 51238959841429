import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, dragOverlayStyle, Header, NavHeader, Page, SubmitButton } from '../../../../../../components';
import { RgbColorListItem } from '../../components/color-list-item';
import { RgbSceneDetails } from '../../components/scene-details';
import { useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';
import { RgbColorListItemSortable } from './components/color-list-item-sortable';
import './index.scss';

export const RgbSceneChangeOrder: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { setSelectedView, colors, setColors } = useRgbSceneFormContext();
  const [tempColors, setTempColors] = useState(colors);

  const [dndActiveId, setDndActiveId] = useState<string | null>(null);

  const onDragStart = (event: dndCore.DragStartEvent) => setDndActiveId(event.active.id);

  const onDragEnd = (event: dndCore.DragEndEvent) => {
    if (!event.active || !event.over) {
      return;
    }

    if (event.active.id !== event.over.id && tempColors) {
      const newList = Array.from(tempColors);
      const oldIndex = newList.findIndex((color) => color.id === event.active.id);
      const newIndex = newList.findIndex((color) => color.id === event.over?.id);

      const sortedGroups = dndSortable.arrayMove(newList, oldIndex, newIndex);
      setTempColors(sortedGroups);
    }

    setDndActiveId(null);
  };

  const onSubmit = () => {
    setColors(tempColors);
    setSelectedView(RgbSceneFormView.LIST);
  };

  return (
    <Page
      header={
        <>
          <NavHeader onClick={() => setSelectedView(RgbSceneFormView.LIST)} />
          <Header title={t('rgbScenes.changeOrder')} />
        </>
      }
      containerClassName={'p-t-0 p-r-0 p-b-0 p-l-0'}
      headerClassName={'p-t-24 p-r-24 p-b-0 p-l-24'}
      contentClassName={'p-r-24 p-b-24 p-l-24 page__content--background-element page__content--scrollable'}
    >
      <div className="rgb-scene-form-change-order">
        <RgbSceneDetails colors={tempColors} />

        <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={tempColors}>
          {tempColors.map((color) => (
            <RgbColorListItemSortable color={color} key={color.id} />
          ))}
          <dndCore.DragOverlay>
            {dndActiveId && <RgbColorListItem style={dragOverlayStyle} id={dndActiveId} isDraggable />}
          </dndCore.DragOverlay>
        </DragAndDrop>
      </div>
      <SubmitButton backgroundElement type={'button'} onClick={onSubmit}>
        {tc('buttons.save')}
      </SubmitButton>
    </Page>
  );
};
