import React, { ChangeEvent } from 'react';
import { Switch } from '../../../../../../components/switch';
import { useLight } from '../../../../hooks';
import { ChannelType } from '../../../../types';

type LightControlProps = {
  channel: ChannelType;
};

export const LightControl: React.FC<LightControlProps> = ({ channel }) => {
  const { handleControlOn: onLight, isOn: isOnLight } = useLight(channel);

  const handleControl = (e: ChangeEvent<HTMLInputElement>) => onLight(e.target.checked);

  return <Switch checked={isOnLight} onChange={handleControl} />;
};
