import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExalusStorageContext } from '../../../integrations/exalus/context/storage';
import { toastSuccess } from '../../../utils/toast';

export const useChatSettings = () => {
  const { t } = useTranslation('profile');
  const { readingChat, toggleReadingChat } = useExalusStorageContext();

  const options = [
    { value: 'true', label: t('reading-chat.on') },
    { value: 'false', label: t('reading-chat.off') },
  ];

  const defaultOptionIndex = useMemo(() => {
    const optionIndex = options.findIndex((option) => (option.value === 'true') === readingChat);
    return optionIndex < 0 ? 0 : optionIndex;
  }, [options, readingChat]);

  const handleChange = (vibrationValue: string) => {
    toggleReadingChat();
    toastSuccess({ content: t(`reading-chat.reading-${vibrationValue === 'true' ? 'on' : 'off'}`) });
  };

  return {
    options,
    defaultOptionIndex,
    handleChange,
  };
};
