import { useExalusServicesContext } from '../context/services';
import { useLoggedUser } from './common/logged-user';

export type ExalusLocalStorage = {
  isDashboardGridView: boolean;
  isChannelsListView: boolean;
  activeGroupIndex: number;
  activeChannelTabIndex: number;
  readingChat: boolean;
  iconName: string;
  hexColor: string;
  name: string;
};

export const defaultState: ExalusLocalStorage = {
  isDashboardGridView: true,
  isChannelsListView: false,
  activeGroupIndex: 0,
  activeChannelTabIndex: 0,
  readingChat: false,
  iconName: '',
  hexColor: '',
  name: '',
};

export const useExalusLocalStorage = () => {
  const { storageApi } = useExalusServicesContext();
  const { user } = useLoggedUser();

  const saveExalusDataStorage = <Key extends keyof ExalusLocalStorage>(key: Key, value: ExalusLocalStorage[Key]) => {
    if (user) storageApi.Save(user.Guid, key, value);
  };

  const readExalusDataStorage = <T,>(key: keyof ExalusLocalStorage): T | null => {
    return user ? storageApi.Read(user.Guid, key) : null;
  };

  const removeExalusDataStorage = <Key extends keyof ExalusLocalStorage>(key: Key) => {
    if (user) storageApi.Remove(user.Guid, key);
  };

  return {
    saveExalusDataStorage,
    readExalusDataStorage,
    removeExalusDataStorage,
  };
};
