import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { dataPickerFormat } from '../../../../../../../components';
import { CheckboxesOptions } from '../../../../../../../components/checkbox-select';
import { PHASES_COLORS } from '../../../../../../../const';
import {
  AggregateVectorParameterTypeInternal,
  DayEntryResponse,
  MeasurementDataRangeInternal,
  TariffKindInternal,
} from '../../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../../types';
import { convertDateToFormat, convertDateToString } from '../../../../../../../utils/helpers';
import { TariffPricesData } from '../../common/types';
import { AvailableLongFormatEnum, AvailableShortFormatEnum, ChartDataItem, ChartDataValues } from '../types';
import {
  clearMergeParameters,
  getMonday,
  getPricingTier,
  getWeekDate,
  noPredictionValues,
  getParameterColor,
} from './helpers';

const currentFormat = (longFormat: boolean, activeMeasurementRange: MeasurementRange) => {
  switch (activeMeasurementRange) {
    case MeasurementRange.DayInHours:
      return longFormat ? AvailableLongFormatEnum.HOURLY : AvailableShortFormatEnum.HOURLY;
    case MeasurementRange.WeekInDays:
      return longFormat ? AvailableLongFormatEnum.WEEKLY : AvailableShortFormatEnum.WEEKLY;
    case MeasurementRange.MonthInDays:
      return longFormat ? AvailableLongFormatEnum.MONTHLY : AvailableShortFormatEnum.MONTHLY;
    case MeasurementRange.YearInMonths:
      return longFormat ? AvailableLongFormatEnum.YEARLY : AvailableShortFormatEnum.YEARLY;
    case MeasurementRange.TenYearsInYears:
      return longFormat ? AvailableLongFormatEnum.TEN_YEARS : AvailableShortFormatEnum.TEN_YEARS;
    default:
      return '';
  }
};

const daysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate();

const getPreviousMonthAndYearFromX = (lastXMonths: number) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - lastXMonths);

  const previousMonth = currentDate.getMonth() + 1;
  const previousYear = currentDate.getFullYear();

  return {
    previousMonth,
    previousYear,
  };
};

const mergePrediction = (data: ChartDataItem[], result: ChartDataItem[], activeMeasurementRange: MeasurementRange) => {
  const tempResult = cloneDeep(result);
  let futurePredictionValues = data.filter((x) => !x.values.find((y) => noPredictionValues.includes(y.type)));

  if (activeMeasurementRange === MeasurementRange.WeekInDays) {
    if (futurePredictionValues.length) {
      futurePredictionValues = futurePredictionValues.slice(0, 7 - new Date().getDay());
    } else {
      const temp = data
        .slice(-8)
        .slice(0, 7)
        .map((x) => ({
          ...x,
          values: x.values.filter((y) => !noPredictionValues.includes(y.type)),
        }));
      futurePredictionValues = [...temp];
    }
  }

  if (futurePredictionValues.length > result.length) return [...tempResult];

  let resultIndex = tempResult.length - 1;

  for (let i = futurePredictionValues.length - 1; i >= 0; i--) {
    tempResult[resultIndex] = {
      values: tempResult[resultIndex].values
        .filter((x) => !clearMergeParameters.includes(x.type))
        .concat(futurePredictionValues[i].values),
    };
    resultIndex--;
  }

  return [...tempResult];
};

const addTariffPrices = (result: ChartDataItem[], tariffPricesConfiguration: TariffPricesData) => {
  return result.map((x, index) => {
    const pricingTier = getPricingTier(index, tariffPricesConfiguration.hours);

    return {
      ...x,
      values: [
        ...x.values,
        {
          type: AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
          index: 0,
          value: tariffPricesConfiguration.prices[pricingTier],
          color: getParameterColor(AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice),
          empty: false,
        },
      ],
    };
  });
};

const getDynamicPricesByDate = (date: number, dynamicPrices: DayEntryResponse[]) => {
  const currentDate = format(new Date(date), 'yyyy-MM-dd');
  return dynamicPrices.find((x) => x.day === currentDate)?.records || [];
};

const addDynamicPrices = (result: ChartDataItem[], dynamicPrices: DayEntryResponse[], date: number) => {
  const priceRecords = getDynamicPricesByDate(date, dynamicPrices);

  if (priceRecords) {
    return result.map((x, index) => {
      const price = priceRecords.find((p) => p.startingHour === index)?.priceInkWh;

      return {
        ...x,
        values: [
          ...x.values,
          {
            type: AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
            index: 0,
            value: price || 0,
            color: getParameterColor(AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice),
            empty: !price,
          },
        ],
      };
    });
  }

  return [...result];
};

export const getActiveBarParameters = () => {
  const activeTooltipCursor = document.querySelector('.recharts-rectangle.recharts-tooltip-cursor');

  if (activeTooltipCursor) {
    const x = Array.from(activeTooltipCursor.attributes).find((x) => x.name === 'x');
    const height = Array.from(activeTooltipCursor.attributes).find((x) => x.name === 'height');
    const width = Array.from(activeTooltipCursor.attributes).find((x) => x.name === 'width');

    return {
      x: x ? parseFloat(x.value) : 0,
      height: height ? parseFloat(height.value) : 0,
      width: width ? parseFloat(width.value) : 0,
    };
  }

  return { height: 0, width: 0, x: 0 };
};

export const predictionRangesAvailable = [MeasurementDataRangeInternal.InHours, MeasurementDataRangeInternal.InDays];

export const getAggregateColor = (type: AggregateVectorParameterTypeInternal, index: number) => {
  if (
    [
      AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
      AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
    ].includes(type)
  ) {
    return getParameterColor(type);
  }

  return index ? PHASES_COLORS[index] : getParameterColor(type);
};

export const getDataByRange = (
  activeMeasurementRange: MeasurementRange,
  mappedChartData: ChartDataItem[],
  page: number,
  kind: TariffKindInternal | undefined,
  tariffPricesConfiguration: TariffPricesData | null,
  dynamicPrices: DayEntryResponse[],
  aggregatedParameters: CheckboxesOptions<AggregateVectorParameterTypeInternal>[],
) => {
  const data: ChartDataItem[] = cloneDeep(mappedChartData).map((x) => ({
    ...x,
    values: x.values.map((y) => ({ ...y, empty: false })),
  }));
  const currentPage = Math.abs(page);
  let prevAvailable = false;
  let result: ChartDataItem[] = [];
  let range = '';
  let parameters: { type: AggregateVectorParameterTypeInternal; index: number }[] = [];
  const priceSelected = !!aggregatedParameters.find(
    (x) => x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
  )?.checked;
  const onlyPricesSelected = aggregatedParameters.filter((x) => x.checked).length === 1 && priceSelected;

  const chartData = data.filter((x) =>
    x.values.find((y) =>
      [
        AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
        AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
        AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
        AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
        AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
        AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
      ].includes(y.type),
    ),
  );

  if (chartData.length)
    parameters = chartData[0].values.map((x) => ({
      type: x.type,
      index: x.index,
    }));

  switch (activeMeasurementRange) {
    case MeasurementRange.DayInHours: {
      let hour = new Date().getHours();
      if (hour === 0) hour = 24;

      result = new Array(24).fill({
        values: parameters.map((param) => ({
          type: param.type,
          value: 0,
          color: getAggregateColor(param.type, param.index),
          empty: true,
          index: param.index,
        })),
      });

      if (currentPage === 0) {
        if (!onlyPricesSelected) {
          result =
            hour !== 24
              ? [...chartData.slice(-(hour + 1)), ...result.slice(-(24 - (hour + 1)))]
              : [...chartData.slice(-hour)];
          prevAvailable = true;

          result = mergePrediction(data, result, activeMeasurementRange);
        }
      } else {
        if (!onlyPricesSelected) {
          chartData.splice(-(hour + 1 + (currentPage - 1) * 24));
          const length = chartData.slice(-24).length;
          result =
            length < 24
              ? [...result.slice(-Math.abs(24 - length)), ...chartData.slice(-length)]
              : [...chartData.slice(-length)];
          prevAvailable = length >= 24 && hour !== 24;
        }
      }

      result = [...result].map((x, i) => {
        const date = new Date();
        date.setHours(i);
        date.setMinutes(0);
        date.setDate(date.getDate() - currentPage);

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d = Array.from(result).pop()?.date;

      if (d) {
        range = `${convertDateToFormat(new Date(d - currentPage), dataPickerFormat[activeMeasurementRange].format[0])}`;

        if (onlyPricesSelected && kind && kind !== TariffKindInternal.None) {
          if (kind.includes('_DYNAMIC')) {
            const oneDayMs = 86400000;
            prevAvailable = !!getDynamicPricesByDate(d - oneDayMs, dynamicPrices).length;
          } else {
            prevAvailable = true;
          }
        }
      }

      if (priceSelected && kind && kind !== TariffKindInternal.None) {
        if (
          [
            TariffKindInternal.EonDynamic,
            TariffKindInternal.PgeDynamic,
            TariffKindInternal.EneaDynamic,
            TariffKindInternal.EnergaDynamic,
            TariffKindInternal.TauronDynamic,
          ].includes(kind)
        ) {
          if (d && dynamicPrices.length) {
            result = addDynamicPrices(result, dynamicPrices, d);
          }
        } else {
          if (tariffPricesConfiguration) {
            result = addTariffPrices(result, tariffPricesConfiguration);
          }
        }
      }

      break;
    }
    case MeasurementRange.WeekInDays: {
      const dayOfWeek = new Date().getDay();

      result = new Array(7).fill({
        values: parameters.map((param) => ({
          type: param.type,
          value: 0,
          color: getAggregateColor(param.type, param.index),
          empty: true,
          index: param.index,
        })),
      });

      if (currentPage === 0) {
        result =
          dayOfWeek !== 7
            ? [...chartData.slice(-dayOfWeek), ...result.slice(-(7 - dayOfWeek))]
            : [...chartData.slice(-dayOfWeek)];
        prevAvailable = true;

        result = mergePrediction(data, result, activeMeasurementRange);
      } else {
        chartData.splice(-(dayOfWeek + (currentPage - 1) * 7));
        const length = chartData.slice(-7).length;
        result =
          length < 7
            ? [...result.slice(-Math.abs(7 - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = length >= 7;
      }

      result = [...result].map((x, i) => {
        const monday = getMonday();
        const date = getWeekDate(new Date(monday.getTime() - currentPage * 7 * 24 * 60 * 60 * 1000), i);

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d1 = Array.from(result).shift()?.date;
      const d2 = Array.from(result).pop()?.date;

      if (d1 && d2)
        range = `${convertDateToFormat(
          new Date(d1),
          dataPickerFormat[activeMeasurementRange].format[0],
        )} - ${convertDateToFormat(new Date(d2), dataPickerFormat[activeMeasurementRange].format[0])}`;

      break;
    }
    case MeasurementRange.MonthInDays: {
      const day = new Date().getDate();
      const daysQuantity = daysInMonth(new Date().getMonth() + 1, new Date().getFullYear());

      result = new Array(daysQuantity).fill({
        values: parameters.map((param) => ({
          type: param.type,
          value: 0,
          color: getAggregateColor(param.type, param.index),
          empty: true,
          index: param.index,
        })),
      });

      if (currentPage === 0) {
        result =
          day !== daysQuantity
            ? [...chartData.slice(-day), ...result.slice(-(daysQuantity - day))]
            : [...chartData.slice(-day)];
        prevAvailable = true;

        result = mergePrediction(data, result, activeMeasurementRange);
      } else {
        let sum = day;

        if (currentPage > 1) {
          for (let i = 1; i < currentPage; i++) {
            const prev = getPreviousMonthAndYearFromX(i);
            const quantity = daysInMonth(prev.previousMonth, prev.previousYear);
            sum += quantity;
          }
        }

        chartData.splice(-sum);
        const prev = getPreviousMonthAndYearFromX(currentPage);
        const quantity = daysInMonth(prev.previousMonth, prev.previousYear);
        const length = chartData.slice(-quantity).length;

        result =
          length < quantity
            ? [...result.slice(-Math.abs(quantity - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = sum + quantity < 62;
      }

      result = [...result].map((x, i) => {
        const date = new Date();
        date.setDate(i + 1);

        if (currentPage !== 0) {
          const prev = getPreviousMonthAndYearFromX(currentPage);
          date.setMonth(prev.previousMonth - 1);
          date.setFullYear(prev.previousYear);
        }

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d = Array.from(result).pop()?.date;

      if (d) range = convertDateToFormat(new Date(d), dataPickerFormat[activeMeasurementRange].format[0]);

      break;
    }
    case MeasurementRange.YearInMonths: {
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();

      result = new Array(12).fill({
        values: parameters.map((param) => ({
          type: param.type,
          value: 0,
          color: getAggregateColor(param.type, param.index),
          empty: true,
          index: param.index,
        })),
      });

      if (currentPage === 0) {
        result =
          month !== 12 ? [...chartData.slice(-month), ...result.slice(-(12 - month))] : [...chartData.slice(-month)];
        prevAvailable = month !== 12;
      } else {
        chartData.splice(-(month + (currentPage - 1) * 12));
        const length = chartData.slice(-12).length;
        result =
          length < 12
            ? [...result.slice(-Math.abs(12 - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = length >= 12 && month !== 12;
      }

      result = [...result].map((x, i) => {
        const date = new Date();
        date.setMonth(i);
        date.setFullYear(year - currentPage);

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d = Array.from(result).pop()?.date;

      if (d) range = `${new Date(d).getFullYear()}`;

      break;
    }
    case MeasurementRange.TenYearsInYears: {
      result = [...mappedChartData].map((x, i) => {
        const date = new Date().setFullYear(new Date().getFullYear() - (9 - i));

        return {
          ...x,
          time: convertDateToString(date, currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date, currentFormat(false, activeMeasurementRange)),
          date,
        };
      });

      const d1 = Array.from(result).shift()?.date;
      const d2 = Array.from(result).pop()?.date;

      if (d1 && d2)
        range = `${convertDateToFormat(
          new Date(d1),
          dataPickerFormat[activeMeasurementRange].format[0],
        )} - ${convertDateToFormat(new Date(d2), dataPickerFormat[activeMeasurementRange].format[0])}`;
      break;
    }
  }

  return {
    result,
    prevAvailable: !onlyPricesSelected ? Boolean(mappedChartData.length && prevAvailable) : prevAvailable,
    nextAvailable: !onlyPricesSelected ? Boolean(mappedChartData.length && page < 0) : page < 0,
    range,
  };
};

export const prepareTooltipPayload = (values: ChartDataValues[]) => {
  const newValues = [...values];
  const prices = newValues.find((x) => x.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice);
  if (!prices) return newValues;

  newValues
    .filter((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy && [1, 2, 3].includes(x.index))
    .map((x) => {
      newValues.push({ ...prices, index: x.index });
    });

  return newValues;
};
