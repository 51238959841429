import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetMeterPurpose } from '../../../../api/meter/meter.hooks';
import { PurposeValueRequest } from '../../../../api/meter/meter.types';
import { InputSelect } from '../../../../../../components';
import { MeterPurposeInternal } from '../../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useDevicesAndChannelsContext } from '../../../../context/devices-and-channels';
import { ChannelMeterType } from '../../../../types';
import Info from '../../../../../../components/channel-configuration/info';

type MeterPurposeProps = {
  setTimeoutError: () => void;
};

const MeterPurpose: React.FC<MeterPurposeProps> = ({ setTimeoutError }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelId: string }>();
  const { channel } = useDevicesAndChannelsContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate } = useSetMeterPurpose();

  const onChangePurpose = (value: MeterPurposeInternal) => {
    if (!channelId) return;

    if (meterPurpose !== value && channel) {
      turnOnBackdrop();

      mutate(
        { channelId, value: PurposeValueRequest[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  const meterPurpose = (channel?.data as ChannelMeterType)?.meterPurpose

  return (
    <>
      <Info title={t('meterPurpose.purpose')} />
      <InputSelect
        value={meterPurpose}
        onChange={onChangePurpose}
        options={[
          { label: t('meterPurpose.main'), value: MeterPurposeInternal.Main },
          { label: t('meterPurpose.pv'), value: MeterPurposeInternal.Pv },
          { label: t('meterPurpose.energyStorage'), value: MeterPurposeInternal.EnergyStorage },
          { label: t('meterPurpose.consumption'), value: MeterPurposeInternal.Consumption },
        ]}
        label={''}
      />
    </>
  );
};

export default MeterPurpose;
