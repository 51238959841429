import React from 'react';
import {
  BlindRemoteButtonState,
  BlindRemoteButtonStateEnum,
  FacadeRemoteButtonState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { IconChevron, IconPause } from '../../../../../components';
import { ChannelBoxControls } from '../../../../../components/channel-box-controls';

type BlindControlButtonsProps = {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  remoteButtonState?: IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState>;
  isListItem?: boolean;
};

export const BlindControlButtons: React.FC<BlindControlButtonsProps> = ({
  close,
  stop,
  open,
  remoteButtonState,
  isListItem,
}) => {
  const remoteState = remoteButtonState?.Data.State as BlindRemoteButtonStateEnum | null;

  return (
    <ChannelBoxControls
      kind="normal"
      isListItem={isListItem}
      controls={[
        {
          icon: <IconChevron withCircle direction="down" />,
          handleAction: close,
          active: remoteState === BlindRemoteButtonStateEnum.ClosePressed,
        },
        {
          icon: <IconPause />,
          handleAction: stop,
          active: remoteState === BlindRemoteButtonStateEnum.StopPressed,
        },
        {
          icon: <IconChevron withCircle direction="up" />,
          handleAction: open,
          active: remoteState === BlindRemoteButtonStateEnum.OpenPressed,
        },
      ]}
    />
  );
};
