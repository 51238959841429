import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconInitiate2 } from '../../../../../../../../../components';
import { ChannelBoxControls } from '../../../../../../../../../components/channel-box-controls';
import { useGateControl } from '../../../../../hooks/use-gate-control';

type GateCommonControlsProps = {
  channel: IDeviceChannel;
};

const GateCommonControls: React.FC<GateCommonControlsProps> = ({ channel }) => {
  const { handlePositionPulse } = useGateControl(channel);

  return (
    <ChannelBoxControls
      kind="normal"
      controls={[
        {
          icon: <IconInitiate2 />,
          handleAction: handlePositionPulse,
        },
      ]}
    />
  );
};

export default GateCommonControls;
