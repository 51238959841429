import { EnvironmentType, IntegrationDefaultEnum } from './types';
import { isLocalhost } from './utils/helpers/environment';

export const environment: EnvironmentType = {
  ENV: 'development',
  PUBLIC_URL: !isLocalhost ? window.configuration.PUBLIC_URL || window.location.hostname : `//localhost:3000`,
  VERSION: window.configuration.VERSION || '',
  API_URL: window.configuration.API_URL || '',
  KEYCLOAK_CHANGE_PASSWORD_URL: window.configuration.KEYCLOAK_CHANGE_PASSWORD_URL || '',
  KEYCLOAK_URL: window.configuration.KEYCLOAK_URL || '',
  KEYCLOAK_CLIENT_ID: window.configuration.KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM: window.configuration.KEYCLOAK_REALM,
  UPLOAD_PHOTO: window.configuration.UPLOAD_PHOTO || '',
  API_LAVVA_MANAGER: window.configuration.API_LAVVA_MANAGER || '',
  API_LAVVA_MANAGEMENT: window.configuration.API_LAVVA_MANAGEMENT || '',
  MAPS_API_KEY: window.configuration.MAPS_API_KEY || '',
  INTEGRATION_DEFAULT:
    (window.configuration.INTEGRATION_DEFAULT as IntegrationDefaultEnum) || IntegrationDefaultEnum.Lavva,
  NATIVE_APP_ID: window.configuration.NATIVE_APP_ID || '',
  DISCOVERY_URL: window.configuration.DISCOVERY_URL || '',
  SENTRY_DSN: window.configuration.SENTRY_DSN || '',
  BUILD_ID: window.configuration.BUILD_ID || '',
  VAPID_KEY: window.configuration.VAPID_KEY || '',
  FIREBASE_API_KEY: window.configuration.FIREBASE_API_KEY || '',
  FIREBASE_AUTH_DOMAIN: window.configuration.FIREBASE_AUTH_DOMAIN || '',
  FIREBASE_PROJECT_ID: window.configuration.FIREBASE_PROJECT_ID || '',
  FIREBASE_STORAGE_BUCKET: window.configuration.FIREBASE_STORAGE_BUCKET || '',
  FIREBASE_MESSAGING_SENDER_ID: window.configuration.FIREBASE_MESSAGING_SENDER_ID || '',
  FIREBASE_APP_ID: window.configuration.FIREBASE_APP_ID || '',
  FIREBASE_MEASUREMENT_ID: window.configuration.FIREBASE_MEASUREMENT_ID || '',
};
