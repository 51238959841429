import {
  BlindStateDirectionInternal,
  ChannelTypeInternal,
  GateStateDirectionInternal,
  LightToggleValueInternal,
  SwitchStateInternal,
} from '../../../../../data-access/gql-types/graphql';
import {
  ChannelCoverStateType,
  ChannelGateStateType,
  ChannelLightStateType,
  ChannelStateType,
  ChannelSwitchStateType,
} from '../../../types';

export const getChannelActive = (type: ChannelTypeInternal, state: ChannelStateType | null) => {
  switch (type) {
    case ChannelTypeInternal.Gate: {
      const gateState = state as ChannelGateStateType | null;

      return !!(
        (gateState?.direction &&
          [GateStateDirectionInternal.Opening, GateStateDirectionInternal.Closing].includes(gateState.direction)) ||
        gateState?.movingParams?.predictedTimeInMs
      );
    }
    case ChannelTypeInternal.Blind: {
      const blindState = state as ChannelCoverStateType | null;

      return !!(
        blindState?.lavvaStateDirection &&
        [BlindStateDirectionInternal.Opening, BlindStateDirectionInternal.Closing].includes(
          blindState?.lavvaStateDirection as BlindStateDirectionInternal,
        )
      );
    }
  }

  return false;
};

export const getChannelIsOn = (type: ChannelTypeInternal, state: ChannelStateType | null) => {
  switch (type) {
    case ChannelTypeInternal.Switch: {
      const switchState = state as ChannelSwitchStateType | null;
      return switchState?.lavvaState === SwitchStateInternal.On;
    }
    case ChannelTypeInternal.Light: {
      const lightState = state as ChannelLightStateType | null;
      return lightState?.toggleValue === LightToggleValueInternal.On;
    }
  }

  return false;
};
