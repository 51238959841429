import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconDashboard, IconProfile } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { IconDevice } from '../../../components/icons/device';
import { useInstallationContext } from '../../../context/installation';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import './index.scss';

const Device: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('profile');
  const { integrationType } = useInstallationContext();

  return (
    <div className="device-settings-section grid-list-24">
      <ArrowButton
        title={t('devicesList')}
        onClick={() => navigate(ROUTES.Device())}
        className="m-b-24"
        icon={<IconDevice />}
      />
      {integrationType !== IntegrationType.Exalus && (
        <ArrowButton
          title={t('wifiConfiguration')}
          onClick={() => navigate(ROUTES.DeviceWifiConfiguration())}
          icon={<IconProfile />}
          className="m-b-24"
        />
      )}
      <ArrowButton title={t('ourProducts')} onClick={() => navigate(ROUTES.Store())} icon={<IconDashboard />} />
    </div>
  );
};

export default Device;
