import { MeasuredBy } from '../../../../../../../data-access/gql-types/graphql';
import { ChannelType, ValueEntry } from '../../../../../types';

export type ChartDataItem = {
  values: ChartDataValues[];
  time?: string;
  tooltipTime?: string;
  date?: number;
};

export type ChartDataValues = ValueEntry & {
  color: string;
  index: number;
  empty?: boolean;
};

export type AnalysisHookParams = {
  channel: ChannelType;
  measurementChannel?: MeasuredBy;
};

export enum AvailableShortFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'EEEEEE',
  MONTHLY = 'dd.LL',
  YEARLY = 'LL',
  TEN_YEARS = 'yyyy',
}

export enum AvailableLongFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'eeee, dd.MM',
  MONTHLY = 'dd.LL.yyyy',
  YEARLY = 'LLLL',
  TEN_YEARS = 'yyyy',
}

export enum CostEnum {
  Cost = 'COST',
  PredictedCost = 'PREDICTED_COST',
}

export type EnergyStats = {
  color: string;
  count: number;
  max: number | null;
  sum: number;
  average?: number;
};
