import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconConnectionError, IconRecovery } from '../../../../../components';
import { IconDevice } from '../../../../../components/icons/device';
import { DeviceConnectionState } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../context/backdrop';
import { toastError } from '../../../../../utils/toast';
import { useDevicesAndChannels } from '../../../hooks';
import { useChannelsStateStore } from '../../../store/channels-state';
import { DeviceType } from '../../../types';
import { checkDeviceStatus } from '../../../utils/devices/status';

type DeviceInfoProps = {
  device: DeviceType;
};

const DeviceInfo: React.FC<DeviceInfoProps> = ({ device }) => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const { backdropOpen } = useBackdropContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId: device?.id });
  const channelState = useChannelsStateStore((s) => s.channelState);
  const isDeviceDisconnected = checkDeviceStatus(deviceChannels, channelState, DeviceConnectionState.Disconnected);
  const isDeviceInRecoveryState = checkDeviceStatus(deviceChannels, channelState, DeviceConnectionState.Recovery);

  useEffect(() => {
    if (isDeviceDisconnected && !device.isUpdating && !backdropOpen) {
      toastError({ content: t('connectionError.message'), icon: <IconConnectionError iconToastError size={16} /> });
    }
  }, [isDeviceDisconnected, device.isUpdating, backdropOpen]);

  useEffect(() => {
    if (isDeviceInRecoveryState) {
      toastError({ content: t('recoveryError.message'), icon: <IconRecovery iconToastError /> });
    }
  }, [isDeviceInRecoveryState]);

  if (!device?.payload.name) return null;

  return (
    <div className="driver-settings">
      <div className="driver-settings__icon">
        <IconDevice />
        {isDeviceDisconnected ? (
          <IconConnectionError big details size={14} />
        ) : isDeviceInRecoveryState ? (
          <IconRecovery big details />
        ) : null}
      </div>
      <h4 className="driver-settings__info">
        <div className="driver-title text-ellipsis">{device.payload.name || 'N/A'}</div>
        <div className="driver-type text-ellipsis">{tc(`types.${device.type}`)}</div>
      </h4>
    </div>
  );
};

export default DeviceInfo;
