import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArgumentType, SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import ArrowButton from '../../../arrow-button';
import { ActionType, supportedTaskTypeBySceneTaskType } from '../../../../types/actions';
import ActionTasksTypes from '../../../../integrations/lavva/modules/action-create/components/task-types';
import { useChannels } from '../../../../integrations/exalus/modules/channel-list/hooks/use-channels';
import { useExalusCreateActionContext } from '../../../../integrations/exalus/modules/action-create/context';
import { useActionTasks } from '../../../../integrations/exalus/modules/action-create/hooks/use-action-tasks';
import { actionTasks } from '../../../../integrations/exalus/modules/action-create/utils/action-tasks';
import { supportedResponseType } from '../../../../integrations/exalus/modules/action-create/utils/notify-helpers';

const CommonActionTasksTypes: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectTaskType } = useActionTasks();
  const { channels } = useChannels();
  const { taskList, actionType, supportedTaskTypes, conditionList } = useExalusCreateActionContext();

  const conditionListChannels = useMemo(() => {
    const tempList = conditionList
      .filter((x) => x.LeftArgumentType === ArgumentType.ArgumentAsDeviceState)
      .map((x) => {
        const arg = x.LeftArgument.Argument?.ArgumentAsDeviceState;
        const st = arg?.GetCheckDeviceState();

        return {
          deviceId: arg?.DeviceGuid,
          channelNumber: st?.Channel,
        };
      });

    return (channels || []).filter((ch) =>
      tempList.find((c) => c.deviceId === ch.GetDevice().Guid && c.channelNumber === ch.Number),
    );
  }, [channels, conditionList]);

  const list = useMemo(() => {
    const actionTasksTemp = [...actionTasks]
      .filter((x) => {
        if (actionType === ActionType.Action) return x.type !== SceneTaskType.NotifyTask;
        const supportedChannelsExists = !!conditionListChannels.filter((x) =>
          x.AvailableResponseTypes.some((x) => supportedResponseType.includes(x.Type)),
        ).length;

        return !conditionListChannels.length || !supportedChannelsExists ? x.type !== SceneTaskType.NotifyTask : true;
      })
      .filter((x) => {
        if (!supportedTaskTypes.length) return true;
        return supportedTaskTypes.includes(supportedTaskTypeBySceneTaskType[x.type]);
      });

    const index = taskList.findIndex((x) => x.taskType === SceneTaskType.LockExecution);
    if (index !== -1) return [...actionTasksTemp].filter((x) => x.type !== SceneTaskType.LockExecution);
    return [...actionTasksTemp];
  }, [taskList, actionType, supportedTaskTypes, conditionListChannels]);

  return (
    <ActionTasksTypes>
      {list.map((x) => (
        <ArrowButton
          key={x.title}
          title={t(x.title)}
          icon={x.icon}
          className="m-b-24"
          iconAccent
          onClick={() => selectTaskType(x.type)}
        />
      ))}
    </ActionTasksTypes>
  );
};

export default CommonActionTasksTypes;
