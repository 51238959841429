import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IWszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/IWszfBidiConfigService';
import { BrightnessThresholdStep as BrightnessThresholdStepType } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../context/backdrop';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { BrightnessThresholdParams } from '../../../device-details/types';

type BrightnessThresholdFormProps = {
  device: IDevice;
  channel: IDeviceChannel;
  brightnessThreshold: BrightnessThresholdStepType | null;
};

const BrightnessThresholdForm: React.FC<BrightnessThresholdFormProps> = ({ device, channel, brightnessThreshold }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<BrightnessThresholdParams>();

  const { BrightnessThreshold } = form.watch();

  useEffect(() => {
    if (brightnessThreshold !== null) form.setValue('BrightnessThreshold', brightnessThreshold);
  }, [brightnessThreshold]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IWszfBidiConfigService>(
      WszfBidiConfigService.ServiceName,
    );

    const result = await configService.SetBrightnessThresholdAsync(device, channel.Number, values.BrightnessThreshold);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  const onChangeBrightnessThreshold = (value: BrightnessThresholdStepType) => {
    form.setValue('BrightnessThreshold', value);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeBrightnessThreshold}
          value={BrightnessThreshold}
          options={[
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Disabled'),
              value: BrightnessThresholdStepType.Disabled,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness2000lux'),
              value: BrightnessThresholdStepType.Brightness2000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness5000lux'),
              value: BrightnessThresholdStepType.Brightness5000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness10000lux'),
              value: BrightnessThresholdStepType.Brightness10000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness20000lux'),
              value: BrightnessThresholdStepType.Brightness20000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness40000lux'),
              value: BrightnessThresholdStepType.Brightness40000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness60000lux'),
              value: BrightnessThresholdStepType.Brightness60000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness70000lux'),
              value: BrightnessThresholdStepType.Brightness70000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness80000lux'),
              value: BrightnessThresholdStepType.Brightness80000lux,
            },
            {
              label: t('exalus.params.WszfBidiParams.brightnessThresholdSteps.Brightness90000lux'),
              value: BrightnessThresholdStepType.Brightness90000lux,
            },
          ]}
          label={t('exalus.params.WszfBidiParams.BrightnessThreshold')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default BrightnessThresholdForm;
