import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../../../components';
import { useNative } from '../../../../../../hooks';
import { isAndroid, isIos } from '../../../../../../utils/helpers/operation-system';
import { OSSystem } from '../../types';
import { appStoreLink } from '../../utils';

export const BluetoothNotSupportedDialog: React.FC = () => {
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const [system, setSystem] = useState<OSSystem>(OSSystem.Desktop);
  const { showBluetoothNotSupportedDialog, setShowBluetoothNotSupportedDialog } = useNative();

  useEffect(() => {
    if (isIos) setSystem(OSSystem.Ios);
    else if (isAndroid) setSystem(OSSystem.Android);
  }, []);

  return (
    <DialogConfirmation
      show={showBluetoothNotSupportedDialog}
      setShow={setShowBluetoothNotSupportedDialog}
      header={t('bluetoothNotSupported.header')}
      primaryBtnText={tc('buttons.understand')}
      primaryBtnAction={() => {
        setShowBluetoothNotSupportedDialog(false);
      }}
    >
      <Trans
        t={t}
        i18nKey={`bluetoothNotSupported.${system.toLowerCase()}Content`}
        values={{ linkText: appStoreLink[system].store }}
      >
        <a href={appStoreLink[system].href}>{appStoreLink[system].store}</a>
      </Trans>
    </DialogConfirmation>
  );
};
