import { useNavigate, useLocation } from 'react-router-dom';

export const useRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isBackAvailable = !!location.key;

  const handleReplaceRedirect = (route: string) => navigate(route, { replace: true });

  return {
    isBackAvailable,
    handleReplaceRedirect,
  };
};
