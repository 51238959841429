import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, Input } from '../../../../../../../../components';

type PriceDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  label: string;
  price: number;
  onSave: (val: number) => void;
};

export const PriceDialog: React.FC<PriceDialogProps> = ({ show, setShow, label, price, onSave }) => {
  const { t: tc } = useTranslation('common');
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(price.toString());
  }, [show]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const handleSave = () => {
    onSave(parseFloat(value));
    setShow(false);
  };

  return (
    <DialogConfirmation
      show={show}
      setShow={setShow}
      primaryBtnText={tc('buttons.save')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnAction={handleSave}
      secondaryBtnAction={() => setShow(false)}
    >
      <Input
        label={label}
        placeholder={'10 zł'}
        inputType="number"
        step="0.1"
        onChange={handleChange}
        defaultValue={value}
        value={value}
        reverse
      />
    </DialogConfirmation>
  );
};
