import React from 'react';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { DeviceTaskExecutionResult as ChannelTaskExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/TaskExecutionResult';
import { IconOff, IconOn } from '../../../../../components';
import { ChannelBoxControls } from '../../../../../components/channel-box-controls';
import { PowerItem } from './power';

type SwitchControlButtonsProps = {
  turnOn: () =>
    | Promise<ChannelTaskExecutionResult>
    | Promise<ChannelTaskExecutionResult>[]
    | Promise<ChannelTaskPairExecutionResult[]>;
  turnOff: () =>
    | Promise<ChannelTaskExecutionResult>
    | Promise<ChannelTaskExecutionResult>[]
    | Promise<ChannelTaskPairExecutionResult[]>;
  isListItem?: boolean;
  power?: number;
};

export const SwitchControlButtons: React.FC<SwitchControlButtonsProps> = ({ turnOn, turnOff, isListItem, power }) => (
  <ChannelBoxControls
    kind="with-data"
    isListItem={isListItem}
    controls={[
      {
        icon: <IconOn />,
        handleAction: turnOn,
      },
      {
        icon: <IconOff />,
        handleAction: turnOff,
      },
    ]}
  >
    <PowerItem power={power} column={!isListItem} />
  </ChannelBoxControls>
);
