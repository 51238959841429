import { useMutation } from '@apollo/client';
import { useBackdropContext } from '../../context/backdrop';
import {
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables,
  RejectInvitationMutation,
  RejectInvitationMutationVariables,
} from '../../data-access/gql-types/graphql';
import { INVITATION_ACCEPT } from '../../data-access/mutations/invitation';
import { INVITATION_REJECT } from '../../data-access/mutations/invitation';
import { MutationErrorType } from '../../types';
import { useMutationErrors } from '../backend-errors/use-mutation-errors';

export const useInvitationsOperations = () => {
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Invitation);
  const [invitationAccept] = useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(
    INVITATION_ACCEPT,
  );
  const [invitationReject] = useMutation<RejectInvitationMutation, RejectInvitationMutationVariables>(
    INVITATION_REJECT,
  );

  const acceptInvitation = (invitationId: string, onSuccess: () => void) => {
    turnOnBackdrop();

    invitationAccept({
      variables: {
        input: { invitationId },
      },
      onCompleted: (data) => {
        if (data?.acceptInvitation?.result?.ok) onSuccess();
        else {
          turnOffBackdrop();
          handleErrors(data?.acceptInvitation?.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const rejectInvitation = (invitationId: string, onSuccess: () => void) => {
    turnOnBackdrop();

    invitationReject({
      variables: {
        input: { invitationId },
      },
      onCompleted: (data) => {
        if (data.rejectInvitation.result?.ok) onSuccess();
        else {
          turnOffBackdrop();
          handleErrors(data.rejectInvitation.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  return {
    acceptInvitation,
    rejectInvitation,
  };
};
