import React from 'react';
import { StatusTypeEnum } from '../../types';
import {
  Integration,
  Join,
  NoInstallation,
  Sent,
  Share,
  Update,
  Wait,
  InstallationCreated,
  IntegrationController,
  Migration,
} from '../icons';

type StatusIconProps = {
  type: StatusTypeEnum;
};

export const StatusIcon: React.FC<StatusIconProps> = ({ type }) => {
  switch (type) {
    case StatusTypeEnum.NO_INSTALLATION:
      return <NoInstallation />;
    case StatusTypeEnum.INTEGRATION:
      return <Integration />;
    case StatusTypeEnum.SENT:
      return <Sent />;
    case StatusTypeEnum.JOIN:
      return <Join />;
    case StatusTypeEnum.UPDATE:
      return <Update />;
    case StatusTypeEnum.WAIT:
      return <Wait />;
    case StatusTypeEnum.SHARE:
      return <Share />;
    case StatusTypeEnum.INTEGRATION_CONTROLLER:
      return <IntegrationController />;
    case StatusTypeEnum.INSTALLATION_CREATED:
      return <InstallationCreated />;
    case StatusTypeEnum.MIGRATION:
      return <Migration />;
    default:
      return null;
  }
};
