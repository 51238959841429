import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, CustomBackdrop, IconToastWarning, InputSelect } from '../../../../../../components';
import { CheckboxSelect } from '../../../../../../components/checkbox-select';
import { AggregateVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../types';
import { ChannelMeterType, ChannelType } from '../../../../types';
import { PhasesButtonGroup } from '../../measurements/components/phases-button-group';
import { useMeterPrices } from '../common/hooks/use-meter-prices';
import { AnalysisChart } from './components/chart';
import { RangeHeader } from './components/chart/range-header';
import { Summary } from './components/summary';
import { useMeterAnalysis } from './hooks/use-meter-analysis';
import './index.scss';
import { getDataByRange } from './utils';

type MeterAnalysisProps = {
  channel: ChannelType;
};

export const MeterAnalysis: React.FC<MeterAnalysisProps> = ({ channel }) => {
  const { t: t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const {
    data,
    selectedPhases,
    isLoading,
    mappedChartData,
    aggregatedParameters,
    activeMeasurementRange,
    page,
    isStandalone,
    setSelectedPhases,
    onClickActiveMeasurementRange,
    setPage,
    handleSetAggregatedParameters,
  } = useMeterAnalysis({ channel });
  const { tariffPricesConfiguration, kind, dynamicPrices, emptyPrices } = useMeterPrices(selectedPhases);

  const chartData = useMemo(
    () =>
      getDataByRange(
        activeMeasurementRange,
        mappedChartData,
        page,
        kind,
        tariffPricesConfiguration,
        dynamicPrices,
        aggregatedParameters,
      ),
    [activeMeasurementRange, mappedChartData, page, tariffPricesConfiguration, dynamicPrices, aggregatedParameters],
  );

  const parametersAvailable = aggregatedParameters.filter((x) => !x.disabled).length > 0;

  const selectedParameters = aggregatedParameters.filter(
    (x) => !x.disabled && x.checked && x.value !== AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
  );

  const priceParameterSelected = selectedParameters.find(
    (x) => x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
  );

  const timeRanges = [
    { value: MeasurementRange.DayInHours, label: t('dateAbbreviation.hours') },
    { value: MeasurementRange.MonthInDays, label: t('dateAbbreviation.days') },
    { value: MeasurementRange.WeekInDays, label: t('dateAbbreviation.weeks') },
    { value: MeasurementRange.YearInMonths, label: t('dateAbbreviation.months') },
    { value: MeasurementRange.TenYearsInYears, label: t('dateAbbreviation.years') },
  ];

  const defaultPhases = isStandalone
    ? [0, 1, 2, 3]
    : [0, ...(channel.data as ChannelMeterType).measuredChannels.map((x) => x.phase)];

  const itemPrefix = t(isStandalone ? 'phase' : 'outputShort');

  return (
    <div className="meter-analysis">
      <ControlWrapper className="control-wrapper--column  control-wrapper--reverse p-l-24 p-r-24 p-b-16">
        {parametersAvailable ? (
          <div className="grid-list-24">
            <CheckboxSelect
              options={aggregatedParameters}
              onSave={handleSetAggregatedParameters}
              className="input--reverse"
              multipleLabel={tc('parameters')}
            />
            <InputSelect
              options={timeRanges}
              value={activeMeasurementRange}
              onChange={onClickActiveMeasurementRange}
              className="input--reverse"
              label={''}
            />
          </div>
        ) : null}
        {emptyPrices && priceParameterSelected && (
          <div className="empty-prices">
            <IconToastWarning /> <span>{t('pricesEmpty')}</span>
          </div>
        )}
        <RangeHeader
          prevAvailable={chartData.prevAvailable}
          nextAvailable={chartData.nextAvailable}
          range={chartData.range}
          setPage={setPage}
        />
        <AnalysisChart
          data={chartData.result}
          activeMeasurementRange={activeMeasurementRange}
          isLoading={isLoading}
          aggregatedParameters={aggregatedParameters}
          itemPrefix={itemPrefix}
        />
        {selectedParameters.length &&
        selectedParameters.length === 1 &&
        ![
          AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyIncoming,
          AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergyOutgoing,
        ].includes(selectedParameters[0].value) ? (
          <PhasesButtonGroup
            onSelectedPhases={setSelectedPhases}
            defaultPhases={defaultPhases}
            itemPrefix={itemPrefix}
          />
        ) : null}
      </ControlWrapper>
      <Summary summaryData={chartData.result} activeMeasurementRange={activeMeasurementRange} itemPrefix={itemPrefix} />
      <CustomBackdrop loading={isLoading && !data} />
    </div>
  );
};
